import { styled } from "@mui/material";

import { ExpandButtonStyled } from "@/shared/Table/ui/TableEntities/ExpandButton/ExpandButton.styled";

export const RegionPopUpButtonStyled = styled(ExpandButtonStyled, {
  shouldForwardProp: (prop) =>
    !["isOpen", "isChangePosition"].includes(prop.toString()),
})<{
  isOpen?: boolean;
  isChangePosition?: boolean;
}>(({ theme, isOpen, isChangePosition = false }) => ({
  width: theme.spacing(3),
  height: theme.spacing(3),
  backgroundColor: isOpen
    ? "transparent"
    : theme.palette.button?.[
        theme.palette.mode === "dark" ? "popup" : "iconBackground"
      ],
  ...(!isOpen && {
    border: `1px solid ${theme.palette.button.border}`,
  }),
  ...(isChangePosition && {
    position: "absolute",
    top: "1.65rem",
    right: "1.5rem",
    zIndex: 100,
  }),
  "& > svg > path": {
    fill:
      theme.palette.mode === "light"
        ? theme.palette.modal.text
        : theme.palette.text.lightGrey,
  },
}));
