import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { Box, FormControl, Grid, SelectChangeEvent } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";

import { useRegions } from "@/pages/RegionPage/hooks/useRegions";

import { fileTypeSolid, periods, QUARTERS } from "../lib/constants";
import { formationPath } from "../lib/helpers";
import { FileType, IExportControlProps } from "../lib/types";
import {
  BootstrapInput,
  FileButtonBox,
  FileTypeBox,
  FileTypeButton,
  FileTypeName,
  InputAdornmentStyled,
  Label,
  menuExportStyle,
  MenuItemStyled,
  SelectStyled,
} from "./ExportControl.styled";
import QuarterSelect from "./QuarterSelect/QuarterSelect";

export const FoExportControl = ({
  handleChangeData,
  districtId,
}: IExportControlProps) => {
  const { districts } = useRegions();
  const [isOpenPeriod, setIsOpenPeriod] = useState(false);
  const [period, setPeriod] = useState<string>("");
  const [quarter, setQuarter] = useState<number>(0);
  const [actualYear, setActualYear] = useState<string>();
  const [subject, setSubject] = useState<string>("");
  const [fileType, setFileType] = useState<FileType | null>(null);

  useEffect(() => {
    setSubject(districtId || districts?.[0]?.id);
    setFileType(null);
  }, []);

  const districtItems = districts?.map((item) => (
    <MenuItemStyled key={item?.id} value={item?.id}>
      {item?.attributeVals?.name.replace(/федеральный округ/gi, "ФО")}
    </MenuItemStyled>
  ));

  const checkPeriodsFormat = useMemo(() => {
    return periods?.[actualYear!!]?.find(
      (period) => +period.quarter === quarter
    )?.formatFile;
  }, [actualYear, quarter]);

  const formationCallbackData = () => {
    const isDisabled = !(
      subject &&
      period &&
      fileType &&
      checkPeriodsFormat?.includes(fileType)
    );
    const fileName =
      districts?.find(({ id }) => id === subject)?.attributeVals[
        "SHORT_NAME"
      ] || "";
    let filePath = "";

    if (fileType && fileName && quarter) {
      filePath = formationPath({
        fileType,
        fileName,
        quarter,
        year: actualYear,
      });
    }
    handleChangeData({ isDisabled, filePath, fileName });
  };

  useEffect(() => {
    formationCallbackData();
  }, [subject, period, fileType, quarter, actualYear]);

  const handleChangeSubject = (event: SelectChangeEvent<any>) => {
    setSubject(event.target.value);
  };

  const handleChangePeriod = useCallback((quarter: number, year: number) => {
    setPeriod(`${QUARTERS[quarter]} квартал ${year}`);
    setQuarter(quarter + 1);
    setActualYear(String(year));
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box sx={{ minWidth: 306 }}>
            <FormControl
              style={{ position: "relative" }}
              fullWidth
              size="small"
            >
              <Label>Период</Label>
              <BootstrapInput
                onClick={() => setIsOpenPeriod(!isOpenPeriod)}
                value={period}
                endAdornment={
                  <InputAdornmentStyled position="end">
                    <KeyboardArrowDownIcon
                      sx={{
                        color: (theme) =>
                          theme.palette.mode === "dark"
                            ? theme.palette.icon.iconProject
                            : theme.palette.tabs.textColorSelect,
                      }}
                    />
                  </InputAdornmentStyled>
                }
              />
              <QuarterSelect
                isOpen={isOpenPeriod}
                setIsOpen={setIsOpenPeriod}
                changePeriod={handleChangePeriod}
              />
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box sx={{ minWidth: 306 }}>
            <FormControl fullWidth size="small">
              <Label>Федеральный округ</Label>
              <SelectStyled
                variant="standard"
                id="subject"
                value={subject}
                onChange={handleChangeSubject}
                input={<BootstrapInput />}
                IconComponent={KeyboardArrowDownIcon}
                MenuProps={{ sx: menuExportStyle }}
              >
                {districtItems}
              </SelectStyled>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
      <FileTypeBox>
        <Label>Выберите тип файла</Label>
        <FileButtonBox>
          {fileTypeSolid.map((file, index) => (
            <div key={file}>
              <FileTypeButton
                value={file}
                disabled={!checkPeriodsFormat?.includes(file)}
                selected={
                  fileType === file && checkPeriodsFormat?.includes(file)
                }
                onChange={() => setFileType(file)}
              >
                <TextSnippetIcon />
              </FileTypeButton>
              <FileTypeName
                selected={
                  fileType === file && checkPeriodsFormat?.includes(file)
                }
              >
                {file.toUpperCase()}
              </FileTypeName>
            </div>
          ))}
        </FileButtonBox>
      </FileTypeBox>
    </>
  );
};
