import { defaultIndicatorsData } from "./constants";
import { INationalProjectsItemProps } from "./types";

export const getDecomposeIndicatorsData = (
  selected: string
): INationalProjectsItemProps[] => {
  return defaultIndicatorsData.map((data) => {
    return {
      ...data,
      selected,
    };
  });
};
