import { Box, styled } from "@mui/material";

export const OverlaySceleton = styled(Box)(({ theme }) => ({
  height: "100%",
  background: theme.palette.sceleton.baseColor,
  borderRadius: "0.5rem",
  overflow: "hidden",

  "@keyframes animationSceleton": {
    "0%": {
      opacity: 1,
    },

    "50%": {
      opacity: 0.5,
    },

    "100%": {
      opacity: 1,
    },
  },
}));

export const WrapperSceleton = styled(Box)({
  display: "flex",
  flexDirection: "column",
  padding: "19px 1.5rem",
});

export const SceletonLine1Styled = styled("div")<{
  widthLine?: string;
  heightLine?: number;
}>(({ theme, widthLine, heightLine }) => ({
  display: "flex",
  width: widthLine ? widthLine : "100%",
  height: heightLine ? `${heightLine}px` : "1.25rem",
  background: theme.palette.table.popUpBackground,
  borderRadius: "0.25rem",
  animation: "animationSceleton 1.5s ease-in-out 0.5s infinite",
}));

export const SceletonLine2Styled = styled("div")<{ widthLine?: string }>(
  ({ theme, widthLine }) => ({
    display: "flex",
    width: widthLine ? widthLine : "100%",
    height: "2.125rem",
    background: theme.palette.table.popUpBackground,
    borderRadius: "0.25rem",
    animation: "animationSceleton 1.5s ease-in-out 0.5s infinite",
  })
);

export const SceletonCircleStyled = styled("div")<{ size?: string }>(
  ({ theme, size }) => ({
    display: "flex",
    flexShrink: 0,
    width: size || "5.67rem",
    height: size || "5.67rem",
    background: theme.palette.table.popUpBackground,
    borderRadius: "50%",
    animation: "animationSceleton 1.5s ease-in-out 0.5s infinite",
  })
);
