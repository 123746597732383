import moment from "moment";
import { useMemo, useState } from "react";

import { useProjectPage } from "@/pages/ProjectsPage/hooks/useProjectPage";
import {
  checkPeriodsByActual,
  defaultColumns,
  defaultIndicators,
  projectIndicators,
} from "@/pages/ProjectsPage/lib/constants";
import { SELECT_VALUE } from "@/shared/InputSelect/lib/types";
import { formatNumber } from "@/shared/lib/heplers";
import { stickyTop } from "@/shared/ProjectResultsGroup/lib/helpers";
import { PROJECT_INDICATORS } from "@/shared/Table/model/api/scheme";
import {
  IProjectIndicatorAttributeVals,
  MODEL_IDS,
  MODELS,
} from "@/store/scheme/olap";

import { decimalToString, getTitleCell } from "../lib/helpers";
import { IArrReportDate, IPagesProjectInfoPeriodData } from "../lib/types";

export const useProjectIndicators = (changePeriod: SELECT_VALUE | null) => {
  const { info, infoFetching } = useProjectPage();
  const presentYear = moment();
  const lastYear = moment().subtract(1, "years");
  const [achievementDate, setAchievementDate] = useState("2030");
  const model = MODELS[MODEL_IDS.PROJECT_PASSPORT];

  // отфильтрованные периоды по возрастанию
  const convertFilterPeriods = useMemo(() => {
    const convertPeriods = checkPeriodsByActual(
      Object.keys(info?.periods || {})?.sort()
    );

    const allPeriods = convertPeriods
      .map((period) => {
        const periodData = info?.periods?.[period];
        return periodData;
      })
      ?.filter((periodData) => {
        return ["106", "107", "108", "109"].some((period) => {
          return Object.keys(periodData?.indicators || {})?.includes(period);
        });
      });

    // Срок достижения
    const lastAchievementDate =
      allPeriods[convertPeriods?.length - 1]?.attributeVals?.["YEAR"];
    if (lastAchievementDate) {
      setAchievementDate(lastAchievementDate);
    }

    if (changePeriod === "CURRENT_PERIOD") {
      return allPeriods?.filter((item) => {
        return [lastYear.year(), presentYear.year()].includes(
          +(item?.attributeVals?.["YEAR"] || 0)
        );
      });
    } else {
      return allPeriods;
    }
  }, [info?.periods, changePeriod]);

  const isCheckActualYear = (item: IPagesProjectInfoPeriodData | undefined) => {
    return item?.dims?.[model.dataActualId]?.code === "2";
  };

  // получение данных для отображения фильтров
  const QUAR_OPTIONS = useMemo(() => {
    const arrReportDate: Record<string, IArrReportDate[]> = {};

    Object.values(convertFilterPeriods ?? {}).forEach((item) => {
      if (item?.level === 2 && !!item?.code?.match(/^3/gi)?.length) {
        const year = item?.attributeVals?.YEAR;
        if (!arrReportDate?.[year]) {
          arrReportDate[year] = [];
        }
        arrReportDate[year].push({
          value: Number(item?.code?.slice(item?.code?.length - 1)),
          label: item.attributeVals.REPORT_DATE.replace("на ", ""),
          ...(+year === presentYear.year() && {
            isActual: isCheckActualYear(item),
          }),
        });
      }
    });
    return arrReportDate;
  }, [convertFilterPeriods, presentYear]);

  const defaultQuarter = (period: string) => {
    const defaultQuarOptions = QUAR_OPTIONS?.[period];
    if (+period === presentYear.year()) {
      const actualValue = defaultQuarOptions?.filter(
        (item: IArrReportDate) => item?.isActual
      );

      return actualValue?.[0]?.value || defaultQuarOptions?.[0]?.value;
    }
    return defaultQuarOptions?.[defaultQuarOptions?.length - 1]?.value;
  };

  const [quarter, setQuarter] = useState<Record<string, number>>(() => {
    return Object.keys(QUAR_OPTIONS)?.reduce((prev, period) => {
      prev[period] = defaultQuarter(period);
      return prev;
    }, {} as { [period: string]: number });
  });

  const handleQuarter = (year: string, newQuarter: number) => {
    setQuarter((prev) => ({
      ...prev,
      [year]: newQuarter,
    }));
  };

  // здесь фильтрация в зависимости от квартала текущего и прошлого годов из фильтра и годовых значений
  const filteredPeriods = useMemo(() => {
    const result = convertFilterPeriods.filter((period) => {
      const date = moment(period?.attributeVals?.DateEnd);
      const isNextYear = period?.level === 1;

      return date?.quarter() === quarter?.[date.year()] || isNextYear;
    });

    return result;
  }, [quarter, convertFilterPeriods]);

  const columnRows = useMemo(() => {
    const [firstRow, secondRow, thirdRow] = defaultColumns(
      !!filteredPeriods?.length
    );

    filteredPeriods.forEach((period) => {
      const date = moment(period?.attributeVals?.DateEnd);

      if (period?.level === 2) {
        firstRow.push({
          id: `${date.format("YYYY")}-current-year`,
          label: date.format("YYYY"),
          colSpan: 4,
          align: "center",
          oldid: "current-year",
          ...stickyTop(0),
        });
        secondRow.push(
          {
            id: `${date.format("YYYY")}-completion_status_attr`,
            label: "Статус",
            width: 100,
            oldid: "completion_status_attr",
            ...stickyTop(29),
          },
          {
            id: `${date.format("YYYY")}-current-year-plan`,
            label: "План",
            width: 100,
            oldid: "current-year-plan",
            ...stickyTop(29),
          },
          {
            id: `${date.format("YYYY")}-current-year-fact`,
            label: "Факт",
            width: 100,
            oldid: "current-year-fact",
            ...stickyTop(29),
          },
          {
            id: `${date.format("YYYY")}-current-year-percent`,
            label: "Достижение, %",
            width: 140,
            oldid: "current-year-percent",
            ...stickyTop(29),
          }
        );
        thirdRow.push({
          id: `${date.format("YYYY")}`,
          isFilter: true,
          label: "", // будет определяться динамически
          colSpan: 4,
          width: 100,
          ...stickyTop(58),
        });
      }

      if (period?.level === 1) {
        firstRow.push({
          id: period?.id,
          label: date.format("YYYY"),
          ...stickyTop(0),
        });
        secondRow.push({
          id: `${period?.id}-plan-year`,
          label: "План на год",
          rowSpan: 2,
          width: 100,
          ...stickyTop(29),
        });
      }
    });

    return [firstRow, secondRow, thirdRow];
  }, [filteredPeriods]);

  const rows = useMemo(() => {
    const result = JSON.parse(JSON.stringify({ ...defaultIndicators }));
    // Получить значения для первых колонок
    const getValue = (
      id: "value" | "achievement_date" | keyof IProjectIndicatorAttributeVals,
      indicator: PROJECT_INDICATORS
    ) => {
      switch (id) {
        case "value":
          return formatNumber(
            info?.summary?.[indicator]?.values?.[model.indexes.plan],
            undefined,
            indicator === PROJECT_INDICATORS.SALARY ? 0 : 2
          );
        case "achievement_date":
          return achievementDate;

        case "short_name":
          return getTitleCell(indicator);

        default:
          return info?.summary?.[indicator]?.attributeVals[id];
      }
    };

    const resultSummary = Object.keys(result) as PROJECT_INDICATORS[];

    resultSummary.forEach((indicator) => {
      result[indicator]?.push(
        ...(["short_name", "UNIT", "value", "achievement_date"] as any).map(
          (id: any) => {
            return {
              id,
              value: getValue(id, indicator),
            };
          }
        )
      );
    });
    // пройдем по всем периодам
    filteredPeriods.forEach((period, index) => {
      const date = moment(period?.attributeVals?.DateEnd);
      // по всем PROJECT_INDICATORS
      (Object.keys(result) as PROJECT_INDICATORS[]).forEach((indicator) => {
        // Для квартальных значений
        if (period?.level === 2) {
          const percentValue =
            period?.indicators[indicator]?.values?.[model.indexes.percent];
          const plan = String(
            period?.indicators[indicator]?.values?.[model.indexes.plan]
          ).includes("e-")
            ? decimalToString(
                period?.indicators[indicator]?.values?.[model.indexes.plan]
              )
            : period?.indicators[indicator]?.values?.[model.indexes.plan];
          const fact =
            period?.indicators[indicator]?.values?.[model.indexes.fact];
          const colorPercent =
            period?.indicators[indicator]?.values?.[model.indexes.color];

          result[indicator].push(
            {
              id: `${date.format("YYYY")}-completion_status_attr`,
              oldid: "completion_status_attr",
              value:
                period?.indicators[indicator]?.values?.[model.indexes.status],
            },
            {
              id: `${date.format("YYYY")}-current-year-plan`,
              oldid: "current-year-plan",
              value: plan,
            },

            {
              id: `${date.format("YYYY")}-current-year-fact`,
              oldid: "current-year-fact",
              value: fact,
            },
            {
              id: `${date.format("YYYY")}-current-year-percent`,
              oldid: "current-year-percent",
              value: formatNumber(
                percentValue,
                undefined,
                percentValue && percentValue < 100 ? 1 : 0
              ),
              colorPercent,
            }
          );
        }
        // Для годовых значений
        if (period?.level === 1) {
          result[indicator].push({
            id: `${period?.id}-plan-year`,
            value: formatNumber(
              period?.indicators[indicator]?.values?.[model.indexes.plan],
              undefined,
              indicator === PROJECT_INDICATORS.SALARY ? 0 : 2
            ),
          });
        }
      });
    });

    return [...projectIndicators].map((indicator, index) => [
      {
        id: "number",
        value: index + 1,
      },
      ...result[indicator],
    ]);
  }, [info?.summary, filteredPeriods, quarter, achievementDate]);

  return {
    QUAR_OPTIONS,
    columnRows,
    rows,
    quarter,
    handleQuarter,
    infoFetching,
  };
};
