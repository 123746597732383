import { useAppSelector } from "@store/redux/hooks";
import React from "react";

import {
  BoxStyled,
  RegionGridCell,
  RegionGridProfit,
  RegionGridTripleCell,
} from "@/pages/RegionPage/ui/RegionMutableInfo/ui/RegionMutableInfo.styled";

import { KktWidget } from "../KktWidget/ui/KktWidget";
import {
  AverageMonthNominalSalary,
  AverageSalary,
  DistrictValuesProfit,
  Inflation,
  NominalIncome,
} from "./components";

export const RegionMutableProfit = () => {
  const { user } = useAppSelector((state) => state.auth.user);

  return user?.isAnalyticFK ? null : (
    <BoxStyled>
      <RegionGridProfit>
        <RegionGridCell adjustHeight sx={{ gridArea: "DistrictValues" }}>
          <DistrictValuesProfit />
        </RegionGridCell>

        <RegionGridCell sx={{ gridArea: "TripleCell" }}>
          <RegionGridTripleCell>
            <NominalIncome />
            <AverageMonthNominalSalary />
            <Inflation />
          </RegionGridTripleCell>
        </RegionGridCell>

        <RegionGridCell sx={{ gridArea: "AverageSalary" }}>
          <AverageSalary />
        </RegionGridCell>
        <RegionGridCell sx={{ gridArea: "KKT" }}>
          <KktWidget />
        </RegionGridCell>
      </RegionGridProfit>
    </BoxStyled>
  );
};
