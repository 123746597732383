import { styled, ToggleButton } from "@mui/material";

export const ToggleButtonStyled = styled(ToggleButton)(({ theme }) => ({
  boxSizing: "border-box",
  backgroundColor: theme.palette.button.background,
  border: `1px solid ${theme.palette.button.border} !important`,
  "&.Mui-selected": {
    padding: 7,
  },
  "&:not(.Mui-selected)": {
    padding: 7,
  },
}));
