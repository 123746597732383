import DynamicsHighRFIcon from "@assets/svg_components/DynamicsHighRF";
import DynamicsLowRFIcon from "@assets/svg_components/DynamicsLowRF";
import { FC } from "react";

import { useSubFooter } from "../../hooks/useSubFooter";
import { Item } from "../Footer.styled";

export const FooterHighLowRF: FC = () => {
  const { isCheckManufacuringTab } = useSubFooter();
  return (
    <>
      <Item>
        <DynamicsHighRFIcon />
        {isCheckManufacuringTab && (
          <DynamicsHighRFIcon contrast={isCheckManufacuringTab} />
        )}
        Значение выше РФ
      </Item>
      <Item>
        <DynamicsLowRFIcon />
        {isCheckManufacuringTab && (
          <DynamicsLowRFIcon contrast={isCheckManufacuringTab} />
        )}
        Значение ниже РФ
      </Item>
    </>
  );
};
