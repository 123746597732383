import React, { useState } from "react";

import { ICONS } from "@/pages/ProjectsPage/lib/constants";
import {
  ToggleButtonGroupStyled,
  ToggleButtonStyled,
} from "@/pages/ProjectsPage/ui/tables/ProjectFinancesTable/ProjectFinancesTable.styled";
import { IProjectResultsGroupProps } from "@/shared/ProjectResultsGroup/lib/types";

export const ProjectResultsGroup = ({
  disabled,
  handleChangeCallback,
}: IProjectResultsGroupProps) => {
  const [changeIcon, setChangeIcon] = useState(ICONS[0].id);

  const handleIcon = (
    event: React.MouseEvent<HTMLElement>,
    newIcon: string
  ) => {
    if (newIcon) {
      setChangeIcon(newIcon);
      handleChangeCallback(newIcon);
    }
  };

  return (
    <ToggleButtonGroupStyled
      size="small"
      exclusive
      value={changeIcon}
      onChange={handleIcon}
    >
      {Object.keys(ICONS).map((index: number | string) => (
        <ToggleButtonStyled
          value={ICONS[index as number].id}
          key={ICONS[index as number].id}
          disabled={disabled}
        >
          {ICONS[index as number].icon}
        </ToggleButtonStyled>
      ))}
    </ToggleButtonGroupStyled>
  );
};
