import Stack from "@mui/material/Stack/Stack";
import { useAppSelector } from "@store/redux/hooks";
import { MODEL_IDS, MODELS } from "@store/scheme/olap";
import { FC, Fragment, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router";

import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { COMPARE_TYPE } from "@/shared/CompareControl/lib/types";
import { CompareIcons } from "@/shared/CompareIcons/ui/CompareIcons";
import { FoCoatsOfArms } from "@/shared/FoCoatsOfArms/ui/FoCoatsOfArms";
import { formatNumber } from "@/shared/lib/heplers";
import { REGION_LEVEL } from "@/shared/lib/types";
import { useFuturePlan } from "@/shared/Table/hooks/useFuturePlan";
import { useSortTable } from "@/shared/Table/hooks/useSortTable";
import { useData } from "@/shared/Table/hooks/useTotal";
import { useTransitionPage } from "@/shared/Table/hooks/useTransitionPage";
import { getColorValue, getSubColor } from "@/shared/Table/lib/helpers";
import { IDistrictRow } from "@/shared/Table/lib/types";
import { TAB_ID } from "@/store/scheme/region";

import {
  LinkStyled,
  MainTableValue,
  SubValueProjectStyled,
  SubValueStyled,
  TableHL,
} from "../../Table.styled";
import { ExpandButton } from "../ExpandButton/ExpandButton";
import { RegionRow } from "../RegionRow/RegionRow";
import { TableCellStyled, TableRowStyled } from "./DistrictRow.styled";

export const DistrictRow: FC<IDistrictRow> = ({
  district,
  columns,
  withProjects,
  order,
  orderBy,
}) => {
  const { tabName = TAB_ID.VDL_PROJECTS } = useParams<"tabName">();
  const { getIndicator } = useData();
  const { isCheckFuturePlan } = useFuturePlan();
  const location = useLocation();
  const { getRegionsByParent, districtIds } = useRegions();
  const { compare } = useAppSelector((state) => state.controls);
  const modelKPI = MODELS[MODEL_IDS.KPI_SOC_ECONOMY];
  const { sortBy, getComparator } = useSortTable();
  const { subjectId, handleChangeSubjectId } = useTransitionPage();
  const [isShowRegions, setIsShowRegions] = useState(() => {
    return (
      district?.id === subjectId?.districtId ||
      district?.id === subjectId?.regionId
    );
  });

  useEffect(() => {
    if (
      !subjectId?.regionId &&
      !subjectId?.districtId &&
      (location?.state?.regionId || location?.state?.districtId)
    ) {
      setIsShowRegions(
        location?.state?.districtId === district?.id ||
          location?.state?.regionId === district?.id
      );
    }
  }, [
    subjectId?.regionId,
    subjectId?.districtId,
    location?.state?.regionId,
    location?.state?.districtId,
  ]);

  const isExpandable = useMemo(
    () => districtIds.includes(district?.id),
    [district, districtIds]
  );

  const getRegionRowsRows = useMemo(() => {
    const regions = getRegionsByParent(district?.id);
    const sortableColumns = columns.filter((col) => col.sortBy);

    return regions?.map((region) => {
      const values = sortableColumns.map((col) => [
        [col.sortBy as string],
        getIndicator(
          col?.data,
          region?.id,
          col?.id,
          undefined,
          col?.model,
          undefined,
          col?.dateCode
        )?.values?.[col?.indicator] || 0,
      ]);

      return {
        title: region?.attributeVals?.name,
        id: region.id,
        parentId: district?.id,
        ...Object.fromEntries(values),
      };
    });
  }, [columns, district?.id, getIndicator, getRegionsByParent]);

  const handleExpand = () => {
    setIsShowRegions(!isShowRegions);
  };

  const handleClickRow = () => {
    if (districtIds?.includes(district?.id)) {
      handleChangeSubjectId(undefined, district?.id);
      handleExpand();
    }
    return;
  };

  return (
    <>
      <TableRowStyled
        focused={district.id === subjectId?.regionId}
        onClick={handleClickRow}
      >
        <TableCellStyled
          sx={{
            pl: "3.5rem",
            fontSize: "1.375rem",
            position: "sticky",
            left: 0,
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <FoCoatsOfArms idFo={district?.id} />
              <LinkStyled
                to={
                  isCheckFuturePlan && !!isExpandable
                    ? `/region/${district?.id}/vdlProjects`
                    : ""
                }
                state={{ tabName }}
                isNotUnderline={!(isCheckFuturePlan && isExpandable)}
              >
                <div>{district?.title}</div>
              </LinkStyled>
            </Stack>

            {isExpandable && (
              <ExpandButton onClick={handleExpand} isExpanded={isShowRegions} />
            )}
          </Stack>
        </TableCellStyled>

        {columns
          .filter((column) => !["firstHeaderRow", "header"].includes(column.id))
          .map((col) => (
            <Fragment key={`${col?.id}_${col?.label}`}>
              {!col.subId && (
                <MainTableValue
                  focused={col.focused}
                  valueColor={getColorValue(col, district, getIndicator)}
                  level={REGION_LEVEL.DISTRICT}
                >
                  <Stack direction="row" spacing={2} alignItems="center">
                    {col?.isComparable && compare.value === COMPARE_TYPE.RF && (
                      <TableHL>
                        {
                          <CompareIcons
                            value={
                              getIndicator(
                                col?.data,
                                district?.id,
                                col?.id,
                                undefined,
                                col?.model,
                                undefined,
                                col?.dateCode
                              )?.values?.[modelKPI.indexes.factHighLowRF] ?? 0
                            }
                            isBig
                          />
                        }
                      </TableHL>
                    )}

                    <span>
                      {formatNumber(
                        getIndicator(
                          col?.data,
                          district?.id,
                          col?.helperId || col?.id,
                          undefined,
                          col?.model,
                          undefined,
                          col?.dateCode
                        )?.values?.[col?.indicator],
                        col.valuesUnit,
                        col?.fractionDigits
                      )}
                    </span>
                  </Stack>
                </MainTableValue>
              )}

              {col.subId && (
                <MainTableValue
                  focused={col.focused}
                  level={REGION_LEVEL.DISTRICT}
                >
                  <span>
                    {`${formatNumber(
                      getIndicator(
                        col?.data,
                        district?.id,
                        col?.helperId || col?.id,
                        undefined,
                        col?.model,
                        undefined,
                        col?.dateCode
                      )?.values?.[col?.indicator],
                      undefined,
                      0
                    )} / `}
                  </span>

                  <SubValueStyled
                    valueColor={
                      col?.isRiskColumn
                        ? getSubColor(col, district, getIndicator)
                        : null
                    }
                    isRisk={col?.isRiskColumn}
                  >
                    {formatNumber(
                      getIndicator(
                        col?.isRiskColumn ? col.subData : col?.data,
                        district?.id,
                        col?.subId,
                        undefined,
                        col?.isRiskColumn ? col.subModel : col?.model,
                        undefined,
                        col?.dateCode
                      )?.values?.[col?.subIndicator || col?.indicator],
                      undefined,
                      0
                    )}
                  </SubValueStyled>
                  {col?.isRiskColumn && (
                    <SubValueProjectStyled level={REGION_LEVEL.DISTRICT}>
                      {formatNumber(
                        getIndicator(
                          col.subData,
                          district?.id,
                          col?.subId,
                          undefined,
                          col.subModel,
                          undefined,
                          col?.dateCode
                        )?.values?.[col?.subShareId!!],
                        "%",
                        0
                      )}
                    </SubValueProjectStyled>
                  )}
                </MainTableValue>
              )}
            </Fragment>
          ))}
      </TableRowStyled>

      {isShowRegions &&
        sortBy(getRegionRowsRows, getComparator<any>(order, orderBy))?.map(
          (region) => (
            <RegionRow
              key={`${region.id}`}
              region={region}
              columns={columns}
              withProjects={withProjects}
              order={order}
              orderBy={orderBy}
            />
          )
        )}
    </>
  );
};
