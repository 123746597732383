import { Button, styled, TableRow } from "@mui/material";

import { TableCellStyled } from "@/features/RegionProjectsWidget/ui/RegionProjectsWidget.styled";

export const TableRowHeader = styled(TableRow)(({ theme }) => ({
  height: "3.5rem",
}));

export const TableRowBody = styled(TableRow)(({ theme }) => ({
  minHeight: "4rem",
  "& > .MuiTableCell-root": {
    padding: " 0.75rem 1rem",
    verticalAlign: "top",
  },
  "&:nth-of-type(-n+4)": {
    // цвет подложки для первых 4-х строк
    background: theme.palette.dropdownWidget.baseBodyCell,
  },
  "&:nth-of-type(n+5) > .MuiTableCell-root": {
    color: theme.palette.text.unitText,
  },
  "&:last-child > .MuiTableCell-root": {
    borderBottom: "none",
  },
}));

export const TableCellBody = styled(TableCellStyled)<{
  changeColor?: boolean;
  show?: boolean;
}>(({ theme, changeColor, show }) => ({
  fontSize: theme.spacing(2.25),
  lineHeight: theme.spacing(2.5),
  ...(changeColor && {
    color: theme.palette.text.primary,
    fontSize: theme.spacing(2.5),
    lineHeight: theme.spacing(2.5),
    fontWeight: 600,
  }),
  "& > span": {
    display: "block",
    ...(!show && {
      display: "-webkit-box",
      overflow: "hidden",
      textOverflow: "ellipsis",
      WebkitLineClamp: "3",
      WebkitBoxOrient: "vertical",
    }),
    ...(changeColor && {
      color: theme.palette.text.primary,
      fontSize: theme.spacing(2.5),
      lineHeight: theme.spacing(3),
      fontWeight: 600,
    }),
  },
}));

export const RowButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  textDecoration: "underline",
  "& > span": {
    fontWeight: 500,
    fontSize: theme.spacing(2.5),
    lineHeight: theme.spacing(3),
  },
  "&:hover": {
    textDecoration: "underline",
    background: "transparent",
  },
}));
