import { Button, styled, ToggleButtonGroup } from "@mui/material";

export const ButtonStyled = styled(Button)(({ theme }) => ({
  minWidth: theme.spacing(5),
  minHeight: theme.spacing(5),
  padding: theme.spacing(),
  ...theme.mixins.border(),
}));

export const ToggleButtonGroupStyled = styled(ToggleButtonGroup)(
  ({ theme }) => ({
    boxSizing: "border-box",
    height: theme.spacing(5),
    "& .MuiButtonBase-root": {
      color: theme.palette.button.icon,
      textTransform: "none",
      "&.Mui-selected": {
        backgroundColor: theme.palette.button.selected,
        color: theme.palette.text.onAccent,
        "&:hover": {
          backgroundColor: theme.palette.button.selectedHover,
        },
        "&.MuiToggleButtonGroup-grouped": {
          border: 0,
          borderRadius: theme.spacing(1),
        },
      },
      "&.MuiToggleButtonGroup-grouped": {
        border: 0,
      },
    },
  })
);
