import { styled } from "@mui/material";

import { colorBasedOnValue } from "@/pages/MainPage/lib/helpers";

export const ValueColor = styled("span")<{
  selected?: boolean;
  valueColor?: number | null;
}>(({ theme, selected = false, valueColor = null }) => ({
  color: colorBasedOnValue(theme, valueColor, selected),
}));
