import LogoutIcon from "@mui/icons-material/Logout";
import { actions as tokenActions } from "@store/redux/slices/auth/tokenSlice";
import { actions as userActions } from "@store/redux/slices/auth/userSlice";
import { FC } from "react";

import { useAppDispatch, useAppSelector } from "@/store/redux/hooks";

import {
  AppBarUserMenuWrapper,
  AppBarUserName,
  LogoutButton,
} from "./AppBarUserMenu.styled";
import { getCovertingFullname } from "./lib/constants";

export const AppBarUserMenu: FC = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth.user);
  const userName = user?.name ? getCovertingFullname(user.name) : "";

  const handleClick = () => {
    dispatch(tokenActions.logout());
    dispatch(userActions.logout());
  };

  return (
    <AppBarUserMenuWrapper>
      <AppBarUserName>{userName}</AppBarUserName>

      <LogoutButton onClick={handleClick} disableRipple>
        <LogoutIcon />
      </LogoutButton>
    </AppBarUserMenuWrapper>
  );
};
