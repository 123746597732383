import { olapRequestDimensionData } from "@/store/api/core";
import { MODEL_IDS, MODELS } from "@/store/scheme/olap";

export const getPeriodFilterData = () => {
  const model = MODELS[MODEL_IDS.KPI_SOC_ECONOMY];

  return olapRequestDimensionData(
    model.dimId,
    {
      modelUuid: model.uuid,
      dimensionId: model.dataSummaryId,
      includeAttributesByCodes: ["year", "report_period", "visibility"],
      dataRequestCaching: true,
    },
    "f5227cdc-8d0a-45f4-9495-71906f7b1737"
  );
};
