import RF_Map from "@assets/RF_Map.svg";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";

import { useTabs } from "@/app/hooks/useTabs";
import { Map } from "@/features/Map/ui/Map";
import { MapLegend } from "@/features/Map/ui/MapLegend/MapLegend";
import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { AchievementsTabCard } from "@/shared/AchievementTabCard/ui/AchievementsTabCard";
import { DynamicsTabCard } from "@/shared/DynamicsTabCard/ui/DynamicsTabCard";
import { Footer } from "@/shared/Footer/ui/Footer";
import { FooterHighLow } from "@/shared/Footer/ui/subComponents/FooterHighLow";
import { REGION_COUNTRY_CODE } from "@/shared/lib/constants";
import { useAppSelector } from "@/store/redux/hooks";

import { useGetVldData } from "../hooks/useGetVldData";
import { useMapTabs } from "../hooks/useMapTabs";
import { getTargetId, onBackLink } from "../lib/helpers";
import { TargetId } from "../lib/types";
import {
  BackLink,
  MainGrid,
  MapGrid,
  MapWrapper,
  MiniMap,
  VdlWrapper,
} from "./MainPage.styled";
import { VDLMain } from "./VDL/VDLMain";

export const MainPage = () => {
  const navigate = useNavigate();
  const { regionId, districtId, selectedDistrictId } = useParams<
    "regionId" | "districtId" | "selectedDistrictId"
  >();

  const { getRegionsByParent } = useRegions();
  const { getMapTabsData } = useMapTabs();
  const { enabled: achEnabled } = useAppSelector(
    (state) => state.controls.achievement
  );
  const { activeTab, updateCurrentTab } = useTabs();

  const regions = getRegionsByParent(districtId!);

  const [selectedSubjectId, setSelectedSubjectId] = useState<TargetId>(
    regionId || selectedDistrictId || districtId || REGION_COUNTRY_CODE
  );

  const targetId = getTargetId(false, selectedSubjectId, regionId, districtId);

  const tabs = useMemo(
    () => getMapTabsData(targetId),
    [getMapTabsData, targetId]
  );

  const { vdlValues, vdlLabels } = useGetVldData({
    districtId,
    selectedSubjectId,
    regionId,
    regions,
  });

  const onClickOnMiniMap = (id: TargetId) => {
    setSelectedSubjectId(id);
    navigate("/");
  };

  const isShowBackLink = districtId
    ? regions?.find(({ id }) => id === selectedSubjectId)
    : selectedSubjectId !== REGION_COUNTRY_CODE;

  return (
    <>
      <MainGrid>
        {achEnabled && (
          <AchievementsTabCard
            selectedSubjectId={selectedSubjectId}
            selectedTabId={activeTab}
            setSelectedTabId={updateCurrentTab}
            tabs={tabs}
            targetId={targetId}
          />
        )}

        {!achEnabled && (
          <DynamicsTabCard
            tabs={tabs}
            selectedSubjectId={selectedSubjectId}
            selectedTabId={activeTab}
            setSelectedTabId={updateCurrentTab}
          />
        )}
        <MapGrid>
          <VdlWrapper>
            <VDLMain
              id={selectedSubjectId}
              selectedTabId={activeTab}
              data={tabs.find((tab) => tab.id === activeTab)}
              vdlValues={vdlValues}
              value={vdlValues[selectedSubjectId]?.value}
            />

            <MapLegend selectedTabId={activeTab} />
          </VdlWrapper>

          <MapWrapper>
            <Map
              vdlValues={vdlValues}
              vdlLabels={vdlLabels}
              selectedSubjectId={selectedSubjectId}
              setSelectedSubjectId={setSelectedSubjectId}
              selectedTabId={activeTab}
            />

            {isShowBackLink && (
              <BackLink
                onClick={() =>
                  onBackLink(
                    setSelectedSubjectId,
                    navigate,
                    districtId || REGION_COUNTRY_CODE,
                    districtId,
                    selectedDistrictId
                  )
                }
              >
                {`Вернуться к данным по ${districtId ? "ФО" : "РФ"}`}
              </BackLink>
            )}
            {districtId && (
              <MiniMap onClick={() => onClickOnMiniMap(REGION_COUNTRY_CODE)}>
                <img src={RF_Map} alt="RF_Map" />
              </MiniMap>
            )}
          </MapWrapper>
        </MapGrid>
      </MainGrid>

      {!achEnabled && (regionId || selectedDistrictId || districtId) && (
        <Footer>
          <FooterHighLow />
        </Footer>
      )}
    </>
  );
};
