import { useCallback } from "react";

import { useAppDispatch, useAppSelector } from "@/store/redux/hooks";

import { extraActionsKpiGraph } from "../model/slices/kpiGraphSlice";

export const useKpiGraph = () => {
  const dispatch = useAppDispatch();
  const { data: kpiGraphData, fetching: kpiGraphFetching } = useAppSelector(
    (state) => state.regions.kpiGraph
  );
  const requestKpiGraph = useCallback(
    (regionId: string, district: any, indicatorId: string) => {
      dispatch(
        extraActionsKpiGraph.get({
          regionId,
          districtId: district?.id,
          indicatorId,
        })
      );
    },
    []
  );
  return { requestKpiGraph, kpiGraphData, kpiGraphFetching };
};
