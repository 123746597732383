import { useCallback } from "react";

import { useAppDispatch, useAppSelector } from "@/store/redux/hooks";

import { extraActionsKkt } from "../model/kktSlice";

export const useKkt = () => {
  const { data, kktPasport, fetching } = useAppSelector(
    (state) => state.regions.kkt
  );
  const dispatch = useAppDispatch();

  const requestKkt = useCallback(
    (regionId: string, dataSummaryId: string, district: any) => {
      if (regionId) {
        dispatch(
          extraActionsKkt.getKkt({
            regionId,
            districtId: district?.id,
            dataSummaryId,
          })
        );
      }
    },
    [dispatch]
  );

  return {
    data,
    kktPasport,
    requestKkt,
    fetching,
  };
};
