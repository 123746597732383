import { useCallback } from "react";
import { useParams } from "react-router";

import { extraActionsTotalIndicators } from "@/shared/Table/model/slice/totalIndicatorsSlice";
import { useAppDispatch } from "@/store/redux/hooks";

export const useRegionGraphs = () => {
  const dispatch = useAppDispatch();
  const { id: regionId = "8" } = useParams<"id">();

  const requestGraphsData = useCallback(
    (currentAction: "getGraphData" | "getIndicatorsGraphData") => {
      dispatch(extraActionsTotalIndicators[currentAction]({ regionId }));
    },
    [regionId]
  );

  return { requestGraphsData };
};
