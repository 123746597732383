import { styled } from "@mui/material";

export const IconWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  boxSizing: "border-box",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  height: 34,
  padding: theme.spacing(),
  paddingRight: 0,
  pointerEvents: "none",
}));
