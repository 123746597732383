import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";

import { infoField } from "../../../store/scheme/common";
import {
  clearNotificationsFx,
  handleNotificationsClick,
  notificationsFx,
} from "../lib/helpers";
import { IUpdateNotification } from "../lib/types";
import {
  DialogContentStyled,
  DialogStyled,
  DialogTitleStyled,
  IconButtonStyled,
} from "./Notifications.styled";

export const Notifications = () => {
  const [notifications, setNotifications] = useState<IUpdateNotification>({
    show: true,
  });

  useEffect(() => {
    clearNotificationsFx();
    notificationsFx(setNotifications);
  }, [clearNotificationsFx, notificationsFx]);

  if (!notifications.show) {
    return null;
  }

  return (
    <DialogStyled open={notifications.show}>
      <DialogTitleStyled>
        <span>Последнее обновление</span>
        <IconButtonStyled
          size="small"
          aria-label="close"
          onClick={() => handleNotificationsClick(setNotifications)}
        >
          <CloseIcon />
        </IconButtonStyled>
      </DialogTitleStyled>

      <DialogContentStyled>{infoField}</DialogContentStyled>
    </DialogStyled>
  );
};
