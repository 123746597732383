export const manufacturing =
  "Выпуск товаров и услуг по базовым видам экономической деятельности";
export const manufacturingIndicators = [
  "1",
  "33",
  "34",
  "35",
  "36",
  "37",
  "39",
  "40",
  "41",
  "42",
  "38",
];
