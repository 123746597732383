import { REGION_COUNTRY_CODE } from "@/shared/lib/constants";
import { getDatePartIndicator, prepareAttributes } from "@/shared/lib/heplers";
import {
  DATE_PART,
  IAttributeVals,
  IOlapResponseDimensionItemDim,
  IOlapResponseDimensionItemDimWithVals,
  IPeriodAttributeVals,
  IPreparedIndicatorAttributeVals,
  IProjectAttributeVals,
  IProjectIndicatorAttributeVals,
  IRowDateCode,
  MODEL_IDS,
  MODELS,
  RATE,
} from "@/store/scheme/olap";

import {
  HeaderColumns,
  IMainDataProject,
  IProject,
  VALUE_COLOR,
} from "./types";

export const getColorValue = (
  column: HeaderColumns,
  region: any,
  getIndicator: any,
  isRfRow?: boolean
): VALUE_COLOR => {
  return (column?.label !== "Место" && column?.mainColumn) ||
    column?.isRiskColumn
    ? (getIndicator(
        column?.data,
        isRfRow ? REGION_COUNTRY_CODE : region?.id,
        isRfRow ? column?.helperId || column?.id : column?.id,
        undefined,
        column?.model
      )?.values?.[column?.indicatorColor!!] as unknown as VALUE_COLOR)
    : VALUE_COLOR.DEFAULT;
};

export const prepareProjects = (
  projects: IOlapResponseDimensionItemDim<IProjectAttributeVals>[]
) => {
  return projects.reduce((prev, project) => {
    prev[project.id] = {
      attributeVals: prepareAttributes<IProjectAttributeVals>(
        project.attributeVals
      ),
      indicators: {},
    };
    return prev;
  }, {} as { [projectId: string]: IProject });
};

export const prepareResponseTotalIndicator = (
  response: Partial<Record<MODEL_IDS, IOlapResponseDimensionItemDimWithVals[]>>
) =>
  (Object.keys(response) as MODEL_IDS[]).reduce(
    (modelsMap, modelId) => {
      const model = MODELS[modelId];

      modelsMap[modelId] = response[modelId]!.reduce((prev, row) => {
        const { dimId, indexes } = model;
        const dateCode = row.code;
        const indicatorId = row.dims[dimId].code;
        const date = (row?.attributeVals as unknown as IPeriodAttributeVals)
          ?.REPORT_DATE;
        const prevDate = (row?.attributeVals as unknown as IPeriodAttributeVals)
          ?.PREVIOUS_DATE;
        const year = (row?.attributeVals as unknown as IPeriodAttributeVals)
          ?.YEAR;

        const item = row.dims[dimId];

        if (!prev[indicatorId]) {
          prev[indicatorId] = {};
        }

        prev[indicatorId]![dateCode] = {
          id: item.id,
          code: item.code,
          date,
          prevDate,
          year: year ? `${year} г.` : undefined,
          level: item.level,
          values: Object.keys(indexes).reduce((prev, curr) => {
            const index = indexes[curr as keyof typeof indexes];
            prev[index] = item.indicatorVals[index]?.sum;
            return prev;
          }, {} as { [sumIndicatorId: string]: number }),
          attributeVals: prepareAttributes<IPreparedIndicatorAttributeVals>(
            item.attributeVals
          ),
        };

        const fact =
          "fact" in indexes &&
          prev[indicatorId]![dateCode]!.values?.[indexes.fact];

        if (["10", "13", "16", "19", "22"].includes(indicatorId) && fact) {
          prev[indicatorId]![dateCode]!.rate =
            fact < 100 ? RATE.LOW : RATE.HIGH;
        }

        return prev;
      }, {} as { [modelId: string]: any });

      return modelsMap;
    },
    {} as {
      [regionId: string]: {
        [modelId: string]: IRowDateCode<IPreparedIndicatorAttributeVals>;
      };
    }
  );

export const prepareResponseIndicators = (
  response: IOlapResponseDimensionItemDimWithVals<IAttributeVals>[],
  initialObject: { [p: string]: IProject }
) => {
  const model = MODELS[MODEL_IDS.PROJECT_PASSPORT];

  return response.reduce((prev, row) => {
    const projectId = row.dims[model.dataProjectId].code;

    if (!prev[projectId]) return prev;

    const projectIndicator = row.dims[model.dimId].code;
    const date = (
      row.dims[model.dataPeriodId]
        .attributeVals as unknown as IPeriodAttributeVals
    ).REPORT_DATE;
    const year = (
      row.dims[model.dataPeriodId]
        .attributeVals as unknown as IPeriodAttributeVals
    ).YEAR;

    const item = row.dims[model.dimId];

    prev[projectId].indicators[projectIndicator] = {
      id: item.id,
      code: item.code,
      date,
      year: year ? `${year}` : undefined,
      level: item.level,
      values: Object.keys(model.indexes).reduce((prev, curr) => {
        const index = model.indexes[curr as keyof typeof model.indexes];
        prev[index] = item.indicatorVals[index]?.sum;
        return prev;
      }, {} as { [sumIndicatorId: string]: number }),
      attributeVals: prepareAttributes<IProjectIndicatorAttributeVals>(
        item.attributeVals
      ),
    };

    return prev;
  }, initialObject);
};

export const takeProjectsByPeriod = (
  column: HeaderColumns,
  project: IMainDataProject,
  isRisk?: boolean
) => {
  const dateCode = column?.dateCode;
  const datePart = dateCode ? DATE_PART.YEAR : DATE_PART.QUARTER;
  return getDatePartIndicator(
    column?.[isRisk ? "riskData" : "projectsData"]?.[
      project?.attributeVals?.SHORT_NAME
    ]?.[isRisk ? column?.projectId || "0" : column?.id],
    datePart,
    dateCode
  );
};

export const getProjectColorValue = (
  column: HeaderColumns,
  project: IMainDataProject,
  getIndicator: any
) => {
  let valueColor;
  if (column?.helperId) {
    valueColor = getIndicator(
      column?.data,
      project?.attributeVals?.id_subject,
      project?.attributeVals?.SHORT_NAME,
      undefined,
      column?.model
    )?.values?.[column?.indicatorColor!!];
  } else {
    valueColor = takeProjectsByPeriod(column, project)?.values?.[
      column?.indicatorColor!!
    ];
  }
  return column?.mainColumn ? valueColor : VALUE_COLOR.DEFAULT;
};

export const getSubColor = (
  column: HeaderColumns,
  region: any,
  getIndicator: any,
  isRfRow?: boolean
) => {
  return getIndicator(
    column?.subData,
    isRfRow ? REGION_COUNTRY_CODE : region?.id,
    column?.subId,
    undefined,
    column?.subModel
  )?.values?.[column?.indicatorColor!!] as unknown as VALUE_COLOR;
};
