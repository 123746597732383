import React from "react";

import {
  BasicPage,
  BasicSection,
} from "@/pages/RegionPage/ui/RegionPage.styled";
import { TopBar } from "@/shared/TopBar/ui/TopBar";

import { FaqContent } from "./FaqContent/ui/FaqContent";

export const Faq = () => {
  return (
    <BasicPage>
      <BasicSection stretch>
        <TopBar title="Вопросы и ответы" back={-1} />

        <FaqContent />
      </BasicSection>
    </BasicPage>
  );
};
