import {
  getOperand,
  getOperands,
  olapRequestData2,
  olapRequestDimensionData,
} from "@store/api/core";
import {
  FILTER_OPERAND_TYPE,
  FILTER_TYPE,
  IProjectAttributeVals,
  MODEL_IDS,
  MODELS,
} from "@store/scheme/olap";

export const getGraphData = ({ regionIds }: { regionIds: string[] }) => {
  const model = MODELS[MODEL_IDS.KPI_SOC_ECONOMY];

  const dimensions = [
    {
      id: model.dataPeriodId,
      includeItems: true,
      includeAttributesByCodes: ["YEAR", "REPORT_DATE"],
    },
    {
      id: model.dataRegionId,
    },
    {
      id: model.dimId,
      includeItems: true,
      includeAttributesByCodes: ["short_name"],
    },
    {
      id: model.dataActualId,
    },
    {
      id: model.dataSummaryId,
    },
  ];

  const indicators = {
    id: model.indicatorId,
    items: [
      { id: model.indexes.fact },
      { id: model.indexes.plan },
      { id: model.indexes.factChangeAppg },
      { id: model.indexes.colorGraph },
    ],
  };

  const operands = getOperands([
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      dim_id: model.dataPeriodId,
      showLeafsOnly: false,
      showChildren: false,
      levels: [1, 2],
      showAllLevelElements: false,
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataRegionId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: regionIds,
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dimId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: ["1", "2", "3", "127"],
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      dim_id: model.dataActualId,
      showLeafsOnly: false,
      showChildren: false,
      levels: [1],
      showAllLevelElements: false,
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataSummaryId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: ["0"],
        },
      ],
    },
  ]);

  return olapRequestData2(
    dimensions.map(({ id }) => id),
    model.indicatorId,
    {
      modelUuid: model.uuid,
      includeGaps: false,
      indicatorId: model.indicatorId,
      dimensions,
      indicators,
      dataFilter: {
        type: FILTER_TYPE.AND,
        version: 1,
        operands,
      },
    },
    "cd20d249-16dc-4e98-9c1d-08175a87b45f"
  );
};

export const getIndicatorsGraphData = ({
  regionIds,
}: {
  regionIds: string[];
}) => {
  const model = MODELS[MODEL_IDS.PROJECT_PASSPORT];

  const dimensions = [
    {
      id: model.dataPeriodId,
      includeItems: true,
      includeAttributesByCodes: ["REPORT_DATE"],
    },
    { id: model.dataRegionId },
    { id: model.dataProjectId },
    { id: model.dimId },
    { id: model.dataActualId },
    { id: model.dataSummaryId },
  ];

  const indicators = {
    id: model.indicatorId,
    items: [
      { id: model.indexes.fact },
      { id: model.indexes.plan },
      { id: model.indexes.percent },
      { id: model.indexes.color },
    ],
  };

  const operands = getOperands([
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataRegionId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: regionIds,
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataProjectId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: ["0"],
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      dim_id: model.dimId,
      showLeafsOnly: false,
      showChildren: false,
      levels: [1],
      showAllLevelElements: false,
    },
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      dim_id: model.dataActualId,
      showLeafsOnly: false,
      showChildren: false,
      levels: [1],
      showAllLevelElements: false,
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataSummaryId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: ["0"],
        },
      ],
    },
  ]);

  return olapRequestData2(
    dimensions.map(({ id }) => id),
    model.indicatorId,
    {
      includeGaps: false,
      dataRequestCaching: true,
      modelUuid: model.uuid,
      indicatorId: model.indicatorId,
      dimensions,
      indicators,
      dataFilter: {
        type: FILTER_TYPE.AND,
        version: 1,
        operands,
      },
    },
    "356cc6cc-0e1c-4f75-8c9f-0e8d0a6d4d00"
  );
};

export const getProjects = async ({
  regionIds = [],
}: {
  regionIds?: string[];
}) => {
  const model = MODELS[MODEL_IDS.PROJECT_PASSPORT];

  const projects = await olapRequestDimensionData<IProjectAttributeVals>(
    model.dataProjectId,
    {
      modelUuid: model.uuid,
      dimensionId: model.dataProjectId,
      includeAttributesByCodes: [
        "name",
        "implementation_period",
        "code",
        "NAME",
        "SHORT_NAME",
        "SORT_ORDER",
      ],
      dataFilter: {
        type: FILTER_TYPE.EQ,
        operands: [
          getOperand({
            type: FILTER_OPERAND_TYPE.DIM,
            id: model.dataProjectId,
            attributeCode: "id_subject",
          }),
          getOperand({
            type: FILTER_OPERAND_TYPE.TEXT,
            values: regionIds,
          }),
        ],
      },
    }
  );

  const dimIds = [
    model.dataRegionId,
    model.dimId,
    model.dataPeriodId,
    model.dataProjectId,
    model.dataIndicatorsId,
  ];

  const dimensions = [
    {
      id: model.dimId,
      includeItems: true,
      includeAttributesByCodes: ["code", "NAME", "SHORT_NAME", "SORT_ORDER"],
    },
    {
      id: model.dataPeriodId,
      includeItems: true,
      includeAttributesByCodes: [
        "code",
        "name",
        "NAME",
        "SHORT_NAME",
        "SORT_ORDER",
      ],
    },
    {
      id: model.dataRegionId,
      includeItems: true,
      includeAttributesByCodes: ["code", "NAME", "SHORT_NAME", "SORT_ORDER"],
    },
    {
      id: model.dataProjectId,
      includeItems: true,
      includeAttributesByCodes: [
        "code",
        "name",
        "NAME",
        "SHORT_NAME",
        "SORT_ORDER",
      ],
    },
    {
      id: model.dataIndicatorsId,
      includeItems: true,
      includeAttributesByCodes: [
        "code",
        "NAME",
        "SHORT_NAME",
        "SORT_ORDER",
        "name",
        "short_name",
        "unit_measure",
        "type_ind_id",
        "type_ind_unit_measure",
      ],
    },
  ];

  const operands = getOperands([
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      dim_id: model.dataPeriodId,
      showLeafsOnly: false,
      showChildren: false,
      levels: [2],
      showAllLevelElements: false,
    },
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      dim_id: model.dataRegionId,
      showLeafsOnly: false,
      showChildren: false,
      levels: [0, 1, 2],
      showAllLevelElements: false,
    },
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      dim_id: model.dataProjectId,
      showLeafsOnly: false,
      showChildren: false,
      levels: [0, 1],
      showAllLevelElements: false,
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataActualId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: ["2"],
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      dim_id: model.dimId,
      showLeafsOnly: false,
      showChildren: false,
      levels: [0, 1],
      showAllLevelElements: false,
    },
  ]);

  const data = await olapRequestData2(
    dimIds,
    model.indicatorId,
    {
      modelUuid: model.uuid,
      indicatorId: model.indicatorId,
      includeGaps: false,
      dimensions,
      indicators: {
        id: model.indicatorId,
        items: Object.values(model.indexes).map((index) => ({
          id: index,
          aggregationFunction: "sum",
        })),
      },
      dataFilter: {
        type: FILTER_TYPE.AND,
        operands,
      },
    },
    "22222222-2222-2222-2222-222222222222"
  );

  return { data, projects };
};
