import { IFormatPathDefault, IFormPath } from "./types";

export const downloadFile = (filePath: string, fileName: string): void => {
  const link = document.createElement("A");

  link.setAttribute("download", fileName);
  link.setAttribute("href", filePath);
  link.click();
};

export const formationPath = ({
  fileType,
  fileName,
  quarter,
  year = "2022",
}: IFormPath) => {
  const basePath = `${process.env.PUBLIC_URL}/files`;

  if (fileType === "pdf") {
    // Берем данные из Отчетов (Reports.tsx)
    const fileQuarter: Record<string, string> = {
      "1": `0104${year}`,
      "2": `0107${year}`,
      "3": `0110${year}`,
      "4": `0101${+year + 1}`,
    };
    const folderQuarter = fileQuarter?.[quarter];

    return `${basePath}/${folderQuarter}/${fileName}_${folderQuarter}.${fileType}`;
  }
  return `${basePath}/management_report/${year}/${fileType}/${quarter}quarter/${fileName}.${fileType}`;
};

export const formationRfPath = ({
  fileName,
  year = "2023",
  fileType = "pptx",
}: IFormatPathDefault) => {
  const basePath = `${process.env.PUBLIC_URL}/files`;
  return `${basePath}/management_report/${year}/${fileType}/${fileName}.${fileType}`;
};
