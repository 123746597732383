import { combineReducers } from "@reduxjs/toolkit";

import { IIndicatorsReducer } from "@/shared/Table/lib/types";
import indicatorsSlice from "@/shared/Table/model/slice/indicatorsSlice";
import subjectIdSlice from "@/shared/Table/model/slice/subjectIdSlice";
import totalIndicatorsSlice from "@/shared/Table/model/slice/totalIndicatorsSlice";

const reducer = combineReducers<IIndicatorsReducer>({
  total: totalIndicatorsSlice.reducer,
  indicators: indicatorsSlice.reducer,
  subjectId: subjectIdSlice.reducer,
});

export default reducer;
