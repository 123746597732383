import { styled } from "@mui/material";

export const Container = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  height: "100%",
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(3, 6),
  gap: "12%",
}));

export const LineChartsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(11.75),
  width: "27%",
  color: theme.palette.text.tertiary,
}));
