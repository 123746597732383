import DynamicsHighFO from "@assets/svg_components/DynamicsHighFO";
import DynamicsHighRF from "@assets/svg_components/DynamicsHighRF";
import DynamicsLowFO from "@assets/svg_components/DynamicsLowFO";
import DynamicsLowRF from "@assets/svg_components/DynamicsLowRF";
import { useTheme } from "@mui/material";
import React from "react";

import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { COMPARE_TYPE } from "@/shared/CompareControl/lib/types";
import { GridCard } from "@/shared/GridCard/ui/GridCard";
import { formatNumber } from "@/shared/lib/heplers";
import { Sceleton } from "@/shared/Sceleton/ui/Sceleton";
import { useAppSelector } from "@/store/redux/hooks";

import { IndicatorCardProps } from "../../../lib/types";
import {
  BigValue,
  BigValueLabel,
  BigValueWrapper,
  CardContentWrapper,
  FactRF,
  FactValue,
  FactValueLabel,
  RFValue,
} from "./IndicatorCard.styled";

// Формируются табы при включенном свитчере Динамика на главной и на странице субъекта
// по РФ (hideChart) и субъектам данные отображаются по разному
export const IndicatorCard = ({
  tab,
  selected,
  hideChart,
  setSelectedTabId,
  tabFetching = false,
}: IndicatorCardProps) => {
  const { unit, fact, rf, fo } = tab;
  const theme = useTheme();
  const { compare } = useAppSelector((state) => state.controls);
  const { compareLabel } = useRegions();
  const highIcon =
    compare.value === COMPARE_TYPE.FO ? DynamicsHighFO : DynamicsHighRF;
  const lowIcon =
    compare.value === COMPARE_TYPE.FO ? DynamicsLowFO : DynamicsLowRF;

  if (tabFetching) {
    return <Sceleton type="circle" />;
  }

  return (
    <GridCard
      data={tab}
      selected={selected}
      setSelectedTabId={setSelectedTabId}
      className="goals-title fix-title"
      classNameContent="alignment"
    >
      {hideChart ? (
        <CardContentWrapper>
          <BigValue selected={selected}>{formatNumber(fact, unit, 0)}</BigValue>
          <BigValueLabel selected={selected}>Факт</BigValueLabel>
        </CardContentWrapper>
      ) : (
        <BigValueWrapper>
          <FactRF>
            <FactValue selected={selected}>
              {React.createElement(
                Number(
                  tab[compare.value === COMPARE_TYPE.FO ? "hlFO" : "hlRF"]
                ) < 0
                  ? lowIcon
                  : highIcon,
                {
                  style: {
                    height: 40,
                    width: 40,
                    marginRight: theme.spacing(1),
                  },
                }
              )}

              {formatNumber(fact, unit)}
            </FactValue>

            <FactValueLabel selected={selected}>Факт</FactValueLabel>
          </FactRF>

          <FactRF>
            <RFValue selected={selected}>
              {formatNumber(compare.value === COMPARE_TYPE.FO ? fo : rf, unit)}
            </RFValue>

            <FactValueLabel selected={selected}>{compareLabel}</FactValueLabel>
          </FactRF>
        </BigValueWrapper>
      )}
    </GridCard>
  );
};
