import { useCallback, useMemo } from "react";

import { useAppDispatch, useAppSelector } from "@/store/redux/hooks";

import { extraActionsNationalProjects } from "../model/nationalProjectsSlice";

export const useNationalProjects = () => {
  const { data, dataAll } = useAppSelector(
    (state) => state.regions.nationalProjects
  );
  const dispatch = useAppDispatch();

  const options = useMemo(
    () =>
      (dataAll ?? []).map((item) => ({
        label: item?.attributeVals?.short_name,
        value: item?.id,
      })),
    [dataAll]
  );

  const requestNationalProjects = useCallback(
    (regionId: string) => {
      if (regionId) {
        dispatch(extraActionsNationalProjects.get(regionId));
      }
    },
    [dispatch]
  );

  const requestNationalProjectsAll = useCallback(() => {
    dispatch(extraActionsNationalProjects.getAll());
  }, [dispatch]);

  return {
    data,
    requestNationalProjects,
    requestNationalProjectsAll,
    dataAll: dataAll || [],
    options,
  };
};
