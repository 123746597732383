import { useCallback } from "react";

import { useAppDispatch, useAppSelector } from "@/store/redux/hooks";

import { extraActionsBudgetGraph } from "../model/slices/budgetGraphSlice";

export const useBudgetGraph = () => {
  const dispatch = useAppDispatch();
  const { data: budgetGraphData, fetching: budgetGraphFetching } =
    useAppSelector((state) => state.regions.budgetGraph);

  const requestBudgetGraph = useCallback(
    (regionId: string, district: any, indicatorId: string) => {
      dispatch(
        extraActionsBudgetGraph.get({
          regionId,
          districtId: district?.id,
          indicatorId,
        })
      );
    },
    []
  );
  return { requestBudgetGraph, budgetGraphData, budgetGraphFetching };
};
