import { useCallback, useMemo } from "react";
import { useLocation } from "react-router";

import { extraActionsLeaders } from "@/features/VdlLeader/ui/Leader/model/leadersSlice";
import { extraActionsRegion } from "@/pages/RegionPage/model/slices/regionSlice";
import { COMPARE_TYPE } from "@/shared/CompareControl/lib/types";
import { REGION_COUNTRY_CODE } from "@/shared/lib/constants";
import { compareTitle, factHighLow } from "@/shared/lib/constants";
import { REGION_LEVEL } from "@/shared/lib/types";
import { useAppDispatch, useAppSelector } from "@/store/redux/hooks";

import { IIsCheckRfFoPage } from "../lib/types";

export const useRegions = () => {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth.user);
  const regionsSlice = useAppSelector((state) => state.regions.regions);
  const leadersSlice = useAppSelector((state) => state.regions.leaders);
  const regionId = useAppSelector((state) => state.controls.regions.id);
  const { compare } = useAppSelector((state) => state.controls);
  const compareLabel = compareTitle[compare.value];
  const indicatorHighLow = factHighLow[compare.value];

  const fetching = regionsSlice.fetching || leadersSlice.fetching;

  const allRegions = regionsSlice?.items ?? [];

  const country = useMemo(
    () =>
      regionsSlice.items?.find((region) => region.code === REGION_COUNTRY_CODE),
    [regionsSlice.items]
  );

  const allRegionIds = useMemo(
    () => allRegions?.map((region) => region.id) ?? [],
    [allRegions]
  );

  const allDistricts = useMemo(
    () =>
      regionsSlice.items
        ?.filter(
          (district) =>
            district?.level === REGION_LEVEL.DISTRICT &&
            !["96", "97"].includes(district.code)
        )
        .sort(
          (sortA, sortB) =>
            +sortA.attributeVals.code_olap - +sortB.attributeVals.code_olap
        ) ?? [],
    [regionsSlice.items]
  );

  const regions = useMemo(() => {
    // Получаем все регионы, если роль VIP без ограничений
    if (user?.isVIP && !user?.regions.length) {
      return regionsSlice.items;
    }
    // Получаем нужные субъекты по id или parentId
    const prepareRegions = regionsSlice.items?.filter((region) =>
      [...(user?.regions || [])].some(
        (id) => id === region.id || id === region.parentId[0]
      )
    );
    // Добавляем ФО для Cпециалистов
    if (!user?.isVIP && !user?.isAnalyticFK) {
      const districs = allDistricts?.filter((district) => {
        return prepareRegions?.some(
          (prepareRegion) => prepareRegion.parentId[0] === district.id
        );
      });
      prepareRegions?.push(...(districs || []));
    }
    // Добавляем РФ для ролей VIP с ограничением и специалистам
    if (!user?.isAnalyticFK && country) prepareRegions?.push(country);

    return prepareRegions;
  }, [
    user?.isVIP,
    user?.regions,
    user?.isAnalyticFK,
    regionsSlice.items,
    allDistricts,
    country,
  ]);

  const regionIds = useMemo(
    () => Array.from(new Set(regions?.map((region) => region.id))) ?? [],
    [regions]
  );

  const allDistrictIds = useMemo(
    () => allDistricts?.map((district) => district.id) ?? [],
    [allDistricts]
  );

  const districts = useMemo(
    () =>
      allDistricts?.filter((district) =>
        regions?.some((region) => region.parentId[0] === district.code)
      ),
    [allDistricts, regions]
  );

  const districtIds = useMemo(
    () => districts?.map((district) => district.id) ?? [],
    [districts]
  );

  const leaders = leadersSlice.items;

  const requestRegionsByDistrict = useCallback(
    (ids: string[]) => {
      dispatch<any>(extraActionsRegion.get(ids));
    },
    [dispatch]
  );

  const requestRegions = useCallback(() => {
    dispatch<any>(extraActionsLeaders.get());
  }, [dispatch]);

  const getDistrictIdByRegion = useCallback(
    (regionCode: string) =>
      regions?.find((region) => region.code === regionCode)?.parentId[0],
    [regions]
  );

  const getDistrictByRegion = useCallback(
    (regionCode: string) => {
      const districtId = getDistrictIdByRegion(regionCode);
      return districts?.find((district) => district.code === districtId);
    },
    [districts, getDistrictIdByRegion]
  );

  const getRegionsByParent = useCallback(
    (parentCode: string) =>
      allRegions?.filter((region) => region.parentId[0] === parentCode),
    [allRegions]
  );

  const arrRegions = useMemo(() => {
    return (allRegions ?? [])
      .filter((i) => i?.id !== REGION_COUNTRY_CODE)
      .map((region) => ({
        id: region?.id ?? "",
        name: region?.attributeVals?.name ?? "",
      }));
  }, [allRegions]);

  const indexCurrentRegion = useMemo(() => {
    return arrRegions.findIndex((item) => item.id === regionId);
  }, [arrRegions, regionId]);

  const getSubject = useMemo(() => {
    const districtId = getDistrictIdByRegion(regionId!);
    if (regionId && districtId) {
      return compare.value === COMPARE_TYPE.RF ? regionId : districtId;
    }
    return REGION_COUNTRY_CODE;
  }, [compare.value, getDistrictIdByRegion, regionId]);

  // Проверка нахождения на страницах РФ или ФО
  const isCheckRfFoPage: IIsCheckRfFoPage = useMemo(() => {
    const isCheckRegionPage = pathname.startsWith("/region");
    const isFO = isCheckRegionPage && districtIds?.includes(regionId!!);
    const isRF = isCheckRegionPage && regionId === REGION_COUNTRY_CODE;
    return {
      isAll: isRF || isFO,
      isRF,
      isFO,
    };
  }, [districtIds, regionId, pathname]);

  return {
    fetching,
    country,
    districts: districts ?? [],
    regions: regions ?? [],
    leaders: leaders ?? [],
    allRegions,
    allDistricts,
    arrRegions,
    indexCurrentRegion,
    allRegionIds,
    allDistrictIds,
    districtIds,
    regionIds,
    requestRegions,
    requestRegionsByDistrict,
    getDistrictByRegion,
    getDistrictIdByRegion,
    getRegionsByParent,
    getSubject,
    indicatorHighLow,
    compareLabel,
    isCheckRfFoPage,
  };
};
