import { useCallback, useMemo } from "react";
import { useParams } from "react-router";

import {
  extraActionsTabs,
  updateCurrentTabAction,
  updateGeneralTab,
} from "@/pages/MainPage/model/tabsSlice";
import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { useFuturePlan } from "@/shared/Table/hooks/useFuturePlan";
import { useAppDispatch, useAppSelector } from "@/store/redux/hooks";
import { TAB_ID } from "@/store/scheme/region";

import { useRouter } from "./useRouter";

export const useTabs = () => {
  const { id } = useParams<"id" | "tabId">();
  const { user } = useAppSelector((state) => state.auth.user);
  const { isCheckFuturePlan } = useFuturePlan();
  const { navigate } = useRouter();
  const dispatch = useAppDispatch();
  const { getDistrictByRegion } = useRegions();
  const { achievements, dynamics } = useAppSelector((state) => state.ui.tabs);
  const regionId = useAppSelector((state) => state.controls.regions.id);
  const { enabled } = useAppSelector((state) => state.controls.achievement);
  const { currentPeriod } = useAppSelector((state) => state.controls.period);
  const { activeTab, generalTab } = useAppSelector((state) => state.ui.tabs);

  const limitedForAnalystTabs = useCallback(
    (tabId: TAB_ID) => {
      const tabs = [TAB_ID.MANUFACTURING, TAB_ID.PROFIT, TAB_ID.EMPLOYED];
      if (isCheckFuturePlan) tabs.push(TAB_ID.INVESTMENT);
      const actualTabId = tabs.includes(tabId);
      return user?.isAnalyticFK && actualTabId;
    },
    [user?.isAnalyticFK, isCheckFuturePlan]
  );

  const checkPeriodByHideDate = useMemo(() => {
    return ["320220001", "320220002"].includes(currentPeriod);
  }, [currentPeriod]);

  const district = getDistrictByRegion(regionId!);

  const setUrlByTab = useCallback(
    (currentTab?: TAB_ID) => {
      let path =
        currentTab && currentTab !== TAB_ID.RATE
          ? `/region/${id}/${currentTab}`
          : `/region/${id}`;

      navigate(path);
    },
    [id, navigate]
  );

  const updateCurrentTab = (tab: TAB_ID) => {
    dispatch(updateCurrentTabAction(tab));
    dispatch(updateGeneralTab({ enabled, tab }));
  };

  const setDefaultTab = useCallback(
    (enabledValue: boolean) => {
      const isPresenceTab = [
        TAB_ID.MANUFACTURING,
        TAB_ID.PROFIT,
        TAB_ID.INVESTMENT,
        TAB_ID.EMPLOYED,
      ].includes(activeTab);
      let changeTab = isPresenceTab
        ? activeTab
        : generalTab[enabledValue ? "achieve" : "dynamic"];

      if (limitedForAnalystTabs(changeTab)) {
        changeTab = enabledValue ? TAB_ID.VDL_PROJECTS : TAB_ID.RISKS;
      }
      dispatch(updateCurrentTabAction(changeTab));
      dispatch(updateGeneralTab({ enabled: enabledValue, tab: changeTab }));
      return changeTab;
    },
    [activeTab, generalTab.achieve, generalTab.dynamic]
  );

  const receiptGoal = (tab: TAB_ID) => {
    let indicator = "";
    let altIndicator = "";

    switch (tab) {
      case TAB_ID.MANUFACTURING:
        indicator = "1";
        altIndicator = "13";
        break;

      case TAB_ID.PROFIT:
        indicator = enabled ? "2" : "132";
        altIndicator = "16";
        break;

      case TAB_ID.INVESTMENT:
        indicator = enabled ? "3" : "133";
        altIndicator = "19";
        break;

      case TAB_ID.EMPLOYED:
        indicator = enabled ? "127" : "4";
        altIndicator = "22";
        break;

      default:
        break;
    }
    return [indicator, altIndicator];
  };

  const isCheckPeriod = (indicator: string) => {
    return (
      !checkPeriodByHideDate || (checkPeriodByHideDate && indicator === "4")
    );
  };

  const requestAchievementsTabs = useCallback(
    (regionId: string, dist: any) => {
      if (regionId) {
        dispatch(
          extraActionsTabs.getTabsAchievements({
            regionId,
            districtId: dist?.id,
            dataSummaryId: currentPeriod,
          })
        );
      }
    },
    [currentPeriod, dispatch]
  );

  const requestMainAchievementsTabs = useCallback(
    (regionId: string, dist: any) => {
      if (regionId) {
        dispatch(
          extraActionsTabs.getTabsMainAchievements({
            regionId,
            districtId: dist?.id,
            dataSummaryId: currentPeriod,
          })
        );
      }
    },
    [currentPeriod, dispatch]
  );

  const requestDynamicsTabs = useCallback(
    (regionId: string, dist: any) => {
      if (regionId && dist) {
        dispatch(
          extraActionsTabs.getTabsDynamics({ regionId, districtId: dist.id })
        );
      }
    },
    [dispatch]
  );

  const requestCheckpointsAchievementsTabs = useCallback(
    (regionId: string) => {
      if (regionId && district) {
        dispatch(
          extraActionsTabs.getTabsCheckpointsAchievements({
            regionId,
            districtId: district?.id,
          })
        );
      }
    },
    [dispatch, district]
  );

  return {
    setDefaultTab,
    requestAchievementsTabs,
    requestMainAchievementsTabs,
    requestDynamicsTabs,
    requestCheckpointsAchievementsTabs,
    updateCurrentTab,
    achievements,
    dynamics,
    activeTab,
    setUrlByTab,
    checkPeriodByHideDate,
    receiptGoal,
    isCheckPeriod,
    limitedForAnalystTabs,
  };
};
