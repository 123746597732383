import { styled, Tooltip, TooltipProps } from "@mui/material";

import { colorBasedOnValue } from "@/pages/MainPage/lib/helpers";
import {
  CardList,
  CardListContent,
  CardListHeader,
  CardListResultCell,
  CardListTitle,
  CardListTitleText,
} from "@/shared/CardList/CardList.styled";
import { VALUE_COLOR } from "@/shared/Table/model/api/scheme";

interface ITooltipStyledProps extends TooltipProps {
  darkTheme?: boolean;
}

export const ToolTipStyled = styled(
  ({ className, darkTheme, children, ...props }: ITooltipStyledProps) => (
    <Tooltip {...props} classes={{ popper: className }} children={children} />
  )
)(({ theme, darkTheme }) => ({
  "& .MuiTooltip-tooltip": {
    fontSize: theme.spacing(1.8),
    fontWeight: theme.typography.fontWeightMedium,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.toolTip.background,
    color: theme.palette.toolTip.text,
    border: `1 px solid ${theme.palette.text.toolTip}`,
    boxShadow: darkTheme
      ? "0px 0px 13px 6px rgba(0, 0, 0, 0.6)"
      : "0px 0px 13px 1px rgba(0, 0, 0, 0.1)",
    "&:first-of-type": {
      textAlign: "center",
    },
  },
  "& .MuiTooltip-arrow": {
    color: theme.palette.toolTip.background,
  },
}));

const BudgetRowCompareFlexBasis = 100;

export const BudgetStyled = styled(CardList)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  position: "relative",
  "&::after": {
    display: "block",
    position: "absolute",
    content: '" "',
    backgroundColor: theme.palette.divider,
    width: 1,
    top: 0,
    bottom: 0,
    right: BudgetRowCompareFlexBasis + parseInt(theme.spacing(4)),
  },
}));

export const BudgetTitle = styled(CardListTitle)(({ theme }) => ({
  flex: "0",
  paddingRight: theme.spacing(2),
}));

export const BudgetTitleText = styled(CardListTitleText)({
  fontSize: 16,
});

export const BudgetRegionText = styled("div")<{ openWidget?: boolean }>(
  ({ theme, openWidget }) => ({
    maxWidth: theme.spacing(32),
    fontSize: theme.spacing(openWidget ? 3 : 2),
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    color: theme.palette.text.tertiary,
  })
);

export const BudgetDate = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(0.25),
}));

export const BudgetContent = styled(CardListContent)(({ theme }) => ({
  flexDirection: "column",
}));

export interface BudgetRowProps {
  level?: number;
  isOpenPopUp?: boolean;
}

export const BudgetRow = styled("div")<BudgetRowProps>(
  ({ theme, level, isOpenPopUp }) => ({
    ...(isOpenPopUp && { background: "#1E2731" }),
    display: "flex",
    flex: "0",
    alignItems: "stretch",
    justifyContent: "space-between",
    gap: theme.spacing(1),
    fontSize: isOpenPopUp ? 24 : theme.typography.fontSize,
    borderBottom: `1px solid ${theme.palette.table.dividerLight}`,
    fontWeight:
      level === 2
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightSemiBold,
  })
);

export const BudgetRowValue = styled("div")<{
  isColorGrey?: boolean;
  isCheckRf?: boolean;
}>(({ theme, isColorGrey, isCheckRf }) => ({
  display: "flex",
  flex: `0 0 ${isCheckRf ? 120 : 60}px`,
  alignItems: "center",
  padding: theme.spacing(2.5, 0.9),
  textAlign: "right",
  ...(isColorGrey && {
    color: theme.palette.text.grey,
    fontWeight: 500,
  }),
}));

export const BudgetResultCell = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
}));

export const BudgetRowTitle = styled(BudgetRowValue)<BudgetRowProps>(
  ({ theme, level }) => ({
    display: "flex",
    flex: "1 1 auto",
    flexWrap: "wrap",
    paddingLeft: theme.spacing(level === 2 ? 3 : 0),
    textAlign: "left",
    fontWeight:
      level === 2
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightSemiBold,
    "& > span.budget:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  })
);

export const BudgetUnit = styled("span")(({ theme }) => ({
  display: "inline",
  color: theme.palette.text.tertiary,
  margin: theme.spacing(0, 1),
  fontWeight: theme.typography.fontWeightRegular,
}));

export const BudgetRowDynamics = styled(BudgetRowValue, {
  shouldForwardProp: (prop) => !["isCenter"].includes(prop.toString()),
})<{ isCenter?: boolean }>(({ theme, isCenter }) => ({
  display: "flex",
  flexBasis: 120,
  justifyContent: isCenter ? "center" : "flex-start",
  gap: theme.spacing(),
}));

export const BudgetRowCompare = styled(BudgetRowValue)(({ theme }) => ({
  flexBasis: BudgetRowCompareFlexBasis,
  paddingLeft: theme.spacing(2),
  color: theme.palette.text.tertiary,
}));

export const BudgetRowCompareHeader = styled(BudgetRowCompare)(({ theme }) => ({
  marginRight: theme.spacing(-1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export const BudgetRowEmptyIcon = styled("div")(({ theme }) => ({
  padding: theme.spacing(1.5),
}));

export const BudgetContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  ".track": {
    borderRadius: theme.spacing(0.375),
    background: theme.palette.scrollbar.track,
    "&.vertical": {
      right: theme.spacing(0.625),
      bottom: theme.spacing(0.5),
      top: theme.spacing(0.5),
      zIndex: 100,
    },
    "&.horizontal": {
      right: theme.spacing(0.25),
      bottom: theme.spacing(0.25),
      left: theme.spacing(0.25),
      zIndex: 100,
    },
  },
  ".thumb": {
    cursor: "pointer",
    borderRadius: "inherit",
    background: theme.palette.scrollbar.thumb,
  },
  ".scroll-view": {
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      width: 0,
    },
  },
}));

export const BudgetWrapper = styled(CardList)(({ theme }) => ({
  position: "relative",
}));

export const BudgetRowsWrapper = styled("div")(({ theme }) => ({
  flex: "1 1 100%",
  overflowY: "auto",
  paddingRight: theme.spacing(8),
}));

export const BudgetHeader = styled(CardListHeader)(({ theme }) => ({
  paddingRight: theme.spacing(8),
}));

export const BudgetHeaderResultCell = styled(CardListResultCell)(
  ({ theme }) => ({
    paddingLeft: theme.spacing(5),
  })
);

export const BudgetPopUpButton = styled("span")(({ theme }) => ({
  marginLeft: "-" + theme.spacing(5),
}));
export const CompareValueStyled = styled("span")(({ theme }) => ({
  color: theme.palette.indicatorCard.tooltip,
  opacity: 0.5,
}));

export const DynamicColumn = styled("span")<{
  valueColor: VALUE_COLOR | null;
  isOpacity?: boolean;
}>(({ theme, valueColor, isOpacity }) => ({
  whiteSpace: "nowrap",
  color: colorBasedOnValue(theme, valueColor),
  ...(isOpacity && {
    opacity: 0.6,
  }),
}));
