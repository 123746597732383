import { useMemo } from "react";

import {
  GOALS_TABS,
  IDynamicTab,
  MAIN_TABS,
  PROJECTS_TABS,
} from "@/shared/GridCard/lib/types";

export const useFilteredTabs = (tabs: IDynamicTab[]) => {
  const tabsGroup = useMemo(() => {
    return {
      mainTabs: tabs.filter((t) => MAIN_TABS.includes(t.id)),
      goalsTabs: tabs.filter((t) => GOALS_TABS.includes(t.id)),
      projectsTabs: tabs.filter((t) => PROJECTS_TABS.includes(t.id)),
    };
  }, [tabs]);

  return {
    ...tabsGroup,
  };
};
