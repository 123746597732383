import { useTheme } from "@mui/material";
import { FC } from "react";

import { colorBasedOnValue } from "@/pages/MainPage/lib/helpers";
import { AchievementLevel } from "@/shared/AchievementLevel/ui/AchievementLevel";
import {
  PlanFact,
  Value,
  ValueWrapper,
} from "@/shared/DynamicsTabCard/ui/components/IndicatorCard/IndicatorCard.styled";
import { GridCard } from "@/shared/GridCard/ui/GridCard";
import { formatNumber } from "@/shared/lib/heplers";
import { Sceleton } from "@/shared/Sceleton/ui/Sceleton";
import { useAppSelector } from "@/store/redux/hooks";

import { IGoalsCardProps } from "../../../lib/types";
import { PlanFactWrapper } from "./GoalsCard.styled";

export const GoalsCard: FC<IGoalsCardProps> = ({
  setSelectedTabId,
  data: goalsData,
  selected,
  main = false,
  tabFetching = false,
}) => {
  const theme = useTheme();
  const { highlightWidget } = useAppSelector((state) => state.ui.tabs);
  const formationProps = main
    ? { gridArea: goalsData.gridArea }
    : { isHighlight: highlightWidget === "GoalsTabs" };

  if (tabFetching) {
    return <Sceleton type="circle" />;
  }

  return (
    <GridCard
      key={goalsData.id}
      noCloseButton
      data={goalsData}
      selected={selected}
      className="goals-title"
      setSelectedTabId={setSelectedTabId}
      {...formationProps}
    >
      <AchievementLevel
        unit="%"
        value={goalsData.value}
        color={colorBasedOnValue(theme, goalsData?.color || null, selected)}
      />

      <PlanFactWrapper>
        <ValueWrapper>
          <Value>{formatNumber(goalsData.plan, undefined, 1)}</Value>
        </ValueWrapper>
        <PlanFact>План</PlanFact>

        <Value>{formatNumber(goalsData.fact, undefined, 1)}</Value>
        <PlanFact>Факт</PlanFact>
      </PlanFactWrapper>
    </GridCard>
  );
};
