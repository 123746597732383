import { Button, styled } from "@mui/material";

import { ICheckpointsWrapperProps } from "@/shared/ProjectResultsGroup/lib/types";

import {
  ICheckpointProps,
  ICheckpointsInnerLineTodayProps,
  ICheckpointsInnerProps,
} from "../lib/types";

export const CheckpointsWrapper = styled("div")<ICheckpointsWrapperProps>(
  ({ theme, width }) => ({
    position: "relative",
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    maxWidth: `${width}px`,
    height: "100%",
  })
);

export const CheckpointsContainer = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
  overflow: "hidden",
  height: "inherit",
}));

export const CheckpointsInner = styled("div")<ICheckpointsInnerProps>(
  ({ theme, width, carusel }) => ({
    width: `${width}px`,
    transform: `translateX(${carusel}px)`,
    height: "0.75rem",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    borderRadius: "0.25rem",
    transition: "all .5s ease",
    left: 0,
    top: "50%",
  })
);

export const CheckpointsInnerLine = styled("span")(({ theme }) => ({
  left: 0,
  position: "absolute",
  display: "block",
  height: "100%",
  background: theme.palette.table.backgroundImg,
  width: "100%",
  borderRadius: theme.spacing(0.25),
}));

export const CheckpointsInnerLineToday = styled(
  "span"
)<ICheckpointsInnerLineTodayProps>(({ theme, width }) => ({
  left: 0,
  position: "absolute",
  display: "block",
  height: "100%",
  width: `${width}px`,
  zIndex: 103,
  background: theme.palette.chart.fill.primary,
  borderRight: "none",
  borderRadius: "0.25rem 0px 0px 0.25rem",
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  position: "absolute",
  top: "50%",
  transform: "translate(0, -50%)",
  boxSizing: "border-box",
  minWidth: "2.4rem",
  height: "4.75rem",
  background: theme.palette.button.selected,
  color: theme.palette.text.onAccent,
  border: "1px solid rgba(0, 0, 0, 0.1)",
  backdropFilter: "blur(7.5px)",
  borderRadius: "0.5rem",
  zIndex: 140,
  "&.Mui-disabled": {
    background:
      theme.palette.mode === "dark" ? "#46525C" : theme.palette.text.grey,
  },
}));

export const Checkpoint = styled("div")<ICheckpointProps>(
  ({ theme, left, width }) => ({
    position: "absolute",
    left: `${left}px`,
    top: "50%",
    display: "flex",
    alignItems: "center",
    maxWidth: `${width}px`,
    width: "100%",
    zIndex: 150,
    transform: "translate(0, -50%)",
  })
);

export const CheckpointDate = styled("div")(({ theme, color }) => ({
  position: "absolute",
  top: "-50px",
  left: 0,
  display: "flex",
  flexWrap: "wrap",
  alignItems: "end",
  fontSize: "1rem",
  fontWeight: 500,
  lineHeight: "130%",
  color: theme.palette.text.primary,
  "& > .date_plan": {
    "&:after": {
      content: "' / '",
      color: theme.palette.graphs.projectGraph,
    },
  },
  "& > .date_fact": {
    paddingLeft: "0.25rem",
    fontSize: "1.2rem",
    color: color,
  },
}));

export const CheckpointDote = styled("div")(({ theme, color }) => ({
  width: "1.75rem",
  height: "1.75rem",
  background:
    theme.palette.toolTip[
      theme.palette.mode === "dark" ? "background" : "text"
    ],
  display: "block",
  borderRadius: "50%",
  border: `0.25rem solid ${color}`,
}));

export const CheckpointName = styled("div")(({ theme }) => ({
  width: "100%",
  maxWidth: "15.75rem",
  position: "absolute",
  textAlign: "left",
  top: theme.spacing(6.25),
  left: 0,
  color: theme.palette.text.primary,
  fontSize: "1rem",
  lineHeight: "1.25rem",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: "4",
  WebkitBoxOrient: "vertical",
}));

export const menuStyle = (theme: any) => ({
  minWidth: "27rem",
  "& .MuiList-root": {
    background:
      theme.palette.mode === "dark"
        ? theme.palette.input.background
        : theme.palette.background.darkTheme,
    border: `1px solid ${theme.palette.input.border}`,
    borderRadius: theme.spacing(),
    padding: theme.spacing(),
  },
});
