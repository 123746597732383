import { useCallback } from "react";

import { getDatePartIndicator } from "@/shared/lib/heplers";
import { useAppSelector } from "@/store/redux/hooks";
import {
  DATE_PART,
  IPreparedIndicatorAttributeVals,
  IRowDatePart,
  MODEL_IDS,
} from "@/store/scheme/olap";

import { ACHIEVEMENT_STATUS } from "../lib/types";

export const useData = () => {
  const { achievement } = useAppSelector((state) => state.controls);

  const getIndicator = useCallback(
    (
      modelData:
        | {
            [regionId: string]: IRowDatePart<IPreparedIndicatorAttributeVals>;
          }
        | undefined,
      regionId: string,
      indicatorId: string,
      achievementStatus?: ACHIEVEMENT_STATUS,
      modelId = MODEL_IDS.KPI_SOC_ECONOMY,
      prevPeriod?: boolean,
      dateCodeInitial?: string,
      datePartInitial?: DATE_PART
    ) => {
      if (!modelData?.[regionId]?.[indicatorId]) return undefined;

      let datePart: DATE_PART | undefined;
      let dateCode: string | undefined;
      let getPrevPeriod: boolean = prevPeriod || false;

      if (modelId === MODEL_IDS.KPI_SOC_ECONOMY) {
        if (["1", "2", "3", "127"].includes(indicatorId) && dateCodeInitial) {
          dateCode = dateCodeInitial;
          datePart = DATE_PART.YEAR;
        } else {
          switch (indicatorId) {
            case "1":
              if (achievementStatus === ACHIEVEMENT_STATUS.DISABLED) {
                datePart = DATE_PART.MONTH;
              } else if (
                achievement.enabled ||
                achievementStatus === ACHIEVEMENT_STATUS.ENABLED
              ) {
                datePart = DATE_PART.QUARTER;
              } else {
                datePart = DATE_PART.MONTH;
              }
              break;
            case "2":
              datePart = DATE_PART.QUARTER;
              break;
            case "3":
              datePart = DATE_PART.QUARTER;
              break;
            case "4":
              datePart = DATE_PART.QUARTER;
              break;
            case "127":
              datePart = DATE_PART.QUARTER;
              break;
          }
        }
      }

      if (modelId === MODEL_IDS.PROJECT_PASSPORT) {
        datePart = DATE_PART.QUARTER;
        if (dateCodeInitial) {
          dateCode = dateCodeInitial;
          datePart = DATE_PART.YEAR;
        }
      }

      return {
        ...getDatePartIndicator(
          modelData[regionId][indicatorId],
          datePart,
          dateCode,
          getPrevPeriod
        ),
      };
    },
    [achievement.enabled]
  );

  return {
    getIndicator,
  };
};
