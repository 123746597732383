import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack/Stack";
import { MODEL_IDS, MODELS } from "@store/scheme/olap";
import { FC, Fragment, useState } from "react";
import { useParams } from "react-router";

import { useRouter } from "@/app/hooks/useRouter";
import { COMPARE_TYPE } from "@/shared/CompareControl/lib/types";
import { CompareIcons } from "@/shared/CompareIcons/ui/CompareIcons";
import { REGION_COATS } from "@/shared/lib/constants";
import { formatNumber } from "@/shared/lib/heplers";
import { REGION_LEVEL } from "@/shared/lib/types";
import { useData } from "@/shared/Table/hooks/useTotal";
import { useTransitionPage } from "@/shared/Table/hooks/useTransitionPage";
import { getColorValue, getSubColor } from "@/shared/Table/lib/helpers";
import { IRegionRow } from "@/shared/Table/lib/types";
import { useAppSelector } from "@/store/redux/hooks";
import { TAB_ID } from "@/store/scheme/region";

import {
  LinkStyled,
  MainTableValue,
  SubValueProjectStyled,
  SubValueStyled,
  TableHL,
} from "../../Table.styled";
import { TableCellStyled } from "../DistrictRow/DistrictRow.styled";
import { ExpandButton } from "../ExpandButton/ExpandButton";
import { ProjectRow } from "../ProjectRow/ProjectRow";
import { TableRowStyled } from "./RegionRow.styled";

export const RegionRow: FC<IRegionRow> = ({
  region,
  columns,
  withProjects,
  order,
  orderBy,
}) => {
  const { tabName = TAB_ID.VDL_PROJECTS } = useParams<"tabName">();
  const { getPath } = useRouter();
  const { getIndicator } = useData();
  const [isShowProjects, setIsShowProjects] = useState(false);
  const { compare } = useAppSelector((state) => state.controls);
  const modelKPI = MODELS[MODEL_IDS.KPI_SOC_ECONOMY];
  const { subjectId, handleChangeSubjectId } = useTransitionPage();

  const handleExpand = () => {
    setIsShowProjects(!isShowProjects);
  };

  const handleClickRow = () => {
    handleChangeSubjectId(region?.parentId, region?.id);
    withProjects && handleExpand();
  };

  return (
    <>
      <TableRowStyled
        focused={subjectId?.regionId === region?.id}
        onClick={handleClickRow}
      >
        <TableCellStyled
          sx={{
            pl: "6rem",
            fontSize: "1.125rem",
            position: "sticky",
            left: 0,
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Avatar
                sx={{ width: 28, height: 28 }}
                src={REGION_COATS[region?.id as keyof typeof REGION_COATS]}
                alt={region.title}
              />

              <LinkStyled
                to={getPath(`/region/${region.id}/vdlProjects`)}
                state={{ tabName }}
              >
                {region?.title}
              </LinkStyled>
            </Stack>

            {withProjects && (
              <ExpandButton
                onClick={handleExpand}
                isExpanded={isShowProjects}
              />
            )}
          </Stack>
        </TableCellStyled>

        {columns
          .filter((column) => !["firstHeaderRow", "header"].includes(column.id))
          .map((col) => (
            <Fragment key={`${col.id}_${col.label}`}>
              {!col.subId && (
                <MainTableValue
                  focused={col.focused}
                  valueColor={getColorValue(col, region, getIndicator)}
                  level={REGION_LEVEL.REGION}
                >
                  <Stack direction="row" spacing={2} alignItems="center">
                    {col?.isComparable && (
                      <TableHL>
                        <CompareIcons
                          value={
                            getIndicator(
                              col?.data,
                              region?.id,
                              col?.helperId || col?.id,
                              undefined,
                              col?.model,
                              undefined,
                              col?.dateCode
                            )?.values?.[
                              compare.value === COMPARE_TYPE.FO
                                ? modelKPI.indexes.factHighLowFO
                                : modelKPI.indexes.factHighLowRF
                            ] ?? 0
                          }
                          isBig
                        />
                      </TableHL>
                    )}

                    <span>
                      {formatNumber(
                        getIndicator(
                          col?.data,
                          region?.id,
                          col?.helperId || col?.id,
                          undefined,
                          col?.model,
                          undefined,
                          col?.dateCode
                        )?.values?.[col?.indicator],
                        col.valuesUnit,
                        col?.fractionDigits
                      )}
                    </span>
                  </Stack>
                </MainTableValue>
              )}

              {col.subId && (
                <MainTableValue
                  focused={col.focused}
                  level={REGION_LEVEL.REGION}
                >
                  <span>
                    {`${formatNumber(
                      getIndicator(
                        col?.data,
                        region?.id,
                        col?.helperId || col?.id,
                        undefined,
                        col?.model,
                        undefined,
                        col?.dateCode
                      )?.values?.[col?.indicator],
                      undefined,
                      0
                    )} / `}
                  </span>

                  <SubValueStyled
                    valueColor={
                      col?.isRiskColumn
                        ? getSubColor(col, region, getIndicator)
                        : null
                    }
                    isRisk={col?.isRiskColumn}
                  >
                    {formatNumber(
                      getIndicator(
                        col?.isRiskColumn ? col.subData : col?.data,
                        region?.id,
                        col?.subId,
                        undefined,
                        col?.isRiskColumn ? col.subModel : col?.model,
                        undefined,
                        col?.dateCode
                      )?.values?.[col?.subIndicator || col?.indicator],
                      undefined,
                      0
                    )}
                  </SubValueStyled>
                  {col?.isRiskColumn && (
                    <SubValueProjectStyled level={REGION_LEVEL.REGION}>
                      {formatNumber(
                        getIndicator(
                          col.subData,
                          region?.id,
                          col?.subId,
                          undefined,
                          col.subModel,
                          undefined,
                          col?.dateCode
                        )?.values?.[col?.subShareId!!],
                        "%",
                        0
                      )}
                    </SubValueProjectStyled>
                  )}
                </MainTableValue>
              )}
            </Fragment>
          ))}
      </TableRowStyled>

      {withProjects && isShowProjects && (
        <ProjectRow
          key={region?.id}
          columns={columns}
          region={region}
          order={order}
          orderBy={orderBy}
          currentTabName={tabName as TAB_ID}
        />
      )}
    </>
  );
};
