import { useAppSelector } from "@store/redux/hooks";
import { MODEL_IDS, MODELS } from "@store/scheme/olap";
import { FC, useMemo } from "react";

import icon from "@/assets/VEDInvestment.svg";
import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { COMPARE_TYPE } from "@/shared/CompareControl/lib/types";
import { useKpi } from "@/shared/GeneralIndicators/hooks/useKpi";
import { IPopUpProps, REGION_TABLES } from "@/shared/RegionPopUp/lib/types";
import { Sceleton } from "@/shared/Sceleton/ui/Sceleton";
import { IVEDRow } from "@/shared/VED/lib/types";
import { VED } from "@/shared/VED/ui/VED";

import { investment, investmentIndicators } from "../lib/constants";
import { TitleExtra } from "./VEDInvestment.styled";

export const VEDInvestment: FC<IPopUpProps> = ({ isOpenPopUp }) => {
  const model = MODELS[MODEL_IDS.KPI_SOC_ECONOMY];
  const { indicatorHighLow, isCheckRfFoPage } = useRegions();
  const { compare } = useAppSelector((state) => state.controls);
  const { getValuesById, fetching } = useKpi();
  const basketValue = [] as number[];

  const rows: IVEDRow[] = useMemo(
    () =>
      investmentIndicators.map((indicator) => {
        const { countryData, districtData, regionData } =
          getValuesById(indicator);
        const value = regionData?.[0]?.indicatorVals?.[model.indexes.fact] ?? 0;

        if (isCheckRfFoPage.isRF) {
          basketValue.push(value);
        }

        return {
          key: indicator,
          title:
            indicator === "56"
              ? "Всего"
              : regionData?.[0]?.attributeVals?.short_name,
          value,
          reportDate: regionData?.[0]?.REPORT_DATE,
          type: isCheckRfFoPage.isRF ? "primary" : "secondary",
          ...(!isCheckRfFoPage.isRF && {
            valueRegion:
              compare.value === COMPARE_TYPE.FO
                ? districtData?.[0]?.indicatorVals?.[model.indexes.fact]
                : countryData?.[0]?.indicatorVals?.[model.indexes.fact],
            valueHL:
              regionData?.[0]?.indicatorVals?.[model.indexes[indicatorHighLow]],
          }),
        };
      }),
    [compare.value, getValuesById, isCheckRfFoPage.isRF, basketValue]
  );

  const maxRowValue =
    isCheckRfFoPage.isRF && !!basketValue.length
      ? Math.max(...basketValue)
      : undefined;

  if (fetching) {
    return <Sceleton type="line" />;
  }

  return (
    <VED
      icon={<img src={icon} alt={investment} />}
      title={
        <div>
          {investment} <TitleExtra>(в т.ч. частные и бюджетные)</TitleExtra>
        </div>
      }
      rows={rows}
      isOpenPopUp={isOpenPopUp}
      popUpId={REGION_TABLES.VED_INVESTMENT}
      maxRowValue={maxRowValue}
      titleIncident={investment}
    />
  );
};
