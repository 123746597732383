import React, { useMemo, useRef } from "react";
import { useParams } from "react-router";

import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { CustomScrollbar } from "@/shared/CustomScrollbar/ui/CustomScrollbar";
import { CustomScrollbarWrapper } from "@/shared/CustomScrollbar/ui/CustomScrollbar.styled";
import { useOpenIncident } from "@/shared/Incident/hooks/useOpenIncident";
import { IncidentCreateButton } from "@/shared/Incident/ui/IncidentCreateButton/IncidentCreateButton";
import { highPerson } from "@/shared/lib/constants";
import { useRegionPopUp } from "@/shared/RegionPopUp/hooks/useRegionPopUp";
import { IPopUpProps, REGION_TABLES } from "@/shared/RegionPopUp/lib/types";
import { ValueDate } from "@/shared/ValueDate/ValueDate";

import { VDLHeader } from "../../VdlLeader/ui/VdlLeader.styled";
import { useAllVDL } from "../hooks/useAllVdl";
import { vdlStaticData } from "../lib/constants";
import {
  AchievementVdlBox,
  AchievementVdlContent,
  AchievementVdlStyled,
  AchievementVdlTitle,
  VdlFooter,
} from "./AchievementVdl.styled";
import { AllVdlLeader } from "./AllVdlLeader";

export const AchievementVdl = ({ isOpenPopUp }: IPopUpProps) => {
  const { id } = useParams<"id">();
  const { topAllVdl, allVdlByParentId } = useAllVDL();
  const { isCheckRfFoPage, districts } = useRegions();
  const showRef = useRef<HTMLDivElement | null>(null);
  const { handleChange } = useOpenIncident();

  const [selectedSubjects, subject] = useMemo(() => {
    if (isCheckRfFoPage.isRF) {
      return [topAllVdl, "РФ"];
    } else if (isCheckRfFoPage.isFO && id) {
      const vdlData = allVdlByParentId(id);
      const subject = districts?.find((district) => district?.id === id)
        ?.attributeVals.SHORT_NAME;
      return [isOpenPopUp ? vdlData : vdlData.slice(0, 3), subject];
    }
    return [[], ""];
  }, [
    isCheckRfFoPage.isRF,
    isCheckRfFoPage.isFO,
    topAllVdl,
    allVdlByParentId,
    districts,
    id,
  ]);

  const { PopUp, PopUpButton, popupHeightMax }: any = useRegionPopUp(
    REGION_TABLES.ACHIEVEMENT_VDL,
    isOpenPopUp,
    isCheckRfFoPage.isRF ? (
      <AchievementVdlTitle>
        {`${vdlStaticData.title} ${subject}`}
        <span className="subtitle openSubTitle">
          {selectedSubjects?.[0]?.indicatorName}
        </span>
      </AchievementVdlTitle>
    ) : isCheckRfFoPage.isFO ? (
      <ValueDate isOpenPopUp={!isOpenPopUp}>
        {selectedSubjects?.[0]?.reportDate}
      </ValueDate>
    ) : (
      <>{highPerson}</>
    )
  );

  const showDate = useMemo(() => {
    return !isOpenPopUp || (isOpenPopUp && isCheckRfFoPage.isRF);
  }, [isOpenPopUp, isCheckRfFoPage.isRF]);

  const isCheckOpenPopUp = useMemo(
    () => !!isOpenPopUp && isCheckRfFoPage.isFO,
    [isOpenPopUp, isCheckRfFoPage.isFO]
  );

  return (
    <AchievementVdlStyled isOpenPopUp={!!isOpenPopUp} ref={showRef}>
      {!isOpenPopUp && (
        <AchievementVdlBox isOpenPopUp={isCheckOpenPopUp}>
          {isCheckRfFoPage.isAll && (
            <VDLHeader>
              <AchievementVdlTitle>
                <span className="title">{`${vdlStaticData.title} ${subject}`}</span>
                <span className="subtitle">
                  {selectedSubjects?.[0]?.indicatorName}
                </span>
              </AchievementVdlTitle>

              {!isOpenPopUp && (
                <IncidentCreateButton
                  className="hoverIncident"
                  posRight={48}
                  onClick={(event) => {
                    handleChange(
                      event,
                      showRef?.current,
                      `${vdlStaticData.title} ${subject}`,
                      selectedSubjects?.[0]?.reportDate
                    );
                  }}
                />
              )}
              <PopUpButton />
            </VDLHeader>
          )}
        </AchievementVdlBox>
      )}

      <AchievementVdlContent openWidgetByFo={isCheckOpenPopUp}>
        {isOpenPopUp ? (
          <CustomScrollbarWrapper>
            <CustomScrollbar
              heightMax={popupHeightMax}
              autoHeight={isOpenPopUp}
            >
              <AllVdlLeader
                isOpenPopUp={isOpenPopUp}
                allVdl={selectedSubjects}
              />
            </CustomScrollbar>
          </CustomScrollbarWrapper>
        ) : (
          <AllVdlLeader allVdl={selectedSubjects} />
        )}
        {showDate && (
          <VdlFooter>
            <ValueDate isOpenPopUp={isOpenPopUp}>
              {selectedSubjects?.[0]?.reportDate}
            </ValueDate>
          </VdlFooter>
        )}
      </AchievementVdlContent>

      <PopUp />
    </AchievementVdlStyled>
  );
};
