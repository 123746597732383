import { FC } from "react";

import icon from "@/assets/DistrictValuesEmployed.svg";
import { DistrictValues } from "@/shared/DistrictValues/ui/DistrictValues";
import { IPopUpProps } from "@/shared/RegionPopUp/lib/types";

export const DistrictValuesEmployed: FC<IPopUpProps> = ({ isOpenPopUp }) => {
  return (
    <DistrictValues icon={icon} indicatorId="4" isOpenPopUp={isOpenPopUp} />
  );
};
