import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";

import { useProjectPage } from "@/pages/ProjectsPage/hooks/useProjectPage";
import { IPagesProjectInfoPeriodData } from "@/pages/ProjectsPage/lib/types";
import { usePeriod } from "@/shared/PeriodControl/hooks/usePeriod";

import { InputSelect } from "../InputSelect";
import { ICheckpoints, SELECT_VALUE } from "../lib/types";

export const useCheckpointsFilter = (): {
  Select: React.ElementType;
  allCheckpoints?: ICheckpoints;
  actualPeriod?: string;
  actualPeriodData?: IPagesProjectInfoPeriodData;
} => {
  const { allCheckpoints, info } = useProjectPage();
  const { periodData } = usePeriod();
  const [periodValue, setPeriodValue] = useState(SELECT_VALUE.CURRENT_PERIOD);

  const Select = useCallback(
    () => (
      <InputSelect
        value={periodValue}
        handleChange={(e) => setPeriodValue(e.target.value)}
      />
    ),
    [periodValue]
  );

  const [actualPeriodData, actualPeriod] = useMemo(() => {
    const period = Object.keys(info?.periods || [])
      ?.filter((item) => {
        return (
          info?.periods[item].dims["796"].code === "2" &&
          info?.periods[item].attributeVals?.DatePart === "QUARTER"
        );
      })
      ?.join("");

    let actualKTPeriod = info?.periods?.[period]?.attributeVals?.DateEnd;

    if (!actualKTPeriod) {
      // Если квартальных данных нет, то берем актуальность из саммари
      actualKTPeriod = periodData?.[periodData?.length - 1]?.attributeVals?.[
        "report_period"
      ]
        ?.split(".")
        ?.reverse()
        ?.join("-");
    }

    return [info?.periods[period], actualKTPeriod];
  }, [info?.periods, periodData?.[periodData?.length - 1]]);

  const data = useMemo(() => {
    const isCheckpointDateBeforeDateEnd = (
      datePlan: string,
      dateEnd: string
    ) => {
      return !moment(datePlan, "DD.MM.YYYY").isAfter(moment(dateEnd));
    };

    const result = Object.fromEntries(
      Object.keys(allCheckpoints ?? {}).map((checkpoint) => {
        return [
          checkpoint,
          (allCheckpoints?.[checkpoint] ?? []).filter((item) => {
            const datePlan =
              item?.dims?.["847"]?.attributeVals?.achievement_date_plan;
            const dateEnd = actualPeriod;

            if (datePlan && dateEnd) {
              return isCheckpointDateBeforeDateEnd(datePlan, dateEnd);
            }
            return false;
          }),
        ];
      })
    );

    return periodValue === SELECT_VALUE.CURRENT_PERIOD
      ? result
      : allCheckpoints;
  }, [allCheckpoints, periodValue, actualPeriod]);

  if (!allCheckpoints) return { Select };

  return {
    Select,
    allCheckpoints: data,
    actualPeriod,
    actualPeriodData,
  };
};
