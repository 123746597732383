import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { prepareAttributes } from "@/shared/lib/heplers";
import {
  IOlapResponseDimensionItemDimWithVals,
  IPeriodAttributeVals,
  IProjectAttributeVals,
  IProjectIndicatorAttributeVals,
  IRegionAttributeVals,
  MODEL_IDS,
  MODELS,
} from "@/store/scheme/olap";

import { IPagesProjectInfoData, IPagesProjectInfoState } from "../../lib/types";
import { getProjectInfo } from "../api/api";

export const initialState: IPagesProjectInfoState = {
  data: undefined,
  fetching: false,
  error: null,
};

export const extraActionsInfoProjects = {
  getProjectInfo: createAsyncThunk(
    "pages/project/info/get",
    async ({
      projectId,
      dataSummaryId,
    }: {
      projectId: string;
      dataSummaryId?: string;
    }) => await getProjectInfo({ projectId, dataSummaryId })
  ),
};

const infoSlice = createSlice({
  name: "pages/project/info",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActionsInfoProjects.getProjectInfo.pending, (state) => {
        state.data = undefined;
        state.fetching = true;
      })
      .addCase(
        extraActionsInfoProjects.getProjectInfo.fulfilled,
        (state, action) => {
          const model = MODELS[MODEL_IDS.PROJECT_PASSPORT];
          let temporary: string | undefined;

          state.data = action.payload.reduce(
            (prev, item) => {
              const dimIdItem = item.dims[model.dimId];
              const periodId = item.id;
              const indicatorId =
                dimIdItem.attributeVals.type_ind_id || dimIdItem.id;
              const attributeVals =
                prepareAttributes<IProjectIndicatorAttributeVals>(
                  dimIdItem.attributeVals
                );

              const indicator = {
                ...item,
                attributeVals,
                values: Object.keys(model.indexes).reduce((prev, curr) => {
                  const index =
                    model.indexes[curr as keyof typeof model.indexes];
                  prev[index] = dimIdItem.indicatorVals[index]?.sum;
                  return prev;
                }, {} as { [sumIndicatorId: string]: number }),
              };

              if (!prev.periods[periodId]) {
                const { attributeVals: periodAttributeVals, ...period } = item;
                prev.periods[periodId] = {
                  ...(period as unknown as IOlapResponseDimensionItemDimWithVals<IPeriodAttributeVals>),
                  attributeVals:
                    prepareAttributes<IPeriodAttributeVals>(
                      periodAttributeVals
                    ),
                  indicators: {},
                };
              }

              prev.periods[periodId].indicators[indicatorId] = indicator;

              // Общая информация - это информация за последний актуальный год
              if (
                prev.periods[periodId].attributeVals.DatePart === "YEAR" &&
                prev.periods[periodId]?.dims?.[model.dataActualId]?.code === "2"
              ) {
                const periodCode = prev.periods[periodId]?.code;

                if (!temporary || periodCode >= temporary) {
                  temporary = periodCode;
                  prev.summary = prev.periods[periodId].indicators;
                }
              }

              prev.general = item.dims[
                model.dataProjectId
              ] as unknown as IOlapResponseDimensionItemDimWithVals<IProjectAttributeVals>;
              prev.region = item.dims[
                model.dataRegionId
              ] as unknown as IOlapResponseDimensionItemDimWithVals<IRegionAttributeVals>;

              return prev;
            },
            { summary: {}, periods: {} } as IPagesProjectInfoData
          );

          state.fetching = false;
          state.error = null;
        }
      )
      .addCase(
        extraActionsInfoProjects.getProjectInfo.rejected,
        (state, action) => {
          state.fetching = false;
          state.error = action.error;
        }
      );
  },
});

export const reducer = infoSlice.reducer;

export default infoSlice;
