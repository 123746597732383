import { Theme } from "@mui/material";
import React from "react";

import { formatNumber } from "@/shared/lib/heplers";
import { VALUE_COLOR } from "@/shared/Table/model/api/scheme";

import { IValue, IValueBasic, IValueRender } from "./types";

export const changeColor = (
  valueColor: VALUE_COLOR | undefined,
  theme: Theme
) => {
  return valueColor === VALUE_COLOR.GREY
    ? theme.palette.text.tertiary
    : undefined;
};

export const isValueRender = (value: IValue): value is IValueRender =>
  "render" in value;

export const replaceCharactersItem = (value: React.ReactNode): number => {
  const replaceItem: string = (value as string)
    ?.replace(/\s/g, "")
    ?.replace(/,/g, ".");
  return Number(replaceItem);
};

export const getValueItem = ({ key, value, valueColor }: IValueBasic) => {
  const newValue =
    key === "26" ? formatNumber(value as string, undefined, 0) : value;

  return `${
    replaceCharactersItem(newValue) > 0 &&
    (newValue as string)?.indexOf("+") === -1 &&
    valueColor
      ? "+"
      : ""
  }${newValue}`;
};
