import ExportIcon from "@assets/svg_components/Export";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, useTheme } from "@mui/material";
import { FC, useMemo, useState } from "react";

import { REGION_COUNTRY_CODE } from "@/shared/lib/constants";
import { useTransitionPage } from "@/shared/Table/hooks/useTransitionPage";

import { downloadFile } from "../lib/helpers";
import { IAllData } from "../lib/types";
import {
  ButtonStyled,
  DialogActionsStyled,
  DialogContentStyled,
  DialogStyled,
  DialogTitleStyled,
  ToggleButtonStyled,
  ToggleButtonWrapper,
} from "./ExportControl.styled";
import { FoExportControl } from "./FoExportControl";
import { RfExportControl } from "./RfExportControl";

// TODO: Будет дорабатываться после добавления автоматической выгрузки отчетов
export const ExportControl: FC = () => {
  const theme = useTheme();
  const { subjectId } = useTransitionPage();
  const [open, setOpen] = useState(false);
  const isRfDistrictId = subjectId?.districtId === REGION_COUNTRY_CODE;
  const showRfUpLoading = isRfDistrictId && !subjectId?.regionId;
  const districtId = useMemo(() => {
    return isRfDistrictId ? subjectId?.regionId : subjectId?.districtId;
  }, [isRfDistrictId, subjectId?.regionId, subjectId?.districtId]);

  const [allLoadingData, setAllLoadingData] = useState<IAllData>({
    isDisabled: true,
    filePath: "",
    fileName: "",
  });
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const getAllLoadingData = (allData: IAllData) => {
    setAllLoadingData({ ...allData });
  };

  const handleExport = () => {
    const { filePath, fileName } = allLoadingData;
    if (filePath && fileName) {
      downloadFile(filePath, fileName);
    }
    handleClose();
  };

  return (
    <ToggleButtonWrapper>
      <ToggleButtonStyled value={{}} onClick={handleClickOpen}>
        <ExportIcon color={theme.palette.button.iconColor} />
      </ToggleButtonStyled>

      <DialogStyled maxWidth={false} open={open} onClose={handleClose}>
        <DialogTitleStyled>
          <span>Выгрузка</span>

          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ color: (theme) => theme.palette.text.primary }}
          >
            <CloseIcon style={{ width: 24 }} />
          </IconButton>
        </DialogTitleStyled>

        <DialogContentStyled>
          {showRfUpLoading ? (
            <RfExportControl handleChangeData={getAllLoadingData} />
          ) : (
            <FoExportControl
              handleChangeData={getAllLoadingData}
              districtId={districtId}
            />
          )}
        </DialogContentStyled>

        <DialogActionsStyled>
          <ButtonStyled
            disabled={allLoadingData.isDisabled}
            variant="contained"
            onClick={handleExport}
          >
            Экспортировать
          </ButtonStyled>
        </DialogActionsStyled>
      </DialogStyled>
    </ToggleButtonWrapper>
  );
};
