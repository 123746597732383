import { IconButton, styled, Typography } from "@mui/material";

import { ITopBarStyledProps } from "../lib/types";

export const TopBarStyled = styled("div")<ITopBarStyledProps>(
  ({ theme, accent }) => ({
    display: "flex",
    flex: "0 0 auto",
    alignItems: "flex-start",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
    color: accent ? theme.palette.primary.contrastText : undefined,
  })
);

export const TypographyStyled = styled(Typography)({
  lineHeight: 1.1,
});

export const Title = styled("div")<ITopBarStyledProps>(({ theme, accent }) => ({
  display: "flex",
  alignItems: "center",

  "& a": {
    flex: "0 0 auto",
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(2),
    color: accent ? theme.palette.common.white : theme.palette.button.dark,
    transition: theme.transitions.create(["color"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    "&:hover": {
      color: theme.palette.button.selected,
    },

    "& svg": {
      width: "100%",
      height: "100%",
    },
  },
}));

export const IconButtonStyled = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== "accent",
})<ITopBarStyledProps>(({ theme, accent }) => ({
  color: accent ? theme.palette.common.white : theme.palette.button.dark,
}));

export const Controls = styled("div")<ITopBarStyledProps>(
  ({ theme, accent }) => ({
    display: "flex",
    flex: "0 0 auto",
    alignItems: "center",
    color: accent
      ? theme.palette.primary.contrastText
      : theme.palette.text.secondary,

    "& > div": {
      marginLeft: theme.spacing(2),
    },

    "& .MuiToggleButtonGroup-root>.MuiToggleButton-root:not(.Mui-selected)": {
      color: accent ? theme.palette.primary.contrastText : undefined,
    },
  })
);
