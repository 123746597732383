import { Grid } from "@mui/material";
import { useCallback, useMemo } from "react";

import { useProjectPage } from "@/pages/ProjectsPage/hooks/useProjectPage";
import { IPagesProjectInfoPeriodData } from "@/pages/ProjectsPage/lib/types";
import { GraphCard } from "@/shared/GraphCard/ui/GraphCard";
import { GridContainer } from "@/shared/GraphCard/ui/GraphCard.styled";
import { INDICATORS_GRAPHS } from "@/shared/GraphContainer/lib/types";
import { getPrevPer } from "@/shared/lib/heplers";
import { Loader } from "@/shared/Loader/Loader";
import { graphData } from "@/shared/ProjectResultsGroup/lib/types";

export const GraphContainer = () => {
  const { info } = useProjectPage();
  const regionGData = info?.periods;
  const { indicators, graphMap } = INDICATORS_GRAPHS;

  const checkPeriodsByActual = useCallback((periods: string[]) => {
    let quarterPeriods: string[] = [];
    const yearPeriods = periods
      ?.reduce((prev, cur) => {
        const year = +cur?.slice(1, 5);
        if (year >= 2022) {
          if (cur?.match(/^3/gi)?.length) {
            quarterPeriods.push(cur);
          } else {
            prev.push(cur);
          }
        }
        return prev;
      }, [] as string[])
      ?.filter((period) => {
        const year = period?.slice(1, 5) || "";
        return !quarterPeriods?.filter((quarterPeriod) => {
          return quarterPeriod?.match(year)?.join("");
        })?.length;
      });
    return [...quarterPeriods, ...yearPeriods];
  }, []);

  const periodsForDate = useCallback(
    (
      periodsObject: Record<string, IPagesProjectInfoPeriodData>,
      dateCodes: string[]
    ) => {
      return [...dateCodes]?.reduce((prev, cur) => {
        const attributeValues = periodsObject?.[cur]?.["attributeVals"];
        let year = attributeValues?.YEAR?.match(/[0-9]/gi)?.join("") || "";
        const date = attributeValues?.REPORT_DATE?.slice(3, 8) || "";

        if (cur?.match(/^3/gi)?.length) {
          year = attributeValues?.REPORT_DATE?.slice(9) || "";
          prev.push(`${year}\n${date}`);
        } else {
          prev.push(year);
        }
        return prev;
      }, [] as string[]);
    },
    []
  );

  const periodsByGraphMapId = useMemo(() => {
    const dateCodes = checkPeriodsByActual(
      Object.keys(regionGData || {})?.sort()
    );
    const periods = periodsForDate({ ...regionGData }, dateCodes);
    return { dateCodes, periods } as unknown as Record<string, string[]>;
  }, [regionGData]);

  if (!regionGData) return <Loader />;

  const preparedData = graphMap.reduce((prev, { iId }) => {
    const vallByDateCode = periodsByGraphMapId.dateCodes.map((dateCode) => {
      const valByCode = regionGData?.[dateCode]?.indicators?.[iId]?.values;
      const curValByCode =
        regionGData?.[getPrevPer()]?.indicators?.[iId]?.values ??
        regionGData?.[periodsByGraphMapId.dateCodes[0]]?.indicators?.[iId]
          ?.values;

      let plan =
        valByCode?.[indicators.plan] ?? curValByCode?.[indicators.plan];
      let fact = valByCode?.[indicators.fact];

      if (["106", "109"].includes(iId)) {
        plan = plan ? Math.round(plan) : plan;
        fact = fact ? Math.round(fact) : fact;
      }

      return {
        fact,
        plan,
        percent: valByCode?.[indicators.percent],
        color: valByCode?.[indicators.color],
        unit: regionGData?.[dateCode]?.indicators?.[iId]?.attributeVals?.UNIT,
      };
    });

    prev[iId] = vallByDateCode;

    return prev;
  }, {} as { [key: string]: graphData });

  return (
    <GridContainer container spacing={2}>
      {graphMap.map(({ title, iId }) => (
        <Grid key={iId} item xs={6}>
          <GraphCard
            isOnlyProject
            title={title}
            graphData={preparedData[iId]}
            periods={periodsByGraphMapId?.periods}
          />
        </Grid>
      ))}
    </GridContainer>
  );
};
