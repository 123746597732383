import { Box } from "@mui/material";
import React from "react";

import { AchievementVdl } from "@/features/AchievementVdl/ui/AchievementVdl";
import { GeneralIndicatorsWidget } from "@/features/GeneralIndicatorsWidget/GeneralIndicatorsWidget";
import { NationalProjectsIndicators } from "@/features/NationalProjectsIndicators/ui/NationalProjectsIndicators";
import { VdlLeader } from "@/features/VdlLeader/ui/VdlLeader";
import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { useGeneralIndicators } from "@/shared/GeneralIndicators/hooks/useGeneralIndicators";
import { Sceleton } from "@/shared/Sceleton/ui/Sceleton";

import { useRegionMainInfo } from "../hooks/useRegionMainInfo";
import {
  RegionMainInfoGridCell,
  RegionMainInfoWrapper,
} from "./RegionMainInfo.styled";

export const RegionMainInfo = () => {
  const { canShowAllPage } = useRegionMainInfo();
  const { isCheckRfFoPage } = useRegions();

  const { fetching } = useGeneralIndicators();

  return canShowAllPage ? (
    <Box>
      <RegionMainInfoWrapper>
        <RegionMainInfoGridCell sx={{ gridArea: "VDL" }}>
          {fetching && <Sceleton type="content" />}

          {!fetching &&
            (isCheckRfFoPage.isAll ? <AchievementVdl /> : <VdlLeader />)}
        </RegionMainInfoGridCell>

        <RegionMainInfoGridCell sx={{ gridArea: "GeneralIndicators" }}>
          {fetching && <Sceleton type="content" />}

          {!fetching && <GeneralIndicatorsWidget />}
        </RegionMainInfoGridCell>

        <RegionMainInfoGridCell sx={{ gridArea: "NationalProjectsIndicators" }}>
          {fetching && <Sceleton type="content" />}

          {!fetching && <NationalProjectsIndicators />}
        </RegionMainInfoGridCell>
      </RegionMainInfoWrapper>
    </Box>
  ) : null;
};
