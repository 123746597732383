import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { prepareAttributes } from "@/shared/lib/heplers";
import { ArgumentsType, IRegionsState } from "@/shared/lib/types";
import {
  IPreparedIndicatorAttributeVals,
  MODEL_IDS,
  MODELS,
} from "@/store/scheme/olap";

import { getIndicatorIdByPeriod } from "../lib/heplers";
import { getSocEconomy } from "./api";

export const initialState: IRegionsState = {
  data: undefined,
  fetching: false,
  error: null,
};

export const extraActionsSocEconomy = {
  get: createAsyncThunk(
    "regions/socEconomy/get",
    async (args: ArgumentsType) => await getSocEconomy(args)
  ),
};

const socEconomySlice = createSlice({
  name: "socEconomy",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActionsSocEconomy.get.pending, (state) => {
        state.data = undefined;
        state.fetching = true;
      })
      .addCase(extraActionsSocEconomy.get.fulfilled, (state, action) => {
        const model = MODELS[MODEL_IDS.KPI_SOC_ECONOMY];

        state.data = action.payload.reduce((prev, cur) => {
          const regionId = cur?.dims?.[model.dataRegionId]?.code;
          const reportPeriod = (cur?.attributeVals as any)?.DatePart;

          if (regionId) {
            if (!prev[regionId]) {
              prev[regionId] = {};
            }
            const indicatorId = getIndicatorIdByPeriod(
              cur?.dims?.[model.dimId]?.code,
              reportPeriod
            );

            if (indicatorId) {
              if (!prev[regionId][indicatorId]) {
                prev[regionId][indicatorId] = {};
              }

              prev[regionId][indicatorId] = {
                ...prepareAttributes<IPreparedIndicatorAttributeVals>(
                  cur.dims[model.dataRegionId].attributeVals
                ),
                attributeVals: cur.dims[model.dimId].attributeVals || {},
                indicatorVals: Object.fromEntries(
                  Object.entries(cur.dims[model.dimId].indicatorVals || {}).map(
                    ([key, value]) => [key, value?.sum]
                  )
                ) as any,
              };
            }
          }

          return prev;
        }, {} as any);

        state.fetching = false;
        state.error = null;
      })
      .addCase(extraActionsSocEconomy.get.rejected, (state, action) => {
        state.fetching = false;
        state.error = action.error;
      });
  },
});

export const reducer = socEconomySlice.reducer;

export default socEconomySlice;
