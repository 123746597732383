import { Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import { FC, useRef } from "react";

import { useProjectPage } from "@/pages/ProjectsPage/hooks/useProjectPage";
import { Icon } from "@/shared/CustomIcon/Icon.styled";
import { CustomScrollbar } from "@/shared/CustomScrollbar/ui/CustomScrollbar";
import { useOpenIncident } from "@/shared/Incident/hooks/useOpenIncident";
import { IncidentCreateButton } from "@/shared/Incident/ui/IncidentCreateButton/IncidentCreateButton";
import { useCheckpointsFilter } from "@/shared/InputSelect/hooks/useCheckpointsFilter";
import { formatNumber, formatNumberPeople } from "@/shared/lib/heplers";
import { PROJECT_INDICATORS } from "@/shared/Table/model/api/scheme";
import { ValueUnit } from "@/shared/Value/Value.styled";
import { MODEL_IDS, MODELS } from "@/store/scheme/olap";

import {
  ProjectCard,
  ProjectCardContent,
  ProjectCardInfo,
  ProjectCardLabel,
  ProjectCardName,
  ProjectCardTitle,
  ProjectContent,
} from "../../ProjectCards.styled";
import {
  ProjectGeneralInfoValue,
  ProjectGeneralInfoValues,
} from "./ProjectGeneralInfo.styled";
import { GeneralInfoIcon } from "./ProjectGeneralInfoIcon";

export const ProjectGeneralInfo: FC = () => {
  const model = MODELS[MODEL_IDS.PROJECT_PASSPORT];
  const theme = useTheme();
  const { info } = useProjectPage();
  const { actualPeriodData } = useCheckpointsFilter();
  const reportDate = actualPeriodData?.attributeVals.REPORT_DATE || "";
  const showRef = useRef<HTMLDivElement | null>(null);
  const { move, handleMove, handleChange } = useOpenIncident();

  return (
    <ProjectCard
      variant="outlined"
      ref={showRef}
      onMouseEnter={() => handleMove(true)}
      onMouseLeave={() => handleMove(false)}
    >
      <ProjectCardContent>
        <ProjectCardTitle>
          <Icon
            size="big"
            sx={{ background: theme.palette.table.backgroundImg }}
          >
            <GeneralInfoIcon />
          </Icon>

          <Typography variant="subtitle1">Общая информация</Typography>
          {move && (
            <IncidentCreateButton
              bigSize
              posRight={24}
              onClick={(event) => {
                handleChange(
                  event,
                  showRef?.current,
                  "Общая информация",
                  reportDate
                );
              }}
            />
          )}
        </ProjectCardTitle>
        <CustomScrollbar>
          <ProjectContent>
            <ProjectCardName sx={{ lineHeight: "1.5rem" }}>
              {info?.general?.attributeVals?.name ?? "-"}
            </ProjectCardName>
            <ProjectCardLabel>Наименование проекта</ProjectCardLabel>

            <ProjectCardInfo>
              <ProjectGeneralInfoValues>
                <div>
                  <ProjectGeneralInfoValue>
                    {info?.general?.attributeVals?.implementation_period}
                  </ProjectGeneralInfoValue>

                  <ProjectCardLabel>Срок реализации</ProjectCardLabel>
                </div>

                {info?.general?.attributeVals?.completion_status_attr ===
                  "Реализация завершена" && (
                  <div>
                    <ProjectGeneralInfoValue>
                      {info?.general?.attributeVals?.completion_status_attr}
                    </ProjectGeneralInfoValue>

                    <ProjectCardLabel>Статус проекта</ProjectCardLabel>
                  </div>
                )}
              </ProjectGeneralInfoValues>

              <ProjectGeneralInfoValues>
                <div>
                  <ProjectGeneralInfoValue>
                    {formatNumber(
                      info?.summary[PROJECT_INDICATORS.INVESTMENTS]
                        ?.indicatorVals[model.indexes.plan].sum,
                      undefined,
                      2
                    )}

                    <ValueUnit>
                      {
                        info?.summary[PROJECT_INDICATORS.INVESTMENTS]
                          ?.attributeVals.UNIT
                      }
                    </ValueUnit>
                  </ProjectGeneralInfoValue>
                  <ProjectCardLabel>Частные инвестиции</ProjectCardLabel>
                </div>

                <div>
                  <ProjectGeneralInfoValue>
                    {formatNumberPeople(
                      info?.summary[PROJECT_INDICATORS.PLACES]?.indicatorVals[
                        model.indexes.plan
                      ].sum
                    )}

                    <ValueUnit>
                      {
                        info?.summary[PROJECT_INDICATORS.PLACES]?.attributeVals
                          .UNIT
                      }
                    </ValueUnit>
                  </ProjectGeneralInfoValue>
                  <ProjectCardLabel>Новые рабочие места</ProjectCardLabel>
                </div>
              </ProjectGeneralInfoValues>
            </ProjectCardInfo>
          </ProjectContent>
        </CustomScrollbar>
      </ProjectCardContent>
    </ProjectCard>
  );
};
