import React from "react";

import { RegionProjectsWidget } from "@/features/RegionProjectsWidget/ui/RegionProjectsWidget";
import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import {
  BoxStyled,
  RegionGridCell,
  RegionGridInvestment,
} from "@/pages/RegionPage/ui/RegionMutableInfo/ui/RegionMutableInfo.styled";
import { Sceleton } from "@/shared/Sceleton/ui/Sceleton";
import { useFuturePlan } from "@/shared/Table/hooks/useFuturePlan";
import { useAppSelector } from "@/store/redux/hooks";

import { EnterpriseWidget } from "../EnterpriseWidget/ui/EnterpriseWidget";
import { DistrictValuesInvestment, VEDInvestment } from "./components";

export const RegionMutableInvestment = () => {
  const { isCheckRfFoPage } = useRegions();
  const { isCheckFuturePlan } = useFuturePlan();

  const isCheckShowEnterprise =
    isCheckRfFoPage.isAll || (!isCheckRfFoPage.isAll && isCheckFuturePlan);

  const { extensionProjectsList } = useAppSelector((state) => state.ui.tabs);
  const { fetching } = useAppSelector((state) => state.regions.enterprise);

  return (
    <BoxStyled fitHeight>
      <RegionGridInvestment>
        <RegionGridCell sx={{ gridArea: "DistrictValues" }}>
          <DistrictValuesInvestment />
        </RegionGridCell>
        <RegionGridCell sx={{ gridArea: "VED" }}>
          <VEDInvestment />
        </RegionGridCell>
        <RegionGridCell
          sx={{ gridArea: "ProjectsTable" }}
          style={{ overflow: "auto" }}
        >
          {isCheckShowEnterprise && (
            <>{fetching ? <Sceleton type="line" /> : <EnterpriseWidget />}</>
          )}

          {!isCheckShowEnterprise && (
            <>
              {!extensionProjectsList ? (
                <Sceleton type="line" />
              ) : (
                <RegionProjectsWidget container="rate" />
              )}
            </>
          )}
        </RegionGridCell>
      </RegionGridInvestment>
    </BoxStyled>
  );
};
