import { useCallback, useMemo } from "react";

import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { getConvertDote } from "@/shared/Achievements/lib/helpers";
import { COMPARE_TYPE } from "@/shared/CompareControl/lib/types";
import { REGION_COUNTRY_CODE } from "@/shared/lib/constants";
import { formatNumber } from "@/shared/lib/heplers";
import { useAppDispatch, useAppSelector } from "@/store/redux/hooks";
import { MODEL_IDS, MODELS } from "@/store/scheme/olap";

import { dinamicIndicator, enterpriseIndicators } from "../lib/constants";
import { IEnterpriseRows } from "../lib/types";
import { extraActionsEnterprise } from "../model/enterpriseSlice";

export const useEnterprise = () => {
  const model = MODELS[MODEL_IDS.KPI_SOC_ECONOMY];
  const { data: enterprise, fetching: enterpriseFetching } = useAppSelector(
    (state) => state.regions.enterprise
  );
  const { getDistrictByRegion, indicatorHighLow, isCheckRfFoPage } =
    useRegions();
  const dispatch = useAppDispatch();
  const regionId = useAppSelector((state) => state.controls.regions.id);
  const district = getDistrictByRegion(regionId!);
  const { compare } = useAppSelector((state) => state.controls);
  const basketValue = [] as number[];

  const requestEnterprise = useCallback(
    (regionId: string, dataSummaryId: string, district: any) => {
      if (regionId) {
        dispatch(
          extraActionsEnterprise.getEnterprise({
            regionId,
            districtId: district?.id,
            dataSummaryId,
          })
        );
      }
    },
    []
  );

  const getValuesEnterprise = useCallback(
    (id: string) => {
      const countryData = enterprise?.[id]?.[REGION_COUNTRY_CODE];
      const districtData = enterprise?.[id]?.[district?.id!!];
      const regionData = enterprise?.[id]?.[regionId!!];

      return { countryData, districtData, regionData };
    },
    [enterprise, district, regionId]
  );

  const convertValues = (
    value: string | number,
    fractionDigits: number = 0,
    isConvertThousand: boolean = false
  ) => {
    let convertValue = value;
    if (isConvertThousand) {
      convertValue = +convertValue / 1000;
    }
    return formatNumber(convertValue, undefined, fractionDigits);
  };

  const rows: IEnterpriseRows[] = useMemo(
    () =>
      enterpriseIndicators.map((indicator) => {
        //Значения для 14, 16, 18 - левые значения и наименование
        const { countryData, districtData, regionData } =
          getValuesEnterprise(indicator);

        const title =
          (regionData || districtData || countryData)?.attributeVals?.[
            "short_name"
          ] || "";

        const absolutValue =
          convertValues(
            regionData?.indicatorVals?.[model.indexes.fact],
            0,
            true
          ) || "";
        // Значения для 15, 17, 19 - правые значения, диаграммы
        const {
          countryData: dynamicCountryData,
          districtData: dynamicDistrictData,
          regionData: dynamicRegionData,
        } = getValuesEnterprise(dinamicIndicator[indicator as string]);

        const dynamicValue =
          convertValues(
            dynamicRegionData?.indicatorVals?.[model.indexes.fact],
            1,
            false
          ) || "";

        if (isCheckRfFoPage.isRF) {
          basketValue.push(getConvertDote(dynamicValue));
        }

        return {
          // Общее для всех страниц
          key: indicator,
          title,
          absolutValue,
          absolutReportDate: regionData?.["REPORT_DATE"],
          dynamicValue,
          dynamicUnit: dynamicRegionData?.attributeVals?.["unit_measure"] || "",
          dynamicReportDate: dynamicRegionData?.["PREVIOUS_DATE"],
          // Для субъектов и ФО
          ...(!isCheckRfFoPage.isRF && {
            absolutValueByParent:
              convertValues(
                {
                  ...(compare.value === COMPARE_TYPE.FO
                    ? districtData
                    : countryData),
                }?.indicatorVals?.[model.indexes.fact],
                0,
                true
              ) || "",
            dynamicValueByParent:
              convertValues(
                {
                  ...(compare.value === COMPARE_TYPE.FO
                    ? dynamicDistrictData
                    : dynamicCountryData),
                }?.indicatorVals?.[model.indexes.fact],
                1,
                false
              ) || "",
            valueHL:
              dynamicRegionData?.indicatorVals?.[
                model.indexes[indicatorHighLow]
              ],
          }),
        };
      }),
    [compare.value, isCheckRfFoPage.isRF, basketValue, indicatorHighLow]
  );

  const maxRowValue =
    isCheckRfFoPage.isRF && !!basketValue.length
      ? Math.max(...basketValue)
      : undefined;

  const hasData = !!Object.keys(enterprise || {})?.length;

  return {
    rows,
    maxRowValue,
    enterprise,
    hasData,
    requestEnterprise,
    enterpriseFetching,
  };
};
