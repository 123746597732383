export const kpiGraphIndicators = [
  "1",
  "4",
  "5",
  "6",
  "7",
  "11",
  "13",
  "14",
  "16",
  "18",
  "25",
  "26",
  "27",
  "29",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "44",
  "46",
  "48",
  "50",
  "52",
  "54",
  "56",
  "57",
  "58",
  "59",
  "60",
  "61",
  "62",
  "63",
  "64",
  "76",
  "78",
  "82",
  "84",
  "86",
  "88",
  "90",
  "92",
  "117",
  "132",
  "133",
];

export const budgetGraphIndicators = [
  "8",
  "9",
  "94",
  "95",
  "96",
  "97",
  "98",
  "99",
];

export const kktGraphIndicators = ["110"];

export const isYAxisIndicator = [
  "84",
  "86",
  "54",
  "25",
  "31",
  "32",
  "5",
  "6",
  "92",
  "110",
  "117",
  "26",
  "94",
  "95",
  "96",
  "98",
  "8",
  "11",
  "13",
  "14",
  "16",
  "18",
  "82",
  "90",
  "99",
  "7",
];

export enum PERIOD_GRAPH {
  DAY = "DAY",
  MONTH = "MONTH",
  QUARTER = "QUARTER",
  YEAR = "YEAR",
}

export const PERIOD_INDICATOR = {
  [PERIOD_GRAPH.DAY]: ["110"],
  [PERIOD_GRAPH.MONTH]: [
    "84",
    "86",
    "76",
    "50",
    "25",
    "1",
    "5",
    "6",
    "33",
    "34",
    "35",
    "36",
    "37",
    "38",
    "39",
    "40",
    "41",
    "42",
    "52",
    "78",
    "82",
    "88",
    "90",
    "92",
    "94",
    "95",
    "96",
    "97",
    "98",
    "99",
    "8",
    "9",
    "14",
    "16",
    "18",
  ],
  [PERIOD_GRAPH.QUARTER]: [
    "133",
    "132",
    "117",
    "64",
    "63",
    "62",
    "61",
    "60",
    "59",
    "58",
    "57",
    "56",
    "48",
    "46",
    "4",
    "54",
    "11",
  ],
  [PERIOD_GRAPH.YEAR]: ["44", "27", "31", "32", "26", "29", "7", "13"],
};

export const PERIOD_INDICATOR_START = {
  [PERIOD_GRAPH.YEAR]: "1",
  [PERIOD_GRAPH.QUARTER]: "3",
  [PERIOD_GRAPH.MONTH]: "4",
  [PERIOD_GRAPH.DAY]: "6",
};

export const PERIOD_INDICATOR_END = {
  [PERIOD_GRAPH.YEAR]: "0000",
  [PERIOD_GRAPH.QUARTER]: "0004",
  [PERIOD_GRAPH.MONTH]: "0012",
  [PERIOD_GRAPH.DAY]: "1231",
};

export const absoluteUnits = ["чел", "мест"];

export const defaultIrregularIndicators = ["82", "90", "99"];
export const irregularIndicators = ["27", ...defaultIrregularIndicators];
export const barInidcarors = ["7", ...defaultIrregularIndicators];
