import { useMemo } from "react";

import { findFoCoatsOfArms } from "../lib/helpers";
import { IFoCoatsOfArmsProps } from "../lib/types";
import { IconWrapper } from "./FoCoatsOfArms.styled";

export const FoCoatsOfArms = ({
  idFo,
  achEnabled = true,
  isVDL = false,
  openWidget = false,
}: IFoCoatsOfArmsProps) => {
  const foCoatOfArms = useMemo(() => {
    return findFoCoatsOfArms(idFo ?? "");
  }, [idFo]);

  return (
    <IconWrapper isVDL={isVDL} achEnabled={achEnabled} openWidget={openWidget}>
      {foCoatOfArms}
    </IconWrapper>
  );
};
