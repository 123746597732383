import { styled } from "@mui/material";

export const RegionPlaceWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "baseline",
  gap: theme.spacing(),
}));

export const RegionPlaceColor = styled("div")(({ theme }) => ({
  fontSize: theme.spacing(1.5),
  color: theme.palette.primary.contrastText,
}));
