import { GeoJSON } from "echarts/types/src/coord/geo/geoTypes";
import { useMemo } from "react";

import { useMapDataT } from "../lib/types";

export const useMapData = ({
  foData,
  allDistrictIds,
  regionsData,
  allRegionIds,
}: useMapDataT) => {
  const filteredFoData = useMemo(
    () => ({
      ...foData,
      jsonData: {
        ...foData?.jsonData,
        features: foData?.jsonData.features,
        // .filter((feature) =>
        //   allDistrictIds.includes(feature.properties.id)
        // ) ?? [],
      } as GeoJSON,
    }),
    [allDistrictIds, foData]
  );

  const filteredRegionsData = useMemo(
    () => ({
      ...regionsData,
      jsonData: {
        ...regionsData?.jsonData,
        features:
          regionsData?.jsonData.features.filter((feature: any) =>
            allRegionIds.includes(feature.properties.id)
          ) ?? [],
      } as GeoJSON,
    }),
    [regionsData, allRegionIds]
  );

  return { filteredFoData, filteredRegionsData };
};
