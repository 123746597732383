import {
  Button,
  IconButton,
  InputBase,
  ListItemText,
  MenuItem,
  Stack,
  styled,
} from "@mui/material";

export const WrapperStack = styled(Stack)(({ theme }) => ({
  maxWidth: theme.spacing(37.5),
  minWidth: theme.spacing(27),
  overflow: "hidden",
  alignSelf: "stretch",
  "& .MuiFormControl-root": {
    alignSelf: "stretch",
  },
  "@media (max-width: 96.9rem)": {
    minWidth: theme.spacing(17.5),
  },
}));

export const BootstrapInput = styled(InputBase)(({ theme }) => {
  return {
    height: "100%",
    maxHeight: theme.spacing(5),
    alignItems: "stretch",
    "label + &": {
      marginTop: theme.spacing(3),
    },

    "& > .MuiSvgIcon-root": {
      color: theme.palette.icon.color,
    },
    "& .MuiInputBase-input": {
      borderRadius: theme.spacing(),
      position: "relative",
      backgroundColor: theme.palette.button.background,
      border: `1px solid ${theme.palette.table.divider}`,
      fontSize: 14,
      padding: theme.spacing(),
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      color: theme.palette.text.tertiary,
      fontWeight: theme.typography.fontWeightSemiBold,
    },
  };
});

export const TextStyled = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  height: "2.5rem",
  width: "100%",
  padding: "0 12px",
  border: "1px solid rgba(255, 255, 255, 0.1)",
  borderRadius: theme.spacing(),
  backgroundColor: "rgba(43, 48, 63, 0.5)",
  color: "rgba(255, 255, 255, 0.7)",
  fontWeight: 500,
  fontSize: theme.spacing(2),
  lineGeight: theme.spacing(2.75),
  cursor: "pointer",
}));

export const YearChange = styled(IconButton)(({ theme }) => ({
  width: 24,
  height: 24,
  marginLeft: theme.spacing(0.5),
  color: theme.palette.text.tertiary,

  "&.Mui-disabled": {
    color: theme.palette.text.primary,
    opacity: 0.2,
  },
}));

export const ListTextStyled = styled(ListItemText)(({ theme }) => ({
  margin: theme.spacing(0),
  color: theme.palette.text.secondary,
  "& .MuiListItemText-primary": {
    fontWeight: theme.typography.fontWeightSemiBold,
    fontSize: theme.spacing(1.75),
  },
  "& .MuiListItemText-secondary": {
    color: theme.palette.text.light,
    fontWeight: theme.typography.fontWeightSemiBold,
    fontSize: theme.spacing(2),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

export const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  margin: theme.spacing(),
  padding: theme.spacing(2),
  justifyContent: "center",
  color: theme.palette.text.tertiary,
  border: `2px solid rgba(73, 180, 239, 0.0)`,
  "&:hover": {
    background: "rgba(73, 180, 239, 0.18)",
    borderRadius: theme.spacing(),
    color: theme.palette.text.primary,
    border: `2px solid ${theme.palette.border.selected}`,
  },
  "&.Mui-selected": {
    background: "rgba(73, 180, 239, 0.18)",
    borderRadius: theme.spacing(),
    color: theme.palette.text.primary,
    border: `2px solid ${theme.palette.border.selected}`,
  },
}));

export const ButtonStyled = styled(Button)(({ theme }) => ({
  background: theme.palette.button.primary,
}));

export const PopoverStyled = styled("div")(({ theme }) => ({
  width: theme.spacing(38.5),
}));

export const PopoverStyledHeader = styled("div")(({ theme }) => ({
  display: "flex",
  maxHeight: theme.spacing(7),
  alignItems: "center",
  padding: theme.spacing(2),
  justifyContent: "space-between",
  background: "rgba(191, 218, 255, 0.07)",
  fontWeight: 700,
  fontSize: theme.spacing(2),
  lineHeight: theme.spacing(3),
}));
