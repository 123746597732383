import React from "react";
import { useLocation } from "react-router";

import { IMapTabCardProps } from "../lib/types";
import { MapTabCard } from "./MapTabCard";
import { RegionTabCard } from "./RegionTabCard";

// Общий компонент табов на главной и в карточке субъекта
export const AchievementsTabCard = ({
  tabs,
  targetId,
  selectedTabId,
  selectedSubjectId,
  setSelectedTabId,
  tabFetching = false,
}: IMapTabCardProps) => {
  const { pathname } = useLocation();

  const renderContent = () => {
    const isRegion = pathname.startsWith("/region");
    return isRegion ? (
      <RegionTabCard
        tabs={tabs}
        selectedSubjectId={selectedSubjectId}
        selectedTabId={selectedTabId}
        setSelectedTabId={setSelectedTabId}
        tabFetching={tabFetching}
      />
    ) : (
      <MapTabCard
        tabs={tabs}
        selectedSubjectId={selectedSubjectId}
        selectedTabId={selectedTabId}
        setSelectedTabId={setSelectedTabId}
        targetId={targetId}
      />
    );
  };

  return <>{renderContent()}</>;
};
