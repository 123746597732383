import { FC } from "react";

const icon: FC = () => (
  <svg
    width="15"
    height="11"
    viewBox="0 0 15 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.16683 10.9998H8.66683C9.12516 10.9998 9.50016 10.6248 9.50016 10.1665V0.999837C9.50016 0.541504 9.12516 0.166504 8.66683 0.166504H6.16683C5.7085 0.166504 5.3335 0.541504 5.3335 0.999837V10.1665C5.3335 10.6248 5.7085 10.9998 6.16683 10.9998ZM1.16683 10.9998H3.66683C4.12516 10.9998 4.50016 10.6248 4.50016 10.1665V0.999837C4.50016 0.541504 4.12516 0.166504 3.66683 0.166504H1.16683C0.708496 0.166504 0.333496 0.541504 0.333496 0.999837V10.1665C0.333496 10.6248 0.708496 10.9998 1.16683 10.9998ZM10.3335 0.999837V10.1665C10.3335 10.6248 10.7085 10.9998 11.1668 10.9998H13.6668C14.1252 10.9998 14.5002 10.6248 14.5002 10.1665V0.999837C14.5002 0.541504 14.1252 0.166504 13.6668 0.166504H11.1668C10.7085 0.166504 10.3335 0.541504 10.3335 0.999837Z"
      fill="#ffffff80"
    />
  </svg>
);

export default icon;
