import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { prepareAttributes } from "@/shared/lib/heplers";
import {
  INationalProjectAttributeVals,
  IPreparedIndicatorAttributeVals,
  IPreparedOlapResponseDimensionItemDim,
  MODEL_IDS,
  MODELS,
} from "@/store/scheme/olap";

import { INationalProjectssSlice } from "../lib/types";
import { getNationalProjects, getNationalProjectsAll } from "./api";

export const initialState: INationalProjectssSlice = {
  data: undefined,
  dataAll: undefined,
  dataFetching: false,
  dataAllFetching: false,
  dataError: null,
  dataAllError: null,
};

export const extraActionsNationalProjects = {
  get: createAsyncThunk(
    "regions/nationalProjects/get",
    async (regionId: string) => await getNationalProjects(regionId)
  ),
  getAll: createAsyncThunk(
    "regions/nationalProjects/getAll",
    async () => await getNationalProjectsAll()
  ),
};

const nationalProjectsSlice = createSlice({
  name: "nationalProjects",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActionsNationalProjects.get.pending, (state) => {
        state.data = undefined;
        state.dataAllFetching = true;
        state.dataFetching = true;
      })
      .addCase(extraActionsNationalProjects.get.fulfilled, (state, action) => {
        const model = MODELS[MODEL_IDS.NATIONAL_PROJECTS_INDICATORS];

        state.data = action.payload.reduce((prev, cur) => {
          const indicatorId = cur?.dims?.[model.dataProjectId]?.code;
          const providerId = cur?.dims?.[model.dimId]?.code;
          // @ts-ignore
          const UNIT = cur?.dims?.[model.dimId]?.attributeVals?.unit_measure;

          if (indicatorId) {
            if (!prev[indicatorId]) {
              prev[indicatorId] = {};
            }

            if (providerId) {
              if (!prev[indicatorId][providerId]) {
                prev[indicatorId][providerId] = {};
              }

              prev[indicatorId][providerId] = {
                ...prepareAttributes<IPreparedIndicatorAttributeVals>(
                  cur.attributeVals
                ),
                UNIT,
                attributeVals:
                  cur?.dims?.[model.dataProjectId]?.attributeVals || {},
                indicatorVals: Object.fromEntries(
                  Object.entries(
                    cur?.dims?.[model.dataProjectId]?.indicatorVals || {}
                  ).map(([key, value]) => [key, value?.sum])
                ) as any,
              };
            }
          }

          return prev;
        }, {} as any);

        state.dataFetching = false;
        state.dataError = null;
      })
      .addCase(extraActionsNationalProjects.get.rejected, (state, action) => {
        state.dataFetching = false;
        state.dataError = action.error;
      })
      .addCase(extraActionsNationalProjects.getAll.pending, (state) => {
        state.dataAll = undefined;
        state.dataAllFetching = true;
      })
      .addCase(
        extraActionsNationalProjects.getAll.fulfilled,
        (state, action) => {
          let temporaryAllProjects;
          state.dataAll = action.payload.reduce((prev, cur) => {
            if (!prev) {
              prev = [];
            }
            const temporary = {
              ...cur,
              attributeVals: prepareAttributes<INationalProjectAttributeVals>(
                cur.attributeVals
              ),
            };
            if (!Number(temporary?.id)) {
              temporaryAllProjects = temporary;
            } else {
              prev.push(temporary);
            }
            return prev;
          }, [] as IPreparedOlapResponseDimensionItemDim<INationalProjectAttributeVals>[]);
          if (temporaryAllProjects) {
            state.dataAll = [temporaryAllProjects, ...state.dataAll];
          }
          state.dataAllFetching = false;
          state.dataAllError = null;
        }
      )
      .addCase(
        extraActionsNationalProjects.getAll.rejected,
        (state, action) => {
          state.dataAllFetching = false;
          state.dataAllError = action.error;
        }
      );
  },
});

export const reducer = nationalProjectsSlice.reducer;

export default nationalProjectsSlice;
