export const generalInfo = [
  {
    indicator: "802",
    name: "Достижение",
    unit: "%",
    color: "804",
  },
  {
    indicator: "801",
    name: "План",
    unit: "шт.",
  },
  {
    indicator: "800",
    name: "Факт",
    unit: "шт.",
  },
];
