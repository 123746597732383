import { AccessTime } from "@mui/icons-material";
import { Accordion, AccordionDetails, Box, styled } from "@mui/material";

export const BoxWrapper = styled(Box)(({ theme }) => ({
  background: theme.palette.tabs.backgroundContent,
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
}));

export const AccordionWrapper = styled(Accordion)(({ theme }) => ({
  background: "none",
  boxShadow: "none",
  ".MuiAccordionSummary-expandIconWrapper": {
    "& > div": {
      borderRadius: theme.spacing(0.5),
      width: theme.spacing(4),
      height: theme.spacing(4),
      background: theme.palette.button.collapseIcon,
      "& > svg": {
        fill: theme.palette.text.primary,
      },
    },
  },
  ".MuiAccordionSummary-content": {
    margin: "1.5rem 0",
  },
  "&:before": {
    background:
      theme.palette.mode === "dark"
        ? theme.palette.divider
        : theme.palette.table.dividerLight,
  },
  "&.Mui-expanded&:not(:first-of-type)": {
    borderTop: `1px solid ${
      theme.palette.mode === "dark"
        ? theme.palette.divider
        : theme.palette.table.dividerLight
    }`,
  },
}));

export const AccordionTitle = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(3),
  ".MuiTypography-root": {
    fontSize: theme.spacing(2.5),
  },
}));

export const AccordionDetailsStyled = styled(AccordionDetails)(({ theme }) => ({
  ".MuiTypography-root": {
    fontSize: theme.spacing(2.25),
    color: theme.palette.text.tertiary,
  },
  "& p": {
    lineHeight: theme.spacing(3.5),
  },
  "& li": {
    lineHeight: theme.spacing(3.5),
  },
}));

export const AccessTimeStyled = styled(AccessTime)(({ theme }) => ({
  background: theme.palette.button.selected,
  padding: theme.spacing(),
  borderRadius: theme.spacing(),
}));

export const FilesListWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  height: "100%",
  width: "100%",
  padding: theme.spacing(2),
  background: theme.palette.tabs.content,
  borderRadius: theme.spacing(2),
  "& > p": {
    display: "flex",
    justifyContent: "space-between",
  },
}));

export const FileList = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
}));
