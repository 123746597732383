import { Box, Button, Rating, styled } from "@mui/material";

export const FeedbackContentStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  boxSizing: "border-box",
  minHeight: theme.spacing(22.5),
  padding: theme.spacing(5, 4),
}));

export const FeedbackContentHeader = styled("h2")(({ theme }) => ({
  paddingBottom: theme.spacing(5),
  color: theme.palette.text.primary,
  fontSize: theme.spacing(2.25),
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: theme.spacing(2.5),
}));

export const FeedbackRatingText = styled("div")(({ theme }) => ({
  color: theme.palette.text.unitTextSelected,
  fontSize: theme.spacing(2),
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: theme.spacing(2.5),
}));

export const FeedbackContentRating = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

export const RatingStyled = styled(Rating)<{ isSmallsize?: boolean }>(
  ({ theme, isSmallsize = false }) => ({
    "& .MuiRating-icon": {
      transform: "scale(1, 1)",
      width: theme.spacing(isSmallsize ? 3 : 5),
      height: theme.spacing(isSmallsize ? 3 : 5),
      "& > svg": {
        width: theme.spacing(isSmallsize ? 3 : 5),
        height: theme.spacing(isSmallsize ? 3 : 5),
        ...(isSmallsize && {
          "& > path": {
            fill: theme.palette.border.selected,
            fillOpacity: 1,
          },
        }),
      },
    },
    "& .MuiRating-iconFilled": {
      color: theme.palette.border.selected,
      width: theme.spacing(isSmallsize ? 3 : 5),
      height: theme.spacing(isSmallsize ? 3 : 5),
    },
    "& .MuiRating-iconHover": {
      color: theme.palette.border.selected,
      width: theme.spacing(isSmallsize ? 3 : 5),
      height: theme.spacing(isSmallsize ? 3 : 5),
    },
    "& > label": {
      padding: "0 0.75rem",
    },
  })
);

export const FeedbackCommonStyled = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  "& > .grade": {
    paddingRight: theme.spacing(),
    color: theme.palette.text.primary,
    fontSize: theme.spacing(2.25),
    fontWeight: 600,
    lineHeight: theme.spacing(2.5),
  },
}));

export const FeedbackCommonButton = styled(Button)(({ theme }) => ({
  flex: 1,
  justifyContent: "flex-end",
  padding: 0,
  color: theme.palette.border.selected,
  fontSize: theme.spacing(2),
  fontWeight: 500,
  lineHeight: theme.spacing(2.5),
  textDecoration: "none",
  textTransform: "capitalize",
  "&:after": {
    display: "inline",
    width: "56%",
    height: theme.spacing(0.125),
    backgroundColor: theme.palette.border.selected,
    borderRadius: 1,
    content: "''",
    position: "absolute",
    bottom: theme.spacing(0.125),
    left: "auto",
  },
  "&:hover": {
    backgroundColor: "transparent",
  },
}));

export const FeedbackAnalyticsStyled = styled(Box)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(3, 4),
  gap: theme.spacing(4),
  alignItems: "center",
  "& > .analytic": {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& > .gradeAnalytic": {
      color: theme.palette.text.primary,
      fontSize: theme.spacing(5.5),
      "& > span": {
        fontSize: theme.spacing(2.75),
      },
    },
    "& > .answersAnalytic": {
      alignSelf: "flex-end",
      color: theme.palette.text.unitTextSelected,
      fontSize: theme.spacing(2),
      fontWeight: 500,
      lineHeight: theme.spacing(2.4),
    },
  },
  "& > .titleAnalytic": {
    alignSelf: "flex-start",
    color: theme.palette.text.unitTextSelected,
    fontSize: theme.spacing(2),
    fontWeight: 500,
    lineHeight: theme.spacing(2.4),
  },
  "& > .analyticSummary": {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(4),
    alignItems: "center",
    justifyContent: "space-between",
    "& > .rating": {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: theme.spacing(2),
      "& > .values": {
        display: "flex",
        gap: theme.spacing(0.5),
        flex: "1 0 20%",
        flexWrap: "nowrap",
        "& > .percent": {
          whiteSpace: "nowrap",
          color: theme.palette.text.primary,
          fontSize: theme.spacing(2.25),
          fontWeight: 600,
          lineHeight: theme.spacing(2.5),
        },
        "& > .count": {
          color: theme.palette.text.lightGrey,
          fontSize: theme.spacing(1.75),
          fontWeight: 400,
          lineHeight: theme.spacing(2.5),
        },
      },
      "& > .chart": {
        flex: "0 0 11rem",
        "& > div > .fillChart": {
          background: theme.palette.input.selected,
          boxShadow: `0 0 0.25rem 0 ${theme.palette.input.selected}`,
        },
      },
    },
  },
}));
