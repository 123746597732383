import { Box, styled } from "@mui/material";

import { IBoxStyledProps, IRegionGridCellProps } from "../lib/types";

export const BoxStyled = styled(Box, {
  shouldForwardProp: (prop) => !["fitHeight"].includes(prop.toString()),
})<IBoxStyledProps>(({ theme, fitHeight }) => ({
  flexGrow: 1,
  marginTop: theme.spacing(2),
  overflow: fitHeight ? "hidden" : undefined,
}));

export const RegionGrid = styled("div")(({ theme }) => ({
  display: "grid",
  height: "100%",
  gridTemplateRows: "minmax(200px, auto)",
  gridTemplateColumns: "repeat(5, 1fr)",
  gridGap: theme.spacing(2),
}));

export const RegionGridCell = styled("div")<IRegionGridCellProps>(
  ({ adjustHeight, isHighlight, theme }) => ({
    padding: theme.spacing(0.25), // для Card elevation (shadow)
    overflow: "hidden",
    borderRadius: theme.spacing(),
    ...(isHighlight && {
      background: "rgba(191, 218, 255, 0.3)",
      boxShadow: "0px 0px 19px #49B4EF",
      "& > .MuiPaper-root": {
        background: "transparent",
      },
    }),
  })
);

export const RegionGridRate = styled(RegionGrid)({
  gridTemplateColumns: "repeat(3, 1fr)",
  gridTemplateAreas: `"Achievements ProjectsTable ProjectsTable"`,
  "@media (max-width: 137.5rem)": {
    gridTemplateColumns: "minmax(43.75rem, 1fr) repeat(2, 1fr)",
    gridTemplateAreas: `"Achievements ProjectsTable ProjectsTable"`,
  },
});

export const RegionGridManufacturing = styled(RegionGrid)({
  gridTemplateAreas: `"DistrictValues VED VED Budget Budget"`,
});

export const RegionGridInvestment = styled(RegionGrid)({
  gridTemplateAreas: `"DistrictValues VED VED ProjectsTable ProjectsTable"`,
});

export const RegionGridProfit = styled(RegionGrid)({
  height: "100%",
  overflow: "unset",
  gridTemplateAreas:
    `"DistrictValues TripleCell TripleCell TripleCell TripleCell" ` +
    `"DistrictValues AverageSalary AverageSalary KKT KKT" `,
});

export const RegionGridEmployed = styled(RegionGrid)({
  height: "100%",
  overflow: "unset",
  gridTemplateAreas:
    `"DistrictValues TripleCell1 TripleCell1 TripleCell1 TripleCell1" ` +
    `"DistrictValues TripleCell2 TripleCell2 TripleCell2 TripleCell2" `,
});

export const RegionGridTripleCell = styled("div")(({ theme }) => ({
  height: "100%",
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  gap: theme.spacing(2),
}));
