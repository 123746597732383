import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { MODEL_IDS, MODELS } from "@/store/scheme/olap";

import { shareRatingArray, totalRatingArray } from "../lib/constants";
import {
  IFeedbackPayload,
  IFeedbackRequest,
  IFeedbackState,
} from "../lib/types";
import { getAnalyticsFeedback, postFeedback } from "./api";

export const initialState: IFeedbackState = {
  isOpenFeedback: false,
  isAnalyticSummary: false,
  success: false,
  fetching: false,
  error: null,
  analyticsData: undefined,
  analtyticsFetching: false,
  analyticsError: null,
};

export const extraActionsFeedback = {
  post: createAsyncThunk(
    "feedback/post",
    async (feedbackData: IFeedbackRequest) => await postFeedback(feedbackData)
  ),
  postAnalytics: createAsyncThunk(
    "feedback/analytics/post",
    async () => await getAnalyticsFeedback()
  ),
};

const feedbackSlice = createSlice({
  name: "feedback",
  initialState,
  reducers: {
    setFeedback: (state, action: PayloadAction<IFeedbackPayload>) => {
      state.isOpenFeedback = action.payload.isOpen;
      state.isAnalyticSummary = action.payload.isAnalytic;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(extraActionsFeedback.post.pending, (state) => {
        state.success = false;
        state.fetching = true;
      })
      .addCase(extraActionsFeedback.postAnalytics.pending, (state) => {
        state.analtyticsFetching = true;
        state.analyticsData = undefined;
        state.analyticsError = null;
      })
      .addCase(extraActionsFeedback.post.fulfilled, (state, action) => {
        state.success = true;
        state.fetching = false;
        state.error = null;
      })
      .addCase(
        extraActionsFeedback.postAnalytics.fulfilled,
        (state, action) => {
          //TODO: Поправить типизацию и оптимизировать получение данных
          const model = MODELS[MODEL_IDS.INCIDENT];
          state.analyticsData = action.payload.reduce((prev, cur) => {
            const dimId = cur.dims[model.dimId];
            if (!prev) prev = {};
            if (["128", "134"].includes(dimId?.code)) {
              if (!prev?.middleRating) {
                prev.middleRating = [];
              }
              prev.middleRating.push({
                id: dimId?.id,
                name: (dimId?.attributeVals as any)?.name,
                unit: (dimId?.attributeVals as any)?.["unit_measure"],
                count: dimId.indicatorVals?.[model.indexes.fact]?.sum,
              });
            }

            if (
              [...shareRatingArray, ...totalRatingArray].includes(dimId?.code)
            ) {
              if (!prev?.ratings) prev.ratings = {};
              const ratingData = {
                id: dimId?.id,
                name: (dimId?.attributeVals as any)?.name
                  ?.match(/[0-9]$/)
                  ?.join(),
                unit: (dimId?.attributeVals as any)?.["unit_measure"],
                count: dimId.indicatorVals?.[model.indexes.fact]?.sum,
              };
              if (shareRatingArray.includes(dimId?.code)) {
                if (!prev.ratings?.shareRating) prev.ratings.shareRating = [];
                prev.ratings.shareRating.push(ratingData);
              } else {
                if (!prev.ratings?.totalRating) prev.ratings.totalRating = [];
                prev.ratings.totalRating.push(ratingData);
              }
            }

            if (dimId?.code === "122") {
              if (!prev?.users) prev.users = [];
              prev.users.push({
                userCode: cur.dims[model.dataRegionId].code,
                period: (cur.attributeVals as any)?.["NAME"],
              });
            }

            return prev;
          }, {} as any);
          state.analtyticsFetching = false;
          state.analyticsError = null;
        }
      )
      .addCase(extraActionsFeedback.post.rejected, (state, action) => {
        state.fetching = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(extraActionsFeedback.postAnalytics.rejected, (state, action) => {
        state.analtyticsFetching = false;
        state.analyticsData = undefined;
        state.analyticsError = action.error;
      });
  },
});

export const { setFeedback } = feedbackSlice.actions;

export const reducer = feedbackSlice.reducer;

export default feedbackSlice;
