import { useAppSelector } from "@store/redux/hooks";
import { FC, useRef } from "react";
import { useLocation } from "react-router";

import { GridCardButton } from "@/shared/GridCardButton/ui/GridCardButton";
import { useOpenIncident } from "@/shared/Incident/hooks/useOpenIncident";
import { IncidentCreateButton } from "@/shared/Incident/ui/IncidentCreateButton/IncidentCreateButton";
import { failNoText } from "@/shared/lib/constants";

import { IGridCard, STEPS } from "../lib/types";
import {
  CardContent,
  CardDate,
  CardHeader,
  CardSubTitle,
  CardTitle,
  GridCardStyled,
  Unit,
} from "./GridCard.styled";

export const GridCard: FC<IGridCard> = ({
  data,
  children,
  subTitle,
  closeIcon,
  noCloseButton,
  selected,
  gridArea,
  arrow,
  setSelectedTabId,
  isHighlight,
  noBorder,
  dateLeft,
  compactTitle,
  sx,
  direction = "column",
  className = "",
  classNameContent = "",
  onClick,
  onComponentClick,
}) => {
  const { pathname } = useLocation();
  const { enabled } = useAppSelector((state) => state.controls.achievement);
  const { saveStep } = useAppSelector((state) => state.ui.tabs);
  const showRef = useRef<HTMLDivElement | null>(null);
  const { handleChange } = useOpenIncident();

  const handleClick = () => {
    setSelectedTabId(data.id);
    if (onComponentClick && onClick) {
      onClick(data.id);
    }
  };

  const checkNeedStep = (text: string = "") => {
    const isCheckProject =
      /^по проектам/.test(text) && saveStep === STEPS.PROJECTS;
    const isCheckGoals = /^по целям/.test(text) && saveStep === STEPS.GOALS;
    return isCheckProject || isCheckGoals;
  };
  // Так как ширина табов динамически меняется => вычисляем позицию кнопки
  const getPositionIncident = () => {
    return (data?.id !== "vdlProjects" &&
      [STEPS.PROJECTS, STEPS.GOALS].includes(saveStep)) ||
      pathname.startsWith("/region") ||
      !enabled
      ? 12
      : 62;
  };

  return (
    <GridCardStyled
      key={data?.id}
      ref={showRef}
      selected={selected}
      gridArea={gridArea}
      noBorder={noBorder}
      arrow={arrow}
      isHighlight={isHighlight}
      needStep={checkNeedStep(data?.text)}
      onClick={handleClick}
      sx={{
        ...sx,
        flexDirection: direction,
      }}
    >
      <CardHeader className="parentIncident">
        <CardTitle
          variant="subtitle2"
          className={className}
          selected={selected}
          arrow={arrow}
          isAchievement={!!subTitle}
        >
          {data?.text && subTitle && (
            <CardSubTitle
              compactTitle={compactTitle}
              splitLines={
                saveStep === STEPS.MAIN_FULL || data?.text === "по проектам"
              }
              selected={selected}
              arrow={arrow}
            >
              {subTitle + " "}
            </CardSubTitle>
          )}

          {`${data?.text ?? failNoText}`}

          {data?.unit && <Unit selected={selected}>{`, ${data.unit}`}</Unit>}
        </CardTitle>

        <IncidentCreateButton
          className="hoverIncident"
          posRight={getPositionIncident()}
          style={{ zIndex: 11 }}
          onClick={(event) => {
            handleChange(
              event,
              showRef?.current,
              `${subTitle ?? ""} ${data?.text}`,
              data?.date
            );
          }}
        />

        {!noCloseButton && onClick && (
          <GridCardButton
            selected={selected}
            turnOff={closeIcon!!}
            handleClick={() => onClick(data?.id)}
          />
        )}
      </CardHeader>

      {children && (
        <CardContent
          className={classNameContent}
          sx={{ ...(direction === "row" && { justifyContent: "flex-end" }) }}
        >
          {children}
        </CardContent>
      )}

      {data?.date && (
        <CardDate
          selected={selected}
          prevPeriod={!!data?.prevPeriod}
          dateLeft={dateLeft}
        >
          {data?.date}
        </CardDate>
      )}

      {!!data?.prevPeriod && (
        <CardDate selected={selected} dateLeft={dateLeft}>
          {data?.prevPeriod}
        </CardDate>
      )}
    </GridCardStyled>
  );
};
