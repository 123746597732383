import { TableBody, TableCell, TableRow } from "@mui/material";
import {
  CardListContent,
  CardListTitle,
  CardListTitleIcon,
} from "@shared/CardList/CardList.styled";
import { FC, useRef } from "react";

import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { CustomScrollbar } from "@/shared/CustomScrollbar/ui/CustomScrollbar";
import { useOpenIncident } from "@/shared/Incident/hooks/useOpenIncident";
import { IncidentCreateButton } from "@/shared/Incident/ui/IncidentCreateButton/IncidentCreateButton";

import { IWidgetScrollbar } from "../lib/types";
import {
  CustomScrollbarWrapper,
  TableHeadStyled,
  TableStyled,
  WrapperStyled,
} from "./WidgetScrollbar.styled";

export const WidgetScrollbar: FC<IWidgetScrollbar> = ({
  children,
  icon,
  table,
  header,
  autoHeight,
  heightMax,
  dateIncident,
  titleIncident = "",
  isOpenPopUp,
}) => {
  const showRef = useRef<HTMLDivElement | null>(null);
  const { handleChange } = useOpenIncident();
  const { isCheckRfFoPage } = useRegions();

  if (table) {
    return (
      <WrapperStyled ref={showRef}>
        {!autoHeight && (
          <IncidentCreateButton
            style={{ zIndex: 3, top: isCheckRfFoPage.isAll ? 9 : 15 }}
            className="hoverIncident"
            posRight={65}
            onClick={(event) => {
              handleChange(event, showRef?.current, titleIncident);
            }}
          />
        )}
        <CustomScrollbarWrapper isOpenPopUp={isOpenPopUp} isTable>
          <CustomScrollbar autoHeight={autoHeight} heightMax={heightMax}>
            <TableStyled stickyHeader>
              {(icon || header) && (
                <TableHeadStyled openWidgetTable={isOpenPopUp}>
                  <TableRow sx={{ height: isOpenPopUp ? 88 : 64 }}>
                    {icon && (
                      <TableCell align="center" sx={{ p: 0, width: 64 }}>
                        {icon}
                      </TableCell>
                    )}
                    {header}
                  </TableRow>
                </TableHeadStyled>
              )}
              <TableBody>{children}</TableBody>
            </TableStyled>
          </CustomScrollbar>
        </CustomScrollbarWrapper>
      </WrapperStyled>
    );
  }
  return (
    <WrapperStyled ref={showRef}>
      {header && (
        <CardListTitle openWidget={isOpenPopUp} className="parentIncident">
          {icon && <CardListTitleIcon>{icon}</CardListTitleIcon>}
          {header}
          {!isOpenPopUp && (
            <IncidentCreateButton
              className="hoverIncident"
              posRight={60}
              onClick={(event) => {
                handleChange(
                  event,
                  showRef?.current,
                  titleIncident,
                  dateIncident
                );
              }}
            />
          )}
        </CardListTitle>
      )}
      <CustomScrollbarWrapper isOpenPopUp={isOpenPopUp}>
        <CustomScrollbar autoHeight={autoHeight} heightMax={heightMax}>
          <CardListContent>{children}</CardListContent>
        </CustomScrollbar>
      </CustomScrollbarWrapper>
    </WrapperStyled>
  );
};
