import React, { ReactElement, ReactNode, useState } from "react";

import { ToolTipStyled } from "@/shared/Budget/ui/Budget.styled";
import { COMPARE_TYPE } from "@/shared/CompareControl/lib/types";

import { PASSPORT_DESC, PASSPORT_TABS } from "../lib/constants";
import {
  ToggleButtonGroupStyled,
  ToggleButtonStyled,
} from "../ui/IndicatopPassport.styled";

export const usePassportHeader = () => {
  const [activeIcon, setActiveIcon] = useState<PASSPORT_TABS>(
    PASSPORT_TABS.TABLE
  );
  const [compare, setCompare] = useState<COMPARE_TYPE>(COMPARE_TYPE.RF);

  const resetState = () => {
    setActiveIcon(PASSPORT_TABS.TABLE);
    setCompare(COMPARE_TYPE.RF);
  };

  const handleChange = (
    e: React.MouseEvent<HTMLElement>,
    newValue: PASSPORT_TABS | COMPARE_TYPE
  ) => {
    if (newValue) {
      switch (true) {
        case ["TABLE", "GRAPH"].includes(newValue):
          setActiveIcon(newValue as PASSPORT_TABS);
          break;
        case ["RF", "FO"].includes(newValue):
          setCompare(newValue as COMPARE_TYPE);
          break;
        default:
          resetState();
          break;
      }
    }
  };

  const renderButtonGroup = (
    passportGroup: Record<string, React.ReactNode>,
    value: string
  ) => {
    return (
      <ToggleButtonGroupStyled
        size="small"
        exclusive
        value={value}
        onChange={handleChange}
      >
        {Object.keys(passportGroup).map((index: string) => (
          <ToggleButtonStyled
            isBig={value === compare}
            value={index}
            key={index}
          >
            {["TABLE", "GRAPH"].includes(index) ? (
              <ToolTipStyled key={index} title={PASSPORT_DESC[index]}>
                {passportGroup[index] as ReactElement<any, any> & ReactNode}
              </ToolTipStyled>
            ) : (
              passportGroup[index]
            )}
          </ToggleButtonStyled>
        ))}
      </ToggleButtonGroupStyled>
    );
  };

  return { activeIcon, compare, handleChange, resetState, renderButtonGroup };
};
