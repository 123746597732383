import { Grid, Stack, styled, Typography } from "@mui/material";

import { colorBasedOnValue } from "@/pages/MainPage/lib/helpers";

export const GridContainer = styled(Grid)(({ theme }) => ({
  marginTop: 0,
  height: "95%",
}));

export const StackStyled = styled(Stack, {
  shouldForwardProp: (prop) =>
    !["isOnlyOne", "isOnlyProject"].includes(prop.toString()),
})<{
  isOnlyOne?: boolean;
  isOnlyProject?: boolean;
}>(({ theme, isOnlyOne, isOnlyProject }) => ({
  justifyContent: "space-between",
  background:
    isOnlyProject && theme.palette.mode === "light"
      ? theme.palette.indicatorCard.backgroundColor
      : theme.palette.graphs.graphContainer,
  padding: theme.spacing(3, 3.5, 1),
  boxSizing: "border-box",
  boxShadow: theme.spacing(0, 0, 1.75, -1.5),
  borderRadius: theme.spacing(2),
  minHeight: isOnlyOne ? 686 : 335,
  height: "100%",
}));

export const ChartArea = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  flex: "1 1 auto",
  width: "100%",
  height: "100%",
});

export const TextBlock = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "space-between",
  gap: theme.spacing(1.25),
  height: theme.spacing(3.5),
}));

export const TypographyStyled = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: theme.spacing(2.5),
  lineHeight: theme.spacing(2.5),
}));

export const StatisticContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: "0 0 auto",
  gap: theme.spacing(0.5),
  marginLeft: theme.spacing(),
}));

export const Value = styled("div")<{ color?: string }>(({ theme, color }) => ({
  display: "flex",
  alignItems: "baseline",
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.spacing(2.5),
  color: colorBasedOnValue(theme, color ? +color : null),
}));

export const ValueNumber = styled("span")(({ theme }) => ({
  fontSize: theme.spacing(4),
  lineHeight: theme.spacing(3.5),
  fontWeight: 600,
}));

export const Unit = styled("span")({
  opacity: 0.7,
});

export const ReactEChartsWrapper = styled("div")<{
  widthLabel: string | number;
  isOnlyOne?: boolean;
}>(({ widthLabel }) => ({
  width: widthLabel,
  height: "100%",
}));
