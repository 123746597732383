import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { prepareAttributes } from "@/shared/lib/heplers";
import { IRegionLeaderAttributeVals } from "@/store/scheme/olap";

import { ILeadersState } from "../lib/types";
import { getLeaders } from "./api";

export const initialState: ILeadersState = {
  items: undefined,
  fetching: true,
  error: null,
};

export const extraActionsLeaders = {
  get: createAsyncThunk("regions/leaders/get", async () => await getLeaders()),
};

const leadersSlice = createSlice({
  name: "regions/leaders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActionsLeaders.get.pending, (state) => {
        state.items = undefined;
        state.fetching = true;
      })
      .addCase(extraActionsLeaders.get.fulfilled, (state, action) => {
        state.items = action.payload.map((item) => ({
          ...item,
          attributeVals: prepareAttributes<IRegionLeaderAttributeVals>(
            item.attributeVals
          ),
        }));
        state.fetching = false;
        state.error = null;
      })
      .addCase(extraActionsLeaders.get.rejected, (state, action) => {
        state.fetching = false;
        state.error = action.error;
      });
  },
});

export const reducer = leadersSlice.reducer;

export default leadersSlice;
