import React, { FC, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";

import { useRouter } from "@/app/hooks/useRouter";
import { isDevBranch } from "@/shared/lib/constants";
import { TabPanelUnstyled, TabUnstyled } from "@/shared/Tabs/Tabs.styled";
import { TAB_LEVEL } from "@/store/scheme/region";

import { IProjectTabsProps } from "../../lib/types";
import { ProjectCharacteristicsTable } from "../tables/ProjectCharacteristicsTable/ProjectCharacteristicsTable";
import { ProjectFinancesTable } from "../tables/ProjectFinancesTable/ProjectFinancesTable";
import { ProjectIndicatorsTable } from "../tables/ProjectIndicatorsTable/ProjectIndicatorsTable";
import { ProjectResultsTable } from "../tables/ProjectResultsTable/ProjectResultsTable";
import { ProjectRisksTable } from "../tables/ProjectRisksTable/ProjectRisksTable";
import { ProjectTabsStyled, TabListStyled } from "./ProjectTabs.styled";

export const ProjectTabs: FC<IProjectTabsProps> = ({
  onTabChange,
  handleLegendCallback,
}) => {
  const { id, tabLevel = TAB_LEVEL.ACHIEVE } = useParams<"id" | "tabLevel">();
  const [tab, setTab] = useState<string>(tabLevel);
  const { navigate } = useRouter();

  const handleTabChange = useCallback(
    (
      event: React.SyntheticEvent<Element, Event> | null,
      value: string | number | boolean | null
    ) => {
      setTab(value as TAB_LEVEL);
      onTabChange?.(value as TAB_LEVEL);
      navigate(`/projects/${id}/${value}`);
    },
    [onTabChange, id]
  );

  useEffect(() => {
    if (tabLevel) {
      setTab(tabLevel);
    }
  }, [tabLevel]);

  return (
    <ProjectTabsStyled value={tab} onChange={handleTabChange}>
      <TabListStyled>
        <TabUnstyled value={TAB_LEVEL.ACHIEVE} afterElement>
          Достижение
        </TabUnstyled>
        <TabUnstyled value={TAB_LEVEL.INDICATOR}>Показатели</TabUnstyled>
        <TabUnstyled value={TAB_LEVEL.CHECKPOINT}>
          Контрольные точки
        </TabUnstyled>
        <TabUnstyled value={TAB_LEVEL.FINANCE}>Финансирование</TabUnstyled>
        {isDevBranch && (
          <TabUnstyled value={TAB_LEVEL.RISKS}>Риски реализации</TabUnstyled>
        )}
      </TabListStyled>

      <TabPanelUnstyled value={TAB_LEVEL.ACHIEVE}>
        <ProjectCharacteristicsTable />
      </TabPanelUnstyled>
      <TabPanelUnstyled value={TAB_LEVEL.INDICATOR}>
        <ProjectIndicatorsTable />
      </TabPanelUnstyled>
      <TabPanelUnstyled value={TAB_LEVEL.CHECKPOINT}>
        <ProjectResultsTable handleLegendCallback={handleLegendCallback} />
      </TabPanelUnstyled>
      <TabPanelUnstyled value={TAB_LEVEL.FINANCE}>
        <ProjectFinancesTable />
      </TabPanelUnstyled>
      {isDevBranch && (
        <TabPanelUnstyled value={TAB_LEVEL.RISKS}>
          <ProjectRisksTable />
        </TabPanelUnstyled>
      )}
    </ProjectTabsStyled>
  );
};
