import { FC, useCallback, useMemo } from "react";
import { useParams } from "react-router";

import { useRouter } from "@/app/hooks/useRouter";
import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { BlockWpapper } from "@/shared/BlockWrapper/BlockWrapper";
import { Coat } from "@/shared/Coat/ui/Coat";
import { CompareIcons } from "@/shared/CompareIcons/ui/CompareIcons";
import { useSocEconomy } from "@/shared/DistrictValues/hooks/useSocEconomy";
import { defaultNoDataText, REGION_COUNTRY_CODE } from "@/shared/lib/constants";
import { formatNumber } from "@/shared/lib/heplers";
import { LinkStyled } from "@/shared/Link/Link.styled";
import { useRegionPopUp } from "@/shared/RegionPopUp/hooks/useRegionPopUp";
import { REGION_TABLES } from "@/shared/RegionPopUp/lib/types";
import { Sceleton } from "@/shared/Sceleton/ui/Sceleton";
import { useFuturePlan } from "@/shared/Table/hooks/useFuturePlan";
import { ValueDate } from "@/shared/ValueDate/ValueDate";
import { WidgetScrollbar } from "@/shared/WidgetScrollbar/ui/WidgetScrollbar";
import { useAppSelector } from "@/store/redux/hooks";
import { MODEL_IDS, MODELS } from "@/store/scheme/olap";
import { useIndicatorPassport } from "@/widgets/IndicatorPassport/hooks/useIndicatorPassport";

import { getUrl, sortDistrictRows, titleWidgets } from "../lib/heplers";
import { IDistrictValuesProps, IDistrictValuesRow } from "../lib/types";
import {
  DistrictValuesCoat,
  DistrictValuesDate,
  DistrictValuesHeader,
  DistrictValuesHL,
  DistrictValuesRow,
  DistrictValuesTitle,
  DistrictValuesTitleText,
  DistrictValuesValue,
} from "./DistrictValues.styled";

export const DistrictValues: FC<IDistrictValuesProps> = ({
  indicatorId,
  icon,
  isOpenPopUp,
}) => {
  const { id, tabId } = useParams<"id" | "tabId">();
  const { getPath } = useRouter();
  const { data } = useSocEconomy();
  const { achievements, achievementsFiltered, achievementsFetching } =
    useAppSelector((state) => state.ui.tabs);
  const {
    getDistrictByRegion,
    indicatorHighLow,
    isCheckRfFoPage,
    districtIds,
    regionIds,
  } = useRegions();
  const { isCheckFuturePlan } = useFuturePlan();
  const { openIndicatorPassport } = useIndicatorPassport();
  const model = MODELS[MODEL_IDS.KPI_SOC_ECONOMY];
  const district = getDistrictByRegion(id!);
  const title = titleWidgets(indicatorId);

  const isCheckTransition = (regionId: string) => {
    return regionId === REGION_COUNTRY_CODE || districtIds.includes(regionId);
  };

  const isCheckRolesRegions = (regionId: string) => {
    return !regionIds.includes(regionId);
  };

  const [mainData, date] = useMemo(() => {
    const mainData = (
      indicatorId === "1" ? achievementsFiltered : achievements
    )?.[indicatorId];
    const date = mainData?.[REGION_COUNTRY_CODE]?.REPORT_DATE;
    return [mainData, date];
  }, [achievements, achievementsFiltered, indicatorId, id]);

  const { PopUp, PopUpButton, popupHeightMax }: any = useRegionPopUp(
    REGION_TABLES.DISTRICT_VALUES,
    isOpenPopUp,
    <>
      <img src={icon} alt={title} />
      <div>
        {title}
        {date && (
          <DistrictValuesDate>
            <ValueDate isOpenPopUp={!isOpenPopUp}>{date}</ValueDate>
          </DistrictValuesDate>
        )}
      </div>
    </>,
    undefined,
    undefined,
    544
  );
  const getPathUrl = (regionId: string) => {
    const url = getUrl({ regionId, id, tabId });
    return getPath(url);
  };

  const mainRows: IDistrictValuesRow[] = useMemo(() => {
    if (mainData) {
      const result: IDistrictValuesRow[] = [
        {
          region: REGION_COUNTRY_CODE,
          parent: true,
          title: "РФ",
          value:
            mainData?.[REGION_COUNTRY_CODE]?.indicatorVals?.[
              model.indexes.fact
            ],
        },
      ];

      if (district) {
        const {
          id: districtId,
          attributeVals: { SHORT_NAME: shortName },
        } = district;
        const dataValue = mainData?.[districtId]?.indicatorVals;

        result.push({
          region: districtId,
          parent: true,
          title: shortName,
          value: dataValue?.[model.indexes.fact],
          valueHL: dataValue?.[model.indexes[indicatorHighLow]],
        });
      }
      return result;
    }
    return [];
  }, [mainData, district, indicatorHighLow]);

  const rows: IDistrictValuesRow[] = useMemo(() => {
    const rowsBeforeSort = Object.keys(data || {}).reduce((prev, regionId) => {
      const regionData = data?.[regionId]?.[indicatorId];
      let intermediateValue = {} as IDistrictValuesRow;
      if (regionData) {
        intermediateValue = {
          id: regionId,
          title: regionData?.[isCheckRfFoPage.isAll ? "SHORT_NAME" : "name"],
          region: regionData?.code_olap,
          value: regionData?.indicatorVals?.[model.indexes.fact],
          valueHL: regionData?.indicatorVals?.[model.indexes[indicatorHighLow]],
        };
        prev.push(intermediateValue);
      }
      return prev;
    }, [] as IDistrictValuesRow[]);
    return sortDistrictRows(rowsBeforeSort, id);
  }, [data, id, indicatorHighLow, indicatorId, isCheckRfFoPage.isAll]);

  const handleOpenPassport = useCallback(() => {
    openIndicatorPassport(indicatorId);
  }, [indicatorId, openIndicatorPassport]);

  if (data === undefined || achievementsFetching) {
    return <Sceleton type="line" />;
  }

  return (
    <WidgetScrollbar
      titleIncident={`${title} ${
        isCheckRfFoPage.isAll ? "(по федеральным округам)" : ""
      }`}
      dateIncident={date}
      icon={!isOpenPopUp && <img src={icon} alt={title} />}
      header={
        !isOpenPopUp && (
          <DistrictValuesHeader>
            <DistrictValuesTitleText onClick={handleOpenPassport}>
              {title}
              {date && (
                <DistrictValuesDate>
                  <ValueDate>{date}</ValueDate>
                </DistrictValuesDate>
              )}
            </DistrictValuesTitleText>
            <PopUpButton />
          </DistrictValuesHeader>
        )
      }
      heightMax={popupHeightMax}
      autoHeight={isOpenPopUp}
    >
      <BlockWpapper
        hasData={!!Object.keys([...mainRows, ...rows])?.length}
        noDataText={defaultNoDataText}
      >
        {[...mainRows, ...rows].map((row, index) => (
          <DistrictValuesRow
            isOpenPopUp={!!isOpenPopUp}
            key={`${row.region}_${row.title}_${index}`}
            parent={row.parent}
            isSelected={id === row.region}
          >
            <DistrictValuesCoat parent={row.parent}>
              <Coat
                isOpenPopUp={isOpenPopUp}
                region={row.region}
                parent={row.parent}
              />
            </DistrictValuesCoat>

            <DistrictValuesTitle isOpenPopUp={isOpenPopUp}>
              {(!isCheckFuturePlan && isCheckTransition(row?.region)) ||
              isCheckRolesRegions(row?.region) ? (
                row?.title
              ) : (
                <LinkStyled to={getPathUrl(row.region)}>{row.title}</LinkStyled>
              )}
            </DistrictValuesTitle>

            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {((row.valueHL ?? 0) > 0 || (row.valueHL ?? 0) < 0) && (
                <DistrictValuesHL isOpenPopUp={isOpenPopUp}>
                  {<CompareIcons isBig value={row.valueHL!!} />}
                </DistrictValuesHL>
              )}
              <DistrictValuesValue isOpenPopUp={isOpenPopUp}>
                {formatNumber(row.value, "%")}
              </DistrictValuesValue>
            </div>
          </DistrictValuesRow>
        ))}
      </BlockWpapper>

      <PopUp />
    </WidgetScrollbar>
  );
};
