import { useAppDispatch } from "@store/redux/hooks";

import { setControlRegion } from "../model/slices/regionControlSlice";

export const useRegionPage = () => {
  const dispatch = useAppDispatch();

  const setRegionId = (id: string) => {
    dispatch(setControlRegion(id));
  };

  return { setRegionId };
};
