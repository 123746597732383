import { combineReducers } from "@reduxjs/toolkit";

import allVdlSlice from "@/features/AchievementVdl/model/allVdlSlice";
import enterpriseSlice from "@/features/EnterpriseWidget/model/enterpriseSlice";
import { IKktSlice } from "@/features/KktWidget/lib/types";
import kktSlice from "@/features/KktWidget/model/kktSlice";
import { INationalProjectssSlice } from "@/features/NationalProjectsIndicators/lib/types";
import nationalProjectsSlice from "@/features/NationalProjectsIndicators/model/nationalProjectsSlice";
import vdlSlice from "@/features/VdlLeader/model/vdlSlice";
import { ILeadersState } from "@/features/VdlLeader/ui/Leader/lib/types";
import leadersSlice from "@/features/VdlLeader/ui/Leader/model/leadersSlice";
import {
  IProjectsSlice,
  IRiskProjectState,
} from "@/pages/ProjectsPage/lib/types";
import projectsSlice from "@/pages/ProjectsPage/model/slice/projectsSlice";
import riskProjectSlice from "@/pages/ProjectsPage/model/slice/riskProjectSlice";
import {
  IAllRegionsState,
  ISumFoProjectsState,
  ISumRfProjectsState,
  ISumRisksKpiState,
  ISumRisksRfFoState,
  ISumRisksTotalState,
} from "@/pages/RegionPage/lib/types";
import regionsSlice from "@/pages/RegionPage/model/slices/regionSlice";
import sumFoProjectsSlice from "@/pages/RegionPage/model/slices/sumProjectsByFoSlice";
import sumRfProjectsSlice from "@/pages/RegionPage/model/slices/sumProjectsByRfSlice";
import sumRfFoRisksSlice from "@/pages/RegionPage/model/slices/sumRfFoRisksSlice";
import sumRisksKpiSlice from "@/pages/RegionPage/model/slices/sumRisksKpiRfFo";
import sumRisksTotalSlice from "@/pages/RegionPage/model/slices/sumRisksTotalSlice";
import { IBudgetSlice } from "@/shared/Budget/lib/types";
import budgetSlice from "@/shared/Budget/model/budgetSlice";
import socEconomySlice from "@/shared/DistrictValues/model/socEconomySlice";
import { IGeneralIndicatorsSlice } from "@/shared/GeneralIndicators/lib/types";
import generalIndicatorsSlice from "@/shared/GeneralIndicators/model/slices/generalIndicatorsSlice";
import kpiSlice from "@/shared/GeneralIndicators/model/slices/kpiSlice";
import { IRegionsState } from "@/shared/lib/types";
import budgetGraphSlice from "@/widgets/IndicatorGraph/model/slices/budgetGraphSlice";
import kktGraphSlice from "@/widgets/IndicatorGraph/model/slices/kktGraphSlice";
import kpiGraphSlice from "@/widgets/IndicatorGraph/model/slices/kpiGraphSlice";
import { IPassportState } from "@/widgets/IndicatorPassport/lib/types";
import passportSlice from "@/widgets/IndicatorPassport/model/passportSlice";

export interface IRegionsReducer {
  leaders: ILeadersState;
  regions: IAllRegionsState;
  vdl: IRegionsState;
  generalIndicators: IGeneralIndicatorsSlice;
  budget: IBudgetSlice;
  nationalProjects: INationalProjectssSlice;
  projects: IProjectsSlice;
  socEconomy: IRegionsState;
  kpi: IRegionsState;
  kkt: IKktSlice;
  passport: IPassportState;
  allVdl: IRegionsState;
  sumRfProjects: ISumRfProjectsState;
  sumFoProjects: ISumFoProjectsState;
  enterprise: IRegionsState;
  kpiGraph: IRegionsState;
  budgetGraph: IRegionsState;
  kktGraph: IRegionsState;
  sumTotalRisk: ISumRisksTotalState;
  sumRfFoRisk: ISumRisksRfFoState;
  riskProject: IRiskProjectState;
  risksKpi: ISumRisksKpiState;
}

const reducer = combineReducers<IRegionsReducer>({
  leaders: leadersSlice.reducer,
  regions: regionsSlice.reducer,
  vdl: vdlSlice.reducer,
  generalIndicators: generalIndicatorsSlice.reducer,
  budget: budgetSlice.reducer,
  nationalProjects: nationalProjectsSlice.reducer,
  projects: projectsSlice.reducer,
  socEconomy: socEconomySlice.reducer,
  kpi: kpiSlice.reducer,
  kkt: kktSlice.reducer,
  passport: passportSlice.reducer,
  allVdl: allVdlSlice.reducer,
  sumRfProjects: sumRfProjectsSlice.reducer,
  sumFoProjects: sumFoProjectsSlice.reducer,
  enterprise: enterpriseSlice.reducer,
  kpiGraph: kpiGraphSlice.reducer,
  budgetGraph: budgetGraphSlice.reducer,
  kktGraph: kktGraphSlice.reducer,
  sumTotalRisk: sumRisksTotalSlice.reducer,
  sumRfFoRisk: sumRfFoRisksSlice.reducer,
  riskProject: riskProjectSlice.reducer,
  risksKpi: sumRisksKpiSlice.reducer,
});

export default reducer;
