import ReorderIcon from "@mui/icons-material/Reorder";
import Timeline from "@mui/icons-material/Timeline";
import React from "react";

import { COMPARE_TYPE } from "@/shared/CompareControl/lib/types";

import { Columns } from "./types";

export const indicatorsForPopUpId: Record<string, string> = {
  BIRTH_RATE: "84",
  DEATH_RATE: "86",
  INFLATION: "50",
  MIGRATION: "90",
  NATALITY: "82",
  SELF_EMPLOYED: "92",
  UNEMPLOYMENT_RATE: "88",
  AVERAGE_MONTH_NOMINAL_SALARY: "52",
  AVERAGE_SALARY: "46",
  NOMINAL_INCOME: "48",
  KKT: "110", // берутся данные из useKKT
};

export const indicatorsByOpenPassport = [
  "76",
  "54",
  "44",
  "25",
  "27",
  "31",
  "32",
  "78",
  "7",
  "5",
  "6",
  "29",
  "117",
  "132",
  "26",
  "11",
  "13",
];

export const indicatorsEnterprises = ["14", "16", "18"];

export const indicatorByOpenPassportForKpi = [
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "56",
  "57",
  "58",
  "59",
  "60",
  "61",
  "62",
  "63",
  "64",
];
// Индикаторы виджета Бюджет
export const indicatorsByOpenPassportForBudget = [
  "8",
  "9",
  "94",
  "95",
  "96",
  "97",
  "98",
  "99",
];

export enum PASSPORT_TABS {
  TABLE = "TABLE",
  GRAPH = "GRAPH",
}

export const PASSPORT_BUTTONS: Record<string, React.ReactNode> = {
  [PASSPORT_TABS.TABLE]: <ReorderIcon />,
  [PASSPORT_TABS.GRAPH]: <Timeline />,
};

export const PASSPORT_DESC: Record<string, string> = {
  [PASSPORT_TABS.TABLE]: "Паспорт показателя",
  [PASSPORT_TABS.GRAPH]: "Динамика за 5 лет",
};

export const PASSPORT_COMPARE: Record<string, string> = {
  [COMPARE_TYPE.RF]: "РФ",
  [COMPARE_TYPE.FO]: "ФО",
};

// Ключи из присылаемого объекта
export const rowsNamePassport: Record<string, string> = {
  description: "Описание",
  update_period: "Периодичность обновления",
  update_date: "Дата обновления в Дашборде",
  next_update_date: "Дата следующего обновления в Дашборде",
  NAME: "Идентификационный номер",
  foiv: "Владелец",
  name_resp_person: "Ответственное лицо",
  phone_resp_person: "Телефон ответственного лица",
  email_resp_person: "Адрес электронной почты ответственного лица",
};

export const columns: Columns[] = [
  {
    id: "number",
    title: "N",
    width: "56",
  },
  {
    id: "name",
    title: "Название поля паспорта",
    width: "316",
  },
  {
    id: "description",
    title: "Значение поля паспорта",
  },
];
