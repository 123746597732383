import { TAB_ID } from "@store/scheme/region";
import { useCallback, useEffect, useMemo } from "react";
import { useParams } from "react-router";

import { useTabs } from "@/app/hooks/useTabs";
import { useRegion } from "@/pages/RegionPage/hooks/useRegion";
import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { useCountryTabs } from "@/pages/RegionPage/ui/RegionTabs/hooks/useCountryTabs";
import { AchievementsTabCard } from "@/shared/AchievementTabCard/ui/AchievementsTabCard";
import { DynamicsTabCard } from "@/shared/DynamicsTabCard/ui/DynamicsTabCard";

import { useRegionPageTabs } from "../hooks/useRegionPageTabs";

export const RegionTabs = () => {
  const { id, tabId } = useParams<"id" | "tabId">();
  const { getDistrictByRegion } = useRegions();
  const region = useRegion(id!);
  const district = getDistrictByRegion(region?.id!);

  const { activeTab } = useTabs();
  const {
    requestAllAchievementsTabs,
    limitedForAnalystTabs,
    achEnabled,
    switchOverTabs,
    updateTabAndSetUrl,
  } = useRegionPageTabs();

  const { getRegionTabsData, tabFetching } = useCountryTabs();

  const targetId = id ?? "";

  const onSelectTab = useCallback(
    (tabID: TAB_ID) => {
      if (limitedForAnalystTabs(tabID)) {
        return;
      }
      updateTabAndSetUrl({ tabByUrl: tabID, tabByUpdate: tabID });
    },
    [limitedForAnalystTabs, updateTabAndSetUrl]
  );

  const tabs = useMemo(() => {
    return getRegionTabsData(targetId);
  }, [getRegionTabsData, targetId]);

  useEffect(() => {
    switchOverTabs(tabId as TAB_ID);
  }, [tabId]);

  useEffect(() => {
    if (id) {
      requestAllAchievementsTabs(id, district || {});
    }
  }, [id, district, requestAllAchievementsTabs]);

  return (
    <>
      {achEnabled && (
        <AchievementsTabCard
          tabs={tabs}
          selectedSubjectId={targetId}
          selectedTabId={activeTab}
          setSelectedTabId={onSelectTab}
          tabFetching={tabFetching}
        />
      )}

      {!achEnabled && (
        <DynamicsTabCard
          tabs={tabs}
          selectedSubjectId={targetId}
          selectedTabId={activeTab}
          setSelectedTabId={onSelectTab}
          tabFetching={tabFetching}
        />
      )}
    </>
  );
};
