import MapLegend01 from "@assets/MapLegend01.svg";
import MapLegend01Light from "@assets/MapLegend01_light.svg";
import MapLegend90 from "@assets/MapLegend90.svg";
import MapLegend90Light from "@assets/MapLegend90_light.svg";
import { useTheme } from "@mui/material";
import { TAB_ID } from "@store/scheme/region";

import { MapLegendProps } from "../../lib/types";
import { Legend } from "./MapLegend.styled";

export const MapLegend = ({ selectedTabId }: MapLegendProps) => {
  const theme = useTheme();

  switch (true) {
    case selectedTabId === TAB_ID.RISKS:
      return (
        <Legend
          src={theme.palette.mode === "dark" ? MapLegend01 : MapLegend01Light}
          alt="Map Legend 0 to 1"
        />
      );

    default:
      return (
        <Legend
          src={theme.palette.mode === "dark" ? MapLegend90 : MapLegend90Light}
          alt="Map Legend 90"
        />
      );
  }
};
