import { PaletteMode } from "@mui/material";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IModeState } from "../lib/types";

export const initialState: IModeState = {
  theme: "light",
  isDark: false,
};

const modeSlice = createSlice({
  name: "ui/mode",
  initialState,
  reducers: {
    modeSet: (state, action: PayloadAction<PaletteMode>) => {
      state.theme = action.payload;
      state.isDark = action.payload === "dark";
    },
  },
});

export const { modeSet } = modeSlice.actions;
export const reducer = modeSlice.reducer;

export default modeSlice;
