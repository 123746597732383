import { Button } from "@mui/material";
import { styled } from "@mui/material";

export const Control = styled("div")(({ theme }) => ({
  margin: theme.spacing(1, 0),
}));

export const SubmitButton = styled(Button)({
  width: `100%`,
});
