import { FC, useState } from "react";

import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { Icon } from "@/shared/CustomIcon/Icon.styled";
import { ProjectsFilter } from "@/shared/ProjectsFilter/ProjectsFilter";
import { useRegionPopUp } from "@/shared/RegionPopUp/hooks/useRegionPopUp";
import { REGION_TABLES } from "@/shared/RegionPopUp/lib/types";
import { useFuturePlan } from "@/shared/Table/hooks/useFuturePlan";
import { WidgetScrollbar } from "@/shared/WidgetScrollbar/ui/WidgetScrollbar";

import { ProjectTableIcon } from "../../../assets/svg_components/ProjectsTable";
import { useSumProjects } from "../hooks/useSumProjects";
import { IRegionProjectsProps } from "../lib/types";
import { RegionFoProjects } from "./RegionFoProjects/RegionFoProjects";
import {
  DateWrapper,
  HeaderTitle,
  HeaderTitleWrapper,
  SumProjectRowTitle,
  SumProjectRowTitleBig,
  TableCellStyled,
  TableRowHover,
} from "./RegionProjectsWidget.styled";
import { RegionRow } from "./RegionRow/RegionRow";

export const RegionProjectsWidget: FC<IRegionProjectsProps> = ({
  isOpenPopUp,
  container,
  selectedPeriod,
}) => {
  const [periodId, setPeriodId] = useState("");
  const { PopUpButton, PopUp, popupHeightMax }: any = useRegionPopUp(
    REGION_TABLES.PROJECTS_TABLE,
    isOpenPopUp,
    undefined,
    undefined,
    periodId
  );

  const { columns, rows, selectData } = useSumProjects(periodId);
  const { isCheckFuturePlan } = useFuturePlan();
  const { isCheckRfFoPage } = useRegions();

  const changeClick = (id: string) => {
    setPeriodId(id);
  };

  return (
    <WidgetScrollbar
      isOpenPopUp={isOpenPopUp}
      table
      titleIncident="Проекты"
      heightMax={popupHeightMax}
      autoHeight={!!isOpenPopUp}
      icon={
        <Icon size="big" background={false}>
          <ProjectTableIcon />
        </Icon>
      }
      header={
        <>
          <TableCellStyled
            sx={{ fontSize: "1.125rem" }}
            align="left"
            isOpenPopUp={!!isOpenPopUp}
          >
            <HeaderTitleWrapper isFlex={isCheckFuturePlan}>
              <HeaderTitle
                sx={
                  isOpenPopUp
                    ? { fontSize: "1.62rem", lineHeight: "1.56rem" }
                    : {}
                }
              >
                Проекты
              </HeaderTitle>

              {isCheckFuturePlan ? (
                <ProjectsFilter
                  selectValues={selectData}
                  handleClick={changeClick}
                  selectedPeriod={selectedPeriod}
                />
              ) : (
                <PopUpButton />
              )}
            </HeaderTitleWrapper>
          </TableCellStyled>
          {columns.map((col, index) => (
            <TableCellStyled
              key={col.id}
              sx={{
                minWidth: 144,
                fontSize: !!isOpenPopUp ? "1.25rem" : "1rem",
              }}
              isGray={true}
              isOpenPopUp={!!isOpenPopUp}
            >
              <div className="flexCell">
                {col.id === "date" && container === "rate" ? (
                  <>
                    <DateWrapper>{col.title}</DateWrapper>
                  </>
                ) : (
                  col.title
                )}
                {isCheckFuturePlan && index === columns.length - 1 && (
                  <PopUpButton />
                )}
              </div>
            </TableCellStyled>
          ))}
        </>
      }
    >
      <>
        {rows.map((row) =>
          row.id === "0" ? (
            <TableRowHover
              isOpenPopUp={!!isOpenPopUp}
              key={row.id}
              sx={{ height: 64 }}
            >
              <TableCellStyled
                colSpan={2}
                sx={{
                  paddingLeft: "1rem",
                  background: isOpenPopUp ? "#1e2731" : "#2b3641",
                }}
                isOpenPopUp={!!isOpenPopUp}
              >
                <SumProjectRowTitleBig isOpenPopUp={!!isOpenPopUp}>
                  {row.name}
                </SumProjectRowTitleBig>

                {!isCheckFuturePlan && !isCheckRfFoPage.isAll && (
                  <SumProjectRowTitle>
                    на весь срок реализации
                  </SumProjectRowTitle>
                )}
              </TableCellStyled>

              {columns.map((col) => (
                <TableCellStyled
                  key={col.id}
                  sx={{
                    minWidth: 144,
                    fontSize: isOpenPopUp ? 30 : 20,
                    background: isOpenPopUp ? "#1e2731" : "#2b3641",
                    fontWeight: 500,
                  }}
                  isOpenPopUp={!!isOpenPopUp}
                >
                  <>{row[col.id]}</>
                </TableCellStyled>
              ))}
            </TableRowHover>
          ) : (
            <RegionRow
              row={row}
              columns={columns}
              key={row.id}
              isProject={!isCheckRfFoPage.isRF}
              isOpenPopUp={!!isOpenPopUp}
            />
          )
        )}
        {isCheckRfFoPage.isFO && (
          <RegionFoProjects
            isOpenPopUp={!!isOpenPopUp}
            periodId={periodId}
            columns={columns}
          />
        )}
      </>
      <PopUp />
    </WidgetScrollbar>
  );
};
