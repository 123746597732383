import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";

import { useRouter } from "@/app/hooks/useRouter";
import { useRegion } from "@/pages/RegionPage/hooks/useRegion";
import { useRegionPage } from "@/pages/RegionPage/hooks/useRegionPage";
import { FooterForecastFact } from "@/shared/FooterForecastFact/FooterForecastFact";
import { useOpenIncident } from "@/shared/Incident/hooks/useOpenIncident";
import { IncidentCreateButton } from "@/shared/Incident/ui/IncidentCreateButton/IncidentCreateButton";
import { TabsListUnstyled, TabsUnstyled } from "@/shared/Tabs/Tabs.styled";
import { useAppSelector } from "@/store/redux/hooks";

import { AchievementNavigateGroup } from "../../InsideMainPage/ui/InsideMainPageTabs/InsideMainPageButtons/AchievementNavigateGroup";
import { ButtonsWrapper } from "../../InsideMainPage/ui/InsideMainPageTabs/InsideMainPageTabs.styled";
import { BasicPage, BasicSection } from "../../RegionPage/ui/RegionPage.styled";
import {
  defineDataName,
  defineTabs,
  GRAPH_LEVEL,
  panelGraphs,
  tabsGraphs,
} from "../lib/constants";
import { RegionGraphContainer } from "./RegionGraphContainer/ui/RegionGraphContainer";
import {
  Description,
  TabPanelStyled,
  TabStyled,
} from "./RegionGraphsPage.styled";

export const RegionGraphsPage = () => {
  const { user } = useAppSelector((state) => state.auth.user);
  const { navigate } = useRouter();
  const { id, graphLevel = GRAPH_LEVEL.VDL } = useParams<"id" | "graphLevel">();
  const [tabValue, setTabValue] = useState<string>(graphLevel);
  const region = useRegion(id!);
  const { setRegionId } = useRegionPage();
  const showRef = useRef<HTMLDivElement | null>(null);
  const { handleChange } = useOpenIncident();

  useEffect(() => {
    if (region?.id) {
      setRegionId(region.id);
    }
  }, [id, region?.id]);

  const handleChangeValue = (
    event: React.SyntheticEvent<Element, Event> | null,
    newValue: string | number | boolean | null
  ) => {
    setTabValue(newValue as GRAPH_LEVEL);
    navigate(`/region/${id}/graphs/${newValue}`);
  };

  return (
    <>
      <BasicPage ref={showRef}>
        <BasicSection>
          <TabsUnstyled
            className="parentIncident"
            value={tabValue}
            style={{ position: "relative", height: "95%" }}
            onChange={handleChangeValue}
          >
            <TabsListUnstyled>
              {tabsGraphs.map(({ id, value, title }) => (
                <TabStyled key={id} value={value}>
                  {title}
                </TabStyled>
              ))}
            </TabsListUnstyled>

            <ButtonsWrapper sx={{ padding: 0 }}>
              <IncidentCreateButton
                bigSize
                className="hoverIncident"
                style={{ position: "relative" }}
                posRight={0}
                onClick={(event) => {
                  handleChange(event, showRef?.current, defineTabs[tabValue]);
                }}
              />
              {user?.isVIP && <AchievementNavigateGroup iconId="3" />}
            </ButtonsWrapper>

            {panelGraphs.map(({ id, value, description = null }) => (
              <TabPanelStyled key={id} first={id === 1} value={value}>
                {description && <Description>{description}</Description>}
                <RegionGraphContainer dataName={defineDataName[value]} />
              </TabPanelStyled>
            ))}
          </TabsUnstyled>
        </BasicSection>

        <FooterForecastFact />
      </BasicPage>
    </>
  );
};
