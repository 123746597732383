import { styled, Switch, Typography } from "@mui/material";

export const BinarySwitchTitle = styled(Typography)<{
  isSelected?: boolean;
  openWidget?: boolean;
}>(({ theme, isSelected, openWidget }) => ({
  fontSize: theme.spacing(openWidget ? 2.25 : 2),
  lineHeight: theme.spacing(3),
  color: theme.palette.button.notSelected,
  ...(isSelected && {
    color: theme.palette.text.primary,
  }),
}));

export const BinarySwitch = styled(Switch)(({ theme }) => ({
  width: 32,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2.5,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: theme.palette.text.primary,
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.button.dark,
        opacity: 1,
        border: 0,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    color: theme.palette.text.primary,
    width: 15,
    height: 15,
  },
  "& .MuiSwitch-track": {
    borderRadius: 10,
    backgroundColor: theme.palette.button.dark,
    opacity: 1,
  },
}));
