import { AccordionSummary, Typography } from "@mui/material";
import React from "react";

import { useContentExpand } from "@/pages/Faq/hooks/useContentExpand";
import { ExpandedIcon } from "@/shared/ExpandedIcon/ExpandedIcon";

import { faqData } from "../lib/helpers";
import {
  AccordionDetailsStyled,
  AccordionTitle,
  AccordionWrapper,
  BoxWrapper,
} from "./FaqContent.styled";

export const FaqContent = () => {
  const { expanded, handleExpanded } = useContentExpand();

  return (
    <BoxWrapper>
      {faqData.map(({ id, question, answer }) => (
        <AccordionWrapper
          disableGutters
          onChange={() => handleExpanded(id)}
          key={id}
        >
          <AccordionSummary
            expandIcon={<ExpandedIcon isExpanded={expanded.includes(id)} />}
          >
            <AccordionTitle>
              <Typography sx={{ fontWeight: 500 }} component="div">
                {id}
              </Typography>
              <Typography sx={{ fontWeight: 500 }} component="div">
                {question}
              </Typography>
            </AccordionTitle>
          </AccordionSummary>

          <AccordionDetailsStyled sx={{ py: 2, px: 8 }}>
            <Typography component={"div"}>{answer}</Typography>
          </AccordionDetailsStyled>
        </AccordionWrapper>
      ))}
    </BoxWrapper>
  );
};
