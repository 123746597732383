import { TimeLineControl } from "@shared/TimeLineControl/TimeLineControl";
import { ReactNode } from "react";

import { TAB_ID } from "@/store/scheme/region";

import { ButtonBack } from "../ui/InsideMainPageTabs/InsideMainPageButtons/ButtonBack";
import { ButtonTableBack } from "../ui/InsideMainPageTabs/InsideMainPageButtons/ButtonTableBack";

export const ICONS_INSIDE_PAGE: Record<string, ReactNode | string>[] = [
  { id: "1", icon: <ButtonBack /> },
  { id: "2", icon: <ButtonTableBack /> },
  { id: "3", icon: <TimeLineControl noBorder /> },
];

export const defineTabs: Record<string, string> = {
  [TAB_ID.VDL_PROJECTS]: "Достижение по целям развития экономики и проектам",
  [TAB_ID.VDL]: "Достижение по целям развития экономики",
  [TAB_ID.PROJECTS]: "Достижение по проектам",
  [TAB_ID.RISKS]: "Риски реализации проектов",
};

export const dynamicTableTitle =
  "Динамика по целям развития экономики (фактические значения)";
