import { Box, styled } from "@mui/material";

export const ModalWrapperStyled = styled(Box)<{
  widthsize: number;
  heightsize?: number;
}>(({ theme, widthsize, heightsize = 0 }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: theme.spacing(widthsize),
  height: heightsize ? theme.spacing(heightsize) : "auto",
  maxHeight: "85vh",
  maxWidth: "85vw",
  borderRadius: theme.spacing(1.5),
  backgroundColor: theme.palette.background.paper,
  outline: "none",
  overflow: "hidden",
}));
