import { Stack } from "@mui/material";
import { FC } from "react";

import {
  StatisticContainer,
  Value,
  ValueNumber,
} from "@/shared/GraphCard/ui/GraphCard.styled";
import { formatNumber } from "@/shared/lib/heplers";

import { IStatisticElementGraph } from "../lib/types";

export const StatisticElementGraph: FC<IStatisticElementGraph> = ({
  percent,
  color,
}) => {
  const isShowPercent = percent || percent === 0;

  return (
    <StatisticContainer>
      <Stack direction="row" alignItems="flex-end">
        <Value color={color}>
          <ValueNumber>
            {isShowPercent
              ? formatNumber(percent, undefined, percent >= 100 ? 0 : 1)
              : "-"}
          </ValueNumber>
          {isShowPercent && "\u00A0%"}
        </Value>
      </Stack>
    </StatisticContainer>
  );
};
