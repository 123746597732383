import { PUBLIC_URL } from "@/shared/lib/constants";

export const linkToAct = (fileName: string) =>
  `${PUBLIC_URL}/files/${fileName}`;

export const onDownload = ({
  label,
  fileName,
  type,
}: Record<string, string>) => {
  const link = document.createElement("a");
  link.download = `${label}.${type}`;
  link.href = linkToAct(fileName);
  link.click();
};
