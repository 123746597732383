import React from "react";

import { Legend } from "@/shared/Legend/Legend";
import { IPopUpProps } from "@/shared/RegionPopUp/lib/types";

import { risksLegend } from "./lib/constants";
import { RisksLegendWrapper } from "./RisksLegend.styled";

export const RisksLegend = ({ isOpenPopUp }: IPopUpProps) => {
  return (
    <RisksLegendWrapper isOpenPopUp={isOpenPopUp}>
      <Legend legendData={risksLegend} />
    </RisksLegendWrapper>
  );
};
