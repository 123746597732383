import { useMemo } from "react";

import { useFuturePlan } from "@/shared/Table/hooks/useFuturePlan";
import { HeaderColumns } from "@/shared/Table/lib/types";

import { useKeysIndicatorsColumnsT } from "../lib/types";

export const useKeysIndicatorsColumns = ({
  model,
  kpiData,
  checkPeriodByHideDate,
}: useKeysIndicatorsColumnsT): {
  keysIndicatorsColumns: HeaderColumns[];
} => {
  const { isCheckFuturePlan } = useFuturePlan();

  const keysIndicatorsColumns = useMemo(() => {
    return [
      {
        id: "10",
        sortId: 1,
        indicator: model.indexes.factChangeAppg,
        label: "Достижение по целям развития экономики, %",
        popUpLabel: "Достижение по целям развития экономики",
        sortable: true,
        model: model.modelId,
        colorValues: true,
        valuesUnit: "%",
        mainColumn: true,
        data: kpiData,
        rowSpan: 3,
        sortBy: "AchievementEconomics",
        ...(!checkPeriodByHideDate && {
          needPrevPeriod: true,
          prevPeriod: true,
        }),
        indicatorColor: model.indexes.colorGraph,
      },
      {
        id: "header",
        label: `Выпуск товаров и услуг`,
        updateDateId: "1",
        model: model.modelId,
        data: kpiData,
        ...(!checkPeriodByHideDate && {
          needPrevPeriod: true,
          prevPeriod: true,
        }),
        indicatorColor: "",
      },
      {
        id: "1",
        sortId: 2,
        label: `План, %`,
        indicator: model.indexes.plan,
        parentHeaderLabel: "Выпуск товаров и услуг",
        popUpLabel: "Выпуск товаров и услуг План, %",
        sortable: true,
        model: model.modelId,
        notVisible: true,
        data: kpiData,
        sortBy: "ManufacturingPlan",
        valuesUnit: "%",
        indicatorColor: "",
      },
      {
        id: "1",
        sortId: 5,
        label: `Факт, %`,
        indicator: model.indexes.fact,
        parentHeaderLabel: "Выпуск товаров и услуг",
        popUpLabel: "Выпуск товаров и услуг Факт, %",
        sortable: true,
        model: model.modelId,
        notVisible: true,
        data: kpiData,
        sortBy: "ManufacturingFact",
        valuesUnit: "%",
        indicatorColor: "",
      },
      {
        id: "1",
        sortId: 6,
        label: `Достижение, %`,
        indicator: model.indexes.factChangeAppg,
        parentHeaderLabel: "Выпуск товаров и услуг",
        popUpLabel: "Выпуск товаров и услуг Достижение, %",
        sortable: true,
        model: model.modelId,
        focused: true,
        valuesUnit: "%",
        data: kpiData,
        sortBy: "ManufacturingAchievement",
        indicatorColor: "",
      },
      ...(isCheckFuturePlan
        ? [
            {
              id: "1",
              sortId: 3,
              label: `План 2024 год, %`,
              indicator: model.indexes.plan,
              parentHeaderLabel: "Выпуск товаров и услуг",
              popUpLabel: "Выпуск товаров и услуг План 2024/2030, %",
              sortable: true,
              model: model.modelId,
              notVisible: true,
              data: kpiData,
              sortBy: "ManufacturingFuturePlan2024",
              dateCode: "120240000",
              valuesUnit: "%",
              indicatorColor: "",
            },
            {
              id: "1",
              sortId: 4,
              label: `План 2030 год, %`,
              indicator: model.indexes.plan,
              parentHeaderLabel: "Выпуск товаров и услуг",
              popUpLabel: "Выпуск товаров и услуг План 2024/2030, %",
              sortable: true,
              model: model.modelId,
              notVisible: true,
              data: kpiData,
              sortBy: "ManufacturingFuturePlan2030",
              dateCode: "120300000",
              valuesUnit: "%",
              indicatorColor: "",
            },
          ]
        : []),
      {
        id: "header",
        label: `Доходы населения`,
        updateDateId: "2",
        model: model.modelId,
        data: kpiData,
        ...(!checkPeriodByHideDate && {
          needPrevPeriod: true,
          prevPeriod: true,
          indicatorColor: "",
        }),
      },
      {
        id: "2",
        sortId: 7,
        label: `План, %`,
        indicator: model.indexes.plan,
        parentHeaderLabel: "Доходы населения",
        popUpLabel: "Доходы населения План, %",
        sortable: true,
        model: model.modelId,
        notVisible: true,
        data: kpiData,
        sortBy: "ProfitPlan",
        valuesUnit: "%",
        indicatorColor: "",
      },
      {
        id: "2",
        sortId: 10,
        label: `Факт, %`,
        indicator: model.indexes.fact,
        parentHeaderLabel: "Доходы населения",
        popUpLabel: "Доходы населения Факт, %",
        sortable: true,
        model: model.modelId,
        notVisible: true,
        data: kpiData,
        sortBy: "ProfitFact",
        valuesUnit: "%",
        indicatorColor: "",
      },
      {
        id: "2",
        sortId: 11,
        label: `Достижение, %`,
        indicator: model.indexes.factChangeAppg,
        parentHeaderLabel: "Доходы населения",
        popUpLabel: "Доходы населения Достижение, %",
        sortable: true,
        model: model.modelId,
        focused: true,
        valuesUnit: "%",
        data: kpiData,
        sortBy: "ProfitAchievement",
        indicatorColor: "",
      },
      ...(isCheckFuturePlan
        ? [
            {
              id: "2",
              sortId: 8,
              label: `План 2024 год, %`,
              indicator: model.indexes.plan,
              parentHeaderLabel: "Доходы населения",
              popUpLabel: "Доходы населения План 2024/2030, %",
              sortable: true,
              model: model.modelId,
              notVisible: true,
              data: kpiData,
              sortBy: "ProfitFuturePlan2024",
              dateCode: "120240000",
              valuesUnit: "%",
              indicatorColor: "",
            },
            {
              id: "2",
              sortId: 9,
              label: `План 2030 год, %`,
              indicator: model.indexes.plan,
              parentHeaderLabel: "Доходы населения",
              popUpLabel: "Доходы населения План 2024/2030, %",
              sortable: true,
              model: model.modelId,
              notVisible: true,
              data: kpiData,
              sortBy: "ProfitFuturePlan2030",
              dateCode: "120300000",
              valuesUnit: "%",
              indicatorColor: "",
            },
          ]
        : []),
      {
        id: "header",
        label: `Частные инвестиции`,
        updateDateId: "3",
        model: model.modelId,
        data: kpiData,
        ...(!checkPeriodByHideDate && {
          needPrevPeriod: true,
          prevPeriod: true,
        }),
        indicatorColor: "",
      },
      {
        id: "3",
        sortId: 12,
        label: `План, %`,
        indicator: model.indexes.plan,
        parentHeaderLabel: "Частные инвестиции",
        popUpLabel: "Частные инвестиции План, %",
        sortable: true,
        model: model.modelId,
        notVisible: true,
        data: kpiData,
        sortBy: "InvestmentPlan",
        valuesUnit: "%",
        indicatorColor: "",
      },
      {
        id: "3",
        sortId: 15,
        label: `Факт, %`,
        indicator: model.indexes.fact,
        parentHeaderLabel: "Частные инвестиции",
        popUpLabel: "Частные инвестиции Факт, %",
        sortable: true,
        model: model.modelId,
        notVisible: true,
        data: kpiData,
        sortBy: "InvestmentFact",
        valuesUnit: "%",
        indicatorColor: "",
      },
      {
        id: "3",
        sortId: 16,
        label: `Достижение, %`,
        indicator: model.indexes.factChangeAppg,
        parentHeaderLabel: "Частные инвестиции",
        popUpLabel: "Частные инвестиции Достижение, %",
        sortable: true,
        model: model.modelId,
        focused: true,
        valuesUnit: "%",
        data: kpiData,
        sortBy: "InvestmentAchievement",
        indicatorColor: "",
      },
      ...(isCheckFuturePlan
        ? [
            {
              id: "3",
              sortId: 13,
              label: `План 2024 год, %`,
              indicator: model.indexes.plan,
              parentHeaderLabel: "Частные инвестиции",
              popUpLabel: "Частные инвестиции План 2024/2030, %",
              sortable: true,
              model: model.modelId,
              notVisible: true,
              data: kpiData,
              sortBy: "InvestmentFuturePlan2024",
              dateCode: "120240000",
              valuesUnit: "%",
              indicatorColor: "",
            },
            {
              id: "3",
              sortId: 14,
              label: `План 2030 год, %`,
              indicator: model.indexes.plan,
              parentHeaderLabel: "Частные инвестиции",
              popUpLabel: "Частные инвестиции План 2024/2030, %",
              sortable: true,
              model: model.modelId,
              notVisible: true,
              data: kpiData,
              sortBy: "InvestmentFuturePlan2030",
              dateCode: "120300000",
              valuesUnit: "%",
              indicatorColor: "",
            },
          ]
        : []),
      {
        id: "header",
        label: `Число рабочих мест`,
        updateDateId: "127",
        model: model.modelId,
        data: kpiData,
        indicatorColor: "",
      },
      {
        id: "127",
        sortId: 17,
        label: `План, тыс шт.`,
        indicator: model.indexes.plan,
        parentHeaderLabel: "Число рабочих мест",
        popUpLabel: "Число рабочих мест План, тыс шт.",
        sortable: true,
        model: model.modelId,
        notVisible: true,
        data: kpiData,
        sortBy: "EmployedPlan",
        indicatorColor: "",
      },
      {
        id: "127",
        sortId: 20,
        label: `Факт, тыс шт.`,
        indicator: model.indexes.fact,
        parentHeaderLabel: "Число рабочих мест",
        popUpLabel: "Число рабочих мест Факт, тыс шт.",
        sortable: true,
        model: model.modelId,
        notVisible: true,
        data: kpiData,
        sortBy: "EmployedFact",
        indicatorColor: "",
      },
      {
        id: "127",
        sortId: 21,
        label: `Достижение, %`,
        indicator: model.indexes.factChangeAppg,
        parentHeaderLabel: "Число рабочих мест",
        popUpLabel: "Число рабочих мест Достижение, %",
        sortable: true,
        model: model.modelId,
        focused: true,
        valuesUnit: "%",
        data: kpiData,
        sortBy: "EmployedAchievement",
        indicatorColor: "",
      },
      ...(isCheckFuturePlan
        ? [
            {
              id: "127",
              sortId: 18,
              label: `План 2024 год, тыс шт.`,
              indicator: model.indexes.plan,
              parentHeaderLabel: "Число рабочих мест",
              popUpLabel: "Число рабочих мест План 2024/2030, тыс шт.",
              sortable: true,
              model: model.modelId,
              notVisible: true,
              data: kpiData,
              sortBy: "EmployedFuturePlan2024",
              dateCode: "120240000",
              indicatorColor: "",
            },
            {
              id: "127",
              sortId: 19,
              label: `План 2030 год, тыс шт.`,
              indicator: model.indexes.plan,
              parentHeaderLabel: "Число рабочих мест",
              popUpLabel: "Число рабочих мест План 2024/2030, тыс шт.",
              sortable: true,
              model: model.modelId,
              notVisible: true,
              data: kpiData,
              sortBy: "EmployedFuturePlan2030",
              dateCode: "120300000",
              indicatorColor: "",
            },
          ]
        : []),
    ] as HeaderColumns[];
  }, [kpiData, checkPeriodByHideDate, isCheckFuturePlan]);

  return { keysIndicatorsColumns };
};
