import { styled } from "@mui/material";

export const IndicatorsGrid = styled("div")<{ gridColumns: string }>(
  ({ theme, gridColumns }) => ({
    flex: "0 0 12.5rem",
    display: "grid",
    gridTemplateColumns: `repeat(${gridColumns}, 1fr)`,
    gap: "0 1em",
  })
);

export const VDLCardsWrapper = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateRows: "repeat(2, 5.75rem)",
  gap: "1rem 0",
}));

export const CardWrapper = styled("div")<{ disabled?: boolean }>(
  ({ theme, disabled }) =>
    disabled
      ? {
          opacity: 0.5,
          pointerEvents: "none",
        }
      : {}
);
