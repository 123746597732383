import { Theme } from "@mui/material";
import { TAB_ID } from "@store/scheme/region";
import inRange from "lodash/inRange";
import range from "lodash/range";
import { NavigateFunction } from "react-router";

import { REGION_COUNTRY_CODE } from "@/shared/lib/constants";
import { VALUE_COLOR } from "@/shared/Table/model/api/scheme";

import { ColorMap, ColorRisk, TargetId } from "./types";

const redColorsBefore7 = (value: number) => {
  if (value === 1) {
    return "rgb(255, 149, 137)";
  }
  return `rgb(255, ${149 - value ** 2}, ${137 - value ** 2})`;
};

export const getMapBackgroundColor = (
  theme: Theme,
  value: number,
  tabId: TAB_ID,
  achEnabled: boolean
) => {
  const { inRange: colors }: any = theme.palette.geo;
  const { risks: colorsRisks }: any = theme.palette.geo;
  const redRange = range(10, 91, 10);
  const greenRange90 = range(90, 100, 2);
  const greenRange100 = [90, 95, 98, 100, 103, 105, 110];

  if (tabId === TAB_ID.RISKS) {
    if (value < 1) return colors.green100start;

    if (value >= 1 && value < 7) {
      return redColorsBefore7(value);
    }

    if (value >= 7) {
      const targetNum = redRange.find((num) => inRange(value, num - 10, num));
      return colorsRisks[`red${targetNum ?? 0}` as ColorRisk];
    }
  }

  if (value < 90) {
    const targetNum = redRange.find((num) => inRange(value, num - 10, num));

    return colors[`red${targetNum ?? 0}start` as ColorMap];
  }

  if (value >= 90 && value <= 100) {
    const targetNum = greenRange90.find((num, i) =>
      inRange(value, num, num + 2)
    );
    return colors[`green${targetNum ?? 100}start` as ColorMap];
  }

  if (value > 100) {
    const targetNum = greenRange100.find((num, i) => {
      const curValue = achEnabled ? i * 12.5 + value : value;

      return inRange(curValue, num, num + 3);
    });

    return colorsRisks[`green${targetNum ?? 110}` as ColorRisk];
  }

  return colors[`green${100}start` as ColorMap];
};

export const colorBasedOnValue = (
  theme: Theme,
  color: VALUE_COLOR | null,
  selected?: boolean
) => {
  switch (color) {
    case VALUE_COLOR.GREEN:
      return selected
        ? theme.palette.tabs.selectedGreenValue
        : theme.palette.success.main;
    case VALUE_COLOR.RED:
      return selected
        ? theme.palette.tabs.selectedRedValue
        : theme.palette.error.main;
    case VALUE_COLOR.BLUE:
      return theme.palette.info.main;
    case VALUE_COLOR.GREY:
      return theme.palette.chart.grey;
    case VALUE_COLOR.BORDO:
      return theme.palette.table.riskCountColor;
    case VALUE_COLOR.LIGHT_GREEN:
      return theme.palette.graphs.success;
    case VALUE_COLOR.ORANGE:
      return theme.palette.table.middleRiskColor;
    case VALUE_COLOR.BLACK:
    default:
      return theme.palette.text.primary;
  }
};

export const getTargetId = (
  isForMap: boolean,
  selectedSubjectId: string,
  regionId?: string,
  districtId?: string
) => {
  let targetId: TargetId;

  switch (true) {
    case Boolean(selectedSubjectId) && !isForMap:
      targetId = selectedSubjectId;
      break;

    case Boolean(regionId):
      targetId = regionId ?? "";
      break;

    case Boolean(!regionId && districtId):
      targetId = districtId ?? "";
      break;

    default:
      targetId = REGION_COUNTRY_CODE;
      break;
  }

  return targetId;
};

export const onBackLink = (
  setSelectedSubjectId: React.Dispatch<React.SetStateAction<string>>,
  navigate: NavigateFunction,
  id: TargetId,
  districtId?: string,
  selectedDistrictId?: string
) => {
  setSelectedSubjectId(id);

  if (districtId) {
    navigate(`/district/${districtId}`);
  }
  if (selectedDistrictId) {
    navigate("/");
  }
};

export const getVdlId = (activeTab: TAB_ID, achEnabled: boolean) => {
  let vdlId = "";
  let valIdAchievement = "";

  switch (activeTab) {
    case TAB_ID.VDL_PROJECTS:
      valIdAchievement = "123";
      break;

    case TAB_ID.VDL:
      valIdAchievement = "10";
      break;

    case TAB_ID.PROJECTS:
      valIdAchievement = "0";
      break;

    case TAB_ID.MANUFACTURING:
      vdlId = "1";
      valIdAchievement = "1";
      break;

    case TAB_ID.PROFIT:
      vdlId = "132";
      valIdAchievement = "2";
      break;

    case TAB_ID.INVESTMENT:
      vdlId = "133";
      valIdAchievement = "3";
      break;

    case TAB_ID.EMPLOYED:
      vdlId = "4";
      valIdAchievement = "127";
      break;

    case TAB_ID.RISKS:
      vdlId = "121";
      break;

    case TAB_ID.RESULT_PROJECTS:
      vdlId = "0";
      valIdAchievement = "0";
      break;

    case TAB_ID.RESULT_CHECKPOINTS:
      vdlId = "129";
      valIdAchievement = "129";
      break;

    default:
      break;
  }

  return achEnabled ? valIdAchievement : vdlId;
};
