import { styled } from "@mui/system";

import { STEPS } from "@/shared/GridCard/lib/types";
import { TAB_ID } from "@/store/scheme/region";

import { TypeGrid } from "../lib/types";

const setGridTemplateAreas = (type: STEPS) => {
  switch (type) {
    case STEPS.GOALS:
      return `
      "${TAB_ID.VDL_PROJECTS} ${TAB_ID.VDL} ${TAB_ID.MANUFACTURING} ${TAB_ID.PROFIT} ${TAB_ID.INVESTMENT} ${TAB_ID.EMPLOYED}"
      "${TAB_ID.VDL_PROJECTS} ${TAB_ID.PROJECTS} ${TAB_ID.MANUFACTURING} ${TAB_ID.PROFIT} ${TAB_ID.INVESTMENT} ${TAB_ID.EMPLOYED}"
      `;
    case STEPS.PROJECTS:
      return `
      "${TAB_ID.VDL_PROJECTS} ${TAB_ID.VDL} ${TAB_ID.RESULT_PROJECTS} ${TAB_ID.RESULT_PROJECTS} ${TAB_ID.RESULT_CHECKPOINTS} ${TAB_ID.RESULT_CHECKPOINTS}"
      "${TAB_ID.VDL_PROJECTS} ${TAB_ID.PROJECTS} ${TAB_ID.RESULT_PROJECTS} ${TAB_ID.RESULT_PROJECTS} ${TAB_ID.RESULT_CHECKPOINTS} ${TAB_ID.RESULT_CHECKPOINTS}"
      `;

    default:
      return `
      "${TAB_ID.VDL_PROJECTS} ${TAB_ID.VDL} ${TAB_ID.PROJECTS}"
      "${TAB_ID.VDL_PROJECTS} ${TAB_ID.VDL} ${TAB_ID.PROJECTS}"
      `;
  }
};

export const GridContainer = styled("div")<{ active: boolean }>(
  ({ theme, active }) => ({
    flex: "0 0 12.5rem",
    display: "grid",
    gridTemplateColumns: active
      ? `38.75rem repeat(4 , 1fr)`
      : `repeat(5 , 1fr)`,
    gap: theme.spacing(2),
    maxWidth: "100%",
    maxHeight: "100%",
  })
);

export const GridMapContainer = styled("div")<TypeGrid>(
  ({ theme, columns = 3, typeArea }) => ({
    display: "grid",
    gridTemplateColumns: `
      repeat(${columns},
      minmax(calc(100%/${columns} - ${theme.spacing(2)}),
      auto));`,
    gridTemplateAreas: setGridTemplateAreas(typeArea),
    gridTemplateRows: "1fr 1fr",
    gap: theme.spacing(2),
    maxWidth: "100%",
  })
);

export const GridContainerStack = styled("div")<TypeGrid>(
  ({ theme, columns = 3 }) => ({
    display: "grid",
    gridTemplateColumns: `repeat(${columns}, 1fr)`,
    gap: theme.spacing(2),
  })
);
