import React from "react";

import { Sceleton } from "@/shared/Sceleton/ui/Sceleton";

import { useProjectPage } from "../../hooks/useProjectPage";
import { ProjectGeneralInfo } from "./cards/ProjectGeneralInfo/ProjectGeneralInfo";
import { ProjectGoals } from "./cards/ProjectGoals/ProjectGoals";
import { ProjectInitiator } from "./cards/ProjectInitiator/ProjectInitiator";
import { ProjectCardsStyled } from "./ProjectCards.styled";

export const ProjectCards = () => {
  const { infoFetching } = useProjectPage();
  return (
    <ProjectCardsStyled>
      {infoFetching ? <Sceleton type="card" /> : <ProjectGeneralInfo />}
      {infoFetching ? <Sceleton type="card" /> : <ProjectInitiator />}
      {infoFetching ? <Sceleton type="card" /> : <ProjectGoals />}
    </ProjectCardsStyled>
  );
};
