import { useAppSelector } from "@store/redux/hooks";
import React from "react";

import {
  BoxStyled,
  RegionGridCell,
  RegionGridEmployed,
  RegionGridTripleCell,
} from "@/pages/RegionPage/ui/RegionMutableInfo/ui/RegionMutableInfo.styled";

import {
  BirthRate,
  DeathRate,
  DistrictValuesEmployed,
  Migration,
  Natality,
  SelfEmployed,
  UnemploymentRate,
} from "./components";

export const RegionMutableEmployed = () => {
  const { user } = useAppSelector((state) => state.auth.user);

  return user?.isAnalyticFK ? null : (
    <BoxStyled>
      <RegionGridEmployed>
        <RegionGridCell adjustHeight sx={{ gridArea: "DistrictValues" }}>
          <DistrictValuesEmployed />
        </RegionGridCell>

        <RegionGridCell sx={{ gridArea: "TripleCell1" }}>
          <RegionGridTripleCell>
            <Natality />
            <BirthRate />
            <DeathRate />
          </RegionGridTripleCell>
        </RegionGridCell>

        <RegionGridCell sx={{ gridArea: "TripleCell2" }}>
          <RegionGridTripleCell>
            <UnemploymentRate />
            <Migration />
            <SelfEmployed />
          </RegionGridTripleCell>
        </RegionGridCell>
      </RegionGridEmployed>
    </BoxStyled>
  );
};
