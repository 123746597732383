import { useAppDispatch, useAppSelector } from "@store/redux/hooks";

import {
  getSaveStep,
  updatetHighlightWidget,
} from "@/pages/MainPage/model/tabsSlice";
import { STEPS } from "@/shared/GridCard/lib/types";
import { TAB_ID } from "@/store/scheme/region";

export const useAchievementTabs = () => {
  const dispatch = useAppDispatch();
  const { saveStep, highlightWidget } = useAppSelector(
    (state) => state.ui.tabs
  );

  const setSaveStep = (step: STEPS) => {
    dispatch(getSaveStep(step));
  };

  const isCheckSaveStep = (id: TAB_ID) => {
    return (
      [STEPS.GOALS, STEPS.PROJECTS].includes(saveStep) &&
      id !== TAB_ID.VDL_PROJECTS
    );
  };

  const setInitialStep = (selectedTabId: TAB_ID, step: STEPS) => {
    switch (true) {
      case [TAB_ID.RESULT_PROJECTS, TAB_ID.RESULT_CHECKPOINTS].includes(
        selectedTabId
      ):
        return STEPS.PROJECTS;
      case [
        TAB_ID.MANUFACTURING,
        TAB_ID.PROFIT,
        TAB_ID.INVESTMENT,
        TAB_ID.EMPLOYED,
      ].includes(selectedTabId):
        return STEPS.GOALS;

      default:
        return step;
    }
  };

  const selectedTab = (tabId: TAB_ID) => {
    let selectTab: STEPS;
    switch (true) {
      case saveStep === STEPS.MAIN && tabId === TAB_ID.VDL_PROJECTS:
        selectTab = STEPS.MAIN_FULL;
        break;
      case tabId === TAB_ID.VDL:
        selectTab = STEPS.GOALS;
        break;
      case tabId === TAB_ID.PROJECTS:
        selectTab = STEPS.PROJECTS;
        break;
      case saveStep !== STEPS.MAIN && tabId === TAB_ID.VDL_PROJECTS:
      default:
        selectTab = saveStep === STEPS.MAIN_FULL ? STEPS.MAIN : STEPS.MAIN_FULL;
        break;
    }
    return selectTab;
  };

  const setHighlightWidget = (widget: string) => {
    dispatch(updatetHighlightWidget(widget));
  };

  return {
    saveStep,
    isCheckSaveStep,
    setSaveStep,
    setInitialStep,
    selectedTab,
    highlightWidget,
    setHighlightWidget,
  };
};
