import { useAppSelector } from "@store/redux/hooks";
import { FC } from "react";

import { COMPARE_TYPE } from "@/shared/CompareControl/lib/types";

import { FooterHighLowFO } from "./FooterHighLowFO";
import { FooterHighLowRF } from "./FooterHighLowRF";

export const FooterHighLow: FC = () => {
  const { value } = useAppSelector((state) => state.controls.compare);

  return value === COMPARE_TYPE.FO ? <FooterHighLowFO /> : <FooterHighLowRF />;
};
