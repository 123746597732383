import { FC, useMemo } from "react";

import { ISceleton } from "../lib/types";
import { SceletonCircle } from "./base/SceletonCircle/SceletonCircle";
import { SceletonLine } from "./base/SceletonLine/SceletonLine";
import { SceletonAchieve } from "./extend/SceletonAchieve/SceletonAchieve";
import { SceletonCard } from "./extend/SceletonCard/SceletonCard";
import { SceletonContent } from "./extend/SceletonContent/SceletonContent";
import {
  OverlaySceleton,
  SceletonLine1Styled,
  WrapperSceleton,
} from "./Sceleton.styled";

export const Sceleton: FC<ISceleton> = ({ type, size, heightLine }) => {
  const typeSceleton = useMemo(() => {
    return {
      circle: <SceletonCircle />,
      line: <SceletonLine />,
      content: <SceletonContent />,
      card: <SceletonCard />,
      achieve: <SceletonAchieve size={size} />,
    };
  }, []);

  return (
    <OverlaySceleton>
      <WrapperSceleton>
        {!["card", "achieve"].includes(type) && (
          <SceletonLine1Styled heightLine={heightLine} />
        )}

        {typeSceleton[type]}
      </WrapperSceleton>
    </OverlaySceleton>
  );
};
