import { styled } from "@mui/material";

export const SceletonAchieveStyled = styled("div")({
  display: "flex",
  gap: "12%",
  margin: "0.75rem 0",
});

export const SceletonAchieveColumn = styled("div")<{ width?: number }>(
  ({ width }) => ({
    display: "flex",
    gap: "1rem",
    flexDirection: "column",
    maxWidth: width,
    width: "100%",
  })
);

export const SceletonAchieveContent = styled("div")({
  display: "flex",
  gap: "2.25rem",
  flexDirection: "column",
});
