import { useMemo } from "react";
import { useLocation } from "react-router";

import {
  GeneralIndicatorsEmployed,
  GeneralIndicatorsInvestment,
  GeneralIndicatorsManufacturing,
  GeneralIndicatorsProfit,
  GeneralIndicatorsRate,
} from "./components";

export const GeneralIndicatorsWidget = () => {
  const location = useLocation();
  const getGeneralIndicators = useMemo(() => {
    switch (true) {
      case location.pathname.includes("/manufacturing"):
        return <GeneralIndicatorsManufacturing />;
      case location.pathname.includes("/profit"):
        return <GeneralIndicatorsProfit />;
      case location.pathname.includes("/investment"):
        return <GeneralIndicatorsInvestment />;
      case location.pathname.includes("/employed"):
        return <GeneralIndicatorsEmployed />;

      default:
        return <GeneralIndicatorsRate />;
    }
  }, [location.pathname]);

  return getGeneralIndicators;
};
