import React, { FC, useMemo } from "react";

import { useKpi } from "@/shared/GeneralIndicators/hooks/useKpi";
import { IValue } from "@/shared/GeneralIndicators/lib/types";
import { GeneralIndicators } from "@/shared/GeneralIndicators/ui/GeneralIndicators";
import { IPopUpProps, REGION_TABLES } from "@/shared/RegionPopUp/lib/types";

export const Inflation: FC<IPopUpProps> = ({ isOpenPopUp }) => {
  const { setBaseValues, fetching } = useKpi();

  const values: IValue[] = useMemo(() => {
    return setBaseValues(["50", "51"]);
  }, [setBaseValues]);

  return (
    <GeneralIndicators
      title="Инфляция"
      generalFetching={fetching}
      values={values}
      indicatorsFontStyle="italic"
      isOpenPopUp={isOpenPopUp}
      popUpId={REGION_TABLES.INFLATION}
    />
  );
};
