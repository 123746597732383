import DynamicsHighFO from "@assets/svg_components/DynamicsHighFO";
import DynamicsHighRF from "@assets/svg_components/DynamicsHighRF";
import DynamicsLowFO from "@assets/svg_components/DynamicsLowFO";
import DynamicsLowRF from "@assets/svg_components/DynamicsLowRF";

import { COMPARE_TYPE } from "@/shared/CompareControl/lib/types";
import { useAppSelector } from "@/store/redux/hooks";

import { getIconsByValueHL } from "../lib/helpers";
import { ICompareIconsProps } from "../lib/types";

export const CompareIcons = ({ value, isBig = false }: ICompareIconsProps) => {
  const { compare } = useAppSelector((state) => state.controls);
  const icons =
    compare.value === COMPARE_TYPE.FO
      ? {
          high: DynamicsHighFO,
          low: DynamicsLowFO,
        }
      : {
          high: DynamicsHighRF,
          low: DynamicsLowRF,
        };
  return <>{getIconsByValueHL({ value, icons, isBig })}</>;
};
