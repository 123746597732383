import { REGION_COUNTRY_CODE } from "@/shared/lib/constants";
import { ArgumentsWithDistrictIdType } from "@/shared/lib/types";
import { getOperands, olapRequestData2 } from "@/store/api/core";
import {
  DATA_ACTUAL_VALUE,
  FILTER_OPERAND_TYPE,
  FILTER_TYPE,
  MODEL_IDS,
  MODELS,
} from "@/store/scheme/olap";

export const getCountEnterprise = ({
  regionId,
  districtId,
  dataSummaryId,
}: ArgumentsWithDistrictIdType) => {
  const model = MODELS[MODEL_IDS.KPI_SOC_ECONOMY];
  const dimensions = [
    {
      id: model.dataPeriodId,
      includeItems: true,
      includeAttributesByCodes: ["REPORT_DATE"],
    },
    {
      id: model.dataRegionId,
    },
    {
      id: model.dimId,
    },
    {
      id: model.dataActualId,
    },
    {
      id: model.dataSummaryId,
    },
  ];

  const indicators = {
    id: model.indicatorId,
    items: [
      { id: model.indexes.fact },
      { id: model.indexes.factHighLowRF },
      { id: model.indexes.factHighLowFO },
    ],
  };

  const operands = getOperands([
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataRegionId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: [regionId, districtId, REGION_COUNTRY_CODE],
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dimId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: ["14", "15", "16", "17", "18", "19"],
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataActualId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: [DATA_ACTUAL_VALUE],
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataSummaryId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: [dataSummaryId],
        },
      ],
    },
  ]);

  return olapRequestData2(
    dimensions.map(({ id }) => id),
    model.indicatorId,
    {
      modelUuid: model.uuid,
      includeGaps: false,
      indicatorId: model.indicatorId,
      dimensions,
      indicators,
      dataFilter: {
        type: FILTER_TYPE.AND,
        version: 1,
        operands,
      },
    },
    "cd9149c9-1bca-48b4-acd5-814d55573e81"
  );
};
