import { useAppSelector } from "@store/redux/hooks";
import { FC } from "react";

import { IValueDateProps } from "./lib/types";
import { Root } from "./ValueDate.styled";

export const ValueDate: FC<IValueDateProps> = ({
  children,
  forceShow,
  isOpenPopUp,
}) => {
  const { enabled } = useAppSelector((state) => state.controls.period);
  const isShow = forceShow || (enabled && Boolean(children));

  return (
    <Root isOpenPopUp={isOpenPopUp} show={isShow}>
      {children}
    </Root>
  );
};
