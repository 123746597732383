import { MODEL_IDS, MODELS } from "@store/scheme/olap";
import React, { FC, useCallback, useMemo } from "react";
import { useParams } from "react-router";

import { useRegion } from "@/pages/RegionPage/hooks/useRegion";
import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { useGeneralIndicators } from "@/shared/GeneralIndicators/hooks/useGeneralIndicators";
import { IValue } from "@/shared/GeneralIndicators/lib/types";
import { GeneralIndicators } from "@/shared/GeneralIndicators/ui/GeneralIndicators";
import { formatNumber, formatNumberPeople } from "@/shared/lib/heplers";
import { IPopUpProps, REGION_TABLES } from "@/shared/RegionPopUp/lib/types";

export const GeneralIndicatorsRate: FC<IPopUpProps> = ({ isOpenPopUp }) => {
  const { id } = useParams<"id">();
  const model = MODELS[MODEL_IDS.KPI_SOC_ECONOMY];
  const { data, indicators, generalIndicatorsTitle, fetching } =
    useGeneralIndicators();
  const region = useRegion(id!);
  const { isCheckRfFoPage } = useRegions();

  const placePopulation = useCallback(
    (indicator: string) => {
      if (indicator === "27") {
        return !isCheckRfFoPage.isAll ? (
          <>
            {`${formatNumber(
              data?.["28"]?.indicatorVals?.[model.indexes.fact],
              data?.["28"]?.UNIT
            )}
            ${data?.["28"]?.short_name || ""}`}
          </>
        ) : undefined;
      }
      const subjectData = data?.[indicator];
      return subjectData?.indicatorVals?.[indicators.place];
    },
    [isCheckRfFoPage.isAll, data, indicators.place]
  );

  const values: IValue[] = useMemo(() => {
    return ["26", "29", "25", "27"].map((index) => {
      const subjectData = data?.[index];

      return {
        key: index,
        title:
          index === "27" && !isCheckRfFoPage.isAll ? (
            <span>
              {subjectData?.short_name} {region?.attributeVals.adm_center}
            </span>
          ) : index === "27" && isCheckRfFoPage.isFO ? (
            `Численность населения адм. центра ${region?.attributeVals.adm_center}`
          ) : index === "27" && isCheckRfFoPage.isRF ? (
            "Числ. населения адм. центра г. Москва"
          ) : (
            subjectData?.short_name
          ),
        date: subjectData?.attributeVals?.REPORT_DATE,
        place: placePopulation(index),
        value:
          index === "27"
            ? formatNumberPeople(
                subjectData?.indicatorVals?.[model.indexes.fact]
              )
            : formatNumber(subjectData?.indicatorVals?.[model.indexes.fact]),
        unit: subjectData?.UNIT,
        placeChange:
          !isCheckRfFoPage.isRF && ["25", "29", "26"].includes(index)
            ? formatNumber(
                subjectData?.indicatorVals?.[indicators.placeChange],
                undefined,
                0,
                true
              )
            : undefined,
        placeChangeRate: subjectData?.indicatorVals?.[indicators.colorChange],
      };
    });
  }, [
    data,
    indicators.colorChange,
    indicators.place,
    indicators.placeChange,
    region?.attributeVals.adm_center,
    isCheckRfFoPage.isRF,
    isCheckRfFoPage.isAll,
  ]);

  return (
    <GeneralIndicators
      isNotGetDate={true}
      generalFetching={fetching}
      title={generalIndicatorsTitle}
      typography="light"
      values={values}
      isOpenPopUp={isOpenPopUp}
      popUpId={REGION_TABLES.GENERAL_INDICATORS_RATE}
    />
  );
};
