// @ts-nocheck
import { FC, useEffect, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";

import { ICustomScrollbar } from "../lib/types";

export const CustomScrollbar: FC<ICustomScrollbar> = ({
  children,
  heightMax,
  autoHeight,
}) => {
  const ref = useRef();
  const [isVerticalScroll, setIsVerticalScroll] = useState(false);
  const [isHorizontalScroll, setIsHorizontalScroll] = useState(false);

  const calcVisibleScroll = (element: HTMLElement | null) => {
    // Высчитываем высоту элемента (видимую и не видимую)
    const totalHeight = element?.getScrollHeight();
    const visibilityHeight = element?.getClientHeight();
    setIsVerticalScroll(+visibilityHeight < +totalHeight);
    // Высчитываем ширину элемента (видимую и не видимую)
    const totalWidth = element?.getScrollWidth();
    const visibilityWidth = element?.getClientWidth();
    setIsHorizontalScroll(+visibilityWidth < +totalWidth);
  };

  useEffect(() => {
    if (ref && ref?.current) {
      calcVisibleScroll(ref.current);
    }

    return () => {
      setIsVerticalScroll(false);
      setIsHorizontalScroll(false);
    };
  }, [ref?.current]);

  return (
    <Scrollbars
      ref={ref}
      onScroll={() => calcVisibleScroll(ref?.current)}
      onUpdate={() => calcVisibleScroll(ref?.current)}
      renderTrackVertical={(props: any) => (
        <div {...props} className="track vertical" />
      )}
      renderThumbVertical={(props: any) => <div {...props} className="thumb" />}
      renderTrackHorizontal={(props: any) => (
        <div {...props} className="track horizontal" />
      )}
      renderThumbHorizontal={(props: any) => (
        <div {...props} className="thumb" />
      )}
      renderView={(props: any) => (
        <div
          {...props}
          className={`scroll-view ${isVerticalScroll ? "verticalScroll" : ""} ${
            isHorizontalScroll ? "horisontalScroll" : ""
          }`}
        />
      )}
      hideTracksWhenNotNeeded
      autoHeight={autoHeight}
      autoHeightMax={heightMax}
      style={{ overflow: "none" }}
    >
      {children}
    </Scrollbars>
  );
};
