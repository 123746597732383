import { Box, styled } from "@mui/material";

export const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "100%",
  padding: "1.5rem",
  boxSizing: "border-box",
  justifyContent: "center",
  alignItems: "center",
  color: theme.palette.text.primary,
  fontSize: "1rem",
  lineHeight: "1.2rem",
}));
