import { styled } from "@mui/material";

export const DotMark = styled("div", {
  shouldForwardProp: (prop) => prop !== "isSmall",
})<{ isSmall?: boolean }>(({ theme, isSmall }) => {
  const size = isSmall ? 1.4 : 2;
  return {
    display: "inline-block",
    width: theme.spacing(size),
    height: theme.spacing(size),
    minWidth: theme.spacing(size),
    minHeight: theme.spacing(size),
    borderRadius: "50%",
  };
});

export const DotMarkSuccess = styled(DotMark)(({ theme }) => ({
  backgroundColor: theme.palette.success.main,
}));

export const DotMarkFail = styled(DotMark)(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
}));

export const DotMarkChartPrimary = styled(DotMark)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? theme.palette.indicatorCard.tooltip
      : theme.palette.chart.fill.primary,
}));

export const DotMarkChartSecondary = styled(DotMark)(({ theme }) => ({
  backgroundColor: theme.palette.chart.fill.secondary,
}));

export const DotMarkChartBackground = styled(DotMark)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "light"
      ? theme.palette.table.backgroundImg
      : theme.palette.chart.background,
}));
