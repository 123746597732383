import { useTheme } from "@mui/material";
import { useAppSelector } from "@store/redux/hooks";
import { FC } from "react";

import { colorBasedOnValue } from "@/pages/MainPage/lib/helpers";
import { AchievementLevel } from "@/shared/AchievementLevel/ui/AchievementLevel";
import { formatNumber } from "@/shared/lib/heplers";
import { IPopUpProps } from "@/shared/RegionPopUp/lib/types";
import { ValueDate } from "@/shared/ValueDate/ValueDate";
import { MODEL_IDS, MODELS } from "@/store/scheme/olap";

import { getConvertDote } from "../lib/helpers";
import {
  Box,
  ChartsBox,
  CircleWrapper,
  Content,
  TextBlock,
} from "./Achievements.styled";
import { GetRowChart } from "./GetRowChart";

export const Achievements: FC<IPopUpProps> = ({ isOpenPopUp }) => {
  const theme = useTheme();
  const { mainAchievements } = useAppSelector((state) => state.ui.tabs);

  const modelPassport = MODELS[MODEL_IDS.PROJECT_PASSPORT];
  const passport = mainAchievements?.["0"]?.["0"];
  const projectResults = mainAchievements?.["129"]?.["0"];

  const models = {
    projectResults: {
      date: projectResults?.REPORT_DATE ?? "",
      value: formatNumber(
        projectResults?.indicatorVals?.[modelPassport.indexes.percent] ?? ""
      ),
      color: projectResults?.indicatorVals?.[modelPassport.indexes.color],
    },
    passport: {
      date: passport?.REPORT_DATE ?? "",
      value: formatNumber(
        passport?.indicatorVals?.[modelPassport.indexes.percent]
      ),
      color: passport?.indicatorVals?.[modelPassport.indexes.color],
      achievement: {
        value: formatNumber(
          passport?.indicatorVals?.[modelPassport.indexes.achievement]
        ),
        color:
          passport?.indicatorVals?.[
            modelPassport.indexes.achievementProjectColor
          ],
      },
    },
  };

  const valuePassport = getConvertDote(models?.passport?.achievement.value);
  const colorPassport = models?.passport?.achievement.color || null;

  return (
    <Content isOpenPopUp={!!isOpenPopUp}>
      <Box>
        <div
          style={
            isOpenPopUp
              ? {
                  height: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                }
              : {}
          }
        >
          <TextBlock isOpenPopUp={isOpenPopUp}>
            <div
              className="total"
              style={{ ...(isOpenPopUp && { textTransform: "uppercase" }) }}
            >
              Итого
            </div>

            <ValueDate isOpenPopUp={isOpenPopUp}>
              {models.passport.date}
            </ValueDate>
          </TextBlock>
          <CircleWrapper>
            <AchievementLevel
              isOpenPopUp={isOpenPopUp}
              value={valuePassport}
              size={isOpenPopUp ? 270 : 200}
              radius="92%"
              unit="%"
              color={colorBasedOnValue(theme, colorPassport)}
            />
          </CircleWrapper>
        </div>
      </Box>

      <ChartsBox sx={isOpenPopUp ? { paddingRight: "1.5rem" } : {}}>
        <GetRowChart
          isOpenPopUp={isOpenPopUp}
          title="По показателям проектов"
          date={models.passport.date}
          value={models.passport.value}
          color={models.passport.color || null}
        />
        <GetRowChart
          isOpenPopUp={isOpenPopUp}
          title="По контрольным точкам"
          date={models.projectResults.date}
          value={models.projectResults.value}
          color={models.projectResults.color || null}
        />
      </ChartsBox>
    </Content>
  );
};
