import { FC, useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router";

import { useTabs } from "@/app/hooks/useTabs";
import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { getUrlByTheme } from "@/shared/lib/heplers";

import { IAppBarRegionsFilter, targetType } from "../../lib/types";
import { AppBarFilter } from "../AppBarFilter/AppBarFilter";

export const AppBarRegionsFilter: FC<IAppBarRegionsFilter> = ({
  subjectId,
  navigatePath,
  navigatePathFO,
  useRF,
  useFO,
  path,
}) => {
  const { arrRegions } = useRegions();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { activeTab } = useTabs();
  const isGraphPage = pathname.includes("/graphs");

  const convertPath = useMemo(() => {
    const formatingPath = path?.split("/");
    const lastElement = formatingPath?.[formatingPath?.length - 1];
    return isNaN(Number(lastElement)) && lastElement !== "graphs"
      ? lastElement
      : null;
  }, [path]);

  const handleClick = useCallback(
    ({ id, type }: { id: string; type: targetType }) => {
      const path =
        type === targetType.FO ? navigatePathFO || navigatePath : navigatePath;
      const nav = `${path.replace(/{id}/gi, id)}${
        activeTab && !isGraphPage ? `/${activeTab}` : ""
      }`;
      navigate(getUrlByTheme(`${nav}${convertPath ? `/${convertPath}` : ""}`), {
        state: { backLink: pathname },
      });
    },
    [
      navigatePathFO,
      navigatePath,
      activeTab,
      navigate,
      pathname,
      isGraphPage,
      convertPath,
    ]
  );

  return (
    <AppBarFilter
      useRF={useRF}
      useFO={useFO}
      defaultValue="Не выбрано"
      arrayData={arrRegions}
      subjectId={subjectId}
      onChange={handleClick}
    />
  );
};
