import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IAttributeVals,
  IModel,
  IModelProjectResults,
  IOlapResponseDimensionItemDimWithVals,
  IPeriodAttributeVals,
  IPreparedIndicatorAttributeVals,
  IRowDatePart,
  isModel,
  MODEL_IDS,
  MODELS,
  RATE,
} from "@store/scheme/olap";

import { prepareAttributes } from "@/shared/lib/heplers";

import { newTerritories } from "../lib/constants";
import { IMapGeoState } from "../lib/types";
import {
  getGeoJSON,
  getKpiData,
  getProjectPassport,
  getProjectPassportForProjects,
  getProjectResults,
  getProjectsData,
  getProjectsKT,
} from "./api/api";

export const initialState: IMapGeoState = {
  foData: undefined,
  foFetching: false,
  foError: null,
  regionsData: undefined,
  regionsFetching: false,
  regionsError: null,
  kpiData: undefined,
  kpiDataFetching: false,
  kpiError: null,
  projectPassport: undefined,
  projectPassportFetching: false,
  projectPassportError: null,
  projectPassportForProjects: undefined,
  projectPassportForProjectsFetching: false,
  projectPassportForProjectsError: null,
  projectResults: undefined,
  projectResultsFetching: false,
  projectResultsError: null,
  projectsData: undefined,
  projectsDataFetching: false,
  projectsDataError: null,
  projectsKT: undefined,
  projectsKTFetching: false,
  projectsKTError: null,
};

export const geoExtraActions = {
  getGeoJson: createAsyncThunk(
    "map/fo/geo/get",
    async (mapId: string) => await getGeoJSON(mapId)
  ),
  getRegionsGeoJson: createAsyncThunk(
    "map/regions/geo/get",
    async (mapId: string) => await getGeoJSON(mapId)
  ),
  getKpiData: createAsyncThunk(
    "map/kpi/get",
    async (dataSummaryId: string) => await getKpiData(dataSummaryId)
  ),
  getProjectPassport: createAsyncThunk(
    "map/project_passport/get",
    async (dataSummaryId: string) => await getProjectPassport(dataSummaryId)
  ),
  getProjectPassportForProjects: createAsyncThunk(
    "map/project_passport_for_projects/get",
    async (dataSummaryId: string) =>
      await getProjectPassportForProjects(dataSummaryId)
  ),
  getProjectResults: createAsyncThunk(
    "map/project_results/get",
    async () => await getProjectResults()
  ),
  getProjectsData: createAsyncThunk(
    "map/project_data/get",
    async () => await getProjectsData()
  ),
  getProjectsKT: createAsyncThunk(
    "map/project_kt/get",
    async () => await getProjectsKT()
  ),
};

const getParsedData = (
  res: IOlapResponseDimensionItemDimWithVals<IAttributeVals>[],
  model: IModel
) =>
  res.reduce((prev, row) => {
    const dimId = isModel<IModelProjectResults>(
      model,
      MODEL_IDS.PROJECT_RESULTS
    )
      ? model.dataProjectId
      : model.dimId;
    const regionId = row.dims[model.dataRegionId].code;
    const projectId = row.dims[dimId].code;
    const dateCode = row.code;
    const datePart = (row.attributeVals as unknown as IPeriodAttributeVals)
      .DatePart;
    const date = (row.attributeVals as unknown as IPeriodAttributeVals)
      .REPORT_DATE;
    const prevDate = (row.attributeVals as unknown as IPeriodAttributeVals)
      .PREVIOUS_DATE;
    const year = (row.attributeVals as unknown as IPeriodAttributeVals).YEAR;

    const item = row.dims[dimId];

    if (!prev[regionId]) {
      prev[regionId] = {};
    }

    if (!prev[regionId][projectId]) {
      prev[regionId][projectId] = {};
    }

    if (!prev[regionId][projectId][datePart]) {
      prev[regionId][projectId][datePart] = {};
    }

    prev[regionId][projectId][datePart]![dateCode] = {
      id: item.id,
      code: item.code,
      date,
      prevDate,
      year: year ? `${year} г.` : undefined,
      level: item.level,
      values: Object.keys(model.indexes).reduce((prev, curr) => {
        const index = model.indexes[curr as keyof typeof model.indexes];
        prev[index] = item.indicatorVals[index]?.sum;
        return prev;
      }, {} as { [sumIndicatorId: string]: number }),
      attributeVals: prepareAttributes<IPreparedIndicatorAttributeVals>(
        item.attributeVals
      ),
    };

    const fact =
      "fact" in model.indexes &&
      prev[regionId][projectId][datePart]![dateCode]!.values?.[
        model.indexes.fact
      ];

    if (["10", "13", "16", "19", "22"].includes(projectId) && fact) {
      prev[regionId][projectId][datePart]![dateCode]!.rate =
        fact < 100 ? RATE.LOW : RATE.HIGH;
    }

    return prev;
  }, {} as { [regionId: string]: IRowDatePart<IPreparedIndicatorAttributeVals> });

const geoSlice = createSlice({
  name: "map/geo",
  initialState,
  reducers: {
    changeMapCoordinate: (state, action: PayloadAction<string | undefined>) => {
      // @ts-ignore
      state.foData.jsonData.features = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(geoExtraActions.getGeoJson.pending, (state) => {
        state.foFetching = true;
      })
      .addCase(geoExtraActions.getRegionsGeoJson.pending, (state) => {
        state.regionsFetching = true;
      })
      .addCase(geoExtraActions.getKpiData.pending, (state) => {
        state.kpiDataFetching = true;
      })
      .addCase(geoExtraActions.getProjectPassport.pending, (state) => {
        state.projectPassportFetching = true;
      })
      .addCase(
        geoExtraActions.getProjectPassportForProjects.pending,
        (state) => {
          state.projectPassportForProjectsFetching = true;
        }
      )
      .addCase(geoExtraActions.getProjectResults.pending, (state) => {
        state.projectResultsFetching = true;
      })
      .addCase(geoExtraActions.getProjectsData.pending, (state) => {
        state.projectsDataFetching = true;
      })
      .addCase(geoExtraActions.getProjectsKT.pending, (state) => {
        state.projectsKTFetching = true;
      })
      .addCase(geoExtraActions.getGeoJson.rejected, (state, action) => {
        state.foFetching = false;
        state.foError = action.error;
      })
      .addCase(geoExtraActions.getRegionsGeoJson.rejected, (state, action) => {
        state.regionsFetching = false;
        state.regionsError = action.error;
      })
      .addCase(geoExtraActions.getKpiData.rejected, (state, action) => {
        state.kpiDataFetching = false;
        state.kpiError = action.error;
      })
      .addCase(geoExtraActions.getProjectPassport.rejected, (state, action) => {
        state.projectPassportFetching = false;
        state.projectPassportError = action.error;
      })
      .addCase(
        geoExtraActions.getProjectPassportForProjects.rejected,
        (state, action) => {
          state.projectPassportForProjectsFetching = false;
          state.projectPassportForProjectsError = action.error;
        }
      )
      .addCase(geoExtraActions.getProjectResults.rejected, (state, action) => {
        state.projectResultsFetching = false;
        state.projectResultsError = action.error;
      })
      .addCase(geoExtraActions.getProjectsData.rejected, (state, action) => {
        state.projectsDataFetching = false;
        state.projectsDataError = action.error;
      })
      .addCase(geoExtraActions.getProjectsKT.rejected, (state, action) => {
        state.projectsKTFetching = false;
        state.projectsKTError = action.error;
      })
      .addCase(geoExtraActions.getKpiData.fulfilled, (state, action) => {
        state.kpiDataFetching = false;
        const model = MODELS[MODEL_IDS.KPI_SOC_ECONOMY];
        state.kpiData = getParsedData(action.payload, model);
        state.kpiError = null;
      })
      .addCase(geoExtraActions.getGeoJson.fulfilled, (state, action) => {
        state.foData = {
          ...action.payload.data,
          jsonData: {
            ...action.payload.data.jsonData,
            features: [
              ...newTerritories,
              ...action.payload.data.jsonData.features.map((feature: any) => ({
                ...feature,
                properties: {
                  ...feature.properties,
                  name: feature.properties.name.replace(
                    /федеральный округ/gi,
                    "ФО"
                  ),
                  fullName: feature.properties.name,
                },
              })),
            ] as any,
          },
        };
        state.foFetching = false;
        state.foError = null;
      })
      .addCase(geoExtraActions.getRegionsGeoJson.fulfilled, (state, action) => {
        state.regionsFetching = false;
        const regData = action.payload.data;

        state.regionsData = {
          ...regData,
          jsonData: {
            ...regData.jsonData,
            features: regData.jsonData.features.map((item: any) => ({
              ...item,
              properties: {
                ...item.properties,
                id: String(item.properties.id),
              },
            })),
          },
        };
        state.regionsError = null;
      })
      .addCase(
        geoExtraActions.getProjectPassport.fulfilled,
        (state, action) => {
          state.projectPassportFetching = false;
          const model = MODELS[MODEL_IDS.PROJECT_PASSPORT];

          state.projectPassport = getParsedData(action.payload, model);
          state.projectPassportError = null;
        }
      )
      .addCase(
        geoExtraActions.getProjectPassportForProjects.fulfilled,
        (state, action) => {
          state.projectPassportForProjectsFetching = false;
          const model = MODELS[MODEL_IDS.PROJECT_PASSPORT];
          state.projectPassportForProjects = action.payload.reduce(
            (prev, cur) => {
              const projectId = cur?.dims?.[model.dataProjectId]?.code;
              const indicatorId = cur.dims[model.dimId].code;
              const dateCode = cur.code;
              const datePart = (
                cur.attributeVals as unknown as IPeriodAttributeVals
              ).DatePart;

              if (projectId) {
                if (!prev[projectId]) {
                  prev[projectId] = {};
                }

                if (!prev[projectId][indicatorId]) {
                  prev[projectId][indicatorId] = {};
                }

                if (!prev[projectId][indicatorId][datePart]) {
                  prev[projectId][indicatorId][datePart] = {};
                }

                prev[projectId][indicatorId][datePart][dateCode] = {
                  ...prepareAttributes<IPreparedIndicatorAttributeVals>(
                    cur.dims[model.dimId].attributeVals
                  ),
                  values: Object.keys(model.indexes).reduce((prev, curr) => {
                    const index =
                      model.indexes[curr as keyof typeof model.indexes];
                    prev[index] =
                      cur.dims[model.dimId]?.indicatorVals?.[index]?.sum;
                    return prev;
                  }, {} as { [sumIndicatorId: string]: number }),
                  attributeVals: cur?.attributeVals || {},
                };
              }
              return prev;
            },
            {} as any
          );
          state.projectPassportForProjectsError = null;
        }
      )
      .addCase(geoExtraActions.getProjectResults.fulfilled, (state, action) => {
        state.projectResultsFetching = false;
        const model = MODELS[MODEL_IDS.PROJECT_RESULTS];
        state.projectResults = getParsedData(action.payload, model);
        state.projectResultsError = null;
      })
      .addCase(geoExtraActions.getProjectsData.fulfilled, (state, action) => {
        state.projectsDataFetching = false;
        const model = MODELS[MODEL_IDS.PROJECT_PASSPORT];
        state.projectsData = getParsedData(action.payload, model);
        state.projectsDataError = null;
      })
      .addCase(geoExtraActions.getProjectsKT.fulfilled, (state, action) => {
        state.projectsKTFetching = false;
        const model = MODELS[MODEL_IDS.PROJECT_RESULTS];
        state.projectsKT = getParsedData(action.payload, model);
        state.projectsKTError = null;
      });
  },
});

export const reducer = geoSlice.reducer;

export const { changeMapCoordinate } = geoSlice.actions;

export default geoSlice;
