import { FC, SVGProps } from "react";

import theme from "../../app/theme/common";

export interface IDynamicsLowProps extends SVGProps<SVGSVGElement> {
  contrast?: boolean;
  isBig?: boolean;
}

const icon: FC<IDynamicsLowProps> = ({
  children,
  contrast = false,
  isBig,
  ...props
}) => (
  <svg
    width={isBig ? "40" : "24"}
    height={isBig ? "40" : "24"}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      opacity="0.6"
      r="11.5"
      transform="matrix(-1 0 0 1 12 12)"
      stroke={contrast ? theme.palette.error.main : theme.palette.success.main}
    />
    <path
      d="M7.97 17.6672C7.49 17.6672 7.064 17.6012 6.692 17.4692C6.326 17.3312 6.017 17.1392 5.765 16.8932C5.513 16.6412 5.321 16.3442 5.189 16.0022C5.063 15.6542 5 15.2702 5 14.8502V14.6702C5 14.2742 5.063 13.9142 5.189 13.5902C5.321 13.2602 5.513 12.9812 5.765 12.7532C6.017 12.5252 6.326 12.3482 6.692 12.2222C7.064 12.0962 7.49 12.0332 7.97 12.0332V11.4302H9.05V12.0332C9.524 12.0332 9.944 12.0962 10.31 12.2222C10.682 12.3482 10.994 12.5252 11.246 12.7532C11.498 12.9812 11.69 13.2602 11.822 13.5902C11.954 13.9142 12.02 14.2742 12.02 14.6702V14.8502C12.02 15.2702 11.954 15.6542 11.822 16.0022C11.69 16.3442 11.498 16.6412 11.246 16.8932C10.994 17.1392 10.682 17.3312 10.31 17.4692C9.944 17.6012 9.524 17.6672 9.05 17.6672V18.2702H7.97V17.6672ZM10.886 14.6702C10.886 14.1242 10.733 13.7072 10.427 13.4192C10.121 13.1312 9.662 12.9872 9.05 12.9872V16.7132C9.668 16.7132 10.127 16.5512 10.427 16.2272C10.733 15.9032 10.886 15.4442 10.886 14.8502V14.6702ZM6.134 14.8502C6.134 15.4442 6.284 15.9032 6.584 16.2272C6.89 16.5512 7.352 16.7132 7.97 16.7132V12.9872C7.352 12.9872 6.89 13.1312 6.584 13.4192C6.284 13.7072 6.134 14.1242 6.134 14.6702V14.8502Z"
      fill={contrast ? theme.palette.error.main : theme.palette.success.main}
    />
    <path
      d="M15.8439 18.0992C15.4599 18.0992 15.0939 18.0392 14.7459 17.9192C14.3979 17.7992 14.0919 17.6132 13.8279 17.3612C13.5639 17.1032 13.3539 16.7732 13.1979 16.3712C13.0419 15.9632 12.9639 15.4712 12.9639 14.8952V14.7152C12.9639 14.1632 13.0419 13.6922 13.1979 13.3022C13.3539 12.9062 13.5639 12.5822 13.8279 12.3302C14.0919 12.0782 14.3979 11.8952 14.7459 11.7812C15.0939 11.6612 15.4599 11.6012 15.8439 11.6012C16.2279 11.6012 16.5939 11.6612 16.9419 11.7812C17.2899 11.8952 17.5959 12.0782 17.8599 12.3302C18.1239 12.5822 18.3339 12.9062 18.4899 13.3022C18.6459 13.6922 18.7239 14.1632 18.7239 14.7152V14.8952C18.7239 15.4712 18.6459 15.9632 18.4899 16.3712C18.3339 16.7732 18.1239 17.1032 17.8599 17.3612C17.5959 17.6132 17.2899 17.7992 16.9419 17.9192C16.5939 18.0392 16.2279 18.0992 15.8439 18.0992ZM15.8439 17.1452C16.0659 17.1452 16.2819 17.1062 16.4919 17.0282C16.7019 16.9502 16.8879 16.8242 17.0499 16.6502C17.2119 16.4702 17.3409 16.2362 17.4369 15.9482C17.5389 15.6602 17.5899 15.3092 17.5899 14.8952V14.7152C17.5899 14.3192 17.5389 13.9862 17.4369 13.7162C17.3409 13.4402 17.2119 13.2182 17.0499 13.0502C16.8879 12.8762 16.7019 12.7502 16.4919 12.6722C16.2819 12.5942 16.0659 12.5552 15.8439 12.5552C15.6159 12.5552 15.3969 12.5942 15.1869 12.6722C14.9769 12.7502 14.7909 12.8762 14.6289 13.0502C14.4669 13.2182 14.3379 13.4402 14.2419 13.7162C14.1459 13.9862 14.0979 14.3192 14.0979 14.7152V14.8952C14.0979 15.3092 14.1459 15.6602 14.2419 15.9482C14.3379 16.2362 14.4669 16.4702 14.6289 16.6502C14.7909 16.8242 14.9769 16.9502 15.1869 17.0282C15.3969 17.1062 15.6159 17.1452 15.8439 17.1452Z"
      fill={contrast ? theme.palette.error.main : theme.palette.success.main}
    />
    <path
      d="M12.1423 4.59966L16.1336 8.54199C16.2604 8.6672 16.1706 8.88129 15.9913 8.8813L8.00867 8.8813C7.82939 8.8813 7.73961 8.6672 7.86638 8.54199L11.8577 4.59966C11.9363 4.52204 12.0637 4.52204 12.1423 4.59966Z"
      fill={contrast ? theme.palette.error.main : theme.palette.success.main}
      stroke={contrast ? theme.palette.error.main : theme.palette.success.main}
    />
  </svg>
);

export default icon;
