import { Divider, useTheme } from "@mui/material";
import React, { Fragment, useCallback, useMemo, useRef } from "react";

import { colorBasedOnValue } from "@/pages/MainPage/lib/helpers";
import { useCountryTabs } from "@/pages/RegionPage/ui/RegionTabs/hooks/useCountryTabs";
import { useColorAchievements } from "@/shared/AchievementLevel/hooks/useColorAchievement";
import { AchievementLevel } from "@/shared/AchievementLevel/ui/AchievementLevel";
import { useAchievementTabs } from "@/shared/AchievementTabCard/hooks/useAchievementTabs";
import { IEconomicsProjectCardProps } from "@/shared/AchievementTabCard/lib/types";
import { Unit } from "@/shared/DynamicsTabCard/ui/components/IndicatorCard/IndicatorCard.styled";
import { IDynamicTab } from "@/shared/GridCard/lib/types";
import { GridCardButton } from "@/shared/GridCardButton/ui/GridCardButton";
import { useOpenIncident } from "@/shared/Incident/hooks/useOpenIncident";
import { IncidentCreateButton } from "@/shared/Incident/ui/IncidentCreateButton/IncidentCreateButton";
import { failNoText } from "@/shared/lib/constants";
import { formatNumber } from "@/shared/lib/heplers";
import { Sceleton } from "@/shared/Sceleton/ui/Sceleton";
import { TAB_ID } from "@/store/scheme/region";

import {
  CardContentStyled,
  CardDate,
  CardSubTitle,
  CardTitleStyled,
  DiagramStyled,
  GridCardStyled,
  MainTabsWrapper,
  ValueColor,
  Wrapper,
} from "./EconomicsProjectCard.styled";

export const EconomicsProjectCard = ({
  isFullShow,
  selectedTabId,
  setSelectedTabId,
  setIsFullShow,
  tabs,
}: IEconomicsProjectCardProps) => {
  const theme = useTheme();
  const showRef = useRef<HTMLDivElement | null>(null);
  const { handleChange } = useOpenIncident();
  const { highlightWidget, setHighlightWidget } = useAchievementTabs();
  const { tabEconomicsFetching } = useCountryTabs();
  const { achievementColor } = useColorAchievements({ selected: false });

  const mainTab: IDynamicTab[] = useMemo(() => {
    return tabs.filter((tab) => [TAB_ID.VDL_PROJECTS].includes(tab.id));
  }, [tabs]);

  const secondTabs: IDynamicTab[] = useMemo(() => {
    return tabs.filter((t) => [TAB_ID.VDL, TAB_ID.PROJECTS].includes(t.id));
  }, [tabs]);

  const handleMouseMove = (tabID: TAB_ID) => {
    const isCheckTab = tabID === TAB_ID.PROJECTS;
    setHighlightWidget(isCheckTab ? "Achievements" : "GoalsTabs");
  };

  const handleMouseLeave = () => {
    setHighlightWidget("");
  };

  const isHighLight = useCallback(
    (text: string = "") => {
      return (
        (highlightWidget === "GoalsTabs" && /^по целям/.test(text || "")) ||
        (highlightWidget === "Achievements" && /^по проектам/.test(text || ""))
      );
    },
    [highlightWidget]
  );

  if (tabEconomicsFetching) {
    return <Sceleton type="circle" />;
  }

  return (
    <Wrapper
      ref={showRef}
      selected={[TAB_ID.VDL_PROJECTS, TAB_ID.VDL, TAB_ID.PROJECTS].includes(
        selectedTabId
      )}
      onClick={() => setSelectedTabId(TAB_ID.VDL_PROJECTS)}
    >
      <MainTabsWrapper>
        {mainTab.map((tab: IDynamicTab) => (
          <Fragment key={tab.id}>
            <GridCardStyled sx={isFullShow ? { maxWidth: 200 } : undefined}>
              <div>
                <CardTitleStyled
                  variant="subtitle2"
                  selected={selectedTabId === tab.id}
                >
                  <CardSubTitle selected={selectedTabId === tab.id}>
                    Достижение
                  </CardSubTitle>

                  {`${tab?.text ?? failNoText}`}
                  {tab?.unit && <Unit>{`, ${tab.unit}`}</Unit>}
                </CardTitleStyled>
              </div>

              <CardContentStyled>
                <ValueColor
                  valueColor={tab?.color}
                  selected={selectedTabId === tab.id}
                  sx={{ fontSize: 52, whiteSpace: "nowrap" }}
                >
                  {formatNumber(tab?.fact, tab?.unit, 0) ?? "-"}
                </ValueColor>
              </CardContentStyled>
            </GridCardStyled>

            {tab?.date && (
              <CardDate selected={selectedTabId === tab.id}>
                {tab?.date}
              </CardDate>
            )}
          </Fragment>
        ))}

        {isFullShow && (
          <>
            <Divider orientation="vertical" flexItem />

            <MainTabsWrapper sx={{ flexDirection: "column" }}>
              {secondTabs.map((tab: IDynamicTab) => (
                <GridCardStyled
                  key={tab.id}
                  sx={{ display: "flex", flexDirection: "row-reverse" }}
                  onMouseLeave={handleMouseLeave}
                  onMouseMove={() => handleMouseMove(tab.id)}
                >
                  <div>
                    <CardTitleStyled
                      isHighLight={isHighLight(tab?.text)}
                      variant="subtitle2"
                      selected={[
                        TAB_ID.VDL_PROJECTS,
                        TAB_ID.VDL,
                        TAB_ID.PROJECTS,
                      ].includes(selectedTabId)}
                    >
                      <CardSubTitle
                        isHighLight={isHighLight(tab?.text)}
                        selected={[
                          TAB_ID.VDL_PROJECTS,
                          TAB_ID.VDL,
                          TAB_ID.PROJECTS,
                        ].includes(selectedTabId)}
                      >
                        Достижение
                      </CardSubTitle>

                      {`${tab?.text ?? failNoText}`}
                      {tab?.unit && (
                        <Unit
                          isHighLight={isHighLight(tab?.text)}
                        >{`, ${tab.unit}`}</Unit>
                      )}
                    </CardTitleStyled>
                    <ValueColor
                      valueColor={tab?.color}
                      sx={{ fontSize: 20 }}
                      isHighLight={isHighLight(tab?.text)}
                      selected={selectedTabId === tab.id}
                    >
                      {formatNumber(tab?.fact, tab?.unit, 0) ?? "-"}
                    </ValueColor>
                  </div>

                  <CardContentStyled>
                    <DiagramStyled>
                      <AchievementLevel
                        isHighLight={isHighLight(tab?.text)}
                        value={tab?.fact}
                        isHideText={true}
                        size={72}
                        radius="80%"
                        color={colorBasedOnValue(
                          theme,
                          tab?.color || null,
                          selectedTabId === tab.id
                        )}
                        blur={achievementColor(tab?.fact)?.blur}
                      />
                    </DiagramStyled>
                  </CardContentStyled>
                </GridCardStyled>
              ))}
            </MainTabsWrapper>
          </>
        )}
      </MainTabsWrapper>
      <IncidentCreateButton
        className="hoverIncident"
        posRight={62}
        onClick={(event) => {
          handleChange(
            event,
            showRef?.current,
            `Достижение ${mainTab?.[0].text}`,
            mainTab?.[0]?.date
          );
        }}
      />
      <GridCardButton
        selected={[TAB_ID.VDL_PROJECTS, TAB_ID.VDL, TAB_ID.PROJECTS].includes(
          selectedTabId
        )}
        turnOff={isFullShow}
        handleClick={() => setIsFullShow(!isFullShow)}
      />
    </Wrapper>
  );
};
