import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { IProjectAttributeVals } from "@/store/scheme/olap";

import { ILevelTypeIncident, ITypeIncidentState } from "../../lib/types";
import { getTypeIncidents } from "../api/typeIncidentsApi";

export const initialState: ITypeIncidentState = {
  typeIncident: [],
  fetching: false,
  error: null,
};

export const extraActionsTypeIncident = {
  post: createAsyncThunk(
    "typeIncident/post",
    async () => await getTypeIncidents()
  ),
};

const typeIncidentsSlice = createSlice({
  name: "typeIncident",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActionsTypeIncident.post.pending, (state) => {
        state.fetching = true;
      })
      .addCase(extraActionsTypeIncident.post.fulfilled, (state, action) => {
        state.typeIncident = action.payload
          .reduce((prev, cur) => {
            const { name, SHORT_NAME: id } =
              cur.attributeVals as IProjectAttributeVals;
            if (!Array.isArray(prev)) {
              prev = [];
            }
            if (Number(id)) {
              const level: ILevelTypeIncident = {
                id,
                name,
              };
              prev.push(level);
            }

            return prev;
          }, [] as ILevelTypeIncident[])
          ?.sort((aType, bType) => {
            return (aType?.id || 0) > (bType?.id || 0) ? 1 : -1;
          });

        state.fetching = false;
        state.error = null;
      })
      .addCase(extraActionsTypeIncident.post.rejected, (state, action) => {
        state.fetching = false;
        state.error = action.error;
      });
  },
});

export const reducer = typeIncidentsSlice.reducer;

export default typeIncidentsSlice;
