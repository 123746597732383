import CheckBoxIcon from "@mui/icons-material/CheckBox";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { ReactComponent as SquareIcon } from "@/assets/SquareCheckBox.svg";

import { useColumns } from "../../hooks/useColumns";
import { ICheckedScheme, IFuturePlanCheckBoxProps } from "../../lib/types";
import { CheckBoxStyled, FormControlLabelStyled } from "../PopUp/PopUp.styled";

export const FuturePlanCheckBox = ({
  activeColumns,
  handlePlanCheckBox,
}: IFuturePlanCheckBoxProps) => {
  const [checkedPlan, setCheckedPlan] = useState<boolean>(false);
  const { copyObject, getParentLabel } = useColumns();

  const countFuturePlan = useMemo(() => {
    let countChecked = 0,
      countDateCode = 0;
    if (activeColumns) {
      Object.values(activeColumns).forEach(({ checked, isDateCode }) => {
        if (isDateCode) {
          ++countDateCode;
          if (checked) ++countChecked;
        }
      });
      if (countDateCode) {
        return countChecked;
      }
    }
    return 0;
  }, [activeColumns]);

  const allCountFuturePlan = useMemo(() => {
    return Object.keys(activeColumns || {}).includes("Контрольные точки")
      ? 5
      : 4;
  }, [activeColumns]);

  const isCheckedIcon = useMemo(() => {
    if (countFuturePlan > 0 && countFuturePlan < allCountFuturePlan) {
      return <SquareIcon />;
    }
    return <CheckBoxIcon />;
  }, [countFuturePlan, allCountFuturePlan]);

  const handleChangeCheckBox = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked;
      setCheckedPlan(checked);
      const scheme: ICheckedScheme = copyObject(activeColumns);

      Object.values(scheme).forEach(({ isDateCode, popUpLabel }) => {
        if (isDateCode && popUpLabel) {
          scheme[popUpLabel].checked = checked;
          const parentLabel = getParentLabel(popUpLabel);

          if (parentLabel) {
            const quantitySubColumns =
              scheme[parentLabel].quantitySubColumns || 0;
            scheme[parentLabel].quantitySubColumns = checked
              ? quantitySubColumns + 1
              : quantitySubColumns === 0
              ? 0
              : quantitySubColumns - 1;
          }
        }
      });
      handlePlanCheckBox(scheme);
    },
    [activeColumns]
  );

  useEffect(() => {
    setCheckedPlan(!!countFuturePlan);
  }, [countFuturePlan]);

  return (
    <FormControlLabelStyled
      control={
        <CheckBoxStyled
          checked={checkedPlan}
          onChange={handleChangeCheckBox}
          checkedIcon={isCheckedIcon}
        />
      }
      label="План на 2024/2030"
    />
  );
};
