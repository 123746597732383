import { styled, Typography } from "@mui/material";
import { TypographyProps } from "@mui/material/Typography/Typography";

export interface ICardContainerProps {
  selected?: boolean;
}

export const CardContainer = styled("div")<ICardContainerProps>(
  ({ theme, selected }) => ({
    cursor: "pointer",
    boxSizing: "border-box",
    position: "relative",
    display: "flex",
    flexFlow: "column nowrap",
    height: 200,
    padding: theme.spacing(2, 3, 0, 3),
    background: selected
      ? theme.palette.indicatorCard.backgroundColorSelected
      : theme.palette.indicatorCard.backgroundColor,
    borderRadius: theme.spacing(2),
    border: `2px solid ${
      theme.palette.border[selected ? "selected" : "default"]
    }`,
  })
);

export const Title = styled(Typography)<{ selected?: boolean }>(
  ({ theme, selected }) => ({
    height: 44,
    fontSize: 18,
    margin: theme.spacing(0, 0, 2),
    color: selected
      ? theme.palette.icon.colorSelected
      : theme.palette.text.tertiary,
  })
);

export interface IUnitProps {
  isHighLight?: boolean;
}

export const Unit = styled("span")<IUnitProps>(
  ({ theme, isHighLight = false }) => ({
    fontSize: 14,
    margin: theme.spacing(0, 0, 0, 0.5),
    color: theme.palette.text.grey,
    ...(isHighLight && {
      textShadow: `0px 0px 0.25rem ${theme.palette.diagram.blurGrey}`,
    }),
  })
);

export const CardContentWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
}));

export const PlanFactWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexFlow: "column nowrap",
  alignSelf: "center",
  marginLeft: theme.spacing(0.5),
}));

export const ValueWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  fontWeight: 400,
  fontSize: 13,
}));

export const Value = (props: TypographyProps) => (
  <Typography variant="h5" style={{ whiteSpace: "nowrap" }} {...props} />
);

export const PlanFact = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 13,
  margin: theme.spacing(0, 0, 1),
  opacity: 0.5,
}));

export const ValueDate = styled("span")<{
  prevPeriod?: boolean;
  selected?: boolean;
}>(({ theme, prevPeriod, selected }) => ({
  position: "absolute",
  bottom: prevPeriod ? 21 : 8,
  right: 14,
  fontSize: 12,
  color: selected ? theme.palette.text.lightGrey : theme.palette.tabs.dateTab,
}));

export const BigValue = styled("span")<{ selected?: boolean }>(
  ({ theme, selected }) => ({
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 52,
    color: selected
      ? theme.palette.geo.select?.border
      : theme.palette.text.primary,
  })
);

export const BigValueLabel = styled("span")<{ selected?: boolean }>(
  ({ theme, selected }) => ({
    fontSize: 18,
    opacity: 0.7,
    color: selected
      ? theme.palette.geo.select?.border
      : theme.palette.text.primary,
  })
);

export const BigValueWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
}));

export const FactRF = styled("div")(({ theme }) => ({
  display: "flex",
  flexFlow: "column nowrap",
}));

export const FactValue = styled("span")<{ selected?: boolean }>(
  ({ theme, selected }) => ({
    display: "flex",
    alignItems: "center",
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 36,
    color: selected
      ? theme.palette.geo.select?.border
      : theme.palette.text.primary,
    whiteSpace: "nowrap",
  })
);

export const RFValue = styled("span")<{ selected?: boolean }>(
  ({ theme, selected }) => ({
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 24,
    opacity: 0.7,
    color: selected
      ? theme.palette.geo.select?.border
      : theme.palette.text.primary,
    whiteSpace: "nowrap",
  })
);

export const FactValueLabel = styled("span")<{ selected?: boolean }>(
  ({ theme, selected }) => ({
    marginTop: theme.spacing(0.5),
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 16,
    opacity: 0.7,
    color: selected
      ? theme.palette.geo.select?.border
      : theme.palette.text.primary,
  })
);
