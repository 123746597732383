export const defaultIndicatorsData = [
  {
    id: 1,
    title: "Объекты капитального строительства",
    indicator: "103",
  },
  {
    id: 2,
    title: "Достижение",
    indicator: "101",
  },
  {
    id: 3,
    title: "Финансирование",
    indicator: "102",
  },
];
