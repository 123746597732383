import { useMemo } from "react";

import { useDistrictRowTableT } from "../lib/types";

export const useDistrictRowTable = ({
  columns,
  allDistricts,
  getIndicator,
  isSort,
}: useDistrictRowTableT) => {
  const districtsRows = useMemo(() => {
    const sortableColumns = columns.filter((i) => i.sortBy);
    const result = allDistricts?.map((district) => {
      const values = sortableColumns.map((col) => [
        [col.sortBy as string],
        getIndicator(
          col?.data,
          district?.id,
          col?.id,
          undefined,
          col?.model,
          undefined,
          col?.dateCode
        )?.values?.[col?.indicator] || 0,
      ]);

      return {
        title: district?.attributeVals?.name,
        id: district.id,
        ...Object.fromEntries(values),
      };
    });

    if (isSort) {
      return result.sort((a, b) => +a.place - +b.place);
    }
    return result;
  }, [allDistricts, columns, getIndicator, isSort]);

  return { districtsRows };
};
