import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { prepareAttributes } from "@/shared/lib/heplers";
import {
  IOlapResponseDimensionItemDimWithVals,
  IPeriodAttributeVals,
  IProjectFinancesAttributeVals,
  MODEL_IDS,
  MODELS,
} from "@/store/scheme/olap";

import {
  IPagesProjectFinancesData,
  IPagesProjectFinancesState,
} from "../../lib/types";
import { getProjectFinances } from "../api/api";

export const initialState: IPagesProjectFinancesState = {
  data: undefined,
  fetching: false,
  error: null,
};

export const extraActionsFinances = {
  getProjectFinances: createAsyncThunk(
    "pages/project/finances/get",
    async (projectId: string) => await getProjectFinances({ projectId })
  ),
};

const financesSlice = createSlice({
  name: "pages/project/finances",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActionsFinances.getProjectFinances.pending, (state) => {
        state.data = undefined;
        state.fetching = true;
      })
      .addCase(
        extraActionsFinances.getProjectFinances.fulfilled,
        (state, action) => {
          const model = MODELS[MODEL_IDS.PROJECT_FINANCES];

          state.data = action.payload.reduce(
            (prev, item) => {
              const dimIdItem = item.dims[model.dimId];
              const periodId = item.id;
              const indicatorId =
                dimIdItem.attributeVals.type_ind_id || dimIdItem.id;
              const attributeVals = {
                ...prepareAttributes<IProjectFinancesAttributeVals>(
                  dimIdItem.attributeVals
                ),
                DateEnd: item.attributeVals.DateEnd,
              };

              const indicator = {
                ...item,
                attributeVals,
                values: Object.keys(model.indexes).reduce((prev, curr) => {
                  const index =
                    model.indexes[curr as keyof typeof model.indexes];
                  prev[index] = dimIdItem.indicatorVals[index]?.sum;
                  return prev;
                }, {} as { [sumIndicatorId: string]: number }),
              };
              // Забираем актуальный период
              if (
                !prev.summary[indicatorId] &&
                item.dims[model.dataActualId].code === "2"
              ) {
                prev.summary[indicatorId] = indicator;
              }

              // periodId !== "120210000" - исключение данных за 2021 год
              if (!prev.periods[periodId] && periodId !== "120210000") {
                const { attributeVals: periodAttributeVals, ...period } = item;
                prev.periods[periodId] = {
                  ...(period as unknown as IOlapResponseDimensionItemDimWithVals<IPeriodAttributeVals>),
                  attributeVals:
                    prepareAttributes<IPeriodAttributeVals>(
                      periodAttributeVals
                    ),
                  indicators: {},
                };
              }

              if (prev.periods[periodId]) {
                prev.periods[periodId].indicators[indicatorId] = indicator;
              }

              return prev;
            },
            { summary: {}, periods: {} } as IPagesProjectFinancesData
          );

          state.fetching = false;
          state.error = null;
        }
      )
      .addCase(
        extraActionsFinances.getProjectFinances.rejected,
        (state, action) => {
          state.fetching = false;
          state.error = action.error;
        }
      );
  },
});

export const reducer = financesSlice.reducer;

export default financesSlice;
