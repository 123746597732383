import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { prepareAttributes } from "@/shared/lib/heplers";
import {
  IOlapResponseDimensionItemDimWithVals,
  IPreparedIndicatorAttributeVals,
  MODEL_IDS,
  MODELS,
} from "@/store/scheme/olap";

import { ISumFoProjectsState } from "../../lib/types";
import { getFOSumProjects } from "../api/sum_fo_projects_api";

export const initialState: ISumFoProjectsState = {
  sumProjectsByFo: undefined,
  fetching: true,
  error: null,
};

export const extraActionsSumFoProjects = {
  get: createAsyncThunk(
    "sum_fo_projects/get",
    async ({ dataSummaryId, dataRegionId }: Record<string, string>) =>
      await getFOSumProjects({ dataSummaryId, dataRegionId })
  ),
};

const sumFoProjectsSlice = createSlice({
  name: "sum_fo_projects",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActionsSumFoProjects.get.pending, (state) => {
        state.sumProjectsByFo = undefined;
        state.fetching = true;
      })
      .addCase(extraActionsSumFoProjects.get.fulfilled, (state, action) => {
        const model = MODELS[MODEL_IDS.PROJECT_PASSPORT];

        // Получение статуса для виджета Проекты из квартальных данных
        const dataByStatus = action.payload.reduce((prev, cur) => {
          const datePart = (cur?.attributeVals as any)?.DatePart;
          const projectId = cur?.dims?.[model.dataProjectId]?.code;
          if (projectId && datePart === "QUARTER") {
            if (!prev[projectId]) {
              prev[projectId] = {};
            }
            const projectIdCode = cur?.dims?.[model.dimId]?.code;

            if (projectIdCode === "0") {
              prev[projectId] = {
                indicatorVals: Object.entries(cur?.indicatorVals || {}).reduce(
                  (acc, [key, value]) =>
                    key === model.indexes.status
                      ? { ...acc, [key]: value }
                      : acc,
                  {}
                ),
              };
            }
          }

          return prev;
        }, {} as any);

        state.sumProjectsByFo = action.payload.reduce((prev, cur) => {
          // Периоды с 3 - квартальные для фактов, 120240000, 120300000
          const period = cur?.code;
          if (!prev[period]) {
            prev[period] = {};
          }
          const { code: regionId } = cur.dims[model.dataRegionId];
          // Проверка на 760 и из него берем regionId
          if (regionId) {
            if (!prev[period][regionId]) {
              prev[period][regionId] = {};
            }
            // Берем из 767 code проекта
            const { code: projectId } = cur?.dims?.[model.dataProjectId];

            if (!prev[period][regionId][projectId]) {
              prev[period][regionId][projectId] = {};
            }
            // Берем из 1129 код показателя 107, 108 ...
            const indicatorId = cur.dims[model.dimId].code;

            prev[period][regionId][projectId][indicatorId] = {
              ...prepareAttributes<IPreparedIndicatorAttributeVals>(
                cur.dims[model.dimId].attributeVals
              ),
              attributeVals: cur?.attributeVals || {},
              indicatorVals: Object.fromEntries(
                Object.entries(cur?.indicatorVals || {}).map(([key, value]) => [
                  key,
                  value?.sum,
                ])
              ) as any,
            };

            if (projectId === "0") {
              // Из 760 берем данные для субъекта
              const regionIdDimId = (cur?.dims[model.dataRegionId] as any)
                ?.dimId as string;
              prev[period][regionId][projectId][regionIdDimId] = {
                ...prepareAttributes<IPreparedIndicatorAttributeVals>(
                  cur.dims[model.dataRegionId].attributeVals
                ),
                attributeVals: cur?.attributeVals || {},
              };
            } else {
              // Из 767 берем данные для проекта данные наименования и статуса
              const projectIdDimId = (cur?.dims?.[model.dataProjectId] as any)
                ?.dimId as string;
              // Статус берется только из кварталов
              const status = dataByStatus?.[projectId];
              prev[period][regionId][projectId][projectIdDimId] = {
                ...prepareAttributes<IPreparedIndicatorAttributeVals>(
                  cur.dims[model.dataProjectId].attributeVals
                ),
                attributeVals: cur?.attributeVals || {},
                indicatorVals: Object.fromEntries(
                  Object.entries({
                    ...(cur?.indicatorVals || {}),
                    ...(status?.indicatorVals || {}),
                  } as IOlapResponseDimensionItemDimWithVals).map(
                    ([key, value]) => [key, value?.sum]
                  )
                ) as any,
              };
            }
          }
          return prev;
        }, {} as any);

        state.fetching = false;
        state.error = null;
      })
      .addCase(extraActionsSumFoProjects.get.rejected, (state, action) => {
        state.fetching = false;
        state.error = action.error;
      });
  },
});

export const reducer = sumFoProjectsSlice.reducer;

export default sumFoProjectsSlice;
