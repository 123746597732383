import { Table, TableBody, TableHead } from "@mui/material";
import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { TableCellStyled } from "@/features/RegionProjectsWidget/ui/RegionProjectsWidget.styled";
import { BlockWpapper } from "@/shared/BlockWrapper/BlockWrapper";

import { columns, rowsNamePassport } from "../../lib/constants";
import { IndicatorPassportContentProps, Rows } from "../../lib/types";
import { IndicatorDescriptionButton } from "./IndicatorDescriptionButton";
import {
  RowButton,
  TableCellBody,
  TableRowBody,
  TableRowHeader,
} from "./IndicatorPassportContent.styled";

export const IndicatorPassportContent = ({
  indicatorResult,
}: IndicatorPassportContentProps) => {
  const [showDescription, setShowDescription] = useState<boolean>(true);
  const [showButton, setShowButton] = useState<boolean>(false);
  const heightRef = useRef<HTMLDivElement>(null);

  const rows: Rows[] = useMemo(() => {
    const dataDependingParent = {
      ...((indicatorResult?.attributeVals as unknown as Record<
        string,
        string
      >) || {}),
    };

    return [
      "description",
      "update_period",
      "update_date",
      "next_update_date",
      "NAME",
      "foiv",
      "name_resp_person",
      "phone_resp_person",
      "email_resp_person",
    ]?.map((cell, index) => {
      return {
        id: index + 1,
        cell: dataDependingParent?.[cell] || "-",
        title: rowsNamePassport?.[cell],
      };
    });
  }, [indicatorResult]);

  const checkMaxHeight = useCallback(
    (height: string) => {
      const maxHeight = 72;
      if (parseInt(height) > maxHeight) {
        setShowDescription(false);
        setShowButton(true);
      }
    },
    [setShowDescription, setShowButton]
  );

  const handleShowDescription = useCallback(() => {
    setShowDescription(!showDescription);
  }, [showDescription, setShowDescription]);

  useLayoutEffect(() => {
    if (heightRef?.current) {
      const height = getComputedStyle(
        heightRef?.current as unknown as Element
      )?.height;
      checkMaxHeight(height);
    }
  }, []);

  return (
    <>
      <BlockWpapper
        hasData={!!Object.keys(indicatorResult || {})?.length}
        noDataText="Данные по показателю отсутствуют"
      >
        <Table sx={{ height: "100%" }}>
          <TableHead>
            <TableRowHeader>
              {columns.map(({ width = "", title }) => (
                <TableCellStyled width={width}>{title}</TableCellStyled>
              ))}
            </TableRowHeader>
          </TableHead>
          <TableBody>
            {rows.map(({ id, cell, title }) => (
              <TableRowBody key={id}>
                <TableCellStyled style={{ fontSize: "1.125rem" }}>
                  {id}
                </TableCellStyled>
                <TableCellStyled
                  style={{ fontSize: "1.125rem", lineHeight: "1.25rem" }}
                >
                  {title}
                </TableCellStyled>
                {title === "Описание" ? (
                  <TableCellBody changeColor={true} show={showDescription}>
                    <span ref={heightRef}>{cell}</span>
                    {showButton && (
                      <RowButton onClick={handleShowDescription}>
                        <IndicatorDescriptionButton show={showDescription} />
                      </RowButton>
                    )}
                  </TableCellBody>
                ) : (
                  <TableCellBody changeColor={[1, 2, 3, 4].includes(id)}>
                    {cell}
                  </TableCellBody>
                )}
              </TableRowBody>
            ))}
          </TableBody>
        </Table>
      </BlockWpapper>
    </>
  );
};
