import { useCallback, useMemo } from "react";

import { useEnterprise } from "@/features/EnterpriseWidget/hooks/useEnterprise";
import { useKkt } from "@/features/KktWidget/hooks/useKkt";
import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { useBudget } from "@/shared/Budget/hooks/useBudget";
import { useSocEconomy } from "@/shared/DistrictValues/hooks/useSocEconomy";
import { useGeneralIndicators } from "@/shared/GeneralIndicators/hooks/useGeneralIndicators";
import { useKpi } from "@/shared/GeneralIndicators/hooks/useKpi";
import { REGION_COUNTRY_CODE } from "@/shared/lib/constants";
import { useAppDispatch, useAppSelector } from "@/store/redux/hooks";

import {
  indicatorByOpenPassportForKpi,
  indicatorsByOpenPassport,
  indicatorsByOpenPassportForBudget,
  indicatorsForPopUpId,
} from "../lib/constants";
import { indicatorsEnterprises } from "../lib/constants";
import { IIndicatorPassportProps } from "../lib/types";
import { setPassport } from "../model/passportSlice";

export const useIndicatorPassport = () => {
  const { isCheckRfFoPage } = useRegions();
  const { kktPasport } = useKkt();
  const { genIndicatorPassport, investmentRating } = useGeneralIndicators();
  const { data: dataKpi } = useKpi();
  const { data: dataSocEconomy } = useSocEconomy();
  const { enterprise } = useEnterprise();
  const { indicatorPassport } = useAppSelector(
    (state) => state.regions.passport
  );
  const { regionBudgetPassport } = useBudget();
  const regionId = useAppSelector((state) => state.controls.regions.id);
  const dispatch = useAppDispatch();

  const allGeneralIndicators = useMemo(() => {
    const allInvestmentRating = isCheckRfFoPage.isAll ? investmentRating : {};
    return { ...genIndicatorPassport, ...allInvestmentRating };
  }, [genIndicatorPassport, investmentRating, isCheckRfFoPage.isAll]);

  const getIndicatorDataByIndicatorId = useCallback(
    (indicatorId: string) => {
      let data: Record<string, any>;
      switch (true) {
        case ["110"].includes(indicatorId):
          data = { attributeVals: { ...(kktPasport || {}) } };
          break;
        case indicatorsByOpenPassport.includes(indicatorId):
          data = { ...(allGeneralIndicators?.[indicatorId] || {}) };
          break;
        case [
          ...Object.values(indicatorsForPopUpId),
          ...indicatorByOpenPassportForKpi,
        ].includes(indicatorId):
          const kpiToIndicatorId =
            dataKpi?.[indicatorId]?.[regionId || REGION_COUNTRY_CODE];
          data = {
            ...(kpiToIndicatorId?.[Object.keys(kpiToIndicatorId)[0]] || {}),
          };
          break;
        case indicatorsByOpenPassportForBudget.includes(indicatorId):
          data = regionBudgetPassport[indicatorId];
          break;
        case indicatorsEnterprises.includes(indicatorId):
          data =
            enterprise?.[indicatorId]?.[regionId!!] ||
            enterprise?.[indicatorId]?.[REGION_COUNTRY_CODE] ||
            {};
          break;
        default:
          const getRegionId =
            regionId && regionId !== REGION_COUNTRY_CODE
              ? regionId
              : Object.keys(dataSocEconomy || {})?.[0];
          data = dataSocEconomy?.[getRegionId]?.[indicatorId];
          break;
      }
      return data;
    },
    [
      dataKpi,
      dataSocEconomy,
      kktPasport,
      allGeneralIndicators,
      regionId,
      enterprise,
    ]
  );

  const setIndicatorPassport = useCallback(
    ({ indicatorId, data, open }: IIndicatorPassportProps) => {
      dispatch(
        setPassport({
          indicatorId,
          data,
          open,
        })
      );
    },
    [dispatch]
  );

  const openIndicatorPassport = useCallback(
    (indicatorId: string) => {
      const data = getIndicatorDataByIndicatorId(indicatorId);
      setIndicatorPassport({ indicatorId, data, open: true });
    },
    [getIndicatorDataByIndicatorId, setIndicatorPassport]
  );

  return {
    indicatorPassport,
    setIndicatorPassport,
    openIndicatorPassport,
  };
};
