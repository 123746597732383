import { Typography } from "@mui/material";
import { styled } from "@mui/material";

import { colorBasedOnValue } from "@/pages/MainPage/lib/helpers";

export const GridContainer = styled("div")<{ active: boolean }>(
  ({ theme, active }) => ({
    flex: "0 0 12.5rem",
    display: "grid",
    gridTemplateColumns: active
      ? `38.75rem repeat(4 , 1fr)`
      : `repeat(5 , 1fr)`,
    gap: theme.spacing(2),
    maxWidth: "100%",
    maxHeight: "100%",
    "@media (max-width: 112.5rem)": {
      gridTemplateColumns: active
        ? `36.875rem repeat(4 , 1fr)`
        : `repeat(5 , 1fr)`,
    },
  })
);

export const MainTabsWrapper = styled("div")(({ theme }) => ({
  flex: 1,
  display: "flex",
  gap: theme.spacing(2),
  "@media (max-width: 112.5rem)": {
    gap: "0.625rem",
  },
}));

export const Wrapper = styled("div")<{ selected: boolean }>(
  ({ theme, selected }) => ({
    height: "12.5rem",
    boxSizing: "border-box",
    cursor: "pointer",
    padding: theme.spacing(2, 3),
    position: "relative",
    display: "flex",
    borderRadius: theme.spacing(2),
    background: selected
      ? theme.palette.indicatorCard.backgroundColorSelected
      : theme.palette.indicatorCard.backgroundColor,
    border: `2px solid ${
      theme.palette.border[selected ? "selected" : "default"]
    }`,
    "& > .hoverIncident": {
      display: "none",
    },
    "&:hover > .hoverIncident": {
      display: "block",
    },
  })
);

export const ValueColor = styled("span")<{
  valueColor?: number | null;
  isHighLight?: boolean;
  selected?: boolean;
}>(({ theme, valueColor = null, isHighLight, selected }) => ({
  color: colorBasedOnValue(theme, valueColor, selected),
  ...(isHighLight && {
    textShadow: `0px 0px 7px ${valueColor === 3 ? "#ff5368cf" : "#06ceb6cf"}`,
  }),
}));

export const GridCardStyled = styled("div")(({ theme }) => ({
  gap: theme.spacing(2),
  position: "relative",
}));

export const CardTitleStyled = styled(Typography)<{
  isHighLight?: boolean;
  selected?: boolean;
}>(({ theme, isHighLight, selected }) => ({
  fontSize: theme.spacing(2.25),
  color: selected
    ? theme.palette.text.onAccent
    : theme.palette.tabs.textColorSelect,
  ...(isHighLight && {
    textShadow: "0px 0px 4px #ffffff70",
  }),
  "@media (max-width: 112.5rem)": {
    fontSize: theme.spacing(2.075),
  },
}));

export const CardContentStyled = styled("div")(({ theme }) => ({
  flex: 1,
  display: "flex",
  alignItems: "center",
}));

export const DiagramStyled = styled("div")(({ theme }) => ({
  width: theme.spacing(9),
  height: theme.spacing(9),
}));

export const CardDate = styled("span")<{ selected?: boolean }>(
  ({ theme, selected }) => ({
    position: "absolute",
    bottom: 8,
    right: 14,
    whiteSpace: "nowrap",
    fontSize: 12,
    color: selected ? theme.palette.text.lightGrey : theme.palette.tabs.dateTab,
  })
);
interface ICardSubTitleProps {
  isHighLight?: boolean;
  selected?: boolean;
}
export const CardSubTitle = styled(Typography)<ICardSubTitleProps>(
  ({ theme, isHighLight, selected }) => ({
    display: "inline-block",
    marginRight: theme.spacing(0.5),
    color: selected
      ? theme.palette.text.unitTextSelected
      : theme.palette.text.tertiary,
    ...(isHighLight && {
      textShadow: "0px 0px 0.25rem #ffffff70",
    }),
  })
);
