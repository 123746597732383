import { useLocation } from "react-router";

import { useRouter } from "@/app/hooks/useRouter";
import { useRisksTransition } from "@/pages/MainPage/hooks/useRisksTransition";
import { TAB_ID } from "@/store/scheme/region";

import { ACHIEVEMENT_VALUE } from "../lib/types";

export const useAchievementControl = () => {
  const location = useLocation();
  const { pathname } = location;
  const { navigate } = useRouter();
  const { getPath } = useRisksTransition();

  const getRegionPath = (pathname: string, activeTab: TAB_ID) => {
    const shatterPath = pathname?.split("/");
    const lastShatterPathWord =
      !!shatterPath[shatterPath.length - 1]?.match(/^[a-zA-Z]+$/gim);
    if (lastShatterPathWord) {
      shatterPath[shatterPath.length - 1] = activeTab;
    } else {
      shatterPath.push(activeTab);
    }
    return shatterPath.join("/");
  };

  const navigateToPage = (value: ACHIEVEMENT_VALUE, activeTab: TAB_ID) => {
    const isTablePageAndAchieve =
      pathname?.startsWith("/achievementLevel") &&
      value === ACHIEVEMENT_VALUE.ACHIEVEMENT;
    const isRegionPage =
      pathname?.startsWith("/region") && !pathname?.endsWith(activeTab);

    if (isTablePageAndAchieve || isRegionPage) {
      let path = "";
      switch (true) {
        case isTablePageAndAchieve:
          path = getPath();
          break;
        case isRegionPage:
          path = getRegionPath(pathname, activeTab);
          break;
      }

      navigate(path, {
        state: {
          ...location?.state,
        },
      });
    }
    return;
  };

  return { navigateToPage };
};
