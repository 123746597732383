import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { IPeriodAttributeVals, MODEL_IDS, MODELS } from "@/store/scheme/olap";

import {
  IRiskByProject,
  IRiskProjectReference,
  IRiskProjectState,
} from "../../lib/types";
import { getRiskProject, getRiskReference } from "../api/risk_project_api";

export const initialState: IRiskProjectState = {
  riskByProject: undefined,
  riskProjectReference: undefined,
  riskProjectFetching: false,
  riskReferenceFetching: false,
  riskProjectError: null,
  riskReferenceError: null,
};

export const extraActionsRiskProject = {
  getRiskProject: createAsyncThunk(
    "risk_project",
    async ({
      projectId,
      dataSummaryId,
    }: {
      projectId: string;
      dataSummaryId?: string;
    }) => await getRiskProject({ projectId, dataSummaryId })
  ),
  getRiskReference: createAsyncThunk(
    "risk_project/reference",
    async () => await getRiskReference()
  ),
};

const riskProjectSlice = createSlice({
  name: "risk_project",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActionsRiskProject.getRiskProject.pending, (state) => {
        state.riskByProject = undefined;
        state.riskProjectFetching = true;
      })
      .addCase(extraActionsRiskProject.getRiskReference.pending, (state) => {
        state.riskProjectReference = undefined;
        state.riskReferenceFetching = true;
      })
      .addCase(
        extraActionsRiskProject.getRiskProject.fulfilled,
        (state, action) => {
          const model = MODELS[MODEL_IDS.RISKS];
          state.riskByProject = action.payload.reduce((prev, cur) => {
            if (!prev) prev = {};
            if (cur?.dims?.[model.dataResultId]) {
              if (!prev["levelRisk"]) prev["levelRisk"] = {};
              prev["levelRisk"] = {
                level:
                  cur?.dims?.[model.dataResultId]?.indicatorVals?.[
                    model.indexes.status
                  ]?.sum,
                color:
                  cur?.dims?.[model.dataResultId]?.indicatorVals?.[
                    model.indexes.color
                  ]?.sum,
                date: (cur?.attributeVals as IPeriodAttributeVals)?.[
                  "REPORT_DATE"
                ],
              };
            }
            return prev;
          }, {} as IRiskByProject);
          state.riskProjectFetching = false;
          state.riskProjectError = null;
        }
      )
      .addCase(
        extraActionsRiskProject.getRiskReference.fulfilled,
        (state, action) => {
          state.riskProjectReference = action.payload.reduce((prev, cur) => {
            if (!prev) prev = {};
            const curData = cur.attributeVals as any;
            const projectId = curData?.["project_id"];
            if (projectId) {
              if (!prev[projectId]) prev[projectId] = {};
              if (!prev[projectId][cur.id]) prev[projectId][cur.id] = {};
              prev[projectId][cur.id] = {
                id: cur.id,
                problem: curData?.group,
                description: curData?.description,
                responsible: curData?.foiv,
                solution: curData?.solving,
              };
            }
            return prev;
          }, {} as IRiskProjectReference);
          state.riskReferenceFetching = false;
          state.riskReferenceError = null;
        }
      )
      .addCase(
        extraActionsRiskProject.getRiskProject.rejected,
        (state, action) => {
          state.riskProjectFetching = false;
          state.riskProjectError = action.error;
        }
      )
      .addCase(
        extraActionsRiskProject.getRiskReference.rejected,
        (state, action) => {
          state.riskReferenceFetching = false;
          state.riskReferenceError = action.error;
        }
      );
  },
});

export const reducer = riskProjectSlice.reducer;

export default riskProjectSlice;
