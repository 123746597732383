import { styled } from "@mui/material";
import TableRow from "@mui/material/TableRow/TableRow";

export const TableRowStyled = styled(TableRow, {
  shouldForwardProp: (prop) => !["focused"].includes(prop.toString()),
})<{ focused?: boolean }>(({ theme, focused }) => ({
  cursor: "pointer",
  ".MuiTableCell-body": {
    borderTop: `1px solid transparent`,
    borderBottom: `1px solid ${theme.palette.table.dividerLight}`,
    borderRight: `1px solid ${theme.palette.table.dividerLight}`,
    fontWeight: 600,
  },
  ".MuiTableCell-body:first-of-type": {
    borderLeft: `1px solid ${
      theme.palette.mode === "dark"
        ? "transparent"
        : theme.palette.button.border
    }`,
  },
  ".MuiTableCell-body:last-child": {
    borderRight: `1px solid ${
      theme.palette.mode === "dark"
        ? "transparent"
        : theme.palette.button.border
    }`,
  },
  "&:hover": {
    ".MuiTableCell-body": {
      boxShadow: `-6.25rem -6.25rem 6.25rem 6.25rem ${theme.palette.background.rowHover} inset`,
      borderTop: `1px solid ${theme.palette.table.rowBorder}`,
      borderBottom: `1px solid ${theme.palette.table.rowBorder}`,
    },
    ".MuiTableCell-body:first-of-type": {
      borderRadius: theme.spacing(1, 0, 0, 1),
      borderLeft: `1px solid ${theme.palette.table.rowBorder}`,
    },
    ".MuiTableCell-body:last-child": {
      borderRadius: theme.spacing(0, 1, 1, 0),
      borderRight: `1px solid ${theme.palette.table.rowBorder}`,
    },
  },

  ...(focused && {
    ".MuiTableCell-body": {
      boxShadow: `-6.25rem -6.25rem 6.25rem 6.25rem ${theme.palette.background.rowHover} inset`,
      borderTop: `1px solid ${theme.palette.table.rowBorder}`,
      borderBottom: `1px solid ${theme.palette.table.rowBorder}`,
    },
    ".MuiTableCell-body:first-of-type": {
      borderRadius: theme.spacing(1, 0, 0, 1),
      borderLeft: `1px solid ${theme.palette.table.rowBorder}`,
      borderRight: `1px solid ${theme.palette.table.dividerLight}`,
    },
    ".MuiTableCell-body:last-child": {
      borderRadius: theme.spacing(0, 1, 1, 0),
      borderRight: `1px solid ${theme.palette.table.rowBorder}`,
    },
  }),
}));
