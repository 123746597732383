import { useCallback } from "react";

import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { useAppDispatch, useAppSelector } from "@/store/redux/hooks";

import { extraActionsProjects } from "../model/slice/projectsSlice";

// Данные используются в ProjectRow, AppBarProjectsFilter
export const useProjects = () => {
  const dispatch = useAppDispatch();
  const {
    data,
    filtered,
    allProjects,
    isLoadAllProjects,
    projectsDirectory,
    projectsDirectoryFetching,
    projectsDirectoryByRegion,
    projectsDirectoryError,
  } = useAppSelector((state) => state.regions.projects);
  const { regionIds } = useRegions();

  const requestProjectsSum = useCallback(
    (regionId: string, dataSummaryId: string) => {
      if (regionId) {
        dispatch(extraActionsProjects.getSum({ regionId, dataSummaryId }));
      }
    },
    [dispatch]
  );

  const requestProjectsDirectory = useCallback(() => {
    dispatch(extraActionsProjects.getProjectsDirectory());
  }, [dispatch]);

  const requestAllProjects = useCallback(() => {
    if (regionIds.length > 0 && !isLoadAllProjects) {
      dispatch(extraActionsProjects.getAll(regionIds));
    }
  }, [dispatch, isLoadAllProjects, regionIds]);

  return {
    data,
    allProjects,
    filtered,
    requestProjectsSum,
    requestAllProjects,
    requestProjectsDirectory,
    projectsDirectory,
    projectsDirectoryFetching,
    projectsDirectoryByRegion,
    projectsDirectoryError,
  };
};
