import { IIconsByValueHl } from "./types";

export const getIconsByValueHL = ({
  value,
  icons,
  isBig = false,
}: IIconsByValueHl) => {
  switch (value) {
    case 1:
    case 2:
      return <icons.high contrast={value === 2} isBig={isBig} />;
    case -1:
    case -2:
      return <icons.low contrast={value === -2} isBig={isBig} />;
    default:
      return null;
  }
};
