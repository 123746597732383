import {
  InputBase,
  ListItemText,
  MenuItem,
  Stack,
  styled,
} from "@mui/material";

export const BootstrapInput = styled(InputBase)(({ theme }) => {
  return {
    "&.MuiInputBase-root > .MuiModal-root > .MuiPaper-root": {
      maxWidth: 400,
      background: theme.palette.menu.itemBackground,
      border: `1px solid ${theme.palette.menu.itemBackground}`,
      padding: theme.spacing(0, 2),
    },
    height: "100%",
    maxHeight: theme.spacing(5),
    minWidth: theme.spacing(35.5),
    alignItems: "stretch",
    "label + &": {
      marginTop: theme.spacing(3),
    },

    "& > .MuiSvgIcon-root": {
      color: theme.palette.text.tertiary,
    },
    "& .MuiInputBase-input": {
      borderRadius: theme.spacing(),
      position: "relative",
      backgroundColor: theme.palette.button.background,
      border: `1px solid ${theme.palette.table.divider}`,
      fontSize: 14,
      padding: theme.spacing(),
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      color: theme.palette.text.tertiary,
      fontWeight: theme.typography.fontWeightSemiBold,
    },
  };
});

export const WrapperStack = styled(Stack)(({ theme }) => ({
  maxWidth: theme.spacing(37.5),
  minWidth: theme.spacing(27),
  overflow: "hidden",
  alignSelf: "stretch",
  "& .MuiFormControl-root": {
    alignSelf: "stretch",
  },
}));

export const ListTextStyled = styled(ListItemText)(({ theme }) => ({
  margin: theme.spacing(0),
  color: theme.palette.text.secondary,
  "& .MuiListItemText-primary": {
    fontWeight: theme.typography.fontWeightSemiBold,
    fontSize: theme.spacing(1.75),
  },
  "& .MuiListItemText-secondary": {
    color: theme.palette.text.tertiary,
    fontWeight: theme.typography.fontWeightSemiBold,
    fontSize: theme.spacing(2),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

export const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  margin: theme.spacing(),
  padding: theme.spacing(0.75, 1.5),
  borderRadius: theme.spacing(0.5),
  "&.Mui-selected": {
    background: theme.palette.dropdown.background,
    borderRadius: theme.spacing(0.5),
    color: theme.palette.primary.contrastText,
  },
  "&:hover": {
    background: theme.palette.menu.itemBackgroundHover,
  },
}));
