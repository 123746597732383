import { FormGroup } from "@mui/material";
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { CustomScrollbar } from "@/shared/CustomScrollbar/ui/CustomScrollbar";
import { CustomScrollbarWrapper } from "@/shared/CustomScrollbar/ui/CustomScrollbar.styled";
import { ModalWrapper } from "@/shared/Modal/Modal";

import { useColumns } from "../../hooks/useColumns";
import { useFuturePlan } from "../../hooks/useFuturePlan";
import { ICheckedScheme, IHeaderColumns, IPopUpProps } from "../../lib/types";
import { FuturePlanCheckBox } from "../FuturePlanCheckBox/FuturePlanCheckBox";
import {
  Actions,
  ButtonAction,
  CloseButton,
  FormBody,
  FormHeader,
} from "./PopUp.styled";

export const PopUp: FC<IPopUpProps> = ({
  initialHeaderCols,
  currentHeaderCols,
  onSubmit,
  openPopUp,
  onClosePopUp,
}) => {
  const { isCheckFuturePlan, isFuturePlanLabel } = useFuturePlan();
  const { copyObject, collectionCheckBoxes, getInitialHeaderColsScheme } =
    useColumns();
  const [checkedScheme, setCheckedScheme] = useState<ICheckedScheme>({});

  const initialHeaderColsScheme = useMemo(() => {
    return getInitialHeaderColsScheme(initialHeaderCols);
  }, [initialHeaderCols]);

  useEffect(() => {
    let storagePopUpLabel = [] as string[];
    const scheme = copyObject(initialHeaderColsScheme);
    initialHeaderCols.forEach(({ id, label }) => {
      if (id === "header") scheme[label].quantitySubColumns = 0;
    });
    currentHeaderCols.forEach(
      ({ popUpLabel, parentHeaderLabel, id, label }) => {
        if (popUpLabel && scheme?.[popUpLabel]) {
          scheme[popUpLabel].checked = true;
        }
        if (
          parentHeaderLabel &&
          popUpLabel &&
          scheme?.[popUpLabel]?.checked &&
          !storagePopUpLabel.includes(popUpLabel)
        ) {
          scheme[parentHeaderLabel].quantitySubColumns += 1;
        }
        storagePopUpLabel.push(popUpLabel!!);
      }
    );
    setCheckedScheme(scheme);
  }, [currentHeaderCols, initialHeaderCols, initialHeaderColsScheme]);

  const handleChangeCheckBox = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const scheme = copyObject(checkedScheme);
      const checkedStatus = scheme[event.target.id].checked;
      const { id: item, name: parentLabel } = event.target;
      scheme[item].checked = !checkedStatus;

      if (parentLabel) {
        const quantitySubColumns = scheme[event.target.name].quantitySubColumns;
        scheme[event.target.name].quantitySubColumns = checkedStatus
          ? quantitySubColumns - 1
          : quantitySubColumns + 1;
      }
      setCheckedScheme(scheme);
    },
    [checkedScheme, copyObject]
  );

  const handleClickPickAll = useCallback(
    (pickAll: boolean) => {
      const scheme = copyObject(checkedScheme);
      initialHeaderCols
        .filter(
          ({ defaultColumn, id }) =>
            (defaultColumn && id === "header") || !defaultColumn
        )
        .forEach(({ popUpLabel, id, label }) => {
          if (popUpLabel) scheme[popUpLabel].checked = pickAll;

          if (pickAll && id === "header") {
            scheme[label].quantitySubColumns =
              initialHeaderColsScheme[label].quantitySubColumns;
          }

          if (!pickAll && id === "header") {
            scheme[label].quantitySubColumns =
              initialHeaderColsScheme[label]?.defaultQuantity || 0;
          }
        });
      setCheckedScheme(scheme);
    },
    [checkedScheme, copyObject, initialHeaderCols, initialHeaderColsScheme]
  );

  const handlePlanCheckBox = useCallback((scheme: ICheckedScheme) => {
    setCheckedScheme(scheme);
  }, []);

  const isDisableSubmit = useMemo(() => {
    if (checkedScheme) {
      return !initialHeaderCols.some(({ label, popUpLabel }) => {
        return checkedScheme?.[popUpLabel || label]?.checked;
      });
    }
  }, [checkedScheme, initialHeaderCols]);

  const handleSubmit = useCallback(() => {
    const result = initialHeaderCols.reduce((prev, item) => {
      const { popUpLabel, id, label } = item;
      if (popUpLabel && checkedScheme![popUpLabel].checked) {
        prev.push(item);
      }

      if (id === "header" && checkedScheme?.[label]?.quantitySubColumns) {
        prev.push(item);
      }

      return prev;
    }, [] as IHeaderColumns[]);
    onSubmit(result);
    onClosePopUp(!openPopUp);
  }, [checkedScheme, initialHeaderCols, onClosePopUp, onSubmit, openPopUp]);

  const columns = useMemo(
    () => [
      ...initialHeaderCols.filter((column) => column.defaultColumn),
      ...initialHeaderCols.filter((column) => !column.defaultColumn),
    ],
    [initialHeaderCols]
  );

  const isSomeFuturePlan = useMemo(
    () => isFuturePlanLabel(initialHeaderCols),
    [initialHeaderCols]
  );

  const createCheckBoxes = useMemo(() => {
    return collectionCheckBoxes(columns, checkedScheme, handleChangeCheckBox);
  }, [columns, checkedScheme]);

  return (
    <ModalWrapper
      open={openPopUp}
      onClose={() => onClosePopUp(!openPopUp)}
      width={125}
      height={111}
      children={
        <>
          <FormHeader>
            <span>Столбцы</span>
            {isCheckFuturePlan && isSomeFuturePlan && (
              <FuturePlanCheckBox
                activeColumns={checkedScheme}
                handlePlanCheckBox={handlePlanCheckBox}
              />
            )}
            <Actions>
              <ButtonAction onClick={() => handleClickPickAll(true)}>
                Выбрать все
              </ButtonAction>
              <ButtonAction onClick={() => handleClickPickAll(false)}>
                Снять все выделения
              </ButtonAction>
              <ButtonAction
                onClick={handleSubmit}
                variant="contained"
                disabled={isDisableSubmit}
                sx={{ color: "#ffffff" }}
              >
                Применить
              </ButtonAction>
              <CloseButton onClick={() => onClosePopUp(!openPopUp)} />
            </Actions>
          </FormHeader>

          <CustomScrollbarWrapper>
            <CustomScrollbar>
              <FormBody>
                {checkedScheme && <FormGroup>{createCheckBoxes}</FormGroup>}
              </FormBody>
            </CustomScrollbar>
          </CustomScrollbarWrapper>
        </>
      }
    />
  );
};
