export const generalIndicatorsValues = [
  "11",
  "13",
  "25",
  "26",
  "27",
  "28",
  "29",
  "31",
  "32",
  "6",
  "5",
  "132",
  "7",
  "43",
  "44",
  "45",
  "54",
  "55",
  "117",
  "118",
  "76",
  "77",
  "78",
  "79",
];

export const kpiValues = [
  "1",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "50",
  "51",
  "52",
  "53",
  "46",
  "47",
  "48",
  "49",
  "56",
  "57",
  "58",
  "59",
  "60",
  "61",
  "62",
  "63",
  "64",
  "82",
  "84",
  "85",
  "86",
  "87",
  "88",
  "89",
  "90",
  "92",
  "93",
];

export const indicatorsTitle: Record<string, string> = {
  title: "Общие показатели",
  subTitle: "субъекта РФ",
};
