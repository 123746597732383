import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { prepareAttributes } from "@/shared/lib/heplers";
import { IRegionsState } from "@/shared/lib/types";
import {
  IPeriodAttributeVals,
  IPreparedIndicatorAttributeVals,
  MODEL_IDS,
  MODELS,
} from "@/store/scheme/olap";

import { IIndicatorGraph } from "../../lib/types";
import { getGraphBudget } from "../api/budget_graph_api";

export const initialState: IRegionsState = {
  data: undefined,
  fetching: false,
  error: null,
};

export const extraActionsBudgetGraph = {
  get: createAsyncThunk(
    "regions/budgetGpaph/get",
    async (args: IIndicatorGraph) => await getGraphBudget(args)
  ),
};

const budgetGraphSlice = createSlice({
  name: "budgetGraph",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActionsBudgetGraph.get.pending, (state) => {
        state.data = undefined;
        state.fetching = true;
      })
      .addCase(extraActionsBudgetGraph.get.fulfilled, (state, action) => {
        const model = MODELS[MODEL_IDS.BUDGET];

        state.data = action.payload.reduce((prev, cur) => {
          const indicatorId = cur?.dims?.[model.dimId]?.code;
          const subjectId = cur?.dims?.[model.dataRegionId]?.code;
          const periodId = cur?.code;

          if (indicatorId) {
            if (!prev[indicatorId]) {
              prev[indicatorId] = {};
            }
            if (!prev[indicatorId][subjectId]) {
              prev[indicatorId][subjectId] = {};
            }
            prev[indicatorId][subjectId][periodId] = {
              ...prepareAttributes<IPreparedIndicatorAttributeVals>({
                ...cur?.dims?.[model.dataRegionId]?.attributeVals,
                REPORT_DATE: (cur?.attributeVals as IPeriodAttributeVals)
                  ?.REPORT_DATE,
                UNIT: (
                  cur?.dims?.[model.dimId]
                    ?.attributeVals as IPeriodAttributeVals
                ).unit_measure,
              }),
              periodId,
              attributeVals: cur?.attributeVals || {},
              indicatorVals: Object.fromEntries(
                Object.entries(cur?.indicatorVals || {}).map(([key, value]) => [
                  key,
                  value?.sum,
                ])
              ) as any,
            };
          }

          return prev;
        }, {} as any);

        state.fetching = false;
        state.error = null;
      })
      .addCase(extraActionsBudgetGraph.get.rejected, (state, action) => {
        state.fetching = false;
        state.error = action.error;
      });
  },
});

export const reducer = budgetGraphSlice.reducer;

export default budgetGraphSlice;
