import React, { FC } from "react";

import NCImage from "@/assets/national_goals.png";

import { nationalGoalsLink } from "../../lib/constants";
import { INCLinkProps } from "../../lib/types";
import { NCLinkStyled } from "../VdlLeader.styled";

export const NationalGoalsLink: FC<INCLinkProps> = ({
  dateStart = "",
  datePart = "",
  name = "",
  codeFns = "",
}) => (
  <NCLinkStyled
    href={`${nationalGoalsLink}?year%5B0%5D=${dateStart}&prognoz%5B0%5D=0&text_param=${datePart}&karta_name%5B0%5D=${name}&karta_code%5B0%5D=${codeFns}&subject_name%5B0%5D=${name}&disableSelect=1&hideHeader=1&disableEdit=1`}
    target="_blank"
    rel="noreferrer"
  >
    <img src={NCImage} alt="Национальные цели развития" />
  </NCLinkStyled>
);
