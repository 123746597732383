import { IconButton, styled } from "@mui/material";

export const AppBarUserMenuWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

export const LogoutButton = styled(IconButton)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  color: theme.palette.appBar.color,
  gap: theme.spacing(2),
  opacity: theme.palette.mode === "dark" ? 0.7 : 1,
}));

export const AppBarUserName = styled("p")(({ theme }) => ({
  color: theme.palette.appBar.color,
  ...(theme.palette.mode === "dark" && {
    opacity: 0.5,
    fontSize: theme.spacing(1.75),
  }),
}));
