import { useSumFoProjects } from "../../hooks/useSumFoProjects";
import { IRegionFoProjectsProps } from "../../lib/types";
import { RegionRow } from "../RegionRow/RegionRow";

export const RegionFoProjects = ({
  periodId,
  columns,
  isOpenPopUp,
}: IRegionFoProjectsProps) => {
  const { rows } = useSumFoProjects(periodId);

  return (
    <>
      {rows.map((row) => (
        <RegionRow
          isOpenPopUp={isOpenPopUp}
          row={row}
          key={row.id}
          columns={columns}
        />
      ))}
    </>
  );
};
