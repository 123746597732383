import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { prepareAttributes } from "@/shared/lib/heplers";
import { IRegionsState } from "@/shared/lib/types";
import {
  IPreparedIndicatorAttributeVals,
  MODEL_IDS,
  MODELS,
} from "@/store/scheme/olap";

import { getVDL } from "./api";

export const initialState: IRegionsState = {
  data: undefined,
  fetching: false,
  error: null,
};

export const extraActionsVdl = {
  get: createAsyncThunk(
    "regions/VDL/get",
    async (regionId: string) => await getVDL(regionId)
  ),
};

const vdlSlice = createSlice({
  name: "vdl",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActionsVdl.get.pending, (state) => {
        state.data = undefined;
        state.fetching = true;
      })
      .addCase(extraActionsVdl.get.fulfilled, (state, action) => {
        const model = MODELS[MODEL_IDS.VDL];

        state.data = action.payload.reduce((prev, cur) => {
          if (cur?.dims?.[model.dimId]?.code) {
            prev[cur.dims[model.dimId].code] = {
              ...prepareAttributes<IPreparedIndicatorAttributeVals>(
                cur.dims[model.dimId].attributeVals
              ),
              attributeVals: cur?.attributeVals || {},
              indicatorVals: Object.fromEntries(
                Object.entries(cur?.indicatorVals || {}).map(([key, value]) => [
                  key,
                  value?.sum,
                ])
              ) as any,
            };
          }

          const otherParams = [
            model.dataRegionId,
            model.dataRegionLeader,
            model.dataActualId,
          ].reduce((acc, current) => {
            acc[current] = prepareAttributes<IPreparedIndicatorAttributeVals>(
              cur.dims[current].attributeVals
            );
            return acc;
          }, {} as any);

          return { ...prev, ...otherParams };
        }, {} as any);

        state.fetching = false;
        state.error = null;
      })
      .addCase(extraActionsVdl.get.rejected, (state, action) => {
        state.fetching = false;
        state.error = action.error;
      });
  },
});

export const reducer = vdlSlice.reducer;

export default vdlSlice;
