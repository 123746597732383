import { ReactNode } from "react";

import { ISumIndicator } from "@/pages/RegionPage/lib/types";
import { DotMarkFail, DotMarkSuccess } from "@/shared/Dots/Dots.styled";
import { formatNumber } from "@/shared/lib/heplers";
import {
  PROJECT_INDICATORS,
  PROJECT_STATUS,
} from "@/shared/Table/model/api/scheme";
import { IModelProjectPassport } from "@/store/scheme/olap";

import { projectIndicators } from "./constants";

export const getColorStatusProject = (
  status?: number,
  bigSize?: boolean
): ReactNode => {
  if ([0, 1].includes(Number(status))) {
    return Number(status) === PROJECT_STATUS.RED ? (
      <DotMarkFail sx={bigSize ? { width: 24, height: 24 } : {}} />
    ) : (
      <DotMarkSuccess sx={bigSize ? { width: 24, height: 24 } : {}} />
    );
  }
  return null;
};

export const checkArraySomeIndicators = (bunch: string[]) => {
  return bunch.some((indicator) => {
    return projectIndicators.some((value) => value === indicator);
  });
};

export const getIndicatorsByPeriod = (
  model: IModelProjectPassport,
  period: string
) => {
  return !!period?.match(/^3/gim) ? model.indexes.fact : model.indexes.plan;
};

export const defaultRows = (
  modelId: string,
  modelValue: string,
  indicator: string,
  data?: { [projectId: string]: ISumIndicator }
) => {
  return {
    name: data?.[indicator]?.[modelId]?.name || "",
    [PROJECT_INDICATORS.SALARY]: formatNumber(
      data?.[indicator]?.[PROJECT_INDICATORS.SALARY]?.indicatorVals?.[
        modelValue
      ],
      undefined,
      0
    ),
    [PROJECT_INDICATORS.PROFIT]: formatNumber(
      data?.[indicator]?.[PROJECT_INDICATORS.PROFIT]?.indicatorVals?.[
        modelValue
      ],
      undefined,
      2
    ),
    [PROJECT_INDICATORS.INVESTMENTS]: formatNumber(
      data?.[indicator]?.[PROJECT_INDICATORS.INVESTMENTS]?.indicatorVals?.[
        modelValue
      ],
      undefined,
      2
    ),
    [PROJECT_INDICATORS.PLACES]: formatNumber(
      data?.[indicator]?.[PROJECT_INDICATORS.PLACES]?.indicatorVals?.[
        modelValue
      ],
      undefined,
      0
    ),
  };
};
