import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ISubjectId, ISubjectIdState } from "../../lib/types";

export const initialState: ISubjectIdState = {
  subjectId: {},
};

const subjectIdSlice = createSlice({
  name: "subject",
  initialState,
  reducers: {
    setSubjectId: (state, action: PayloadAction<ISubjectId>) => {
      state.subjectId = action.payload;
    },
  },
});

export const { setSubjectId } = subjectIdSlice.actions;

export const reducer = subjectIdSlice.reducer;

export default subjectIdSlice;
