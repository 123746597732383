import { MODEL_IDS, MODELS } from "@store/scheme/olap";
import React, { FC, useMemo } from "react";

import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { useGeneralIndicators } from "@/shared/GeneralIndicators/hooks/useGeneralIndicators";
import { IValue } from "@/shared/GeneralIndicators/lib/types";
import { GeneralIndicators } from "@/shared/GeneralIndicators/ui/GeneralIndicators";
import { formatNumber } from "@/shared/lib/heplers";
import { IPopUpProps, REGION_TABLES } from "@/shared/RegionPopUp/lib/types";

export const GeneralIndicatorsProfit: FC<IPopUpProps> = ({ isOpenPopUp }) => {
  const model = MODELS[MODEL_IDS.KPI_SOC_ECONOMY];
  const { data, indicators, generalIndicatorsTitle, fetching } =
    useGeneralIndicators();
  const mainData = ["132", "44"];
  const { isCheckRfFoPage } = useRegions();

  const values: IValue[] = useMemo(() => {
    return [...mainData, "43", "45"].map((item) => {
      const subjectData = data?.[item];
      return {
        key: item,
        title: mainData.includes(item) ? subjectData?.short_name : "",
        date: mainData.includes(item)
          ? subjectData?.attributeVals?.REPORT_DATE
          : subjectData?.attributeVals?.PREVIOUS_DATE,
        value: formatNumber(
          subjectData?.indicatorVals?.[model.indexes.fact],
          undefined,
          1
        ),
        valueHL: subjectData?.indicatorVals?.[indicators.highLow],
        valueColor: ["43", "45"].includes(item)
          ? subjectData?.indicatorVals?.[model.indexes.color]
          : undefined,
        unit: subjectData?.UNIT,
        ...(mainData.includes(item) &&
          !isCheckRfFoPage.isRF && {
            place: subjectData?.indicatorVals?.[indicators.place],
            placeChange: formatNumber(
              subjectData?.indicatorVals?.[indicators.placeChange],
              undefined,
              0,
              true
            ),
            placeChangeRate:
              subjectData?.indicatorVals?.[indicators.colorChange],
          }),
      };
    });
  }, [
    data,
    indicators.colorChange,
    indicators.highLow,
    indicators.place,
    indicators.placeChange,
    isCheckRfFoPage.isRF,
  ]);

  return (
    <GeneralIndicators
      isNotGetDate={true}
      title={generalIndicatorsTitle}
      generalFetching={fetching}
      typography="light"
      indicatorsFontStyle="italic"
      values={values}
      isOpenPopUp={isOpenPopUp}
      popUpId={REGION_TABLES.GENERAL_INDICATORS_PROFIT}
    />
  );
};
