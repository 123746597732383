import { Button, styled } from "@mui/material";

export const DefaultButtonStyled = styled(Button, {
  shouldForwardProp: (prop) =>
    !["titleText", "selected", "size"].includes(prop.toString()),
})<{
  selected?: boolean;
  size?: "small" | "large";
}>(({ theme, selected, size }) => ({
  "&.MuiButtonBase-root": {
    maxWidth: "100%",
    minWidth: theme.spacing(4),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: `${size === "small" ? 0.5 : 0.75}rem 1.5rem`,
    textTransform: "none",
    background:
      theme.palette.button[selected ? "selected" : "incidentButtonColor"],
    borderRadius: theme.spacing(1),
    color: theme.palette.text.primary,
    border: `1px solid ${theme.palette.table.backgroundFocused}`,
    cursor: "pointer",
    fontSize: theme.spacing(2),
    lineHeight: theme.spacing(3),
    ...(selected && {
      "&:hover": {
        backgroundColor: theme.palette.button.primaryHover,
      },
    }),
    "&.Mui-disabled": {
      color: theme.palette.text.disabledSelected,
      background: theme.palette.text.disabledSelected,
    },
    "& > .iconWrapper": {
      width: theme.spacing(2.5),
      height: theme.spacing(3),
      paddingRight: theme.spacing(),
      "& > .MuiSvgIcon-root": {
        fontSize: theme.spacing(2.5),
      },
    },
  },
}));
