import { Button, styled, Tooltip, TooltipProps } from "@mui/material";

export const IncidentButtonStyled = styled(Button, {
  shouldForwardProp: (prop) =>
    !["posRight", "bigSize"].includes(prop.toString()),
})<{
  posRight: number;
  bigSize: boolean;
}>(({ theme, posRight, bigSize }) => ({
  "&.MuiButtonBase-root": {
    position: "absolute",
    right: posRight,
    borderRadius: theme.spacing(1),
    width: theme.spacing(bigSize ? 5 : 4),
    minWidth: theme.spacing(bigSize ? 5 : 4),
    height: theme.spacing(bigSize ? 5 : 4),
    background:
      theme.palette.button[
        bigSize ? "incidentButtonBackground" : "incidentColor"
      ],
    ...(!bigSize && {
      opacity: 0.75,
    }),
    cursor: "pointer",
  },
  "& > svg": {
    display: "block",
    margin: "auto",
    fontSize: theme.spacing(2),
    fill: theme.palette.text.notificationText,
  },
}));

export const IncidentTooltipStyled = styled(
  ({ className, children, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} children={children} />
  )
)(({ theme }) => ({
  "& .MuiTooltip-tooltip": {
    background: theme.palette.toolTip.background,
    boxShadow: "0px 0px 0.875rem -0.75 rgba(6, 15, 35, 0.65)",
    borderRadius: theme.spacing(),
    padding: "0.75rem",
    gap: theme.spacing(0.5),
    color: theme.palette.text.light,
    fontSize: theme.spacing(2.25),
    lineHeight: theme.spacing(2.75),
  },
  "& .MuiTooltip-arrow": {
    color: theme.palette.toolTip.background,
  },
}));
