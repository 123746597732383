import { useCallback, useMemo } from "react";

import { useAppDispatch, useAppSelector } from "@/store/redux/hooks";

import { extraActionsAllVdl } from "../model/allVdlSlice";

export const useAllVDL = () => {
  const dispatch = useAppDispatch();
  const {
    data,
    error: allVdlError,
    fetching: allVdlFetching,
  } = useAppSelector((state) => state.regions.allVdl);

  const requestAllVDL = useCallback(
    () => dispatch(extraActionsAllVdl.get()),
    [dispatch]
  );

  const allSortedAllVdl: Record<string, string>[] = useMemo(() => {
    return Object.values(data || {})?.sort((a, b) =>
      +a.factValue < +b.factValue ? 1 : -1
    );
  }, [data]);

  const topAllVdl = useMemo(() => {
    return allSortedAllVdl.slice(0, 3);
  }, [allSortedAllVdl]);

  const allVdlByParentId = useCallback(
    (parentId: string) => {
      return allSortedAllVdl.filter(
        ({ parentRegionId }) => parentRegionId === parentId
      );
    },
    [allSortedAllVdl]
  );

  return {
    topAllVdl,
    allVdl: data,
    allVdlError,
    requestAllVDL,
    allSortedAllVdl,
    allVdlByParentId,
    allVdlFetching,
  };
};
