import { styled } from "@mui/material";

export const LegendWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: theme.spacing(2),
}));

export const LegendItemWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: theme.spacing(),
}));

export const LegendIcon = styled("div", {
  shouldForwardProp: (prop) =>
    !["type", "sizeHeight", "sizeWidth", "color", "isBlur"].includes(
      prop.toString()
    ),
})<{
  type: "line" | "circle";
  sizeHeight: number;
  sizeWidth: number;
  color: string;
  isBlur?: boolean;
}>(({ theme, type, sizeHeight, sizeWidth, color, isBlur }) => ({
  display: "flex",
  width: sizeWidth,
  height: sizeHeight,
  background: color,
  ...(type === "circle" && {
    borderRadius: "50%",
  }),
  ...(isBlur && { boxShadow: `0px 0px 5px ${color}` }),
}));

export const LegendTitle = styled("div")(({ theme }) => ({
  fontSize: theme.spacing(1.75),
  lineHeight: theme.spacing(2.5),
  color: theme.palette.text.primary,
}));
