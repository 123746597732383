import { useMemo } from "react";

import { useFuturePlan } from "@/shared/Table/hooks/useFuturePlan";
import { HeaderColumns } from "@/shared/Table/lib/types";

import { useAverageAchievementColumnsT } from "../lib/types";

export const useAverageAchievementColumns = ({
  modelPassport,
  projectPassport,
}: useAverageAchievementColumnsT): {
  averageAchievementColumns: HeaderColumns[];
} => {
  const { isCheckFuturePlan } = useFuturePlan();

  const averageAchievementColumns = useMemo(() => {
    return [
      {
        id: "0",
        sortId: 1,
        indicator: modelPassport.indexes.achievement,
        label: "Достижение по проектам, %",
        popUpLabel: "Достижение по проектам, %",
        sortable: true,
        model: modelPassport.modelId,
        colorValues: true,
        valuesUnit: "%",
        mainColumn: true,
        defaultColumn: true,
        data: projectPassport,
        sortBy: "AchievementProjects",
        needPrevPeriod: true,
        prevPeriod: false,
        indicatorColor: modelPassport.indexes.achievementProjectColor,
      },
      {
        id: "0",
        sortId: 2,
        indicator: modelPassport.indexes.percent,
        label: "Достижение по показателям проектов, %",
        popUpLabel: "Достижение по показателям проектов, %",
        sortable: true,
        model: modelPassport.modelId,
        valuesUnit: "%",
        defaultColumn: true,
        data: projectPassport,
        rowSpan: 3,
        sortBy: "AchievementProjectsStatus",
        needPrevPeriod: true,
        prevPeriod: false,
        indicatorColor: "",
      },
      {
        id: "header",
        label: `Выпуск товаров и услуг`,
        updateDateId: "107",
        model: modelPassport.modelId,
        notVisible: true,
        data: projectPassport,
        indicatorColor: "",
      },
      {
        id: "107",
        sortId: 4,
        label: `План, млрд руб.`,
        indicator: modelPassport.indexes.plan,
        parentHeaderLabel: "Выпуск товаров и услуг",
        popUpLabel: "Выпуск товаров и услуг План, млрд руб.",
        sortable: true,
        model: modelPassport.modelId,
        notVisible: true,
        data: projectPassport,
        sortBy: "ManufacturingPlan",
        indicatorColor: "",
      },
      {
        id: "107",
        sortId: 7,
        label: `Факт, млрд руб.`,
        indicator: modelPassport.indexes.fact,
        parentHeaderLabel: "Выпуск товаров и услуг",
        popUpLabel: "Выпуск товаров и услуг Факт, млрд руб.",
        sortable: true,
        model: modelPassport.modelId,
        notVisible: true,
        data: projectPassport,
        sortBy: "ManufacturingFact",
        indicatorColor: "",
      },
      {
        id: "107",
        sortId: 8,
        label: `Достижение, %`,
        indicator: modelPassport.indexes.percent,
        parentHeaderLabel: "Выпуск товаров и услуг",
        popUpLabel: "Выпуск товаров и услуг Достижение, %",
        sortable: true,
        model: modelPassport.modelId,
        notVisible: true,
        focused: true,
        valuesUnit: "%",
        data: projectPassport,
        sortBy: "ManufacturingAchievement",
        indicatorColor: "",
      },
      ...(isCheckFuturePlan
        ? [
            {
              id: "107",
              sortId: 5,
              label: `План 2024 год, млрд руб.`,
              indicator: modelPassport.indexes.plan,
              parentHeaderLabel: "Выпуск товаров и услуг",
              popUpLabel: "Выпуск товаров и услуг План 2024/2030, млрд руб.",
              sortable: true,
              model: modelPassport.modelId,
              notVisible: true,
              data: projectPassport,
              sortBy: "ManufacturingFuturePlan2024",
              dateCode: "120240000",
              indicatorColor: "",
            },
            {
              id: "107",
              sortId: 6,
              label: `План 2030 год, млрд руб.`,
              indicator: modelPassport.indexes.plan,
              parentHeaderLabel: "Выпуск товаров и услуг",
              popUpLabel: "Выпуск товаров и услуг План 2024/2030, млрд руб.",
              sortable: true,
              model: modelPassport.modelId,
              notVisible: true,
              data: projectPassport,
              sortBy: "ManufacturingFuturePlan2030",
              dateCode: "120300000",
              indicatorColor: "",
            },
          ]
        : []),
      {
        id: "header",
        label: `Средняя з/п`,
        updateDateId: "106",
        model: modelPassport.modelId,
        notVisible: true,
        data: projectPassport,
        indicatorColor: "",
      },
      {
        id: "106",
        sortId: 9,
        label: `План, руб.`,
        indicator: modelPassport.indexes.plan,
        parentHeaderLabel: "Средняя з/п",
        popUpLabel: "Средняя з/п План, руб.",
        sortable: true,
        model: modelPassport.modelId,
        notVisible: true,
        data: projectPassport,
        sortBy: "ProfitPlan",
        indicatorColor: "",
      },
      {
        id: "106",
        sortId: 12,
        label: `Факт, руб.`,
        indicator: modelPassport.indexes.fact,
        parentHeaderLabel: "Средняя з/п",
        popUpLabel: "Средняя з/п Факт, руб.",
        sortable: true,
        model: modelPassport.modelId,
        notVisible: true,
        data: projectPassport,
        sortBy: "ProfitFact",
        indicatorColor: "",
      },
      {
        id: "106",
        sortId: 13,
        label: `Достижение, %`,
        indicator: modelPassport.indexes.percent,
        parentHeaderLabel: "Средняя з/п",
        popUpLabel: "Средняя з/п Достижение, %",
        sortable: true,
        model: modelPassport.modelId,
        notVisible: true,
        focused: true,
        valuesUnit: "%",
        data: projectPassport,
        sortBy: "ProfitAchievement",
        indicatorColor: "",
      },
      ...(isCheckFuturePlan
        ? [
            {
              id: "106",
              sortId: 10,
              label: `План 2024 год, руб.`,
              indicator: modelPassport.indexes.plan,
              parentHeaderLabel: "Средняя з/п",
              popUpLabel: "Средняя з/п План 2024/2030, руб.",
              sortable: true,
              model: modelPassport.modelId,
              notVisible: true,
              data: projectPassport,
              sortBy: "ProfitFuturePlan2024",
              dateCode: "120240000",
              indicatorColor: "",
            },
            {
              id: "106",
              sortId: 11,
              label: `План 2030 год, руб.`,
              indicator: modelPassport.indexes.plan,
              parentHeaderLabel: "Средняя з/п",
              popUpLabel: "Средняя з/п План 2024/2030, руб.",
              sortable: true,
              model: modelPassport.modelId,
              notVisible: true,
              data: projectPassport,
              sortBy: "ProfitFuturePlan2030",
              dateCode: "120300000",
              indicatorColor: "",
            },
          ]
        : []),
      {
        id: "header",
        label: `Частные инвестиции`,
        updateDateId: "108",
        model: modelPassport.modelId,
        notVisible: true,
        data: projectPassport,
        indicatorColor: "",
      },
      {
        id: "108",
        sortId: 14,
        label: `План, млрд руб.`,
        indicator: modelPassport.indexes.plan,
        parentHeaderLabel: "Частные инвестиции",
        popUpLabel: "Частные инвестиции План, млрд руб.",
        sortable: true,
        model: modelPassport.modelId,
        notVisible: true,
        data: projectPassport,
        sortBy: "InvestmentPlan",
        indicatorColor: "",
      },
      {
        id: "108",
        sortId: 17,
        label: `Факт, млрд руб.`,
        indicator: modelPassport.indexes.fact,
        parentHeaderLabel: "Частные инвестиции",
        popUpLabel: "Частные инвестиции Факт, млрд руб.",
        sortable: true,
        model: modelPassport.modelId,
        notVisible: true,
        data: projectPassport,
        sortBy: "InvestmentFact",
        indicatorColor: "",
      },
      {
        id: "108",
        sortId: 18,
        label: `Достижение, %`,
        indicator: modelPassport.indexes.percent,
        parentHeaderLabel: "Частные инвестиции",
        popUpLabel: "Частные инвестиции Достижение, %",
        sortable: true,
        model: modelPassport.modelId,
        notVisible: true,
        focused: true,
        valuesUnit: "%",
        data: projectPassport,
        sortBy: "InvestmentAchievement",
        indicatorColor: "",
      },
      ...(isCheckFuturePlan
        ? [
            {
              id: "108",
              sortId: 15,
              label: `План 2024 год, млрд руб.`,
              indicator: modelPassport.indexes.plan,
              parentHeaderLabel: "Частные инвестиции",
              popUpLabel: "Частные инвестиции План 2024/2030, млрд руб.",
              sortable: true,
              model: modelPassport.modelId,
              notVisible: true,
              data: projectPassport,
              sortBy: "InvestmentFuturePlan2024",
              dateCode: "120240000",
              indicatorColor: "",
            },
            {
              id: "108",
              sortId: 16,
              label: `План 2030 год, млрд руб.`,
              indicator: modelPassport.indexes.plan,
              parentHeaderLabel: "Частные инвестиции",
              popUpLabel: "Частные инвестиции План 2024/2030, млрд руб.",
              sortable: true,
              model: modelPassport.modelId,
              notVisible: true,
              data: projectPassport,
              sortBy: "InvestmentFuturePlan2030",
              dateCode: "120300000",
              indicatorColor: "",
            },
          ]
        : []),
      {
        id: "header",
        label: "Новые рабочие места",
        updateDateId: "109",
        model: modelPassport.modelId,
        notVisible: true,
        data: projectPassport,
        indicatorColor: "",
      },
      {
        id: "109",
        sortId: 19,
        label: `План, ед.`,
        indicator: modelPassport.indexes.plan,
        parentHeaderLabel: "Новые рабочие места",
        popUpLabel: "Новые рабочие места План, ед.",
        sortable: true,
        model: modelPassport.modelId,
        notVisible: true,
        fractionDigits: 0,
        data: projectPassport,
        sortBy: "EmployedPlan",
        indicatorColor: "",
      },
      {
        id: "109",
        sortId: 22,
        label: `Факт, ед.`,
        indicator: modelPassport.indexes.fact,
        parentHeaderLabel: "Новые рабочие места",
        popUpLabel: "Новые рабочие места Факт, ед.",
        sortable: true,
        model: modelPassport.modelId,
        notVisible: true,
        fractionDigits: 0,
        data: projectPassport,
        sortBy: "EmployedFact",
        indicatorColor: "",
      },
      {
        id: "109",
        sortId: 23,
        label: `Достижение, %`,
        indicator: modelPassport.indexes.percent,
        parentHeaderLabel: "Новые рабочие места",
        popUpLabel: "Новые рабочие места Достижение, %",
        sortable: true,
        model: modelPassport.modelId,
        notVisible: true,
        focused: true,
        valuesUnit: "%",
        data: projectPassport,
        sortBy: "EmployedAchievement",
        indicatorColor: "",
      },
      ...(isCheckFuturePlan
        ? [
            {
              id: "109",
              sortId: 20,
              label: `План 2024 год, ед.`,
              indicator: modelPassport.indexes.plan,
              parentHeaderLabel: "Новые рабочие места",
              popUpLabel: "Новые рабочие места План 2024/2030, ед.",
              sortable: true,
              model: modelPassport.modelId,
              notVisible: true,
              data: projectPassport,
              sortBy: "EmployedFuturePlan2024",
              dateCode: "120240000",
              fractionDigits: 0,
              indicatorColor: "",
            },
            {
              id: "109",
              sortId: 21,
              label: `План 2030 год, ед.`,
              indicator: modelPassport.indexes.plan,
              parentHeaderLabel: "Новые рабочие места",
              popUpLabel: "Новые рабочие места План 2024/2030, ед.",
              sortable: true,
              model: modelPassport.modelId,
              notVisible: true,
              data: projectPassport,
              sortBy: "EmployedFuturePlan2030",
              dateCode: "120300000",
              fractionDigits: 0,
              indicatorColor: "",
            },
          ]
        : []),
      {
        id: "header",
        label: `Контрольные точки`,
        updateDateId: "129",
        model: modelPassport.modelId,
        defaultColumn: true,
        data: projectPassport,
        needPrevPeriod: true,
        prevPeriod: false,
        indicatorColor: "",
      },
      {
        id: "129",
        sortId: 24,
        label: `План, шт.`,
        indicator: modelPassport.indexes.plan,
        parentHeaderLabel: "Контрольные точки",
        popUpLabel: "Контрольные точки План, шт.",
        sortable: true,
        model: modelPassport.modelId,
        notVisible: true,
        fractionDigits: 0,
        data: projectPassport,
        sortBy: "CheckpointsPlan",
        indicatorColor: "",
      },
      {
        id: "129",
        sortId: 27,
        label: `Факт, шт.`,
        indicator: modelPassport.indexes.fact,
        parentHeaderLabel: "Контрольные точки",
        popUpLabel: "Контрольные точки Факт, шт.",
        sortable: true,
        model: modelPassport.modelId,
        notVisible: true,
        fractionDigits: 0,
        data: projectPassport,
        sortBy: "CheckpointsFact",
        indicatorColor: "",
      },
      {
        id: "129",
        sortId: 3,
        label: `Достижение, %.`,
        indicator: modelPassport.indexes.percent,
        parentHeaderLabel: "Контрольные точки",
        popUpLabel: "Контрольные точки Достижение, %",
        sortable: true,
        model: modelPassport.modelId,
        valuesUnit: "%",
        focused: true,
        defaultColumn: true,
        data: projectPassport,
        sortBy: "CheckpointsAchievement",
        indicatorColor: "",
      },
      ...(isCheckFuturePlan
        ? [
            {
              id: "129",
              sortId: 25,
              label: `План 2024 год, шт.`,
              indicator: modelPassport.indexes.plan,
              parentHeaderLabel: "Контрольные точки",
              popUpLabel: "Контрольные точки План 2024/2030, шт.",
              sortable: true,
              model: modelPassport.modelId,
              notVisible: true,
              data: projectPassport,
              sortBy: "CheckpointsFuturePlan2024",
              dateCode: "120240000",
              fractionDigits: 0,
              indicatorColor: "",
            },
            {
              id: "129",
              sortId: 26,
              label: `План 2030 год, шт.`,
              indicator: modelPassport.indexes.plan,
              parentHeaderLabel: "Контрольные точки",
              popUpLabel: "Контрольные точки План 2024/2030, шт.",
              sortable: true,
              model: modelPassport.modelId,
              notVisible: true,
              data: projectPassport,
              sortBy: "CheckpointsFuturePlan2030",
              dateCode: "120300000",
              fractionDigits: 0,
              indicatorColor: "",
            },
          ]
        : []),
    ] as HeaderColumns[];
  }, [projectPassport, isCheckFuturePlan]);

  return { averageAchievementColumns };
};
