import ColumnsIcon from "@assets/svg_components/ColumnsIcon";

import { AchievementNavigateWrapperProps } from "@/pages/InsideMainPage/lib/types";
import { StyledButton } from "@/shared/Table/ui/Table.styled";
import { ExportControl } from "@/widgets/ExportControl/ui/ExportControl";

import { ButtonsWrapper } from "../InsideMainPageTabs.styled";
import { AchievementNavigateGroup } from "./AchievementNavigateGroup";

export const AchievementNavigateWrapper = ({
  isNoGraphs = false,
  isNotTableRisks = true,
  isShowExport,
  handleClosePopUp,
}: AchievementNavigateWrapperProps) => {
  return (
    <ButtonsWrapper>
      {isNotTableRisks && <AchievementNavigateGroup isNoGraphs={isNoGraphs} />}
      <StyledButton remoteButton onClick={handleClosePopUp}>
        <>
          <ColumnsIcon /> <span>Столбцы</span>
        </>
      </StyledButton>
      {isNotTableRisks && isShowExport && <ExportControl />}
    </ButtonsWrapper>
  );
};
