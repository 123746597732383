import { styled } from "@mui/material";

import { ITotalProps } from "../lib/types";

export const Total = styled("div")<ITotalProps>(
  ({ theme, type, subType, size }) => ({
    width: "100%",
    height: !subType
      ? theme.spacing(
          size === "middle" && type === "primary"
            ? 2
            : size !== "middle" && type === "primary"
            ? 1.5
            : 2.5
        )
      : theme.spacing(subType === "enterprisePrimary" ? 1 : 2.25),
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.indicatorCard.tooltipBg
        : theme.palette.table.backgroundImg,
    borderRadius: theme.spacing(0.5),
  })
);

export const Fill = styled("div")<ITotalProps>(
  ({ theme, type = "primary", background, subType }) => ({
    width: 0,
    height: "100%",
    ...(!subType && {
      backgroundColor: background || theme.palette.chart.fill[type],
    }),
    ...(subType && {
      backgroundColor:
        subType === "enterpriseSecondary"
          ? theme.palette.text.primary
          : theme.palette.diagram.enterpriseColor,
    }),
    borderRadius: theme.spacing(0.5),
    transition: theme!.transitions.create(["width", "color"], {
      easing: theme!.transitions.easing.sharp,
      duration: theme!.transitions.duration.leavingScreen,
    }),
  })
);
