import { MenuItem, Select, styled } from "@mui/material";

export const DropdownStyled = styled("div")<{ isBigSize?: boolean }>(
  ({ theme, isBigSize }) => ({
    display: "inline-flex",
    alignItems: "center",
    position: "relative",
    color: theme.palette.dropdownWidget.baseDropdown,
    cursor: "pointer",
    fontSize: theme.spacing(isBigSize ? 2.5 : 2),

    transition: theme.transitions.create(["color"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    "&:hover": {
      color: theme.palette.primary.main,
    },
  })
);

export const DropdownSelect = styled(Select)({
  position: "absolute",
  width: "100%",
  height: "100%",
  opacity: 0,
  zIndex: -1,
});

export const DropdownOption = styled(MenuItem, {
  shouldForwardProp: (prop) => !["isBigSize"].includes(prop.toString()),
})<{ isBigSize?: boolean }>(({ theme, isBigSize }) => ({
  padding: theme.spacing(0.5, 0.5),

  "& > div": {
    width: "100%",
    padding: theme.spacing(0.75, 1.5),
    fontSize: isBigSize ? 16 : 14,
    lineHeight: "1.25rem",
    borderRadius: 4,

    transition: theme.transitions.create(["background-color"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  "&:hover > div": {
    background: theme.palette.background.default,
  },

  "&.Mui-selected": {
    "& > div": {
      backgroundColor: theme.palette.dropdown.background,
      color: theme.palette.text.onAccent,
    },
  },
}));
