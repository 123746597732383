import { Table as TableWrapper } from "@mui/material";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { CustomScrollbar } from "@/shared/CustomScrollbar/ui/CustomScrollbar";
import { WrapperScrollbar } from "@/shared/CustomScrollbar/ui/CustomScrollbar.styled";

import { HeaderColumns, ITable } from "../lib/types";
import { PopUp } from "./PopUp/PopUp";
import { Header, TableTitle } from "./Table.styled";
import { TableBody } from "./TableBody";
import { TableHeader } from "./TableHeader";

export const Table: FC<ITable> = ({
  headerColumns,
  openColumnsModal,
  title,
  withProjects,
  helperHeder,
  isSort,
  borderForDynamics,
  closeColumnsModal,
}) => {
  const [headerCols, setHeaderCols] = useState<HeaderColumns[]>([]);
  const [orderBy, setOrderBy] = useState();
  const [order, setOrder] = useState("asc");

  const actualHeaderColumns = useMemo(() => {
    return headerColumns.filter((column) => !column.notVisible);
  }, [headerColumns]);

  const columnsPopUp = useRef<HeaderColumns[]>(actualHeaderColumns);

  const handleSubmitPopUpForm = useCallback((result: HeaderColumns[]) => {
    setHeaderCols(result);
    columnsPopUp.current = result;
  }, []);

  const getActualColumns = useMemo(() => {
    let storage: HeaderColumns[] = columnsPopUp.current.filter(
      ({ popUpLabel }) =>
        headerColumns.some(({ popUpLabel: label }) => popUpLabel === label)
    );
    if (storage.every(({ id }) => id === "header")) {
      storage = actualHeaderColumns;
      columnsPopUp.current = actualHeaderColumns;
    } else {
      storage = storage.filter((column, _, array) => {
        let isCheckValues: boolean = true;
        if (column?.id === "header") {
          isCheckValues = Boolean(
            array.find(({ id }) => id === column?.updateDateId)
          );
        }
        return isCheckValues || Number(column?.id) ? column : null;
      });
      columnsPopUp.current = storage;
    }

    return storage.map((col) => {
      const column = headerColumns?.find((item) => {
        return col?.popUpLabel
          ? col.popUpLabel === item.popUpLabel && col?.label === item?.label
          : col?.label === item?.label;
      });
      return {
        ...(column ?? col),
        data: column?.data,
        projectsData: column?.projectsData,
      };
    });
  }, [headerColumns]);

  useEffect(() => {
    setHeaderCols(getActualColumns);
  }, [getActualColumns, headerColumns]);

  const handleSubmitOrderBy = useCallback((val: any) => {
    setOrderBy(val);
    setOrder((prev) => (prev === "asc" ? "desc" : "asc"));
  }, []);

  return (
    <>
      {title && (
        <TableTitle>
          <Header>{title}</Header>
        </TableTitle>
      )}

      <WrapperScrollbar darkTheme style={{ overflow: "none !important" }}>
        <CustomScrollbar>
          <TableWrapper stickyHeader>
            <PopUp
              currentHeaderCols={headerCols}
              initialHeaderCols={headerColumns}
              onSubmit={handleSubmitPopUpForm}
              openPopUp={openColumnsModal}
              onClosePopUp={closeColumnsModal}
            />
            <TableHeader
              setOrderBy={handleSubmitOrderBy}
              setOrder={setOrder}
              orderBy={orderBy}
              order={order}
              data={columnsPopUp.current}
              helperHeder={helperHeder}
              borderForDynamics={borderForDynamics}
            />
            <TableBody
              isSort={isSort}
              order={order}
              orderBy={orderBy}
              columns={headerCols}
              withProjects={withProjects}
            />
          </TableWrapper>
        </CustomScrollbar>
      </WrapperScrollbar>
    </>
  );
};
