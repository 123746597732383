import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { IProjectAttributeVals, MODEL_IDS, MODELS } from "@/store/scheme/olap";

import { ISumRisksTotal, ISumRisksTotalState } from "../../lib/types";
import { getSumRisksTotal } from "../api/sum_risks_total";

export const initialState: ISumRisksTotalState = {
  sumRisksTotal: undefined,
  fetching: true,
  error: null,
};

export const extraActionsSumRisksTotal = {
  get: createAsyncThunk(
    "sum_risks/getTotal",
    async ({
      dataSummaryId,
      dataRegionId,
      districtId,
    }: Record<string, string>) =>
      await getSumRisksTotal({ dataSummaryId, dataRegionId, districtId })
  ),
};

const sumRisksTotalSlice = createSlice({
  name: "sum_risks",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActionsSumRisksTotal.get.pending, (state) => {
        state.sumRisksTotal = undefined;
        state.fetching = true;
      })
      .addCase(extraActionsSumRisksTotal.get.fulfilled, (state, action) => {
        const model = MODELS[MODEL_IDS.RISKS];
        state.sumRisksTotal = action.payload.reduce((prev, cur) => {
          if (!prev) prev = {};
          const regionId = cur?.dims[model.dataRegionId]?.code;
          if (!prev[regionId]) prev[regionId] = {};
          const projectId = cur?.dims[model.dataProjectId]?.code;
          if (!prev[regionId][projectId]) prev[regionId][projectId] = {};
          const indicatorId = cur?.dims[model.dataResultId]?.code;
          if (!prev[regionId][projectId][indicatorId])
            prev[regionId][projectId][indicatorId] = {};
          prev[regionId][projectId][indicatorId] = {
            regionName: (
              cur?.dims[model.dataRegionId]
                ?.attributeVals as IProjectAttributeVals
            )?.name,
            regionId: cur?.dims[model.dataRegionId]?.code,
            project: cur?.dims[model.dataProjectId],
            attributeVals: cur?.attributeVals,
            indicatorVals: Object.fromEntries(
              Object.entries(
                cur.dims[model.dataResultId]?.indicatorVals || {}
              ).map(([key, value]) => [key, value?.sum])
            ) as any,
          };
          return prev;
        }, {} as ISumRisksTotal);
        state.fetching = false;
        state.error = null;
      })
      .addCase(extraActionsSumRisksTotal.get.rejected, (state, action) => {
        state.fetching = false;
        state.error = action.error;
      });
  },
});

export const reducer = sumRisksTotalSlice.reducer;

export default sumRisksTotalSlice;
