import { styled } from "@mui/material";

export const NotifyWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  width: theme.spacing(7.5),
  height: theme.spacing(2),
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(0.25, 1),
  background: theme.palette.button.notifyColor,
  color: theme.palette.button.notifyTitleColor,
  fontWeight: 500,
  fontSize: theme.spacing(1.5),
  lineHeight: theme.spacing(2),
  textTransform: "uppercase",
  whiteSpace: "nowrap",
}));
