import { styled, TableHead, Theme } from "@mui/material";
import Button from "@mui/material/Button";
import TableCell from "@mui/material/TableCell";
import { Link } from "react-router-dom";

import {
  IMainTableValueProps,
  PropsWithLevel,
  VALUE_COLOR,
} from "../lib/types";

const getColor = (theme: Theme, color: VALUE_COLOR, rowLevel?: number) => {
  const isOpacity = rowLevel && rowLevel > 2;
  let result;
  switch (String(color)) {
    case VALUE_COLOR.GREEN:
      result = isOpacity
        ? theme.palette.success.lightOpacity
        : theme.palette.success.main;
      break;
    case VALUE_COLOR.RED:
      result = isOpacity
        ? theme.palette.error.lightOpacity
        : theme.palette.error.main;
      break;
    case VALUE_COLOR.BORDO:
      result = theme.palette.table.riskCountColor;
      break;
    case VALUE_COLOR.LIGHT_GREEN:
      result = theme.palette.graphs.success;
      break;
    case VALUE_COLOR.ORANGE:
      result = theme.palette.table.middleRiskColor;
      break;
    case VALUE_COLOR.DEFAULT:
      result = isOpacity
        ? theme.palette.text.tertiary
        : theme.palette.text.primary;
      break;
  }
  return result;
};

const getSize = (theme: Theme, level?: number, isShare?: boolean) => {
  switch (level) {
    case 0:
      return theme.spacing(isShare ? 2.75 : 3.5);
    case 1:
      return theme.spacing(isShare ? 2.25 : 3);
    case 2:
      return theme.spacing(isShare ? 2.25 : 2.5);
    default:
      return theme.spacing(isShare ? 2.25 : 2.5);
  }
};

export const TableTitle = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  width: "100%",
  height: theme.spacing(8),
  backgroundColor: theme.palette.background.paper,
  "& > button": {
    marginRight: theme.spacing(3),
  },
}));

export const Header = styled("span")(({ theme }) => ({
  fontSize: theme.spacing(4.2),
  color: theme.palette.tabs.textColorSelect,
  fontWeight: theme.typography.fontWeightSemiBold,
}));

export const LinkStyled = styled(Link, {
  shouldForwardProp: (prop) => prop !== "isNotUnderline",
})<{ isNotUnderline?: boolean }>(({ theme, isNotUnderline = false }) => ({
  color: theme.palette.text.primary,
  textDecoration: "none",
  fontWeight: theme.typography.fontWeightSemiBold,
  "&:hover": {
    textDecoration: isNotUnderline ? "none" : "underline",
  },
}));

export const MainTableValue = styled(TableCell, {
  shouldForwardProp: (prop) =>
    !["valueColor", "focused", "level"].includes(prop.toString()),
})<IMainTableValueProps>(
  ({ theme, valueColor = VALUE_COLOR.DEFAULT, level, focused }) => ({
    color: getColor(theme, valueColor, level),
    fontWeight: theme.typography.fontWeightBold,
    fontSize: getSize(theme, level),
    whiteSpace: "nowrap",
    borderRight: `2px solid ${theme.palette.table.dividerLight}`,
    ...(focused && { background: `${theme.palette.table.backgroundFocused}` }),
  })
);

export const HeaderCellStyled = styled(TableCell)<PropsWithLevel>(
  ({ theme, level }) => ({
    color: "red",
  })
);

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "remoteButton",
})<{ remoteButton?: boolean }>(({ theme, remoteButton = false }) => ({
  fontWeight: theme.typography.fontWeightSemiBold,
  color: theme.palette.text.primary,
  textTransform: "none",
  "& > svg": {
    marginRight: theme.spacing(1.2),
  },
  ...(!remoteButton && {
    border: `1px solid ${theme.palette.table.borderButton}`,
    background: theme.palette.table.backgroundButton,
    padding: theme.spacing(1, 1.5),
  }),
  ...(remoteButton && {
    boxSizing: "border-box",
    height: theme.spacing(5),
    border: `1px solid ${
      theme.palette.mode === "dark"
        ? theme.palette.button.border
        : theme.palette.table.borderButton
    }`,
    background:
      theme.palette.mode === "dark"
        ? theme.palette.appBar.achievementBackground
        : theme.palette.text.onAccent,
    padding: theme.spacing(0.5, 1.5),
    "& > svg > path": {
      fill: theme.palette.text.tertiary,
    },
  }),
}));

export const TableHeadStyled = styled(TableHead)(({ theme }) => ({
  ".MuiTableCell-head": {
    background:
      theme.palette.mode === "dark"
        ? theme.palette.table.header
        : theme.palette.button.dividerBackground,
  },
}));

export const TableFooterStyled = styled("div")(({ theme }) => ({
  position: "fixed",
  background: "red",
  bottom: 0,
}));

export const SubValueStyled = styled("span")<{
  isRisk?: boolean;
  valueColor?: any;
}>(({ children, theme, isRisk, valueColor = null }) => {
  const isRed =
    ["number", "string"].includes(typeof children) &&
    parseInt(children as string) > 0;

  return {
    ...(isRed && { color: theme.palette.error.main }),
    ...(isRisk && { color: getColor(theme, valueColor, 0) }),
  };
});

export const TableHL = styled("div")(({ theme, hidden }) => ({
  display: "inline-block",
}));

export const TableWrapperCell = styled(TableCell)(({ theme }) => ({
  boxSizing: "border-box",
  borderBottom: `2px solid ${theme.palette.table.dividerLight}`,
}));

export const TableHeaderStyled = styled(TableWrapperCell, {
  shouldForwardProp: (prop) =>
    !["firstColumn", "lastColumn"].includes(prop.toString()),
})<{
  firstColumn?: boolean;
  lastColumn?: boolean;
}>(({ theme, firstColumn = false, lastColumn = false }) => ({
  borderRight: `2px solid ${theme.palette.table.dividerLight}`,
  ...(firstColumn && {
    width: "43.75rem",
    position: "sticky",
    left: 0,
    zIndex: 10,
    borderRadius: "1rem 0 0 0",
  }),
  ...(lastColumn && {
    borderRadius: "0 1rem 0 0",
  }),
  ...(theme.palette.mode !== "dark" && {
    borderLeft: `1px solid ${theme.palette.button.border}`,
    borderTop: `1px solid ${theme.palette.button.border}`,
  }),
}));

export const TableHelperHeader = styled(TableWrapperCell)<{
  helperhederhight: number;
  isNotLastColum?: boolean;
}>(({ theme, helperhederhight, isNotLastColum }) => ({
  padding: "0.5rem 0.75rem",
  color:
    theme.palette.mode === "dark"
      ? theme.palette.text.tertiary
      : theme.palette.text.primary,
  height: helperhederhight - 2,
  borderRadius: "0 1rem 0 0",
  ...(theme.palette.mode !== "dark" && {
    borderRight: `1px solid ${theme.palette.button.border}`,
    borderTop: `1px solid ${theme.palette.button.border}`,
  }),
  ...(isNotLastColum && {
    borderRadius: "0",
    borderRight: `2px solid ${theme.palette.table.dividerLight}`,
  }),
}));

export const TableHelperLabel = styled(TableWrapperCell)<{
  helperhederhight: number;
}>(({ theme, helperhederhight }) => ({
  top: helperhederhight,
  height: 72,
  borderRight: `2px solid ${theme.palette.table.dividerLight}`,
  "&:last-child": {
    ...(theme.palette.mode !== "dark" && {
      borderRight: `1px solid ${theme.palette.button.border}`,
    }),
  },
}));

export const TableHeaderLabel = styled(TableWrapperCell)<{
  helperhederhight: number;
}>(({ theme, helperhederhight }) => ({
  padding: theme.spacing(0, 1.5),
  lineHeight: "100%",
  top: helperhederhight,
  height: 72,
  "&:not(:last-child)": {
    borderRight: `2px solid ${theme.palette.table.dividerLight}`,
  },
  "&:last-child": {
    ...(theme.palette.mode !== "dark" && {
      borderRight: `1px solid ${theme.palette.button.border}`,
    }),
  },
}));

export const TableValuesLabel = styled(TableWrapperCell, {
  shouldForwardProp: (prop) => prop !== "borderForDynamics",
})<{
  borderForDynamics: boolean;
  top: number;
  isNotLastColum?: boolean;
}>(({ theme, borderForDynamics, top, isNotLastColum }) => ({
  top: top,
  whiteSpace: "nowrap",
  "&:not(:last-child)": {
    borderRight: `2px solid ${theme.palette.table.dividerLight}`,
  },
  padding: "1.375rem 0.75rem",
  ...(borderForDynamics &&
    theme.palette.mode !== "dark" && {
      borderTop: `1px solid ${theme.palette.button.border}`,
    }),
  ...(borderForDynamics && {
    "&:last-child": {
      borderRadius: "0 1rem 0 0",
    },
  }),
  ...(theme.palette.mode !== "dark" && {
    "&:last-child": {
      borderRight: `1px solid ${theme.palette.button.border}`,
      borderRadius: borderForDynamics && "0 1rem 0 0",
    },
  }),
  ...(isNotLastColum && {
    borderRight: `2px solid ${theme.palette.table.dividerLight}`,
  }),
}));

export const RiskProjectTitle = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  "& > span": {
    color: theme.palette.text.unitTextSelected,
  },
}));

export const SubValueRiskStyled = styled("span", {
  shouldForwardProp: (prop) =>
    !["valueColor", "level"].includes(prop.toString()),
})<IMainTableValueProps>(
  ({ theme, valueColor = VALUE_COLOR.DEFAULT, level }) => ({
    color: getColor(theme, valueColor, level),
  })
);

export const SubValueProjectStyled = styled("span")<{ level: number }>(
  ({ theme, level }) => ({
    color: theme.palette.button.achievementText,
    fontSize: getSize(theme, level, true),
    lineHeight: theme.spacing(3),
    fontWeight: 500,
    marginLeft: theme.spacing(3),
  })
);
