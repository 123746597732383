import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { IProjectAttributeVals } from "@/store/scheme/olap";

import { ILevelIncidentState, ILevelTypeIncident } from "../../lib/types";
import { getLevelIncidents } from "../api/levelIncidentsApi";

export const initialState: ILevelIncidentState = {
  levelIncident: [],
  fetching: false,
  error: null,
};

export const extraActionsLevelIncident = {
  post: createAsyncThunk(
    "levelIncident/post",
    async () => await getLevelIncidents()
  ),
};

const levelIncidentsSlice = createSlice({
  name: "levelIncident",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActionsLevelIncident.post.pending, (state) => {
        state.fetching = true;
      })
      .addCase(extraActionsLevelIncident.post.fulfilled, (state, action) => {
        state.levelIncident = action.payload.reduce((prev, cur) => {
          const { name, SHORT_NAME: id } =
            cur.attributeVals as IProjectAttributeVals;
          if (!Array.isArray(prev)) {
            prev = [];
          }
          if (Number(id)) {
            const level: ILevelTypeIncident = {
              id,
              name,
            };
            prev.push(level);
          }

          return prev;
        }, [] as ILevelTypeIncident[]);

        state.fetching = false;
        state.error = null;
      })
      .addCase(extraActionsLevelIncident.post.rejected, (state, action) => {
        state.fetching = false;
        state.error = action.error;
      });
  },
});

export const reducer = levelIncidentsSlice.reducer;

export default levelIncidentsSlice;
