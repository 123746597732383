import { useCallback } from "react";

import { useAppDispatch } from "@/store/redux/hooks";

import { extraActionsSumFoProjects } from "../model/slices/sumProjectsByFoSlice";
import { extraActionsSumRfProjects } from "../model/slices/sumProjectsByRfSlice";
import { extraActionsSumRfFoRisks } from "../model/slices/sumRfFoRisksSlice";
import { extraActionsSumRiskKpi } from "../model/slices/sumRisksKpiRfFo";
import { extraActionsSumRisksTotal } from "../model/slices/sumRisksTotalSlice";

export const useRequestRegions = () => {
  const dispatch = useAppDispatch();

  const requestSumProjectsByRf = useCallback((dataSummaryId: string) => {
    dispatch<any>(extraActionsSumRfProjects.get(dataSummaryId));
  }, []);

  const requestSumProjectsByFo = useCallback(
    ({ dataSummaryId, dataRegionId }: Record<string, string>) => {
      dispatch<any>(
        extraActionsSumFoProjects.get({ dataSummaryId, dataRegionId })
      );
    },
    []
  );

  const requestSumRiskTotal = useCallback(
    ({ dataSummaryId, dataRegionId, districtId }: Record<string, string>) => {
      dispatch<any>(
        extraActionsSumRisksTotal.get({
          dataSummaryId,
          dataRegionId,
          districtId,
        })
      );
    },
    []
  );

  const requestSumRfFoRisk = useCallback(
    ({ dataSummaryId, dataRegionId }: Record<string, string>) => {
      dispatch<any>(
        extraActionsSumRfFoRisks.get({ dataSummaryId, dataRegionId })
      );
    },
    []
  );

  const requestSumRisksKpi = useCallback(
    ({ dataSummaryId, regionId }: Record<string, string>) => {
      dispatch<any>(extraActionsSumRiskKpi.get({ dataSummaryId, regionId }));
    },
    []
  );
  return {
    requestSumRisksKpi,
    requestSumProjectsByRf,
    requestSumProjectsByFo,
    requestSumRiskTotal,
    requestSumRfFoRisk,
  };
};
