import { styled } from "@mui/material";

export const ProjectInitiatorItem = styled("div")(({ theme }) => ({
  display: "flex",
  margin: theme.spacing(0.5, 0),
}));

export const ProjectInitiatorItemIcon = styled("div")<{
  notBackground?: boolean;
}>(({ theme, notBackground = false }) => ({
  flex: "0 0 auto",
  marginRight: theme.spacing(2),
  ...(theme.palette.mode === "dark" &&
    !notBackground && {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: theme.spacing(3),
      height: theme.spacing(3),
      borderRadius: "50%",
      background: theme.palette.background.light,
      "& > svg": {
        width: "50%",
        height: "50%",
      },
    }),
  ...(!notBackground && {
    "& > svg > path": {
      fill: theme.palette.text.unitText,
    },
  }),
}));

export const ProjectInitiatorItemText = styled("div")({
  flex: "1 1 auto",
  fontSize: "1.125rem",
  fontWeight: 400,
  lineHeight: "1.25rem",
});

export const ProjectInitiatorItemAccounting = styled(ProjectInitiatorItem)(
  ({ theme }) => ({
    margin: theme.spacing(2, 0, 0),
    fontWeight: theme.typography.fontWeightSemiBold,

    "& img": {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
  })
);
