import { styled } from "@mui/material";

import { IBasicSectionProps } from "../lib/types";

export const BasicPage = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
});

export const BasicSection = styled("section")<IBasicSectionProps>(
  ({ theme, stretch }) => ({
    position: "relative",
    display: "flex",
    flex: "1 1 100%",
    flexDirection: "column",
    padding: theme.spacing(3, 5),
    boxSizing: "border-box",
    "& > .hoverIncident, & > .parentIncident > div > .hoverIncident": {
      display: "none",
    },
    "&:hover > .hoverIncident, &:hover > .parentIncident > div > .hoverIncident":
      {
        display: "block",
      },
  })
);
