import { Button, Stack, styled } from "@mui/material";

import { colorBasedOnValue } from "../../lib/helpers";

export const Wrapper = styled("div")(({ theme }) => ({
  boxSizing: "border-box",
  position: "relative",
  padding: "24px 12px",
  background: theme.palette.modal.background,
  borderRadius: theme.spacing(),
  height: theme.spacing(59),
  display: "flex",
  flexDirection: "column",
  boxShadow: theme.palette.tabs.shadowColor,
}));

export const WrapperStack = styled(Stack)(({ theme }) => ({
  paddingBottom: theme.spacing(),
  borderBottom: `1px solid ${theme.palette.border.input}`,
}));

export const WrapperContent = styled("div")(({ theme }) => ({
  margin: theme.spacing(3, 0, 1.5, 0),
  ".track-vertical": {
    right: "2px",
    bottom: "2px",
    top: "2px",
    borderRadius: "3px",
    background: theme.palette.scrollbar.track,
  },
  ".thumb-vertical": {
    cursor: "pointer",
    borderRadius: "inherit",
    background: theme.palette.scrollbar.thumb,
  },
  ".scroll-view": {
    scrollbarWidth: "none",
    maxHeight: "240px !important",
    "&::-webkit-scrollbar": {
      width: 0,
    },
  },
}));

export const Icon = styled("img")<{ achEnabled: boolean }>(
  ({ theme, achEnabled }) => ({
    height: achEnabled ? 48 : 104,
    width: achEnabled ? 43 : 88,
  })
);

export const Text = styled("div")(({ theme }) => ({
  textTransform: "uppercase",
  fontWeight: 700,
  fontSize: 14,
  lineHeight: "24px",
  letterSpacing: 0.8,
  opacity: 0.5,
}));

export const RegionsItem = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "isSelect",
})<{ isSelect: boolean | undefined }>(({ theme, isSelect }) => ({
  fontSize: theme.typography.fontSize,
  background: isSelect ? "rgba(255, 255, 255, 0.1)" : "none",
  borderRadius: isSelect ? theme.spacing(0.5) : "none",
  padding: "3px 12px",
}));

export const RegionsItemFact = styled("div")<{ colorValue?: number | null }>(
  ({ theme, colorValue = null }) => ({
    marginLeft: "auto",
    whiteSpace: "nowrap",
    color: colorBasedOnValue(theme, colorValue),
  })
);

export const RegionsItemNumber = styled("div")<{ colorValue?: number | null }>(
  ({ theme, colorValue = null }) => ({
    color: colorBasedOnValue(theme, colorValue),
  })
);

export const RegionsTitle = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(),
}));

export const ValueVLD = styled("span")<{ valueColor?: number | null }>(
  ({ theme, valueColor = null }) => ({
    textTransform: "uppercase",
    fontSize: theme.spacing(8),
    color: colorBasedOnValue(theme, valueColor),
  })
);

export const ValueColorRiskVLD = styled("span")<{
  valueColor?: number;
  selected?: boolean;
}>(({ theme, valueColor, selected }) => ({
  fontSize: theme.spacing(5.75),
  color: colorBasedOnValue(theme, valueColor || null, selected),
}));

export const ButtonStyled = styled(Button)(({ theme }) => ({
  height: 56,
  minHeight: theme.spacing(7),
  background: theme.palette.button.primary,
  borderRadius: theme.spacing(0.5),
  textTransform: "none",
  fontSize: theme.typography.fontSize,
  fontWeight: theme.typography.fontWeightMedium,
  margin: "0 12px",
  marginTop: "auto",

  "&:hover": {
    backgroundColor: theme.palette.button.primaryHover,
  },
}));

export const RegionTitleSubject = styled("div")(({ theme }) => ({
  flex: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

export const ValueDate = styled("span")<{}>(({ theme }) => ({
  display: "block",
  textAlign: "end",
  paddingTop: "1rem",
  fontSize: 12,
  color: theme.palette.tabs.dateTab,
}));
