import { styled } from "@mui/material";

import { TabsListUnstyled, TabsUnstyled } from "@/shared/Tabs/Tabs.styled";

export const ProjectTabsStyled = styled(TabsUnstyled)(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  "&:focus": {
    outline: "none",
  },

  "& > div[role=tabpanel]": {
    flex: "1 1 auto",
  },
}));

export const TabTextRed = styled("div")(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: theme.spacing(2),
}));

export const TabListStyled = styled(TabsListUnstyled)(({ theme }) => ({
  display: "flex",
  "&:focus": {
    outline: "none",
  },
}));
