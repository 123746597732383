import { Avatar, styled } from "@mui/material";
import Box from "@mui/material/Box/Box";
import Card from "@mui/material/Card/Card";

export const AchievementVdlTitle = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(),
  "& > .title": {
    fontWeight: 500,
    fontSize: theme.spacing(2.25),
    lineHeight: theme.spacing(3),
  },
  "& > .subtitle": {
    fontSize: theme.spacing(1.5),
    lineHeight: theme.spacing(1.75),
    color: theme.palette.text.light,
    "&.openSubTitle": {
      fontSize: theme.spacing(2.25),
      lineHeight: theme.spacing(2.5),
    },
  },
}));

export const AchievementVdlStyled = styled(Card, {
  shouldForwardProp: (prop) => !["isOpenPopUp"].includes(prop.toString()),
})<{ isOpenPopUp: boolean }>(({ theme, isOpenPopUp }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  gap: !isOpenPopUp ? theme.spacing(1.5) : 0,
  fontSize: theme.spacing(2),
  height: "100%",
  boxSizing: "border-box",
  ...(isOpenPopUp && {
    boxShadow: "none",
    background: "inherit",
  }),
  "& > div > div > .hoverIncident": {
    display: "none",
  },
  "&:hover > div > div > .hoverIncident": {
    display: "block",
  },
}));

export const AchievementVdlBox = styled(Box, {
  shouldForwardProp: (prop) => !["isOpenPopUp"].includes(prop.toString()),
})<{ isOpenPopUp: boolean }>(({ theme, isOpenPopUp }) => ({
  padding: theme.spacing(1, 3, 0, 3),
  ...(isOpenPopUp && {
    background: theme.palette.indicatorCard.vdlBackground,
    padding: theme.spacing(2, 3),
  }),
}));

export const AchievementVdlContent = styled("div")<{
  openWidgetByFo?: boolean;
}>(({ theme, openWidgetByFo }) => ({
  padding: theme.spacing(openWidgetByFo ? 0 : 3),
}));

export const VdlFooter = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
}));

export const AvatarStyled = styled(Avatar, {
  shouldForwardProp: (prop) => !["isOpenPopUp"].includes(prop.toString()),
})<{ isOpenPopUp?: boolean }>(({ theme, isOpenPopUp }) => ({
  width: theme.spacing(isOpenPopUp ? 8 : 5),
  height: theme.spacing(isOpenPopUp ? 8 : 5),
}));

export const AllVdlWrapper = styled("div")<{
  isOpenPopUp?: boolean;
  openWidgetByRf?: boolean;
}>(({ theme, isOpenPopUp, openWidgetByRf }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  "&:not(:last-child)": {
    paddingBottom: !isOpenPopUp ? theme.spacing(1) : 0,
  },
  ...(isOpenPopUp && {
    padding: theme.spacing(3, 2, 0, 3),
    "&:last-child": {
      paddingBottom: theme.spacing(3),
    },
  }),
  ...(openWidgetByRf && {
    "&:not(:last-child)": {
      paddingBottom: theme.spacing(3),
    },
  }),
}));

export const LeaderInfo = styled("div")<{ isOpenPopUp?: boolean }>(
  ({ theme, isOpenPopUp }) => ({
    display: "flex",
    flexFlow: "column nowrap",
    flex: 1,
    paddingLeft: theme.spacing(1),
    ...(isOpenPopUp && { gap: 4 }),
  })
);

export const NameWrapper = styled("span")<{ isOpenPopUp?: boolean }>(
  ({ theme, isOpenPopUp }) => ({
    fontSize: theme.spacing(isOpenPopUp ? 3 : 2),
    lineHeight: theme.spacing(isOpenPopUp ? 3 : 2.5),
    color: theme.palette.text.primary,
  })
);

export const RegionWrapper = styled("span")<{ isOpenPopUp?: boolean }>(
  ({ theme, isOpenPopUp }) => ({
    fontSize: theme.spacing(isOpenPopUp ? 2.25 : 1.5),
    lineHeight: theme.spacing(isOpenPopUp ? 2.5 : 1.75),
    color: theme.palette.text.light,
  })
);

export const AllVdlUnit = styled("span")<{ openWidget?: boolean }>(
  ({ theme, openWidget }) => ({
    fontWeight: 700,
    fontSize: theme.spacing(openWidget ? 3.25 : 3),
    lineHeight: theme.spacing(3),
    color: theme.palette.text.primary,
  })
);
