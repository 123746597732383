import { styled, ToggleButton } from "@mui/material";

import { Control } from "@/shared/Control/ui/Control";

export const ToggleButtonWrapper = styled(Control, {
  shouldForwardProp: (prop) => !["isBackground"].includes(prop.toString()),
})<{ isBackground?: boolean }>(({ theme, isBackground }) => ({
  border: `1px solid ${
    isBackground ? theme.palette.button.border : theme.palette.text.tertiary
  }`,
  ...(isBackground && {
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.button.background
        : theme.palette.text.onAccent,
    "& > button > svg > path": {
      fill:
        theme.palette.mode === "dark"
          ? theme.palette.button.notSelected
          : theme.palette.text.primary,
    },
  }),
}));

export const ToggleButtonStyled = styled(ToggleButton)(({ theme }) => ({
  boxSizing: "border-box",
  "&.Mui-selected": {
    backgroundColor: theme.palette.primary.main,
    padding: 7,
  },
  "&:not(.Mui-selected)": {
    padding: 7,
  },
}));
