import React from "react";

import {
  LegendIcon,
  LegendItemWrapper,
  LegendTitle,
  LegendWrapper,
} from "./Legend.styled";
import { ILegendProps } from "./lib/types";

export const Legend = ({ legendData }: ILegendProps) => {
  return (
    <LegendWrapper>
      {legendData.map(({ id, type, sizeHeight, sizeWidth, color, title }) => (
        <LegendItemWrapper key={id}>
          <LegendIcon
            type={type}
            sizeHeight={sizeHeight}
            sizeWidth={sizeWidth}
            color={color}
            isBlur
          ></LegendIcon>
          <LegendTitle>{title}</LegendTitle>
        </LegendItemWrapper>
      ))}
    </LegendWrapper>
  );
};
