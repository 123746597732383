import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { STEPS } from "@/shared/GridCard/lib/types";
import { prepareAttributes } from "@/shared/lib/heplers";
import { ArgumentsWithDistrictIdType } from "@/shared/lib/types";
import {
  IOlapResponseDimensionItemDimWithVals,
  IPeriodAttributeVals,
  IPreparedIndicatorAttributeVals,
  MODEL_IDS,
  MODELS,
} from "@/store/scheme/olap";
import { TAB_ID } from "@/store/scheme/region";

import { ArgumentsTabsT, ITabsState } from "../lib/types";
import {
  getTabsAchievements,
  getTabsCheckpointsAchievements,
  getTabsDynamics,
  getTabsMainAchievements,
} from "./api";

export const initialState: ITabsState = {
  generalTab: {
    dynamic: TAB_ID.MANUFACTURING,
    achieve: TAB_ID.VDL_PROJECTS,
  },
  saveStep: STEPS.MAIN,
  activeTab: TAB_ID.VDL_PROJECTS,
  highlightWidget: "",
  achievements: undefined,
  achievementsFetching: false,
  achievementsError: null,
  dynamics: undefined,
  dynamicsFetching: false,
  dynamicsError: null,
  mainAchievements: undefined,
  mainAchievementsFetching: false,
  mainAchievementError: null,
  checkpointsAchievements: undefined,
  checkpointsAchievementsFetching: false,
  checkpointsAchievementsError: null,
  achievementsFiltered: undefined,
  extensionProjectsList: undefined,
};

export const extraActionsTabs = {
  getTabsAchievements: createAsyncThunk(
    "ui/tabs/achievements",
    async (args: ArgumentsWithDistrictIdType) => await getTabsAchievements(args)
  ),
  getTabsDynamics: createAsyncThunk(
    "ui/tabs/dynamics",
    async ({ regionId, districtId }: ArgumentsTabsT) =>
      await getTabsDynamics(regionId, districtId)
  ),
  getTabsMainAchievements: createAsyncThunk(
    "ui/tabs/mainAchievements",
    async (args: ArgumentsWithDistrictIdType) =>
      await getTabsMainAchievements(args)
  ),
  getTabsCheckpointsAchievements: createAsyncThunk(
    "ui/tabs/checkpointsAchievements",
    async ({ regionId, districtId }: ArgumentsTabsT) =>
      await getTabsCheckpointsAchievements(regionId, districtId)
  ),
};

const tabsSlice = createSlice({
  name: "ui/tabs",
  initialState,
  reducers: {
    updateCurrentTabAction: (state, action: PayloadAction<TAB_ID>) => {
      state.activeTab = action.payload;
    },
    updatetHighlightWidget: (state, action: PayloadAction<string>) => {
      state.highlightWidget = action.payload;
    },
    updateGeneralTab: (state, action: PayloadAction<any>) => {
      const { enabled, tab } = action.payload;

      state.generalTab[enabled ? "achieve" : "dynamic"] = tab;
    },
    getSaveStep: (state, action: PayloadAction<STEPS>) => {
      state.saveStep = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(extraActionsTabs.getTabsAchievements.pending, (state) => {
        state.achievements = undefined;
        state.achievementsFetching = true;
      })
      .addCase(
        extraActionsTabs.getTabsAchievements.fulfilled,
        (state, action) => {
          const model = MODELS[MODEL_IDS.KPI_SOC_ECONOMY];

          const monthData = action.payload.filter(
            (i) => (i?.attributeVals as any)?.DatePart === "MONTH"
          );
          const data = action.payload.filter(
            (i) => (i?.attributeVals as any)?.DatePart === "QUARTER"
          );

          state.achievementsFiltered = monthData.reduce((prev, cur) => {
            const indicatorId = cur?.dims?.[model.dimId]?.code;
            const subjectId = cur?.dims?.[model.dataRegionId]?.code;

            if (indicatorId) {
              if (!prev[indicatorId]) {
                prev[indicatorId] = {};
              }
              prev[indicatorId][subjectId] = {
                ...prepareAttributes<IPreparedIndicatorAttributeVals>({
                  ...cur?.dims?.[model.dataRegionId]?.attributeVals,
                  REPORT_DATE: (cur?.attributeVals as IPeriodAttributeVals)
                    ?.REPORT_DATE,
                  PREVIOUS_DATE: (cur?.attributeVals as IPeriodAttributeVals)
                    ?.PREVIOUS_DATE,
                  DatePart: (cur?.attributeVals as IPeriodAttributeVals)
                    ?.DatePart,
                }),
                attributeVals: cur?.dims?.[model.dimId]?.attributeVals || {},
                indicatorVals: Object.fromEntries(
                  Object.entries(cur?.indicatorVals || {}).map(
                    ([key, value]) => [key, value?.sum]
                  )
                ) as any,
              };
            }

            return prev;
          }, {} as any);

          state.achievements = data.reduce((prev, cur) => {
            const indicatorId = cur?.dims?.[model.dimId]?.code;
            const subjectId = cur?.dims?.[model.dataRegionId]?.code;

            if (indicatorId) {
              if (!prev[indicatorId]) {
                prev[indicatorId] = {};
              }
              prev[indicatorId][subjectId] = {
                ...prepareAttributes<IPreparedIndicatorAttributeVals>({
                  ...cur?.dims?.[model.dataRegionId]?.attributeVals,
                  REPORT_DATE: (cur?.attributeVals as IPeriodAttributeVals)
                    ?.REPORT_DATE,
                  PREVIOUS_DATE: (cur?.attributeVals as IPeriodAttributeVals)
                    ?.PREVIOUS_DATE,
                  DatePart: (cur?.attributeVals as IPeriodAttributeVals)
                    ?.DatePart,
                }),
                attributeVals: cur?.dims?.[model.dimId]?.attributeVals || {},
                indicatorVals: Object.fromEntries(
                  Object.entries(cur?.indicatorVals || {}).map(
                    ([key, value]) => [key, value?.sum]
                  )
                ) as any,
              };
            }

            return prev;
          }, {} as any);

          state.achievementsFetching = false;
          state.achievementsError = null;
        }
      )
      .addCase(
        extraActionsTabs.getTabsAchievements.rejected,
        (state, action) => {
          state.achievementsFetching = false;
          state.achievementsError = action.error;
        }
      )

      .addCase(extraActionsTabs.getTabsDynamics.pending, (state) => {
        state.dynamics = undefined;
        state.dynamicsFetching = true;
      })
      .addCase(extraActionsTabs.getTabsDynamics.fulfilled, (state, action) => {
        const model = MODELS[MODEL_IDS.KPI_SOC_ECONOMY];

        state.dynamics = action.payload.reduce((prev, cur, curIdx) => {
          const indicatorId = cur?.dims?.[model.dimId]?.code;
          const subjectId = cur?.dims?.[model.dataRegionId]?.code;

          if (indicatorId) {
            if (!prev[indicatorId]) {
              prev[indicatorId] = {};
            }
            prev[indicatorId][subjectId] = {
              ...prepareAttributes<IPreparedIndicatorAttributeVals>({
                ...cur?.dims?.[model.dataRegionId]?.attributeVals,
                REPORT_DATE: (cur?.attributeVals as IPeriodAttributeVals)
                  ?.REPORT_DATE,
              }),
              attributeVals: cur?.dims?.[model.dimId]?.attributeVals || {},
              indicatorVals: Object.fromEntries(
                Object.entries(cur?.indicatorVals || {}).map(([key, value]) => [
                  key,
                  value?.sum,
                ])
              ) as any,
            };
          }

          return prev;
        }, {} as any);

        state.dynamicsFetching = false;
        state.dynamicsError = null;
      })
      .addCase(extraActionsTabs.getTabsDynamics.rejected, (state, action) => {
        state.dynamicsFetching = false;
        state.dynamicsError = action.error;
      })

      .addCase(extraActionsTabs.getTabsMainAchievements.pending, (state) => {
        state.mainAchievements = undefined;
        state.extensionProjectsList = undefined;
        state.mainAchievementsFetching = true;
      })
      .addCase(
        extraActionsTabs.getTabsMainAchievements.fulfilled,
        (state, action) => {
          const model = MODELS[MODEL_IDS.PROJECT_PASSPORT];

          const data = action.payload.filter(
            (i) => (i?.attributeVals as any)?.DatePart === "QUARTER"
          );

          state.mainAchievements = data.reduce((prev, cur, curIdx) => {
            const indicatorId = cur?.dims?.[model.dimId]?.code;
            const projectId = cur?.dims?.[model.dataProjectId]?.code;

            if (indicatorId) {
              if (!prev[indicatorId]) {
                prev[indicatorId] = {};
              }
              prev[indicatorId][projectId] = {
                ...prepareAttributes<IPreparedIndicatorAttributeVals>({
                  ...cur?.dims?.[model.dataRegionId]?.attributeVals,
                  REPORT_DATE: (cur?.attributeVals as IPeriodAttributeVals)
                    ?.REPORT_DATE,
                }),
                attributeVals: cur?.dims?.[model.dimId]?.attributeVals || {},
                indicatorVals: Object.fromEntries(
                  Object.entries(cur?.indicatorVals || {}).map(
                    ([key, value]) => [key, value?.sum]
                  )
                ) as any,
              };
            }

            return prev;
          }, {} as any);

          // Получение статуса для виджета Проекты из квартальных данных
          const dataByStatus = data.reduce((prev, cur) => {
            const projectId = cur?.dims?.[model.dataProjectId]?.code;
            if (projectId) {
              if (!prev[projectId]) {
                prev[projectId] = {};
              }
              const projectIdCode = cur?.dims?.[model.dimId]?.code;

              if (projectIdCode === "0") {
                const projectIdDimId = (cur?.dims[model.dataProjectId] as any)
                  ?.dimId as any;

                prev[projectId][projectIdDimId] = {
                  indicatorVals: Object.entries(
                    cur?.indicatorVals || {}
                  ).reduce(
                    (acc, [key, value]) =>
                      key === model.indexes.status
                        ? { ...acc, [key]: value }
                        : acc,
                    {}
                  ),
                };
              }
            }

            return prev;
          }, {} as any);

          state.extensionProjectsList = action.payload.reduce((prev, cur) => {
            // Периоды с 3 - квартальные для фактов, 120240000, 120300000
            const period = cur?.code;
            if (!prev[period]) {
              prev[period] = {};
            }
            // Проверка на 767 и из него берем projectId
            if (cur?.dims?.[model.dataProjectId]?.code) {
              const projectId = cur.dims[model.dataProjectId].code;

              if (!prev[period][projectId]) {
                prev[period][projectId] = {};
              }
              // Проверка на 1129 и из него берем 4 индикатора 106, 107, 108, 109
              if (cur?.dims?.[model.dimId]?.code) {
                const projectIdDimId = (cur?.dims[model.dataProjectId] as any)
                  ?.dimId as string;
                const projectIdCode = cur.dims[model.dimId].code;
                // Структура {320220004 => id проекта => 106 => данные}
                prev[period][projectId][projectIdCode] = {
                  ...prepareAttributes<IPreparedIndicatorAttributeVals>(
                    cur.dims[model.dimId].attributeVals
                  ),
                  attributeVals: cur?.attributeVals || {},
                  indicatorVals: Object.fromEntries(
                    Object.entries(cur?.indicatorVals || {}).map(
                      ([key, value]) => [key, value?.sum]
                    )
                  ) as any,
                };
                // Статус берется только из кварталов
                const status = dataByStatus?.[projectId]?.[projectIdDimId];
                // Для {320220004 => id проекта => 767 берем наименование, срок и статус
                prev[period][projectId][projectIdDimId] = {
                  ...prepareAttributes<IPreparedIndicatorAttributeVals>(
                    cur.dims[model.dataProjectId].attributeVals
                  ),
                  foAttributeVals: cur.dims[model.dataRegionId].attributeVals,
                  attributeVals: cur?.attributeVals || {},
                  indicatorVals: Object.fromEntries(
                    Object.entries({
                      ...(cur?.indicatorVals || {}),
                      ...(status?.indicatorVals || {}),
                    } as IOlapResponseDimensionItemDimWithVals).map(
                      ([key, value]) => [key, value?.sum]
                    )
                  ) as any,
                };
              }
            }
            return prev;
          }, {} as any);

          state.mainAchievementsFetching = false;
          state.mainAchievementError = null;
        }
      )
      .addCase(
        extraActionsTabs.getTabsMainAchievements.rejected,
        (state, action) => {
          state.mainAchievementsFetching = false;
          state.mainAchievementError = action.error;
        }
      )
      .addCase(
        extraActionsTabs.getTabsCheckpointsAchievements.pending,
        (state) => {
          state.checkpointsAchievements = undefined;
          state.checkpointsAchievementsFetching = true;
        }
      )
      .addCase(
        extraActionsTabs.getTabsCheckpointsAchievements.fulfilled,
        (state, action) => {
          const model = MODELS[MODEL_IDS.PROJECT_RESULTS];

          state.checkpointsAchievements = action.payload.reduce(
            (prev, cur, curIdx) => {
              const indicatorId = cur?.dims?.[model.dimId]?.code;
              const subjectId = cur?.dims?.[model.dataRegionId]?.code;

              if (indicatorId) {
                if (!prev[indicatorId]) {
                  prev[indicatorId] = {};
                }
                prev[indicatorId][subjectId] = {
                  ...prepareAttributes<IPreparedIndicatorAttributeVals>({
                    ...cur?.dims?.[model.dataRegionId]?.attributeVals,
                    REPORT_DATE: (cur?.attributeVals as IPeriodAttributeVals)
                      ?.REPORT_DATE,
                  }),
                  attributeVals: cur?.dims?.[model.dimId]?.attributeVals || {},
                  indicatorVals: Object.fromEntries(
                    Object.entries(cur?.indicatorVals || {}).map(
                      ([key, value]) => [key, value?.sum]
                    )
                  ) as any,
                };
              }

              return prev;
            },
            {} as any
          );

          state.checkpointsAchievementsFetching = false;
          state.checkpointsAchievementsError = null;
        }
      )
      .addCase(
        extraActionsTabs.getTabsCheckpointsAchievements.rejected,
        (state, action) => {
          state.checkpointsAchievementsFetching = false;
          state.checkpointsAchievementsError = action.error;
        }
      );
  },
});

export const {
  updateCurrentTabAction,
  updatetHighlightWidget,
  updateGeneralTab,
  getSaveStep,
} = tabsSlice.actions;

export const reducer = tabsSlice.reducer;

export default tabsSlice;
