import { styled } from "@mui/material";

import { colorBasedOnValue } from "@/pages/MainPage/lib/helpers";

export const Divider = styled("span")(({ theme }) => ({
  opacity: 0.5,
}));

export const ValueColor = styled("span")<{
  valueColor?: number;
  selected?: boolean;
}>(({ theme, valueColor, selected = false }) => ({
  ...(valueColor && {
    color: colorBasedOnValue(theme, valueColor, selected),
  }),
}));
