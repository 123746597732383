import { combineReducers } from "@reduxjs/toolkit";

import { IFeedbackState } from "@/shared/Feedback/lib/types";
import feedbackSlice from "@/shared/Feedback/model/feedbackSlice";
import {
  IIncidentState,
  ILevelIncidentState,
  ITypeIncidentState,
} from "@/shared/Incident/lib/types";
import incidentSlice from "@/shared/Incident/model/slices/incidentSlice";
import levelIncidentsSlice from "@/shared/Incident/model/slices/levelIncidentsSlice";
import typeIncidentsSlice from "@/shared/Incident/model/slices/typeIncidentsSlice";

export interface IFeedbackReducer {
  incident: IIncidentState;
  feedback: IFeedbackState;
  levelIncident: ILevelIncidentState;
  typeIncident: ITypeIncidentState;
}

const reducer = combineReducers<IFeedbackReducer>({
  incident: incidentSlice.reducer,
  feedback: feedbackSlice.reducer,
  levelIncident: levelIncidentsSlice.reducer,
  typeIncident: typeIncidentsSlice.reducer,
});

export default reducer;
