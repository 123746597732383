import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IIndicatorPassportProps, IPassportState } from "../lib/types";

export const initialState: IPassportState = {
  indicatorPassport: {
    indicatorId: null,
    data: {},
    open: false,
  },
};

const passportSlice = createSlice({
  name: "passport",
  initialState,
  reducers: {
    setPassport: (state, action: PayloadAction<IIndicatorPassportProps>) => {
      state.indicatorPassport = { ...action.payload };
    },
  },
});

export const { setPassport } = passportSlice.actions;

export const reducer = passportSlice.reducer;

export default passportSlice;
