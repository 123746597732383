import { Theme } from "@mui/material";
import { graphic } from "echarts";

import { formatNumber } from "@/shared/lib/heplers";

export const getXaxisLabelStyle = (
  item: number,
  theme: Theme,
  dataIndex?: number
) => ({
  fontSize: 18,
  lineHeight: 25,
  fontWeight: theme.typography.fontWeightSemiBold,
  color:
    item === dataIndex
      ? theme.palette.text.primary
      : theme.palette.mode === "dark"
      ? theme.palette.text.lightGrey
      : theme.palette.button.achievementText,
  verticalAlign: "center",
});

const labelStyle = (theme: Theme) => {
  return {
    show: true,
    fontSize: 16,
    fontWeight: theme.typography.fontWeightRegular,
    formatter: ({ value }: any) => formatNumber(value),
  };
};

const selectedLabelStyle = (theme: Theme) => {
  return {
    fontSize: 18,
    fontWeight: theme.typography.fontWeightSemiBold,
  };
};

const planLabelStyle = (theme: Theme) => {
  return {
    ...labelStyle(theme),
    color: theme.palette.graphs.planLabel,
  };
};

const factLabelStyle = (theme: Theme) => {
  return {
    ...labelStyle(theme),
    position: "bottom",
    color: theme.palette.graphs.factLabel,
    offset: [0, 6],
  };
};

const selectedPlanLabelStyle = (theme: Theme) => {
  return {
    ...planLabelStyle(theme),
    ...selectedLabelStyle(theme),
    color: theme.palette.graphs.selectedPlanLabel,
  };
};

const selectedFactLabelStyle = (theme: Theme) => {
  return {
    ...factLabelStyle(theme),
    ...selectedLabelStyle(theme),
    color: theme.palette.graphs.selectedFactLabel,
  };
};

export const getDataLabelStyle = (
  name: string,
  item: number,
  theme: Theme,
  dataIndex?: number
) => {
  return item === dataIndex
    ? selectedFactLabelStyle(theme)
    : factLabelStyle(theme);
};

export const getPlanLabelStyle = (
  item: number,
  theme: Theme,
  dataIndex?: number
) => {
  return item === dataIndex
    ? selectedPlanLabelStyle(theme)
    : planLabelStyle(theme);
};

export const ecOpt = (theme: Theme) => {
  return {
    tooltip: {
      showContent: false,
      trigger: "axis",
      axisPointer: {
        lineStyle: {
          type: "solid",
          color: theme.palette.graphs.graphHoverLine,
        },
      },
    },
    grid: {
      top: "8%",
      bottom: "23%",
      right: "4%",
      left: "4%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      splitLine: {
        show: true,
        lineStyle: { color: theme.palette.graphs.graphLine },
      },
      axisLine: { show: false },
      axisTick: { show: false },
      offset: 55,
    },
    yAxis: [
      {
        type: "value",
        axisLabel: { show: false },
        axisLine: { show: false },
        splitLine: { show: false },
      },
    ],
    series: [
      {
        name: "План",
        type: "line",
        areaStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0.3,
              color: theme.palette.graphs.graphsStart,
            },
            {
              offset: 1,
              color: theme.palette.graphs.graphsEnd,
            },
          ]),
        },
        symbol: "pin",
        symbolSize: 0,
        lineStyle: { width: 0 },
      },
      {
        name: "Факт",
        type: "line",
        symbolSize: 8,
        symbol: "circle",
        itemStyle: { color: theme.palette.text.primary },
        lineStyle: { width: 3 },
      },
    ],
  };
};
