import React from "react";

import {
  ToggleButtonItem,
  ToggleButtonWrapper,
} from "../ToggleButton/ToggleButton.styled";
import { BinarySwitcher } from "./BinarySwitcher";
import { SwitcherProps } from "./lib/types";

export const Switcher = ({
  value,
  switcherData,
  callbackChange,
  noBorder = false,
  isSwitchIcon,
  openWidget = false,
  ...props
}: SwitcherProps) => {
  return isSwitchIcon ? (
    <BinarySwitcher
      value={value}
      openWidget={openWidget}
      switcherData={switcherData}
      callbackChange={callbackChange}
    />
  ) : (
    <ToggleButtonWrapper
      onChange={callbackChange}
      exclusive
      value={value}
      noBorder={noBorder}
      {...props}
    >
      {switcherData.map(({ value, title, ...props }) => (
        <ToggleButtonItem key={value} value={value} {...props}>
          <p>{title}</p>
        </ToggleButtonItem>
      ))}
    </ToggleButtonWrapper>
  );
};
