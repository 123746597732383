import { SerializedError } from "@reduxjs/toolkit";
import { MouseEvent, ReactNode } from "react";

import { SELECT_VALUE } from "@/shared/InputSelect/lib/types";
import {
  IOlapResponseDimensionItemDim,
  IOlapResponseDimensionItemDimWithVals,
  IPeriodAttributeVals,
  IProjectAttributeVals,
  IProjectCheckpointAttributeVals,
  IProjectFinancesAttributeVals,
  IProjectIndicatorAttributeVals,
  IProjectResultsAttributeVals,
  IRegionAttributeVals,
} from "@/store/scheme/olap";
import { TAB_LEVEL } from "@/store/scheme/region";

export interface IICONS {
  id: string;
  icon: ReactNode;
}

export interface IProjectResultsTableProps {
  handleLegendCallback: (legend: boolean) => void;
}

export const UNITS_MEASURE: { [key: string]: number } = {
  "тыс руб.": 1,
  "млн руб.": 1000,
  "млрд руб.": 1000000,
};

export interface ProjectTableCellProps {
  width?: string | number;
  align?: "left" | "center" | "right" | "justify" | "inherit";
}

export interface IProjectTabsProps {
  onTabChange?: (tab: TAB_LEVEL) => void;
  handleLegendCallback: (legend: boolean) => void;
}

export interface CheckpointsT {
  [
    resultId: string
  ]: IOlapResponseDimensionItemDimWithVals<IProjectCheckpointAttributeVals>[];
}

export interface ResultsT {
  [
    resultId: string
  ]: IOlapResponseDimensionItemDim<IProjectResultsAttributeVals>;
}

export interface IPagesProjectCheckpointsAndResultsState {
  checkpoints?: CheckpointsT;
  allCheckpoints?: CheckpointsT;
  allResults?: ResultsT;
  results?: ResultsT;
  resultsSum?: number;
  fetching: boolean;
  fetchingAllCheckpoints: boolean;
  fetchingAllResults: boolean;
  checkpointsError: SerializedError | null;
  allCheckpointsError: SerializedError | null;
  allResultsError: SerializedError | null;
}

export interface IPagesProjectFinancesPeriodData
  extends IOlapResponseDimensionItemDimWithVals<IPeriodAttributeVals> {
  indicators: {
    [indicatorId: string]: IPagesProjectFinancesIndicatorData;
  };
}

export interface IPagesProjectFinancesData {
  summary: {
    [indicatorId: string]: IPagesProjectFinancesIndicatorData;
  };
  periods: {
    [periodId: string]: IPagesProjectFinancesPeriodData;
  };
}

export interface IPagesProjectFinancesState {
  data?: IPagesProjectFinancesData;
  fetching: boolean;
  error: SerializedError | null;
}

export interface IPagesProjectFinancesIndicatorData
  extends IOlapResponseDimensionItemDimWithVals<IProjectFinancesAttributeVals> {
  values?: { [sumIndicatorId: string]: number };
}

export interface IPagesProjectInfoIndicatorData
  extends IOlapResponseDimensionItemDimWithVals<IProjectIndicatorAttributeVals> {
  values?: { [sumIndicatorId: string]: number };
}

export interface IPagesProjectInfoPeriodData
  extends IOlapResponseDimensionItemDimWithVals<IPeriodAttributeVals> {
  indicators: {
    [indicatorId: string]: IPagesProjectInfoIndicatorData;
  };
}

export interface IPagesProjectInfoData {
  general: IOlapResponseDimensionItemDimWithVals<IProjectAttributeVals>;
  region: IOlapResponseDimensionItemDimWithVals<IRegionAttributeVals>;
  summary: {
    [indicatorId: string]: IPagesProjectInfoIndicatorData;
  };
  periods: {
    [periodId: string]: IPagesProjectInfoPeriodData;
  };
}

export interface IPagesProjectInfoState {
  data?: IPagesProjectInfoData;
  fetching: boolean;
  error: SerializedError | null;
}

type ProjectType = {
  attributeVals: IProjectAttributeVals;
};

export interface IProjectsSlice {
  data?: {
    [projectId: string]: any;
  };
  filtered?: {
    [projectId: string]: any;
  };
  dataFetching: boolean;
  dataError: SerializedError | null;
  allProjects?: {
    [regionId: string]: any;
  };
  isLoadAllProjects: boolean;
  allProjectsFetching: boolean;
  allProjectsError: SerializedError | null;
  projectsDirectory: any[];
  projectsDirectoryByRegion?: {
    [regionId: string]: ProjectType[];
  };
  projectsDirectoryFetching: boolean;
  projectsDirectoryError: SerializedError | null;
}

export interface IUnitMeasureGroupProps {
  unitMeasure: string;
  handleUnitMeasure: (
    e: MouseEvent<HTMLElement>,
    newUnitMeasure: string
  ) => void;
}

export interface IAchievementIndicatorHeaderProps {
  changePeriod: (period: SELECT_VALUE) => void;
}

export interface IArrReportDate {
  value: number;
  label: string;
  isActual?: boolean;
}

export interface MethodCalcRiskProps {
  handleCallback: () => void;
}

export interface IRisksRow {
  id: string;
  problem: string;
  description: string;
  responsible: string;
  solution: string;
}

export type RiskRowField = keyof IRisksRow;

export interface IRiskByProject {
  [levelRisk: string]: {
    level?: number;
    color?: number;
    date?: string;
  };
}

export interface IRiskProjectReference {
  [projectId: string]: {
    [id: string]: {
      id?: string;
      problem?: string;
      description?: string;
      responsible?: string;
      solution?: string;
    };
  };
}
export interface IRiskProjectState {
  riskByProject?: IRiskByProject;
  riskProjectReference?: IRiskProjectReference;
  riskProjectFetching: boolean;
  riskReferenceFetching: boolean;
  riskProjectError: SerializedError | null;
  riskReferenceError: SerializedError | null;
}
