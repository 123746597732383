import {
  getOperand,
  olapRequestData2,
  olapRequestDimensionData,
} from "@/store/api/core";
import {
  FILTER_OPERAND_TYPE,
  FILTER_TYPE,
  MODEL_IDS,
  MODELS,
} from "@/store/scheme/olap";

export const getNationalProjectsAll = () => {
  const model = MODELS[MODEL_IDS.NATIONAL_PROJECTS_INDICATORS];

  return olapRequestDimensionData(model.dataProjectId, {
    modelUuid: model.uuid,
    dimensionId: model.dataProjectId,
    includeAttributesByCodes: [
      "NAME",
      "SHORT_NAME",
      "SORT_ORDER",
      "short_name",
    ],
  });
};

export const getNationalProjects = (regionId: string) => {
  const model = MODELS[MODEL_IDS.NATIONAL_PROJECTS_INDICATORS];

  const dimIds = [
    model.dataPeriodId,
    model.dataRegionId,
    model.dataProjectId,
    model.dimId,
    model.dataActualId,
  ];

  const dimensions = [
    {
      id: model.dataPeriodId,
      includeItems: true,
      includeAttributesByCodes: ["REPORT_DATE"],
    },
    {
      id: model.dataRegionId,
    },
    {
      id: model.dataProjectId,
      includeItems: true,
      includeAttributesByCodes: ["short_name", "sort_order"],
    },
    {
      id: model.dimId,
      includeItems: true,
      includeAttributesByCodes: [],
    },
    {
      id: model.dataActualId,
    },
  ];

  const indicators = {
    id: model.indicatorId,
    items: [{ id: model.indexes.fact }, { id: model.indexes.plan }],
  };

  const operands = [
    getOperand({
      type: FILTER_OPERAND_TYPE.LEVEL,
      dim_id: model.dataPeriodId,
      showChildren: false,
      showLeafsOnly: false,
      levels: [1, 3],
      showAllLevelElements: false,
    }),
    getOperand({
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        getOperand({
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataRegionId,
        }),
        getOperand({
          type: FILTER_OPERAND_TYPE.CONST,
          values: [regionId],
        }),
      ],
    }),
    getOperand({
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        getOperand({
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataActualId,
        }),
        getOperand({
          type: FILTER_OPERAND_TYPE.CONST,
          values: ["2"],
        }),
      ],
    }),
  ];

  return olapRequestData2(
    dimIds,
    model.indicatorId,
    {
      modelUuid: model.uuid,
      indicatorId: model.indicatorId,
      includeGaps: false,
      dataRequestCaching: true,
      dimensions,
      indicators,
      dataFilter: {
        type: FILTER_TYPE.AND,
        operands,
      },
    },
    "7fadc25c-b16f-48b9-b992-62f93ed0fb89"
  );
};
