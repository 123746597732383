import React from "react";

import { ReactComponent as StarColor } from "@/assets/StarColor.svg";
import { ReactComponent as StarEmpty } from "@/assets/StarEmpty.svg";

import { useFeedback } from "../../hooks/useFeedback";
import { FeedbackCommonDetailProps } from "../../lib/types";
import {
  FeedbackCommonButton,
  FeedbackCommonStyled,
  RatingStyled,
} from "../Feedback.styled";

export const FeedbackCommonDetail = ({
  callbackFeedback,
}: FeedbackCommonDetailProps) => {
  const { feedbackGrade } = useFeedback();

  return (
    <FeedbackCommonStyled>
      <div className="grade">{feedbackGrade}</div>
      <RatingStyled
        readOnly
        value={+(feedbackGrade?.replace(/,/gim, ".") || 0)}
        isSmallsize={true}
        precision={0.1}
        max={5}
        icon={<StarColor fontSize="inherit" />}
        emptyIcon={<StarEmpty fontSize="inherit" />}
      />
      <FeedbackCommonButton onClick={callbackFeedback}>
        Подробнее
      </FeedbackCommonButton>
    </FeedbackCommonStyled>
  );
};
