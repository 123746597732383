import { Box } from "@mui/material";
import React, { FC, SyntheticEvent, useCallback, useRef } from "react";

import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { CompareIcons } from "@/shared/CompareIcons/ui/CompareIcons";
import { useOpenIncident } from "@/shared/Incident/hooks/useOpenIncident";
import { IncidentCreateButton } from "@/shared/Incident/ui/IncidentCreateButton/IncidentCreateButton";
import { NotifyInWork } from "@/shared/NotifyInWork/NotifyInWork";
import { useRegionPopUp } from "@/shared/RegionPopUp/hooks/useRegionPopUp";
import { Sceleton } from "@/shared/Sceleton/ui/Sceleton";
import {
  Value,
  ValueChange,
  ValueChangeSup,
  ValueIcon,
  ValueNumber,
  ValuePlace,
  ValueUnit,
} from "@/shared/Value/Value.styled";
import { ValueDate } from "@/shared/ValueDate/ValueDate";
import { useIndicatorPassport } from "@/widgets/IndicatorPassport/hooks/useIndicatorPassport";
import {
  indicatorsByOpenPassport,
  indicatorsForPopUpId,
} from "@/widgets/IndicatorPassport/lib/constants";

import { getValueItem, isValueRender } from "../lib/helpers";
import { IGeneralIndicators } from "../lib/types";
import {
  GeneralIndicatorsContent,
  GeneralIndicatorsHeader,
  GeneralIndicatorsItem,
  GeneralIndicatorsItemContent,
  GeneralIndicatorsItemIcon,
  GeneralIndicatorsItemTitle,
  GeneralIndicatorsItemTitleWide,
  GeneralIndicatorsSubHeader,
  GeneralIndicatorsWrapper,
  SubjectPlace,
  ValueDateWrapper,
  ValueItem,
  ValueText,
} from "./GeneralIndicators.styled";

export const GeneralIndicators: FC<IGeneralIndicators> = ({
  title,
  subtitle,
  values,
  columns,
  typography,
  indicatorsFontStyle,
  isOpenPopUp,
  popUpId,
  generalFetching = false,
  isNotGetDate = false,
}) => {
  const { openIndicatorPassport } = useIndicatorPassport();
  const showRef = useRef<HTMLDivElement | null>(null);
  const { move, handleMove, handleChange } = useOpenIncident();
  const { PopUp, PopUpButton }: any = useRegionPopUp(
    popUpId,
    isOpenPopUp,
    title,
    undefined,
    undefined,
    720
  );
  const { compareLabel, isCheckRfFoPage } = useRegions();

  const isCheckOpenIndicator = (event: SyntheticEvent) => {
    const isCheckElement = (event.target as HTMLElement)?.tagName === "SPAN";
    return !!indicatorsForPopUpId?.[popUpId || ""] && isCheckElement;
  };

  const handleOpenPassport = useCallback(
    (indicatorId: string) => {
      openIndicatorPassport(indicatorId);
    },
    [openIndicatorPassport]
  );

  if (generalFetching) {
    return <Sceleton type="line" />;
  }

  return (
    <GeneralIndicatorsWrapper
      isOpenPopUp={isOpenPopUp}
      isTotalTitle={!!title?.match(/^Общие/gim)}
      rows={values?.length}
      ref={showRef}
      onMouseEnter={() => handleMove(true)}
      onMouseLeave={() => handleMove(false)}
    >
      <Box>
        <GeneralIndicatorsHeader
          typography={typography}
          onClick={(event) => {
            isCheckOpenIndicator(event) &&
              handleOpenPassport(indicatorsForPopUpId[popUpId || ""]);
          }}
          underline={!!indicatorsForPopUpId?.[popUpId || ""]}
          sx={isOpenPopUp ? { paddingTop: "0.875rem" } : undefined}
        >
          {!isOpenPopUp && <span>{title}</span>}
          {!isOpenPopUp && move && (
            <IncidentCreateButton
              posRight={60}
              onClick={(event) => {
                handleChange(
                  event,
                  showRef?.current,
                  title,
                  !isNotGetDate ? (values?.[0] as any)?.date : null
                );
              }}
            />
          )}
          {!isOpenPopUp && <PopUpButton />}
        </GeneralIndicatorsHeader>
        {subtitle && (
          <GeneralIndicatorsSubHeader typography={typography}>
            {subtitle}
          </GeneralIndicatorsSubHeader>
        )}
      </Box>

      <GeneralIndicatorsContent
        isOpenPopUp={isOpenPopUp}
        columns={columns}
        rows={values?.length}
      >
        {values.map((item, index) =>
          isValueRender(item) ? (
            item.render()
          ) : (
            <GeneralIndicatorsItem key={item.key}>
              {item.icon && (
                <GeneralIndicatorsItemIcon>
                  {item.icon}
                </GeneralIndicatorsItemIcon>
              )}
              <GeneralIndicatorsItemContent
                sx={
                  item.align === "top"
                    ? { justifyContent: "flex-start" }
                    : undefined
                }
              >
                {item.place || item.date ? (
                  <SubjectPlace>
                    {item.title && (
                      <GeneralIndicatorsItemTitle
                        isOpenPopUp={isOpenPopUp}
                        typography={typography}
                        onClick={() => {
                          indicatorsByOpenPassport.includes(item.key) &&
                            handleOpenPassport(item?.key);
                        }}
                        underline={indicatorsByOpenPassport.includes(item.key)}
                      >
                        {item.title}
                      </GeneralIndicatorsItemTitle>
                    )}
                    {item.titleWide && (
                      <GeneralIndicatorsItemTitleWide isOpenPopUp={isOpenPopUp}>
                        {item.titleWide}
                      </GeneralIndicatorsItemTitleWide>
                    )}
                    {(item.place ||
                      typeof item.placeChange !== "undefined") && (
                      <div>
                        {item.place &&
                          (["string", "number"].includes(typeof item?.place) ? (
                            <ValuePlace isOpenPopUp={isOpenPopUp}>
                              {item.place} место в {compareLabel}&nbsp;
                            </ValuePlace>
                          ) : (
                            <ValuePlace isOpenPopUp={isOpenPopUp}>
                              {item.place}
                            </ValuePlace>
                          ))}
                        {typeof item.placeChange !== "undefined" &&
                          !isCheckRfFoPage.isRF && (
                            <ValueChangeSup
                              isOpenPopUp={isOpenPopUp}
                              difference={item.placeChangeRate}
                            >
                              {item.placeChange}
                            </ValueChangeSup>
                          )}
                      </div>
                    )}
                  </SubjectPlace>
                ) : (
                  <GeneralIndicatorsItemTitle
                    isOpenPopUp={isOpenPopUp}
                    typography={typography}
                  >
                    <div className="isNotFoundName">
                      {item.title}
                      {item.inWork && <NotifyInWork />}
                    </div>
                  </GeneralIndicatorsItemTitle>
                )}

                <Value rateColor={item.valueColor}>
                  <ValueNumber>
                    {((item.valueHL ?? 0) > 0 || (item.valueHL ?? 0) < 0) && (
                      <ValueIcon isOpenPopUp={isOpenPopUp}>
                        {<CompareIcons value={item.valueHL!!} />}
                      </ValueIcon>
                    )}
                    <ValueText
                      fontStyle={
                        index >= values.length / 2 && indicatorsFontStyle
                          ? "italic"
                          : undefined
                      }
                      valueColor={item.valueColor}
                    >
                      {isCheckRfFoPage.isAll &&
                      item.key === "7" &&
                      !item.inWork ? null : (
                        <ValueItem
                          isOpenPopUp={isOpenPopUp}
                          valueColor={item.valueColor}
                        >
                          {getValueItem(item)}
                        </ValueItem>
                      )}
                      {item.unit && (
                        <ValueUnit
                          noMargin={isCheckRfFoPage.isAll && item.key === "7"}
                          isOpenPopUp={isOpenPopUp}
                        >
                          {item.unit}
                        </ValueUnit>
                      )}
                    </ValueText>
                  </ValueNumber>

                  {item.valueChange && (
                    <ValueChange>
                      {item.valueChange}
                      <ValueUnit isOpenPopUp={isOpenPopUp}>п.п.</ValueUnit>
                    </ValueChange>
                  )}
                </Value>

                {item.topPlace && item.topPlace?.length > 0 && (
                  <div
                    style={{
                      display: "grid",
                      gap: 4,
                      color: "rgba(255, 255, 255, 0.7)",
                      fontWeight: 400,
                      fontSize: "0.75rem",
                    }}
                  >
                    {item?.topPlace.map((item: any, index) => (
                      <div key={index}>
                        {item.place} место - {item.name.name}
                      </div>
                    ))}
                  </div>
                )}

                <ValueDateWrapper>
                  <ValueDate isOpenPopUp={isOpenPopUp}>{item.date}</ValueDate>
                </ValueDateWrapper>
              </GeneralIndicatorsItemContent>
            </GeneralIndicatorsItem>
          )
        )}
      </GeneralIndicatorsContent>
      <PopUp />
    </GeneralIndicatorsWrapper>
  );
};
