import { useTheme } from "@mui/material";
import ReactECharts, { EChartsInstance } from "echarts-for-react";
import findLastIndex from "lodash/findLastIndex";
import { FC, useEffect, useMemo, useRef, useState } from "react";

import { ContentScrollbar } from "@/app/AppComponent/ui/App.styled";
import { CustomScrollbar } from "@/shared/CustomScrollbar/ui/CustomScrollbar";
import { formatNumber } from "@/shared/lib/heplers";
import { StatisticElementGraph } from "@/shared/StatisticElementGraph/ui/StatisticElementGraph";

import {
  ecOpt,
  getDataLabelStyle,
  getPlanLabelStyle,
  getXaxisLabelStyle,
} from "../lib/areachart";
import { titlesByCheckFormatter } from "../lib/constants";
import { IProjectImpactCard } from "../lib/types";
import {
  ChartArea,
  ReactEChartsWrapper,
  StackStyled,
  TextBlock,
  TypographyStyled,
  Unit,
} from "./GraphCard.styled";

export const GraphCard: FC<IProjectImpactCard> = ({
  title,
  graphData,
  isOnlyOne,
  periods,
  isOnlyProject = false,
}) => {
  const theme = useTheme();
  const getLastFactIndex = () => {
    const lastFactIndex = findLastIndex(
      graphData,
      ({ fact }) => Boolean(fact) || fact === 0
    );
    return lastFactIndex < 0 ? 0 : lastFactIndex;
  };
  const [selectedIndex, setSelectedIndex] = useState(getLastFactIndex());
  const echartRef = useRef<EChartsInstance | null>(null);
  const minWidthLabel = 110;

  // хэндлер на выделение периода
  useEffect(() => {
    const echartInstance = echartRef.current?.getEchartsInstance();

    if (echartInstance) {
      const zr = echartInstance.getZr();
      zr.on("click", (params: any) => {
        const pointInPixel = [params.offsetX, params.offsetY];
        const pointInGrid = echartInstance.convertFromPixel(
          "grid",
          pointInPixel
        );
        setSelectedIndex(pointInGrid[0]);
      });
    }
  }, [echartRef]);

  const getFormatter = useMemo(() => {
    return titlesByCheckFormatter.includes(title)
      ? { formatter: ({ value }: any) => formatNumber(value, undefined, 0) }
      : {};
  }, [title]);

  const chartOpt = {
    ...ecOpt(theme),
    xAxis: [
      {
        ...ecOpt(theme).xAxis,
        data: periods?.map((period: string, index: number) => ({
          value: period,
          textStyle: getXaxisLabelStyle(index, theme, selectedIndex),
        })),
      },
      {
        ...ecOpt(theme).xAxis,
        data: graphData?.map(({ plan }, idx) => ({
          value: formatNumber(
            String(plan)?.replace(/\./gi, ","),
            undefined,
            Object.keys(getFormatter)?.length ? 0 : 1
          ),
          textStyle: getPlanLabelStyle(idx, theme, selectedIndex),
        })),
        position: "top",
        offset: 5,
      },
    ],
    series: ecOpt(theme).series.map((graphItem) => ({
      ...graphItem,
      data: graphData?.map(({ plan, fact }, index) => ({
        value: graphItem.name === "План" ? plan : fact,
        label: {
          ...getDataLabelStyle(graphItem.name, index, theme, selectedIndex),
          ...getFormatter,
          show: graphItem.name !== "План",
        },
      })),
    })),
  };

  const unit = graphData?.[selectedIndex]?.unit;
  const percent = graphData?.[selectedIndex]?.percent ?? NaN;
  const color = graphData?.[selectedIndex]?.color;

  const widthLabel = useMemo(() => {
    const isCheckAmount = periods?.length < 11;
    return isOnlyOne || isCheckAmount
      ? "100%"
      : periods?.length * minWidthLabel;
  }, [periods, isOnlyOne, minWidthLabel]);

  return (
    <StackStyled
      direction="row"
      isOnlyOne={isOnlyOne}
      isOnlyProject={isOnlyProject}
    >
      <ChartArea>
        <TextBlock>
          <TypographyStyled variant="subtitle1">
            {title}
            {`${unit ? ", " : ""}`}
            <Unit>{unit}</Unit>
          </TypographyStyled>

          <StatisticElementGraph percent={percent} color={color} />
        </TextBlock>

        <ContentScrollbar>
          <CustomScrollbar>
            <ReactEChartsWrapper widthLabel={widthLabel} isOnlyOne={isOnlyOne}>
              <ReactECharts
                ref={echartRef}
                option={chartOpt}
                style={{ overflowY: "hidden", height: "100%", width: "100%" }}
              />
            </ReactEChartsWrapper>
          </CustomScrollbar>
        </ContentScrollbar>
      </ChartArea>
    </StackStyled>
  );
};
