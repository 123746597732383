import React from "react";

import { useProjectFinances } from "@/pages/ProjectsPage/hooks/useProjectFinances";
import { IUnitMeasureGroupProps } from "@/pages/ProjectsPage/lib/types";

import {
  ToggleButtonGroupStyled,
  ToggleButtonStyled,
} from "./ProjectFinancesTable.styled";

export const UnitMeasureGroup = ({
  unitMeasure,
  handleUnitMeasure,
}: IUnitMeasureGroupProps) => {
  const { unitsMeasureKeys } = useProjectFinances();
  return (
    <ToggleButtonGroupStyled
      size="small"
      exclusive
      value={unitMeasure}
      onChange={handleUnitMeasure}
      aria-label="Выбор единицы измерения"
    >
      {unitsMeasureKeys.map((unitName) => (
        <ToggleButtonStyled
          value={unitName}
          aria-label={unitName}
          key={unitName}
        >
          {unitName}
        </ToggleButtonStyled>
      ))}
    </ToggleButtonGroupStyled>
  );
};
