import { styled } from "@mui/material";

import { TabPanelUnstyled, TabUnstyled } from "@/shared/Tabs/Tabs.styled";

export const TabPanelStyled = styled(TabPanelUnstyled, {
  shouldForwardProp: (prop) => !["first"].includes(prop.toString()),
})<{ first?: boolean }>(({ theme, first }) => ({
  boxSizing: "border-box",
  padding: theme.spacing(3),
  background: theme.palette.tabs.selected,
  borderRadius: theme.spacing(first ? 0 : 2, 2, 2),
  height: "100%",
}));

export const TabStyled = styled(TabUnstyled)(({ theme }) => ({
  width: "initial",
  padding: theme.spacing(0, 3.5),
}));

export const Description = styled("span")(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  opacity: 0.7,
}));
