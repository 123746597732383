import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { COMPARE_TYPE, ICompareState } from "../lib/types";

export const initialState: ICompareState = {
  value: COMPARE_TYPE.RF,
  disabled: false,
};

const compareSlice = createSlice({
  name: "controls/compare",
  initialState,
  reducers: {
    compareToggle: (state, action: PayloadAction) => {
      state.value =
        state.value === COMPARE_TYPE.RF ? COMPARE_TYPE.FO : COMPARE_TYPE.RF;
    },
    compareSetValue: (state, action: PayloadAction<COMPARE_TYPE>) => {
      state.value = action.payload ?? state.value;
    },
    compareSetDisabled: (state, action: PayloadAction<boolean>) => {
      state.disabled = action.payload ?? state.disabled;
    },
  },
});

export const { compareToggle, compareSetValue, compareSetDisabled } =
  compareSlice.actions;
export const reducer = compareSlice.reducer;

export default compareSlice;
