import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { REGIONS_STARTER } from "@/shared/lib/constants";
import { prepareAttributes } from "@/shared/lib/heplers";
import { IRegionAttributeVals } from "@/store/scheme/olap";

import { IAllRegionsState } from "../../lib/types";
import { getRegions } from "../api/api";

export const initialState: IAllRegionsState = {
  items: undefined,
  fetching: true,
  error: null,
};

export const extraActionsRegion = {
  get: createAsyncThunk(
    "regions/get",
    async (districts: string[]) => await getRegions(districts)
  ),
};

const regionSlice = createSlice({
  name: "regions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActionsRegion.get.pending, (state) => {
        state.items = undefined;
        state.fetching = true;
      })
      .addCase(extraActionsRegion.get.fulfilled, (state, action) => {
        state.items = [
          REGIONS_STARTER,
          ...action.payload.map((item) => ({
            ...item,
            attributeVals: prepareAttributes<IRegionAttributeVals>(
              item.attributeVals
            ),
          })),
        ];
        state.fetching = false;
        state.error = null;
      })
      .addCase(extraActionsRegion.get.rejected, (state, action) => {
        state.fetching = false;
        state.error = action.error;
      });
  },
});

export const reducer = regionSlice.reducer;

export default regionSlice;
