import { EChartsOption } from "echarts-for-react/src/types";
import { Feature } from "geojson";
import { useMemo } from "react";

import { getMapBackgroundColor } from "@/pages/MainPage/lib/helpers";
import { REGION_COUNTRY_CODE } from "@/shared/lib/constants";
import { useTransitionPage } from "@/shared/Table/hooks/useTransitionPage";
import { TAB_ID } from "@/store/scheme/region";

import { getOffsetLabel } from "../lib/heplers";
import { useMapOptionT } from "../lib/types";
import {
  COUNTRY_MAP_BY_DISTRICTS_ID,
  COUNTRY_MAP_BY_REGIONS_ID,
} from "../model/api/scheme";

export const useMapOptions = ({
  activeMapId,
  theme,
  selectedTabId,
  achEnabled,
  currentData,
  vdlValues,
  districtIds,
  regionIds,
  districtId,
  getRegionCenterById,
  selectedSubjectId,
  setMapValues,
  setSelectedSubjectId,
  navigate,
  location,
}: useMapOptionT): {
  option: EChartsOption;
  onEvents: {
    click: (params: any) => void;
    dblclick: (params: any) => void;
  };
} => {
  const { transitionTab } = useTransitionPage();
  const option: EChartsOption = useMemo(() => {
    switch (activeMapId) {
      case COUNTRY_MAP_BY_DISTRICTS_ID:
      case COUNTRY_MAP_BY_REGIONS_ID:
        const scaleOpt = { aspectScale: 1, zoom: 1.2 };
        const textStyle = {
          fontFamily: "GolosText",
          color: theme.palette.primary.contrastText,
          textShadowColor: "rgba(0, 0, 0, 0.53)",
          textShadowBlur: 7,
          lineHeight: 25,
        };
        const selectedAreaStyle = {
          borderColor: theme.palette.geo.select?.border,
          borderWidth: 2,
        };
        const getSeriesItemStyle = (value: number) => {
          return {
            areaColor: getMapBackgroundColor(
              theme,
              value,
              selectedTabId,
              achEnabled
            ),
          };
        };

        return {
          series: [
            {
              ...scaleOpt,
              name: currentData?.name,
              type: "map",
              map: activeMapId,
              select: {
                itemStyle: {
                  ...selectedAreaStyle,
                  color: undefined,
                },
              },
              data: currentData?.jsonData.features?.map(
                ({ properties }: Feature) => {
                  const { id, name } = properties ?? {};
                  const value = Math.round(vdlValues[id]?.value ?? NaN);
                  const disabled =
                    activeMapId === COUNTRY_MAP_BY_DISTRICTS_ID
                      ? !districtIds.includes(id)
                      : !regionIds.includes(id);

                  return {
                    id: id,
                    name: districtId ? getRegionCenterById(id) : name,
                    value: value || "-",
                    selected: selectedSubjectId === id,
                    itemStyle: {
                      ...getSeriesItemStyle(value),
                      ...(selectedSubjectId === id ? selectedAreaStyle : {}),
                    },
                    select: {
                      disabled,
                    },
                    emphasis: {
                      disabled,
                      itemStyle: {
                        ...selectedAreaStyle,
                        areaColor: "none",
                      },
                    },
                    label: {
                      show: true,
                      offset: getOffsetLabel(
                        districtId ? getRegionCenterById(id) : name
                      ),
                      formatter: id
                        ? `{name|{b}}\n{value|${setMapValues(id)}}`
                        : "",
                      rich: {
                        name: {
                          ...textStyle,
                          fontSize: districtId ? 12 : 14,
                          fontWeight: districtId
                            ? theme.typography.fontWeightMedium
                            : theme.typography.fontWeightSemiBold,
                        },
                        value: {
                          ...textStyle,
                          fontSize: districtId ? 14 : 16,
                          fontWeight: 600,
                        },
                      },
                    },
                  };
                }
              ),
            },
          ],
        };

      default:
        return null;
    }
  }, [
    activeMapId,
    currentData?.name,
    currentData?.jsonData.features,
    selectedTabId,
    achEnabled,
    vdlValues,
    districtId,
    districtIds,
    getRegionCenterById,
    selectedSubjectId,
    setMapValues,
  ]);

  const onEvents = useMemo(() => {
    const ids = districtId ? regionIds : districtIds;

    return {
      click: (params: any) => {
        if (!params?.data?.id) return;

        // клик возможен тольо по разрешенных субъектов
        if (!ids.includes(params?.data?.id)) return;

        if (params.data.id !== selectedSubjectId) {
          setSelectedSubjectId(
            selectedSubjectId === params.data.id
              ? districtId || REGION_COUNTRY_CODE
              : params.data.id
          );

          if (!districtId) navigate(`/selectedDistrict/${params.data.id}`);

          if (districtId) {
            navigate(`/district/${districtId}/region/${params.data.id}`);
          }
        }
      },
      dblclick: (params: any) => {
        if (!params?.data?.id) return;

        // клик возможен тольо по разрешенных субъектов
        if (!ids?.includes(params?.data?.id)) return;

        if (!districtId) navigate(`/district/${params.data.id}`);

        if (districtId && selectedTabId !== TAB_ID.RATE) {
          let tabName = transitionTab(selectedTabId);
          navigate(`/region/${params.data.id}/${tabName}`, {
            state: { backLink: location.pathname },
          });
        }
      },
    };
  }, [
    selectedSubjectId,
    setSelectedSubjectId,
    districtId,
    districtIds,
    regionIds,
    navigate,
    selectedTabId,
    location.pathname,
  ]);

  return { option, onEvents };
};
