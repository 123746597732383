import { AxiosResponse } from "axios";
import { v4 } from "uuid";

import {
  IAttributeVals,
  IDataFilterOperandAny,
  IDataFilterWithOperands,
  IIndicatorAttributeVals,
  IOlapRequest,
  IOlapRequestData2,
  IOlapRequestDimensionData,
  IOlapResponse,
  IOlapResponseData2,
  IOlapResponseDataItem,
  IOlapResponseDimensionItemDimWithVals,
  IOlapResponseDimensionsData,
  isDataFilterWithOperands,
  REQUEST_TYPE,
} from "../scheme/olap";
import client from "./client";

export const getOperand = (item: IDataFilterOperandAny) => ({
  version: 1,
  ...item,
});

export const getOperands = (
  operands: (IDataFilterOperandAny | IDataFilterWithOperands)[]
) => {
  function getItem(item: any) {
    return item?.operands?.length
      ? {
          ...getOperand(item),
          operands: item?.operands.map((op: IDataFilterWithOperands) =>
            getItem(op)
          ),
        }
      : getOperand(item as IDataFilterOperandAny);
  }

  return operands.map((op) => getItem(op));
};

export const prepareResponseDimensionData = <
  A extends IAttributeVals = IIndicatorAttributeVals
>(
  dimId: string,
  response: AxiosResponse<IOlapResponseDimensionsData<A>>
) => {
  return response.data.responses[0].data.items;
};

export const prepareResponseData2 = <
  A extends IAttributeVals = IIndicatorAttributeVals
>(
  dimIds: string[],
  indicatorId: string,
  response: AxiosResponse<IOlapResponseData2<A>>
): IOlapResponseDimensionItemDimWithVals<A>[] => {
  const { data, dimensionItems } = response.data.responses[0].data ?? {};

  if (dimIds.length < 1) {
    return [];
  }

  const getItem = (item: IOlapResponseDataItem, dimId: string) => {
    const id = item.dims[dimId];
    const dimItem = dimensionItems[dimId][id];

    return {
      ...dimItem,
      id,
      dims: {},
      indicatorVals: item.indicatorVals,
    };
  };

  return data
    ? data.map((item) => {
        const dims = [];

        for (const dimId of dimIds) {
          dims.push({ dimId, ...getItem(item, dimId) });
        }

        const result = {
          ...dims.shift()!,
          dims: {} as IOlapResponseDimensionItemDimWithVals<A>["dims"],
        };

        for (const dim of dims) {
          result.dims[dim.dimId] = dim;
        }

        return result;
      })
    : [];
};

export const olapRequest = ({
  type,
  requestId,
  params: { dataSort, dataFilter, ...other },
}: IOlapRequest) => {
  const params: IOlapRequest["params"] = {
    dataRequestCaching: true,
    ...other,
  };

  if (dataSort) {
    params.dataSort = dataSort.map((item) => ({
      ...item,
      version: 1,
    }));
  }

  if (dataFilter) {
    params.dataFilter = {
      ...dataFilter,
      version: 1,
    };

    if (isDataFilterWithOperands(dataFilter)) {
      (params.dataFilter as IDataFilterWithOperands).operands =
        dataFilter.operands;
    }
  }

  const request = {
    requestId: requestId ?? v4(),
    type,
    params,
  };

  return client.post<IOlapResponse>("/api/data_api/v1.0/olapdata", {
    requests: [request],
  });
};

export const olapRequestDimensionData = <A extends IAttributeVals>(
  dimId: string,
  params: IOlapRequestDimensionData["params"],
  requestId?: string
) =>
  olapRequest({ type: REQUEST_TYPE.DIMENSION_DATA, params, requestId }).then(
    (response) =>
      prepareResponseDimensionData<A>(
        dimId,
        response as AxiosResponse<IOlapResponseDimensionsData<A>>
      )
  );

export const olapRequestData2 = <A extends IAttributeVals>(
  dimIds: string[],
  indicatorId: string,
  params: IOlapRequestData2["params"],
  requestId?: string
) =>
  olapRequest({ type: REQUEST_TYPE.DATA2, params, requestId }).then(
    (response) =>
      prepareResponseData2<A>(
        dimIds,
        indicatorId,
        response as AxiosResponse<IOlapResponseData2<A>>
      )
  );
