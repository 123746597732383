import { TAB_ID } from "@/store/scheme/region";

export const checkTabsForBacklog = (id: TAB_ID) => {
  const isCheckTab = [
    TAB_ID.VDL_PROJECTS,
    TAB_ID.VDL,
    TAB_ID.PROJECTS,
  ].includes(id);
  return isCheckTab;
};
