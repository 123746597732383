import { getOperand, olapRequestDimensionData } from "@/store/api/core";
import { FILTER_OPERAND_TYPE, MODEL_IDS, MODELS } from "@/store/scheme/olap";

export const getRegions = (districts: string[]) => {
  const model = MODELS[MODEL_IDS.KPI_SOC_ECONOMY];

  return olapRequestDimensionData(
    model.dataRegionId,
    {
      modelUuid: model.uuid,
      dimensionId: model.dataRegionId,
      dataFilter: getOperand({
        type: FILTER_OPERAND_TYPE.PARENT,
        dim_id: model.dataRegionId,
        values: districts,
      }),
      includeAttributesByCodes: [
        "name",
        "code",
        "code_fk",
        "code_fns",
        "adm_center",
        "NAME",
        "SHORT_NAME",
        "SORT_ORDER",
      ],
    },
    "cf85868d-4217-46e8-b22c-8335bf423ad2"
  );
};
