import ExpandIcon from "@assets/svg_components/ExpandIcon";
import CloseIcon from "@mui/icons-material/Close";
import { FC } from "react";

import { IRegionPopUpButtonProps } from "../lib/types";
import { RegionPopUpButtonStyled } from "./RegionPopUpButton.styled";

export const RegionPopUpButton: FC<IRegionPopUpButtonProps> = ({
  sx,
  open,
  handleOpen,
  isChangePosition = false,
  ...props
}) => {
  const icon = open ? <CloseIcon /> : <ExpandIcon />;
  return (
    <RegionPopUpButtonStyled
      {...props}
      sx={sx}
      isChangePosition={isChangePosition}
      onClick={(event) => {
        event.stopPropagation();
        handleOpen && handleOpen();
      }}
      isOpen={open}
    >
      {icon}
    </RegionPopUpButtonStyled>
  );
};
