import { FormLabel, Input, Select, styled, TextField } from "@mui/material";

export const FormLabelStyled = styled(FormLabel)(({ theme }) => ({
  display: "block",
  paddingTop: theme.spacing(3.5),
  paddingBottom: theme.spacing(1.5),
  fontWeight: 400,
  fontSize: theme.spacing(2),
  lineHeight: theme.spacing(2),
  color: theme.palette.text.primary,
}));

export const InputStyled = styled(Input)(({ theme }) => ({
  display: "flex",
  padding: theme.spacing(0.5, 1, 0.5, 1.5),
  background: "rgba(42, 47, 62, 0.5)",
  border: `1px solid ${theme.palette.table.backgroundFocused}`,
  borderRadius: theme.spacing(1),
  "&.Mui-disabled:before": {
    borderBottom: "none",
    borderBottomStyle: "none",
  },
  "& > input.Mui-disabled": {
    color: theme.palette.text.unitText,
    WebkitTextFillColor: theme.palette.text.unitText,
    fontSize: theme.spacing(2),
    lineHeight: theme.spacing(2.5),
    textOverflow: "ellipsis",
  },
}));

export const ScreenshotStyled = styled("p")(({ theme }) => ({
  display: "block",
  paddingTop: theme.spacing(3.5),
  paddingBottom: theme.spacing(3),
  fontWeight: 400,
  fontSize: theme.spacing(2),
  lineHeight: theme.spacing(2.5),
  color: theme.palette.text.primary,
}));

export const SelectStyled = styled(Select)(({ theme }) => ({
  fontWeight: 400,
  fontSize: theme.spacing(2),
  lineHeight: theme.spacing(2),
  background: theme.palette.button.incidentSelectColor,
  border: `1px solid ${theme.palette.table.backgroundFocused}`,
  color: theme.palette.text.unitText,
  "&.MuiInputBase-root": {
    border: "none",
  },
  "& > .MuiSelect-select": {
    boxSizing: "border-box",
    minHeight: theme.spacing(5),
    padding: theme.spacing(1.25, 1.5),
  },
  "& .MuiSelect-icon": {
    marginRight: theme.spacing(),
    color:
      theme.palette.mode === "dark"
        ? theme.palette.icon.iconProject
        : theme.palette.tabs.textColorSelect,
  },
}));

export const PlaceholderStyled = styled("p")(({ theme }) => ({
  color: theme.palette.indicatorCard.tooltipBg,
}));

export const TextFieldStyled = styled(TextField)(({ theme }) => ({
  background: theme.palette.button.incidentSelectColor,
  borderRadius: theme.spacing(),
  fontWeight: 400,
  fontSize: theme.spacing(2),
  lineHeight: theme.spacing(2.5),
  color: theme.palette.text.unitText,
  "& > .MuiInputBase-root": {
    minHeight: theme.spacing(10),
    "& > .MuiOutlinedInput-input": {
      marginBottom: "auto",
      "&::placeholder": {
        color: theme.palette.indicatorCard.tooltipBg,
      },
    },
  },
  "& > .MuiFormHelperText-root": {
    position: "absolute",
    bottom: 0,
  },
}));
