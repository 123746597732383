import { Timeline } from "@mui/icons-material";
import TableChartIcon from "@mui/icons-material/TableChart";
import { ReactNode } from "react";

import { Item } from "@/shared/Footer/ui/Footer.styled";
import { stickyTop } from "@/shared/ProjectResultsGroup/lib/helpers";
import {
  PROJECT_FINANCES,
  PROJECT_INDICATORS,
} from "@/shared/Table/model/api/scheme";
import { IColumn } from "@/shared/TableBase/lib/types";
import { ICell } from "@/shared/TableBase/lib/types";

import { IICONS } from "./types";

export const ICONS: IICONS[] = [
  { id: "1", icon: <TableChartIcon /> },
  { id: "2", icon: <Timeline /> },
];

export const STICKY_INDEX_FINANCE = 3;
export const STICKY_INDEX_INDICATOR = 5;

export const projectFinances = [
  PROJECT_FINANCES.FEDERAL_BUDGET,
  PROJECT_FINANCES.REGIONAL_BUDGET,
  PROJECT_FINANCES.NON_BUDGET,
  PROJECT_FINANCES.TOTAL,
];

export const nextYearsDefault = [
  {
    id: "next-year",
  },
  {
    id: "after-next-year",
  },
];

export const defaultResult: Record<PROJECT_FINANCES, ICell[]> = {
  [PROJECT_FINANCES.FEDERAL_BUDGET]: [] as ICell[],
  [PROJECT_FINANCES.REGIONAL_BUDGET]: [] as ICell[],
  [PROJECT_FINANCES.NON_BUDGET]: [] as ICell[],
  [PROJECT_FINANCES.TOTAL]: [] as ICell[],
};

export const defaultIndicators: Record<PROJECT_INDICATORS, ICell[]> = {
  [PROJECT_INDICATORS.INVESTMENTS]: [] as ICell[],
  [PROJECT_INDICATORS.PLACES]: [] as ICell[],
  [PROJECT_INDICATORS.PROFIT]: [] as ICell[],
  [PROJECT_INDICATORS.SALARY]: [] as ICell[],
};

export const projectIndicators = [
  PROJECT_INDICATORS.INVESTMENTS,
  PROJECT_INDICATORS.PLACES,
  PROJECT_INDICATORS.PROFIT,
  PROJECT_INDICATORS.SALARY,
];

export const defaultColumns = (isCountData: boolean = true) => {
  const firstRow: IColumn[] = [
    {
      id: "number",
      label: "№",
      width: 50,
    },
    {
      id: "short_name",
      label: "Наименование показателя",
      width: 370,
      ...stickyTop(0, 3),
    },
    {
      id: "UNIT",
      label: "Ед. измерения",
      width: 145,
    },
    {
      id: "value",
      label: "Целевое значение",
      width: 125,
    },
    {
      id: "achievement_date",
      label: "Срок достижения",
      width: 125,
    },
  ].map((column) => ({
    ...column,
    rowSpan: 3,
    ...(!isCountData && { height: 80 }),
  }));

  const secondRow: IColumn[] = [];
  const thirdRow: IColumn[] = [];
  return [firstRow, secondRow, thirdRow];
};

// фильтрация периодов до текущего года вкл по кварталам, остальные по годам
export const checkPeriodsByActual = (periods: string[]) => {
  let quarterPeriods: string[] = [];
  let yearPeriods = periods?.reduce((prev, cur) => {
    if (!!cur?.match(/^3/gi)?.length) {
      quarterPeriods.push(cur);
    } else {
      prev.push(cur);
    }
    return prev;
  }, [] as string[]);

  const maxQuarterPeriod = String(
    Math.max(...quarterPeriods?.map(Number))
  ).slice(1, 5);

  yearPeriods = [...yearPeriods]?.filter((period) => {
    const year = period?.slice(1, 5) || "";
    return !(
      quarterPeriods?.filter((quarterPeriod) => {
        return quarterPeriod?.match(year)?.join("");
      })?.length || +year < +maxQuarterPeriod
    );
  });

  return [...quarterPeriods, ...yearPeriods];
};

export const defaultFinancesColumns = () => {
  const firstRow: IColumn[] = [
    {
      id: "number",
      label: "№",
      width: 50,
    },
    {
      id: "short_name",
      label: "Финансовое обеспечение",
      width: 370,
      ...stickyTop(0, 3),
    },
    {
      id: "type_ind_unit_measure",
      label: "Ед. измерения",
      width: 145,
    },
  ].map((column) => ({ ...column, rowSpan: 2 }));

  const secondRow: IColumn[] = [];

  return [firstRow, secondRow];
};

export const getFinanceRowTitle = (indicator: string): string => {
  switch (indicator) {
    case "111":
      return "Федеральный бюджет";
    case "112":
      return "Региональный бюджет";
    case "113":
      return "Частные инвестиции";

    default:
      return "Итого";
  }
};
// Используется в ProjectPage для формирования легенды
export const getFooterComponent = (legends: Record<string, ReactNode>[]) => {
  return [...legends].map(({ name, icon }, index) => {
    return (
      <Item key={index}>
        {icon}
        {name}
      </Item>
    );
  });
};

// Колонки для рисков
export const defaultRisksColumns = [
  {
    id: "problem",
    label: "Группа проблем",
    height: 80,
    width: 340,
  },
  {
    id: "description",
    label: "Описание проблемы",
    height: 80,
    width: 580,
  },
  {
    id: "responsible",
    label: "Ответственный ФОИВ/РОИВ",
    height: 80,
    width: 340,
  },
  {
    id: "solution",
    label: "Решение проблемы",
    height: 80,
    width: 580,
  },
] as IColumn[];
