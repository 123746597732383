import { useTheme } from "@mui/material";
import { FC, SVGProps, useMemo } from "react";

export interface IDynamicsLowProps extends SVGProps<SVGSVGElement> {
  sortType?: "asc" | "desc";
  isLight?: boolean;
}

const SortArrowApp: FC<IDynamicsLowProps> = ({
  children,
  sortType,
  isLight,
  ...props
}) => {
  const theme = useTheme();
  const color = useMemo(() => {
    return isLight && theme.palette.mode === "dark"
      ? theme.palette.text.grey
      : theme.palette.text.primary;
  }, [theme, isLight]);
  return (
    <svg
      width="16"
      height="8"
      viewBox="0 0 16 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.916504 7.16683L7.99984 0.0834961L15.0832 7.16683H0.916504Z"
        fill={sortType === "asc" ? theme.palette.border.selected : color}
        fillOpacity={sortType === "desc" ? "0.5" : ""}
      />
    </svg>
  );
};

export default SortArrowApp;
