import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  InputBase,
  MenuItem,
  Select,
  styled,
  ToggleButton,
} from "@mui/material";

import { Control } from "@/shared/Control/ui/Control";

export const ToggleButtonWrapper = styled(Control)(({ theme }) => ({
  border: `1px solid ${
    theme.palette.mode === "dark"
      ? theme.palette.text.grey
      : theme.palette.table.dividerLight
  }`,
  background:
    theme.palette.mode === "dark"
      ? theme.palette.button.background
      : theme.palette.text.onAccent,
}));

export const DialogStyled = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    backgroundImage: "none",
  },
}));

export const ToggleButtonStyled = styled(ToggleButton)(({ theme }) => ({
  boxSizing: "border-box",
  "&.Mui-selected": {
    backgroundColor: theme.palette.primary.main,
    padding: 7,
  },
  "&:not(.Mui-selected)": {
    padding: 7,
  },
}));

export const DialogTitleStyled = styled(DialogTitle)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: 80,
  padding: theme.spacing(0, 4),
  backgroundColor:
    theme.palette.mode === "dark"
      ? theme.palette.dateBadge.background
      : theme.palette.popup.header,
  fontSize: 22,
  fontWeight: theme.typography.fontWeightBold,
}));

export const DialogContentStyled = styled(DialogContent)(({ theme }) => ({
  overflow: "visible",
  padding: theme.spacing(3, 4),
  paddingTop: theme.spacing(3) + " !important",
  backgroundColor: theme.palette.modal.background,
}));

export const DialogActionsStyled = styled(DialogActions)(({ theme }) => ({
  height: 96,
  padding: theme.spacing(0, 4),
  backgroundColor:
    theme.palette.mode === "dark"
      ? theme.palette.dateBadge.background
      : theme.palette.popup.header,
}));

export const Label = styled("div")(({ theme }) => ({
  fontSize: 16,
  marginBottom: theme.spacing(1.5),
}));

export const ButtonStyled = styled(Button)(({ theme }) => ({
  height: 48,
  fontSize: 16,
  fontWeight: theme.typography.fontWeightMedium,
  textTransform: "capitalize",
  backgroundColor: theme.palette.button.primary,
  "&.Mui-disabled": {
    background:
      theme.palette.mode === "dark"
        ? theme.palette.button.disable
        : theme.palette.text.unitText,
    color: theme.palette.text.unitTextSelected,
  },
  "&:hover": {
    backgroundColor: theme.palette.button.primaryHover,
  },
}));

export const FileTypeBox = styled("div")(({ theme }) => ({
  width: 306,
  marginTop: theme.spacing(4),
}));

export const FileButtonBox = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(3),
}));

export const FileTypeButton = styled(ToggleButton)(({ theme }) => ({
  width: 36,
  height: 36,
  border: `1px solid ${
    theme.palette.mode === "dark"
      ? theme.palette.divider
      : theme.palette.button.divider
  }`,
  background:
    theme.palette.mode === "dark"
      ? theme.palette.button.background
      : theme.palette.background.darkTheme,
  "& > svg": { color: theme.palette.text.primary },
  "&.Mui-selected": {
    backgroundColor: theme.palette.button.primary,
    "&:hover": {
      backgroundColor: theme.palette.button.primaryHover,
    },
  },
  "&:not(.Mui-selected)": {
    color: theme.palette.button.icon,
  },
  "&.Mui-disabled": {
    "& > svg": {
      color: theme.palette.text.grey,
    },
  },
}));

export const FileTypeName = styled("span")<{ selected?: boolean }>(
  ({ theme, selected }) => ({
    marginLeft: theme.spacing(),
    fontWeight: theme.typography.fontWeightMedium,
    opacity: selected ? 1 : 0.7,
  })
);

export const SelectStyled = styled(Select)(({ theme }) => ({
  "& .MuiSelect-icon": {
    marginRight: theme.spacing(),
    color:
      theme.palette.mode === "dark"
        ? theme.palette.icon.iconProject
        : theme.palette.tabs.textColorSelect,
  },
}));

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
  boxSizing: "border-box",
  height: 40,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.button.background,
  border: `1px solid ${theme.palette.button.border}`,
  fontSize: 14,
  opacity: 0.7,
  padding: theme.spacing(0.8, 2),
  cursor: "pointer",
  transition: theme.transitions.create("all"),

  "& .MuiInputBase-input": {
    cursor: "pointer",
  },

  "&:focus": {
    borderColor: "#80bdff",
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    borderRadius: theme.shape.borderRadius,
  },
}));

export const InputAdornmentStyled = styled(InputAdornment)(({ theme }) => ({
  color: theme.palette.button.icon,
}));

export const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  width: 306,
  fontSize: theme.spacing(2),
  borderRadius: theme.spacing(0.5),
  transition: theme.transitions.create("background-color"),
  marginTop: theme.spacing(),
  "&:first-of-type": {
    marginTop: 0,
  },
  "&:hover": {
    backgroundColor: theme.palette.input.hover,
  },
  "&.Mui-selected": {
    backgroundColor: theme.palette.input.selected,
    "&:hover": {
      backgroundColor: theme.palette.input.selected,
    },
  },
}));

export const RfPeriodsWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: theme.spacing(1.5),
  "& > div": {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    padding: theme.spacing(1.5),
    gap: theme.spacing(1.5, 5),
    maxWidth: theme.spacing(48.5),
    background: "#191E28",
    borderRadius: theme.spacing(),
    border: "1px solid #FFFFFF1A",
  },
}));

export const RfPeriodButton = styled("button")<{ isSelected: boolean }>(
  ({ theme, isSelected }) => ({
    width: theme.spacing(7),
    height: theme.spacing(7),
    fontSize: theme.spacing(2),
    lineHeight: theme.spacing(2.5),
    fontWeight: 400,
    cursor: "pointer",
    color: isSelected
      ? theme.palette.text.primary
      : theme.palette.icon.iconFoMap,
    background: isSelected ? "rgba(7, 146, 190, 0.10)" : "#191E28",
    border: isSelected ? `1px solid ${theme.palette.table.rowBorder}` : "none",
    borderRadius: theme.spacing(),
  })
);

export const menuExportStyle = (theme: any) => ({
  "& .MuiList-root": {
    background:
      theme.palette.mode === "dark"
        ? theme.palette.input.background
        : theme.palette.background.darkTheme,
    border: `1px solid ${theme.palette.input.border}`,
    borderRadius: theme.spacing(),
    padding: theme.spacing(),
  },
});
