import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  ButtonProps,
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  styled,
} from "@mui/material";
import { FC } from "react";

export const FormHeader = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  minHeight: theme.spacing(11),
  backgroundColor: theme.palette.table.popUpBackground,
  borderBottom: `1px solid ${theme.palette.table.dividerLight}`,
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.spacing(2.8),
  color: theme.palette.text.main,
  padding: theme.spacing(0, 4),
}));

export const Actions = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: theme.spacing(3),
  "& .MuiButton-root:nth-of-type(3)": {
    padding: theme.spacing(1, 4),
    borderRadius: theme.spacing(0.8),
    backgroundColor: theme.palette.button.primary,
    boxShadow: "none",
  },
}));

export const FormBody = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(0, 4),
  color: theme.palette.toolTip.text,
}));

export const FormFooter = styled(FormHeader)(({ theme }) => ({
  borderBottom: "none",
  borderTop: `1px solid ${theme.palette.table.dividerLight}`,
  "& .MuiButton-root:last-child": {
    padding: theme.spacing(1, 4),
    borderRadius: theme.spacing(0.8),
    backgroundColor: theme.palette.button.primary,
    boxShadow: "none",
  },
}));

export const CloseButtonStyled = styled(Button)(({ theme }) => ({
  backgroundColor: "transparent",
  color: theme.palette.text.primary,
  outline: "none",
  border: "none",
  cursor: "pointer",
}));

export const ButtonAction = styled(Button)<ButtonProps>(
  ({ theme, ...props }) => ({
    color: theme.palette.modal.text,
    textTransform: "none",
    fontSize: theme.spacing(2),
  })
);

export const CheckBoxStyled = styled(Checkbox)<CheckboxProps>(
  ({ theme, ...props }) => ({
    width: theme.spacing(5.5),
    height: theme.spacing(5.5),
    "&.MuiButtonBase-root.MuiCheckbox-root": {
      color: "#626A73 !important",
    },
    "&.MuiButtonBase-root.MuiCheckbox-root.Mui-checked": {
      color: `${theme.palette.button.primary} !important`,
    },
    "&.MuiButtonBase-root.MuiCheckbox-root.Mui-disabled": {
      color: `${theme.palette.button.disable} !important`,
    },
  })
);

export const FormControlLabelStyled = styled(FormControlLabel)(({ theme }) => ({
  color: theme.palette.modal.text,
  "& .MuiFormControlLabel-label": {
    display: "block",
    marginTop: theme.spacing(0.5),
    fontSize: theme.spacing(2),
    "&.Mui-disabled": {
      color: theme.palette.text.primary,
    },
  },
}));

export const CloseButton: FC<ButtonProps> = ({ ...props }) => {
  return (
    <CloseButtonStyled {...props}>
      <CloseIcon />
    </CloseButtonStyled>
  );
};
