import { FC } from "react";

import { useModal } from "@/shared/Modal/hooks/useModal";

import { IMainContainerProps } from "../lib/types";
import { PageContentStyled } from "./PageContent.styled";

export const PageContent: FC<IMainContainerProps> = ({ children }) => {
  const { componentModal } = useModal();
  return (
    <PageContentStyled>
      {children}
      {componentModal}
    </PageContentStyled>
  );
};
