import { useCallback } from "react";

import { useTabs } from "@/app/hooks/useTabs";
import { useAppSelector } from "@/store/redux/hooks";
import {
  IPreparedOlapResponseDimensionItemDim,
  IRegionAttributeVals,
} from "@/store/scheme/olap";
import { TAB_ID } from "@/store/scheme/region";

export const useRegionPageTabs = () => {
  const { enabled: achEnabled } = useAppSelector(
    (state) => state.controls.achievement
  );
  const {
    requestAchievementsTabs,
    requestMainAchievementsTabs,
    setUrlByTab,
    updateCurrentTab,
    limitedForAnalystTabs,
  } = useTabs();

  const requestAllAchievementsTabs = useCallback(
    (
      id: string,
      district: IPreparedOlapResponseDimensionItemDim<IRegionAttributeVals> | {}
    ) => {
      requestAchievementsTabs(id, district);
      requestMainAchievementsTabs(id, district);
    },
    [requestMainAchievementsTabs, requestAchievementsTabs]
  );

  const updateTabAndSetUrl = useCallback(
    ({
      tabByUrl = null,
      tabByUpdate = null,
    }: Partial<Record<string, TAB_ID | null>>) => {
      tabByUrl && setUrlByTab(tabByUrl);
      tabByUpdate && updateCurrentTab(tabByUpdate);
    },
    [setUrlByTab, updateCurrentTab]
  );

  const switchOverTabs = (tabId: TAB_ID) => {
    const isCheckTabId = Object.values(TAB_ID).find(
      (item) => item === tabId
    ) as TAB_ID;

    if (tabId && limitedForAnalystTabs(tabId as TAB_ID)) {
      updateTabAndSetUrl({
        tabByUrl: TAB_ID.RATE,
        tabByUpdate: TAB_ID.VDL_PROJECTS,
      });
    } else if (isCheckTabId) {
      updateTabAndSetUrl({ tabByUpdate: tabId });
    }
  };

  return {
    achEnabled,
    switchOverTabs,
    updateTabAndSetUrl,
    limitedForAnalystTabs,
    requestAllAchievementsTabs,
  };
};
