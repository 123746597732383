import { useRouter } from "@app/hooks/useRouter";
import { useTheme } from "@mui/material";
import Stack from "@mui/material/Stack/Stack";
import { FC, Fragment, useMemo } from "react";

import { useRisksSubjectsProjects } from "@/pages/MainPage/hooks/useRisksSubjectsProjects";
import { useProjects } from "@/pages/ProjectsPage/hooks/useProjects";
import { compareRisks } from "@/shared/lib/constants";
import { formatNumber } from "@/shared/lib/heplers";
import { REGION_LEVEL } from "@/shared/lib/types";
import { useSortTable } from "@/shared/Table/hooks/useSortTable";
import { useData } from "@/shared/Table/hooks/useTotal";
import {
  getProjectColorValue,
  takeProjectsByPeriod,
} from "@/shared/Table/lib/helpers";
import { IMainDataProject, IProjectRow } from "@/shared/Table/lib/types";
import { useAppSelector } from "@/store/redux/hooks";
import { TAB_ID, TAB_LEVEL } from "@/store/scheme/region";

import { LinkStyled, MainTableValue } from "../../Table.styled";
import { TableCellStyled } from "../DistrictRow/DistrictRow.styled";
import { TableRowStyled } from "./ProjectRow.styled";

export const ProjectRow: FC<IProjectRow> = ({
  region,
  columns,
  order,
  orderBy,
  currentTabName,
}) => {
  const theme = useTheme();
  const { sortBy, getComparator } = useSortTable();
  const { getPath } = useRouter();
  const { getIndicator } = useData();
  const { projectsDirectoryByRegion } = useProjects();
  const { projectPassportForProjects } = useAppSelector(
    (state) => state.map.geo
  );
  const { riskProjectData } = useRisksSubjectsProjects();
  const { currentPeriod } = useAppSelector((state) => state.controls.period);
  let projectNumber = 0;

  const mainData: IMainDataProject[] = useMemo(() => {
    return (projectsDirectoryByRegion?.[region.id] || [])?.map(
      (project, idx) => {
        //показывать если текущий период в рамках старта и окончания проекта
        const isPeriod =
          (project.attributeVals.start_action <= currentPeriod &&
            (project.attributeVals.end_action >= currentPeriod ||
              project.attributeVals.end_action === "")) ||
          (currentPeriod === "0" && project.attributeVals.end_action === "");

        return {
          ...project,
          isShow: isPeriod,
        };
      }
    );
  }, [columns, projectsDirectoryByRegion, region.id]);

  const addProjectByColumn = useMemo(() => {
    return [...columns]
      .filter((column) => !["firstHeaderRow", "header"].includes(column.id))
      .map((column) => {
        return {
          ...column,
          projectsData: projectPassportForProjects,
          ...(column?.isRiskColumn
            ? {
                riskData: riskProjectData,
              }
            : {}),
        };
      });
  }, [projectPassportForProjects, columns, riskProjectData]);

  const getProjectsRows = useMemo(() => {
    const sortableColumns = addProjectByColumn.filter((col) => col.sortBy);

    return mainData?.map((project) => {
      const values = sortableColumns.map((col) => {
        let value = takeProjectsByPeriod(col, project, col?.isRiskColumn)
          ?.values?.[
          col?.isRiskColumn && col?.subProjectIndicatorId
            ? col?.subProjectIndicatorId
            : col?.indicator
        ];
        if (value === undefined && col?.isRiskColumn) value = -1;
        if (value === undefined && !col?.isRiskColumn) value = 0;
        return [[col.sortBy as string], value];
      });

      return {
        ...project,
        ...Object.fromEntries(values),
      };
    });
  }, [columns, mainData, addProjectByColumn]);

  const getTransitionProject = (project: IMainDataProject) => {
    const path = [TAB_ID.RISKS].includes(currentTabName) ? TAB_LEVEL.RISKS : "";
    return getPath(
      `/projects/${project?.attributeVals?.SHORT_NAME}${path ? `/${path}` : ""}`
    );
  };

  return (
    <>
      {sortBy(getProjectsRows, getComparator<any>(order, orderBy)).map(
        (project: IMainDataProject) => {
          if (project.isShow) projectNumber++;
          return (
            <Fragment key={project.attributeVals.SHORT_NAME}>
              {project.isShow && (
                <TableRowStyled key={project?.attributeVals?.name}>
                  <TableCellStyled
                    sx={{
                      pl: "6rem",
                      fontSize: "1.125rem",
                      position: "sticky",
                      left: 0,
                    }}
                  >
                    <Stack
                      direction="row"
                      spacing={2}
                      alignItems="center"
                      sx={{ color: theme.palette.text.tertiary }}
                    >
                      <div>{projectNumber}</div>

                      {project?.attributeVals?.["end_action"] ? (
                        <span> {project?.attributeVals?.name} </span>
                      ) : (
                        <LinkStyled
                          sx={{
                            color: theme.palette.text.tertiary,
                            maxWidth: "37.5rem",
                          }}
                          to={getTransitionProject(project)}
                        >
                          {project?.attributeVals?.name}
                        </LinkStyled>
                      )}
                    </Stack>
                  </TableCellStyled>

                  {addProjectByColumn.map((col) => {
                    const value = formatNumber(
                      takeProjectsByPeriod(col, project, col?.isRiskColumn)
                        ?.values?.[
                        col?.isRiskColumn && col?.subProjectIndicatorId
                          ? col?.subProjectIndicatorId
                          : col?.indicator
                      ],
                      col?.valuesUnit,
                      col?.isRiskColumn ? 0 : col?.fractionDigits
                    );
                    const colorRisk = col?.isRiskColumn
                      ? takeProjectsByPeriod(col, project, col?.isRiskColumn)
                          ?.values?.[col?.indicatorColor!!]
                      : undefined;

                    return (
                      <MainTableValue
                        key={`${col.id}_${col.label}`}
                        focused={col.focused}
                        valueColor={
                          col?.isRiskColumn && colorRisk
                            ? colorRisk
                            : getProjectColorValue(col, project, getIndicator)
                        }
                        level={REGION_LEVEL.PROJECT}
                      >
                        {col?.isRiskColumn
                          ? compareRisks?.[value] || "-"
                          : value}
                      </MainTableValue>
                    );
                  })}
                </TableRowStyled>
              )}
            </Fragment>
          );
        }
      )}
    </>
  );
};
