import { useCallback, useMemo } from "react";

import { useEnterprise } from "@/features/EnterpriseWidget/hooks/useEnterprise";
import { useKkt } from "@/features/KktWidget/hooks/useKkt";
import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { useRequestRegions } from "@/pages/RegionPage/hooks/useRequestRegions";
import { useBudget } from "@/shared/Budget/hooks/useBudget";
import { useSocEconomy } from "@/shared/DistrictValues/hooks/useSocEconomy";
import { useGeneralIndicators } from "@/shared/GeneralIndicators/hooks/useGeneralIndicators";
import { useKpi } from "@/shared/GeneralIndicators/hooks/useKpi";
import { REGION_COUNTRY_CODE } from "@/shared/lib/constants";
import {
  extraActionsPeriod,
  periodUpdate,
} from "@/shared/PeriodControl/model/periodSlice";
import { useFuturePlan } from "@/shared/Table/hooks/useFuturePlan";
import { useAppDispatch, useAppSelector } from "@/store/redux/hooks";

import { PeriodDataType } from "../lib/types";

export const usePeriod = () => {
  const regionId = useAppSelector((state) => state.controls.regions.id);
  const { items } = useAppSelector((state) => state.regions.regions);
  const { isCheckRfFoPage, getDistrictByRegion } = useRegions();
  const {
    requestSumProjectsByRf,
    requestSumProjectsByFo,
    requestSumRfFoRisk,
    requestSumRiskTotal,
    requestSumRisksKpi,
  } = useRequestRegions();
  const district = getDistrictByRegion(regionId!);

  const { requestGeneralIndicators } = useGeneralIndicators();
  const { requestBudget } = useBudget();
  const { requestSocEconomy } = useSocEconomy();
  const { requestKpi } = useKpi();
  const { requestKkt } = useKkt();
  const { requestEnterprise } = useEnterprise();
  const { isCheckFuturePlan, isCheckRiskFuturePlan } = useFuturePlan();

  const {
    data,
    fetching,
    currentPeriod,
    error: periodError,
  } = useAppSelector((state) => state.controls.period);
  const dispatch = useAppDispatch();

  const requestPeriodFilter = useCallback(() => {
    dispatch(extraActionsPeriod.periodGet());
  }, [dispatch]);

  const arrPeriodData = useMemo((): Record<string, PeriodDataType[]> => {
    const periods: Record<string, PeriodDataType[]> = {};
    const sortedData = Object.values(data || {})?.sort(
      (periodA: any, periodB: any) => {
        return periodA?.id > periodB?.id ? 1 : -1;
      }
    );

    (sortedData ?? []).forEach((item: any) => {
      const attrubuteData = item?.attributeVals;
      const year = attrubuteData?.year;
      if (!periods[year]) {
        periods[year] = [];
      }
      const reportPeriod = attrubuteData?.report_period ?? "";

      periods[year].unshift({
        id: item?.id ?? "",
        name: reportPeriod,
      });
    });
    return periods;
  }, [data]);

  const handleClick = (value: string) => {
    dispatch(periodUpdate(value));
  };

  const parentIdSocEconomy = useMemo(() => {
    return (
      (regionId && (items || []).find((i) => i.id === regionId)?.parentId[0]) ||
      REGION_COUNTRY_CODE
    );
  }, [items, regionId]);

  const requestDataByPeriod = useCallback(
    (regionId: string | undefined) => {
      if (regionId) {
        requestGeneralIndicators(regionId, currentPeriod, district);
        requestBudget(regionId, currentPeriod, district);
        requestKpi(regionId, currentPeriod, district);
        requestKkt(regionId, currentPeriod, district);
        if (parentIdSocEconomy)
          requestSocEconomy(regionId, currentPeriod, parentIdSocEconomy);
        if (isCheckRfFoPage.isRF) {
          requestSumProjectsByRf(currentPeriod);
        }
        if (isCheckRfFoPage.isFO) {
          requestSumProjectsByFo({
            dataSummaryId: currentPeriod,
            dataRegionId: regionId,
          });
        }
        if (isCheckFuturePlan) {
          requestEnterprise(regionId, currentPeriod, district);
        }
        if (isCheckRiskFuturePlan) {
          requestSumRiskTotal({
            dataSummaryId: currentPeriod,
            dataRegionId: regionId,
            districtId: district?.id as any,
          });
          if (isCheckRfFoPage.isAll) {
            requestSumRfFoRisk({
              dataSummaryId: currentPeriod,
              dataRegionId: regionId,
            });
            requestSumRisksKpi({
              dataSummaryId: currentPeriod,
              regionId,
            });
          }
        }
      }
    },
    [
      currentPeriod,
      district,
      parentIdSocEconomy,
      isCheckRfFoPage.isRF,
      isCheckRfFoPage.isFO,
      isCheckFuturePlan,
      isCheckRiskFuturePlan,
    ]
  );

  return {
    periodData: data,
    periodError,
    arrPeriodData,
    periodDataFetching: fetching,
    requestPeriodFilter,
    handleClick,
    requestDataByPeriod,
  };
};
