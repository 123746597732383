import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { prepareAttributes } from "@/shared/lib/heplers";
import { ArgumentsType } from "@/shared/lib/types";
import {
  IAttributeVals,
  IOlapResponseDimensionItemDimWithVals,
  IPreparedIndicatorAttributeVals,
  MODEL_IDS,
  MODELS,
} from "@/store/scheme/olap";

import { IProjectsSlice } from "../../lib/types";
import {
  getProjectsAll,
  getProjectsDirectory,
  getProjectsSum,
} from "../api/projects_api";

export const initialState: IProjectsSlice = {
  data: undefined,
  filtered: undefined,
  dataFetching: false,
  dataError: null,
  allProjects: undefined,
  isLoadAllProjects: false,
  allProjectsFetching: false,
  allProjectsError: null,
  projectsDirectoryFetching: false,
  projectsDirectory: [],
  projectsDirectoryByRegion: undefined,
  projectsDirectoryError: null,
};

export const extraActionsProjects = {
  getSum: createAsyncThunk(
    "regions/projects/getSum",
    async (args: ArgumentsType) => await getProjectsSum(args)
  ),
  getAll: createAsyncThunk(
    "regions/projects/getAll",
    async (regionIds: string[]) => await getProjectsAll(regionIds)
  ),
  getProjectsDirectory: createAsyncThunk(
    "regions/projects/directory",
    async () => await getProjectsDirectory()
  ),
};

const filteredByDate = (
  data: IOlapResponseDimensionItemDimWithVals<IAttributeVals>[],
  dateFilter: string
) => {
  return data.filter((p) => p?.code === dateFilter);
};

const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActionsProjects.getSum.pending, (state) => {
        state.data = undefined;
        state.filtered = undefined;
        state.dataFetching = true;
      })
      .addCase(extraActionsProjects.getSum.fulfilled, (state, action) => {
        const model = MODELS[MODEL_IDS.PROJECT_PASSPORT];

        const data = filteredByDate(action.payload, "120300000");
        const filtered = filteredByDate(action.payload, "420220006");

        state.data = data.reduce((prev, cur) => {
          if (cur?.dims?.[model.dataProjectId]?.code) {
            const projectId = cur.dims[model.dataProjectId].code;

            if (!prev[projectId]) {
              prev[projectId] = {};
            }

            if (cur?.dims?.[model.dimId]?.code) {
              const projectIdDimId = (cur?.dims[model.dataProjectId] as any)
                ?.dimId as any;
              const projectIdCode = cur.dims[model.dimId].code;

              prev[projectId][projectIdCode] = {
                ...prepareAttributes<IPreparedIndicatorAttributeVals>(
                  cur.dims[model.dimId].attributeVals
                ),
                attributeVals: cur?.attributeVals || {},
                indicatorVals: Object.fromEntries(
                  Object.entries(cur?.indicatorVals || {}).map(
                    ([key, value]) => [key, value?.sum]
                  )
                ) as any,
              };

              prev[projectId][projectIdDimId] = {
                ...prepareAttributes<IPreparedIndicatorAttributeVals>(
                  cur.dims[model.dataProjectId].attributeVals
                ),
                attributeVals: cur?.attributeVals || {},
                indicatorVals: Object.fromEntries(
                  Object.entries(cur?.indicatorVals || {}).map(
                    ([key, value]) => [key, value?.sum]
                  )
                ) as any,
              };
            }
          }

          return prev;
        }, {} as any);

        state.filtered = filtered.reduce((prev, cur) => {
          if (cur?.dims?.[model.dataProjectId]?.code) {
            const projectId = cur.dims[model.dataProjectId].code;

            if (!prev[projectId]) {
              prev[projectId] = {};
            }

            if (cur?.dims?.[model.dimId]?.code) {
              const projectIdDimId = (cur?.dims[model.dataProjectId] as any)
                ?.dimId as any;
              const projectIdCode = cur.dims[model.dimId].code;

              prev[projectId][projectIdCode] = {
                ...prepareAttributes<IPreparedIndicatorAttributeVals>(
                  cur.dims[model.dimId].attributeVals
                ),
                attributeVals: cur?.attributeVals || {},
                indicatorVals: Object.fromEntries(
                  Object.entries(cur?.indicatorVals || {}).map(
                    ([key, value]) => [key, value?.sum]
                  )
                ) as any,
              };

              prev[projectId][projectIdDimId] = {
                ...prepareAttributes<IPreparedIndicatorAttributeVals>(
                  cur.dims[model.dataProjectId].attributeVals
                ),
                attributeVals: cur?.attributeVals || {},
                indicatorVals: Object.fromEntries(
                  Object.entries(cur?.indicatorVals || {}).map(
                    ([key, value]) => [key, value?.sum]
                  )
                ) as any,
              };
            }
          }

          return prev;
        }, {} as any);

        state.dataFetching = false;
        state.dataError = null;
      })
      .addCase(extraActionsProjects.getSum.rejected, (state, action) => {
        state.dataFetching = false;
        state.dataError = action.error;
      })
      .addCase(extraActionsProjects.getAll.pending, (state, action) => {
        state.isLoadAllProjects = true;
        state.allProjectsFetching = true;
      })
      .addCase(extraActionsProjects.getAll.fulfilled, (state, action) => {
        state.allProjects = action.payload;
        state.allProjectsFetching = false;
      })
      .addCase(extraActionsProjects.getAll.rejected, (state, action) => {
        state.allProjectsFetching = false;
        state.isLoadAllProjects = false;
        state.allProjectsError = action.error;
      })

      .addCase(
        extraActionsProjects.getProjectsDirectory.pending,
        (state, action) => {
          state.projectsDirectoryFetching = true;
        }
      )
      .addCase(
        extraActionsProjects.getProjectsDirectory.fulfilled,
        (state, action) => {
          state.projectsDirectory = action.payload;
          state.projectsDirectoryByRegion = action.payload.reduce(
            (prev, cur) => {
              const regionId = (cur?.attributeVals as any)?.id_subject;
              const projectId = (cur?.attributeVals as any)?.SHORT_NAME;

              if (regionId && projectId) {
                if (!prev[regionId]) {
                  prev[regionId] = [];
                }
                prev[regionId].push(cur);
              }

              return prev;
            },
            {} as any
          );
          state.projectsDirectoryFetching = false;
          state.projectsDirectoryError = null;
        }
      )
      .addCase(
        extraActionsProjects.getProjectsDirectory.rejected,
        (state, action) => {
          state.projectsDirectoryFetching = false;
          state.projectsDirectoryError = action.error;
        }
      );
  },
});

export const reducer = projectsSlice.reducer;

export default projectsSlice;
