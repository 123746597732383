import { styled, Table, TableHead, TableRow } from "@mui/material";
import { CardList } from "@shared/CardList/CardList.styled";

export const WrapperStyled = styled(CardList)(({ theme }) => ({
  position: "relative",
  minHeight: "auto",
  "& > .hoverIncident, & > .parentIncident > .hoverIncident": {
    display: "none",
  },
  "&:hover .hoverIncident, &:hover > .parentIncident > .hoverIncident": {
    display: "block",
  },
}));

export const TableStyled = styled(Table)(({ theme }) => ({
  position: "relative",
}));

export const CustomScrollbarWrapper = styled("div", {
  shouldForwardProp: (prop) =>
    !["isOpenPopUp", "isTable"].includes(prop.toString()),
})<{
  isOpenPopUp?: boolean;
  isTable?: boolean;
}>(({ theme, isOpenPopUp, isTable = false }) => ({
  width: "100%",
  height: "100%",
  "& > div": {
    maxHeight: "100% !important",
  },
  ...(isTable && {
    background: isOpenPopUp
      ? theme.palette.popup.header
      : theme.palette.table.lightDark,
  }),
  ".track": {
    borderRadius: theme.spacing(0.375),
    background: theme.palette.scrollbar.track,
    "&.vertical": {
      right: theme.spacing(0.25),
      bottom: theme.spacing(0.5),
      top: theme.spacing(0.5),
      zIndex: 100,
      ...(isTable && {
        top: theme.spacing(isOpenPopUp ? 10.6 : 7.6),
      }),
    },
    "&.horizontal": {
      right: theme.spacing(0.25),
      bottom: theme.spacing(0.25),
      left: theme.spacing(0.25),
      zIndex: 100,
    },
  },
  ".thumb": {
    cursor: "pointer",
    borderRadius: "inherit",
    background: theme.palette.scrollbar.thumb,
  },
  ".scroll-view": {
    width: "100%",
    marginRight: "0 !important",
    marginBottom: "0 !important",
    overflow: "auto !important",
    scrollbarWidth: "none",
    ...(isTable && {
      background: isOpenPopUp
        ? theme.palette.popup.popupBackground
        : theme.palette.sceleton.baseColor,
    }),
    "&.verticalScroll": {
      width: "calc(100% - 9px)",
      marginRight: "7px !important",
    },
    ...(isTable &&
      !isOpenPopUp && {
        "&.horisontalScroll": {
          marginBottom: "-17px !important",
        },
      }),
    "&::-webkit-scrollbar": {
      width: 0,
    },
  },
}));

export const TableHeadStyled = styled(TableHead, {
  shouldForwardProp: (prop) => prop !== "openWidgetTable",
})<{ openWidgetTable?: boolean }>(({ theme, openWidgetTable }) => ({
  ".MuiTableCell-head": {
    borderBottom: `3px solid ${
      theme.palette.mode === "dark"
        ? theme.palette.table.divider
        : theme.palette.diagram.baseDiagram
    }`,
    background:
      theme.palette.mode === "dark"
        ? openWidgetTable
          ? theme.palette.popup.header
          : theme.palette.table.lightDark
        : openWidgetTable
        ? theme.palette.popup.header
        : theme.palette.button.dividerBackground,
  },
}));
export const TableRowStyled = styled(TableRow)(({ theme }) => ({
  height: theme.spacing(8),
}));
