import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { prepareAttributes } from "@/shared/lib/heplers";
import { ArgumentsWithDistrictIdType } from "@/shared/lib/types";
import { IRegionsState } from "@/shared/lib/types";
import {
  IPeriodAttributeVals,
  IPreparedIndicatorAttributeVals,
  MODEL_IDS,
  MODELS,
} from "@/store/scheme/olap";

import { getCountEnterprise } from "./api";

export const initialState: IRegionsState = {
  data: undefined,
  fetching: false,
  error: null,
};

export const extraActionsEnterprise = {
  getEnterprise: createAsyncThunk(
    "regions/enterprise/get",
    async (args: ArgumentsWithDistrictIdType) => await getCountEnterprise(args)
  ),
};

const enterpriseSlice = createSlice({
  name: "enterprise",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActionsEnterprise.getEnterprise.pending, (state) => {
        state.data = undefined;
        state.fetching = true;
      })
      .addCase(
        extraActionsEnterprise.getEnterprise.fulfilled,
        (state, action) => {
          const model = MODELS[MODEL_IDS.KPI_SOC_ECONOMY];
          state.data = action.payload.reduce((prev, cur) => {
            const indicatorId = cur?.dims?.[model.dimId]?.code;
            if (!prev[indicatorId]) {
              prev[indicatorId] = {};
            }
            const subjectId = cur?.dims?.[model.dataRegionId]?.code;
            if (!prev[indicatorId][subjectId]) {
              prev[indicatorId][subjectId] = {};
            }
            prev[indicatorId][subjectId] = {
              ...prepareAttributes<IPreparedIndicatorAttributeVals>({
                ...cur.attributeVals,
                UNIT: (
                  cur?.dims?.[model.dimId]
                    ?.attributeVals as IPeriodAttributeVals
                )?.unit_measure,
              }),
              attributeVals: cur.dims[model.dimId].attributeVals || {},
              indicatorVals: Object.fromEntries(
                Object.entries(cur.dims[model.dimId]?.indicatorVals || {}).map(
                  ([key, value]) => [key, value?.sum]
                )
              ) as any,
            };
            return prev;
          }, {} as any);
          state.fetching = false;
          state.error = null;
        }
      )
      .addCase(
        extraActionsEnterprise.getEnterprise.rejected,
        (state, action) => {
          state.fetching = false;
          state.error = action.error;
        }
      );
  },
});

export const reducer = enterpriseSlice.reducer;

export default enterpriseSlice;
