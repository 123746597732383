import { FC, useMemo } from "react";

import { FoCoatsOfArms } from "@/shared/FoCoatsOfArms/ui/FoCoatsOfArms";
import { REGION_COATS } from "@/shared/lib/constants";
import { IRegionCoatKey } from "@/shared/lib/types";

import { federalDistricts } from "../lib/constants";
import { ICoatProps } from "../lib/types";
import { CoatStyled } from "./Coat.styled";

export const Coat: FC<ICoatProps> = ({ region, parent, isOpenPopUp }) => {
  const isFederalDistrict = useMemo(() => {
    return federalDistricts.includes(region);
  }, [region]);

  return isFederalDistrict ? (
    <FoCoatsOfArms idFo={region} openWidget={isOpenPopUp} />
  ) : (
    <CoatStyled isOpenPopUp={isOpenPopUp} parent={parent}>
      <img src={REGION_COATS[region as IRegionCoatKey]} alt={region} />
    </CoatStyled>
  );
};
