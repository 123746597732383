import { useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";

import { DefaultButton } from "@/shared/DefaultButton/DefaultButton";
import {
  GroupButton,
  IncidentFooter,
  IncidentHeaderTitle,
} from "@/shared/Incident/ui/IncidentWrapper/IncidentWrapper.styled";
import { ModalWrapper } from "@/shared/Modal/Modal";
import { CloseButton, FormHeader } from "@/shared/Table/ui/PopUp/PopUp.styled";

import { useFeedback } from "../hooks/useFeedback";
import { FeedbackAnalytics } from "./FeedbackAnalytics/FeedbackAnalytics";
import { FeedbackContent } from "./FeedbackContent";

export const FeedbackWrapper = () => {
  const theme = useTheme();
  const [totalRating, setTotalRating] = useState<number | null>(null);
  const [isShowAnalytic, setIsShowAnalytic] = useState(false);
  const {
    isOpenFeedback,
    isAnalyticSummary,
    handleFeedbackChange,
    sendFeedbackRating,
    ratings,
  } = useFeedback();

  useEffect(() => {
    setIsShowAnalytic(isAnalyticSummary);
  }, []);

  const handleCloseFeedback = () => handleFeedbackChange(false, false);

  const handleSendFeedback = () => {
    if (totalRating) sendFeedbackRating(totalRating);
    if (ratings?.length) {
      setIsShowAnalytic(true);
    } else {
      handleCloseFeedback();
    }
  };

  const getRatingValue = (rating: number | null) => {
    setTotalRating(rating);
  };

  return (
    <ModalWrapper
      open={isOpenFeedback}
      onClose={handleCloseFeedback}
      width={isShowAnalytic ? 64 : 90}
      height={isShowAnalytic ? 0 : 45.5}
      children={
        <>
          <FormHeader
            sx={{
              background: theme.palette.dropdownWidget.baseHeader,
              borderBottom: "transparent",
              gap: "1rem",
            }}
          >
            <IncidentHeaderTitle>
              {isShowAnalytic ? "Ответы участников" : "Оцените модуль"}
            </IncidentHeaderTitle>
            <CloseButton onClick={handleCloseFeedback} />
          </FormHeader>
          {isShowAnalytic ? (
            <FeedbackAnalytics />
          ) : (
            <FeedbackContent handleRating={getRatingValue} />
          )}
          {!isShowAnalytic && (
            <IncidentFooter>
              <GroupButton>
                <DefaultButton
                  key="Отменить"
                  titleText="Отменить"
                  onClick={handleCloseFeedback}
                />
                <DefaultButton
                  selected
                  disabled={!totalRating}
                  key="Отправить"
                  titleText="Отправить"
                  onClick={handleSendFeedback}
                />
              </GroupButton>
            </IncidentFooter>
          )}
        </>
      }
    />
  );
};
