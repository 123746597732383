import DynamicsHighFOIcon from "@assets/svg_components/DynamicsHighFO";
import DynamicsLowFOIcon from "@assets/svg_components/DynamicsLowFO";
import { FC } from "react";

import { useSubFooter } from "../../hooks/useSubFooter";
import { Item } from "../Footer.styled";

export const FooterHighLowFO: FC = () => {
  const { isCheckManufacuringTab } = useSubFooter();
  return (
    <>
      <Item>
        <DynamicsHighFOIcon />
        {isCheckManufacuringTab && (
          <DynamicsHighFOIcon contrast={isCheckManufacuringTab} />
        )}
        Выше ФО
      </Item>
      <Item>
        <DynamicsLowFOIcon />
        {isCheckManufacuringTab && (
          <DynamicsLowFOIcon contrast={isCheckManufacuringTab} />
        )}
        Ниже ФО
      </Item>
    </>
  );
};
