import ExportIcon from "@assets/svg_components/Export";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { List } from "@mui/material";
import { Fragment } from "react";

import { Icon } from "@/shared/CustomIcon/Icon.styled";
import { ToggleButtonStyled } from "@/shared/DownloadControl/DownloadControl.styled";

import { normativeLegalActs } from "../lib/constants";
import { linkToAct, onDownload } from "../lib/helpers";
import {
  BoxWrapper,
  LinkUnstyled,
  ListDivider,
  ListItemIconStyled,
  ListItemStyled,
  ListItemTextStyled,
  ToggleButtonWrapper,
} from "./ActsContent.styled";

export const ActsContent = () => {
  return (
    <BoxWrapper>
      <List>
        {normativeLegalActs.map(({ label, fileName, type }, index) => (
          <Fragment key={index}>
            <ListItemStyled>
              <ListItemIconStyled>
                <Icon>
                  <TextSnippetIcon />
                </Icon>
                {type?.toUpperCase()}
              </ListItemIconStyled>

              <ListItemTextStyled
                primary={
                  <LinkUnstyled target="_blank" href={linkToAct(fileName)}>
                    {label}
                  </LinkUnstyled>
                }
              />

              <ToggleButtonWrapper background>
                <ToggleButtonStyled
                  value={{}}
                  onClick={() => onDownload({ label, fileName, type })}
                >
                  <ExportIcon />
                </ToggleButtonStyled>
              </ToggleButtonWrapper>
            </ListItemStyled>

            {index < normativeLegalActs.length - 1 && <ListDivider />}
          </Fragment>
        ))}
      </List>
    </BoxWrapper>
  );
};
