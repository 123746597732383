import { TableRow, TableSortLabel } from "@mui/material";
import { useTheme } from "@mui/material";
import Stack from "@mui/material/Stack";
import { FC, useEffect, useMemo, useState } from "react";

import { SortArrows } from "@/assets/svg_components/SortArrows/SortArrows";

import { ITableHeader } from "../lib/types";
import {
  RiskProjectTitle,
  TableHeaderLabel,
  TableHeaderStyled,
  TableHeadStyled,
  TableHelperHeader,
  TableHelperLabel,
  TableValuesLabel,
} from "./Table.styled";

export const TableHeader: FC<ITableHeader> = ({
  data,
  helperHeder,
  orderBy,
  order,
  setOrderBy,
  setOrder,
  borderForDynamics = false,
}) => {
  const theme = useTheme();
  const [groups, setGroups] = useState<any>({});

  const isNotDarkTheme = useMemo(() => {
    return theme.palette.mode !== "dark";
  }, [theme]);

  const helperhederhight = useMemo(
    () => (helperHeder ? (isNotDarkTheme ? 47 : 45) : 0),
    [helperHeder, isNotDarkTheme]
  );

  const mainColumns = useMemo(() => {
    return data.filter((item) => item.mainColumn && !item?.isLastColumn);
  }, [data]);

  const mainLastColumns = useMemo(() => {
    return data.filter((item) => item.mainColumn && item?.isLastColumn);
  }, [data]);

  const [headerRows, valuesRows, helpersRows] = useMemo(() => {
    const headerRows = data
      .filter((rows) => rows.id === "header")
      .map((rows) => {
        return {
          ...rows,
          colSpan:
            rows?.updateDateId && groups[rows.updateDateId]
              ? groups[rows.updateDateId]?.length
              : 0,
        };
      });

    const valuesRows = data.filter(
      (item) => item?.id !== "header" && !item.mainColumn && !item.rowSpan
    );

    const helpersRows = data.filter(
      (item) => item?.id !== "header" && !item.mainColumn && item.rowSpan
    );

    return [headerRows, valuesRows, helpersRows];
  }, [data, groups]);

  const colSpanHelperHeader =
    valuesRows?.length + helpersRows?.length + headerRows?.length ?? 0;

  useEffect(() => {
    setGroups(() => {
      return data
        .filter(
          (item) => item?.id !== "header" && !item.mainColumn && !item.rowSpan
        )
        .reduce((acc, current) => {
          if (!acc[current.id]) {
            acc[current.id] = [];
          }
          acc[current.id].push(current.indicator);
          return acc;
        }, {} as any);
    });
  }, [data]);

  return (
    <TableHeadStyled>
      <TableRow>
        <TableHeaderStyled scope="row" firstColumn rowSpan={5}>
          Наименование
        </TableHeaderStyled>

        {mainColumns.map((column) => (
          <TableHeaderStyled
            rowSpan={5}
            key={`${column.indicator}_${column.id}`}
            lastColumn={!colSpanHelperHeader}
          >
            <Stack direction="row" alignItems="center" gap={1}>
              {(!column?.sortable || !column?.sortBy) && column?.label}

              {column?.sortable && column?.sortBy && (
                <TableSortLabel
                  IconComponent={() => (
                    <SortArrows
                      sortType={orderBy === column.sortBy && order}
                      isLight={column?.sortBy === orderBy}
                    />
                  )}
                  active={orderBy === column.sortBy}
                  direction={orderBy === column.sortBy ? order : "asc"}
                  onClick={() => setOrderBy(column?.sortBy)}
                >
                  {column?.label}
                </TableSortLabel>
              )}
            </Stack>
          </TableHeaderStyled>
        ))}
      </TableRow>

      {helperHeder && colSpanHelperHeader > 0 && (
        <TableRow>
          <TableHelperHeader
            helperhederhight={helperhederhight}
            colSpan={colSpanHelperHeader}
            isNotLastColum={!!mainLastColumns?.length}
          >
            {helperHeder}
          </TableHelperHeader>
          {/* Колонка для новой таблицы по рискам */}
          {!!mainLastColumns?.length &&
            mainLastColumns.map((column) => (
              <TableHeaderStyled
                rowSpan={5}
                key={`${column.indicator}_${column.id}`}
                lastColumn={true}
              >
                <Stack direction="row" alignItems="center" gap={1}>
                  {column?.sortable && column?.sortBy && (
                    <TableSortLabel
                      IconComponent={() => (
                        <SortArrows
                          sortType={orderBy === column.sortBy && order}
                          isLight={column?.sortBy === orderBy}
                        />
                      )}
                      active={orderBy === column.sortBy}
                      direction={orderBy === column.sortBy ? order : "asc"}
                      onClick={() => setOrderBy(column?.sortBy)}
                    >
                      <RiskProjectTitle>
                        <span>{column?.subLabel}</span>
                        {column?.label}
                      </RiskProjectTitle>
                    </TableSortLabel>
                  )}
                </Stack>
              </TableHeaderStyled>
            ))}
        </TableRow>
      )}

      <TableRow>
        {helpersRows.map((row) => (
          <TableHelperLabel
            helperhederhight={helperhederhight}
            align="center"
            rowSpan={row?.rowSpan}
            key={`${row.indicator}_${row.id}`}
          >
            <Stack direction="row" alignItems="center" gap={1}>
              {(!row?.sortable || !row?.sortBy) && row?.label}

              {row?.sortable && row?.sortBy && (
                <TableSortLabel
                  IconComponent={() => (
                    <SortArrows
                      sortType={orderBy === row.sortBy && order}
                      isLight={row?.sortBy === orderBy}
                    />
                  )}
                  active={orderBy === row.sortBy}
                  direction={orderBy === row.sortBy ? order : "asc"}
                  onClick={() => setOrderBy(row?.sortBy)}
                >
                  {row?.label}
                </TableSortLabel>
              )}
            </Stack>
          </TableHelperLabel>
        ))}
      </TableRow>
      {/* верхние хедеры, например "Выпуск товаров и услуг" */}
      <TableRow>
        {headerRows.map((row) => (
          <TableHeaderLabel
            helperhederhight={helperhederhight}
            align="left"
            colSpan={row?.colSpan}
            key={`${row.label}_${row.id}`}
          >
            {row?.label}
          </TableHeaderLabel>
        ))}
      </TableRow>
      {/* нижние хедеры, например "План, млрд руб." */}
      <TableRow>
        {valuesRows.map((row) => (
          <TableValuesLabel
            top={helperhederhight + (headerRows?.length ? 72 : 0)}
            borderForDynamics={borderForDynamics}
            rowSpan={row?.rowSpan}
            key={`${row.indicator}_${row.id}_${row.label}`}
            isNotLastColum={!!mainLastColumns?.length}
          >
            <Stack direction="row" alignItems="center" gap={1}>
              {(!row?.sortable || !row?.sortBy) && row?.label}

              {row?.sortable && row?.sortBy && (
                <TableSortLabel
                  IconComponent={() => (
                    <SortArrows
                      sortType={orderBy === row.sortBy && order}
                      isLight={row?.sortBy === orderBy}
                    />
                  )}
                  active={orderBy === row.sortBy}
                  direction={orderBy === row.sortBy ? order : "asc"}
                  onClick={() => setOrderBy(row?.sortBy)}
                >
                  {row?.label}
                </TableSortLabel>
              )}
            </Stack>
          </TableValuesLabel>
        ))}
      </TableRow>
    </TableHeadStyled>
  );
};
