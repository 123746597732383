import { styled } from "@mui/material";

export const RisksLegendWrapper = styled("div")<{ isOpenPopUp?: boolean }>(
  ({ theme, isOpenPopUp }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "center",
    gap: theme.spacing(),
    ...(isOpenPopUp && { zoom: 1.3 }),
  })
);

export const RisksLegendTitle = styled("span")(({ theme }) => ({
  whiteSpace: "nowrap",
  lineHeight: theme.spacing(1.25),
}));
