import { Theme } from "@mui/material";

import { getParams } from "@/shared/lib/heplers";

import { AppBarProjectRegionsFilter } from "../ui/AppBarProjectRegionsFilter/AppBarProjectRegionsFilter";
import { AppBarProjectsFilter } from "../ui/AppBarProjectsFilter/AppBarProjectsFilter";
import { AppBarRegionsFilter } from "../ui/AppBarRegionsFilter/AppBarRegionsFilter";

export const getHeaderHeight = (theme: Theme) => theme.spacing(11);

export const renderFilterForAppBar = (
  pathname: string,
  isCheckFuturePlan?: boolean
) => {
  const subjectId = getParams("region");
  const isGraphPage = pathname.includes("/graphs");
  const isProjectsPage = pathname.includes("/projects/");
  const isMapDistrictPage = pathname.includes("/district/");

  switch (true) {
    case isProjectsPage && !isGraphPage:
      return (
        <>
          <AppBarProjectRegionsFilter />
          <AppBarProjectsFilter path={pathname} />
        </>
      );

    case isGraphPage && !isProjectsPage:
      return (
        <AppBarRegionsFilter
          useRF
          useFO
          path={pathname}
          subjectId={subjectId}
          navigatePath="/region/{id}/graphs"
        />
      );

    case !isMapDistrictPage && pathname.includes("/region/") && !isGraphPage:
      return (
        <AppBarRegionsFilter
          useRF={isCheckFuturePlan}
          useFO={isCheckFuturePlan}
          subjectId={subjectId}
          navigatePath="/region/{id}"
        />
      );
  }
};
