import { styled } from "@mui/material";

import { CustomIconProps } from "./lib/types";

export const Icon = styled("div")<CustomIconProps>(
  ({ theme, size = "small", background = true }) => {
    let s;

    switch (size) {
      case "large":
        s = theme.spacing(9);
        break;
      case "big":
        s = theme.spacing(6);
        break;
      case "medium":
        s = theme.spacing(5);
        break;
      case "small":
        s = theme.spacing(3);
        break;
      default:
        s = size ?? theme.spacing(3);
        break;
    }

    return {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: s,
      height: s,
      borderRadius: "50%",
      ...(background && {
        background: theme.palette.background.light,
      }),

      "& svg,img": {
        width: "50%",
        height: "50%",
      },
    };
  }
);
