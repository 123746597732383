import { styled } from "@mui/material";

import { IRegionMainInfoGridCell } from "../lib/types";

export const RegionMainInfoWrapper = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(2),
  gridGap: theme.spacing(2),
  display: "grid",
  gridTemplateColumns: "repeat(5, 1fr)",
  gridTemplateAreas: `"VDL GeneralIndicators GeneralIndicators NationalProjectsIndicators NationalProjectsIndicators"`,
}));

export const RegionMainInfoGridCell = styled("div")<IRegionMainInfoGridCell>(
  ({ isHighlight }) => ({
    ...(isHighlight && {
      background: "rgba(191, 218, 255, 0.25)",
      boxShadow: "0px 0px 19px #49B4EF",
    }),
  })
);
