import { useMemo } from "react";
import { useLocation } from "react-router";

import {
  getSaveStep,
  updateCurrentTabAction,
  updateGeneralTab,
} from "@/pages/MainPage/model/tabsSlice";
import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { ACHIEVEMENT_VALUE } from "@/shared/AchievementControl/lib/types";
import { achievementControlToggle } from "@/shared/AchievementControl/model/achievementSlice";
import { STEPS } from "@/shared/GridCard/lib/types";
import { getParams } from "@/shared/lib/heplers";
import { useAppDispatch, useAppSelector } from "@/store/redux/hooks";
import { TAB_ID } from "@/store/scheme/region";

export const useAppBar = () => {
  const {
    mode: { isDark },
  } = useAppSelector((state) => state.ui);
  const { achievement } = useAppSelector((state) => state.controls);
  const { isCheckRfFoPage } = useRegions();
  const location = useLocation();
  const { pathname } = location;
  const dispatch = useAppDispatch();

  const resetTabsByCkickOnHome = () => {
    dispatch(achievementControlToggle(ACHIEVEMENT_VALUE.ACHIEVEMENT));
    dispatch(updateCurrentTabAction(TAB_ID.VDL_PROJECTS));
    dispatch(getSaveStep(STEPS.MAIN));
    dispatch(updateGeneralTab({ enabled: true, tab: TAB_ID.VDL_PROJECTS }));
  };

  const isControls =
    isDark &&
    !["faq", "acts", "projects/", "/graphs", "reports"].find((path) =>
      pathname.includes(path)
    );

  const isShowCompareControlOnTable = useMemo(() => {
    const ispPathAchievementLevel = pathname.startsWith("/achievementLevel");
    return ispPathAchievementLevel && !achievement.enabled;
  }, [achievement.enabled, pathname]);

  const isShowCompareControl = useMemo(() => {
    const ispPathDistrict = pathname.startsWith("/district");
    const isPathRegion = pathname.startsWith("/region");
    const subjectId = getParams("region");
    const is_Fo_Subject_NotEnabled =
      ispPathDistrict && subjectId && !achievement.enabled;

    return (
      is_Fo_Subject_NotEnabled ||
      isShowCompareControlOnTable ||
      (isPathRegion && !isCheckRfFoPage.isAll)
    );
  }, [
    achievement.enabled,
    isShowCompareControlOnTable,
    pathname,
    isCheckRfFoPage.isAll,
  ]);

  return { resetTabsByCkickOnHome, isShowCompareControl, isControls };
};
