import moment from "moment";
import { useMemo } from "react";

import { REGION_COUNTRY_CODE } from "@/shared/lib/constants";
import { formatNumber } from "@/shared/lib/heplers";
import { useAppDispatch, useAppSelector } from "@/store/redux/hooks";
import { menuSetOpen } from "@/widgets/MainMenu/model/menuSlice";

import { ratingGrade } from "../lib/constants";
import { extraActionsFeedback, setFeedback } from "../model/feedbackSlice";

export const useFeedback = () => {
  const { user } = useAppSelector((state) => state.auth.user);
  const today = moment();
  const todayYear = today.year();
  const todayQuarter = today.quarter();
  const quarterKey = `3${todayYear}000${todayQuarter}`;
  const dispatch = useAppDispatch();
  const { isOpenFeedback, isAnalyticSummary, analyticsData } = useAppSelector(
    (state) => state.feedback.feedback
  );

  const isActualUserGrade = useMemo(() => {
    return analyticsData?.users?.some(
      ({ userCode, period }: any) =>
        +userCode === user?.userId && period === quarterKey
    );
  }, [analyticsData?.users, user?.userId, quarterKey]);

  const [feedbackGrade, feedbackTotal] = useMemo(() => {
    let feedbackGrade = "0",
      feedbackTotal = "0";
    analyticsData?.middleRating?.forEach((data: any) => {
      if (data?.id === "128") {
        feedbackGrade = formatNumber(data?.count, undefined, 1);
      } else {
        feedbackTotal = data?.count;
      }
    });
    return [feedbackGrade, feedbackTotal];
  }, [analyticsData?.middleRating]);

  const ratings = useMemo(() => {
    const { shareRating, totalRating } = analyticsData?.ratings || {};
    return ratingGrade.reduce((prev, rating) => {
      const share = shareRating?.find(({ name }: any) => name === rating);
      const countTotal = totalRating?.find(({ name }: any) => name === rating);
      if (!prev) prev = [];
      const prepareData = {
        rating: Number(rating),
        shareFeedback: formatNumber(share?.count || 0, undefined, 0),
        countFeedback: countTotal?.count || 0,
      };
      prev.push(prepareData);
      return prev;
    }, [] as any);
  }, [
    analyticsData?.ratings?.totalRating,
    analyticsData?.ratings?.shareRating,
  ]);

  const setFeedbackChange = (isOpen: boolean, isAnalytic: boolean) => {
    dispatch(setFeedback({ isOpen, isAnalytic }));
    if (!isOpen) dispatch(menuSetOpen(false));
  };

  const handleFeedbackChange = (isOpen: boolean, isAnalytic: boolean) => {
    setFeedbackChange(isOpen, isAnalytic);
  };

  const sendFeedbackRating = (rating: number) => {
    dispatch(
      extraActionsFeedback.post({
        quarter_key: quarterKey,
        np_arm: REGION_COUNTRY_CODE,
        value: String(rating),
      })
    );
  };

  return {
    isActualUserGrade,
    quarterKey,
    handleFeedbackChange,
    isOpenFeedback,
    isAnalyticSummary,
    sendFeedbackRating,
    analyticsData,
    feedbackGrade,
    feedbackTotal,
    ratings,
  };
};
