import { useMemo } from "react";

import { HeaderColumns } from "@/shared/Table/lib/types";

export const useAverageRiskAndProjectsColumns = ({
  modelPassport,
  projectPassport,
  modelKpiData,
  kpiData,
  modelRisk,
  riskData,
}: any): {
  averageRiskAndProjectsColumns: HeaderColumns[];
} => {
  const averageRiskAndProjectsColumns = useMemo(() => {
    return [
      {
        id: "104",
        sortId: 1,
        indicator: modelKpiData.indexes.fact,
        subId: "152",
        projectId: "151",
        subProjectIndicatorId: modelRisk.indexes.status,
        subIndicator: modelRisk.indexes.fact,
        subData: riskData,
        subModel: modelRisk,
        label: "Количество проектов / в зоне риска",
        popUpLabel: "Количество проектов / в зоне риска",
        sortable: true,
        model: modelKpiData,
        defaultColumn: true,
        mainColumn: true,
        data: kpiData,
        rowSpan: 2,
        sortBy: "RiskProjects",
        needPrevPeriod: true,
        prevPeriod: false,
        textPeriod: "по данным АЦ",
        indicatorColor: modelRisk.indexes.color,
        isRiskColumn: true,
        subShareId: modelRisk.indexes.plan,
      },
      {
        id: "153",
        sortId: 2,
        label: `с высоким уровнем риска`,
        indicator: "996",
        popUpLabel: "Количество проектов с высоким уровнем риска",
        defaultColumn: true,
        sortable: true,
        model: modelRisk,
        data: riskData,
        sortBy: "HighRiskProjects",
        indicatorColor: modelRisk.indexes.color,
        colorValues: true,
        isNotNeedPeriod: true,
        isRiskColumn: true,
        fractionDigits: 0,
      },
      {
        id: "154",
        sortId: 3,
        label: `со средним уровнем риска`,
        indicator: "996",
        popUpLabel: "Количество проектов со средним уровнем риска",
        defaultColumn: true,
        sortable: true,
        model: modelRisk,
        data: riskData,
        sortBy: "MiddleRiskProjects",
        indicatorColor: modelRisk.indexes.color,
        colorValues: true,
        isNotNeedPeriod: true,
        isRiskColumn: true,
        fractionDigits: 0,
      },
      {
        id: "155",
        sortId: 4,
        label: `без риска`,
        indicator: "996",
        popUpLabel: "Количество проектов без рисков",
        defaultColumn: true,
        sortable: true,
        model: modelRisk,
        data: riskData,
        sortBy: "LowRiskProjects",
        indicatorColor: modelRisk.indexes.color,
        colorValues: true,
        isNotNeedPeriod: true,
        isRiskColumn: true,
        fractionDigits: 0,
      },
      {
        id: "0",
        sortId: 5,
        indicator: modelPassport.indexes.achievement,
        label: `Достижение по проектам, %`,
        subLabel: "Справочно",
        popUpLabel: "Достижение по проектам, % (Справочно)",
        sortable: true,
        model: modelPassport.modelId,
        colorValues: true,
        valuesUnit: "%",
        mainColumn: true,
        isLastColumn: true,
        notVisible: true,
        data: projectPassport,
        sortBy: "AchievementProjects",
        indicatorColor: modelPassport.indexes.achievementProjectColor,
        needPrevPeriod: true,
        prevPeriod: false,
      },
    ] as HeaderColumns[];
  }, [projectPassport, riskData, kpiData]);

  return { averageRiskAndProjectsColumns };
};
