import { useTheme } from "@mui/material";
import ReactECharts from "echarts-for-react";
import { useMemo } from "react";

import { ACHIEVEMENT_COLOR } from "@/shared/AchievementLevel/lib/types";
import { formatNumber } from "@/shared/lib/heplers";

import { AchievementLevelProps } from "../lib/types";
import {
  AchievementContainer,
  AchievementLable,
  AchievementLevelText,
  AchievementUnit,
  AchievementValue,
} from "./AchievementLevel.styled";

// Формирование круговых диаграмм в табах
export const AchievementLevel = ({
  unit,
  value,
  blur,
  radius,
  bunchValues,
  blurValue = 17,
  size = 120,
  isHideText = false,
  isHighLight = false,
  isOpenPopUp = false,
  text = "Уровень достижения",
  color = ACHIEVEMENT_COLOR.GREY,
}: AchievementLevelProps) => {
  const theme = useTheme();
  const resultValue =
    !value && value !== 0 ? "-" : formatNumber(value, undefined, 0);

  const setRadius = useMemo(() => {
    return [size - (size / 100) * 50 - 5, size - (size / 100) * 50];
  }, [size]);

  const [valuesData, colorData] = useMemo(() => {
    const valuesData =
      bunchValues && bunchValues.length
        ? bunchValues?.map((item) => Math.round(Number(item?.value)) || 0)
        : [
            { value: Math.round(Number(value)) || 0 },
            { value: Number(value) ? Math.round(100 - Number(value)) : 100 },
          ];
    const colorData =
      bunchValues && bunchValues.length
        ? bunchValues?.map((item) => item?.color)
        : [color, theme.palette.diagram.baseDiagram];

    return [valuesData, colorData];
  }, [value, bunchValues, theme, color]);

  const option = {
    series: [
      {
        type: "pie",
        radius: radius ? [radius, "100%"] : setRadius,
        data: valuesData,
        label: false,
        silent: true,
      },
    ],
    color: colorData,
  };

  return (
    <AchievementContainer size={size}>
      <ReactECharts
        option={option}
        style={{
          height: size,
          width: size,
          ...(isHighLight && {
            filter: `drop-shadow(0px 0px ${blurValue}px ${blur})`,
          }),
        }}
      />

      {!isHideText && (
        <AchievementLevelText>
          <AchievementUnit isOpenPopUp={isOpenPopUp}>{unit}</AchievementUnit>
          <AchievementValue
            isOpenPopUp={isOpenPopUp}
            color={
              resultValue === "-" ? theme.palette.diagram.baseDiagram : color
            }
          >
            {resultValue}
          </AchievementValue>
          <AchievementLable isOpenPopUp={isOpenPopUp}>{text}</AchievementLable>
        </AchievementLevelText>
      )}
    </AchievementContainer>
  );
};
