import { useTheme } from "@mui/material";
import React, { useMemo } from "react";

import { ReactComponent as LogoDark } from "@/assets/logo/logo_dark.svg";
import { ReactComponent as LogoLight } from "@/assets/logo/logo_light.svg";

import { AppBarLogo } from "../AppBar.styled";

export const AppBarLogotype = () => {
  const theme = useTheme();

  const receiveLogo = useMemo(() => {
    return theme.palette.mode === "dark" ? <LogoDark /> : <LogoLight />;
  }, [theme]);

  return <AppBarLogo> {receiveLogo} </AppBarLogo>;
};
