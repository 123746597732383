import {
  CFO,
  CoatOfArmsOfRF,
  DalnevostochnyiFO,
  PrivolzhskyiFO,
  SeveroKavkazskiyFO,
  SeveroZapadnyiFO,
  SibirskiyFO,
  UralskiyFO,
  YuzhnyiFO,
} from "@assets/federal_districts";

export const findFoCoatsOfArms = (idFo: string): React.ReactNode => {
  switch (idFo) {
    case "9":
      return <CFO />;
    case "25":
      return <SeveroZapadnyiFO />;
    case "34":
      return <YuzhnyiFO />;
    case "39":
      return <SeveroKavkazskiyFO />;
    case "45":
      return <PrivolzhskyiFO />;
    case "56":
      return <UralskiyFO />;
    case "61":
      return <SibirskiyFO />;
    case "71":
      return <DalnevostochnyiFO />;
    case "8":
      return <CoatOfArmsOfRF />;
    default:
      return <></>;
  }
};
