import React, { MouseEvent, useEffect, useState } from "react";
import { useParams } from "react-router";

import { useRisksGraph } from "@/features/RisksWidget/hooks/useRisksGraph";
import { RisksWidget } from "@/features/RisksWidget/RisksWidget";
import { ICONS } from "@/pages/ProjectsPage/lib/constants";
import { Achievements } from "@/shared/Achievements/ui/Achievements";
import { Header } from "@/shared/Achievements/ui/Achievements.styled";
import { isDevBranch } from "@/shared/lib/constants";
import { useRegionPopUp } from "@/shared/RegionPopUp/hooks/useRegionPopUp";
import { REGION_TABLES } from "@/shared/RegionPopUp/lib/types";
import { Switcher } from "@/shared/Switcher/Switcher";
import { useFuturePlan } from "@/shared/Table/hooks/useFuturePlan";
import { ValueDate } from "@/shared/ValueDate/ValueDate";
import { WidgetScrollbar } from "@/shared/WidgetScrollbar/ui/WidgetScrollbar";
import { useAppSelector } from "@/store/redux/hooks";
import { textForTabsAndTable } from "@/store/scheme/common";

import {
  achieveTitle,
  risksTitle,
  switcherRisksData,
  textUnderTitle,
} from "../lib/constants";
import { ACHIEVE_RISKS_VALUE, IAchieveRisksWrapperProps } from "../lib/types";
import { HeaderBase } from "./AchieveRisksWrapper.styled";

export const AchieveRisksWrapper = ({
  isOpenPopUp,
  callbackChange,
  switcher,
}: IAchieveRisksWrapperProps) => {
  const { id } = useParams<"id" | "tabId">();
  const { mainAchievements } = useAppSelector((state) => state.ui.tabs);
  const { isCheckRiskFuturePlan } = useFuturePlan();
  const [switcherValue, setSwitcherValue] = useState<ACHIEVE_RISKS_VALUE>(
    switcher?.mainSwitcher || ACHIEVE_RISKS_VALUE.ACHIEVE
  );
  const isCheckAchieve = [ACHIEVE_RISKS_VALUE.ACHIEVE].includes(switcherValue);
  const allSwitcherData = [...ICONS].reverse();
  const [activeIcon, setActiveIcon] = useState(
    switcher?.risksSwitcher || allSwitcherData[0]?.id
  );
  const { riskReportDate } = useRisksGraph();

  const handleChangeIcon = (
    event: React.MouseEvent<HTMLElement>,
    newIcon: string
  ) => {
    if (newIcon) {
      setActiveIcon(newIcon);
    }
  };

  useEffect(() => {
    if (!isCheckRiskFuturePlan) {
      setSwitcherValue(ACHIEVE_RISKS_VALUE.ACHIEVE);
    }
    return () => setSwitcherValue(ACHIEVE_RISKS_VALUE.ACHIEVE);
  }, [isCheckRiskFuturePlan, id]);

  const handleChange = (event: MouseEvent<HTMLElement>, newValue: string) => {
    if (newValue) {
      setSwitcherValue(newValue as ACHIEVE_RISKS_VALUE);
    }
    if (callbackChange) callbackChange();
  };

  const header = (isOpenPopUp?: boolean, component?: React.ReactNode) => {
    return (
      <Header>
        <HeaderBase
          direction="row"
          isCenter
          sx={{ justifyContent: "space-between", width: "88%" }}
        >
          <HeaderBase
            direction="column"
            style={{
              minWidth: "16rem",
              gap: "0.25rem",
            }}
          >
            <span
              style={
                isOpenPopUp
                  ? { fontSize: "1.625rem", paddingBottom: "0.5rem" }
                  : {}
              }
            >
              {isCheckAchieve ? achieveTitle : risksTitle}
            </span>
            <ValueDate isOpenPopUp={isOpenPopUp}>
              {isCheckAchieve ? (
                textForTabsAndTable
              ) : (
                <>
                  {riskReportDate} &nbsp;
                  {textUnderTitle}
                </>
              )}
            </ValueDate>
          </HeaderBase>
          {isCheckRiskFuturePlan && isDevBranch && (
            <HeaderBase direction="row" isCenter>
              <Switcher
                switcherData={switcherRisksData}
                value={switcherValue}
                callbackChange={handleChange}
                openWidget={isOpenPopUp}
                isSwitchIcon
              />
            </HeaderBase>
          )}
        </HeaderBase>
        {!isOpenPopUp && component}
      </Header>
    );
  };
  const { PopUp, PopUpButton, popupHeightMax }: any = useRegionPopUp(
    REGION_TABLES.ACHIEVEMENTS,
    isOpenPopUp,
    undefined,
    undefined,
    undefined,
    isDevBranch ? 1060 : 906,
    { mainSwitcher: switcherValue, risksSwitcher: activeIcon }
  );
  const getDataIncident = () => {
    return {
      date: isCheckAchieve
        ? mainAchievements?.["0"]?.["0"]?.REPORT_DATE ?? ""
        : riskReportDate ?? "",
      title: isCheckAchieve ? achieveTitle : risksTitle,
    };
  };

  return (
    <WidgetScrollbar
      isOpenPopUp={isOpenPopUp}
      titleIncident={getDataIncident().title}
      dateIncident={getDataIncident().date}
      header={header(isOpenPopUp, <PopUpButton />)}
      autoHeight={isOpenPopUp}
      heightMax={popupHeightMax}
    >
      <>
        {isCheckAchieve ? (
          <Achievements isOpenPopUp={isOpenPopUp} />
        ) : (
          <RisksWidget
            isOpenPopUp={isOpenPopUp}
            allSwitcherData={allSwitcherData}
            activeSwitcher={activeIcon}
            callbackActiveSwicher={handleChangeIcon}
          />
        )}
        <PopUp />
      </>
    </WidgetScrollbar>
  );
};
