import { Card, styled } from "@mui/material";

export const Root = styled(Card)(({ theme }) => ({
  boxSizing: "border-box",
  height: "100%",
  borderRadius: theme.spacing(0, 1, 1, 0),
  borderLeft: `1px solid ${theme.palette.table.divider}`,
}));

export const Header = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  boxSizing: "border-box",
  height: 60,
  width: "100%",
  padding: theme.spacing(2.5, 0),
  fontSize: 18,
  fontWeight: theme.typography.fontWeightMedium,
}));

export const Content = styled("div")<{
  isOpenPopUp: boolean;
}>(({ theme, isOpenPopUp }) => ({
  display: "flex",
  justifyContent: "space-between",
  boxSizing: "border-box",
  height: "100%",
  minHeight: isOpenPopUp ? 420 : 370,
  padding: isOpenPopUp ? theme.spacing(3, 5, 3, 3) : theme.spacing(2, 3),
  ...(isOpenPopUp && { background: "#1E2731" }),
}));

export const Box = styled("div")(({ theme }) => ({
  display: "flex",
  flexFlow: "column nowrap",
  gap: theme.spacing(3),
  width: "50%",
  color: theme.palette.text.tertiary,
}));

export const ChartsBox = styled(Box)(({ theme }) => ({
  gap: theme.spacing(7),
}));

export const TextBlock = styled("div")<{ isOpenPopUp?: boolean }>(
  ({ theme, isOpenPopUp = false }) => ({
    marginBottom: theme.spacing(1.5),
    "& > div.total": {
      paddingBottom: theme.spacing(1),
      fontWeight: 600,
      fontSize: theme.spacing(isOpenPopUp ? 3.5 : 2.75),
      lineHeight: theme.spacing(2.5),
      color: theme.palette.text.primary,
    },
    "& > div": {
      fontSize: theme.spacing(isOpenPopUp ? 3 : 2),
      ...(isOpenPopUp && { marginBottom: "0.25rem" }),
    },
  })
);

export const CircleWrapper = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
}));

export const LineValue = styled("span")<{
  color?: string;
  isOpenPopUp?: boolean;
}>(({ theme, color = "", isOpenPopUp }) => ({
  fontSize: isOpenPopUp ? 40 : 24,
  fontWeight: theme.typography.fontWeightBold,
  color: color ?? theme.palette.text.primary,
}));

export const CircleValue = styled(LineValue)(({ theme }) => ({
  position: "absolute",
}));

export const Unit = styled("span")<{ isOpenPopUp?: boolean }>(
  ({ theme, isOpenPopUp }) => ({
    fontSize: isOpenPopUp ? 26 : 16,
    marginLeft: theme.spacing(0.5),
  })
);

export const LineWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: theme.spacing(),
  width: "100%",
}));
