import { styled, Typography } from "@mui/material";

import {
  TypeCardDate,
  TypeCardSubTitle,
  TypeCardTitle,
  TypeGridCardStyled,
  TypeUnit,
} from "../lib/types";

export const GridCardStyled = styled("div")<TypeGridCardStyled>(
  ({
    theme,
    selected,
    gridArea,
    arrow,
    needStep = false,
    isHighlight = false,
    noBorder = false,
  }) => ({
    position: "relative",
    display: "flex",
    gap: theme.spacing(0.5),
    padding: theme.spacing(2, 3),
    boxSizing: "border-box",
    height: theme.spacing(25),
    ...(gridArea && {
      gridArea: gridArea,
      maxHeight: theme.spacing(25),
    }),
    color: selected ? theme.palette.text.onAccent : theme.palette.text.primary,
    cursor: "pointer",
    borderRadius: theme.spacing(2),
    ...(arrow && {
      marginRight: theme.spacing(1.5),
      height: theme.spacing(11.5),
    }),
    "&:before": {
      boxSizing: "border-box",
      content: arrow && (selected || needStep) && '""',
      position: "absolute",
      transform: "translate(calc(50% + 1px), -50%) rotate(45deg)",
      right: 0,
      top: "50%",
      width: theme.spacing(3),
      height: theme.spacing(3),
      background: selected
        ? theme.palette.indicatorCard.backgroundColorSelected
        : theme.palette.indicatorCard.backgroundColor,
      borderTop: `2px solid ${
        theme.palette.border[selected ? "selected" : "default"]
      }`,
      borderRight: `2px solid ${
        theme.palette.border[selected ? "selected" : "default"]
      }`,
      zIndex: 10,
    },
    ...(!noBorder && {
      background: selected
        ? theme.palette.indicatorCard.backgroundColorSelected
        : theme.palette.indicatorCard.backgroundColor,
      border: `2px solid ${
        theme.palette.border[selected ? "selected" : "default"]
      }`,
    }),
    ...(isHighlight && {
      background: theme.palette.tabs.highlightTab,
      boxShadow: `0px 0px 19px ${theme.palette.border.selected}`,
    }),
    "& > .parentIncident > .hoverIncident": {
      display: "none",
    },
    "&:hover > .parentIncident > .hoverIncident": {
      display: "block",
    },
  })
);

export const CardHeader = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
}));

export const CardTitle = styled(Typography, {
  shouldForwardProp: (prop) =>
    !["isAchievement", "arrow"].includes(prop.toString()),
})<TypeCardTitle>(({ theme, selected, isAchievement, arrow = false }) => ({
  fontSize: theme.spacing(2.25),
  lineHeight: theme.spacing(isAchievement ? 2.75 : 2.25),
  ...(!isAchievement && {
    height: theme.spacing(4.67),
  }),
  color: selected ? theme.palette.text.onAccent : theme.palette.text.primary,
  ...(arrow && { lineHeight: "1" }),
  "&.goals-title": {
    color: selected ? theme.palette.text.onAccent : theme.palette.text.tertiary,
  },
  "&.fix-title": {
    height: theme.spacing(5.5),
    marginBottom: theme.spacing(2),
  },
  "@media (max-width: 112.5rem)": {
    fontSize: theme.spacing(2.1),
  },
}));

export const CardSubTitle = styled(Typography, {
  shouldForwardProp: (prop) =>
    !["compactTitle", "splitLines", "arrow"].includes(prop.toString()),
})<TypeCardSubTitle>(
  ({
    theme,
    compactTitle,
    splitLines,
    selected,
    isHighLight,
    arrow = false,
  }) => ({
    ...(compactTitle && { display: "inline" }),
    ...(splitLines && { display: "block" }),
    color: selected ? theme.palette.text.onAccent : theme.palette.text.tertiary,
    ...(arrow && { lineHeight: "1" }),
    ...(isHighLight && {
      textShadow: `0px 0px 0.25rem ${theme.palette.tabs.textColorDontSelect}`,
    }),
  })
);

export const Unit = styled("span")<TypeUnit>(({ theme, selected }) => ({
  fontSize: 14,
  margin: theme.spacing(0, 0, 0, 0.5),
  color: selected
    ? theme.palette.text.unitTextSelected
    : theme.palette.text.unitText,
}));

export const CardContent = styled("div")(({ theme }) => ({
  flex: 1,
  display: "flex",
  alignItems: "center",
  "&.alignment": {
    flex: 0,
  },
}));

export const ValueColor = styled("span")<{
  isRed?: boolean;
  fullHeight?: boolean;
}>(({ theme, isRed, fullHeight }) => ({
  fontSize: fullHeight ? theme.spacing(6.5) : theme.spacing(2.5),
  ...(typeof isRed !== "undefined" && {
    color: isRed ? theme.palette.error.main : theme.palette.success.main,
  }),
}));

export const CardDate = styled("span")<TypeCardDate>(
  ({ theme, dateLeft, prevPeriod, selected }) => ({
    position: "absolute",
    bottom: prevPeriod ? 21 : 8,
    ...(dateLeft ? { left: 14 } : { right: 14 }),
    fontSize: 12,
    color: selected ? theme.palette.text.lightGrey : theme.palette.tabs.dateTab,
    textOverflow: "ellipsis",
    overflow: "hidden",
    "@media (max-width: 108.75rem)": {
      fontSize: 11.2,
    },
  })
);
