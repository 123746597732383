import { useAppSelector } from "@store/redux/hooks";
import React from "react";

import {
  BoxStyled,
  RegionGridCell,
  RegionGridManufacturing,
} from "@/pages/RegionPage/ui/RegionMutableInfo/ui/RegionMutableInfo.styled";
import { Budget } from "@/shared/Budget/ui/Budget";

import { DistrictValuesManufacturing, VEDManufacturing } from "./components";

export const RegionMutableManufacturing = () => {
  const { user } = useAppSelector((state) => state.auth.user);

  return user?.isAnalyticFK ? null : (
    <BoxStyled fitHeight>
      <RegionGridManufacturing>
        <RegionGridCell sx={{ gridArea: "DistrictValues" }}>
          <DistrictValuesManufacturing />
        </RegionGridCell>
        <RegionGridCell sx={{ gridArea: "VED" }}>
          <VEDManufacturing />
        </RegionGridCell>
        <RegionGridCell sx={{ gridArea: "Budget" }}>
          <Budget />
        </RegionGridCell>
      </RegionGridManufacturing>
    </BoxStyled>
  );
};
