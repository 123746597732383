import {
  Card,
  styled,
  Table,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import { REGION_LEVEL } from "../lib/types";
import {
  IBasicTableCellProps,
  IBasicTableRegionContainerProps,
  IBasicTableRowProps,
  IBasicTableStyledProps,
  IStickyTableCellProps,
} from "./lib/types";

export const CardStyled = styled(Card)(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  boxSizing: "border-box",
  overflow: "auto",
  height: "100%",
  boxShadow: "none",
}));

export const BasicTableStyled = styled(Table, {
  shouldForwardProp: (prop) =>
    !["fullHeight", "horizontalBorders", "darkTheme", "isHeaderColor"].includes(
      prop.toString()
    ),
})<IBasicTableStyledProps>(
  ({ theme, fullHeight, horizontalBorders, darkTheme, isHeaderColor }) => {
    if (darkTheme) {
      return {
        borderCollapse: "separate",
        minHeight: fullHeight ? "calc(100% - 10px);" : undefined,
        backgroundColor: theme.palette.background.paper,

        "& .MuiTableCell-root": {
          paddingTop: theme.spacing(0.5),
          paddingBottom: theme.spacing(0.5),
          lineHeight: 1.25,
        },
        "& .MuiTableCell-head": {
          color: theme.palette.text.primary,
          backgroundColor: isHeaderColor
            ? theme.palette.table.header
            : theme.palette.background.paper,
          borderLeft: "unset",
          borderBottom: "none",
        },
        "& .MuiTableCell-body": {
          borderLeft: "unset",
          borderBottom: `1px solid ${theme.palette.table.dividerLight}`,
        },
      };
    }
    return {
      backgroundColor: theme.palette.background.paper,
      borderCollapse: "separate",
      minHeight: fullHeight ? "calc(100% - 10px);" : undefined,

      "& .MuiTableCell-root": {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        lineHeight: 1.25,
      },
      "& .MuiTableCell-head": {
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.background.paper,
        borderLeft: "unset",
        borderRight: `1px solid ${
          theme.palette.table[
            theme.palette.mode === "dark" ? "divider" : "dividerLight"
          ]
        }`,
        borderBottom: `1px solid ${
          theme.palette.table[
            theme.palette.mode === "dark" ? "divider" : "dividerLight"
          ]
        }`,
      },
      "& .MuiTableCell-body": {
        borderLeft: "unset",
        borderRight: `1px solid ${
          theme.palette.table[
            theme.palette.mode === "dark" ? "divider" : "dividerLight"
          ]
        }`,
        borderBottom: horizontalBorders
          ? `1px solid ${
              theme.palette.table[
                theme.palette.mode === "dark" ? "divider" : "dividerLight"
              ]
            }`
          : "none",
      },
      "& .MuiTableRow-root:first-of-type": {
        "& .MuiTableCell-root:first-of-type": {
          borderLeft: "unset",
        },
      },
    };
  }
);

export const BasicTableRow = styled(TableRow)<IBasicTableRowProps>(
  ({ theme, pinned, level = REGION_LEVEL.COUNTRY }) => {
    let fontSize;
    let fontWeight;

    switch (level) {
      case REGION_LEVEL.COUNTRY:
        fontSize = 20;
        fontWeight = theme.typography.fontWeightSemiBold;
        break;
      case REGION_LEVEL.DISTRICT:
        fontSize = 20;
        fontWeight = theme.typography.fontWeightMedium;
        break;
      case REGION_LEVEL.REGION:
      default:
        fontSize = theme.typography.fontSize;
        fontWeight = theme.typography.fontWeightRegular;
        break;
    }

    return {
      "& .MuiTableCell-body": {
        fontSize,
        fontWeight,
        backgroundColor: pinned ? theme.palette.table.pinned : undefined,
        boxSizing: "border-box",
      },
    };
  }
);

export const BasicTableInlineContainer = styled("div")({
  display: "flex",
  alignItems: "center",
});

export const BasicTableRegionContainer = styled(
  BasicTableInlineContainer
)<IBasicTableRegionContainerProps>(({ theme, level = REGION_LEVEL.REGION }) => {
  let minHeight;

  switch (level) {
    case REGION_LEVEL.COUNTRY:
      minHeight = theme.spacing(5);
      break;
    case REGION_LEVEL.DISTRICT:
      minHeight = theme.spacing(5);
      break;
    case REGION_LEVEL.REGION:
    default:
      minHeight = theme.spacing(5);
      break;
  }

  return {
    minHeight,
  };
});

export const StickyTableCell = styled(TableCell, {
  shouldForwardProp: (prop) =>
    !["stickyPosition", "stickyLast", "alignText", "stickyWidth"].includes(
      prop.toString()
    ),
})<IStickyTableCellProps>(
  ({ theme, stickyPosition, width, stickyLast, alignText }) => {
    const style = {
      width,
      minWidth: width,
      "& > div": {
        justifyContent: alignText === "right" && "flex-end",
      },
    };

    if (stickyPosition == null || isNaN(stickyPosition)) {
      return style;
    }

    return {
      "&.MuiTableCell-head": {
        ...style,
        position: "sticky",
        left: stickyPosition,
        top: 0,
        boxSizing: "border-box",
        backgroundColor: theme.palette.background.paper,
        borderRightWidth: stickyLast ? 3 : undefined,
        zIndex: 2,
      },

      "&.MuiTableCell-body": {
        ...style,
        position: "sticky",
        left: stickyPosition,
        boxSizing: "border-box",
        backgroundColor: theme.palette.background.paper,
        borderRightWidth: stickyLast ? 3 : undefined,
        zIndex: 1,
      },
    };
  }
);

export const LevelTableCell = styled(StickyTableCell)<IBasicTableCellProps>(
  ({ theme, level = REGION_LEVEL.COUNTRY }) => ({
    paddingLeft: theme.spacing(2 + 5 * (level < 3 ? level : 2)),
  })
);

export const StickyTableHead = styled(TableHead)({
  position: "sticky",
  top: 0,
  zIndex: 5,
});
