import { ILegendData } from "@/shared/Legend/lib/types";

export const risksLegend: ILegendData[] = [
  {
    id: 1,
    type: "circle",
    color: "#FF5368",
    isBlur: true,
    sizeWidth: 12,
    sizeHeight: 12,
    title: "Высокий риск",
  },
  {
    id: 2,
    type: "circle",
    color: "#FFA24C",
    isBlur: true,
    sizeWidth: 12,
    sizeHeight: 12,
    title: "Средний риск",
  },
  {
    id: 3,
    type: "circle",
    color: "#11E981",
    isBlur: true,
    sizeWidth: 12,
    sizeHeight: 12,
    title: "Без риска",
  },
];
