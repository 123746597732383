import { combineReducers } from "@reduxjs/toolkit";
import achievementSlice from "@shared/AchievementControl/model/achievementSlice";
import compareSlice from "@shared/CompareControl/model/compareSlice";
import periodSlice from "@shared/PeriodControl/model/periodSlice";

import { IRegionsControlState } from "@/pages/RegionPage/lib/types";
import regionsSlice from "@/pages/RegionPage/model/slices/regionControlSlice";
import { IAchievementState } from "@/shared/AchievementControl/lib/types";
import { ICompareState } from "@/shared/CompareControl/lib/types";
import { IPeriodState } from "@/shared/PeriodControl/lib/types";

export interface IControlsReducer {
  achievement: IAchievementState;
  compare: ICompareState;
  period: IPeriodState;
  regions: IRegionsControlState;
}

const reducer = combineReducers<IControlsReducer>({
  achievement: achievementSlice.reducer,
  compare: compareSlice.reducer,
  period: periodSlice.reducer,
  regions: regionsSlice.reducer,
});

export default reducer;
