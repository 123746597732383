import { Card, styled } from "@mui/material";

export const VDLStyled = styled(Card, {
  shouldForwardProp: (prop) => !["isOpenPopUp"].includes(prop.toString()),
})<{ isOpenPopUp: boolean }>(({ theme, isOpenPopUp }) => ({
  position: "relative",
  display: "flex",
  gap: theme.spacing(3),
  flexDirection: "column",
  justifyContent: "space-between",
  fontSize: theme.spacing(2),
  padding: theme.spacing(isOpenPopUp ? 3 : 1, 3, 3, 3),
  height: "100%",
  boxSizing: "border-box",
  ...(isOpenPopUp && {
    background: "inherit",
  }),
}));

export const StatisticsWrapper = styled("div")<{ openWidget?: boolean }>(
  ({ theme, openWidget }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(openWidget ? 4 : 2),
  })
);
export const Statistics = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(3),
}));

export const StatisticElement = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "50%",
  minHeight: theme.spacing(10),
}));

export const StatisticPLaceInfo = styled("div")(({ theme }) => ({
  display: "flex",
  fontWeight: "bold",
  minHeight: theme.spacing(4),
  gap: theme.spacing(2.5),
  alignItems: "center",
}));

export const StatisticNumber = styled("span")<{ isOpenPopUp?: boolean }>(
  ({ theme, isOpenPopUp }) => ({
    fontSize: theme.spacing(isOpenPopUp ? 5 : 3),
    [theme.breakpoints.down(1788)]: {
      fontSize: theme.spacing(isOpenPopUp ? 5 : 2.5),
    },
  })
);

export const StatisticDescription = styled("span")(({ theme }) => ({
  fontSize: theme.spacing(1.5),
  color: theme.palette.text.secondary,
}));

export const NCLinkStyled = styled("a")({
  display: "flex",
  width: 117,
});

export const XText = styled("span")(({ theme }) => ({
  alignSelf: "flex-start",
  fontWeight: "normal",
  fontSize: theme.spacing(1.75),
  color: theme.palette.success.main,
}));

export const TextStyled = styled("span")<{ isOpenPopUp?: boolean }>(
  ({ theme, isOpenPopUp }) => ({
    fontSize: theme.spacing(isOpenPopUp ? 2.25 : 1.5),
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.text.light,
    ...(isOpenPopUp && {
      maxWidth: theme.spacing(38),
      lineHeight: theme.spacing(2.5),
    }),
  })
);

export const StatisticContainer = styled("div", {
  shouldForwardProp: (prop) => !["isOpenPopUp"].includes(prop.toString()),
})<{ isOpenPopUp?: boolean }>(({ theme, isOpenPopUp }) => ({
  display: "flex",
  alignItems: "baseline",
  gap: theme.spacing(0.5),
  flexWrap: "nowrap",
  color: theme.palette.text.primary,
  fontSize: theme.spacing(isOpenPopUp ? 4 : 2),
}));

export const LinkDateWrapper = styled("div")<{ openWidget?: boolean }>(
  ({ theme, openWidget }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    ...(openWidget && {
      paddingTop: theme.spacing(13.5),
    }),
  })
);

export const VDLHeader = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "102%",
}));

export const LeaderWrapper = styled("div", {
  shouldForwardProp: (prop) => !["isOpenPopUp"].includes(prop.toString()),
})<{ isOpenPopUp?: boolean }>(({ theme, isOpenPopUp }) => ({
  ...(!isOpenPopUp && { paddingTop: theme.spacing(3) }),
}));
