import {
  SceletonCircleStyled,
  SceletonLine1Styled,
} from "../../Sceleton.styled";
import { SceletonPartStyled } from "../SceletonCard/SceletonCard.styled";
import {
  SceletonAchieveColumn,
  SceletonAchieveContent,
  SceletonAchieveStyled,
} from "./SceletonAchieve.styled";

export const SceletonAchieve = (props: { size?: number }) => {
  const { size } = props;
  return (
    <SceletonAchieveStyled>
      <SceletonAchieveColumn width={290}>
        <SceletonPartStyled sx={{ alignItems: "flex-start" }}>
          <SceletonLine1Styled widthLine="80%" />
          <SceletonLine1Styled widthLine="50%" />
        </SceletonPartStyled>
        <SceletonCircleStyled size={`${size || 240}px`} />
      </SceletonAchieveColumn>
      <SceletonAchieveColumn
        width={490}
        sx={{ justifyContent: "space-between" }}
      >
        <SceletonAchieveContent>
          <SceletonPartStyled sx={{ alignItems: "flex-start" }}>
            <SceletonLine1Styled widthLine="50%" />
            <SceletonLine1Styled widthLine="33%" />
          </SceletonPartStyled>
          <SceletonLine1Styled widthLine="100%" />
        </SceletonAchieveContent>
        <SceletonAchieveContent>
          <SceletonPartStyled sx={{ alignItems: "flex-start" }}>
            <SceletonLine1Styled widthLine="50%" />
            <SceletonLine1Styled widthLine="33%" />
          </SceletonPartStyled>
          <SceletonLine1Styled widthLine="100%" />
        </SceletonAchieveContent>
      </SceletonAchieveColumn>
    </SceletonAchieveStyled>
  );
};
