import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IPeriodState } from "../lib/types";
import { getPeriodFilterData } from "./api";

export const initialState: IPeriodState = {
  enabled: true,
  fetching: false,
  data: undefined,
  currentPeriod: "0",
  isUpdatePeriod: false,
  error: null,
};

export const extraActionsPeriod = {
  periodGet: createAsyncThunk(
    "controls/periods/get",
    async () => await getPeriodFilterData()
  ),
};

const periodSlice = createSlice({
  name: "controls/period",
  initialState,
  reducers: {
    periodToggle: (state, action: PayloadAction<boolean>) => {
      state.enabled = action.payload;
    },
    periodUpdate: (state, action: PayloadAction<string>) => {
      state.currentPeriod = action.payload;
    },
    periodIsUpdate: (state, action: PayloadAction<boolean>) => {
      state.isUpdatePeriod = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(extraActionsPeriod.periodGet.pending, (state) => {
        state.data = undefined;
        state.fetching = true;
      })
      .addCase(extraActionsPeriod.periodGet.fulfilled, (state, action) => {
        state.data = action.payload.filter(
          (item) => (item?.attributeVals as any)?.visibility === "true"
        );
        state.fetching = false;
        state.error = null;
      })
      .addCase(extraActionsPeriod.periodGet.rejected, (state, action) => {
        state.fetching = false;
        state.error = action.error;
      });
  },
});

export const { periodToggle, periodUpdate, periodIsUpdate } =
  periodSlice.actions;
export const reducer = periodSlice.reducer;

export default periodSlice;
