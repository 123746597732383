import { BigValue } from "@/shared/DynamicsTabCard/ui/components/IndicatorCard/IndicatorCard.styled";
import { GridCard } from "@/shared/GridCard/ui/GridCard";
import { formatNumber } from "@/shared/lib/heplers";
import { Sceleton } from "@/shared/Sceleton/ui/Sceleton";

import { BacklogCardProps } from "../../../lib/types";
import { Divider, ValueColor } from "./Backlog.styled";

// Формирование таба Проекты / с отставанием от плана (главная и карточка субъекта)
export const BacklogCard = ({
  selected,
  tab,
  setSelectedTabId,
  tabFetching = false,
}: BacklogCardProps) => {
  const { value, projects, color } = tab ?? {};

  if (tabFetching) {
    return <Sceleton type="circle" />;
  }

  return (
    <GridCard
      data={tab}
      selected={selected}
      setSelectedTabId={setSelectedTabId}
      className="goals-title fix-title"
      classNameContent="alignment"
    >
      <BigValue selected={selected} style={{ whiteSpace: "nowrap" }}>
        {projects ?? "-"}

        <Divider>{" / "}</Divider>

        <ValueColor valueColor={color} selected={selected}>
          {formatNumber(value, undefined, 0) ?? "-"}
        </ValueColor>
      </BigValue>
    </GridCard>
  );
};
