import { getOperand, olapRequestData2 } from "@/store/api/core";
import {
  FILTER_OPERAND_TYPE,
  FILTER_TYPE,
  MODEL_IDS,
  MODELS,
} from "@/store/scheme/olap";

export const getAllVDL = () => {
  const model = MODELS[MODEL_IDS.VDL];
  const dimIds = [
    model.dataPeriodId,
    model.dataRegionId,
    model.dataRegionLeader,
    model.dimId,
    model.dataActualId,
  ];

  const dimensions = [
    {
      id: model.dataPeriodId,
      includeItems: true,
      includeAttributesByCodes: ["REPORT_DATE"],
    },
    {
      id: model.dataRegionId,
    },
    {
      id: model.dataRegionLeader,
      includeItems: true,
      includeAttributesByCodes: ["FIO", "photo"],
    },
    {
      id: model.dimId,
    },
    {
      id: model.dataActualId,
    },
  ];

  const indicators = {
    id: model.indicatorId,
    items: [{ id: model.indexes.fact }],
  };

  const operands = [
    getOperand({
      type: FILTER_OPERAND_TYPE.AND,
      operands: [
        getOperand({
          type: FILTER_OPERAND_TYPE.LEVEL,
          dim_id: model.dataRegionId,
          levels: [2],
          showChildren: false,
          showLeafsOnly: false,
          showAllLevelElements: false,
        }),
        getOperand({
          type: FILTER_OPERAND_TYPE.LEVEL,
          dim_id: model.dataRegionLeader,
          levels: [1],
          showChildren: false,
          showLeafsOnly: false,
          showAllLevelElements: false,
        }),
      ],
    }),
    getOperand({
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        getOperand({
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dimId,
        }),
        getOperand({
          type: FILTER_OPERAND_TYPE.CONST,
          values: ["105"],
        }),
      ],
    }),
    getOperand({
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        getOperand({
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataActualId,
        }),
        getOperand({
          type: FILTER_OPERAND_TYPE.CONST,
          values: ["2"],
        }),
      ],
    }),
  ];

  return olapRequestData2(
    dimIds,
    model.indicatorId,
    {
      modelUuid: model.uuid,
      includeGaps: false,
      indicatorId: model.indicatorId,
      dimensions,
      dataRequestCaching: true,
      indicators,
      dataFilter: {
        type: FILTER_TYPE.AND,
        version: 1,
        operands,
      },
    },
    "2522e199-3220-4eb9-9274-a2eb7b11c9e0"
  );
};
