import { Dialog, DialogProps, styled } from "@mui/material";

import { ExpandButtonStyled } from "@/shared/Table/ui/TableEntities/ExpandButton/ExpandButton.styled";

import { WrapperProps } from "../lib/types";

export const RegionsPopUp = styled(Dialog)<
  DialogProps & { width?: string | number }
>(({ width }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& > .MuiDialog-container > .MuiPaper-root.MuiDialog-paper": {
    overflow: "hidden",
    height: "auto",
    maxWidth: "90vw",
    width: width,
  },
}));

export const RegionPopUpHeader = styled("div")<{
  minWidth?: number;
  isChangeHeight?: boolean;
}>(({ theme, minWidth, isChangeHeight }) => ({
  display: "flex",
  height: theme.spacing(10),
  minWidth: minWidth ? minWidth : "34rem",
  backgroundColor: theme.palette.popup.header,
  padding: theme.spacing(0, 3, 0, 3),
  alignItems: "center",
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: "1.625rem",
  borderRadius: theme.spacing(1, 1, 0, 0),
  color: theme.palette.primary.contrastText,
  boxSizing: "border-box",
  ...(isChangeHeight && {
    height: theme.spacing(0),
  }),
  "& > .title": {
    display: "flex",
    flex: "1",
    alignItems: "center",
    gap: "1rem",
  },
}));

export const RegionPopUpBody = styled("div")<{ colorBody?: boolean }>(
  ({ theme, colorBody }) => ({
    height: "100%",
    background: colorBody
      ? theme.palette.table.lightDark
      : theme.palette.popup.popupBackground,
    backgroundColor: colorBody
      ? theme.palette.table.lightDark
      : theme.palette.popup.popupBackground,
    position: "relative",
    "& > .MuiPaper-root": {
      background: theme.palette.popup.popupBackground,
      backgroundColor: theme.palette.popup.popupBackground,
      height: "100%",
    },
  })
);

export const ButtonWrapper = styled("div")<Pick<WrapperProps, "scale">>(
  ({ theme }) => ({
    marginLeft: "auto",
  })
);

export const ButtonPlug = styled(ExpandButtonStyled)(({ theme }) => ({
  backgroundColor: "transparent",
}));
