import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import RemoveIcon from "@mui/icons-material/Remove";
import { FC } from "react";

import { IExpandButton } from "@/shared/Table/lib/types";

import { ExpandButtonStyled } from "./ExpandButton.styled";

export const ExpandButton: FC<IExpandButton> = ({
  children,
  isExpanded,
  size,
  darkTheme,
  closeIcon,
  ...props
}) => {
  const icon = isExpanded ? (
    closeIcon ? (
      <CloseIcon fontSize={size} />
    ) : (
      <RemoveIcon fontSize={size} />
    )
  ) : (
    <AddIcon fontSize={size} />
  );
  return (
    <ExpandButtonStyled size={size} darkTheme={darkTheme} {...props}>
      {children ?? icon}
    </ExpandButtonStyled>
  );
};
