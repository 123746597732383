import {
  SceletonCircleStyled,
  SceletonLine1Styled,
  SceletonLine2Styled,
} from "../../Sceleton.styled";
import { SceletonCardStyled, SceletonPartStyled } from "./SceletonCard.styled";

export const SceletonCard = () => {
  return (
    <SceletonCardStyled>
      <SceletonPartStyled direction="row">
        <SceletonCircleStyled size="3rem" />
        <SceletonLine2Styled widthLine="100%" />
      </SceletonPartStyled>
      <SceletonLine1Styled widthLine="100%" sx={{ marginBottom: "1.5rem" }} />
      <SceletonPartStyled>
        <SceletonLine1Styled widthLine="100%" />
        <SceletonLine1Styled widthLine="100%" />
      </SceletonPartStyled>
      <SceletonPartStyled sx={{ alignItems: "flex-start" }}>
        <SceletonLine1Styled widthLine="100%" />
        <SceletonLine1Styled widthLine="33%" />
      </SceletonPartStyled>
    </SceletonCardStyled>
  );
};
