import {
  InputBase,
  ListItemText,
  MenuItem,
  Popper,
  Select,
  Stack,
  styled,
} from "@mui/material";

export const BootstrapInput = styled(InputBase)<{ title?: string }>(
  ({ theme, title }) => {
    return {
      "&.MuiInputBase-root > .MuiModal-root > .MuiPaper-root": {
        background: theme.palette.menu.itemBackground,
        border: `1px solid ${theme.palette.menu.itemBackground}`,
        padding: theme.spacing(0, 2),
      },
      "label + &": {
        marginTop: theme.spacing(3),
      },

      "& > .MuiSvgIcon-root": {
        color: theme.palette.icon.color,
      },

      "& .MuiInputBase-input": {
        height: `${theme.spacing(title ? 7.5 : 5)} !important`,
        boxSizing: "border-box",
        borderRadius: theme.spacing(),
        position: "relative",
        backgroundColor: theme.palette.button.background,
        border: `1px solid ${theme.palette.table.divider}`,
        fontSize: 14,
        padding: theme.spacing(),
        transition: theme.transitions.create(["border-color", "box-shadow"]),
      },
    };
  }
);

export const ListTextStyled = styled(ListItemText)(({ theme }) => ({
  margin: theme.spacing(0),
  color: theme.palette.text.secondary,
  "& .MuiListItemText-primary": {
    fontWeight: theme.typography.fontWeightSemiBold,
    fontSize: theme.spacing(1.75),
  },
  "& .MuiListItemText-secondary": {
    color: theme.palette.text.light,
    fontWeight: theme.typography.fontWeightSemiBold,
    fontSize: theme.spacing(2),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

export const WrapperStack = styled(Stack)(({ theme }) => ({
  width: theme.spacing(37.5),
  overflow: "hidden",
  "@media (max-width: 1550px)": {
    maxWidth: theme.spacing(25),
  },
}));

export const SelectStyled = styled(Select)(({ theme }) => ({
  "& >.MuiModal-root": {
    "& > .MuiPaper-root": {
      borderRadius: theme.spacing(0.5),
      border: `1px solid ${theme.palette.primary.light}`,
      overflow: "unset",
      background: theme.palette.appBar.substrateColor,
    },
  },
}));

export const MenuItemWrapper = styled(MenuItem)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(0.75, 1.5),
  borderRadius: theme.spacing(0.5),
  "&.Mui-selected": {
    background: theme.palette.dropdown.background,
    borderRadius: theme.spacing(0.5),
    color: theme.palette.primary.contrastText,
  },
  "&:hover": {
    background: theme.palette.menu.itemBackgroundHover,
  },
}));

export const MenuItemPopper = styled(Popper)(({ theme }) => ({
  zIndex: 1400,
  maxHeight: "100vh",
  overflow: "auto",
  background: theme.palette.menu.itemBackground,
  border: `1px solid ${theme.palette.menu.itemBackground}`,
  padding: theme.spacing(0, 2),
  borderRadius: theme.spacing(0.5),
}));

export const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  margin: theme.spacing(),
  padding: theme.spacing(1),
  borderRadius: theme.spacing(0.5),
  color: theme.palette.text.primary,
  "&.Mui-selected": {
    background: theme.palette.dropdown.background,
    borderRadius: theme.spacing(0.5),
    color: theme.palette.primary.contrastText,
  },
  "&:hover": {
    background: theme.palette.menu.itemBackgroundHover,
  },
}));

export const MenuItemDropdown = styled("div")(({ theme }) => ({
  position: "absolute",
}));
