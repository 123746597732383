import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { Typography } from "@mui/material";
import React from "react";

import { listFilesToUpload } from "@/pages/Faq/ui/FaqContent/ui/FaqFilesList/lib/helpers";
import { ListItemIconStyled } from "@/pages/NormativeLegalActs/ui/ActsContent/ui/ActsContent.styled";
import { ContentInfo, ContentWrapper } from "@/pages/Reports/ui/Reports.styled";
import { Icon } from "@/shared/CustomIcon/Icon.styled";
import { DownloadLink } from "@/shared/DownloadLink/DownloadLink";
import { PUBLIC_URL } from "@/shared/lib/constants";

import { FileList, FilesListWrapper } from "../../FaqContent.styled";

export const FaqFilesList = () => {
  return (
    <FilesListWrapper>
      {listFilesToUpload.map(({ id, title, file, type }) => {
        return (
          <FileList key={id}>
            <ContentWrapper style={{ alignItems: "center" }}>
              <ListItemIconStyled>
                <Icon>
                  <TextSnippetIcon />
                </Icon>
                {type?.toUpperCase()}
              </ListItemIconStyled>

              <ContentInfo>
                <Typography>{title}</Typography>
              </ContentInfo>
            </ContentWrapper>

            <DownloadLink href={`${PUBLIC_URL}/files/${file}`} />
          </FileList>
        );
      })}
    </FilesListWrapper>
  );
};
