import { FC, useMemo } from "react";

import NationalRateIcon from "@/assets/NatinalRate.png";
import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { useGeneralIndicators } from "@/shared/GeneralIndicators/hooks/useGeneralIndicators";
import { IValue } from "@/shared/GeneralIndicators/lib/types";
import { GeneralIndicators } from "@/shared/GeneralIndicators/ui/GeneralIndicators";
import { formatNumber } from "@/shared/lib/heplers";
import { IPopUpProps, REGION_TABLES } from "@/shared/RegionPopUp/lib/types";
import { ValueChangeSup } from "@/shared/Value/Value.styled";
import { MODEL_IDS, MODELS } from "@/store/scheme/olap";

import { NationalRateStyled } from "./GeneralIndicatorsInvestment.styled";

export const GeneralIndicatorsInvestment: FC<IPopUpProps> = ({
  isOpenPopUp,
}) => {
  const model = MODELS[MODEL_IDS.KPI_SOC_ECONOMY];

  const { data, investmentData, indicators, generalIndicatorsTitle, fetching } =
    useGeneralIndicators();
  const { isCheckRfFoPage, getSubject } = useRegions();

  const values: IValue[] = useMemo(() => {
    return ["54", "7", "55"].map((value) => {
      let subjectData;
      if (isCheckRfFoPage.isAll && value === "7") {
        subjectData = investmentData?.data?.[value];
      } else {
        subjectData = data?.[value];
      }

      return {
        key: value,
        align: value === "54" ? "top" : undefined,
        icon:
          value === "7" ? (
            <NationalRateStyled
              src={NationalRateIcon}
              alt="Национальный рейтинг"
            />
          ) : undefined,
        title: value !== "55" ? subjectData?.short_name : undefined,
        date:
          value !== "55"
            ? subjectData?.attributeVals?.REPORT_DATE
            : subjectData?.attributeVals?.PREVIOUS_DATE,
        place:
          value === "54"
            ? subjectData?.indicatorVals?.[indicators.place]
            : undefined,
        value: formatNumber(
          isCheckRfFoPage.isAll && value === "54"
            ? subjectData?.indicatorVals?.[model.indexes.fact] / 1000000
            : subjectData?.indicatorVals?.[model.indexes.fact],
          undefined,
          value === "7" ? 0 : 1
        ),
        valueHL:
          value === "55"
            ? subjectData?.indicatorVals?.[indicators.highLow]
            : undefined,
        valueColor:
          value === "55"
            ? subjectData?.indicatorVals?.[model.indexes.color]
            : undefined,
        unit:
          value === "7" && !isCheckRfFoPage.isAll ? (
            <>
              <span>место из </span>
              {formatNumber(
                subjectData?.indicatorVals?.[model.indexes.factChangeYearStart],
                undefined,
                0
              )}
              <ValueChangeSup
                difference={
                  subjectData?.indicatorVals?.[model.indexes.colorChangeAptg]
                }
              >
                {formatNumber(
                  subjectData?.indicatorVals?.[model.indexes.factChangeAptg],
                  undefined,
                  0,
                  true
                )}
              </ValueChangeSup>
            </>
          ) : value === "7" && isCheckRfFoPage.isAll ? (
            <span>ТОП-3 по рейтингу АСИ</span>
          ) : value === "54" && isCheckRfFoPage.isAll ? (
            "трилл руб"
          ) : (
            subjectData?.UNIT
          ),
        placeChange:
          value === "54"
            ? formatNumber(
                subjectData?.indicatorVals?.[indicators.placeChange],
                undefined,
                0,
                true
              )
            : undefined,
        placeChangeRate: subjectData?.indicatorVals?.[indicators.colorChange],
        topPlace:
          value === "7" && isCheckRfFoPage.isRF
            ? investmentData?.arr.slice(0, 3)
            : value === "7" && isCheckRfFoPage.isFO
            ? investmentData?.arr
                .filter((item: any) => item.parent === getSubject)
                .slice(0, 3)
            : [],
      };
    });
  }, [
    data,
    getSubject,
    investmentData.arr,
    investmentData.data,
    indicators.colorChange,
    indicators.highLow,
    indicators.place,
    indicators.placeChange,
    isCheckRfFoPage.isAll,
    isCheckRfFoPage.isRF,
    isCheckRfFoPage.isFO,
  ]);

  return (
    <GeneralIndicators
      generalFetching={fetching}
      isNotGetDate={true}
      title={generalIndicatorsTitle}
      typography="light"
      indicatorsFontStyle="italic"
      values={values}
      isOpenPopUp={isOpenPopUp}
      popUpId={REGION_TABLES.GENERAL_INDICATORS_INVESTMENT}
    />
  );
};
