import { generalTitle } from "./constants";
import { IListFiles } from "./types";

export const listFilesToUpload: IListFiles[] = [
  {
    id: 1,
    title: generalTitle,
    file: `Руководство пользователя ''Мониторинг развития субъектов Российской Федерации''.pdf`,
    type: "pdf",
  },
  {
    id: 2,
    title: `${generalTitle} (полномочный ПП)`,
    file: `Руководство пользователя ''Мониторинг развития субъектов Российской Федерации'' (полномочный ПП).pdf`,
    type: "pdf",
  },
  {
    id: 3,
    title: `${generalTitle} (глава субъекта РФ)`,
    file: `Руководство пользователя ''Мониторинг развития субъектов Российской Федерации'' (глава субъекта РФ) актуальное.pdf`,
    type: "pdf",
  },
];
