import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import React from "react";

export interface IndicatorDescriptionButtoProps {
  show: boolean;
}

export const IndicatorDescriptionButton = ({
  show = false,
}: IndicatorDescriptionButtoProps) => {
  return show ? (
    <>
      <span>Скрыть</span> <KeyboardArrowUpIcon />
    </>
  ) : (
    <>
      <span>Раскрыть</span> <KeyboardArrowDownIcon />
    </>
  );
};
