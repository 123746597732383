import { TAB_ID } from "@/store/scheme/region";

export const defaultTabs = {
  [TAB_ID.VDL_PROJECTS]: {
    id: TAB_ID.VDL_PROJECTS,
    gridArea: TAB_ID.VDL_PROJECTS,
    text: "по целям развития экономики и проектам",
  },
  [TAB_ID.VDL]: {
    id: TAB_ID.VDL,
    gridArea: TAB_ID.VDL,
    text: "по целям развития экономики",
  },
  [TAB_ID.PROJECTS]: {
    id: TAB_ID.PROJECTS,
    gridArea: TAB_ID.PROJECTS,
    text: "по проектам",
  },
  [TAB_ID.RESULT_PROJECTS]: {
    id: TAB_ID.RESULT_PROJECTS,
    gridArea: TAB_ID.RESULT_PROJECTS,
    text: "по показателям проектов",
  },
  [TAB_ID.RESULT_CHECKPOINTS]: {
    id: TAB_ID.RESULT_CHECKPOINTS,
    gridArea: TAB_ID.RESULT_CHECKPOINTS,
    text: "по контрольным точкам",
  },
  [TAB_ID.RISKS]: {
    id: TAB_ID.RISKS,
    gridArea: TAB_ID.RISKS,
    text: "Проекты / с отставанием от плана",
  },
};
