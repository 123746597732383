import { MouseEvent } from "react";

import { useTabs } from "@/app/hooks/useTabs";
import { Switcher } from "@/shared/Switcher/Switcher";
import { useAppDispatch, useAppSelector } from "@/store/redux/hooks";

import { useAchievementControl } from "../hooks/useAchievementControl";
import { ACHIEVEMENT_VALUE } from "../lib/types";
import { achievementControlToggle } from "../model/achievementSlice";

export const AchievementControl = () => {
  const dispatch = useAppDispatch();
  const { setDefaultTab } = useTabs();
  const { value } = useAppSelector((state) => state.controls.achievement);
  const { navigateToPage } = useAchievementControl();

  const handleChange = (event: MouseEvent<HTMLElement>, newValue: string) => {
    if (newValue) {
      dispatch(achievementControlToggle(newValue as ACHIEVEMENT_VALUE));
      const activeTab = setDefaultTab(
        newValue === ACHIEVEMENT_VALUE.ACHIEVEMENT
      );
      navigateToPage(newValue as ACHIEVEMENT_VALUE, activeTab);
    }
  };
  const switcherData = [
    { value: ACHIEVEMENT_VALUE.ACHIEVEMENT, title: "Достижение" },
    { value: ACHIEVEMENT_VALUE.DYNAMICS, title: "Динамика" },
  ];

  return (
    <Switcher
      switcherData={switcherData}
      callbackChange={handleChange}
      value={value}
    />
  );
};
