import { Card, styled, Typography } from "@mui/material";
import { TypographyProps } from "@mui/material/Typography/Typography";
import { FC } from "react";

import { Icon } from "@/shared/CustomIcon/Icon.styled";
import { CustomIconProps } from "@/shared/CustomIcon/lib/types";

export const ProjectCardsStyled = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  gap: theme.spacing(2.5),
  marginBottom: theme.spacing(3),
  "& > div": {
    width: "100%",
  },
}));

export const ProjectCard = styled(Card)(({ theme }) => ({
  position: "relative",
  flex: 1,
  borderRadius: theme.spacing(2),
  padding: theme.spacing(3, 0),
  minHeight: "19.4rem",
  maxHeight: "22rem",
}));

export const ProjectCardContent = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  boxSizing: "border-box",
}));

export const ProjectCardTitle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(),
  padding: theme.spacing(0, 3),
  marginBottom: theme.spacing(2),
}));

export const ProjectCardTitleIcon: FC<CustomIconProps> = (props) => (
  <Icon size="big" {...props} />
);

export const ProjectCardInfo = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 100%",
  flexDirection: "column",
  justifyContent: "flex-end",
  marginTop: theme.spacing(1.5),
}));

export const ProjectContent = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 3),
}));

export const ProjectCardLabelTypography = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  color: theme.palette.text.lightGrey,
  lineHeight: theme.spacing(2.5),
  fontWeight: 400,
}));

export const ProjectCardLabel: FC<TypographyProps> = (props) => (
  <ProjectCardLabelTypography variant="subtitle2" {...props} />
);

export const ProjectCardName: FC<TypographyProps> = (props) => (
  <Typography variant="h5" {...props} />
);
