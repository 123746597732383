import { useMemo } from "react";

import { HeaderColumns } from "@/shared/Table/lib/types";

import { useKeysIndicatorsAndProjectsColumnsT } from "../lib/types";

export const useKeysIndicatorsAndProjectsColumns = ({
  factChangeYearStart,
  modelKPI,
  kpiData,
  factChangeAppg,
  checkPeriodByHideDate,
  modelPassport,
  projectPassport,
}: useKeysIndicatorsAndProjectsColumnsT): {
  keysIndicatorsAndProjectsColumns: HeaderColumns[];
} => {
  const keysIndicatorsAndProjectsColumns = useMemo(() => {
    return [
      {
        id: "123",
        sortId: 1,
        indicator: factChangeYearStart,
        label: "Место",
        popUpLabel: "Место",
        sortable: true,
        model: modelKPI.modelId,
        mainColumn: true,
        fractionDigits: 0,
        data: kpiData,
        sortBy: "place",
        indicatorColor: "",
      },
      {
        id: "123",
        sortId: 2,
        indicator: factChangeAppg,
        label: "Достижение по целям развития экономики и проектам,%",
        popUpLabel: "Достижение по целям развития экономики и проектам,%",
        sortable: true,
        model: modelKPI.modelId,
        colorValues: true,
        valuesUnit: "%",
        mainColumn: true,
        data: kpiData,
        sortBy: "AchievementSum",
        indicatorColor: modelKPI.indexes.colorGraph,
      },
      {
        id: "10",
        sortId: 3,
        indicator: modelKPI.indexes.factChangeAppg,
        label: "Достижение по целям развития экономики,%",
        popUpLabel: "Достижение по целям развития экономики,%",
        sortable: true,
        model: modelKPI.modelId,
        valuesUnit: "%",
        data: kpiData,
        sortBy: "AchievementEconomics",
        ...(!checkPeriodByHideDate && {
          needPrevPeriod: true,
          prevPeriod: true,
        }),
        indicatorColor: "",
      },
      {
        id: "0",
        sortId: 4,
        indicator: modelPassport.indexes.achievement,
        label: "Достижение по проектам,%",
        popUpLabel: "Достижение по проектам,%",
        sortable: true,
        model: modelPassport.modelId,
        valuesUnit: "%",
        data: projectPassport,
        sortBy: "AchievementProjects",
        needPrevPeriod: true,
        prevPeriod: false,
        indicatorColor: "",
      },
    ] as HeaderColumns[];
  }, [
    factChangeYearStart,
    modelKPI.modelId,
    modelKPI.indexes.factChangeAppg,
    kpiData,
    factChangeAppg,
    modelPassport.indexes.achievement,
    modelPassport.modelId,
    projectPassport,
    checkPeriodByHideDate,
  ]);

  return { keysIndicatorsAndProjectsColumns };
};
