import ExportIcon from "@assets/svg_components/Export";
import { toPng } from "html-to-image";
import jsPDF from "jspdf";
import { FC, useCallback } from "react";
import { useParams } from "react-router";

import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { downloadFile } from "@/widgets/ExportControl/lib/helpers";

import { isDevBranch } from "../lib/constants";
import {
  ToggleButtonStyled,
  ToggleButtonWrapper,
} from "./DownloadControl.styled";
import { useDownload } from "./hooks/useDownload";

export const DownloadControl: FC = () => {
  const { id } = useParams<"id" | "tabId">();
  const { isCheckRfFoPage } = useRegions();
  const { getFilePathAndName } = useDownload();

  const temporaryFunction = async () => {
    const rootEl = document.getElementById("root");
    if (!rootEl) return;

    const { offsetWidth, offsetHeight } = rootEl;
    const png = await toPng(rootEl, {
      filter: (node) => {
        return !node.classList?.contains("MuiAvatar-img");
      },
    });
    const pdf = new jsPDF("l", "px", [offsetWidth, offsetHeight]);

    pdf.addImage(png, "PNG", 0, 0, offsetWidth, offsetHeight);
    pdf.save("armregion.pdf");
  };

  const exportRfFoSummary = (id: string) => {
    const { fileName, filePath } = getFilePathAndName(id);
    downloadFile(filePath, fileName);
  };

  const onDownload = useCallback(async () => {
    return isCheckRfFoPage.isAll || (isDevBranch && id === "19")
      ? exportRfFoSummary(id || "")
      : await temporaryFunction();
  }, [isCheckRfFoPage.isAll, id]);

  return (
    <ToggleButtonWrapper isBackground>
      <ToggleButtonStyled value={{}} onClick={onDownload}>
        <ExportIcon />
      </ToggleButtonStyled>
    </ToggleButtonWrapper>
  );
};
