import { Stack, styled, ToggleButton, ToggleButtonGroup } from "@mui/material";

export const PassportHeaderTitle = styled("h4")(({ theme }) => ({
  color: theme.palette.text.primary,
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: "2",
  WebkitBoxOrient: "vertical",
  "& > span": {
    color:
      theme.palette.text[theme.palette.mode === "dark" ? "grey" : "secondary"],
  },
}));

export const PassportHeaderGroup = styled("div")(({ theme }) => ({
  display: "flex",
  gap: "2rem",
  "& > button > svg > path": {
    fill: theme.palette.graphs.graphCloseButton,
  },
}));

export const ToggleButtonGroupStyled = styled(ToggleButtonGroup)(
  ({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.button.background
        : theme.palette.background.darkTheme,
    border: `1px solid ${theme.palette.button.border}`,
    padding: theme.spacing(0.5),
  })
);

export const ToggleButtonStyled = styled(ToggleButton)<{ isBig?: boolean }>(
  ({ theme, isBig }) => ({
    boxSizing: "border-box",
    fontSize: 16,
    padding: theme.spacing(0.3, isBig ? 5 : 2),
    textTransform: "none",
    border: "none",
    borderRadius: `${theme.spacing()} !important`,
    color: theme.palette.button.notSelected,
    "&.Mui-selected": {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.button.primary,
      "& > button > svg, & > .MuiToggleButtonGroup-root > button > svg": {
        fill: theme.palette.primary.contrastText,
      },
    },
  })
);

export const StackGraphStyled = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
  background:
    theme.palette.mode === "light"
      ? theme.palette.indicatorCard.backgroundColor
      : theme.palette.graphs.graphContainer,
  margin: theme.spacing(3),
  padding: theme.spacing(3),
  boxSizing: "border-box",
  boxShadow: theme.spacing(0, 0, 1.75, -1.5),
  borderRadius: theme.spacing(2),
  height: "92%",
}));

export const LegendWrapper = styled("div")<{ isDouble: boolean }>(
  ({ theme, isDouble }) => ({
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    color: theme.palette.text.primary,
    ...(isDouble && {
      gap: theme.spacing(3),
    }),
  })
);
