import CloseIcon from "@mui/icons-material/Close";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import React from "react";

import { IGridCardButtonProps } from "../lib/types";
import { IconButtonStyled } from "./GridCardButton.styled";

export const GridCardButton = ({
  selected,
  turnOff,
  handleClick,
}: IGridCardButtonProps) => {
  return (
    <IconButtonStyled selected={selected} onClick={handleClick}>
      {turnOff ? <CloseIcon /> : <NavigateNextIcon />}
    </IconButtonStyled>
  );
};
