import { Timeline } from "@mui/icons-material";
import { useLocation, useNavigate, useParams } from "react-router";

import { GRAPH_LEVEL } from "@/pages/RegionGraphsPage/lib/constants";
import { Control } from "@/shared/Control/ui/Control";
import { getParams } from "@/shared/lib/heplers";
import { useAppSelector } from "@/store/redux/hooks";
import { TAB_ID } from "@/store/scheme/region";

import { REGION_COUNTRY_CODE } from "../lib/constants";
import { TimeLineControlProps } from "./lib/types";
import { ToggleButtonStyled } from "./TimeLineControl.styled";

export const TimeLineControl = ({
  noBorder = false,
  region = false,
}: TimeLineControlProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams<"id">();
  const selectedDistrictId = getParams("selectedDistrict");
  const { subjectId } = useAppSelector((state) => state.indicators.subjectId);
  const districtId = getParams("district");
  const regionId = getParams("region");

  const checkLocationPath = () => {
    if (location.pathname.match(/achievementLevel/gim)) {
      const tabId = location.pathname.replace(/\/achievementLevel\/*/gm, "");
      return tabId === TAB_ID.PROJECTS
        ? GRAPH_LEVEL.RESULT_PROJECTS
        : GRAPH_LEVEL.VDL;
    }
    return GRAPH_LEVEL.VDL;
  };

  const handleChange = () => {
    let graphId = REGION_COUNTRY_CODE;

    switch (true) {
      case Boolean(id):
        graphId = id as string;
        break;

      case Boolean(selectedDistrictId):
        graphId = selectedDistrictId as string;
        break;

      case Boolean(districtId) && !regionId:
        graphId = districtId as string;
        break;

      case Boolean(districtId && regionId):
        graphId = regionId as string;
        break;

      case Boolean(subjectId?.regionId):
        graphId = subjectId?.regionId!!;
        break;

      default:
        break;
    }
    const tabName = checkLocationPath();
    navigate(`/region/${graphId}/graphs${tabName ? `/${tabName}` : ""}`, {
      state: {
        ...location?.state,
      },
    });
  };

  return (
    <Control>
      <ToggleButtonStyled
        noBorder={noBorder}
        region={region}
        value="graphs"
        onClick={handleChange}
      >
        <Timeline />
      </ToggleButtonStyled>
    </Control>
  );
};
