export const convertFio = (fio: string) => {
  return fio
    ?.split(" ")
    ?.map((word: string, index: number) =>
      index === 0
        ? word[0].toUpperCase() + word.substring(1)
        : word[0]
        ? word[0].toUpperCase() + "."
        : ""
    )
    ?.join(" ");
};
