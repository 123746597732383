import { useMemo } from "react";
import { NavigateOptions, useLocation } from "react-router";

import { useFuturePlan } from "@/shared/Table/hooks/useFuturePlan";
import { useAppSelector } from "@/store/redux/hooks";
import { TAB_ID } from "@/store/scheme/region";
import { MIN_YEAR_RISKS } from "@/widgets/AppBar/lib/constants";
import { MIN_YEAR } from "@/widgets/ExportControl/lib/constants";

const defaultPath = `/achievementLevel/${TAB_ID.VDL_PROJECTS}`;

export const useRisksTransition = () => {
  // Функции для переходов из таблицы Риски и обратно
  const location = useLocation();
  const { pathname } = location;
  const { isCheckRiskFuturePlan } = useFuturePlan();
  const { achievement } = useAppSelector((state) => state.controls);
  const checkRisksTable =
    pathname?.startsWith("/achievementLevel") && pathname?.match(/risks$/gim);

  const minYear = useMemo(() => {
    return checkRisksTable && achievement.enabled ? MIN_YEAR_RISKS : MIN_YEAR;
  }, [checkRisksTable, achievement.enabled]);

  const getPath = () => {
    let path = pathname;
    if (path?.match(/risks/gim) && !isCheckRiskFuturePlan) {
      path = defaultPath;
    }
    return path;
  };

  // Функция для возврата на предыдущую страницу в TopBar
  const getBackLink = (
    back?: string | number,
    navigateOptions?: NavigateOptions
  ) => {
    if (
      !isCheckRiskFuturePlan &&
      navigateOptions?.state?.tabName === TAB_ID.RISKS &&
      window?.history?.state?.usr?.tabName === TAB_ID.RISKS
    ) {
      return defaultPath;
    }
    return back;
  };

  return { getBackLink, minYear, checkRisksTable, getPath };
};
