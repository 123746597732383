import { Button, styled } from "@mui/material";

import {
  IExpandButtonStyledProps,
  ITableCellExpandableProps,
} from "@/shared/Table/lib/types";
import { LevelTableCell } from "@/shared/TableBase/BasicTable.styled";

export const TableCellExpandable = styled(
  LevelTableCell
)<ITableCellExpandableProps>(({ theme, whiteSpace, isBorderRight }) => ({
  "& > div:first-of-type": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    "& > div:first-of-type": {
      display: "flex",
      alignItems: "center",
      marginRight: theme.spacing(whiteSpace ? 4 : 2),
    },
  },
  "&": {
    whiteSpace: whiteSpace,
    ...(isBorderRight && {
      borderRight: `2px solid ${theme.palette.table.dividerLight}`,
    }),
  },
}));

export const ExpandButtonStyled = styled(Button, {
  shouldForwardProp: (prop) => !["darkTheme", "size"].includes(prop.toString()),
})<IExpandButtonStyledProps>(({ theme, size, darkTheme }) => ({
  flex: "0 0 auto",
  width: theme.spacing(size ? 3 : 4),
  height: theme.spacing(size ? 3 : 4),
  minWidth: "auto",
  minHeight: "auto",
  padding: 0,
  borderRadius: 4,
  backgroundColor: darkTheme
    ? theme.palette.background.darkTheme
    : theme.palette.table.divider,
  color: theme.palette.text.primary,
}));
