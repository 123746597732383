import moment from "moment";
import React, { useMemo, useState } from "react";

import { formatNumber } from "@/shared/lib/heplers";
import { stickyTop } from "@/shared/ProjectResultsGroup/lib/helpers";
import { PROJECT_FINANCES } from "@/shared/Table/model/api/scheme";
import {
  IProjectFinancesAttributeVals,
  MODEL_IDS,
  MODELS,
} from "@/store/scheme/olap";

import {
  defaultFinancesColumns,
  defaultResult,
  getFinanceRowTitle,
  projectFinances,
} from "../lib/constants";
import { UNITS_MEASURE } from "../lib/types";
import { useProjectPage } from "./useProjectPage";

export const useProjectFinances = () => {
  const { finances, financesFetching } = useProjectPage();
  const model = MODELS[MODEL_IDS.PROJECT_FINANCES];
  const unitsMeasureKeys = Object.keys(UNITS_MEASURE);
  const [unitMeasure, setUnitMeasure] = useState(unitsMeasureKeys[2]);

  const isCheckFinancesPeriods = !!Object.keys(finances?.periods || {}).length;

  const periods = useMemo(() => {
    // Забираем актуальный период
    const summary = finances?.summary;
    const summaryDateEnd =
      (
        summary?.["111"] ||
        summary?.["112"] ||
        summary?.["113"] ||
        summary?.["120"]
      )?.attributeVals?.DateEnd || 0;
    const actualPeriod = summaryDateEnd ? moment(summaryDateEnd) : 0;

    const temporaryBeforePeriods: any = [];
    const temporaryAfterPeriods: any = [];

    Object.values(finances?.periods ?? {})
      ?.sort((sortedA, sortedB) => (sortedA?.code > sortedB?.code ? 1 : -1))
      ?.forEach((period) => {
        // Сравниваем актуальный с остальными
        const date = moment(period?.attributeVals?.DateEnd);
        if (date.isSameOrBefore(actualPeriod, "year")) {
          temporaryBeforePeriods.push({ ...period, actualPeriod: true });
        }

        if (date.isAfter(actualPeriod, "year")) {
          temporaryAfterPeriods.push({ ...period, actualPeriod: false });
        }
      });

    return [...temporaryBeforePeriods, ...temporaryAfterPeriods];
  }, [finances?.periods, finances?.summary]);

  const handleUnitMeasure = (
    e: React.MouseEvent<HTMLElement>,
    newUnitMeasure: string
  ) => {
    newUnitMeasure !== null && setUnitMeasure(newUnitMeasure);
  };

  const getCorrectValue = React.useCallback(
    (value?: number) => (value ? value / UNITS_MEASURE[unitMeasure] : value),
    [unitMeasure]
  );

  const setIndicatorValue = (
    id: "value" | keyof IProjectFinancesAttributeVals,
    indicator: string
  ) => {
    switch (id) {
      case "short_name":
        return getFinanceRowTitle(indicator);

      case "type_ind_unit_measure":
        return unitMeasure;
      default:
        return null;
    }
  };

  const columnRows = useMemo(() => {
    const [firstRow, secondRow] = defaultFinancesColumns();
    periods.forEach((period) => {
      const date = moment(period?.attributeVals?.DateEnd);

      if (period?.actualPeriod) {
        firstRow.push({
          id: `${date.format("YYYY")}-current-year`,
          label: date.format("YYYY"),
          colSpan: 3,
          align: "center",
          oldid: "current-year",
          ...stickyTop(0),
        });

        secondRow.push(
          {
            id: `${date.format("YYYY")}-current-year-plan`,
            oldid: "current-year-plan",
            label: "План",
            width: 100,
            height: 68,
            ...stickyTop(29),
          },
          {
            id: `${date.format("YYYY")}-current-year-fact`,
            oldid: "current-year-fact",
            label: "Факт",
            width: 100,
            height: 68,
            ...stickyTop(29),
          },
          {
            id: `${date.format("YYYY")}-current-year-percent`,
            oldid: "current-year-percent",
            label: "Исполнение, %",
            width: 110,
            height: 68,
            ...stickyTop(29),
          }
        );
      } else {
        firstRow.push({
          id: period.id,
          label: date.format("YYYY"),
          ...stickyTop(0),
        });

        secondRow.push({
          id: `${period.id}-plan-year`,
          label: "План",
          width: 100,
          height: 68,
          ...stickyTop(29),
        });
      }
    });

    return [firstRow, secondRow];
  }, [periods]);

  const rows = useMemo(() => {
    const result = JSON.parse(JSON.stringify({ ...defaultResult }));

    const financesSummary = Object.keys(
      finances?.summary ?? {}
    ) as PROJECT_FINANCES[];

    const resultSummary = Object.keys(result) as PROJECT_FINANCES[];

    (financesSummary.length > 0 ? financesSummary : resultSummary).forEach(
      (indicator) => {
        result[indicator].push(
          ...(
            [
              "short_name",
              "type_ind_unit_measure",
            ] as (keyof IProjectFinancesAttributeVals)[]
          ).map((id) => ({
            id,
            value: setIndicatorValue(id, indicator),
            sx:
              indicator === PROJECT_FINANCES.TOTAL && id === "short_name"
                ? {
                    "&.MuiTableCell-body": {
                      textAlign: "left",
                      textTransform: "uppercase",
                    },
                  }
                : undefined,
          }))
        );
      }
    );

    // пройдем по всем периодам
    periods.forEach((period, index) => {
      const date = moment(period.attributeVals.DateEnd);

      // по всем PROJECT_INDICATORS
      (Object.keys(result) as PROJECT_FINANCES[]).forEach((indicator) => {
        if (period?.actualPeriod) {
          const preparePercent =
            period?.indicators?.[indicator]?.values?.[model.indexes.percent];
          const fractionDigitsPercent =
            preparePercent === 100 || preparePercent === 0 ? 0 : 1;

          const percent = formatNumber(
            period?.indicators?.[indicator]?.values?.[model.indexes.percent],
            undefined,
            fractionDigitsPercent
          );
          const fact = formatNumber(
            getCorrectValue(
              period.indicators[indicator]?.values?.[model.indexes.fact]
            ),
            undefined,
            2
          );
          const plan = formatNumber(
            getCorrectValue(
              period.indicators[indicator]?.values?.[model.indexes.plan]
            ),
            undefined,
            2
          );

          result[indicator].push(
            {
              id: `${date.format("YYYY")}-current-year-plan`,
              oldid: "current-year-plan",
              value: plan,
            },
            {
              id: `${date.format("YYYY")}-current-year-fact`,
              oldid: "current-year-fact",
              value: fact,
            },
            {
              id: `${date.format("YYYY")}-current-year-percent`,
              oldid: "current-year-percent",
              value: percent,
            }
          );
        } else {
          result[indicator].push({
            id: `${period.id}-plan-year`,
            value: formatNumber(
              getCorrectValue(
                period.indicators[indicator]?.values?.[model.indexes.plan]
              ),
              undefined,
              2
            ),
          });
        }
      });
    });

    return [...projectFinances].map((indicator, index) => [
      ...(indicator !== PROJECT_FINANCES.TOTAL
        ? [
            {
              id: "number",
              value: index + 1,
            },
          ]
        : []),
      ...result[indicator],
    ]);
  }, [
    finances?.summary,
    periods,
    unitMeasure,
    getCorrectValue,
    setIndicatorValue,
  ]);

  return {
    isCheckFinancesPeriods,
    financesFetching,
    unitMeasure,
    handleUnitMeasure,
    getCorrectValue,
    unitsMeasureKeys,
    periods,
    columnRows,
    setIndicatorValue,
    rows,
  };
};
