import { ArgumentsType } from "@/shared/lib/types";
import {
  getOperand,
  getOperands,
  olapRequestData2,
  olapRequestDimensionData,
} from "@/store/api/core";
import {
  DATA_ACTUAL_VALUE,
  FILTER_OPERAND_TYPE,
  FILTER_TYPE,
  IProjectAttributeVals,
  MODEL_IDS,
  MODELS,
} from "@/store/scheme/olap";

export const getProjectsDirectory = () => {
  const model = MODELS[MODEL_IDS.PROJECT_PASSPORT];

  return olapRequestDimensionData(
    model.dataRegionId,
    {
      modelUuid: model.uuid,
      dimensionId: model.dataProjectId,
      includeAttributesByCodes: ["name", "id_subject"],
      dataRequestCaching: true,
    },
    "3c74562b-8197-43f5-a508-e7e405d896f0"
  );
};

export const getProjectsSum = ({ regionId, dataSummaryId }: ArgumentsType) => {
  const model = MODELS[MODEL_IDS.PROJECT_PASSPORT];

  const dimIds = [
    model.dataPeriodId,
    model.dataRegionId,
    model.dataProjectId,
    model.dimId,
    model.dataActualId,
  ];

  const dimensions = [
    {
      id: model.dataPeriodId,
      includeItems: true,
      includeAttributesByCodes: ["REPORT_DATE"],
    },
    {
      id: model.dataRegionId,
    },
    {
      id: model.dataProjectId,
      includeItems: true,
      includeAttributesByCodes: ["name", "implementation_period"],
    },
    {
      id: model.dimId,
    },
    {
      id: model.dataActualId,
    },
    {
      id: model.dataSummaryId,
    },
  ];

  const indicators = {
    id: model.indicatorId,
    items: [
      { id: model.indexes.plan },
      { id: model.indexes.color },
      { id: model.indexes.status },
    ],
  };

  const operands = getOperands([
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      dim_id: model.dataPeriodId,
      showLeafsOnly: false,
      showChildren: false,
      levels: [1, 2],
      showAllLevelElements: false,
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataRegionId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: [regionId],
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      dim_id: model.dataProjectId,
      showLeafsOnly: false,
      showChildren: false,
      levels: [0, 1],
      showAllLevelElements: false,
    },
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      dim_id: model.dimId,
      showLeafsOnly: false,
      showChildren: false,
      levels: [0, 1],
      showAllLevelElements: false,
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataActualId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: [DATA_ACTUAL_VALUE],
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataSummaryId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: [dataSummaryId],
        },
      ],
    },
  ]);

  return olapRequestData2(dimIds, model.indicatorId, {
    modelUuid: model.uuid,
    indicatorId: model.indicatorId,
    includeGaps: false,
    dataRequestCaching: true,
    dimensions,
    indicators,
    dataFilter: {
      type: FILTER_TYPE.AND,
      operands,
    },
  });
};

export const getProjectsAll = (regionIds: string[]) => {
  const model = MODELS[MODEL_IDS.PROJECT_PASSPORT];

  return olapRequestDimensionData<IProjectAttributeVals>(
    model.dataProjectId,
    {
      modelUuid: model.uuid,
      dimensionId: model.dataProjectId,
      includeAttributesByCodes: [
        "name",
        "implementation_period",
        "code",
        "NAME",
        "SHORT_NAME",
        "SORT_ORDER",
      ],
      dataFilter: {
        type: FILTER_TYPE.EQ,
        operands: [
          getOperand({
            type: FILTER_OPERAND_TYPE.DIM,
            id: model.dataProjectId,
            attributeCode: "id_subject",
          }),
          getOperand({
            type: FILTER_OPERAND_TYPE.TEXT,
            values: regionIds,
          }),
        ],
      },
    },
    "3c74562b-8197-43f5-a508-e7e405d896f0"
  );
};
