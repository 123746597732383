import React from "react";

import { isDevBranch } from "@/shared/lib/constants";

import { AccessTimeStyled } from "../ui/FaqContent.styled";
import { FaqFilesList } from "../ui/FaqFilesList/ui/FaqFilesList";
import { IfaqData } from "./types";

export const faqData: IfaqData[] = [
  {
    id: "01",
    question:
      "Что такое информационный ресурс «Мониторинг развития субъектов Российской Федерации»?",
    answer: (
      <>
        <p>
          Это информационный ресурс в рамках института кураторства федеральных
          округов заместителями Председателя Правительства Российской Федерации
          (поручение Председателя Правительства Российской Федерации от 23 июня
          2021 года № ММ-П16-18пр).
        </p>
        <p>
          Ресурс позволяет проводить автоматизированный мониторинг экономической
          ситуации в субъекте РФ по 4 целям развития экономики и инвестиционным
          проектам, предложенным субъектами РФ и утвержденных Председателем
          Правительства Российской Федерации.
        </p>
      </>
    ),
  },
  {
    id: "02",
    question: "Что такое 4 цели развития экономики?",
    answer: (
      <>
        <span>
          Эти показатели характеризуют динамику развития экономики субъектов РФ
          по ключевым направлениям, включая развитие бизнеса и уровень жизни
          граждан:
        </span>
        <ol>
          <li>
            Выпуск товаров и услуг – характеризует динамику реализации продукции
            по ключевым отраслям экономики (по данным Росстата);
          </li>
          <li>
            Частные инвестиции – характеризует динамику привлечённых
            внебюджетных инвестиций в субъект РФ (по данным Росстата);
          </li>
          <li>
            Доходы населения – характеризует динамику уровня благосостояния
            граждан без учета инфляции (по данным Росстата);
          </li>
          <li>
            Число рабочих мест – характеризует прирост количество созданных
            рабочих мест к соответствующему периоду прошлого года (граждане,
            которым начисляются страховые взносы по данным ФНС России).
          </li>
        </ol>
      </>
    ),
  },
  {
    id: "03",
    question: "Что такое инвестиционные проекты субъекта РФ?",
    answer: (
      <p>
        Это инвестиционные проекты (преимущественно с привлечением частных
        средств), которые будут реализовываться до 2030 года. Они позволят
        привлечь дополнительные инвестиции в субъекты РФ, создать новые рабочие
        места и повысить уровень жизни граждан. Эти проекты разработаны
        субъектами РФ совместно с инвесторами и утверждены Председателем
        Правительства Российской Федерации.
      </p>
    ),
  },
  {
    id: "04",
    question: "Что такое плановые значения?",
    answer: (
      <p>
        Это прогнозные значения целей развития экономики субъектов РФ к
        соответствующем периоду 2020 года (прогноз разработан Минэкономразвития
        России).
      </p>
    ),
  },
  {
    id: "05",
    question:
      "Как определяется уровень достижения по целям развития экономики?",
    answer: (
      <p>
        Это соотношение факта уже достигнутых результатов к плановым значениям
        целей развития экономики (не более 100%). Позволяет заранее увидеть
        риски по соответствующим направлениям социально-экономического развития
        субъекта РФ.
      </p>
    ),
  },
  {
    id: "06",
    question:
      "Как рассчитывается среднее достижение по целям развития экономики и проектам?",
    answer: (
      <>
        <p>
          Это ключевой показатель результативности работы субъекта РФ по всем
          направлениям (не более 100%).
        </p>
        <span>
          Рассчитывается как среднеарифметическое значение 2-х показателей:
        </span>
        <ul style={{ listStyle: "none" }}>
          <li>
            - среднее достижение по 4-м целям развития экономики
            (среднеарифметическое значение достижения 4-х ключевых показателей),
          </li>
          <li>
            - среднее достижение по проектам (среднеарифметическое значение
            достижения контрольных точек и показателей проекта).
          </li>
        </ul>
        <p>
          На основании среднего достижения по целям развития экономики и
          проектам формируется итоговый рейтинг федеральных округов и субъектов
          РФ.
        </p>
      </>
    ),
  },
  {
    id: "07",
    question: "Что такое контрольные точки?",
    answer: (
      <p>
        Контрольные точки реализации проекта отражают документально
        подтвержденное завершение промежуточных этапов реализации проекта
        (пример: аренда земельного участка для строительства, утверждение
        проектно-сметной документации).
      </p>
    ),
  },
  {
    id: "08",
    question: "Как часто обновляется информация в системе?",
    answer: (
      <>
        <p>
          Информация по показателям обновляется в зависимости от периодичности
          выхода статистических данных от соответствующего федерального органа
          исполнительной власти.
        </p>
        <p>
          При нажатии на значок <AccessTimeStyled /> по каждому показателю
          отображается дата актуализации информации.
        </p>
        <span>
          Ориентировочный график поквартального обновления целей развития
          экономики (данные представляются Росстатом и ФНС России):
        </span>
        <ol>
          <li>
            Выпуск товаров и услуг – 13 мая (за 1 квартал), 12 августа (за 1
            полугодие), 14 ноября 2022 г. (за 9 месяцев) и 14 февраля 2023 г.
            (годовые данные).
          </li>
          <li>
            Доходы населения – 7 июня (за 1 квартал), 31 августа (за 1
            полугодие), 2 декабря 2022 г. (за 9 месяцев) и 2 марта 2023 г.
            (годовые данные).
          </li>
          <li>
            Частные инвестиции – 7 июня (за 1 квартал), 31 августа (за 1
            полугодие), 2 декабря 2022 г. (за 9 месяцев) и 2 марта 2023 г.
            (годовые данные).
          </li>
          <li>
            Число рабочих мест – 31 мая (за 1 квартал), 2 сентября (за 1
            полугодие), 30 ноября 2022 г. (за 9 месяцев) и 28 февраля 2023 г.
            (годовые данные).
          </li>
        </ol>
        <p>
          Информация по приоритетным проектам обновляется ежеквартально на
          основании данных субъектов РФ и верифицируется Минэкономразвития
          России в ГИС «Электронный бюджет».
        </p>
      </>
    ),
  },
  {
    id: "09",
    question: "Как осуществляется доступ в дашборд и работа с системой?",
    answer: <FaqFilesList />,
  },
  ...(isDevBranch
    ? [
        {
          id: "10",
          question: "Как рассчитываются риски реализации проектов",
          answer: (
            <>
              <p>
                Риск - неопределенность реализации проекта на основании степени
                его изменения относительно первоначально утвержденной версии
                паспорта.
              </p>
              <span>
                Рассчитывается как среднеарифметическое значение 2-x ключевых
                показателей, характеризующих:
              </span>
              <ul style={{ listStyle: "none", margin: 0 }}>
                <li>- перенос плановой даты исполнения контрольных точек,</li>
                <li>
                  - степень снижения плановых значений показателей проектов
                  относительно изначально утвержденных.
                </li>
              </ul>
              <p>
                На основании степени переноса контрольных точек и снижения
                показателей каждому проекту присваивается высокий, средний или
                минимальный уровень риска.
              </p>
              <br />
              <p>
                Количество проектов в зоне риска, ед. - количество проектов,
                которым присвоен риск.
              </p>
              <p>
                Количество проектов в зоне риска, % - доля проектов, которым
                присвоен риск, в общем количестве проектов в Российской
                Федерации, федеральном округе или субъекте Российской Федерации.
              </p>
            </>
          ),
        },
      ]
    : []),
];
