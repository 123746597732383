import {
  Accordion,
  AccordionDetails,
  Box,
  styled,
  Typography,
} from "@mui/material";

export const BoxWrapper = styled(Box)(({ theme }) => ({
  background: theme.palette.tabs.backgroundContent,
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
}));

export const AccordionWrapper = styled(Accordion)(({ theme }) => ({
  background: "none",
  boxShadow: "none",
  ".MuiAccordionSummary-expandIconWrapper": {
    "& > div": {
      borderRadius: theme.spacing(0.5),
      width: theme.spacing(4),
      height: theme.spacing(4),
      background: theme.palette.button.collapseIcon,
      "& > svg": {
        fill: theme.palette.text.primary,
      },
    },
  },
  ".MuiAccordionSummary-content": {
    margin: "1.5rem 0",
  },
  "&:before": {
    background:
      theme.palette.mode === "dark"
        ? theme.palette.divider
        : theme.palette.table.dividerLight,
  },
  "&.Mui-expanded&:not(:first-of-type)": {
    borderTop: `1px solid ${
      theme.palette.mode === "dark"
        ? theme.palette.divider
        : theme.palette.table.dividerLight
    }`,
  },
}));

export const AccordionTitle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(3),
  ".MuiTypography-root": {
    fontSize: theme.spacing(2.5),
  },
}));

export const AccordionDetailsWrapper = styled(AccordionDetails)(
  ({ theme }) => ({
    margin: theme.spacing(1, 8),
  })
);

export const AccordionDetail = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  height: theme.spacing(10.5),
  width: "100%",
  borderRadius: theme.spacing(2),
  background: theme.palette.tabs.content,
  padding: theme.spacing(0, 2),
  ":not(:last-child)": {
    marginBottom: theme.spacing(2),
  },
}));

export const ContentWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(6),
}));

export const ContentInfo = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
}));

export const FileSize = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.tertiary,
  fontSize: theme.spacing(2),
  lineHeight: theme.spacing(3),
  fontWeight: theme.typography.fontWeightMedium,
}));
