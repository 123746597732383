import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useCallback, useEffect, useRef, useState } from "react";
import useOnClickOutside from "use-onclickoutside";

import { useExportControl } from "../../hooks/useExportControl";
import { MIN_YEAR, periods, QUARTERS } from "../../lib/constants";
import { QuarterSelectProps } from "../../lib/types";
import {
  Main,
  Quarter,
  QuartersSelect,
  Year,
  YearChange,
  YearSelect,
} from "./QuarterSelect.styled";

export default function QuarterSelect({
  isOpen = false,
  setIsOpen,
  changePeriod,
}: QuarterSelectProps) {
  const { years, minYear, maxYear } = useExportControl();

  const [year, setYear] = useState<number>(MIN_YEAR);
  const [quarter, setQuarter] = useState<number>(1);

  useEffect(() => {
    setYear(maxYear);
    setQuarter(+years[maxYear]?.["quarter"] - 1 || 0);
  }, []);

  const handleSetQuarter = useCallback(
    (newQuarter: number) => {
      setQuarter(newQuarter);
      setIsOpen(false);
    },
    [setIsOpen, setQuarter]
  );
  const handleSetYear = useCallback(
    (step: "next" | "prev") => {
      const sign = step === "prev" ? year - 1 : year + 1;
      setYear(+sign);
      setQuarter(+years[sign]["quarter"] - 1);
    },
    [setYear, year, setQuarter]
  );

  useEffect(() => {
    changePeriod(quarter, year);
  }, [quarter, year]);

  const mainRef = useRef(null);
  useOnClickOutside(mainRef, () => setIsOpen(false));

  if (!isOpen) return null;
  return (
    <Main ref={mainRef}>
      <YearSelect>
        <Year>{year}</Year>

        <div>
          <YearChange
            aria-label="prev year"
            disabled={year <= minYear}
            onClick={() => handleSetYear("prev")}
          >
            <KeyboardArrowLeftIcon fontSize="small" />
          </YearChange>
          <YearChange
            aria-label="next year"
            disabled={year >= maxYear}
            onClick={() => handleSetYear("next")}
          >
            <KeyboardArrowRightIcon fontSize="small" />
          </YearChange>
        </div>
      </YearSelect>

      <QuartersSelect>
        {QUARTERS.map((q, i) => (
          <Quarter
            key={i}
            selected={i === quarter}
            disabled={!periods?.[year]?.[i]}
            onClick={() => handleSetQuarter(i)}
          >
            {q} квартал
          </Quarter>
        ))}
      </QuartersSelect>
    </Main>
  );
}
