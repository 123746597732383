import React, { useState } from "react";

import { useRouter } from "@/app/hooks/useRouter";
import { Icon } from "@/pages/MainPage/ui/VDL/VDLMain.styled";
import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { FoCoatsOfArms } from "@/shared/FoCoatsOfArms/ui/FoCoatsOfArms";
import { REGION_COATS } from "@/shared/lib/constants";
import { getUrlByTheme } from "@/shared/lib/heplers";
import { ExpandButton } from "@/shared/Table/ui/TableEntities/ExpandButton/ExpandButton";

import { getColorStatusProject } from "../../lib/helpers";
import { IRegionFoRowProps } from "../../lib/types";
import {
  LinkStyled,
  ProjectRowTitle,
  ProjectRowValue,
  TableCellStyled,
  TableRowHover,
  TitleCardListInner,
  TitleProject,
} from "../RegionProjectsWidget.styled";

export const RegionRow = ({
  isProject = false,
  row,
  columns,
  isOpenPopUp,
}: IRegionFoRowProps) => {
  const { getPath } = useRouter();
  const [isShowProjects, setIsShowProjects] = useState(false);
  const { isCheckRfFoPage, regionIds } = useRegions();

  return (
    <>
      <TableRowHover
        isOpenPopUp={isOpenPopUp}
        sx={{ height: isCheckRfFoPage.isAll ? 60 : 132 }}
      >
        <TableCellStyled
          colSpan={2}
          sx={{
            paddingLeft: isCheckRfFoPage.isAll ? "2.5rem" : "1rem",
            background: isOpenPopUp ? "#1e2731" : "#2b3641",
          }}
          isOpenPopUp={!!isOpenPopUp}
        >
          <TitleCardListInner isCheckRF={isCheckRfFoPage.isAll}>
            {row?.number && (
              <ProjectRowValue sx={isOpenPopUp ? { fontSize: "1.25rem" } : {}}>
                {row.number}
              </ProjectRowValue>
            )}
            <ProjectRowValue>
              {isProject ? (
                getColorStatusProject(row.status, isOpenPopUp)
              ) : isCheckRfFoPage.isFO ? (
                <Icon
                  src={REGION_COATS[row?.id as keyof typeof REGION_COATS]}
                  achEnabled={true}
                  sx={{ width: "2rem", height: "2rem" }}
                />
              ) : (
                <FoCoatsOfArms idFo={row?.id} />
              )}
            </ProjectRowValue>

            <ProjectRowTitle isCheckRF={isCheckRfFoPage.isAll}>
              {row.isNotJumpOnPage || !isProject ? (
                <TitleProject
                  isGray={isCheckRfFoPage.isFO && isProject}
                  openWidget={isOpenPopUp}
                >
                  {row.name}
                </TitleProject>
              ) : (
                <LinkStyled
                  to={getPath(getUrlByTheme(`/projects/${row.id}`))}
                  isGray={isCheckRfFoPage.isFO && isProject}
                  sx={
                    isOpenPopUp
                      ? { fontSize: "1.3rem", lineHeight: "normal" }
                      : {}
                  }
                >
                  {row.name}
                </LinkStyled>
              )}
              {!!row?.projectList && regionIds?.includes(row?.id) && (
                <ExpandButton
                  key={row.id}
                  sx={{ marginLeft: "auto", marginRight: "4rem" }}
                  onClick={() => setIsShowProjects(!isShowProjects)}
                  isExpanded={isShowProjects}
                />
              )}
            </ProjectRowTitle>
          </TitleCardListInner>
        </TableCellStyled>
        {columns.map((col) => (
          <TableCellStyled
            key={col.id}
            sx={{
              minWidth: 144,
              fontWeight: 500,
              background: isOpenPopUp ? "#1e2731" : "#2b3641",
            }}
            isGray={isCheckRfFoPage.isFO && isProject}
            isOpenPopUp={!!isOpenPopUp}
          >
            <>{row[col.id]}</>
          </TableCellStyled>
        ))}
      </TableRowHover>
      {!!row?.projectList &&
        isShowProjects &&
        row.projectList.map((projectRow) => (
          <RegionRow
            isProject={true}
            row={projectRow}
            key={projectRow.id}
            columns={columns}
            isOpenPopUp={isOpenPopUp}
          />
        ))}
    </>
  );
};
