// Данные для перехода в нацпроекты по id субъекта и id проекта
export interface IСompareDate {
  [key: string]: string;
}
export const compareDateByFns: IСompareDate = {
  "0": "1", // РФ
  "0001": "1", // РФ
  // ФО
  "0011": "11", // Центральный ФО
  "0012": "12", // Северо-Западный ФО
  "0013": "13", // Южный ФО
  "0014": "14", // Приволжский ФО
  "0015": "15", // Уральский ФО
  "0016": "16", // Сибирский ФО
  "0017": "17", // Дальневосточный ФО
  "0018": "18", // Северо-Кавказский ФО
};

export const compareDataByProject: IСompareDate = {
  "0": "1", // Все проекты
  "2": "1000001", // Культура
  "9": "1000002", // Цифровая экономика
  "4": "1000003", // Образование
  "12": "1000004", // Жилье и городская среда
  "7": "1000005", // Экология
  "5": "1000006", // МСП
  "15": "1000015", // Туризм
  "10": "1000007", // Производстводительность
  "3": "1000008", // Здравоохранение
  "13": "1000009", // Демография
  "6": "1000010", // БКД
  "1": "1000011", // Наука
  "11": "1000012", // Экспорт
  "14": "1000014", // Атом
  "8": "1000013", // КПМИ
};

export const textForTabsAndTable = "в соответствии с графиком проекта";
// Инфо текст Для AppBar и Notifications
// В конце обязательно ставится точка
export const infoField = (
  <>
    <p>
      Обновлено: данные по исполнению бюджета, статистика внешней торговли и
      естественный прирост населения на 01.09.2023, количество ЮЛ, ИП, МСП на
      01.10.2023.
    </p>
  </>
);
export const textForFuturePlan = "Справочно";
