import { Link, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import { FC, useRef } from "react";

import AccountingIcon from "@/assets/projects/accounting.png";
import { ReactComponent as CallIcon } from "@/assets/projects/CallIcon.svg";
import { ReactComponent as MailIcon } from "@/assets/projects/MailIcon.svg";
import { ReactComponent as PlaceIcon } from "@/assets/projects/PlaceIcon.svg";
import { useProjectPage } from "@/pages/ProjectsPage/hooks/useProjectPage";
import { Icon } from "@/shared/CustomIcon/Icon.styled";
import { CustomScrollbar } from "@/shared/CustomScrollbar/ui/CustomScrollbar";
import { useOpenIncident } from "@/shared/Incident/hooks/useOpenIncident";
import { IncidentCreateButton } from "@/shared/Incident/ui/IncidentCreateButton/IncidentCreateButton";
import { useCheckpointsFilter } from "@/shared/InputSelect/hooks/useCheckpointsFilter";

import {
  ProjectCard,
  ProjectCardContent,
  ProjectCardInfo,
  ProjectCardLabel,
  ProjectCardName,
  ProjectCardTitle,
  ProjectContent,
} from "../../ProjectCards.styled";
import {
  ProjectInitiatorItem,
  ProjectInitiatorItemAccounting,
  ProjectInitiatorItemIcon,
  ProjectInitiatorItemText,
} from "./ProjectInitiator.styled";
import { InitiatorIcon } from "./ProjectInitiatorIcon";

export const ProjectInitiator: FC = () => {
  const theme = useTheme();
  const { info } = useProjectPage();
  const { actualPeriodData } = useCheckpointsFilter();
  const reportDate = actualPeriodData?.attributeVals.REPORT_DATE || "";
  const showRef = useRef<HTMLDivElement | null>(null);
  const { move, handleMove, handleChange } = useOpenIncident();

  return (
    <ProjectCard
      variant="outlined"
      ref={showRef}
      onMouseEnter={() => handleMove(true)}
      onMouseLeave={() => handleMove(false)}
    >
      <ProjectCardContent>
        <ProjectCardTitle>
          <Icon
            size="big"
            sx={{ background: theme.palette.table.backgroundImg }}
          >
            <InitiatorIcon />
          </Icon>

          <Typography variant="subtitle1">Инициатор проекта</Typography>
          {move && (
            <IncidentCreateButton
              bigSize
              posRight={24}
              onClick={(event) => {
                handleChange(
                  event,
                  showRef?.current,
                  "Инициатор проекта",
                  reportDate
                );
              }}
            />
          )}
        </ProjectCardTitle>
        <CustomScrollbar>
          <ProjectContent>
            <ProjectCardName sx={{ lineHeight: "1.5rem" }}>
              {info?.general?.attributeVals?.initiator_name || "-"}
            </ProjectCardName>
            <ProjectCardLabel sx={{ lineHeight: "1.375rem" }}>
              ИНН {info?.general?.attributeVals?.initiator_inn || "-"}
            </ProjectCardLabel>

            <ProjectCardInfo>
              <ProjectInitiatorItem>
                <ProjectInitiatorItemIcon>
                  <PlaceIcon />
                </ProjectInitiatorItemIcon>
                <ProjectInitiatorItemText>
                  {info?.general?.attributeVals?.initiator_address || "-"}
                </ProjectInitiatorItemText>
              </ProjectInitiatorItem>
              <ProjectInitiatorItem>
                <ProjectInitiatorItemIcon>
                  <CallIcon />
                </ProjectInitiatorItemIcon>
                <ProjectInitiatorItemText>
                  {info?.general?.attributeVals?.initiator_phone || "-"}
                </ProjectInitiatorItemText>
              </ProjectInitiatorItem>
              <ProjectInitiatorItem>
                <ProjectInitiatorItemIcon>
                  <MailIcon />
                </ProjectInitiatorItemIcon>
                <ProjectInitiatorItemText>
                  {info?.general?.attributeVals?.initiator_email ? (
                    <Link
                      sx={{ textDecoration: "none" }}
                      href={info?.general?.attributeVals?.initiator_email}
                    >
                      {info?.general?.attributeVals?.initiator_email}
                    </Link>
                  ) : (
                    "-"
                  )}
                </ProjectInitiatorItemText>
              </ProjectInitiatorItem>

              <ProjectInitiatorItemAccounting>
                <ProjectInitiatorItemIcon notBackground>
                  <img
                    src={AccountingIcon}
                    alt="Посмотреть бухгалтерскую отчетность"
                  />
                </ProjectInitiatorItemIcon>
                <ProjectInitiatorItemText
                  sx={{ fontWeight: 600, lineHeight: "normal" }}
                >
                  <Link
                    href={
                      info?.general?.attributeVals?.initiator_inn
                        ? `https://bo.nalog.ru/search?query=${info?.general.attributeVals.initiator_inn}&page=1`
                        : "https://bo.nalog.ru/"
                    }
                    target="_blank"
                  >
                    Посмотреть бухгалтерскую отчетность
                  </Link>
                </ProjectInitiatorItemText>
              </ProjectInitiatorItemAccounting>
            </ProjectCardInfo>
          </ProjectContent>
        </CustomScrollbar>
      </ProjectCardContent>
    </ProjectCard>
  );
};
