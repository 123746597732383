import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import { FC, useRef } from "react";

import { colorBasedOnValue } from "@/pages/MainPage/lib/helpers";
import { useProjectPage } from "@/pages/ProjectsPage/hooks/useProjectPage";
import { AchievementLevel } from "@/shared/AchievementLevel/ui/AchievementLevel";
import {
  CircleWrapper,
  TextBlock,
} from "@/shared/Achievements/ui/Achievements.styled";
import { GetRowChart } from "@/shared/Achievements/ui/GetRowChart";
import { CardList } from "@/shared/CardList/CardList.styled";
import { useOpenIncident } from "@/shared/Incident/hooks/useOpenIncident";
import { IncidentCreateButton } from "@/shared/Incident/ui/IncidentCreateButton/IncidentCreateButton";
import { useCheckpointsFilter } from "@/shared/InputSelect/hooks/useCheckpointsFilter";
import { formatNumber } from "@/shared/lib/heplers";
import { Sceleton } from "@/shared/Sceleton/ui/Sceleton";
import { ValueDate } from "@/shared/ValueDate/ValueDate";

import { ProjectTitle, ProjectTitleText } from "../common/ProjectTable.styled";
import {
  Container,
  LineChartsContainer,
} from "./ProjectCharacteristicsTable.styled";

export const ProjectCharacteristicsTable: FC = () => {
  const theme = useTheme();
  const { actualPeriodData } = useCheckpointsFilter();
  const { infoFetching } = useProjectPage();
  const reportDate = actualPeriodData?.attributeVals.REPORT_DATE || "";
  const values = actualPeriodData?.indicators?.["0"]?.values;
  const showRef = useRef<HTMLDivElement | null>(null);
  const { move, handleMove, handleChange } = useOpenIncident();

  return (
    <CardList
      ref={showRef}
      onMouseEnter={() => handleMove(true)}
      onMouseLeave={() => handleMove(false)}
      sx={{ position: "relative" }}
    >
      <ProjectTitle>
        <ProjectTitleText>Достижение</ProjectTitleText>
        {move && (
          <IncidentCreateButton
            bigSize
            posRight={24}
            onClick={(event) => {
              handleChange(event, showRef?.current, "Достижение", reportDate);
            }}
          />
        )}
      </ProjectTitle>
      {infoFetching ? (
        <Sceleton type="achieve" />
      ) : (
        <Container>
          <Box>
            <div>
              <TextBlock>
                <div className="total">Итого</div>
                <ValueDate>{reportDate}</ValueDate>
              </TextBlock>
              <CircleWrapper>
                <AchievementLevel
                  value={values?.["1141"]}
                  size={240}
                  radius="92%"
                  unit="%"
                  color={colorBasedOnValue(theme, values?.["881"] || 5)}
                />
              </CircleWrapper>
            </div>
          </Box>

          <LineChartsContainer>
            <GetRowChart
              title="По показателям проекта"
              date={reportDate}
              value={formatNumber(values?.["802"])}
              color={values?.["804"]}
            />

            <GetRowChart
              title="По контрольным точкам"
              date={reportDate}
              value={formatNumber(
                actualPeriodData?.indicators?.["129"]?.values?.["802"]
              )}
              color={actualPeriodData?.indicators?.["129"]?.values?.["804"]}
            />
          </LineChartsContainer>
        </Container>
      )}
    </CardList>
  );
};
