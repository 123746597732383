import { useRef, useState } from "react";

import { dynamicTableTitle } from "@/pages/InsideMainPage/lib/constants";
import { IAchievementTabsProps } from "@/pages/InsideMainPage/lib/types";
import { useOpenIncident } from "@/shared/Incident/hooks/useOpenIncident";
import { IncidentCreateButton } from "@/shared/Incident/ui/IncidentCreateButton/IncidentCreateButton";

import { IndicatorsDynamicTable } from "../../../../MainPage/ui/Tables/IndicatorsDynamicTable/IndicatorsDynamicTable";
import { AchievementNavigateWrapper } from "../InsideMainPageButtons/AchievementNavigateWrapper";
import {
  TabPanelTable,
  TabPanelWrapper,
  TabPlug,
  TabsListUnstyled,
  TabsWrapper,
} from "../InsideMainPageTabs.styled";

export const AchievementDisableTabs = ({
  isShowExport,
}: IAchievementTabsProps) => {
  const [openPopUp, setOpenPopUp] = useState(false);
  const showRef = useRef<HTMLDivElement | null>(null);
  const { handleChange } = useOpenIncident();

  const handleClosePopUp = () => {
    setOpenPopUp(!openPopUp);
  };

  return (
    <TabsWrapper ref={showRef} defaultValue="dynamics" isDinamic>
      <TabsListUnstyled>
        <TabPlug disabled value="dynamics"></TabPlug>
      </TabsListUnstyled>

      <IncidentCreateButton
        bigSize
        className="hoverIncident"
        posRight={isShowExport ? 317 : 257}
        onClick={(event) => {
          handleChange(event, showRef?.current, dynamicTableTitle);
        }}
      />

      <AchievementNavigateWrapper
        isNoGraphs
        handleClosePopUp={handleClosePopUp}
        isShowExport={isShowExport}
      />

      <TabPanelTable value="dynamics" withFooter>
        <TabPanelWrapper withFooter>
          <IndicatorsDynamicTable
            openColumnsModal={openPopUp}
            closeColumnsModal={handleClosePopUp}
          />
        </TabPanelWrapper>
      </TabPanelTable>
    </TabsWrapper>
  );
};
