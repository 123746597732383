import { FC, SVGProps } from "react";

import theme from "../../app/theme/common";

export interface IDynamicsLowProps extends SVGProps<SVGSVGElement> {
  contrast?: boolean;
  isBig?: boolean;
}

const icon: FC<IDynamicsLowProps> = ({
  children,
  contrast = false,
  isBig,
  ...props
}) => (
  <svg
    width={isBig ? "40" : "24"}
    height={isBig ? "40" : "24"}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      opacity="0.6"
      r="11.5"
      transform="matrix(-1 0 0 1 12 12)"
      stroke={contrast ? theme.palette.error.main : theme.palette.success.main}
    />
    <path
      d="M5.80957 11.4053H8.10457C8.95057 11.4053 9.57457 11.5883 9.97657 11.9543C10.3786 12.3143 10.5796 12.8003 10.5796 13.4123C10.5796 14.0243 10.3786 14.5133 9.97657 14.8793C9.57457 15.2393 8.95057 15.4193 8.10457 15.4193H6.88957V17.7053H5.80957V11.4053ZM8.01457 14.4833C8.29657 14.4833 8.53357 14.4623 8.72557 14.4203C8.91757 14.3723 9.07057 14.3063 9.18457 14.2223C9.29857 14.1323 9.37957 14.0213 9.42757 13.8893C9.47557 13.7513 9.49957 13.5923 9.49957 13.4123C9.49957 13.2323 9.47557 13.0763 9.42757 12.9443C9.37957 12.8123 9.29857 12.7013 9.18457 12.6113C9.07057 12.5213 8.91757 12.4553 8.72557 12.4133C8.53357 12.3653 8.29657 12.3413 8.01457 12.3413H6.88957V14.4833H8.01457Z"
      fill={contrast ? theme.palette.error.main : theme.palette.success.main}
    />
    <path
      d="M14.361 17.3723C13.881 17.3723 13.455 17.3063 13.083 17.1743C12.717 17.0363 12.408 16.8443 12.156 16.5983C11.904 16.3463 11.712 16.0493 11.58 15.7073C11.454 15.3593 11.391 14.9753 11.391 14.5553V14.3753C11.391 13.9793 11.454 13.6193 11.58 13.2953C11.712 12.9653 11.904 12.6863 12.156 12.4583C12.408 12.2303 12.717 12.0533 13.083 11.9273C13.455 11.8013 13.881 11.7383 14.361 11.7383V11.1353H15.441V11.7383C15.915 11.7383 16.335 11.8013 16.701 11.9273C17.073 12.0533 17.385 12.2303 17.637 12.4583C17.889 12.6863 18.081 12.9653 18.213 13.2953C18.345 13.6193 18.411 13.9793 18.411 14.3753V14.5553C18.411 14.9753 18.345 15.3593 18.213 15.7073C18.081 16.0493 17.889 16.3463 17.637 16.5983C17.385 16.8443 17.073 17.0363 16.701 17.1743C16.335 17.3063 15.915 17.3723 15.441 17.3723V17.9753H14.361V17.3723ZM17.277 14.3753C17.277 13.8293 17.124 13.4123 16.818 13.1243C16.512 12.8363 16.053 12.6923 15.441 12.6923V16.4183C16.059 16.4183 16.518 16.2563 16.818 15.9323C17.124 15.6083 17.277 15.1493 17.277 14.5553V14.3753ZM12.525 14.5553C12.525 15.1493 12.675 15.6083 12.975 15.9323C13.281 16.2563 13.743 16.4183 14.361 16.4183V12.6923C13.743 12.6923 13.281 12.8363 12.975 13.1243C12.675 13.4123 12.525 13.8293 12.525 14.3753V14.5553Z"
      fill={contrast ? theme.palette.error.main : theme.palette.success.main}
    />
    <path
      d="M12.1423 4.59966L16.1336 8.54199C16.2604 8.6672 16.1706 8.88129 15.9913 8.8813L8.00867 8.8813C7.82939 8.8813 7.73961 8.6672 7.86638 8.54199L11.8577 4.59966C11.9363 4.52204 12.0637 4.52204 12.1423 4.59966Z"
      fill={contrast ? theme.palette.error.main : theme.palette.success.main}
      stroke={contrast ? theme.palette.error.main : theme.palette.success.main}
    />
  </svg>
);

export default icon;
