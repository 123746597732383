import {
  Box,
  Divider,
  Link,
  LinkProps,
  ListItem,
  ListItemText,
  styled,
} from "@mui/material";

import { Control } from "@/shared/Control/ui/Control";

export const BoxWrapper = styled(Box)(({ theme }) => ({
  background: theme.palette.tabs.backgroundContent,
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
}));

export const ListItemIconStyled = styled("div")<{ isBackground?: boolean }>(
  ({ theme, isBackground = false }) => ({
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    color:
      theme.palette.text[
        theme.palette.mode === "dark" ? "lightGrey" : "dataNotSelected"
      ],
    "& > div": {
      borderRadius: theme.spacing(1),
      width: theme.spacing(4.5),
      height: theme.spacing(4.5),
      ...(!isBackground && {
        background:
          theme.palette.button[
            theme.palette.mode === "dark" ? "downloadBorder" : "background"
          ],
      }),
      ...(isBackground && {
        background:
          theme.palette.mode === "dark"
            ? theme.palette.button.downloadBorder
            : theme.palette.dateBadge.background,
      }),
      "& > svg": {
        fill: theme.palette.text[
          theme.palette.mode === "dark" ? "lightGrey" : "dataNotSelected"
        ],
      },
    },
  })
);

export const ToggleButtonWrapper = styled(Control, {
  shouldForwardProp: (prop) => !["background"].includes(prop.toString()),
})<{ background?: boolean }>(({ theme, background }) => ({
  border: `1px solid ${
    background ? theme.palette.button.border : theme.palette.text.tertiary
  }`,
  ...(background && {
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.appBar.borderColor
        : theme.palette.background.darkTheme,
    "& > button > svg > path": {
      fill:
        theme.palette.mode === "dark"
          ? theme.palette.button.notSelected
          : theme.palette.text.primary,
    },
  }),
}));

export const ListItemStyled = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(2.25, 2),
}));

export const ListItemTextStyled = styled(ListItemText)(({ theme }) => ({
  margin: theme.spacing(0, 3),
}));

export const LinkUnstyled = styled(Link)<LinkProps>(({ theme }) => ({
  color: "inherit",
  textDecoration: "inherit",
}));

export const ListDivider = styled(Divider)(({ theme }) => ({
  background:
    theme.palette.mode === "dark"
      ? theme.palette.divider
      : theme.palette.table.dividerLight,
}));
