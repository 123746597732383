import { styled, TableCell, TableRow } from "@mui/material";
import { Link } from "react-router-dom";

import { CardList, CardListHeader } from "@/shared/CardList/CardList.styled";

export const ProjectRow = styled("div")(({ theme }) => ({
  minHeight: theme.spacing(16.5),
  display: "flex",
  fontSize: theme.typography.fontSize,
  border: `1px solid transparent`,
  borderBottom: `1px solid ${theme.palette.table.dividerLight}`,
  overflow: "hidden",

  "&:hover": {
    backgroundColor: theme.palette.background.rowHover,
    border: `1px solid ${theme.palette.table.rowBorder}`,
    borderRadius: theme.spacing(1),
  },
}));

export const SumProjectRow = styled(ProjectRow)(({ theme }) => ({
  minHeight: theme.spacing(8),
}));

export const ProjectRowValue = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2.5, 0.9),
  textAlign: "right",
  fontSize: 16,
  fontWeight: theme.typography.fontWeightMedium,
}));

export const SumProjectRowValue = styled(ProjectRowValue)(({ theme }) => ({
  fontSize: 20,
}));

export const ProjectRowTitle = styled(ProjectRowValue, {
  shouldForwardProp: (prop) =>
    !["isCheckRF", "isOpenPopUp"].includes(prop.toString()),
})<{ isCheckRF?: boolean }>(({ theme, isCheckRF }) => ({
  display: "flex",
  flex: "1 1 auto",
  textAlign: "left",
  fontSize: 16,
  fontWeight: !isCheckRF ? theme.typography.fontWeightRegular : 500,
  ...(isCheckRF && {
    lineHeight: theme.spacing(3),
  }),
}));

export const SumProjectRowTitle = styled(ProjectRowTitle)(({ theme }) => ({
  padding: 0,
}));

export const SumProjectRowTitleBig = styled(SumProjectRowTitle)<{
  isOpenPopUp?: boolean;
}>(({ theme, isOpenPopUp }) => ({
  fontSize: isOpenPopUp ? 32 : 24,
  fontWeight: theme.typography.fontWeightSemiBold,
  color: theme.palette.text.primary,
}));

export const ProjectContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  ".track": {
    borderRadius: theme.spacing(0.375),
    background: theme.palette.scrollbar.track,
    "&.vertical": {
      right: theme.spacing(0.625),
      bottom: theme.spacing(0.5),
      top: theme.spacing(0.5),
      zIndex: 100,
    },
    "&.horizontal": {
      right: theme.spacing(0.25),
      bottom: theme.spacing(0.25),
      left: theme.spacing(0.25),
      zIndex: 100,
    },
  },
  ".thumb": {
    cursor: "pointer",
    borderRadius: "inherit",
    background: theme.palette.scrollbar.thumb,
  },
  ".scroll-view": {
    overflow: "auto !important",
    margin: "0 !important",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      width: 0,
    },
  },
}));

export const TableCellStyled = styled(TableCell, {
  shouldForwardProp: (prop) =>
    !["isGray", "isOpenPopUp"].includes(prop.toString()),
})<{
  isGray?: boolean;
  isOpenPopUp?: boolean;
}>(({ theme, isGray = false, isOpenPopUp = false }) => ({
  fontSize: theme.spacing(isOpenPopUp ? 3 : 2),
  color: theme.palette.text[isGray ? "tertiary" : "primary"],
  padding: theme.spacing(0, 2),
  lineHeight: theme.spacing(2.25),
  borderTop: `1px solid transparent`,
  borderBottom: `1px solid ${theme.palette.table.dividerLight}`,
  "&:first-of-type": {
    borderLeft: `1px solid transparent`,
  },
  "&:last-child": {
    borderRight: `1px solid transparent`,
  },
  "& > .flexCell": {
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(2),
  },
}));

export const TableRowHover = styled(TableRow, {
  shouldForwardProp: (prop) => !["isOpenPopUp"].includes(prop.toString()),
})<{ isOpenPopUp: boolean }>(({ theme, isOpenPopUp }) => ({
  ...(isOpenPopUp && { background: theme.palette.popup.popupBackground }),
  "&:hover": {
    td: {
      backgroundColor: theme.palette.background.rowHover,
      borderTop: `1px solid ${theme.palette.table.rowBorder}`,
      borderBottom: `1px solid ${theme.palette.table.rowBorder}`,
    },
    "td:first-of-type": {
      borderLeft: `1px solid ${theme.palette.table.rowBorder}`,
      borderRadius: theme.spacing(1, 0, 0, 1),
    },
    "td:last-child": {
      borderRight: `1px solid ${theme.palette.table.rowBorder}`,
      borderRadius: theme.spacing(0, 1, 1, 0),
    },
  },
}));

export const ProjectWrapper = styled(CardList)(({ theme }) => ({
  position: "relative",
  width: 1058,
}));

export const ProjectHeader = styled(CardListHeader)(({ theme }) => ({
  display: "flex",
  fontSize: theme.typography.fontSize,
  backgroundColor: theme.palette.table.backgroundLight,
}));

export const TitleCardListInner = styled("div")<{ isCheckRF?: boolean }>(
  ({ theme, isCheckRF }) => ({
    display: "grid",
    gridTemplateColumns: isCheckRF ? "3.75rem 33.75rem" : "3rem 3rem 28.125rem",
    alignItems: "center",
  })
);

export const ProjectRowTitleWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexFlow: "column nowrap",
}));

export const CardListInner = styled("div")(({ theme }) => ({
  width: "100%",
  display: "grid",
  gridTemplateColumns: "repeat(5, 8.125rem)",
  alignItems: "center",
  padding: theme.spacing(0, 2, 0, 3),
}));

export const CardListHeaderText = styled("span")(({ theme }) => ({
  fontSize: 18,
  fontWeight: theme.typography.fontWeightMedium,
}));

export const CardListHeaderTextTertiary = styled(CardListHeaderText)(
  ({ theme }) => ({
    fontSize: 16,
    color: theme.palette.text.tertiary,
  })
);

export const HeaderTitleWrapper = styled("div")<{ isFlex?: boolean }>(
  ({ theme, isFlex }) => ({
    display: "flex",
    minWidth: theme.spacing(44),
    gap: theme.spacing(isFlex ? 3 : 2),
    alignItems: "center",
  })
);

export const HeaderTitle = styled("span")(({ theme }) => ({
  display: "block",
}));

export const DateWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "space-between",
}));

export const Icon = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  paddingLeft: theme.spacing(2),
}));

export const TitleProject = styled("span")<{
  isGray?: boolean;
  openWidget?: boolean;
  isTotal?: boolean;
}>(({ theme, isGray = false, openWidget, isTotal = false }) => ({
  color: theme.palette.text[isGray ? "tertiary" : "primary"],
  fontSize: openWidget ? "1.5rem" : "1rem",
  ...(isTotal && { fontSize: openWidget ? "1.875rem" : "1.375rem" }),
}));

export const LinkStyled = styled(Link, {
  shouldForwardProp: (prop) => !["isGray"].includes(prop.toString()),
})<{ isGray?: boolean }>(({ theme, isGray = false }) => ({
  color: theme.palette.text[isGray ? "tertiary" : "primary"],
  textDecoration: "none",

  "&:hover": {
    textDecoration: "underline",
  },
}));
