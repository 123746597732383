import { Navigate, Route, Routes } from "react-router";

import { Faq } from "@/pages/Faq/ui/Faq";
import { InsideMainPage } from "@/pages/InsideMainPage/ui/InsideMainPage";
import { MainPage } from "@/pages/MainPage/ui/MainPage";
import { NormativeLegalActs } from "@/pages/NormativeLegalActs/ui/NormativeLegalActs";
import { ProjectGraphsPage } from "@/pages/ProjectGraphsPage/ui/ProjectGraphsPage";
import { ProjectsPage } from "@/pages/ProjectsPage/ui/ProjectsPage";
import { RegionGraphsPage } from "@/pages/RegionGraphsPage/ui/RegionGraphsPage";
import { RegionPage } from "@/pages/RegionPage/ui/RegionPage";
import { Reports } from "@/pages/Reports/ui/Reports";
import { Overlay } from "@/shared/Loader/Loader.styled";
import { PageContent } from "@/shared/PageContent/ui/PageContent";
import { DistrictsRoute } from "@/shared/routes/DistrictsRoute";
import { RegionsRoute } from "@/shared/routes/RegionsRoute";
import { User } from "@/store/scheme/user";
import { Login } from "@/widgets/Login/Login";

export const renderContentByRoutes = ({
  user,
  userError,
  regionIds,
}: {
  user?: User;
  userError: any;
  regionIds: string[];
}) => {
  if (!user || userError) {
    return (
      <Overlay>
        <Login />
      </Overlay>
    );
  }

  return (
    <PageContent>
      <Routes>
        {user?.isVIP && (
          <>
            <Route path="/" element={<MainPage />} />
            <Route
              path="/achievementLevel/:tabName"
              element={<InsideMainPage />}
            />
            <Route path="/achievementLevel" element={<InsideMainPage />} />
            <Route path="/reports" element={<Reports />} />
          </>
        )}
        <Route
          path="/selectedDistrict/:selectedDistrictId"
          element={
            <DistrictsRoute>
              <MainPage />
            </DistrictsRoute>
          }
        />
        <Route
          path="/district/:districtId"
          element={
            <DistrictsRoute>
              <MainPage />
            </DistrictsRoute>
          }
        />
        <Route
          path="/district/:districtId/region/:regionId"
          element={
            <DistrictsRoute>
              <RegionsRoute>
                <MainPage />
              </RegionsRoute>
            </DistrictsRoute>
          }
        />
        <Route
          path="/region/:id"
          element={
            <RegionsRoute>
              <RegionPage />
            </RegionsRoute>
          }
        />
        <Route
          path="/region/:id/:tabId"
          element={
            <RegionsRoute>
              <RegionPage />
            </RegionsRoute>
          }
        />
        <Route
          path="/region/:id/graphs"
          element={
            <RegionsRoute>
              <RegionGraphsPage />
            </RegionsRoute>
          }
        />
        <Route
          path="/region/:id/graphs/:graphLevel"
          element={
            <RegionsRoute>
              <RegionGraphsPage />
            </RegionsRoute>
          }
        />
        <Route path="/projects/:id" element={<ProjectsPage />} />
        <Route path="/projects/:id/:tabLevel" element={<ProjectsPage />} />
        <Route path="/projects/:id/graphs" element={<ProjectGraphsPage />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/acts" element={<NormativeLegalActs />} />

        {/* Redirect from wrong path */}
        <Route
          path="*"
          element={
            <Navigate
              to={
                user?.isVIP
                  ? "/"
                  : regionIds.length
                  ? `/region/${regionIds[0]}`
                  : `/faq`
              }
              replace
            />
          }
        />
      </Routes>
    </PageContent>
  );
};
