export const normativeLegalActs = [
  {
    label:
      "Методика расчета значений показателей, характеризующих достижение ключевых целей социально-экономического развития субъектов Российской Федерации и федеральных округов, и оценки влияния реализации проектов субъектов Российской Федерации",
    fileName: "Methodology.pdf",
    type: "pdf",
  },
  {
    label:
      "Протокол совещания у Председателя Правительства Российской Федерации от 23.07.2021 №ММ-П16-18пр",
    fileName: "Protocol_23.07.21.pdf",
    type: "pdf",
  },
  {
    label:
      "Письмо Минэкономразвития России от 26.07.2022 Д35и-23287 «О перечне проектов, подлежащих отображению на Дашборде (по состоянию на 01.07.2022)»",
    fileName: "No_7062п-П16_01.07.2022.pdf",
    type: "pdf",
  },
  {
    label:
      "Письмо Минэкономразвития России от 30.09.2022 37214-ПК/Д03и «О целевых значениях показателей социально-экономического развития субъектов Российской Федерации и федеральных округов»",
    fileName: "No_37214-ПКД03и_30.09.2022.pdf",
    type: "pdf",
  },
  {
    label:
      "Письмо Минэкономразвития России от 11.11.2022 Д35и-36801 «О перечне проектов, подлежащих отображению на Дашборде (по состоянию на 01.10.2022)»",
    fileName: "No_Д35и-36801_11.11.2022.pdf",
    type: "pdf",
  },
];
