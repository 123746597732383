import { styled, TableCell } from "@mui/material";

import { colorBasedOnValue } from "@/pages/MainPage/lib/helpers";
import { TableStyled } from "@/shared/WidgetScrollbar/ui/WidgetScrollbar.styled";

import { TableCellStyled } from "../RegionProjectsWidget/ui/RegionProjectsWidget.styled";

export const RisksWrapper = styled("div")(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
  padding: theme.spacing(2, 3),
}));

export const RisksSwitcherWrapper = styled("div")(({ theme }) => ({
  position: "sticky",
  top: 0,
  zIndex: 100,
  "& > .MuiToggleButtonGroup-root.MuiToggleButtonGroup-root": {
    position: "absolute",
    top: 16,
    right: 24,
    zIndex: 100,
    width: theme.spacing(9.4),
    height: theme.spacing(5),
    boxSizing: "border-box",
    backgroundColor: "rgb(43, 48, 63, 0.5)",
    "& > button": {
      maxWidth: theme.spacing(4),
      color: theme.palette.text.grey,
      "&.Mui-selected": {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.button.primary,
      },
      "& > svg": {
        width: "0.8em",
        height: "0.8em",
      },
    },
  },
}));

export const RisksBrakeByProjects = styled("div")(({ theme }) => ({
  paddingBottom: theme.spacing(),
  fontSize: theme.spacing(2),
  color: theme.palette.text.unitTextSelected,
  lineHeight: theme.spacing(2.5),
}));

export const RisksValue = styled("span")<{ color?: string; isBig?: boolean }>(
  ({ theme, color, isBig }) => ({
    fontSize: theme.spacing(3.5),
    color: color || theme.palette.text.unitTextSelected,
    fontWeight: 500,
    lineHeight: "normal",
    ...(isBig && {
      fontSize: theme.spacing(4),
      fontWeight: 700,
      lineHeight: theme.spacing(5),
    }),
  })
);

export const RisksTableWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  //background: "#191C264D",
}));

export const RisksHeaderCell = styled(TableCell)<{
  isAnotherCell?: boolean;
  isProjectBorder?: boolean;
  openWidget?: boolean;
  indexChangeWidth?: number;
}>(({ theme, isProjectBorder, openWidget, indexChangeWidth }) => ({
  position: "sticky",
  top: 0,
  zIndex: 99,
  width: !indexChangeWidth
    ? isProjectBorder
      ? "80%"
      : "60%"
    : indexChangeWidth === 2
    ? "3rem"
    : "auto",
  height: theme.spacing(5),
  fontSize: theme.spacing(openWidget ? 2.5 : 2),
  color: theme.palette.text.unitTextSelected,
  borderBottom: "none !important",
  ...((isProjectBorder || openWidget) && {
    borderBottom: `1px solid ${theme.palette.table.dividerLight} !important`,
  }),
  //backgroundColor: `${theme.palette.appBar.achievementBackground} !important`,
  background: `${
    openWidget
      ? theme.palette.popup.popupBackground
      : theme.palette.sceleton.baseColor
  } !important`,
  "& > div": {
    lineHeight: theme.spacing(2.5),
  },
}));

export const RisksValueFoRf = styled("span")<{
  column: string;
  openWidget?: boolean;
  isPercent?: boolean;
  isTotal?: boolean;
  colorRisk?: number;
}>(({ theme, column, openWidget, colorRisk = null, isPercent, isTotal }) => ({
  fontSize: theme.spacing(openWidget ? 3 : 2.25),
  fontWeight: 500,
  color: colorBasedOnValue(theme, colorRisk),
  whiteSpace: "nowrap",
  ...(isPercent && {
    fontSize: theme.spacing(openWidget ? 2.75 : 2),
    color: theme.palette.button.achievementText,
  }),
  ...(isTotal && {
    color: theme.palette.text.primary,
  }),
  "& > span": {
    color: theme.palette.button.achievementText,
  },
}));

export const RisksGraphContent = styled("div")(({ theme }) => ({
  flex: 1,
  display: "flex",
  justifyContent: "space-between",
}));

export const RisksCommonValues = styled("div")<{ isOpenPopUp?: boolean }>(
  ({ theme, isOpenPopUp }) => ({
    width: "50%",
    ...(isOpenPopUp && { zoom: 1.3 }),
  })
);

export const RiskTableStyledstyled = styled(TableStyled)(({ theme }) => ({
  width: "100%",
  height: "100%",
  borderCollapse: "separate",
}));

export const RiskTableCellStyled = styled(TableCellStyled)(({ theme }) => ({
  background: "rgba(25, 28, 38, 0.30)",
}));

export const RiskTableValue = styled("span")<{
  color?: string;
  openWidget?: boolean;
}>(({ theme, color = null, openWidget }) => ({
  fontSize: theme.spacing(openWidget ? 2.75 : 2),
  color: color
    ? colorBasedOnValue(theme, +color)
    : theme.palette.text.unitTextSelected,
  fontWeight: 500,
  lineHeight: "normal",
}));
