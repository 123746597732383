import React from "react";

import { ReactComponent as StarColor } from "@/assets/StarColor.svg";
import { ReactComponent as StarEmpty } from "@/assets/StarEmpty.svg";
import { formatNumberPeople } from "@/shared/lib/heplers";
import { LineChart } from "@/shared/LineChart/ui/LineChart";

import { useFeedback } from "../../hooks/useFeedback";
import { FeedbackAnalyticsStyled, RatingStyled } from "../Feedback.styled";

export const FeedbackAnalytics = () => {
  const { feedbackGrade, feedbackTotal, ratings } = useFeedback();
  return (
    <FeedbackAnalyticsStyled>
      <div className="analytic">
        <div className="gradeAnalytic">
          {feedbackGrade} <span>из 5</span>
        </div>
        <div className="answersAnalytic">
          {formatNumberPeople(feedbackTotal, undefined, 0)} ответ(ов)
        </div>
      </div>
      <div className="titleAnalytic">Оцените ваше взаимодействие с модулем</div>
      <div className="analyticSummary">
        {ratings?.map(({ rating, shareFeedback, countFeedback }: any) => {
          return (
            <div className="rating" key={rating}>
              <RatingStyled
                readOnly
                value={shareFeedback && countFeedback ? rating : 0}
                isSmallsize={true}
                max={rating}
                icon={<StarColor fontSize="inherit" />}
                emptyIcon={<StarEmpty fontSize="inherit" />}
                sx={{ flex: "0 0 30%", justifyContent: "flex-end" }}
              />
              <div className="chart">
                <LineChart
                  total={100}
                  value={+shareFeedback}
                  type="primary"
                  subType="enterprisePrimary"
                />
              </div>
              <div className="values">
                <div className="percent">{shareFeedback} %</div>
                <div className="count">({countFeedback})</div>
              </div>
            </div>
          );
        })}
      </div>
    </FeedbackAnalyticsStyled>
  );
};
