import React, { useCallback } from "react";

import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { getConvertDote } from "@/shared/Achievements/lib/helpers";
import { BlockWpapper } from "@/shared/BlockWrapper/BlockWrapper";
import { CompareIcons } from "@/shared/CompareIcons/ui/CompareIcons";
import { failNoText } from "@/shared/lib/constants";
import { LineChart } from "@/shared/LineChart/ui/LineChart";
import { IPopUpProps } from "@/shared/RegionPopUp/lib/types";
import { ValueDate } from "@/shared/ValueDate/ValueDate";
import {
  VEDRfFo,
  VEDRowHL,
  VEDRowProgress,
  VEDRowProgressLine,
} from "@/shared/VED/ui/VED.styled";
import { useIndicatorPassport } from "@/widgets/IndicatorPassport/hooks/useIndicatorPassport";

import { useEnterprise } from "../hooks/useEnterprise";
import { IEnterpriseRows } from "../lib/types";
import {
  EnterpriseHeader,
  EnterpriseListRow,
  EnterpriseValue,
  EnterpriseWrapper,
} from "./EnterpriseWidget.styled";

export const Enterprise = ({ isOpenPopUp }: IPopUpProps) => {
  const { rows, maxRowValue, enterpriseFetching, hasData } = useEnterprise();
  const { compareLabel } = useRegions();
  const { openIndicatorPassport } = useIndicatorPassport();

  const handleOpenPassport = useCallback(
    (indicatorId: string) => {
      openIndicatorPassport(indicatorId);
    },
    [openIndicatorPassport]
  );

  return (
    <BlockWpapper
      hasData={!!(rows || [])?.length && hasData}
      isFetching={enterpriseFetching}
      noDataText={failNoText}
    >
      <EnterpriseWrapper>
        {rows?.map((row: IEnterpriseRows) => {
          const max = Math.max(
            getConvertDote(row?.dynamicValue),
            (getConvertDote(row?.dynamicValueByParent) || maxRowValue) ?? 100
          );
          return (
            <EnterpriseListRow key={row.key} isOpenPopUp={isOpenPopUp!!}>
              <EnterpriseHeader
                openWidget={isOpenPopUp}
                onClick={() => handleOpenPassport(row?.key)}
              >
                {row.title}
              </EnterpriseHeader>
              <VEDRowProgress sx={{ gap: "0.25rem" }}>
                <VEDRowProgressLine>
                  <EnterpriseValue isLine={true} openWidget={isOpenPopUp}>
                    {row?.absolutValue}
                  </EnterpriseValue>
                  <LineChart
                    total={max}
                    value={getConvertDote(row?.dynamicValue)}
                    subType="enterpriseSecondary"
                  />
                  <EnterpriseValue isLine openWidget={isOpenPopUp}>
                    {row?.dynamicValue &&
                      `${row?.dynamicValue} ${row?.dynamicUnit}`}
                  </EnterpriseValue>
                </VEDRowProgressLine>

                {(!!row?.absolutValueByParent ||
                  !!row?.dynamicValueByParent) && (
                  <VEDRowProgressLine>
                    {!!row?.absolutValueByParent && (
                      <EnterpriseValue openWidget={isOpenPopUp} isGray isLine>
                        {row.absolutValueByParent}
                      </EnterpriseValue>
                    )}
                    {!!row?.dynamicValueByParent && (
                      <>
                        <LineChart
                          total={max}
                          value={getConvertDote(row?.dynamicValueByParent)}
                          type="primary"
                          subType="enterprisePrimary"
                        />
                        <EnterpriseValue openWidget={isOpenPopUp} isGray isLine>
                          {`${row?.dynamicValueByParent} ${row?.dynamicUnit}`}
                          <VEDRfFo>{compareLabel}</VEDRfFo>
                        </EnterpriseValue>
                      </>
                    )}
                  </VEDRowProgressLine>
                )}
                <VEDRowProgressLine
                  style={{ justifyContent: "space-between", width: "98%" }}
                >
                  <ValueDate>{row?.absolutReportDate}</ValueDate>
                  {row?.dynamicReportDate && (
                    <ValueDate>{row?.dynamicReportDate}</ValueDate>
                  )}
                </VEDRowProgressLine>
              </VEDRowProgress>
              {row?.valueHL !== undefined && (
                <VEDRowHL isBig={isOpenPopUp}>
                  {<CompareIcons value={+row.valueHL!!} isBig={isOpenPopUp} />}
                </VEDRowHL>
              )}
            </EnterpriseListRow>
          );
        })}
      </EnterpriseWrapper>
    </BlockWpapper>
  );
};
