import { FC, SVGProps } from "react";

import theme from "../../app/theme/common";

export interface IDynamicsLowProps extends SVGProps<SVGSVGElement> {
  contrast?: boolean;
  isBig?: boolean;
}

const icon: FC<IDynamicsLowProps> = ({
  children,
  contrast = false,
  isBig,
  ...props
}) => (
  <svg
    width={isBig ? "40" : "24"}
    height={isBig ? "40" : "24"}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      opacity="0.6"
      cx="12"
      cy="12"
      r="11.5"
      stroke={contrast ? theme.palette.success.main : theme.palette.error.main}
    />
    <path
      d="M6.2373 6.40525H8.5323C9.3783 6.40525 10.0023 6.58825 10.4043 6.95425C10.8063 7.31425 11.0073 7.80025 11.0073 8.41225C11.0073 9.02425 10.8063 9.51325 10.4043 9.87925C10.0023 10.2393 9.3783 10.4193 8.5323 10.4193H7.3173V12.7053H6.2373V6.40525ZM8.4423 9.48325C8.7243 9.48325 8.96131 9.46225 9.15331 9.42025C9.3453 9.37225 9.49831 9.30625 9.61231 9.22225C9.72631 9.13225 9.8073 9.02125 9.8553 8.88925C9.9033 8.75125 9.9273 8.59225 9.9273 8.41225C9.9273 8.23225 9.9033 8.07625 9.8553 7.94425C9.8073 7.81225 9.72631 7.70125 9.61231 7.61125C9.49831 7.52125 9.3453 7.45525 9.15331 7.41325C8.96131 7.36525 8.7243 7.34125 8.4423 7.34125H7.3173V9.48325H8.4423Z"
      fill={contrast ? theme.palette.success.main : theme.palette.error.main}
    />
    <path
      d="M14.7887 12.3723C14.3087 12.3723 13.8827 12.3063 13.5107 12.1743C13.1447 12.0363 12.8357 11.8443 12.5837 11.5983C12.3317 11.3463 12.1397 11.0493 12.0077 10.7073C11.8817 10.3593 11.8187 9.97525 11.8187 9.55525V9.37525C11.8187 8.97925 11.8817 8.61925 12.0077 8.29525C12.1397 7.96525 12.3317 7.68625 12.5837 7.45825C12.8357 7.23025 13.1447 7.05325 13.5107 6.92725C13.8827 6.80125 14.3087 6.73825 14.7887 6.73825V6.13525H15.8687V6.73825C16.3427 6.73825 16.7627 6.80125 17.1287 6.92725C17.5007 7.05325 17.8127 7.23025 18.0647 7.45825C18.3167 7.68625 18.5087 7.96525 18.6407 8.29525C18.7727 8.61925 18.8387 8.97925 18.8387 9.37525V9.55525C18.8387 9.97525 18.7727 10.3593 18.6407 10.7073C18.5087 11.0493 18.3167 11.3463 18.0647 11.5983C17.8127 11.8443 17.5007 12.0363 17.1287 12.1743C16.7627 12.3063 16.3427 12.3723 15.8687 12.3723V12.9753H14.7887V12.3723ZM17.7047 9.37525C17.7047 8.82925 17.5517 8.41225 17.2457 8.12425C16.9397 7.83625 16.4807 7.69225 15.8687 7.69225V11.4183C16.4867 11.4183 16.9457 11.2563 17.2457 10.9323C17.5517 10.6083 17.7047 10.1493 17.7047 9.55525V9.37525ZM12.9527 9.55525C12.9527 10.1493 13.1027 10.6083 13.4027 10.9323C13.7087 11.2563 14.1707 11.4183 14.7887 11.4183V7.69225C14.1707 7.69225 13.7087 7.83625 13.4027 8.12425C13.1027 8.41225 12.9527 8.82925 12.9527 9.37525V9.55525Z"
      fill={contrast ? theme.palette.success.main : theme.palette.error.main}
    />
    <path
      d="M11.7825 19.0388L7.79118 15.0964C7.66441 14.9712 7.75419 14.7571 7.93347 14.7571L15.9161 14.7571C16.0954 14.7571 16.1852 14.9712 16.0584 15.0964L12.0671 19.0388C11.9885 19.1164 11.8611 19.1164 11.7825 19.0388Z"
      fill={contrast ? theme.palette.success.main : theme.palette.error.main}
      stroke={contrast ? theme.palette.success.main : theme.palette.error.main}
    />
  </svg>
);

export default icon;
