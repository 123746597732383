import {
  getOperands,
  olapRequestData2,
  olapRequestDimensionData,
} from "@/store/api/core";
import {
  FILTER_OPERAND_TYPE,
  FILTER_TYPE,
  MODEL_IDS,
  MODELS,
} from "@/store/scheme/olap";

export const getRiskProject = ({
  projectId,
  dataSummaryId = "0",
}: {
  projectId: string;
  dataSummaryId?: string;
}) => {
  const model = MODELS[MODEL_IDS.RISKS];

  const dimensions = [
    {
      id: model.dataPeriodId,
      includeItems: true,
      includeAttributesByCodes: ["REPORT_DATE"],
    },
    {
      id: model.dataRegionId,
    },
    {
      id: model.dataProjectId,
    },
    {
      id: model.dimId,
    },
    {
      id: model.dataResultId,
    },
    {
      id: model.dataRiskId,
    },
    {
      id: model.dataSummaryId,
    },
  ];

  const indicators = {
    id: model.indicatorId,
    items: [{ id: model.indexes.color }, { id: model.indexes.status }],
  };

  const operands = getOperands([
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      dim_id: model.dataPeriodId,
      levels: [2],
      showChildren: false,
      showLeafsOnly: false,
      showAllLevelElements: false,
    },
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      dim_id: model.dataRegionId,
      levels: [2],
      showChildren: false,
      showLeafsOnly: false,
      showAllLevelElements: false,
    },
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      dim_id: model.dataProjectId,
      levels: [0, 1],
      showChildren: false,
      showLeafsOnly: false,
      showAllLevelElements: false,
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataProjectId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: [projectId],
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      dim_id: model.dimId,
      levels: [0],
      showChildren: false,
      showLeafsOnly: false,
      showAllLevelElements: false,
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataResultId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: ["151"],
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataRiskId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: ["2"],
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataSummaryId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: [dataSummaryId],
        },
      ],
    },
  ]);

  return olapRequestData2(
    dimensions.map(({ id }) => id),
    model.indicatorId,
    {
      modelUuid: model.uuid,
      includeGaps: false,
      indicatorId: model.indicatorId,
      dimensions,
      dataRequestCaching: true,
      indicators,
      dataFilter: {
        type: FILTER_TYPE.AND,
        version: 1,
        operands,
      },
    },
    "9eded004-7986-4fc3-b306-101aa74cf50c"
  );
};

export const getRiskReference = () => {
  const model = MODELS[MODEL_IDS.RISKS];
  return olapRequestDimensionData(
    model.dataProjectId,
    {
      modelUuid: model.uuid,
      dimensionId: "940",
      includeAttributesByCodes: [
        "project_id",
        "group",
        "description",
        "foiv",
        "solving",
      ],
    },
    "bb57a23e-4bac-496f-82c8-0bc3cb75f6f6"
  );
};
