import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  FormControl,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import { FC, ReactElement, ReactNode, useEffect, useState } from "react";

import { IAppBarSimpleFilter } from "../../lib/types";
import {
  BootstrapInput,
  ListTextStyled,
  MenuItemStyled,
  WrapperStack,
} from "./AppBarSimpleFilter.styled";

export const AppBarSimpleFilter: FC<IAppBarSimpleFilter> = ({
  title,
  arrayData,
  indexCurrentValue,
  onChange,
}) => {
  const [selectValue, setSelectValue] = useState<string>("");
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleChange = (event: SelectChangeEvent, child: ReactNode) => {
    const value = arrayData.find(
      (item) => item?.id === (child as ReactElement)?.props?.id
    );
    if (value) {
      setSelectValue(value?.name);
      if (onChange) {
        onChange(value);
      }
    }
  };

  const handleToggleSelect = () => {
    setOpen((status) => !status);
  };

  useEffect(() => {
    setSelectValue(arrayData[indexCurrentValue || 0]?.name);
  }, [arrayData, indexCurrentValue]);

  return (
    <WrapperStack direction="row">
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        flex={1}
        sx={{ overflow: "hidden" }}
      >
        <FormControl fullWidth>
          <Select
            open={isOpen}
            IconComponent={KeyboardArrowDownIcon}
            onOpen={handleToggleSelect}
            onClose={handleToggleSelect}
            value={selectValue || ""}
            onChange={handleChange}
            input={<BootstrapInput />}
            renderValue={() => (
              <ListTextStyled primary={title} secondary={selectValue} />
            )}
            MenuProps={{ disablePortal: true }}
          >
            {arrayData?.map((item) => (
              <MenuItemStyled key={item?.id} id={item?.id} value={item?.name}>
                <Typography noWrap>{item?.name}</Typography>
              </MenuItemStyled>
            ))}
          </Select>
        </FormControl>
      </Stack>
    </WrapperStack>
  );
};
