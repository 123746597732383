import React from "react";

import { DefaultButtonStyled } from "./DefaultButton.styled";

export const DefaultButton = ({ ...props }) => {
  const { titleText, icon } = props;
  return (
    <DefaultButtonStyled {...props}>
      <>
        {icon && <div className="iconWrapper">{icon}</div>}
        {titleText}
      </>
    </DefaultButtonStyled>
  );
};
