import client from "@store/api/client";
import { getOperands, olapRequestData2 } from "@store/api/core";
import {
  DATA_ACTUAL_VALUE,
  FILTER_OPERAND_TYPE,
  FILTER_TYPE,
  MODEL_IDS,
  MODELS,
} from "@store/scheme/olap";

import { IGeoJsonMapData } from "../../lib/types";

export const MAP_API_BASEPATH = "/api/content_api/v1.0/map/";

export const getGeoJSON = (mapId: string) =>
  client.get<IGeoJsonMapData>(`${MAP_API_BASEPATH}${mapId}`);

export const getKpiData = (dataSummaryId: string) => {
  const model = MODELS[MODEL_IDS.KPI_SOC_ECONOMY];

  const dimensions = [
    {
      id: model.dataPeriodId,
      includeItems: true,
      includeAttributesByCodes: ["REPORT_DATE"],
    },
    { id: model.dataRegionId },
    { id: model.dimId },
    { id: model.dataActualId },
    { id: model.dataSummaryId },
  ];

  const indicators = {
    id: model.indicatorId,
    items: [
      { id: model.indexes.fact },
      { id: model.indexes.plan },
      { id: model.indexes.factChangeAppg },
      { id: model.indexes.factChangeYearStart },
      { id: model.indexes.factHighLowRF },
      { id: model.indexes.factHighLowFO },
      { id: model.indexes.color },
      { id: model.indexes.colorGraph },
    ],
  };

  const operands = getOperands([
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      dim_id: model.dataPeriodId,
      showLeafsOnly: false,
      showChildren: false,
      levels: [1, 2, 3],
      showAllLevelElements: false,
    },
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      dim_id: model.dataRegionId,
      showLeafsOnly: false,
      showChildren: false,
      levels: [0, 1, 2],
      showAllLevelElements: false,
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dimId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: [
            "1",
            "2",
            "3",
            "4",
            "10",
            "104",
            "121",
            "123",
            "127",
            "132",
            "133",
          ],
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataActualId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: [DATA_ACTUAL_VALUE],
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataSummaryId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: [dataSummaryId],
        },
      ],
    },
  ]);

  return olapRequestData2(
    dimensions.map(({ id }) => id),
    model.indicatorId,
    {
      includeGaps: false,
      dataRequestCaching: true,
      modelUuid: model.uuid,
      dimensions,
      indicators,
      dataFilter: {
        type: FILTER_TYPE.AND,
        version: 1,
        operands,
      },
    },
    "e592a6cb-fd53-4276-b991-537570cf0b24"
  );
};

export const getProjectPassport = (dataSummaryId: string) => {
  const model = MODELS[MODEL_IDS.PROJECT_PASSPORT];

  const dimensions = [
    {
      id: model.dataPeriodId,
      includeItems: true,
      includeAttributesByCodes: ["REPORT_DATE"],
    },
    { id: model.dataRegionId },
    { id: model.dataProjectId },
    { id: model.dimId },
    { id: model.dataActualId },
    { id: model.dataSummaryId },
  ];

  const indicators = {
    id: model.indicatorId,
    items: [
      { id: model.indexes.fact },
      { id: model.indexes.plan },
      { id: model.indexes.percent },
      { id: model.indexes.achievement },
      { id: model.indexes.color },
      { id: model.indexes.achievementProjectColor },
    ],
  };

  const operands = getOperands([
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      dim_id: model.dataPeriodId,
      showLeafsOnly: false,
      showChildren: false,
      levels: [1, 2],
      showAllLevelElements: false,
    },
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      dim_id: model.dataRegionId,
      showLeafsOnly: false,
      showChildren: false,
      levels: [0, 1, 2],
      showAllLevelElements: false,
    },
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      dim_id: model.dataProjectId,
      showLeafsOnly: false,
      showChildren: false,
      levels: [0],
      showAllLevelElements: false,
    },
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      dim_id: model.dimId,
      showLeafsOnly: false,
      showChildren: false,
      levels: [0, 1],
      showAllLevelElements: false,
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataActualId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: [DATA_ACTUAL_VALUE],
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataSummaryId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: [dataSummaryId],
        },
      ],
    },
  ]);

  return olapRequestData2(
    dimensions.map(({ id }) => id),
    model.indicatorId,
    {
      includeGaps: false,
      dataRequestCaching: true,
      modelUuid: model.uuid,
      dimensions,
      indicators,
      dataFilter: {
        type: FILTER_TYPE.AND,
        version: 1,
        operands,
      },
    },
    "c72d4219-9dad-4b37-b5a0-2a5416c7f1f9"
  );
};

export const getProjectResults = () => {
  const model = MODELS[MODEL_IDS.PROJECT_RESULTS];

  const dimensions = [
    {
      id: model.dataPeriodId,
      includeItems: true,
      includeAttributesByCodes: ["REPORT_DATE"],
    },
    { id: model.dataRegionId },
    { id: model.dataProjectId },
    { id: model.dataResultId },
    { id: model.dataCheckpointId },
    { id: model.dataActualId },
  ];

  const indicators = {
    id: model.indicatorId,
    items: [
      { id: model.indexes.fact },
      { id: model.indexes.plan },
      { id: model.indexes.percent },
    ],
  };

  const operands = getOperands([
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      dim_id: model.dataRegionId,
      showLeafsOnly: false,
      showChildren: false,
      levels: [0, 1, 2],
      showAllLevelElements: false,
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataProjectId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: ["0"],
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataResultId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: ["0"],
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataCheckpointId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: ["0"],
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataActualId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: [DATA_ACTUAL_VALUE],
        },
      ],
    },
  ]);

  return olapRequestData2(
    dimensions.map(({ id }) => id),
    model.indicatorId,
    {
      includeGaps: false,
      dataRequestCaching: true,
      modelUuid: model.uuid,
      dimensions,
      indicators,
      dataFilter: {
        type: FILTER_TYPE.AND,
        version: 1,
        operands,
      },
    },
    "50c3372a-96d2-44aa-b0cb-5bf321f7f299"
  );
};

export const getProjectsData = () => {
  const model = MODELS[MODEL_IDS.PROJECT_PASSPORT];

  const dimensions = [
    {
      id: model.dataPeriodId,
      includeItems: true,
      includeAttributesByCodes: ["REPORT_DATE"],
    },
    { id: model.dataRegionId },
    { id: model.dataProjectId },
    { id: model.dimId },
    { id: model.dataActualId },
  ];

  const indicators = {
    id: model.indicatorId,
    items: [
      { id: model.indexes.fact },
      { id: model.indexes.plan },
      { id: model.indexes.percent },
      { id: model.indexes.achievement },
    ],
  };

  const operands = getOperands([
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      dim_id: model.dataPeriodId,
      showLeafsOnly: false,
      showChildren: false,
      levels: [2],
      showAllLevelElements: false,
    },
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      dim_id: model.dataRegionId,
      showLeafsOnly: false,
      showChildren: false,
      levels: [0, 1, 2],
      showAllLevelElements: false,
    },
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      dim_id: model.dataProjectId,
      showLeafsOnly: false,
      showChildren: false,
      levels: [0, 1],
      showAllLevelElements: false,
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataActualId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: ["2"],
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      dim_id: model.dimId,
      showLeafsOnly: false,
      showChildren: false,
      levels: [0, 1],
      showAllLevelElements: false,
    },
  ]);

  return olapRequestData2(
    dimensions.map(({ id }) => id),
    model.indicatorId,
    {
      includeGaps: false,
      dataRequestCaching: true,
      modelUuid: model.uuid,
      dimensions,
      indicators,
      dataFilter: {
        type: FILTER_TYPE.AND,
        version: 1,
        operands,
      },
    },
    "df48b8f3-3665-4d48-bdf3-446053292095"
  );
};

export const getProjectsKT = () => {
  const model = MODELS[MODEL_IDS.PROJECT_RESULTS];

  const dimensions = [
    {
      id: model.dataPeriodId,
      includeItems: true,
      includeAttributesByCodes: ["REPORT_DATE"],
    },
    { id: model.dataRegionId },
    { id: model.dataProjectId },
    { id: model.dataResultId },
    { id: model.dataCheckpointId },
    { id: model.dimId },
  ];

  const indicators = {
    id: model.indicatorId,
    items: [
      { id: model.indexes.fact },
      { id: model.indexes.plan },
      { id: model.indexes.percent },
    ],
  };

  const operands = getOperands([
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataPeriodId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: ["420220006"],
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      dim_id: model.dataRegionId,
      showLeafsOnly: false,
      showChildren: false,
      levels: [0, 1, 2],
      showAllLevelElements: false,
    },
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      dim_id: model.dataProjectId,
      showLeafsOnly: false,
      showChildren: false,
      levels: [1],
      showAllLevelElements: false,
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataResultId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: ["0"],
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataCheckpointId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: ["0"],
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dimId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: ["2"],
        },
      ],
    },
  ]);

  return olapRequestData2(
    dimensions.map(({ id }) => id),
    model.indicatorId,
    {
      includeGaps: false,
      dataRequestCaching: true,
      modelUuid: model.uuid,
      dimensions,
      indicators,
      dataFilter: {
        type: FILTER_TYPE.AND,
        version: 1,
        operands,
      },
    },
    "c197d7ef-45b4-464a-aa63-ad1e47d519dd"
  );
};

export const getProjectPassportForProjects = (dataSummaryId: string) => {
  const model = MODELS[MODEL_IDS.PROJECT_PASSPORT];

  const dimensions = [
    {
      id: model.dataPeriodId,
      includeItems: true,
      includeAttributesByCodes: ["REPORT_DATE"],
    },
    { id: model.dataRegionId },
    { id: model.dataProjectId },
    { id: model.dimId },
    { id: model.dataActualId },
    { id: model.dataSummaryId },
  ];

  const indicators = {
    id: model.indicatorId,
    items: [
      { id: model.indexes.fact },
      { id: model.indexes.plan },
      { id: model.indexes.percent },
      { id: model.indexes.achievement },
      { id: model.indexes.achievementProjectColor },
    ],
  };

  const operands = getOperands([
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      dim_id: model.dataPeriodId,
      showLeafsOnly: false,
      showChildren: false,
      levels: [1, 2],
      showAllLevelElements: false,
    },
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      dim_id: model.dataRegionId,
      showLeafsOnly: false,
      showChildren: false,
      levels: [2],
      showAllLevelElements: false,
    },
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      dim_id: model.dataProjectId,
      showLeafsOnly: false,
      showChildren: false,
      levels: [1],
      showAllLevelElements: false,
    },
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      dim_id: model.dimId,
      showLeafsOnly: false,
      showChildren: false,
      levels: [0, 1],
      showAllLevelElements: false,
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataActualId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: [DATA_ACTUAL_VALUE],
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataSummaryId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: [dataSummaryId],
        },
      ],
    },
  ]);

  return olapRequestData2(
    dimensions.map(({ id }) => id),
    model.indicatorId,
    {
      includeGaps: false,
      dataRequestCaching: true,
      modelUuid: model.uuid,
      dimensions,
      indicators,
      dataFilter: {
        type: FILTER_TYPE.AND,
        version: 1,
        operands,
      },
    },
    "a75983c2-8d6e-40fe-83a5-7fa3756983a8"
  );
};
