import { useMemo } from "react";
import { useLocation } from "react-router";

import { useTabs } from "@/app/hooks/useTabs";
import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { TAB_ID } from "@/store/scheme/region";

export const useSubFooter = () => {
  const { pathname } = useLocation();
  const { activeTab } = useTabs();
  const { isCheckRfFoPage } = useRegions();
  const isCheckManufacuringTab = useMemo(() => {
    return (
      activeTab === TAB_ID.MANUFACTURING &&
      !isCheckRfFoPage.isRF &&
      pathname.startsWith("/region")
    );
  }, [activeTab, isCheckRfFoPage.isRF, pathname]);

  return {
    isCheckManufacuringTab,
  };
};
