import { styled } from "@mui/material";

import { colorBasedOnValue } from "@/pages/MainPage/lib/helpers";

export const AchievementHeader = styled("div")(({ theme }) => ({
  flex: "1 1 100%",
  display: "flex",
  justifyContent: "space-between",
  height: "100%",
}));

export const AchievementTitle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  height: "100%",
  paddingTop: theme.spacing(0.5),
  fontWeight: 400,
  fontSize: theme.spacing(2),
  lineHeight: theme.spacing(2.5),
  color: theme.palette.text.unitTextSelected,
}));

export const AchievementValue = styled("div")<{ valueColor?: number }>(
  ({ theme, valueColor = null }) => ({
    color: colorBasedOnValue(theme, valueColor),
    "& > span": {
      fontWeight: 600,
      fontSize: theme.spacing(3),
      lineHeight: theme.spacing(3),
    },
  })
);
