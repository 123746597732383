import { ArgumentsWithDistrictIdType } from "@/shared/lib/types";
import { getOperands, olapRequestData2 } from "@/store/api/core";
import {
  DATA_ACTUAL_VALUE,
  FILTER_OPERAND_TYPE,
  FILTER_TYPE,
  MODEL_IDS,
  MODELS,
} from "@/store/scheme/olap";

import { generalIndicatorsValues } from "../../lib/constants";

export const getGeneralIndicators = ({
  regionId,
  districtId,
  dataSummaryId,
}: ArgumentsWithDistrictIdType) => {
  const model = MODELS[MODEL_IDS.KPI_SOC_ECONOMY];

  const dimensions = [
    {
      id: model.dataPeriodId,
      includeItems: true,
      includeAttributesByCodes: ["REPORT_DATE"],
    },
    { id: model.dataRegionId },
    { id: model.dimId },
    { id: model.dataActualId },
    { id: model.dataSummaryId },
  ];

  const indicators = {
    id: model.indicatorId,
    items: [
      { id: model.indexes.fact },
      { id: model.indexes.placeChangeRF },
      { id: model.indexes.placeChangeFO },
      { id: model.indexes.placeRF },
      { id: model.indexes.placeFO },
      { id: model.indexes.colorChangeRF },
      { id: model.indexes.colorChangeFO },
      { id: model.indexes.color },
      { id: model.indexes.factChangeYearStart },
      { id: model.indexes.factChangeAptg },
      { id: model.indexes.colorChangeAptg },
      { id: model.indexes.factHighLowRF },
      { id: model.indexes.factHighLowFO },
    ],
  };

  const operands = getOperands([
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataRegionId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: [regionId],
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dimId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: [...generalIndicatorsValues],
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataActualId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: [DATA_ACTUAL_VALUE],
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataSummaryId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: [dataSummaryId],
        },
      ],
    },
  ]);

  return olapRequestData2(
    dimensions.map(({ id }) => id),
    model.indicatorId,
    {
      modelUuid: model.uuid,
      includeGaps: false,
      indicatorId: model.indicatorId,
      dimensions,
      dataRequestCaching: true,
      indicators,
      dataFilter: {
        type: FILTER_TYPE.AND,
        version: 1,
        operands,
      },
    },
    "335c74a6-2dae-4e18-9c8c-7bee2ad502fb"
  );
};

export const getGeneralIndicatorsInvestment = ({
  regionId,
  districtId,
  dataSummaryId,
}: ArgumentsWithDistrictIdType) => {
  const model = MODELS[MODEL_IDS.KPI_SOC_ECONOMY];

  const dimensions = [
    {
      id: model.dataPeriodId,
      includeItems: true,
      includeAttributesByCodes: ["REPORT_DATE"],
    },
    { id: model.dataRegionId },
    { id: model.dimId },
    { id: model.dataActualId },
    { id: model.dataSummaryId },
  ];

  const indicators = {
    id: model.indicatorId,
    items: [{ id: model.indexes.fact }],
  };

  const operands = getOperands([
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      version: 1,
      dim_id: model.dataRegionId,
      showLeafsOnly: false,
      showChildren: false,
      levels: [2],
      showAllLevelElements: false,
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      version: 1,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          version: 1,
          id: model.dimId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          version: 1,
          values: ["7"],
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      version: 1,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          version: 1,
          id: model.dataActualId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          version: 1,
          values: [DATA_ACTUAL_VALUE],
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      version: 1,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          version: 1,
          id: model.dataSummaryId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          version: 1,
          values: [dataSummaryId],
        },
      ],
    },
  ]);

  return olapRequestData2(
    dimensions.map(({ id }) => id),
    model.indicatorId,
    {
      modelUuid: model.uuid,
      includeGaps: false,
      indicatorId: model.indicatorId,
      dimensions,
      dataRequestCaching: false,
      indicators,
      dataFilter: {
        type: FILTER_TYPE.AND,
        version: 1,
        operands,
      },
    },
    "7690a34c-bfbd-4cc2-8c3b-3728b7d134b5"
  );
};
