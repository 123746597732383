import {
  IProjectCheckpointAttributeVals as IPCAV,
  IProjectResultsAttributeVals as IPRAV,
} from "@store/scheme/olap";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";

import { IProjectResultsTableProps } from "@/pages/ProjectsPage/lib/types";
import { useCheckpointsFilter } from "@/shared/InputSelect/hooks/useCheckpointsFilter";
import { IColumn } from "@/shared/TableBase/lib/types";

import { ICONS } from "../lib/constants";
import { useProjectPage } from "./useProjectPage";

export const useProjectResults = ({
  handleLegendCallback,
}: IProjectResultsTableProps) => {
  const { allResults } = useProjectPage();
  const { Select, allCheckpoints } = useCheckpointsFilter();
  const [activeIcon, setActiveIcon] = useState(ICONS[0].id);

  useEffect(() => {
    handleLegendCallback(activeIcon === "1" ? false : true);
  }, []);

  const changeContent = (icon: string) => {
    setActiveIcon(icon);
    handleLegendCallback(icon === "1" ? false : true);
  };

  const setLabelFactCell = (id: string, dim: any) => {
    return dim?.attributeVals?.status === "Не выполнено" &&
      !dim?.attributeVals[id as keyof IPCAV]
      ? "-"
      : dim?.attributeVals[id as keyof IPCAV];
  };

  const setLabelCell = useCallback((id: string, dim: any) => {
    switch (true) {
      case id !== "status":
        return id === "achievement_date_fact"
          ? setLabelFactCell(id, dim)
          : dim?.attributeVals[id as keyof IPCAV];

      case id === "status":
        if (dim?.attributeVals?.status === "Выполнено") return 1;
        if (dim?.attributeVals?.status === "Не выполнено") return 0;
        return "";

      default:
        return "-";
    }
  }, []);

  const columns = useMemo(
    () =>
      [
        {
          id: "name",
          label: "Наименование результата",
          width: 520,
        },
        {
          id: "achievement_date",
          label: "Срок достижения",
          width: 150,
        },
        {
          id: "checkpoint-name",
          label: "Контрольные точки",
          width: 830,
        },
        {
          id: "checkpoint-status",
          label: "Статус",
          width: 80,
        },
        {
          id: "checkpoint-achievement_date_plan",
          label: "Срок достижения план",
          width: 130,
        },
        {
          id: "checkpoint-achievement_date_fact",
          label: "Срок достижения факт",
          width: 130,
        },
      ] as IColumn[],
    []
  );

  const sortAllResults = useMemo(() => {
    return Object.keys(allResults ?? {})?.sort((a, b) => {
      const dateA = allResults?.[a]?.attributeVals?.["achievement_date"];
      const dateB = allResults?.[b]?.attributeVals?.["achievement_date"];

      if (!dateA || !dateB || dateA === dateB) return 0;

      return moment(dateA, "DD-MM-YYYY").isBefore(moment(dateB, "DD-MM-YYYY"))
        ? -1
        : 1;
    });
  }, [allResults, allCheckpoints]);

  const rows = useMemo(() => {
    return (sortAllResults.length > 0 ? sortAllResults : ["000000"]).reduce(
      (prev, resultId) => {
        const result = allResults?.[resultId];
        const curCheckpoints = [...(allCheckpoints?.[resultId] || [])];
        let resultCells: IColumn[];
        if (result) {
          resultCells = ["name", "achievement_date"].map((id) => ({
            id,
            label: result?.attributeVals?.[id as keyof IPRAV],
            rowSpan: curCheckpoints?.length ? curCheckpoints.length : 1,
          }));
        } else {
          resultCells = ["name", "achievement_date"].map((id) => ({
            id,
            label: "-",
            rowSpan: 1,
          }));
        }

        const getCells = (
          index = 0,
          dim?: any,
          isNotCurrentCheckpoints?: boolean
        ) => {
          const cells = [...(index === 0 ? resultCells : [])];

          cells.push(
            ...[
              "name",
              "status",
              "achievement_date_plan",
              "achievement_date_fact",
            ].map((id) => ({
              id: `checkpoint-${id}`,
              label:
                id === "status" && isNotCurrentCheckpoints
                  ? "-"
                  : setLabelCell(id, dim),
            }))
          );

          return cells;
        };

        if (!curCheckpoints || curCheckpoints.length < 1) {
          prev.push(getCells(0, undefined, true));
          return prev;
        }

        curCheckpoints
          .sort((a, b) => {
            const dateA = a?.dims?.["847"].attributeVals.achievement_date_plan;
            const dateB = b?.dims?.["847"].attributeVals.achievement_date_plan;

            if (!dateA || !dateB || dateA === dateB) return 0;

            return moment(dateA, "DD-MM-YYYY").isBefore(
              moment(dateB, "DD-MM-YYYY")
            )
              ? -1
              : 1;
          })
          .forEach((checkpoint, index) => {
            prev.push(getCells(index, checkpoint?.dims?.["847"]));
          });

        return prev;
      },
      [] as IColumn[][]
    );
  }, [allResults, allCheckpoints, sortAllResults]);

  return {
    columns,
    rows,
    activeIcon,
    changeContent,
    Select,
  };
};
