import { styled } from "@mui/material";

import { ACHIEVEMENT_COLOR } from "@/shared/AchievementLevel/lib/types";

export const AchievementContainer = styled("div")<{ size: number }>(
  ({ theme, size }) => ({
    position: "relative",
    width: size,
    height: size,
  })
);

export const AchievementLevelText = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  display: "flex",
  flexFlow: "column nowrap",
  alignItems: "center",
}));

export const AchievementUnit = styled("p")<{ isOpenPopUp?: boolean }>(
  ({ theme, isOpenPopUp }) => ({
    fontSize: theme.spacing(isOpenPopUp ? 3 : 2),
    lineHeight: theme.spacing(2.37),
    opacity: 0.5,
  })
);

export const AchievementValue = styled("p")<{
  color?: string;
  isOpenPopUp?: boolean;
}>(({ theme, color = ACHIEVEMENT_COLOR.GREY, isOpenPopUp = false }) => {
  return {
    fontWeight: 600,
    fontSize: theme.spacing(isOpenPopUp ? 6 : 3.5),
    lineHeight: isOpenPopUp ? "3.625rem" : theme.spacing(4),
    color: color,
  };
});

export const AchievementLable = styled("p")<{ isOpenPopUp?: boolean }>(
  ({ theme, isOpenPopUp = false }) => ({
    maxWidth: isOpenPopUp ? 97 : 69,
    fontSize: theme.spacing(isOpenPopUp ? 2 : 1.5),
    lineHeight: theme.spacing(1.75),
    textAlign: "center",
    opacity: 0.5,
  })
);
