import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { prepareAttributes } from "@/shared/lib/heplers";
import {
  IPeriodAttributeVals,
  IPreparedIndicatorAttributeVals,
  IRowDatePart,
  MODEL_IDS,
  MODELS,
} from "@/store/scheme/olap";

import { IRiskTableState } from "../lib/types";
import { getRiskProjectsTable, getRiskSubjectsTable } from "./riskApi";

export const initialState: IRiskTableState = {
  riskData: undefined,
  error: null,
  fetching: false,
  riskProjectData: undefined,
  projectError: null,
  projectFecthing: false,
};

export const extraActionsRisksTable = {
  post: createAsyncThunk(
    "riskTable/post",
    async (dataSummaryId: string) => await getRiskSubjectsTable(dataSummaryId)
  ),
  postRiskProjects: createAsyncThunk(
    "riskTable/project/post",
    async (dataSummaryId: string) => await getRiskProjectsTable(dataSummaryId)
  ),
};

const riskTableSlice = createSlice({
  name: "riskTable",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActionsRisksTable.post.pending, (state) => {
        state.riskData = undefined;
        state.fetching = true;
      })
      .addCase(extraActionsRisksTable.postRiskProjects.pending, (state) => {
        state.riskProjectData = undefined;
        state.projectFecthing = true;
      })
      .addCase(extraActionsRisksTable.post.fulfilled, (state, action) => {
        const model = MODELS[MODEL_IDS.RISKS];
        state.riskData = action.payload.reduce((prev, row) => {
          const dimId = model.dataResultId;
          const regionId = row.dims[model.dataRegionId].code;
          const projectId = row.dims[model.dataResultId].code;
          const dateCode = row.code;
          const datePart = (
            row.attributeVals as unknown as IPeriodAttributeVals
          ).DatePart;
          const date = (row.attributeVals as unknown as IPeriodAttributeVals)
            .REPORT_DATE;
          const prevDate = (
            row.attributeVals as unknown as IPeriodAttributeVals
          ).PREVIOUS_DATE;
          const year = (row.attributeVals as unknown as IPeriodAttributeVals)
            .YEAR;
          const item = row.dims[dimId];

          if (!prev[regionId]) {
            prev[regionId] = {};
          }

          if (!prev[regionId][projectId]) {
            prev[regionId][projectId] = {};
          }

          if (!prev[regionId][projectId][datePart]) {
            prev[regionId][projectId][datePart] = {};
          }

          prev[regionId][projectId][datePart]![dateCode] = {
            id: item.id,
            code: item.code,
            date,
            prevDate,
            year: year ? `${year} г.` : undefined,
            level: item.level,
            values: Object.keys(model.indexes).reduce((prev, curr) => {
              const index = model.indexes[curr as keyof typeof model.indexes];
              prev[index] = item.indicatorVals[index]?.sum;
              return prev;
            }, {} as { [sumIndicatorId: string]: number }),
            attributeVals: prepareAttributes<IPreparedIndicatorAttributeVals>(
              item.attributeVals
            ),
          };

          return prev;
        }, {} as { [regionId: string]: IRowDatePart<IPreparedIndicatorAttributeVals> });
        state.fetching = false;
        state.error = null;
      })
      .addCase(
        extraActionsRisksTable.postRiskProjects.fulfilled,
        (state, action) => {
          const model = MODELS[MODEL_IDS.RISKS];
          state.riskProjectData = action.payload.reduce((prev, cur) => {
            const projectId = cur?.dims?.[model.dataProjectId]?.code;
            const indicatorId = cur.dims[model.dataResultId].code;
            const dateCode = cur.code;
            const datePart = (
              cur.attributeVals as unknown as IPeriodAttributeVals
            ).DatePart;

            if (projectId) {
              if (!prev[projectId]) {
                prev[projectId] = {};
              }

              if (!prev[projectId][indicatorId]) {
                prev[projectId][indicatorId] = {};
              }

              if (!prev[projectId][indicatorId][datePart]) {
                prev[projectId][indicatorId][datePart] = {};
              }

              prev[projectId][indicatorId][datePart][dateCode] = {
                ...prepareAttributes<IPreparedIndicatorAttributeVals>(
                  cur.dims[model.dimId].attributeVals
                ),
                values: Object.keys(model.indexes).reduce((prev, curr) => {
                  const index =
                    model.indexes[curr as keyof typeof model.indexes];
                  prev[index] =
                    cur.dims[model.dimId]?.indicatorVals?.[index]?.sum;
                  return prev;
                }, {} as { [sumIndicatorId: string]: number }),
                attributeVals: cur?.attributeVals || {},
              };
            }
            return prev;
          }, {} as any);
          state.projectFecthing = false;
          state.projectError = null;
        }
      )
      .addCase(extraActionsRisksTable.post.rejected, (state, action) => {
        state.fetching = false;
        state.riskData = undefined;
        state.error = action.error;
      })
      .addCase(
        extraActionsRisksTable.postRiskProjects.rejected,
        (state, action) => {
          state.projectFecthing = false;
          state.riskProjectData = undefined;
          state.projectError = action.error;
        }
      );
  },
});

export const reducer = riskTableSlice.reducer;

export default riskTableSlice;
