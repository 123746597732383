import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { MODEL_IDS, MODELS } from "@/store/scheme/olap";

import {
  CheckpointsT,
  IPagesProjectCheckpointsAndResultsState,
  ResultsT,
} from "../../lib/types";
import {
  getCheckpoints,
  getProjectCheckpointsAndResults,
  getResults,
} from "../api/api";

export const initialState: IPagesProjectCheckpointsAndResultsState = {
  checkpoints: undefined,
  allCheckpoints: undefined,
  allResults: undefined,
  results: undefined,
  resultsSum: undefined,
  fetching: false,
  fetchingAllCheckpoints: false,
  fetchingAllResults: false,
  checkpointsError: null,
  allCheckpointsError: null,
  allResultsError: null,
};

export const extraActionsCheckpointsAndResult = {
  get: createAsyncThunk(
    "pages/project/checkpointsAndResults/get",
    async (projectId: string) =>
      await getProjectCheckpointsAndResults(projectId)
  ),
  getCheckpoints: createAsyncThunk(
    "pages/project/checkpointsAndResults/getCheckpoints",
    async (projectId: string) => await getCheckpoints(projectId)
  ),
  getResults: createAsyncThunk(
    "pages/project/checkpointsAndResults/getResults",
    async (projectId: string) => await getResults(projectId)
  ),
};

const checkpointsAndResultsSlice = createSlice({
  name: "pages/project/checkpointsAndResults",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActionsCheckpointsAndResult.get.pending, (state) => {
        state.checkpoints = undefined;
        state.results = undefined;
        state.resultsSum = undefined;
        state.fetching = true;
      })
      .addCase(
        extraActionsCheckpointsAndResult.getCheckpoints.pending,
        (state) => {
          state.allCheckpoints = undefined;
          state.fetchingAllCheckpoints = true;
        }
      )
      .addCase(extraActionsCheckpointsAndResult.getResults.pending, (state) => {
        state.allResults = undefined;
        state.fetchingAllResults = true;
      })
      .addCase(
        extraActionsCheckpointsAndResult.get.rejected,
        (state, action) => {
          state.fetching = false;
          state.checkpointsError = action.error;
        }
      )
      .addCase(
        extraActionsCheckpointsAndResult.getCheckpoints.rejected,
        (state, action) => {
          state.fetchingAllCheckpoints = false;
          state.allCheckpointsError = action.error;
        }
      )
      .addCase(
        extraActionsCheckpointsAndResult.getResults.rejected,
        (state, action) => {
          state.fetchingAllResults = false;
          state.allResultsError = action.error;
        }
      )
      .addCase(
        extraActionsCheckpointsAndResult.getResults.fulfilled,
        (state, action) => {
          state.fetchingAllResults = false;

          state.allResults = action.payload.reduce((prev, cur) => {
            const idResult = cur?.code;

            if (!idResult) return prev;

            prev[idResult] = cur;

            return prev;
          }, {} as any);
          state.allResultsError = null;
        }
      )
      .addCase(
        extraActionsCheckpointsAndResult.getCheckpoints.fulfilled,
        (state, action) => {
          state.fetchingAllCheckpoints = false;

          const { dataCheckpointId } = MODELS[MODEL_IDS.PROJECT_RESULTS];

          state.allCheckpoints = action.payload.reduce((prev, item) => {
            const idResult = item?.attributeVals?.id_result;

            if (!idResult) return prev;
            if (!prev[idResult]) prev[idResult] = [];

            prev[idResult].push({ dims: { [dataCheckpointId]: item } } as any);

            return prev;
          }, {} as CheckpointsT);
          state.allCheckpointsError = null;
        }
      )
      .addCase(
        extraActionsCheckpointsAndResult.get.fulfilled,
        (state, action) => {
          state.fetching = false;

          const {
            dataResultId,
            dataCheckpointId,
            indexes: { percent },
          } = MODELS[MODEL_IDS.PROJECT_RESULTS];

          state.results = action.payload.reduce((prev, item) => {
            const result = item.dims[dataResultId];
            if (result.id === "0" && result.indicatorVals[percent]) {
              state.resultsSum = result.indicatorVals[percent].sum;
            } else if (!prev[result.id]) prev[result.id] = result;
            return prev;
          }, {} as ResultsT);

          state.checkpoints = action.payload.reduce((prev, item) => {
            const idResult =
              item.dims?.[dataCheckpointId].attributeVals.id_result;

            if (!idResult) return prev;
            if (!prev[idResult]) prev[idResult] = [];

            prev[idResult].push(item);

            return prev;
          }, {} as CheckpointsT);
          state.checkpointsError = null;
        }
      );
  },
});

export const reducer = checkpointsAndResultsSlice.reducer;

export default checkpointsAndResultsSlice;
