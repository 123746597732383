import { getOperand, olapRequestData2 } from "@/store/api/core";
import {
  FILTER_OPERAND_TYPE,
  FILTER_TYPE,
  MODEL_IDS,
  MODELS,
} from "@/store/scheme/olap";

export const getVDL = (regionId: string) => {
  const model = MODELS[MODEL_IDS.VDL];
  const dimIds = [
    model.dataPeriodId,
    model.dataRegionId,
    model.dataRegionLeader,
    model.dimId,
    model.dataActualId,
  ];

  const dimensions = [
    {
      id: model.dataPeriodId,
      includeItems: true,
      includeAttributesByCodes: ["REPORT_DATE"],
    },
    {
      id: model.dataRegionId,
    },
    {
      id: model.dataRegionLeader,
      includeItems: true,
      includeAttributesByCodes: ["FIO", "photo"],
    },
    {
      id: model.dimId,
    },
    {
      id: model.dataActualId,
    },
  ];

  const indicators = {
    id: model.indicatorId,
    items: [{ id: model.indexes.fact }],
  };

  const operands = [
    getOperand({
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        getOperand({
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataRegionId,
        }),
        getOperand({
          type: FILTER_OPERAND_TYPE.CONST,
          values: [regionId],
        }),
      ],
    }),
    getOperand({
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        getOperand({
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataRegionLeader,
          attributeCode: "id_subject",
        }),
        getOperand({
          type: FILTER_OPERAND_TYPE.TEXT,
          values: [regionId],
        }),
      ],
    }),
    getOperand({
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        getOperand({
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataActualId,
        }),
        getOperand({
          type: FILTER_OPERAND_TYPE.CONST,
          values: ["2"],
        }),
      ],
    }),
  ];

  return olapRequestData2(
    dimIds,
    model.indicatorId,
    {
      modelUuid: model.uuid,
      includeGaps: false,
      indicatorId: model.indicatorId,
      dimensions,
      dataRequestCaching: true,
      indicators,
      dataFilter: {
        type: FILTER_TYPE.AND,
        version: 1,
        operands,
      },
    },
    "fe0ab1fe-1c75-4f2c-9e2b-7fb34bb613b6"
  );
};
