import {
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  styled,
} from "@mui/material";

export const DialogStyled = styled(Dialog)<DialogProps>(({ theme }) => ({
  "& .MuiPaper-root": {
    padding: "1.25rem 2rem",
    boxSizing: "border-box",
    background: theme.palette.modal.background,
    borderRadius: theme.spacing(0.5),
    border: `1px solid ${theme.palette.dateBadge.background}`,
  },
}));

export const DialogTitleStyled = styled(DialogTitle)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0 0 1rem 0",
  color: theme.palette.modal.text,
  fontWeight: 600,
  fontSize: "1.5rem",
  lineHeight: "1.8rem",
}));

export const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  color: theme.palette.modal.text,
  opacity: 0.7,
}));

export const DialogContentStyled = styled(DialogContent)(({ theme }) => ({
  padding: 0,
  color: theme.palette.modal.text,
  fontWeight: 400,
  fontSize: "1.25rem",
  lineHeight: "1.75rem",
  opacity: 0.7,
}));
