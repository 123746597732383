import { useCallback } from "react";

import {
  DescendingComparatorCallback,
  GetComparatorCallback,
  SortByCallback,
} from "../lib/types";

export const useSortTable = () => {
  const sortBy = useCallback<SortByCallback>((arr, comparator) => {
    return arr
      .map((i, idx) => [i, idx] as [any, number])
      .sort((a, b) => {
        const order = comparator(a[0], b[0]);

        if (order !== 0) {
          return order;
        }
        return a[1] - b[1];
      })
      .map((el) => el[0]);
  }, []);

  const descendingComparator = useCallback<DescendingComparatorCallback>(
    (a, b, orderBy) => {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    },
    []
  );

  const getComparator = useCallback<GetComparatorCallback>(
    (order, orderBy) => {
      return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
    },
    [descendingComparator]
  );

  return {
    sortBy,
    getComparator,
  };
};
