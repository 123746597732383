import { styled, TableCell } from "@mui/material";
import TableRow from "@mui/material/TableRow/TableRow";

export const TableRowStyled = styled(TableRow, {
  shouldForwardProp: (prop) => !["focused"].includes(prop.toString()),
})<{ focused?: boolean }>(({ theme, focused }) => ({
  cursor: "pointer",
  ".MuiTableCell-body": {
    borderTop: `1px solid transparent`,
    borderBottom: `1px solid ${theme.palette.table.dividerLight}`,
    borderRight: `1px solid ${theme.palette.table.dividerLight}`,
    fontWeight: 600,
  },
  "&:last-child": {
    ".MuiTableCell-body": {
      borderTop: `1px solid ${theme.palette.table.dividerLight}`,
    },
  },
  ".MuiTableCell-body:first-child": {
    borderLeft: `1px solid ${
      theme.palette.mode === "dark"
        ? "transparent"
        : theme.palette.button.border
    }`,
  },
  ".MuiTableCell-body:last-child": {
    borderRight: `1px solid ${
      theme.palette.mode === "dark"
        ? "transparent"
        : theme.palette.button.border
    }`,
  },
  "&:not(:last-child):hover": {
    ".MuiTableCell-body": {
      boxShadow: `-6.25rem -6.25rem 6.25rem 6.25rem ${theme.palette.background.rowHover} inset`,
      borderTop: `1px solid ${theme.palette.table.rowBorder}`,
      borderBottom: `1px solid ${theme.palette.table.rowBorder}`,
    },
    ".MuiTableCell-body:first-child": {
      borderRadius: theme.spacing(1, 0, 0, 1),
      borderLeft: `1px solid ${theme.palette.table.rowBorder}`,
    },
    ".MuiTableCell-body:last-child": {
      borderRadius: theme.spacing(0, 1, 1, 0),
      borderRight: `1px solid ${theme.palette.table.rowBorder}`,
    },
  },

  ...(focused && {
    ".MuiTableCell-body": {
      boxShadow: `-6.25rem -6.25rem 6.25rem 6.25rem ${theme.palette.background.rowHover} inset`,
      borderTop: `1px solid ${theme.palette.table.rowBorder}`,
      borderBottom: `1px solid ${theme.palette.table.rowBorder}`,
    },
    ".MuiTableCell-body:first-child": {
      borderRadius: theme.spacing(1, 0, 0, 1),
      borderLeft: `1px solid ${theme.palette.table.rowBorder}`,
      borderRight: `1px solid ${theme.palette.table.dividerLight}`,
    },
    ".MuiTableCell-body:last-child": {
      borderRadius: theme.spacing(0, 1, 1, 0),
      borderRight: `1px solid ${theme.palette.table.rowBorder}`,
    },
  }),
}));

export const TableCellStyled = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== "secondaryCell",
})<{ secondaryCell?: boolean }>(({ theme, secondaryCell }) => ({
  color: theme.palette.text.primary,
  background: theme.palette.background.paper,
  "&.MuiTableCell-body": {
    ...(secondaryCell && {
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: "0.87rem",
      color:
        theme.palette.mode === "dark"
          ? theme.palette.text.dataSelected
          : theme.palette.button.achievementText,
    }),
  },
}));
