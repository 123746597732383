import React from "react";

import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { CardListResultCell } from "@/shared/CardList/CardList.styled";
import { COMPARE_TYPE } from "@/shared/CompareControl/lib/types";
import { getValueItem } from "@/shared/GeneralIndicators/lib/helpers";
import { useFuturePlan } from "@/shared/Table/hooks/useFuturePlan";
import { VALUE_COLOR } from "@/shared/Table/model/api/scheme";
import { useAppSelector } from "@/store/redux/hooks";

import { notifyDynamicsCalc } from "../lib/constants";
import { getUnitMeasure } from "../lib/helpers";
import { IBudgetDynamicsProps } from "../lib/types";
import {
  BudgetRowEmptyIcon,
  BudgetRowValue,
  CompareValueStyled,
  DynamicColumn,
  ToolTipStyled,
} from "./Budget.styled";

export const BudgetDynamics = ({ row }: IBudgetDynamicsProps) => {
  const { isCheckRfFoPage } = useRegions();
  const { isCheckFuturePlan } = useFuturePlan();
  const { compare } = useAppSelector((state) => state.controls);

  const [valueByDistrict, dinamicByDistrict, colorDistrict] =
    compare.value === COMPARE_TYPE.RF
      ? [row?.rfValue, row?.rfDynamics, row?.colorRfDynamics]
      : [row?.foValue, row?.foDynamics, row?.colorFoDynamics];
  const tooltipTitle = compare.value === COMPARE_TYPE.RF ? "ФО" : "РФ";

  const isCheckNotificationByKey = (key: string) => {
    if (key === "99" && dinamicByDistrict === "-") {
      return (
        <>
          <BudgetRowEmptyIcon />
          <ToolTipStyled title={notifyDynamicsCalc} placement="bottom" arrow>
            <span>x</span>
          </ToolTipStyled>
        </>
      );
    }
    if (row?.rfDynamics !== "-" && row?.foDynamics !== "-") {
      if (!isCheckRfFoPage.isFO && row?.rfDynamics === row?.foDynamics) {
        return (
          <ToolTipStyled
            title={`Идентичное значение по ${tooltipTitle}`}
            placement="top"
            arrow
          >
            <CompareValueStyled>{`${getValueItem({
              key: row.key,
              value: dinamicByDistrict,
              valueColor: colorDistrict as unknown as VALUE_COLOR,
            })} ${getUnitMeasure(row.key)}`}</CompareValueStyled>
          </ToolTipStyled>
        );
      }
    }
    return (
      <DynamicColumn
        isOpacity
        valueColor={+(colorDistrict ?? "") as unknown as VALUE_COLOR}
      >
        {![undefined, "-"].includes(dinamicByDistrict)
          ? `${getValueItem({
              key: row.key,
              value: dinamicByDistrict,
              valueColor: colorDistrict as unknown as VALUE_COLOR,
            })} ${getUnitMeasure(row.key)}`
          : "-"}
      </DynamicColumn>
    );
  };

  return (
    <>
      {isCheckFuturePlan && (
        <CardListResultCell>
          <BudgetRowValue isColorGrey>{valueByDistrict}</BudgetRowValue>
        </CardListResultCell>
      )}
      <CardListResultCell>
        {isCheckNotificationByKey(row.key)}
      </CardListResultCell>
    </>
  );
};
