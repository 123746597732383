import { useAppSelector } from "@store/redux/hooks";
import { useLocation } from "react-router";

export const useRegionMainInfo = () => {
  const location = useLocation();
  const { user } = useAppSelector((state) => state.auth.user);

  const canShowAllPage =
    !user?.isAnalyticFK ||
    (!location.pathname.includes("/manufacturing") &&
      !location.pathname.includes("/profit") &&
      !location.pathname.includes("/employed"));

  return { canShowAllPage };
};
