import { useRouter } from "@app/hooks/useRouter";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useAppSelector } from "@store/redux/hooks";
import { FC } from "react";
import { To } from "react-router";

import { useRisksTransition } from "@/pages/MainPage/hooks/useRisksTransition";
import { useDownload } from "@/shared/DownloadControl/hooks/useDownload";

import { AchievementControl } from "../../AchievementControl/ui/AchievementControl";
import { CompareControl } from "../../CompareControl/ui/CompareControl";
import { DownloadControl } from "../../DownloadControl/DownloadControl";
import { PeriodControl } from "../../PeriodControl/ui/PeriodControl";
import { TimeLineControl } from "../../TimeLineControl/TimeLineControl";
import { ITopBarProps } from "../lib/types";
import {
  Controls,
  IconButtonStyled,
  Title,
  TopBarStyled,
  TypographyStyled,
} from "./TopBar.styled";

export const TopBar: FC<ITopBarProps> = ({
  title,
  back,
  accent,
  achievement,
  compare,
  timeline,
  period,
  download,
  navigateOptions,
}) => {
  const { navigate } = useRouter();
  const { getBackLink } = useRisksTransition();
  const darkMode = useAppSelector((state) => state.ui.mode.isDark);
  const { compare: compareState } = useAppSelector((state) => state.controls);
  const { achievement: achievementState } = useAppSelector(
    (state) => state.controls
  );
  const { isCheckShowDownload } = useDownload();

  return (
    <TopBarStyled accent={accent}>
      <Title accent={accent}>
        {back && (
          <IconButtonStyled
            onClick={() =>
              navigate(
                getBackLink(back, navigateOptions) as To,
                navigateOptions
              )
            }
          >
            <ArrowBackIcon />
          </IconButtonStyled>
        )}
        <TypographyStyled variant="h4">{title}</TypographyStyled>
      </Title>

      <Controls accent={accent}>
        {achievement && <AchievementControl />}
        {compare && darkMode ? (
          <CompareControl
            isDark
            disabled={compareState.disabled || achievementState.enabled}
          />
        ) : compare ? (
          <CompareControl />
        ) : undefined}
        {timeline && <TimeLineControl region={true} />}
        {period && <PeriodControl />}
        {download && isCheckShowDownload && <DownloadControl />}
      </Controls>
    </TopBarStyled>
  );
};
