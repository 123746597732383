// eslint-disable-next-line simple-import-sort/imports
import { REGION_COUNTRY_CODE } from "@/shared/lib/constants";
import { getOperands, olapRequestData2 } from "@/store/api/core";
import {
  FILTER_OPERAND_TYPE,
  FILTER_TYPE,
  MODEL_IDS,
  MODELS,
} from "@/store/scheme/olap";
import { IIndicatorGraph } from "../../lib/types";

export const getGraphKpi = ({
  regionId,
  districtId,
  indicatorId,
}: IIndicatorGraph) => {
  const model = MODELS[MODEL_IDS.KPI_SOC_ECONOMY];
  // Для Рейтинга АСИ нужен 501 показатель
  const ratingASI =
    indicatorId === "7" ? { id: model.indexes.factChangeYearStart } : {};
  // Для численности населения не нужны данные по ФО и РФ
  const isPeopleAdmCenter = indicatorId === "27";

  const dimensions = [
    {
      id: model.dataPeriodId,
      includeItems: true,
      includeAttributesByCodes: ["YEAR", "REPORT_DATE"],
    },
    {
      id: model.dataRegionId,
    },
    {
      id: model.dimId,
      includeItems: true,
      includeAttributesByCodes: ["short_name"],
    },
    {
      id: model.dataActualId,
    },
    {
      id: model.dataSummaryId,
    },
  ];

  const indicators = {
    id: model.indicatorId,
    items: [{ id: model.indexes.fact }, { ...ratingASI }],
  };

  const operands = getOperands([
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      dim_id: model.dataPeriodId,
      showLeafsOnly: false,
      showChildren: false,
      levels: [1, 2, 3],
      showAllLevelElements: false,
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataRegionId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: [
            regionId,
            ...(!isPeopleAdmCenter ? [districtId, REGION_COUNTRY_CODE] : []),
          ],
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dimId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: [indicatorId],
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      dim_id: model.dataActualId,
      showLeafsOnly: false,
      showChildren: false,
      levels: [1],
      showAllLevelElements: false,
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataSummaryId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: ["0"],
        },
      ],
    },
  ]);

  return olapRequestData2(
    dimensions.map(({ id }) => id),
    model.indicatorId,
    {
      modelUuid: model.uuid,
      includeGaps: false,
      indicatorId: model.indicatorId,
      dimensions,
      dataRequestCaching: true,
      indicators,
      dataFilter: {
        type: FILTER_TYPE.AND,
        version: 1,
        operands,
      },
    },
    "199c7b2f-44e1-4281-84ff-2659c1ae267b"
  );
};
