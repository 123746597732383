import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IMenuState } from "../../AppBar/lib/types";

export const initialState: IMenuState = {
  open: false,
};

const menuSlice = createSlice({
  name: "ui/menu",
  initialState,
  reducers: {
    menuSetOpen: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
  },
});

export const { menuSetOpen } = menuSlice.actions;
export const reducer = menuSlice.reducer;

export default menuSlice;
