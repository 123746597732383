import { NEW_DATE, UPDATE_NOTIFICATION } from "./constants";
import { IUpdateNotification } from "./types";

export const handleNotificationsClick = (
  setNotifications: React.Dispatch<React.SetStateAction<IUpdateNotification>>
) => {
  const updateNotificationJSON = localStorage.getItem(UPDATE_NOTIFICATION);
  const updateNotification: IUpdateNotification = updateNotificationJSON
    ? JSON.parse(updateNotificationJSON)
    : {};

  const res = {
    ...updateNotification,
    show: false,
  };

  localStorage.setItem(UPDATE_NOTIFICATION, JSON.stringify(res));
  setNotifications(res);
};

export const notificationsFx = (
  setNotifications: React.Dispatch<React.SetStateAction<IUpdateNotification>>
) => {
  const updateNotificationJSON = localStorage.getItem(UPDATE_NOTIFICATION);
  const updateNotification: IUpdateNotification = updateNotificationJSON
    ? JSON.parse(updateNotificationJSON)
    : {};

  if (updateNotification) {
    if (!updateNotification?.clear) {
      localStorage.removeItem(UPDATE_NOTIFICATION);
      const res = {
        date: NEW_DATE,
        clear: true,
        show: true,
      };

      localStorage.setItem(UPDATE_NOTIFICATION, JSON.stringify(res));
      setNotifications(res);
    } else {
      setNotifications(updateNotification);
    }
  }
};

export const clearNotificationsFx = () => {
  const updateNotificationJSON = localStorage.getItem(UPDATE_NOTIFICATION);
  const updateNotification: IUpdateNotification = updateNotificationJSON
    ? JSON.parse(updateNotificationJSON)
    : {};

  if (updateNotification) {
    if (updateNotification?.date !== NEW_DATE) {
      localStorage.setItem(
        UPDATE_NOTIFICATION,
        JSON.stringify({
          ...updateNotification,
          clear: false,
        })
      );
    }
  }
};
