export interface MuiAppBarProps extends AppBarProps {
  open?: boolean;
  withMenu?: boolean;
  isDark?: boolean;
}

export interface ITypographyStyled {
  component?: string;
}

export interface AppBarProps {
  withMenu?: boolean;
}

export interface ProjectsArray {
  id: string;
  name: string;
}

export interface Item {
  name: string;
  id: string;
  children: { id: string; name: string }[];
}

export interface IAppBarFilter {
  title?: string;
  defaultValue?: string;
  arrayData: Array<{ name: string; id: string }>;
  subjectId?: string;
  indexCurrentValue?: number;
  useRF?: boolean;
  useFO?: boolean;
  isVDL?: boolean;
  onChange?: (value: { type: targetType; id: string }) => void;
}

export interface FilterItem {
  name: string;
  id: string;
  children: { id: string; name: string }[];
}

export interface IAppBarFilterItem {
  item: FilterItem;
  handleChangeRegion: (id: string) => void;
  handleChangeFO?: (item: FilterItem) => void;
  curValue?: string;
}

export interface RegionArray {
  id: string;
  name: string;
}

export interface ProjectsArray {
  id: string;
  name: string;
}

export enum targetType {
  FO = "FO",
  REGION = "Region",
}

export interface RegionArray {
  id: string;
  name: string;
}

export interface IAppBarRegionsFilter {
  subjectId: string;
  navigatePath: string;
  navigatePathFO?: string;
  useRF?: boolean;
  useFO?: boolean;
  path?: string;
}

export interface IAppBarProjectsFilterProps {
  path?: string;
}

export interface IAppBarSimpleFilter {
  title?: string;
  arrayData: Array<{ name: string; id: string }>;
  indexCurrentValue?: number;
  onChange?: (value: { name: string; id: string }) => void;
}

export interface IMenuState {
  open: boolean;
}
