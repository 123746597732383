import { Stack } from "@mui/material";
import React, { useState } from "react";

import { ACHIEVE_RISKS_VALUE } from "@/features/RegionMutableRate/lib/types";

import { SwitcherProps } from "./lib/types";
import { BinarySwitch, BinarySwitchTitle } from "./Switcher.styled";

export const BinarySwitcher = ({
  value,
  switcherData,
  callbackChange,
  openWidget,
}: SwitcherProps) => {
  const [checked, setChecked] = useState(value === ACHIEVE_RISKS_VALUE.RISKS);
  const {
    0: { title: achievement },
    1: { title: risks },
  } = switcherData;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    const value = event.target.checked
      ? ACHIEVE_RISKS_VALUE.RISKS
      : ACHIEVE_RISKS_VALUE.ACHIEVE;
    callbackChange(
      event as unknown as React.MouseEvent<HTMLElement, MouseEvent>,
      value
    );
  };

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <BinarySwitchTitle isSelected={!checked} openWidget={openWidget}>
        {achievement}
      </BinarySwitchTitle>
      <BinarySwitch checked={checked} onChange={handleChange} />
      <BinarySwitchTitle isSelected={checked} openWidget={openWidget}>
        {risks}
      </BinarySwitchTitle>
    </Stack>
  );
};
