import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  IOlapResponseDimensionItemDimWithVals,
  MODEL_IDS,
} from "@store/scheme/olap";

import { prepareResponseTotalIndicator } from "../../lib/helpers";
import { IIndicatorsTotalState } from "../../lib/types";
import { getGraphData, getIndicatorsGraphData } from "../api/api";

export const initialState: IIndicatorsTotalState = {
  graphData: {},
  graphFetching: false,
  graphError: null,
  indicatorsGraphData: {},
  indicatorsGraphFetching: false,
  indicatorGraphError: null,
};

export const extraActionsTotalIndicators = {
  getGraphData: createAsyncThunk(
    "graphs/getGraphData",
    async ({ regionId }: { regionId: string }) =>
      ({
        [MODEL_IDS.KPI_SOC_ECONOMY]: await getGraphData({
          regionIds: [regionId],
        }),
      } as Partial<Record<MODEL_IDS, IOlapResponseDimensionItemDimWithVals[]>>)
  ),
  getIndicatorsGraphData: createAsyncThunk(
    "graphs/getIndicatorsGraphData",
    async ({ regionId }: { regionId: string }) =>
      ({
        [MODEL_IDS.PROJECT_PASSPORT]: await getIndicatorsGraphData({
          regionIds: [regionId],
        }),
      } as Partial<Record<MODEL_IDS, IOlapResponseDimensionItemDimWithVals[]>>)
  ),
};

const totalIndicatorsSlice = createSlice({
  name: "indicators/total",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        extraActionsTotalIndicators.getGraphData.pending,
        (state, action) => {
          const regionId = action.meta.arg.regionId;
          state.graphData[regionId] = {};
          state.graphFetching = true;
        }
      )
      .addCase(
        extraActionsTotalIndicators.getGraphData.rejected,
        (state, action) => {
          state.graphFetching = false;
          state.graphError = action.error;
        }
      )
      .addCase(
        extraActionsTotalIndicators.getIndicatorsGraphData.pending,
        (state, action) => {
          const regionId = action.meta.arg.regionId;
          state.indicatorsGraphData[regionId] = {};
          state.indicatorsGraphFetching = true;
        }
      )
      .addCase(
        extraActionsTotalIndicators.getIndicatorsGraphData.rejected,
        (state, action) => {
          state.indicatorsGraphFetching = false;
          state.indicatorGraphError = action.error;
        }
      )
      .addCase(
        extraActionsTotalIndicators.getGraphData.fulfilled,
        (state, action) => {
          state.graphFetching = false;
          const response = action.payload;
          const regionId = action.meta.arg.regionId;

          state.graphData[regionId] = prepareResponseTotalIndicator(response);
          state.graphError = null;
        }
      )
      .addCase(
        extraActionsTotalIndicators.getIndicatorsGraphData.fulfilled,
        (state, action) => {
          state.indicatorsGraphFetching = false;
          const response = action.payload;
          const regionId = action.meta.arg.regionId;

          state.indicatorsGraphData[regionId] =
            prepareResponseTotalIndicator(response);
          state.indicatorGraphError = null;
        }
      );
  },
});

export const reducer = totalIndicatorsSlice.reducer;

export default totalIndicatorsSlice;
