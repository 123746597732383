import { MODEL_IDS, MODELS } from "@store/scheme/olap";
import { FC, useMemo } from "react";

import { useGeneralIndicators } from "@/shared/GeneralIndicators/hooks/useGeneralIndicators";
import { IValue } from "@/shared/GeneralIndicators/lib/types";
import { GeneralIndicators } from "@/shared/GeneralIndicators/ui/GeneralIndicators";
import { formatNumber } from "@/shared/lib/heplers";
import { IPopUpProps, REGION_TABLES } from "@/shared/RegionPopUp/lib/types";

export const GeneralIndicatorsEmployed: FC<IPopUpProps> = ({ isOpenPopUp }) => {
  const model = MODELS[MODEL_IDS.KPI_SOC_ECONOMY];
  const { data, indicators, generalIndicatorsTitle, fetching } =
    useGeneralIndicators();

  const values: IValue[] = useMemo(() => {
    return ["117", "76", "78", "118", "77", "79"].map((i) => {
      const subjectData = data?.[i];

      return {
        key: i,
        ...(!["118", "77", "79"].includes(i) && {
          title: subjectData?.short_name,
          place: subjectData?.indicatorVals?.[indicators.place],
          placeChange: formatNumber(
            subjectData?.indicatorVals?.[indicators.placeChange],
            undefined,
            0,
            true
          ),
          placeChangeRate: subjectData?.indicatorVals?.[indicators.colorChange],
        }),
        ...(["118", "77", "79"].includes(i) && {
          valueHL: subjectData?.indicatorVals?.[indicators.highLow],
          valueColor: subjectData?.indicatorVals?.[model.indexes.color],
        }),
        date: ["117", "76", "78"].includes(i)
          ? subjectData?.attributeVals?.REPORT_DATE
          : subjectData?.attributeVals?.PREVIOUS_DATE,
        value: formatNumber(
          subjectData?.indicatorVals?.[model.indexes.fact],
          undefined,
          1
        ),
        unit: subjectData?.UNIT,
      };
    });
  }, [
    data,
    indicators.colorChange,
    indicators.highLow,
    indicators.place,
    indicators.placeChange,
    model.indexes.color,
    model.indexes.fact,
  ]);

  return (
    <GeneralIndicators
      isNotGetDate={true}
      columns={3}
      generalFetching={fetching}
      title={generalIndicatorsTitle}
      typography="light"
      indicatorsFontStyle="italic"
      values={values}
      isOpenPopUp={isOpenPopUp}
      popUpId={REGION_TABLES.GENERAL_INDICATORS_EMPLOYED}
    />
  );
};
