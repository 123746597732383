import { FC, ReactNode } from "react";
import { Navigate, useParams } from "react-router";

import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { useAppSelector } from "@/store/redux/hooks";

/**
 * ReactRouter v6 больше не поддерживает регулярные выражения в Route.path. Разработчики обещают какую-нибудь
 * альтернативу в неопределенном будущем.
 * Пока будем проверять код ФО через этот компонент.
 */
export const DistrictsRoute: FC<{ children: ReactNode }> = ({ children }) => {
  const { districtId, selectedDistrictId } = useParams<
    "districtId" | "selectedDistrictId"
  >();
  const { user } = useAppSelector((state) => state.auth.user);
  const { districtIds } = useRegions();

  const id = districtId ?? selectedDistrictId;

  if (!user || !id) {
    return null;
  }

  // ФО доступен только VIP и Полпреду
  if (!user.isVIP) {
    return null;
  }

  if (!districtIds.length) {
    return null;
  }

  const match = new RegExp(`^(${districtIds.join("|")})$`).test(id);

  // VIP и Полпреда редиректим на главную
  const redirectPath = user.isVIP
    ? "/"
    : // специалиста редиректим на страницу ФО (или /faq, если субъектов нет)
    districtIds.length > 0
    ? `/districts/${districtIds[0]}`
    : "/faq";

  return <>{match ? children : <Navigate to={redirectPath} replace />}</>;
};
