import { olapRequestDimensionData } from "@/store/api/core";
import { MODEL_IDS, MODELS } from "@/store/scheme/olap";

export const getLevelIncidents = () => {
  const model = MODELS[MODEL_IDS.INCIDENT];

  return olapRequestDimensionData(
    model.dimId,
    {
      modelUuid: model.uuid,
      dimensionId: model.indexes.dmIncidentLevel,
      includeAttributesByCodes: ["name"],
      dataRequestCaching: true,
    },
    "81632269-122b-421d-a08e-482d225ca11b"
  );
};
