import { useEffect } from "react";

import { useProjectPage } from "@/pages/ProjectsPage/hooks/useProjectPage";
import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { setControlRegion } from "@/pages/RegionPage/model/slices/regionControlSlice";
import { useAppDispatch } from "@/store/redux/hooks";

import { AppBarFilter } from "../AppBarFilter/AppBarFilter";

export const AppBarProjectRegionsFilter = () => {
  const { info } = useProjectPage();
  const { arrRegions, indexCurrentRegion } = useRegions();
  const dispatch = useAppDispatch();

  const handleClick = ({ id }: { id: string }) => {
    if (id) dispatch(setControlRegion(id));
  };

  useEffect(() => {
    if (info?.region?.id) {
      dispatch(setControlRegion(info.region.id));
    }
  }, [info?.region?.id]);

  return (
    <AppBarFilter
      defaultValue="Не выбрано"
      arrayData={arrRegions}
      indexCurrentValue={indexCurrentRegion}
      onChange={handleClick}
    />
  );
};
