import { styled } from "@mui/material";
import { Link, LinkProps } from "react-router-dom";

export const LinkStyled = styled(Link)<LinkProps>(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecoration: "none",

  "&:hover": {
    textDecoration: "underline",
  },
}));
