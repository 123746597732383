import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { FormControl, SelectChangeEvent } from "@mui/material";
import { useTheme } from "@mui/material";
import { FC, useMemo } from "react";

import { MenuItemStyled, SelectStyled } from "./InputSelect.styled";
import { SELECT_VALUE } from "./lib/types";

export const InputSelect: FC<{
  value: SELECT_VALUE;
  isLineEntire?: boolean;
  handleChange: (event: SelectChangeEvent<any>) => void;
}> = ({ value, handleChange, isLineEntire = false }) => {
  const theme = useTheme();
  const menuStyle = useMemo(
    () => ({
      "& .MuiList-root": {
        background:
          theme.palette.mode === "dark"
            ? theme.palette.input.background
            : theme.palette.background.darkTheme,
        border: `1px solid ${theme.palette.input.border}`,
        borderRadius: theme.spacing(),
        padding: theme.spacing(),
      },
    }),
    [theme]
  );

  return (
    <FormControl sx={{ padding: "1rem" }}>
      <SelectStyled
        value={value}
        onChange={handleChange}
        IconComponent={KeyboardArrowDownIcon}
        MenuProps={{ sx: menuStyle }}
        isLineEntire={value === SELECT_VALUE.ENTIRE_PERIOD && isLineEntire}
      >
        <MenuItemStyled value={SELECT_VALUE.CURRENT_PERIOD}>
          Текущий период
        </MenuItemStyled>
        <MenuItemStyled value={SELECT_VALUE.ENTIRE_PERIOD}>
          Весь срок реализации
        </MenuItemStyled>
      </SelectStyled>
    </FormControl>
  );
};
