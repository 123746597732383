import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ACHIEVEMENT_VALUE, IAchievementState } from "../lib/types";

export const initialState: IAchievementState = {
  value: ACHIEVEMENT_VALUE.ACHIEVEMENT,
  enabled: true,
};

const achievementControlSlice = createSlice({
  name: "controls/achievement",
  initialState,
  reducers: {
    achievementControlToggle: (
      state,
      action: PayloadAction<ACHIEVEMENT_VALUE>
    ) => {
      state.value = action.payload;
      if (action.payload === ACHIEVEMENT_VALUE.ACHIEVEMENT) {
        state.enabled = true;
      } else {
        state.enabled = false;
      }
    },
  },
});

export const { achievementControlToggle } = achievementControlSlice.actions;
export const reducer = achievementControlSlice.reducer;

export default achievementControlSlice;
