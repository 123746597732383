import { Divider, styled, Theme } from "@mui/material";

import {
  DotMark,
  DotMarkChartPrimary,
  DotMarkChartSecondary,
  DotMarkFail,
  DotMarkSuccess,
} from "../../Dots/Dots.styled";

export const getFooterHeight = (theme: Theme) => theme.spacing(5.5);

export const FooterStyled = styled("footer")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flex: "0 0 auto",
  height: getFooterHeight(theme),
  backgroundColor: theme.palette.footer.background,
}));

export const Item = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flex: "0 0 auto",
  "& img, & svg": {
    marginRight: theme.spacing(),
  },
}));

export const ColorMark = styled("div")(({ theme }) => ({
  width: theme.spacing(3),
  height: theme.spacing(3),
  marginRight: theme.spacing(),
  borderRadius: 2,
  ...theme.mixins.border(),
}));

export const ColorMarkLow = styled(ColorMark)(({ theme }) => ({
  backgroundColor: theme.palette.error.light,
}));

export const ColorMarkHigh = styled(ColorMark)(({ theme }) => ({
  backgroundColor: theme.palette.success.light,
}));

export const FooterDotMarkSuccess = styled(DotMarkSuccess)(({ theme }) => ({
  marginRight: theme.spacing(),
  backgroundColor: theme.palette.success.main,
}));

export const FooterDotMarkFail = styled(DotMarkFail)(({ theme }) => ({
  marginRight: theme.spacing(),
  backgroundColor: theme.palette.error.main,
}));

export const FooterDotMarkPlan = styled(DotMark)(({ theme }) => ({
  marginRight: theme.spacing(),
  backgroundColor: theme.palette.footer.backgroundPlanDote,
}));

export const FooterDotMarkFact = styled(DotMark, {
  shouldForwardProp: (prop) => !["colorDote"].includes(prop.toString()),
})<{ colorDote?: boolean }>(({ theme, colorDote = false }) => ({
  marginRight: theme.spacing(),
  backgroundColor: colorDote
    ? theme.palette.text.primary
    : theme.palette.graphs.notCome,
}));

export const DividerFooter = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.footer.divider,
}));

export const FooterDotMarkFO = styled(DotMarkChartPrimary)(({ theme }) => ({
  marginRight: theme.spacing(),
  backgroundColor: theme.palette.chart.fill.primary,
}));

export const FooterDotMarkRF = styled(DotMarkChartSecondary)(({ theme }) => ({
  marginRight: theme.spacing(),
  backgroundColor: theme.palette.chart.fill.secondary,
}));
