import { STEPS } from "@/shared/GridCard/lib/types";
import { TAB_ID } from "@/store/scheme/region";

export const setFontSize = (tabId: TAB_ID, step: STEPS): number => {
  switch (true) {
    case [STEPS.MAIN, STEPS.MAIN_FULL].includes(step) &&
      tabId === TAB_ID.VDL_PROJECTS:
      return 72;
    case [STEPS.MAIN, STEPS.MAIN_FULL].includes(step) &&
      tabId !== TAB_ID.VDL_PROJECTS:
      return 48;
    case ![STEPS.MAIN, STEPS.MAIN_FULL].includes(step) &&
      tabId === TAB_ID.VDL_PROJECTS:
      return 52;
    default:
      return 20;
  }
};
