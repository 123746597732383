import { useCallback, useEffect, useMemo } from "react";
import { useLocation } from "react-router";
import { singletonHook } from "react-singleton-hook";

import { useAppDispatch, useAppSelector } from "@/store/redux/hooks";

import { extraActionsCheckpointsAndResult } from "../model/slice/checkpointsAndResultsSlice";
import { extraActionsFinances } from "../model/slice/financesSlice";
import { extraActionsInfoProjects } from "../model/slice/infoSlice";
import { extraActionsRiskProject } from "../model/slice/riskProjectSlice";

export const initialState = {
  info: undefined,
  allResults: undefined,
  infoFetching: false,
  results: undefined,
  resultsSum: undefined,
  checkpoints: undefined,
  allCheckpoints: undefined,
  checkpointsAndResultsFetching: false,
  finances: undefined,
  financesFetching: false,
  fetching: false,
  projectResultsFetching: false,
  requestProjectInfo: () => {},
  requestProjectCheckpointsAndResults: () => {},
  requestProjectFinances: () => {},
  requestGetCheckpoints: () => {},
  requestGetResults: () => {},
};

const useProjectPageImpl = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth.user);
  const { data: info, fetching: infoFetching } = useAppSelector(
    (state) => state.pages.info
  );
  const { data: finances, fetching: financesFetching } = useAppSelector(
    (state) => state.pages.finances
  );
  const {
    checkpoints,
    allCheckpoints,
    results,
    resultsSum,
    fetching: checkpointsAndResultsFetching,
    allResults,
    fetchingAllCheckpoints,
    fetchingAllResults,
  } = useAppSelector((state) => state.pages.checkpointsAndResults);

  const id = useMemo(() => {
    const m = /\/projects\/(\d+)/.exec(location.pathname);
    return m ? m[1] : undefined;
  }, [location.pathname]);

  const requestProjectInfo = useCallback(
    (projectId: string) => {
      dispatch(
        extraActionsInfoProjects.getProjectInfo({
          projectId,
          dataSummaryId: "0",
        })
      );
    },
    [dispatch]
  );

  const requestProjectCheckpointsAndResults = useCallback(
    (projectId: string) => {
      dispatch(extraActionsCheckpointsAndResult.get(projectId));
    },
    [dispatch]
  );

  const requestGetCheckpoints = useCallback(
    (projectId: string) => {
      dispatch(extraActionsCheckpointsAndResult.getCheckpoints(projectId));
    },
    [dispatch]
  );

  const requestGetResults = useCallback(
    (projectId: string) => {
      dispatch(extraActionsCheckpointsAndResult.getResults(projectId));
    },
    [dispatch]
  );

  const requestProjectFinances = useCallback(
    (resultId: string) => {
      dispatch(extraActionsFinances.getProjectFinances(resultId));
    },
    [dispatch]
  );

  const requestRiskProjects = useCallback(
    (projectId: string, dataSummaryId: string) => {
      dispatch(
        extraActionsRiskProject.getRiskProject({ projectId, dataSummaryId })
      );
    },
    [dispatch]
  );

  const requestRiskReference = useCallback(() => {
    dispatch(extraActionsRiskProject.getRiskReference());
  }, [dispatch]);

  useEffect(() => {
    requestRiskReference();
  }, []);

  useEffect(() => {
    if (user && id) {
      requestProjectInfo(id);
      requestProjectFinances(id);
      requestGetCheckpoints(id);
      requestGetResults(id);
      requestRiskProjects(id, "0");
    }
  }, [
    id,
    user,
    requestProjectInfo,
    requestProjectFinances,
    requestGetCheckpoints,
    requestGetResults,
    requestRiskProjects,
  ]);

  return {
    info,
    infoFetching,
    results,
    resultsSum,
    checkpoints,
    allCheckpoints,
    checkpointsAndResultsFetching,
    finances,
    financesFetching,
    fetching: infoFetching || financesFetching || checkpointsAndResultsFetching,
    requestProjectInfo,
    requestProjectFinances,
    requestProjectCheckpointsAndResults,
    requestGetCheckpoints,
    requestGetResults,
    allResults,
    projectResultsFetching: fetchingAllResults && fetchingAllCheckpoints,
  };
};

export const useProjectPage = singletonHook(initialState, useProjectPageImpl);
