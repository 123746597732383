import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import React from "react";

import { DefaultButton } from "@/shared/DefaultButton/DefaultButton";

import { useFeedback } from "../../hooks/useFeedback";
import { FeedbackCommonDetail } from "./FeedbackCommonDetail";

export const FeedbackCommonInfo = () => {
  const { isActualUserGrade } = useFeedback();
  const { handleFeedbackChange } = useFeedback();

  const handleFeedback = () => {
    handleFeedbackChange(true, isActualUserGrade);
  };

  return !isActualUserGrade ? (
    <DefaultButton
      selected
      size="small"
      sx={{ width: "100%" }}
      titleText="Оценить"
      icon={<ThumbUpIcon />}
      onClick={handleFeedback}
    />
  ) : (
    <FeedbackCommonDetail callbackFeedback={handleFeedback} />
  );
};
