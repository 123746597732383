export const getCovertingFullname = (fullname: string) => {
  return fullname
    .split(" ")
    .map((word: string, item: number) =>
      item === 0
        ? word[0].toUpperCase() + word.substring(1)
        : word[0]
        ? word[0].toUpperCase() + "."
        : ""
    )
    .join(" ");
};
