import { createTheme } from "@mui/material";
import * as React from "react";

import { ColorKeysMap, ColorKeysRisk } from "@/pages/MainPage/lib/types";
import { VALUE_COLOR } from "@/shared/Table/model/api/scheme";

declare module "@mui/material/styles/createPalette" {
  interface TypeBackground {
    light: string;
    dark: string;
    darkTheme: string;
    rowHover: string;
  }

  interface PaletteColor {
    contrast?: string;
    lightOpacity?: string;
  }

  interface SimplePaletteColorOptions {
    contrast?: string;
    lightOpacity?: string;
  }

  export interface Palette {
    button: {
      primary?: string;
      primaryHover?: string;
      disable?: string;
      border?: string;
      achievementText?: string;
      icon?: string;
      selected?: string;
      background?: string;
      selectedHover?: string;
      download?: string;
      downloadBorder?: string;
      dark?: string;
      collapseIcon?: string;
      popup?: string;
      notSelected?: string;
      divider?: string;
      dividerDisable?: string;
      dividerBackground?: string;
      iconBackground?: string;
      iconColor?: string;
      notifyColor?: string;
      notifyTitleColor?: string;
      incidentColor?: string;
      incidentButtonColor?: string;
      incidentButtonBackground?: string;
      incidentSelectColor?: string;
    };
    modal: {
      background?: string;
      backgroundTitle?: string;
      text?: string;
      closeButton?: string;
    };
    menu: {
      border?: string;
      itemBackground?: string;
      itemBackgroundHover?: string;
    };
    border: {
      default?: string;
      selected?: string;
      input?: string;
    };
    table: {
      dividerLight: string;
      divider: string;
      pinned: string;
      rowBorder: string;
      backgroundLight: string;
      backgroundButton?: string;
      borderButton?: string;
      popUpBackground?: string;
      lightDark?: string;
      backgroundFocused?: string;
      header?: string;
      backgroundImg: string;
      highRiskColor: string;
      middleRiskColor: string;
      lowRiskColor: string;
      riskCountColor: string;
    };
    chart: {
      mainText: string;
      background: string;
      fill: {
        primary: string;
        secondary: string;
      };
      grey: string;
    };
    geo: {
      select?: {
        border?: string;
      };
      risks?: ColorKeysRisk;
      inRange?: ColorKeysMap;
    };
    dropdown: {
      background: string;
    };
    dateBadge: {
      color: string;
      background: string;
      backgroundDark?: string;
    };
    appBar: {
      color: string;
      backgroundColor: string;
      borderColor: string;
      logoColor: string;
      achievementBackground?: string;
      substrateColor?: string;
    };
    indicatorCard: {
      backgroundColor: string;
      borderColor: string;
      backgroundColorSelected: string;
      borderColorSelected: string;
      tooltip?: string;
      tooltipBg?: string;
      vdlBackground?: string;
    };
    tabs: {
      background: string;
      textColor: string;
      textColorSelect: string;
      backgroundGradient: string;
      backgroundContent: string;
      textColorDontSelect: string;
      divider: string;
      selected?: string;
      selectedDark?: string;
      content?: string;
      shadowColor?: string;
      dateTab?: string;
      selectedGreenValue?: string;
      selectedRedValue?: string;
      highlightTab?: string;
    };
    scrollbar: {
      track: string;
      thumb: string;
    };
    footer: {
      divider: string;
      background: string;
      backgroundPlanDote: string;
    };
    toolTip: {
      background: string;
      text: string;
    };
    input: {
      background: string;
      border: string;
      selected: string;
      hover: string;
    };
    popup: {
      header: string;
      popupBackground?: string;
    };
    icon: {
      color: string;
      colorSelected: string;
      iconProject: string;
      iconFoMap?: string;
    };
    mainMenu: {
      backgroundColor: string;
      selectedItem: string;
      hoverItem: string;
      hoverItemFilter: string;
    };
    graphs: {
      graphsStart: string;
      graphsEnd: string;
      error: string;
      success: string;
      notCome: string;
      graphContainer: string;
      graphLine: string;
      graphHoverLine: string;
      planLabel: string;
      factLabel: string;
      selectedPlanLabel: string;
      selectedFactLabel: string;
      graphsBarDistrictStart: string;
      graphsBarRegionStart: string;
      graphsBarDistrictEnd: string;
      graphsBarRegionEnd: string;
      graphsRatingRegion: string;
      graphCloseButton: string;
      projectGraph: string;
    };
    diagram: {
      baseDiagram: string;
      blurGrey?: string;
      blurRed?: string;
      blurGreen?: string;
      enterpriseColor?: string;
    };
    dropdownWidget: {
      baseDropdown: string;
      baseHeader: string;
      baseBodyCell: string;
    };
    sceleton: {
      baseColor: string;
    };
  }

  interface PaletteOptions {
    button?: {
      primary?: string;
      primaryHover?: string;
      disable?: string;
      border?: string;
      achievementText?: string;
      icon?: string;
      selected?: string;
      background?: string;
      selectedHover?: string;
      download?: string;
      downloadBorder?: string;
      dark?: string;
      collapseIcon?: string;
      popup?: string;
      notSelected?: string;
      divider?: string;
      dividerDisable?: string;
      dividerBackground?: string;
      iconBackground?: string;
      iconColor?: string;
      notifyColor?: string;
      notifyTitleColor?: string;
      incidentColor?: string;
      incidentButtonColor?: string;
      incidentButtonBackground?: string;
      incidentSelectColor?: string;
    };
    modal?: {
      background?: string;
      backgroundTitle?: string;
      text?: string;
      closeButton?: string;
    };
    menu?: {
      border?: string;
      itemBackground?: string;
      itemBackgroundHover?: string;
    };
    border?: {
      default?: string;
      selected?: string;
      input?: string;
    };
    table?: {
      divider?: string;
      dividerLight?: string;
      pinned?: string;
      rowBorder?: string;
      backgroundLight?: string;
      backgroundButton?: string;
      borderButton?: string;
      popUpBackground?: string;
      lightDark?: string;
      downloadBorder?: string;
      backgroundFocused?: string;
      header?: string;
      backgroundImg: string;
      highRiskColor: string;
      middleRiskColor: string;
      lowRiskColor: string;
      riskCountColor: string;
    };
    chart?: {
      mainText?: string;
      background?: string;
      fill?: {
        primary?: string;
        secondary?: string;
      };
      grey?: string;
    };
    geo?: {
      select?: {
        border?: string;
      };
      risks?: ColorKeysRisk;
      inRange?: ColorKeysMap;
    };
    dropdown?: {
      background?: string;
    };
    dateBadge?: {
      color?: string;
      background?: string;
      backgroundDark?: string;
    };
    appBar?: {
      color?: string;
      backgroundColor?: string;
      borderColor?: string;
      logoColor?: string;
      achievementBackground?: string;
      substrateColor?: string;
    };
    indicatorCard?: {
      backgroundColor?: string;
      backgroundColorSelected?: string;
      tooltip?: string;
      tooltipBg?: string;
      vdlBackground?: string;
    };
    tabs: {
      background: string;
      textColor: string;
      textColorSelect: string;
      backgroundGradient: string;
      backgroundContent: string;
      textColorDontSelect: string;
      divider: string;
      selected?: string;
      selectedDark?: string;
      content?: string;
      shadowColor?: string;
      dateTab?: string;
      selectedGreenValue?: string;
      selectedRedValue?: string;
      highlightTab?: string;
    };
    scrollbar?: {
      track: string;
      thumb: string;
    };
    footer?: {
      divider?: string;
      background?: string;
      backgroundPlanDote?: string;
    };
    toolTip?: {
      background?: string;
      text?: string;
    };
    input?: {
      background?: string;
      border?: string;
      selected?: string;
      hover?: string;
    };
    popup?: {
      header?: string;
      popupBackground?: string;
    };
    icon?: {
      color?: string;
      colorSelected?: string;
      iconProject?: string;
      iconFoMap?: string;
    };
    mainMenu?: {
      backgroundColor: string;
      selectedItem: string;
      hoverItem: string;
      hoverItemFilter: string;
    };
    graphs?: {
      graphsStart: string;
      graphsEnd: string;
      error: string;
      success: string;
      notCome: string;
      graphContainer: string;
      graphLine: string;
      graphHoverLine: string;
      planLabel: string;
      factLabel: string;
      selectedPlanLabel: string;
      selectedFactLabel: string;
      graphsBarDistrictStart: string;
      graphsBarRegionStart: string;
      graphsBarDistrictEnd: string;
      graphsBarRegionEnd: string;
      graphsRatingRegion: string;
      graphCloseButton: string;
      projectGraph: string;
    };
    diagram?: {
      baseDiagram: string;
      blurGrey?: string;
      blurRed?: string;
      blurGreen?: string;
      enterpriseColor?: string;
    };
    dropdownWidget?: {
      baseDropdown: string;
      baseHeader: string;
      baseBodyCell: string;
    };
    sceleton: {
      baseColor: string;
    };
  }

  interface TypeText {
    tertiary: string;
    notificationText: string;
    onAccent: string;
    main: string;
    light: string;
    toolTip: string;
    lightGrey: string;
    grey: string;
    unitText: string;
    unitTextSelected: string;
    disabledSelected: string;
    disabledNotSelected: string;
    dataSelected: string;
    dataNotSelected: string;
  }
}

declare module "@mui/material/styles/createTypography" {
  interface Typography {
    fontWeightSemiBold: React.CSSProperties["fontWeight"];
    fontWeightExtraBold: React.CSSProperties["fontWeight"];
  }

  interface TypographyOptions {
    fontWeightSemiBold?: React.CSSProperties["fontWeight"];
    fontWeightExtraBold?: React.CSSProperties["fontWeight"];
  }
}

declare module "@mui/material/styles/createMixins" {
  interface Mixins {
    borderValue: (width?: string) => string;
    border: (width?: string) => {
      border: string;
    };
    rateColor: (value?: VALUE_COLOR, defaultColor?: string) => string;
  }
}

const theme = createTheme({
  palette: {
    modal: {
      background: "#1E2731",
      backgroundTitle: "#2E3946",
      closeButton: "#E4E6EB",
    },
    primary: {
      light: "#a5d9ff",
      main: "#37a6f5",
      dark: "#023177",
      contrastText: "#fff",
      lightOpacity: "rgba(255, 255, 255, 0.6)",
    },
    text: {
      primary: "#363940",
      secondary: "#616773",
      tertiary: "#989da6",
      onAccent: "#fafcff",
      main: "#e4e6eb",
    },
    background: {
      default: "#ecf2f4",
      light: "#eafbff",
      dark: "#00529b",
      paper: "#fff",
    },
    button: {
      primary: "#0F58D5",
      primaryHover: "#0069d9",
      disable: "#616A73",
      achievementText: "#616773",
      selected: "#37a6f5",
      selectedHover: "#37a6f5",
      icon: "#989da6",
      download: "#fff",
      downloadBorder: "#fff",
      dark: "#023177",
      collapseIcon: "rgba(225, 230, 237, 0.2)",
      notifyColor: "#FF9589",
      notifyTitleColor: "#1B202B",
    },
    popup: {
      popupBackground: "#1E2731",
    },
    menu: {
      border: "#E1E7ED",
      itemBackground: "#191E28",
      itemBackgroundHover: "#363E53",
    },
    border: {
      default: undefined,
      selected: undefined,
      input: "#353C52",
    },
    divider: "#c1c5cc",
    table: {
      divider: "#e1e6ed",
      pinned: "#f6f8fa",
      backgroundImg: "#EAFBFF1A",
      highRiskColor: "#FF5368",
      middleRiskColor: "#FFA24C",
      lowRiskColor: "#11E981",
      riskCountColor: "#E42E21",
    },
    chart: {
      mainText: "rgba(255, 255, 255, 1)",
      background: "#e1e6ed",
      fill: {
        primary: "#005aa1",
        secondary: "#a5d9ff",
      },
      grey: "rgba(0, 0, 0, 0.3)",
    },
    dropdown: {
      background: "#0049D9",
    },
    dateBadge: {
      color: "#568898",
      background: "#f3f5f8",
    },
    appBar: {
      color: "#607080",
      backgroundColor: "#fff",
      borderColor: "transparent",
      logoColor: "#023177",
      substrateColor: "#353942",
    },
    indicatorCard: {
      tooltip: "#49B4EF",
      tooltipBg: "rgba(255, 255, 255, 0.3)",
      vdlBackground: "#343C45",
    },
    tabs: {
      textColor: "rgba(255, 255, 255, 0.6)",
      textColorDontSelect: "rgba(255, 255, 255, 0.4)",
      textColorSelect: "#fff",
      background: "#283443",
      backgroundContent: "rgba(191, 218, 255, 0.14)",
      backgroundGradient:
        "linear-gradient(182.31deg, rgba(255, 255, 255, 0.2) 0.82%, rgba(255, 255, 255, 0.113111) 12.31%, rgba(255, 255, 255, 0.02) 96.76%)",
      divider: "#ffffff4d",
      highlightTab: "rgba(191, 218, 255, 0.3)",
    },
    success: {
      light: "#eafcf4",
      main: "#06CEB6",
      contrast: "#06CEB6",
      lightOpacity: "rgba(6, 206, 182, 0.8)",
    },
    error: {
      main: "#FF5368",
      lightOpacity: "rgba(255, 83, 104, 0.8)",
    },
    scrollbar: {
      track: "rgba(13, 31, 51, 0.05)",
      thumb: "rgba(13, 31, 51, 0.2)",
    },
    footer: {
      background: "#e6eff6",
      divider: "rgba(255, 255, 255, 0.1)",
    },
    toolTip: {
      background: "#fff",
      text: "#363B40",
    },
    diagram: {
      baseDiagram: "rgba(255, 255, 255, 0.3)",
      blurGrey: "#ffffff70",
      blurRed: "#ff5368cf",
      blurGreen: "#06ceb6cf",
      enterpriseColor: "rgba(73, 180, 239, 0.5)",
    },
    sceleton: {
      baseColor: "#2b3641",
    },
  },
  typography: {
    fontFamily:
      'GolosText, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
    fontSize: 18,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,
    fontWeightExtraBold: 800,
    htmlFontSize: 18,
    h3: {
      fontSize: 22,
      lineHeight: 1.25,
      fontWeight: 700,
    },
    h4: {
      fontSize: "2rem",
      lineHeight: 1.25,
      fontWeight: 700,
    },
    h5: {
      fontSize: 20,
      fontWeight: 700,
    },
    h6: {
      fontSize: "1rem",
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: 20,
      fontWeight: 500,
      lineHeight: 1.2,
    },
    subtitle2: {
      color: "#989DA6",
      fontSize: 14,
      lineHeight: 1.2,
    },
    body2: {
      fontSize: 18,
      color: "#989DA6",
    },
    body1: {
      lineHeight: 1.2,
    },
  },
  mixins: {
    borderValue: (width?: string) =>
      `${width ?? "1px"} solid ${theme.palette.divider}`,
    border: (width?: string) => ({
      border: theme.mixins.borderValue(width),
    }),
    rateColor: (value, defaultColor = theme.palette.text.primary) => {
      switch (value) {
        case VALUE_COLOR.GREEN:
          return theme.palette.success.main;
        case VALUE_COLOR.RED:
          return theme.palette.error.main;
        default:
          return defaultColor;
      }
    },
  },
  shape: {
    borderRadius: 8,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1580,
      xl: 1700,
    },
  },
});

export default theme;
