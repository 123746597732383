import { Card, styled } from "@mui/material";
import Avatar from "@mui/material/Avatar";

export const LeaderStyled = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  fontSize: theme.spacing(2),
  padding: theme.spacing(3),
  height: "100%",
  boxSizing: "border-box",
}));

export const AvatarStyled = styled(Avatar, {
  shouldForwardProp: (prop) => !["isOpenPopUp"].includes(prop.toString()),
})<{ isOpenPopUp?: boolean }>(({ theme, isOpenPopUp }) => ({
  width: theme.spacing(isOpenPopUp ? 8.125 : 6.5),
  height: theme.spacing(isOpenPopUp ? 8.125 : 6.5),
}));

export const AvatarWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
}));

export const LeaderInfo = styled("div")(() => ({
  display: "flex",
  flexFlow: "column nowrap",
}));

export const NameWrapper = styled("span")<{ isOpenPopUp?: boolean }>(
  ({ theme, isOpenPopUp }) => ({
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.spacing(isOpenPopUp ? 3 : 2),
    color:
      theme.palette.mode === "dark"
        ? theme.palette.indicatorCard.tooltip
        : theme.palette.text.primary,
  })
);

export const AgeWrapper = styled("span")(({ theme }) => ({
  color: theme.palette.text.tertiary,
  fontWeight: theme.typography.fontWeightMedium,
}));
