import { createTheme } from "@mui/material";

import common from "./common";

const theme = createTheme({
  ...common,
  palette: {
    ...common.palette,
    primary: {
      ...common.palette.primary,
      dark: "#020305",
    },
    background: {
      ...common.palette.background,
      default: "#EDF1F5",
      paper: "#fff",
      darkTheme: "#EAEBED",
      light: "#e1e6ed33",
      rowHover: "rgba(2, 109, 222, 0.06)",
    },
    border: {
      ...common.palette.border,
      default: "rgba(0, 0, 0, 0.1)",
      selected: "#49B4EF",
    },
    text: {
      ...common.palette.text,
      primary: "#363B40",
      secondary: "#616A73",
      light: "#363B40",
      tertiary: "#616A73",
      onAccent: "#fff",
      lightGrey: "rgba(255, 255, 255, 0.5)",
      grey: "#C2C7CC",
      unitTextSelected: "rgba(255, 255, 255, 0.7)",
      unitText: "#8391A0",
      disabledSelected: "#8391A0",
      disabledNotSelected: "#C2C7CC",
      dataSelected: "rgba(255, 255, 255, 0.5)",
      dataNotSelected: "#8391A0",
      main: "#363B40",
    },
    dateBadge: {
      background: "#E2E9F3",
      color: "rgba(54, 59, 64, 0.6)",
    },
    divider: "rgba(225, 230, 237, 0.1)",
    table: {
      ...common.palette.table,
      dividerLight: "#E1E7ED",
      divider: "#e1e6ed1a",
      rowBorder: "#0792BE",
      backgroundLight: "#bfdaff08",
      backgroundButton: "#fff",
      borderButton: "#C1C6CC",
      popUpBackground: "rgba(191, 218, 255, 0.1)",
      lightDark: "#33404c",
      backgroundFocused: "rgba(255, 255, 255, 0.1)",
      header: "#fff",
      backgroundImg: "#E0EBFE",
    },
    tabs: {
      ...common.palette.tabs,
      selected: "#fff",
      selectedDark: "#fff",
      content: "#F5F7FA",
      textColor: "#8391A0",
      textColorSelect: "#363B40",
      backgroundContent: "rgba(255, 255, 255, 0.6)",
      shadowColor: "0px 8px 24px rgba(1, 59, 121, 0.1)",
      dateTab: "#8391A0FF",
      selectedGreenValue: "#5CFCD6",
      selectedRedValue: "#FF9CBF",
    },
    chart: {
      background: "rgba(255, 255, 255, 0.3)",
      fill: {
        primary: "#5B93F5",
        secondary: "#0254AC",
      },
      grey: "rgba(255, 255, 255, 0.3)",
    },
    appBar: {
      ...common.palette.appBar,
      color: "#616A73",
      backgroundColor: "#FFFFFF",
      borderColor: "#20282f",
      logoColor: "#49b4ef",
      achievementBackground: "#E1E7ED",
      substrateColor: "#FFFFFF",
    },
    indicatorCard: {
      ...common.palette.indicatorCard,
      backgroundColor: "#FFFFFF",
      backgroundColorSelected: "#396CC3",
    },
    error: {
      light: "#fff2f5",
      main: "#E42E21",
      contrast: "#FF5368",
    },
    scrollbar: {
      track: "#EAEBED",
      thumb: "#616A734D",
    },
    button: {
      ...common.palette.button,
      disable: "#616A73",
      border: "#E1E7ED",
      achievementText: "#8391A0",
      icon: "rgba(255, 255, 255, 0.5)",
      selected: "#0F58D5",
      notSelected: "#616A73",
      background: "#EAEBED",
      selectedHover: "#0069d9",
      download: "rgba(43, 48, 63, 0.5)",
      downloadBorder: "rgba(255, 255, 255, 0.1)",
      dark: "#37a6f5",
      popup: "rgba(255, 255, 255, 0.2)",
      divider: "#C2C7CC",
      dividerDisable: "#E1E7ED",
      dividerBackground: "#F4F7FC",
      iconBackground: "#EAEBED",
      iconColor: "#C4C4C4",
      collapseIcon: "#EAEBED",
      incidentColor: "#535d67",
      incidentButtonColor: "#464B5A",
      incidentButtonBackground: "#2B333A",
      incidentSelectColor: "#2D3241",
    },
    toolTip: {
      background: "#363B40",
      text: "#fff",
    },
    mode: "light",
    menu: {
      ...common.palette.menu,
      border: "#777a7e",
      itemBackground: "rgba(255, 255, 255, 1)",
    },
    footer: {
      ...common.palette.footer,
      background: "rgba(255, 255, 255, 0.5)",
      backgroundPlanDote: "#5C96FB",
    },
    input: {
      background: "#242B37",
      border: "rgba(255, 255, 255, 0.1)",
      selected: "#0F58D5",
      hover: "rgba(55, 166, 245, 0.12)",
    },
    popup: {
      header: "#EDF1F5",
      popupBackground: "#1E2731",
    },
    modal: {
      text: "#363B40",
      background: "#ffffff",
    },
    icon: {
      color: "#363B40",
      colorSelected: "#FFFFFF",
      iconProject: "#577CBC",
    },
    geo: {
      select: {
        border: "#ffffff",
      },
      risks: {
        red10: "#B03C5A",
        red20: "#BC4A61",
        red30: "#C55467",
        red40: "#CE5E6C",
        red50: "#D76871",
        red60: "#E17377",
        red70: "#EA7D7C",
        red80: "#F38781",
        red90: "#FF9489",
        green90: "#32B2A3",
        green95: "#3EBDAB",
        green98: "#48C5B2",
        green100: "#50CDB7",
        green103: "#59D4BD",
        green105: "#62DCC3",
        green110: "#6FE7CB",
      },
      inRange: {
        red10start: "#B03C5A",
        red20start: "#BC4A61",
        red30start: "#C55467",
        red40start: "#CE5E6C",
        red50start: "#D76871",
        red60start: "#E17377",
        red70start: "#EA7D7C",
        red80start: "#F38781",
        red90start: "#FF9489",
        green90start: "#32B3A4",
        green92start: "#40BFAD",
        green94start: "#4BC8B4",
        green96start: "#56D2BB",
        green98start: "#60DBC1",
        green100start: "#6EE7CB",
      },
    },
    success: {
      light: "#eafcf4",
      main: "#039D8A",
      contrast: "#06CEB6",
      lightOpacity: "rgba(6, 206, 182, 0.8)",
    },
    mainMenu: {
      backgroundColor: "#E2E9F3",
      selectedItem: "#0F58D5",
      hoverItem: "#F2F7FF",
      hoverItemFilter: "#BFDAFF33",
    },
    graphs: {
      graphsStart: "#5090FF",
      graphsEnd: "#457AD500",
      error: "#E42E21",
      success: "#039D8A",
      notCome: "#C1C6CC",
      graphContainer: "#EDF2FA",
      graphLine: "#DFE3E9",
      graphHoverLine: "#78AAFF",
      planLabel: "rgba(86, 142, 238, 0.7)",
      factLabel: "#616A73",
      selectedPlanLabel: "#568EEE",
      selectedFactLabel: "#363B40",
      graphsBarDistrictStart: "rgba(74, 121, 216, 0.6)",
      graphsBarRegionStart: "rgba(255, 255, 255, 0.8)",
      graphsBarDistrictEnd: "rgba(74, 121, 216, 0.15)",
      graphsBarRegionEnd: "rgba(255, 255, 255, 0.15)",
      graphsRatingRegion: "#427BE7",
      graphCloseButton: "#616A73",
      projectGraph: "#72809E",
    },
    diagram: {
      baseDiagram: "#DFE3E9",
      enterpriseColor: "rgba(73, 180, 239, 0.5)",
    },
    dropdownWidget: {
      baseDropdown: "#026DDE",
      baseHeader: "#EDF1F5",
      baseBodyCell: "rgba(191, 218, 255, 0.14)",
    },
    sceleton: {
      ...common.palette.sceleton,
    },
  },
});

export default theme;
