import React, { FC } from "react";

import { useTabs } from "@/app/hooks/useTabs";

import { REGION_CONTAINERS } from "../lib/helpers";

export const RegionMutableInfo: FC = () => {
  const { activeTab } = useTabs();
  const container = REGION_CONTAINERS[activeTab];
  return container ? React.createElement(container) : null;
};
