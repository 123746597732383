import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { AccordionSummary, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";

import { useContentExpand } from "@/pages/Faq/hooks/useContentExpand";
import { ListItemIconStyled } from "@/pages/NormativeLegalActs/ui/ActsContent/ui/ActsContent.styled";
import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import {
  BasicPage,
  BasicSection,
} from "@/pages/RegionPage/ui/RegionPage.styled";
import { Icon } from "@/shared/CustomIcon/Icon.styled";
import { DownloadLink } from "@/shared/DownloadLink/DownloadLink";
import { ExpandedIcon } from "@/shared/ExpandedIcon/ExpandedIcon";
import { FoCoatsOfArms } from "@/shared/FoCoatsOfArms/ui/FoCoatsOfArms";
import { REGION_COATS } from "@/shared/lib/constants";
import { IRegionCoatKey, IRegionReportsKey } from "@/shared/lib/types";
import { TopBar } from "@/shared/TopBar/ui/TopBar";

import { getFilesByReportDate, getFilesSizes } from "../lib/helpers";
import { FilesResultType, RegionsType } from "../lib/types";
import {
  AccordionDetail,
  AccordionDetailsWrapper,
  AccordionTitle,
  AccordionWrapper,
  BoxWrapper,
  ContentInfo,
  ContentWrapper,
  FileSize,
} from "./Reports.styled";

export const Reports = () => {
  const { districts, country } = useRegions();
  const { expanded, handleExpanded } = useContentExpand();
  const [fileSizes, setFileSizes] = useState<FilesResultType>();

  const regions: RegionsType[] = useMemo(
    () =>
      districts
        .map(({ id, attributeVals: { name, SHORT_NAME } }) => ({
          icon: REGION_COATS[id as IRegionCoatKey],
          files: getFilesByReportDate(id as IRegionReportsKey),
          name,
          id: id as IRegionReportsKey,
          shortName: SHORT_NAME,
          type: "PDF",
        }))
        .sort((sortA, sortB) => +sortA.id - +sortB.id),
    [districts]
  );

  const isCountry: any = useMemo(
    () => ({
      icon: REGION_COATS["8" as IRegionReportsKey],
      files: getFilesByReportDate("8" as IRegionReportsKey, ["01012023"]),
      name: country?.attributeVals.name,
      id: "8" as IRegionReportsKey,
      shortName: "РФ",
      type: "PDF",
    }),
    [country]
  );

  const resultFileSizes = useCallback(async () => {
    const result = await getFilesSizes([isCountry, ...regions]);
    setFileSizes(result);
  }, [regions]);

  useEffect(() => {
    resultFileSizes();
  }, []);

  return (
    <BasicPage>
      <BasicSection stretch>
        <TopBar title="Отчеты" back={-1} />
        <BoxWrapper>
          <AccordionWrapper
            disableGutters
            onChange={() => handleExpanded(isCountry.id)}
          >
            <AccordionSummary
              expandIcon={
                <ExpandedIcon isExpanded={expanded.includes(isCountry.id)} />
              }
            >
              <AccordionTitle>
                <FoCoatsOfArms idFo={isCountry.id} />
                <Typography>{isCountry.name}</Typography>
              </AccordionTitle>
            </AccordionSummary>

            <AccordionDetailsWrapper>
              {isCountry.files.map((file: any) => (
                <AccordionDetail key={file.id}>
                  <ContentWrapper>
                    <ListItemIconStyled isBackground>
                      <Icon>
                        <TextSnippetIcon />
                      </Icon>
                      {isCountry.type}
                    </ListItemIconStyled>
                    <ContentInfo>
                      <Typography>{`${isCountry.shortName} в цифрах ${file.date}`}</Typography>
                      {fileSizes?.[isCountry.id] && (
                        <FileSize>{`${
                          fileSizes?.[isCountry.id]?.[file.id]
                        } MB`}</FileSize>
                      )}
                    </ContentInfo>
                  </ContentWrapper>

                  <DownloadLink href={file.url} />
                </AccordionDetail>
              ))}
            </AccordionDetailsWrapper>
          </AccordionWrapper>

          {regions.map(({ id, name, files, shortName, type }) => (
            <AccordionWrapper
              disableGutters
              key={id}
              onChange={() => handleExpanded(id)}
            >
              <AccordionSummary
                expandIcon={<ExpandedIcon isExpanded={expanded.includes(id)} />}
              >
                <AccordionTitle>
                  <FoCoatsOfArms idFo={id} />
                  <Typography>{name}</Typography>
                </AccordionTitle>
              </AccordionSummary>

              <AccordionDetailsWrapper>
                {files.map((file) => (
                  <AccordionDetail key={file.id}>
                    <ContentWrapper>
                      <ListItemIconStyled isBackground>
                        <Icon>
                          <TextSnippetIcon />
                        </Icon>
                        {type}
                      </ListItemIconStyled>
                      <ContentInfo>
                        <Typography>{`${shortName} в цифрах ${file.date}`}</Typography>
                        {fileSizes?.[id] && (
                          <FileSize>{`${
                            fileSizes?.[id]?.[file.id]
                          } MB`}</FileSize>
                        )}
                      </ContentInfo>
                    </ContentWrapper>

                    <DownloadLink href={file.url} />
                  </AccordionDetail>
                ))}
              </AccordionDetailsWrapper>
            </AccordionWrapper>
          ))}
        </BoxWrapper>
      </BasicSection>
    </BasicPage>
  );
};
