import { FC, useMemo } from "react";

import { ILineChartProps } from "../lib/types";
import { Fill, Total } from "./LineChart.styled";

export const LineChart: FC<ILineChartProps> = ({
  total,
  value = 0,
  type,
  subType,
  size,
  color = "",
  bunchValues,
}) => {
  const getFill = () =>
    total === 0 ? 0 : value >= total ? 100 : (value / total) * 100;

  const values = useMemo(() => {
    if (bunchValues) {
      return bunchValues;
    }
    const fill = getFill();
    return [{ value, fill, color }];
  }, [value, bunchValues, color]);

  return (
    <Total
      type={type}
      subType={subType}
      size={size}
      sx={bunchValues && { display: "flex" }}
    >
      {[...values].map((item, index) => (
        <Fill
          key={index}
          type={type}
          subType={subType}
          background={item.color}
          sx={{ width: `${item.fill}%` }}
          className="fillChart"
        />
      ))}
    </Total>
  );
};
