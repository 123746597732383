import { FC } from "react";

const icon: FC = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 3.72667V0.666667C12 0.3 11.7 0 11.3333 0H8.27333C7.68 0 7.38 0.72 7.8 1.14L8.86 2.2L2.19333 8.86667L1.13333 7.80667C0.72 7.38667 0 7.68 0 8.27333V11.3333C0 11.7 0.3 12 0.666667 12H3.72667C4.32 12 4.62 11.28 4.2 10.86L3.14 9.8L9.80667 3.13333L10.8667 4.19333C11.28 4.61333 12 4.32 12 3.72667Z"
      fill="white"
      fillOpacity="0.5"
    />
  </svg>
);

export default icon;
