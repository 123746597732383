import { styled, ToggleButton, ToggleButtonGroup } from "@mui/material";

export const UnitMeasureTitle = styled("span")(({ theme }) => ({
  opacity: 0.7,
}));

export const ToggleButtonGroupStyled = styled(ToggleButtonGroup)(
  ({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.background.paper
        : theme.palette.background.darkTheme,
    border: `1px solid ${theme.palette.button.border}`,
    padding: theme.spacing(0.5),
  })
);

export const ToggleButtonStyled = styled(ToggleButton)(({ theme }) => ({
  fontSize: 16,
  padding: theme.spacing(0.3, 2),
  textTransform: "none",
  border: "none",
  borderRadius: `${theme.spacing()} !important`,
  color: theme.palette.text.primary,
  "&.Mui-selected": {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.button.primary,
  },
  "&.Mui-disabled": {
    color: theme.palette.text.disabledSelected,
  },
}));
