import { FC } from "react";

import icon from "@/assets/DistrictValuesManufacturing.svg";
import { DistrictValues } from "@/shared/DistrictValues/ui/DistrictValues";
import { IPopUpProps } from "@/shared/RegionPopUp/lib/types";

export const DistrictValuesManufacturing: FC<IPopUpProps> = ({
  isOpenPopUp,
}) => {
  return (
    <DistrictValues icon={icon} indicatorId="1" isOpenPopUp={isOpenPopUp} />
  );
};
