import { styled } from "@mui/material";

import {
  CardListRow,
  CardListTitleText,
} from "@/shared/CardList/CardList.styled";

import { IDistrictValuesWithParents } from "../lib/types";

export const DistrictValuesTitleText = styled(CardListTitleText)(
  ({ theme }) => ({
    fontSize: theme.spacing(2.2),
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  })
);

export const DistrictValuesHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
}));

export const DistrictValuesDate = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(0.25),
}));

export const DistrictValuesRow = styled(CardListRow, {
  shouldForwardProp: (prop) =>
    !["isSelected", "parent", "isOpenPopUp"].includes(prop.toString()),
})<IDistrictValuesWithParents>(
  ({ theme, parent, isSelected, isOpenPopUp }) => ({
    ...(isOpenPopUp && {
      backgroundColor: theme.palette.popup.popupBackground,
    }),
    overflow: "hidden",
    gap: 8,
    padding: isOpenPopUp ? theme.spacing(2.25, 3) : theme.spacing(1, 1, 1, 2),
    fontSize: parent ? 18 : 16,
    cursor: "pointer",
    border: "1px solid transparent",
    borderTop: `1px solid ${theme.palette.table.dividerLight}`,
    borderRight: `1px solid ${theme.palette.table.dividerLight}`,
    ...(isSelected
      ? {
          backgroundColor: theme.palette.background.rowHover,
          border: `1px solid ${theme.palette.table.rowBorder} !important`,
          borderRadius: theme.spacing(),
        }
      : {}),
    "&:first-of-type": {
      borderTop: "1px solid transparent",
    },
    "&:last-of-type": {
      borderBottom: `1px solid ${theme.palette.table.dividerLight}`,
    },

    "&:hover": {
      backgroundColor: theme.palette.background.rowHover,
      border: `1px solid ${theme.palette.table.rowBorder}`,
      borderRadius: theme.spacing(),
    },
  })
);

export const DistrictValuesCoat = styled("div")<IDistrictValuesWithParents>(
  ({ theme, parent }) => ({})
);

export const DistrictValuesTitle = styled("div")<{ isOpenPopUp?: boolean }>(
  ({ theme, isOpenPopUp }) => ({
    flex: "1",
    fontSize: isOpenPopUp ? 24 : 16,
    whiteSpace: "normal",
  })
);

export const DistrictValuesHL = styled("div")<{ isOpenPopUp?: boolean }>(
  ({ theme, isOpenPopUp }) => ({
    display: "flex",
    flex: "0 0",
    flexBasis: isOpenPopUp ? theme.spacing(4) : theme.spacing(3),
    marginRight: isOpenPopUp ? theme.spacing(1) : theme.spacing(0.5),

    "& svg": {
      height: "100%",
      width: "100%",
    },
  })
);

export const DistrictValuesValue = styled("div")<{ isOpenPopUp?: boolean }>(
  ({ theme, isOpenPopUp }) => ({
    flex: `0 0 ${isOpenPopUp ? 105 : 70}px`,
    fontSize: isOpenPopUp ? 28 : 16,
    whiteSpace: "nowrap",
  })
);

export const DistrictValuesWrapperContent = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  ".track": {
    borderRadius: theme.spacing(0.375),
    background: theme.palette.scrollbar.track,
    "&.vertical": {
      right: theme.spacing(0.625),
      bottom: theme.spacing(0.5),
      top: theme.spacing(0.5),
      zIndex: 100,
    },
  },
  ".thumb": {
    cursor: "pointer",
    borderRadius: "inherit",
    background: theme.palette.scrollbar.thumb,
  },
  ".scroll-view": {
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      width: 0,
    },
  },
}));
