import { useCallback } from "react";

import { useAppDispatch, useAppSelector } from "@/store/redux/hooks";

import { extraActionsSocEconomy } from "../model/socEconomySlice";

export const useSocEconomy = () => {
  const dispatch = useAppDispatch();
  const { data } = useAppSelector((state) => state.regions.socEconomy);

  const requestSocEconomy = useCallback(
    (regionId: string, dataSummaryId: string, parentId: string) => {
      dispatch(
        extraActionsSocEconomy.get({ regionId: parentId, dataSummaryId })
      );
    },
    [dispatch]
  );

  return {
    data,
    requestSocEconomy,
  };
};
