import { IDynamicsLowProps } from "@assets/svg_components/DynamicsHighFO";
import { FC } from "react";

import { COMPARE_TYPE, ICompareState } from "@/shared/CompareControl/lib/types";
import { getValueItem } from "@/shared/GeneralIndicators/lib/helpers";
import { formatNumber } from "@/shared/lib/heplers";
import { VALUE_COLOR } from "@/shared/Table/model/api/scheme";

export const getUnitMeasure = (key: string) => {
  switch (key) {
    case "9":
    case "97":
      return "п.п";
    default:
      return "%";
  }
};

export const getIconsByValueHL = (
  value: number,
  icons: {
    high: FC<IDynamicsLowProps>;
    low: FC<IDynamicsLowProps>;
  }
) => {
  switch (value) {
    case 1:
    case 2:
      return <icons.high contrast={value === 2} />;
    case -1:
    case -2:
      return <icons.low contrast={value === -2} />;
    default:
      return null;
  }
};

export const getIndicatorHighLow = (key: string, compare: ICompareState) => {
  switch (key) {
    default:
      return compare.value === COMPARE_TYPE.FO
        ? "factHighLowFOAppg"
        : "factHighLowRFAppg";
  }
};

export const convertShortSubject = (regionName: string) => {
  const [firstName] = regionName.split(" ");
  return firstName ? `${firstName} ФО` : "";
};

export const getConvertSurPlus = (
  key: string,
  value: number,
  valueColor: VALUE_COLOR
) => {
  const isKeySurPlus = ["99"].includes(key);
  const formatValue = formatNumber(value);
  if (isKeySurPlus) {
    return getValueItem({
      key,
      value: formatValue,
      valueColor,
    });
  }
  return formatValue;
};
