import { styled, ToggleButton } from "@mui/material";

export const ToggleButtonStyled = styled(ToggleButton, {
  shouldForwardProp: (prop) =>
    !["noBorder", "region"].includes(prop.toString()),
})<{
  noBorder?: boolean;
  region?: boolean;
}>(({ theme, noBorder = false, region = false }) => ({
  boxSizing: "border-box",
  ...(!noBorder && {
    backgroundColor:
      theme.palette.mode === "light" && region
        ? theme.palette.text.onAccent
        : theme.palette.button.background,
    border: `1px solid ${theme.palette.button.border} !important`,
  }),
  ...(noBorder && {
    "&:hover": {
      background: "none",
    },
  }),
  "&.Mui-selected": {
    padding: 7,
  },
  "&:not(.Mui-selected)": {
    padding: 7,
  },
  "& > svg": {
    fill:
      theme.palette.mode === "dark"
        ? theme.palette.button.notSelected
        : theme.palette.text.primary,
  },
}));
