import React from "react";

import enterprise from "@/assets/Enterprise.svg";
import { useRegionPopUp } from "@/shared/RegionPopUp/hooks/useRegionPopUp";
import { IPopUpProps, REGION_TABLES } from "@/shared/RegionPopUp/lib/types";
import { VEDHeader, VEDTitleText } from "@/shared/VED/ui/VED.styled";
import { WidgetScrollbar } from "@/shared/WidgetScrollbar/ui/WidgetScrollbar";

import { enterpriseTitle, enterpriseUnit } from "../lib/constants";
import { Enterprise } from "./Enterprise";
import { EnterpriseTitle } from "./EnterpriseWidget.styled";

export const EnterpriseWidget = ({ isOpenPopUp }: IPopUpProps) => {
  const { PopUp, PopUpButton, popupHeightMax }: any = useRegionPopUp(
    REGION_TABLES.ENTERPRISE,
    isOpenPopUp,
    <>
      <img src={enterprise} alt={enterprise} />
      {`${enterpriseTitle}, ${enterpriseUnit}`}
    </>
  );
  return (
    <WidgetScrollbar
      icon={<img src={enterprise} alt={enterprise} />}
      titleIncident={enterpriseTitle}
      header={
        !isOpenPopUp && (
          <VEDTitleText>
            <VEDHeader>
              {`${enterpriseTitle}, ${enterpriseUnit}`}
              <EnterpriseTitle>
                <PopUpButton />
              </EnterpriseTitle>
            </VEDHeader>
          </VEDTitleText>
        )
      }
      heightMax={popupHeightMax}
      autoHeight={isOpenPopUp}
    >
      <Enterprise isOpenPopUp={isOpenPopUp} />
      <PopUp />
    </WidgetScrollbar>
  );
};
