import { useCallback, useMemo } from "react";

import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { COMPARE_TYPE } from "@/shared/CompareControl/lib/types";
import { REGION_COUNTRY_CODE } from "@/shared/lib/constants";
import { useAppDispatch, useAppSelector } from "@/store/redux/hooks";

import { extraActionsBudget } from "../model/budgetSlice";

export const useBudget = () => {
  const regionId = useAppSelector((state) => state.controls.regions.id);
  const { data, budgetPassport, fetching } = useAppSelector(
    (state) => state.regions.budget
  );
  const { compare } = useAppSelector((state) => state.controls);
  const { getDistrictByRegion } = useRegions();
  const dispatch = useAppDispatch();

  const district = getDistrictByRegion(regionId!);

  const requestBudget = useCallback(
    (regionId: string, dataSummaryId: string, district: any) => {
      dispatch(
        extraActionsBudget.get({
          regionId,
          districtId: district?.id,
          dataSummaryId,
        })
      );
    },
    [dispatch]
  );

  const regionData = useMemo(() => {
    if (data && regionId) {
      return data[regionId];
    }
    return {};
  }, [data, regionId]);

  const regionBudgetPassport = useMemo(() => {
    if (budgetPassport && regionId) {
      return budgetPassport[regionId];
    }
    return {};
  }, [budgetPassport, regionId]);

  const regionCountryData = useMemo(() => {
    if (data && district) {
      switch (compare.value) {
        case COMPARE_TYPE.RF:
          return data[REGION_COUNTRY_CODE];
        case COMPARE_TYPE.FO:
          return data[district.id];
      }
    }
    return {};
  }, [compare.value, data, district]);

  return {
    districtAndRfData: data,
    data: regionData,
    fetching,
    regionCountryData,
    requestBudget,
    district,
    regionBudgetPassport,
  };
};
