import { useTheme } from "@mui/material";
import React, { FC } from "react";

import NPDarkImage from "@/assets/national_project_dark.png";
import NPLightImage from "@/assets/national_project_light.png";

import {
  nationalProjectsLink,
  nationalProjectTitle,
} from "../../lib/constants";
import { INPLinkProps } from "../../lib/types";
import { NPLinkStyled } from "../NationalProjectsIndicators.styled";

export const NationalProjectsLink: FC<INPLinkProps> = ({
  subject = "1",
  projectId = "1",
  projectName = "Все проекты",
}) => {
  const theme = useTheme();
  return (
    <NPLinkStyled
      href={`${nationalProjectsLink}?project[id]=${projectId}&project[name]=${projectName}&subject=${subject}`}
      target="_blank"
    >
      <img
        src={theme.palette.mode === "dark" ? NPDarkImage : NPLightImage}
        alt={nationalProjectTitle}
      />
    </NPLinkStyled>
  );
};
