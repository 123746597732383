import {
  IPreparedIndicatorAttributeVals,
  IRowDatePart,
  MODEL_IDS,
  MODELS,
} from "@/store/scheme/olap";

export type graphData = {
  fact?: number;
  plan?: number;
  percent?: number;
  unit?: string;
  color?: string;
}[];

export type DataName = "Goals" | "Indicators" | "Chekpoints";

export type TGraphContainer = {
  dataName: DataName;
};

export const { indexes: kpiI } = MODELS[MODEL_IDS.KPI_SOC_ECONOMY];

export const GOAL_GRAPHS = {
  modelId: MODEL_IDS.KPI_SOC_ECONOMY,
  dataName: "graphData",
  indicators: {
    fact: kpiI.fact,
    plan: kpiI.plan,
    percent: kpiI.factChangeAppg,
    color: kpiI.colorGraph,
  },
  graphMap: [
    { title: "Выпуск товаров и услуг", iId: "1" },
    { title: "Доходы населения", iId: "2" },
    { title: "Частные инвестиции", iId: "3" },
    { title: "Число рабочих мест", iId: "127" },
  ],
};

export const { indexes: pI } = MODELS[MODEL_IDS.PROJECT_PASSPORT];

export const INDICATORS_GRAPHS = {
  modelId: MODEL_IDS.PROJECT_PASSPORT,
  dataName: "indicatorsGraphData",
  indicators: {
    fact: pI.fact,
    plan: pI.plan,
    percent: pI.percent,
    color: pI.color,
  },
  graphMap: [
    { title: "Выпуск товаров и услуг", iId: "107" },
    { title: "Средняя з/п", iId: "106" },
    { title: "Частные инвестиции", iId: "108" },
    { title: "Новые рабочие места", iId: "109" },
  ],
};

export const CHEKPOINTS_GRAPHS = {
  modelId: MODEL_IDS.PROJECT_PASSPORT,
  dataName: "chekpointsGraphData",
  indicators: {
    fact: pI.fact,
    plan: pI.plan,
    percent: pI.percent,
    color: pI.color,
  },

  graphMap: [{ title: "Контрольные точки", iId: "129" }],
};

export type GraphAddOpt = {
  modelId: MODEL_IDS;
  dataName: string;
  indicators: {
    fact: string;
    plan: string;
    percent: string;
    color: string;
  };
  graphMap: {
    title: string;
    iId: string;
  }[];
};

export type TPeriods = {
  dateCodes: string[];
  periods: string[];
};

export type TOptions = {
  periodCodes: Record<string, TPeriods>;
  currentData: {
    [regionId: string]: {
      [modelId: string]: IRowDatePart<IPreparedIndicatorAttributeVals>;
    };
  };
  currentFetching: boolean;
  currentAction: "getGraphData" | "getIndicatorsGraphData";
  additionalOpt: GraphAddOpt;
};
