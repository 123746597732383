import { TAB_ID } from "@store/scheme/region";

import { GridCardITab } from "@/shared/GridCard/lib/types";
import { REGION_COUNTRY_CODE } from "@/shared/lib/constants";

import { TabGridProps } from "../lib/types";
import { checkTabsForBacklog } from "../lib/utils";
import { BacklogCard, IndicatorCard } from "./components";
import { IndicatorsGrid } from "./DynamicsTabCard.styled";

// Формирование общей обертки для табов при включенном свитчере Динамика
export const DynamicsTabCard = ({
  tabs,
  selectedSubjectId,
  selectedTabId,
  setSelectedTabId,
  tabFetching = false,
}: TabGridProps): JSX.Element => {
  const renderIndicatorCard = (tab: GridCardITab) => {
    const isSelected = tab.id === selectedTabId;
    const hideChart = selectedSubjectId === REGION_COUNTRY_CODE;

    if (tab.id === TAB_ID.RISKS) {
      const isCheckTab = checkTabsForBacklog(selectedTabId);
      return (
        <BacklogCard
          key={tab?.id}
          tab={tab}
          selected={isSelected || isCheckTab}
          setSelectedTabId={setSelectedTabId}
          tabFetching={tabFetching}
        />
      );
    }

    return (
      <IndicatorCard
        key={tab?.id}
        tab={tab}
        selected={isSelected}
        hideChart={hideChart}
        tabFetching={tabFetching}
        setSelectedTabId={setSelectedTabId}
      />
    );
  };

  return (
    <IndicatorsGrid gridColumns="5">
      {tabs.map((tab) =>
        tab.id !== TAB_ID.PROJECTS ? renderIndicatorCard(tab) : null
      )}
    </IndicatorsGrid>
  );
};
