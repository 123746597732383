import { useTheme } from "@mui/material";
import moment from "moment";
import { useMemo } from "react";
import { useLocation } from "react-router";

import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { REGION_COUNTRY_CODE } from "@/shared/lib/constants";
import { useTransitionPage } from "@/shared/Table/hooks/useTransitionPage";
import { useAppSelector } from "@/store/redux/hooks";

import {
  gasuOrigin,
  gasuPanelLink,
  uatOrigin,
  uatPanelLink,
} from "../lib/constants";
import { defineInfoPanel } from "../lib/helpers";
import { useIncident } from "./useIncident";

export const useIncidentForm = () => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const date = moment();
  const { isCheckRfFoPage, indexCurrentRegion, arrRegions, allRegions } =
    useRegions();
  const { user } = useAppSelector((state) => state.auth.user);
  const {
    incident: {
      data: { reportPeriod: reportDate },
    },
  } = useIncident();
  const { data: currentData, currentPeriod } = useAppSelector(
    (state) => state.controls.period
  );
  const { subjectId } = useTransitionPage();

  // Отчётный период
  const reportPeriod = useMemo(() => {
    if (reportDate) {
      return reportDate;
    }
    const currentDate =
      currentPeriod === "0"
        ? date.format("DD.MM.YYYY")
        : currentData?.find(({ id }: any) => id === currentPeriod)
            ?.attributeVals?.["report_period"];
    return currentDate;
  }, [reportDate, currentData]);

  //Ссылка на панель
  const panelLink = useMemo(() => {
    const urlLink = window?.location;
    if (urlLink?.origin === gasuOrigin) {
      return gasuPanelLink;
    } else if (urlLink?.origin === uatOrigin) {
      return uatPanelLink;
    }
    return urlLink?.href || "";
  }, [window?.location]);

  // Автор и время создания черновика
  const authorIncident = user?.name || "";
  const dateCreateIncident = `${date.format("dd")}, ${date.format(
    "L"
  )}г., ${date.format("LT")}`;

  // Наименование инфо панели
  const infoPanel = defineInfoPanel(pathname, isCheckRfFoPage);

  // Наименование территории
  const territory = useMemo(() => {
    let codeSubject: string = "";
    switch (true) {
      case pathname === "/":
        codeSubject = REGION_COUNTRY_CODE;
        break;
      case pathname.startsWith("/district"):
      case pathname.startsWith("/selectedDistrict"):
        codeSubject = pathname.match(/\d+$/gim)?.[0] || "";
        break;
      case pathname.startsWith("/region"):
      case pathname.startsWith("/region") && pathname.endsWith("/graphs"):
        codeSubject = pathname.match(/\d+/gim)?.[0] || "";
        break;
      case pathname.startsWith("/achievementLevel"):
        codeSubject =
          subjectId?.regionId || subjectId?.districtId || REGION_COUNTRY_CODE;
        break;
      case pathname.startsWith("/projects"):
        codeSubject = String(indexCurrentRegion);
        break;
    }
    return pathname.startsWith("/projects")
      ? arrRegions[+codeSubject]?.name
      : allRegions.find(({ id }) => id === codeSubject)?.attributeVals?.name;
  }, [pathname, indexCurrentRegion, arrRegions, allRegions, subjectId]);

  const menuStyle = useMemo(
    () => ({
      "& .MuiList-root": {
        background:
          theme.palette.mode === "dark"
            ? theme.palette.input.background
            : theme.palette.background.darkTheme,
        border: `1px solid ${theme.palette.input.border}`,
        borderRadius: theme.spacing(),
        "& > .MuiButtonBase-root": {
          padding: theme.spacing(1, 2),
          "&:not(:last-child)": {
            borderBottom: `1px solid ${theme.palette.table.backgroundFocused}`,
          },
        },
      },
    }),
    [theme]
  );
  return {
    territory,
    menuStyle,
    infoPanel,
    authorIncident,
    panelLink,
    reportPeriod,
    dateCreateIncident,
  };
};
