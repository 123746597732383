import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { REGION_COUNTRY_CODE } from "@/shared/lib/constants";
import { setSubjectId } from "@/shared/Table/model/slice/subjectIdSlice";
import { useAppDispatch, useAppSelector } from "@/store/redux/hooks";
import { TAB_ID } from "@/store/scheme/region";

export const useTransitionPage = () => {
  const { regions } = useRegions();
  const { subjectId } = useAppSelector((state) => state.indicators.subjectId);
  const dispatch = useAppDispatch();

  const navigateParams = (id?: string | null) => {
    const selectedRegion = regions.find(
      (region) => REGION_COUNTRY_CODE !== region.id && region.id === String(id)
    );
    return selectedRegion?.parentId[0]
      ? {
          districtId: selectedRegion?.parentId[0],
          regionId: id,
        }
      : {
          districtId: id,
          regionId: null,
        };
  };

  const resetSubjectId = () => dispatch(setSubjectId({}));

  const handleChangeSubjectId = (
    districtId: string | undefined = REGION_COUNTRY_CODE,
    regionId: string | undefined | null
  ) => {
    dispatch(
      setSubjectId({
        ...subjectId,
        districtId,
        regionId,
      })
    );
  };

  const changePath = (id?: string | null) => {
    const districtId = navigateParams(id).districtId;
    const regionId = navigateParams(id).regionId;
    if (districtId === REGION_COUNTRY_CODE && regionId) {
      return `/district/${regionId}`;
    } else if (districtId !== REGION_COUNTRY_CODE && regionId) {
      return `/district/${districtId}/region/${regionId}`;
    }
    return "/";
  };

  const getTabName = (pathname: string) => {
    const convertPath = pathname.split("/");
    return convertPath[convertPath.length - 1];
  };

  const transitionTab = (tabId: TAB_ID) => {
    let tabName = tabId;
    if ([TAB_ID.RESULT_CHECKPOINTS, TAB_ID.RESULT_PROJECTS].includes(tabId)) {
      tabName = TAB_ID.PROJECTS;
    }
    return tabName;
  };

  return {
    subjectId,
    resetSubjectId,
    handleChangeSubjectId,
    changePath,
    navigateParams,
    getTabName,
    transitionTab,
  };
};
