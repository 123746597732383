import { FC, ReactNode } from "react";
import { Navigate, useParams } from "react-router";

import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { useAppSelector } from "@/store/redux/hooks";

/**
 * ReactRouter v6 больше не поддерживает регулярные выражения в Route.path. Разработчики обещают какую-нибудь
 * альтернативу в неопределенном будущем.
 * Пока будем проверять код субъекта через этот компонент.
 */
export const RegionsRoute: FC<{ children: ReactNode }> = ({ children }) => {
  const { id, regionId, districtId } = useParams<
    "id" | "regionId" | "districtId"
  >();
  const { user } = useAppSelector((state) => state.auth.user);
  const { regionIds } = useRegions();

  const _id = id ?? regionId;

  if (!user || !_id) {
    return null;
  }

  if (!regionIds.length) {
    return null;
  }

  const match = new RegExp(`^(${regionIds.join("|")})$`).test(_id);

  let redirectPath = "";

  if (districtId) {
    redirectPath = `/district/${districtId}`;
  }

  if (user.isVIP) {
    // VIP и Полпреда редиректим на главную
    redirectPath += "/";
  } else {
    // специалиста и аналитика редиректим на страницу субъекта (или /faq, если субъектов нет)
    if (regionIds.length) {
      redirectPath += `/region/${regionIds[0]}`;
    } else {
      redirectPath = "/faq";
    }
  }

  return <>{match ? children : <Navigate to={redirectPath} replace />}</>;
};
