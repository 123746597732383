import { useMemo } from "react";

import { periods } from "../lib/constants";
import { Periods } from "../lib/types";

export const useExportControl = () => {
  const [years, minYear, maxYear] = useMemo(() => {
    const years = Object.keys(periods)?.reduce((prev, cur) => {
      const year = cur;
      const periodObject = periods?.[year] as Periods[];
      prev[year] = {
        quarter: periodObject[periodObject?.length - 1]["quarter"],
      };
      return prev;
    }, {} as Record<string, Record<string, string>>);

    const yearsArray = Object.keys(years).map(Number);
    return [years, Math.min(...yearsArray), Math.max(...yearsArray)];
  }, []);

  return { years, maxYear, minYear };
};
