import { styled } from "@mui/material";

import { getHeaderHeight } from "@/widgets/AppBar/lib/helpers";

export const RootContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  maxHeight: "100vh",
  color: theme.palette.text.primary,
}));

export const Header = styled("header")({
  flex: "0 0 auto",
});

export const ContentScrollbar = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  overflow: "hidden",
  ".track": {
    borderRadius: theme.spacing(0.375),
    background: theme.palette.scrollbar.track,
    "&.vertical": {
      right: theme.spacing(0.625),
      bottom: theme.spacing(0.5),
      top: theme.spacing(0.5),
      zIndex: 100,
    },
    "&.horizontal": {
      right: theme.spacing(0.25),
      bottom: theme.spacing(0.25),
      left: theme.spacing(0.25),
      zIndex: 100,
    },
  },
  ".thumb": {
    cursor: "pointer",
    borderRadius: "inherit",
    background: theme.palette.scrollbar.thumb,
  },
  ".scroll-view": {
    overflow: "auto !important",
    margin: "0 !important",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      width: 0,
    },
  },
}));
export const Content = styled("div")(({ theme }) => ({
  flex: "1 1 auto",
  maxHeight: `calc(100vh - ${getHeaderHeight(theme)})`,
  overflowY: "auto",
  backgroundColor: theme.palette.background.default,
}));

export const PageContent = styled("div")({
  flex: "1 1 auto",
});
