export const investment = "Инвестиции по видам экономической деятельности";
export const investmentIndicators = [
  "56",
  "57",
  "58",
  "59",
  "60",
  "61",
  "62",
  "63",
  "64",
];
