import { FC, SVGProps } from "react";

import theme from "../../app/theme/common";

export interface IDynamicsLowProps extends SVGProps<SVGSVGElement> {
  contrast?: boolean;
  isBig?: boolean;
}

const icon: FC<IDynamicsLowProps> = ({
  children,
  contrast = false,
  isBig,
  ...props
}) => (
  <svg
    width={isBig ? "40" : "24"}
    height={isBig ? "40" : "24"}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      opacity="0.6"
      cx="12"
      cy="12"
      r="11.5"
      stroke={contrast ? theme.palette.success.main : theme.palette.error.main}
    />
    <path
      d="M7.97 11.6672C7.49 11.6672 7.064 11.6012 6.692 11.4692C6.326 11.3312 6.017 11.1392 5.765 10.8932C5.513 10.6412 5.321 10.3442 5.189 10.0022C5.063 9.65418 5 9.27018 5 8.85018V8.67018C5 8.27418 5.063 7.91418 5.189 7.59018C5.321 7.26018 5.513 6.98118 5.765 6.75318C6.017 6.52518 6.326 6.34818 6.692 6.22218C7.064 6.09618 7.49 6.03318 7.97 6.03318V5.43018H9.05V6.03318C9.524 6.03318 9.944 6.09618 10.31 6.22218C10.682 6.34818 10.994 6.52518 11.246 6.75318C11.498 6.98118 11.69 7.26018 11.822 7.59018C11.954 7.91418 12.02 8.27418 12.02 8.67018V8.85018C12.02 9.27018 11.954 9.65418 11.822 10.0022C11.69 10.3442 11.498 10.6412 11.246 10.8932C10.994 11.1392 10.682 11.3312 10.31 11.4692C9.944 11.6012 9.524 11.6672 9.05 11.6672V12.2702H7.97V11.6672ZM10.886 8.67018C10.886 8.12418 10.733 7.70718 10.427 7.41918C10.121 7.13118 9.662 6.98718 9.05 6.98718V10.7132C9.668 10.7132 10.127 10.5512 10.427 10.2272C10.733 9.90318 10.886 9.44418 10.886 8.85018V8.67018ZM6.134 8.85018C6.134 9.44418 6.284 9.90318 6.584 10.2272C6.89 10.5512 7.352 10.7132 7.97 10.7132V6.98718C7.352 6.98718 6.89 7.13118 6.584 7.41918C6.284 7.70718 6.134 8.12418 6.134 8.67018V8.85018Z"
      fill={contrast ? theme.palette.success.main : theme.palette.error.main}
    />
    <path
      d="M15.8439 12.0992C15.4599 12.0992 15.0939 12.0392 14.7459 11.9192C14.3979 11.7992 14.0919 11.6132 13.8279 11.3612C13.5639 11.1032 13.3539 10.7732 13.1979 10.3712C13.0419 9.96318 12.9639 9.47118 12.9639 8.89518V8.71518C12.9639 8.16318 13.0419 7.69218 13.1979 7.30218C13.3539 6.90618 13.5639 6.58218 13.8279 6.33018C14.0919 6.07818 14.3979 5.89518 14.7459 5.78118C15.0939 5.66118 15.4599 5.60118 15.8439 5.60118C16.2279 5.60118 16.5939 5.66118 16.9419 5.78118C17.2899 5.89518 17.5959 6.07818 17.8599 6.33018C18.1239 6.58218 18.3339 6.90618 18.4899 7.30218C18.6459 7.69218 18.7239 8.16318 18.7239 8.71518V8.89518C18.7239 9.47118 18.6459 9.96318 18.4899 10.3712C18.3339 10.7732 18.1239 11.1032 17.8599 11.3612C17.5959 11.6132 17.2899 11.7992 16.9419 11.9192C16.5939 12.0392 16.2279 12.0992 15.8439 12.0992ZM15.8439 11.1452C16.0659 11.1452 16.2819 11.1062 16.4919 11.0282C16.7019 10.9502 16.8879 10.8242 17.0499 10.6502C17.2119 10.4702 17.3409 10.2362 17.4369 9.94818C17.5389 9.66018 17.5899 9.30918 17.5899 8.89518V8.71518C17.5899 8.31918 17.5389 7.98618 17.4369 7.71618C17.3409 7.44018 17.2119 7.21818 17.0499 7.05018C16.8879 6.87618 16.7019 6.75018 16.4919 6.67218C16.2819 6.59418 16.0659 6.55518 15.8439 6.55518C15.6159 6.55518 15.3969 6.59418 15.1869 6.67218C14.9769 6.75018 14.7909 6.87618 14.6289 7.05018C14.4669 7.21818 14.3379 7.44018 14.2419 7.71618C14.1459 7.98618 14.0979 8.31918 14.0979 8.71518V8.89518C14.0979 9.30918 14.1459 9.66018 14.2419 9.94818C14.3379 10.2362 14.4669 10.4702 14.6289 10.6502C14.7909 10.8242 14.9769 10.9502 15.1869 11.0282C15.3969 11.1062 15.6159 11.1452 15.8439 11.1452Z"
      fill={contrast ? theme.palette.success.main : theme.palette.error.main}
    />
    <path
      d="M11.8577 19.0388L7.86638 15.0964C7.73961 14.9712 7.82939 14.7571 8.00867 14.7571L15.9913 14.7571C16.1706 14.7571 16.2604 14.9712 16.1336 15.0964L12.1423 19.0388C12.0637 19.1164 11.9363 19.1164 11.8577 19.0388Z"
      fill={contrast ? theme.palette.success.main : theme.palette.error.main}
      stroke={contrast ? theme.palette.success.main : theme.palette.error.main}
    />
  </svg>
);

export default icon;
