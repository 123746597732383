import { Theme } from "@mui/material";

export const getColorPoint = (status: string, theme: Theme) => {
  switch (status) {
    case "Выполнено":
      return theme.palette.graphs.success;
    case "Не выполнено":
      return theme.palette.graphs.error;
    case "Срок не наступил":
    default:
      return theme.palette.graphs.notCome;
  }
};
