import MapIcon from "@assets/svg_components/MapIcon";
import { useEffect, useMemo, useRef } from "react";
import { useLocation, useParams } from "react-router";

import { useRouter } from "@/app/hooks/useRouter";
import { useTabs } from "@/app/hooks/useTabs";
import { useAchievementTabs } from "@/shared/AchievementTabCard/hooks/useAchievementTabs";
import { STEPS } from "@/shared/GridCard/lib/types";
import { useTransitionPage } from "@/shared/Table/hooks/useTransitionPage";
import { useAppSelector } from "@/store/redux/hooks";
import { TAB_ID } from "@/store/scheme/region";

import { MapButtonStyled } from "../InsideMainPageTabs.styled";

export const ButtonBack = () => {
  const { navigate } = useRouter();
  const { id } = useParams<"id">();
  const location = useLocation();
  const locationState = useRef<any>();
  const { updateCurrentTab } = useTabs();
  const { setSaveStep } = useAchievementTabs();
  const { enabled } = useAppSelector((state) => state.controls.achievement);
  const { subjectId, changePath, getTabName } = useTransitionPage();

  const getTabId = useMemo(() => {
    if (location.pathname?.match(/graphs/gim)) {
      const tabId = getTabName(location.pathname);
      return tabId !== "graphs" ? tabId : null;
    }
    return location.pathname.match(/achievementLevel/gim)
      ? location.pathname.replace(/\/achievementLevel\/*/gm, "")
      : null;
  }, [location.pathname]);

  useEffect(() => {
    if (location?.state) {
      locationState.current = location?.state;
    }
  }, [location?.state]);

  const recoveryPage = () => {
    if (!enabled) {
      return;
    }
    const tabId = getTabId || locationState.current?.tabId;
    if (tabId) {
      updateCurrentTab(tabId);
      let stepName = STEPS.MAIN;
      switch (tabId) {
        case TAB_ID.VDL_PROJECTS:
          stepName = STEPS.MAIN;
          break;
        case TAB_ID.VDL:
        case TAB_ID.PROJECTS:
          stepName = STEPS.MAIN_FULL;
          break;
        case TAB_ID.RESULT_CHECKPOINTS:
        case TAB_ID.RESULT_PROJECTS:
          stepName = STEPS.PROJECTS;
          break;
      }
      setSaveStep(stepName);
    }
    return;
  };

  const handleNavigate = () => {
    recoveryPage();
    if (id || subjectId?.regionId || subjectId?.districtId) {
      locationState.current.pathname = changePath(id || subjectId?.regionId);
    }
    navigate(locationState.current?.pathname || "/", {
      state: {
        ...location?.state,
        tabId: getTabId || locationState.current?.tabId,
      },
    });
  };

  return (
    <MapButtonStyled onClick={handleNavigate}>
      <MapIcon />
    </MapButtonStyled>
  );
};
