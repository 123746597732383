import {
  AppBar as MuiAppBar,
  Stack,
  styled,
  Toolbar,
  Typography,
} from "@mui/material";
import { FC } from "react";

import { getHeaderHeight } from "../lib/helpers";
import { ITypographyStyled } from "../lib/types";

const ExtendedMuiAppBar: FC<any> = MuiAppBar;

export const AppBarStyled = styled(ExtendedMuiAppBar)(({ theme }) => ({
  position: "relative",
  flex: `0 0 ${getHeaderHeight(theme)}`,
  overflow: "hidden",
  backgroundColor: theme.palette.appBar.backgroundColor,
  boxShadow: theme.shadows[0],
  ...(theme.palette.mode === "dark"
    ? {
        borderBottom: `2px solid ${theme.palette.input.border}`,
      }
    : {
        borderBottom: `1px solid ${theme.palette.border.default}`,
      }),
  backgroundImage: "none",
  color: theme.palette.appBar.color,
}));

export const ToolbarStyled = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
  [theme.breakpoints.up("xs")]: {
    minHeight: getHeaderHeight(theme),
    padding: `0 2.5rem`,
  },
}));

export const AppBarLogo = styled("div")(({ theme }) => ({
  marginRight: theme.spacing(2),
  cursor: "pointer",
}));

export const WrapperStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  flexBasis: 0,
}));

export const TypographyStyled = styled(Typography)<ITypographyStyled>(
  ({ theme }) => ({
    cursor: "pointer",
    fontSize: 12,
    lineHeight: 1.3,
    fontWeight: theme.typography.fontWeightRegular,
    textTransform: "uppercase",
  })
);

export const Controls = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  marginLeft: "auto",
}));

export const InfoText = styled("div")(({ theme }) => ({
  maxWidth: "32rem",
  width: "100%",
  //paddingLeft: theme.spacing(3),
  fontSize: theme.spacing(1.5),
  lineHeight: theme.spacing(2),
  color: theme.palette.text.notificationText,
  display: "-webkit-box",
  overflow: "hidden",
  textOverflow: "ellipsis",
  fontWeight: theme.typography.fontWeightRegular,
  WebkitLineClamp: "4",
  WebkitBoxOrient: "vertical",
  "& > p:first-of-type": {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.primary,
  },
}));
