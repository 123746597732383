import { FC, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";

import { useRouter } from "@/app/hooks/useRouter";
import { BasicPage } from "@/pages/RegionPage/ui/RegionPage.styled";
import { Footer } from "@/shared/Footer/ui/Footer";
import {
  FooterDotMarkFact,
  FooterDotMarkFail,
  FooterDotMarkSuccess,
} from "@/shared/Footer/ui/Footer.styled";
import { TopBar } from "@/shared/TopBar/ui/TopBar";
import { TAB_LEVEL } from "@/store/scheme/region";

import { getFooterComponent } from "../lib/constants";
import { ProjectCards } from "./ProjectCards/ProjectCards";
import { ProjectsSection } from "./ProjectsPage.styled";
import { ProjectTabs } from "./ProjectTabs/ProjectTabs";

export const ProjectsPage: FC = () => {
  const { tabLevel = TAB_LEVEL.ACHIEVE } = useParams<"tabLevel">();
  const [tab, setTab] = useState<string>(tabLevel);
  const { locationState } = useRouter();

  const navigateOptions = locationState?.payload
    ? { state: locationState }
    : undefined;

  const [graphResult, setGraphResult] = useState<boolean>(false);

  const handleLegendCallback = useCallback((legend: boolean) => {
    setGraphResult(legend);
  }, []);

  const renderFooter = () => {
    let children = null;

    switch (tab) {
      case TAB_LEVEL.INDICATOR:
      case TAB_LEVEL.CHECKPOINT:
        children = getFooterComponent([
          {
            icon: <FooterDotMarkFail />,
            name: `Не выполнено ${
              tab === TAB_LEVEL.INDICATOR ? " в срок" : ""
            }`,
          },
          {
            icon: <FooterDotMarkSuccess />,
            name: `Выполнено ${tab === TAB_LEVEL.INDICATOR ? " в срок" : ""}`,
          },
        ]);
        break;
      default:
        return null;
    }
    if (graphResult && tab === TAB_LEVEL.CHECKPOINT) {
      children = [
        ...children,
        getFooterComponent([
          { icon: <FooterDotMarkFact />, name: "Срок не наступил" },
        ]),
      ];
    }
    return <Footer>{children}</Footer>;
  };

  useEffect(() => {
    handleLegendCallback(false);
  }, [tab]);

  return (
    <BasicPage>
      <ProjectsSection>
        <TopBar title="Проекты" back={-1} navigateOptions={navigateOptions} />
        <ProjectCards />
        <ProjectTabs
          onTabChange={setTab}
          handleLegendCallback={handleLegendCallback}
        />
      </ProjectsSection>

      {renderFooter()}
    </BasicPage>
  );
};
