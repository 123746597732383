import { styled } from "@mui/material";

export const ProjectGoalsText = styled("div")(({ theme }) => ({
  fontSize: theme.spacing(2.25),
  fontWeight: theme.typography.fontWeightSemiBold,
  lineHeight: theme.spacing(3.125),
  padding: theme.spacing(0, 3),
}));

export const ProjectGoalsTextRed = styled("span")(({ theme }) => ({
  color: theme.palette.error.main,
}));
