import { styled } from "@mui/material";

export const SceletonCircleWrapper = styled("div")({
  display: "flex",
  gap: "1.5rem",
  marginTop: "0.5rem",
});

export const SceletonCircleContent = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "0.75rem",
  justifyContent: "center",
});
