import { SyntheticEvent, useMemo, useState } from "react";

import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { REGION_COUNTRY_CODE } from "@/shared/lib/constants";
import { formatNumber } from "@/shared/lib/heplers";
import { useFuturePlan } from "@/shared/Table/hooks/useFuturePlan";
import { VALUE_COLOR } from "@/shared/Table/model/api/scheme";
import { useAppSelector } from "@/store/redux/hooks";
import { MODEL_IDS, MODELS } from "@/store/scheme/olap";
import { useIndicatorPassport } from "@/widgets/IndicatorPassport/hooks/useIndicatorPassport";

import { budgetIndicators, firstLevelIndicators } from "../lib/constants";
import { getConvertSurPlus, getIndicatorHighLow } from "../lib/helpers";
import { IBudgetRow } from "../lib/types";
import { useBudget } from "./useBudget";

export const useBudgetRows = () => {
  const { data, district, districtAndRfData } = useBudget();
  const { isCheckRfFoPage } = useRegions();
  const { openIndicatorPassport } = useIndicatorPassport();
  const { isCheckFuturePlan } = useFuturePlan();
  const { compare } = useAppSelector((state) => state.controls);
  const model = MODELS[MODEL_IDS.BUDGET];

  const [expanded, setExpanded] = useState(true);

  const expandedRow = (key: string, expanded: boolean) => {
    return expanded && ["95", "96"].includes(key) ? { display: "none" } : {};
  };

  const handleExpand = () => setExpanded(!expanded);

  const handleOpenPassport = (indicatorId: string) => {
    openIndicatorPassport(indicatorId);
  };

  const isCheckOpenIndicator = (event: SyntheticEvent) => {
    const { classList } = event.target as HTMLElement;
    const isCheckElement = Array.from(classList).includes("budget");
    return isCheckElement;
  };

  const rows: IBudgetRow[] = useMemo(() => {
    return budgetIndicators.map((key) => {
      const colorDynamics = formatNumber(
        data?.[key]?.indicatorVals?.[model.indexes.color],
        undefined,
        0
      ) as unknown as VALUE_COLOR;

      return {
        key,
        level: firstLevelIndicators.includes(key) ? 1 : 2,
        title: data?.[key]?.short_name,
        titleUnit: data?.[key]?.UNIT,
        value: getConvertSurPlus(
          key,
          data?.[key]?.indicatorVals?.[model.indexes.fact],
          colorDynamics
        ),
        valueHL: !(isCheckFuturePlan && ["9", "97"].includes(key))
          ? data?.[key]?.indicatorVals?.[
              model.indexes[getIndicatorHighLow(key, compare)]
            ] ?? 0
          : 0,
        dynamics: formatNumber(
          data?.[key]?.indicatorVals?.[model.indexes.dynamicsAppg]
        ),
        colorDynamics,
        ...(!isCheckRfFoPage.isRF &&
          districtAndRfData && {
            rfValue: getConvertSurPlus(
              key,
              districtAndRfData[REGION_COUNTRY_CODE]?.[key]?.indicatorVals?.[
                model.indexes.fact
              ],
              formatNumber(
                districtAndRfData[REGION_COUNTRY_CODE]?.[key]?.indicatorVals?.[
                  model.indexes.color
                ],
                undefined,
                0
              ) as unknown as VALUE_COLOR
            ),
            rfDynamics: formatNumber(
              districtAndRfData[REGION_COUNTRY_CODE]?.[key]?.indicatorVals?.[
                model.indexes.dynamicsAppg
              ]
            ),
            colorRfDynamics: formatNumber(
              districtAndRfData[REGION_COUNTRY_CODE]?.[key]?.indicatorVals?.[
                model.indexes.color
              ],
              undefined,
              0
            ),
            ...(district &&
              !isCheckRfFoPage.isFO && {
                foValue: getConvertSurPlus(
                  key,
                  districtAndRfData[district?.id]?.[key]?.indicatorVals?.[
                    model.indexes.fact
                  ],
                  formatNumber(
                    districtAndRfData[district?.id]?.[key]?.indicatorVals?.[
                      model.indexes.color
                    ],
                    undefined,
                    0
                  ) as unknown as VALUE_COLOR
                ),
                foDynamics: formatNumber(
                  districtAndRfData[district?.id]?.[key]?.indicatorVals?.[
                    model.indexes.dynamicsAppg
                  ]
                ),
                colorFoDynamics: formatNumber(
                  districtAndRfData[district?.id]?.[key]?.indicatorVals?.[
                    model.indexes.color
                  ],
                  undefined,
                  0
                ),
              }),
          }),
      };
    });
  }, [
    data,
    getIndicatorHighLow,
    district,
    districtAndRfData,
    isCheckFuturePlan,
    isCheckRfFoPage.isRF,
    isCheckRfFoPage.isFO,
  ]);
  return {
    rows,
    isCheckOpenIndicator,
    handleExpand,
    expanded,
    expandedRow,
    handleOpenPassport,
  };
};
