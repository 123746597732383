import DescriptionIcon from "@mui/icons-material/Description";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import ListIcon from "@mui/icons-material/List";
import moment from "moment";
import { useMemo } from "react";
import { useLocation } from "react-router";

import { useVDL } from "@/features/VdlLeader/hooks/useVDL";
import { useRegion } from "@/pages/RegionPage/hooks/useRegion";
import { useAppSelector } from "@/store/redux/hooks";
import { compareDateByFns } from "@/store/scheme/common";

import { GASU_BASE_URL, GASU_PARAMS } from "../lib/constants";
import { IMenuItem } from "../lib/types";

export const useMainMenu = () => {
  const location = useLocation();
  const { user } = useAppSelector((state) => state.auth.user);
  const { pathname } = location;

  const pullOutRegionId = useMemo(() => {
    const path = pathname;
    return path?.match(/(?<=\/region\/)\d+/gi)?.join("");
  }, [pathname]);

  const linkAttributes = useRegion(pullOutRegionId!)?.attributeVals;
  const codeSubject = useMemo(() => {
    const codeFk = linkAttributes?.code_fk || "0";
    return Object.keys(compareDateByFns).includes(codeFk)
      ? compareDateByFns[codeFk]
      : codeFk;
  }, [linkAttributes, pathname]);

  const { data: dataVdl } = useVDL();

  const shapePathVdl = useMemo(() => {
    if (pullOutRegionId) {
      const dataByVdl = dataVdl?.["105"];
      const dateStart = moment(dataByVdl?.attributeVals?.DateStart).format(
        "YYYY-MM-DD"
      );
      const datePart =
        dataByVdl?.attributeVals?.DatePart.toLowerCase() || "quarter";
      const name = linkAttributes?.name;
      const codeFns = linkAttributes?.code_fns;

      return `a4c27add-cbd5-4e93-ba4c-3384b68e520d?year%5B0%5D=${dateStart}&prognoz%5B0%5D=0&text_param=${datePart}&karta_name%5B0%5D=${name}&karta_code%5B0%5D=${codeFns}&subject_name%5B0%5D=${name}&`;
    } else {
      return "d5eb7227-e12b-4d1d-9ce6-306d1ace4209?";
    }
  }, [dataVdl, linkAttributes]);

  const MENU: IMenuItem[] = useMemo(() => {
    const navigation: IMenuItem[] = [
      {
        icon: DescriptionIcon,
        text: "Вопросы и ответы",
        path: "/faq",
        disabled: false,
      },
      {
        icon: ImportContactsIcon,
        text: "Нормативно-правовые акты",
        path: "/acts",
        disabled: false,
      },
    ];

    const options: IMenuItem[] = [
      {
        icon: ListIcon,
        text: "Национальные проекты",
        path: `${GASU_BASE_URL}/redirect/pages/smnp-arm-pfnprp-prod/e9bfccd3-a3c2-41bd-a540-2938fe79cf3f?subject=${codeSubject}`,
        disabled: false,
      },
      {
        icon: FlashOnIcon,
        text: "Оценка эффективности ВДЛ",
        path: `${GASU_BASE_URL}/planeta-nc/dashboard/${shapePathVdl}${GASU_PARAMS}`,
        disabled: false,
      },
    ];

    if (user?.isVIP) {
      navigation.push({
        icon: DescriptionIcon,
        text: "Отчёты",
        path: "/reports",
        disabled: false,
      });
    }

    return [...navigation, ...options];
  }, [codeSubject, shapePathVdl]);

  return { MENU };
};
