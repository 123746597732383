import { SceletonLine1Styled } from "../../Sceleton.styled";
import { SceletonContentStyled } from "./SceletonContent.styled";

export const SceletonContent = () => {
  return (
    <SceletonContentStyled>
      <SceletonLine1Styled widthLine="33%" />
      <SceletonLine1Styled />
    </SceletonContentStyled>
  );
};
