import React, { FC } from "react";

import { CustomScrollbar } from "../CustomScrollbar/ui/CustomScrollbar";
import { WrapperScrollbar } from "../CustomScrollbar/ui/CustomScrollbar.styled";
import { BasicTableStyled } from "./BasicTable.styled";
import { IBasicTableStyledProps } from "./lib/types";

export const BasicTable: FC<IBasicTableStyledProps> =
  React.forwardRef<HTMLTableElement>(({ children, ...props }: any, ref) => {
    return (
      <WrapperScrollbar
        darkTheme
        style={{ overflow: "none !important" }}
        {...props}
      >
        <CustomScrollbar {...props}>
          <BasicTableStyled ref={ref} {...props}>
            {children}
          </BasicTableStyled>
        </CustomScrollbar>
      </WrapperScrollbar>
    );
  });
