import { TableBody as TableBodyWrapper } from "@mui/material";
import { useTheme } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { textForFuturePlan, textForTabsAndTable } from "@store/scheme/common";
import { FC, Fragment, useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";

import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { REGION_COATS, REGION_COUNTRY_CODE } from "@/shared/lib/constants";
import { convertPreviosDate, formatNumber } from "@/shared/lib/heplers";
import { REGION_LEVEL } from "@/shared/lib/types";
import { useData } from "@/shared/Table/hooks/useTotal";
import { TAB_ID } from "@/store/scheme/region";

import { useDistrictRowTable } from "../hooks/useDistrictRowTable";
import { useFuturePlan } from "../hooks/useFuturePlan";
import { useSortTable } from "../hooks/useSortTable";
import { useTransitionPage } from "../hooks/useTransitionPage";
import { labelFuturePlan } from "../lib/constants";
import { getColorValue, getSubColor } from "../lib/helpers";
import { HeaderColumns, ITableBody } from "../lib/types";
import {
  LinkStyled,
  MainTableValue,
  SubValueProjectStyled,
  SubValueStyled,
} from "./Table.styled";
import { DistrictRow } from "./TableEntities/DistrictRow/DistrictRow";
import {
  TableCellStyled,
  TableRowStyled,
} from "./TableEntities/DistrictRow/DistrictRow.styled";
import { ExpandButton } from "./TableEntities/ExpandButton/ExpandButton";

export const TableBody: FC<ITableBody> = ({
  columns,
  withProjects,
  order,
  orderBy,
  isSort,
}) => {
  const { tabName = TAB_ID.VDL_PROJECTS } = useParams<"tabName">();
  const theme = useTheme();
  const location = useLocation();
  const { getIndicator } = useData();
  const [groups, setGroups] = useState<any>({});
  const [isShowDistricts, setIsShowDistricts] = useState(true);
  const { country, allDistricts } = useRegions();
  const { sortBy, getComparator } = useSortTable();
  const { subjectId, handleChangeSubjectId } = useTransitionPage();

  const { districtsRows } = useDistrictRowTable({
    columns,
    allDistricts,
    getIndicator,
    isSort,
  });
  const { isCheckFuturePlan } = useFuturePlan();

  useEffect(() => {
    if (
      !subjectId?.regionId &&
      !subjectId?.districtId &&
      (location?.state?.regionId || location?.state?.districtId)
    ) {
      handleChangeSubjectId(
        location?.state?.districtId,
        location?.state?.regionId
      );
    }
  }, [
    subjectId?.regionId,
    subjectId?.districtId,
    location?.state?.regionId,
    location?.state?.districtId,
  ]);

  useEffect(() => {
    setGroups(() => {
      return columns
        .filter(
          (item) => item?.id !== "header" && !item.mainColumn && !item.rowSpan
        )
        .reduce((acc, current) => {
          if (!acc[current.id]) {
            acc[current.id] = [];
          }
          if (!acc[current.id]["main"]) {
            acc[current.id]["main"] = [];
          }
          if (!acc[current.id]["future"]) {
            acc[current.id]["future"] = [];
          }

          const clearingSigns = current?.label?.split(",")?.[0] || "";

          if (current?.label && labelFuturePlan.includes(clearingSigns)) {
            acc[current.id]["future"].push(current.label);
          } else {
            acc[current.id]["main"].push(current.label || current.popUpLabel);
          }

          return acc;
        }, {} as any);
    });
  }, [columns]);

  const isCheckHeaderColumn = useCallback(
    (column: HeaderColumns) => {
      if (column.id === "header") {
        return (
          column?.updateDateId &&
          groups?.[column?.updateDateId]?.["main"]?.length
        );
      }
      return true;
    },
    [columns, groups]
  );

  return (
    <TableBodyWrapper>
      <TableRowStyled
        focused={
          !subjectId?.regionId && subjectId?.districtId === REGION_COUNTRY_CODE
        }
        onClick={() => {
          handleChangeSubjectId(undefined, null);
        }}
      >
        <TableCellStyled
          component="th"
          scope="row"
          sx={{
            minWidth: "43.75rem",
            fontSize: "1.6rem",
            position: "sticky",
            left: 0,
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Avatar
                sx={{
                  p: "0.5rem",
                  boxSizing: "border-box",
                  color: "red",
                  borderRadius: "50%",
                  background: theme.palette.table.backgroundImg,
                }}
                src={
                  REGION_COATS[REGION_COUNTRY_CODE as keyof typeof REGION_COATS]
                }
                alt={country?.attributeVals?.name}
              />
              <LinkStyled
                to={
                  isCheckFuturePlan ? `/region/${country?.id}/vdlProjects` : ""
                }
                state={{ tabName }}
                isNotUnderline={!isCheckFuturePlan}
              >
                <div>{country?.attributeVals?.name}</div>
              </LinkStyled>
            </Stack>

            <ExpandButton
              isExpanded={isShowDistricts}
              onClick={() => setIsShowDistricts(!isShowDistricts)}
            />
          </Stack>
        </TableCellStyled>
        {columns
          .filter((column) => !["firstHeaderRow", "header"].includes(column.id))
          .map((col) => (
            <Fragment key={`${col.id}_${col.label}`}>
              {!col.subId && (
                <MainTableValue
                  focused={col.focused}
                  valueColor={getColorValue(col, undefined, getIndicator, true)}
                  level={REGION_LEVEL.COUNTRY}
                >
                  {col?.label !== "Место" &&
                    formatNumber(
                      getIndicator(
                        col?.data,
                        REGION_COUNTRY_CODE,
                        col?.helperId || col?.id,
                        undefined,
                        col?.model,
                        undefined,
                        col?.dateCode
                      )?.values?.[col?.indicator],
                      col.valuesUnit,
                      col?.fractionDigits
                    )}
                </MainTableValue>
              )}

              {col.subId && (
                <MainTableValue
                  focused={col.focused}
                  level={REGION_LEVEL.COUNTRY}
                >
                  <span>
                    {`${formatNumber(
                      getIndicator(
                        col?.data,
                        REGION_COUNTRY_CODE,
                        col?.helperId || col?.id,
                        undefined,
                        col?.model,
                        undefined,
                        col?.dateCode
                      )?.values?.[col?.indicator],
                      undefined,
                      0
                    )} / `}
                  </span>
                  <SubValueStyled
                    isRisk={col?.isRiskColumn}
                    valueColor={
                      col?.isRiskColumn
                        ? getSubColor(col, undefined, getIndicator, true)
                        : null
                    }
                  >
                    {formatNumber(
                      getIndicator(
                        col?.isRiskColumn ? col.subData : col?.data,
                        REGION_COUNTRY_CODE,
                        col?.subId,
                        undefined,
                        col?.isRiskColumn ? col.subModel : col?.model,
                        undefined,
                        col?.dateCode
                      )?.values?.[col?.subIndicator!!],
                      undefined,
                      0
                    )}
                  </SubValueStyled>
                  {col?.isRiskColumn && (
                    <SubValueProjectStyled level={REGION_LEVEL.COUNTRY}>
                      {formatNumber(
                        getIndicator(
                          col.subData,
                          REGION_COUNTRY_CODE,
                          col?.subId,
                          undefined,
                          col.subModel,
                          undefined,
                          col?.dateCode
                        )?.values?.[col?.subShareId!!],
                        "%",
                        0
                      )}
                    </SubValueProjectStyled>
                  )}
                </MainTableValue>
              )}
            </Fragment>
          ))}
      </TableRowStyled>

      {isShowDistricts &&
        sortBy(districtsRows, getComparator<any>(order, orderBy)).map(
          (district) => (
            <DistrictRow
              key={district?.id}
              district={district}
              columns={columns}
              withProjects={withProjects}
              order={order}
              orderBy={orderBy}
            />
          )
        )}

      <TableRowStyled sx={{ height: "3.9rem" }}>
        <TableCellStyled
          secondaryCell
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            fontSize: 12,
            zIndex: 10,
            whiteSpace: "nowrap",
          }}
        >
          Дата актуализации
        </TableCellStyled>

        {columns
          .filter(
            (column) =>
              column.id === "header" ||
              (column?.id !== "header" && !column?.parentHeaderLabel)
          )
          .map((col, index) => (
            <Fragment key={`${col.id}_${col.label}`}>
              {isCheckHeaderColumn(col) ? (
                <TableCellStyled
                  secondaryCell
                  key={`${col.indicator}_${col.id}_${index}`}
                  sx={{
                    position: "sticky",
                    bottom: 0,
                    fontSize: 12,
                    whiteSpace: "nowrap",
                  }}
                  colSpan={
                    col?.updateDateId && groups[col.updateDateId]
                      ? groups[col?.updateDateId]["main"]?.length
                      : 0
                  }
                >
                  <div>
                    {!col?.isNotNeedPeriod
                      ? getIndicator(
                          col?.isRiskColumn ? col?.subData : col?.data,
                          REGION_COUNTRY_CODE,
                          col?.isRiskColumn
                            ? col?.subId || col?.id
                            : col?.updateDateId ?? col?.helperId ?? col?.id,
                          undefined,
                          col?.isRiskColumn ? col?.subModel : col?.model,
                          undefined,
                          col?.dateCode
                        )?.date ?? "-"
                      : ""}
                  </div>

                  <div>
                    {col?.needPrevPeriod &&
                      col?.prevPeriod &&
                      convertPreviosDate(
                        getIndicator(
                          col?.data,
                          REGION_COUNTRY_CODE,
                          col?.updateDateId ?? col?.helperId ?? col?.id,
                          undefined,
                          col?.model,
                          undefined,
                          col?.dateCode
                        )?.prevDate
                      )}
                  </div>
                  <div>
                    {col?.needPrevPeriod && !col?.prevPeriod
                      ? col?.textPeriod || textForTabsAndTable
                      : ""}
                  </div>
                </TableCellStyled>
              ) : null}
              {col?.updateDateId &&
              groups?.[col?.updateDateId]?.["future"]?.length ? (
                <TableCellStyled
                  secondaryCell
                  key={`${col.indicator}_${col.id}_${index}_${col?.label}`}
                  sx={{
                    position: "sticky",
                    bottom: 0,
                    fontSize: 12,
                    whiteSpace: "nowrap",
                  }}
                  colSpan={groups[col?.updateDateId]["future"]?.length}
                >
                  <div>{textForFuturePlan}</div>
                </TableCellStyled>
              ) : null}
            </Fragment>
          ))}
      </TableRowStyled>
    </TableBodyWrapper>
  );
};
