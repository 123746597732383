import { useRef } from "react";

import {
  BasicPage,
  BasicSection,
} from "@/pages/RegionPage/ui/RegionPage.styled";
import { FooterForecastFact } from "@/shared/FooterForecastFact/FooterForecastFact";
import { useOpenIncident } from "@/shared/Incident/hooks/useOpenIncident";
import { IncidentCreateButton } from "@/shared/Incident/ui/IncidentCreateButton/IncidentCreateButton";
import { useCheckpointsFilter } from "@/shared/InputSelect/hooks/useCheckpointsFilter";
import { TopBar } from "@/shared/TopBar/ui/TopBar";

import { GraphContainer } from "./GraphContainer/GraphContainer";

export const ProjectGraphsPage = () => {
  const showRef = useRef<HTMLDivElement | null>(null);
  const { actualPeriodData } = useCheckpointsFilter();
  const reportDate = actualPeriodData?.attributeVals.REPORT_DATE || "";
  const { handleChange } = useOpenIncident();

  return (
    <>
      <BasicPage ref={showRef}>
        <BasicSection>
          <TopBar title="Достижение по показателям проекта" back={-1} />
          <IncidentCreateButton
            bigSize
            className="hoverIncident"
            posRight={40}
            onClick={(event) => {
              handleChange(
                event,
                showRef?.current,
                "Достижение по показателям проекта",
                reportDate
              );
            }}
          />
          <GraphContainer />
        </BasicSection>

        <FooterForecastFact />
      </BasicPage>
    </>
  );
};
