import { Card, styled } from "@mui/material";

import { changeColor } from "../lib/helpers";
import {
  GeneralIndicatorsContentProps,
  IGeneralsIndicatorsTypography,
  IValueItemProps,
  IValueTextProps,
} from "../lib/types";

export const GeneralIndicatorsWrapper = styled(Card, {
  shouldForwardProp: (prop) =>
    !["isTotalTitle", "isOpenPopUp", "rows"].includes(prop.toString()),
})<{
  isOpenPopUp?: boolean;
  isTotalTitle?: boolean;
  rows?: number;
}>(({ theme, isOpenPopUp, rows, isTotalTitle }) => ({
  display: "flex",
  ...(!isOpenPopUp && { position: "relative" }),
  flexDirection: "column",
  justifyContent: rows === 2 ? "flex-start" : "space-between",
  gap: theme.spacing(1),
  padding: theme.spacing(1, 3, 3, 3),
  width: "100%",
  height: "100%",
  boxSizing: "border-box",
  ...(isOpenPopUp && {
    backgroundColor: "inherit",
    background: "inherit",
    justifyContent: "flex-start",
    minHeight: theme.spacing(isTotalTitle ? 42 : 33),
  }),
}));

export const GeneralIndicatorsHeader = styled(
  "div"
)<IGeneralsIndicatorsTypography>(({ theme, typography, underline }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  fontSize: theme.typography.fontSize,
  fontWeight: theme.typography.fontWeightMedium,
  "& > span:hover": {
    textDecoration: underline ? "underline" : "none",
    cursor: underline ? "pointer" : "auto",
  },
}));

export const GeneralIndicatorsSubHeader = styled(
  "div"
)<IGeneralsIndicatorsTypography>(({ theme, typography }) => ({
  ...(typography === "light"
    ? {
        fontSize: 16,
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.text.secondary,
        marginTop: theme.spacing(0.5),
      }
    : {
        fontSize: 14,
        fontWeight: theme.typography.fontWeightRegular,
        marginTop: theme.spacing(0.5),
      }),
}));

export const GeneralIndicatorsContent = styled("div", {
  shouldForwardProp: (prop) =>
    !["columns", "isOpenPopUp", "rows"].includes(prop.toString()),
})<GeneralIndicatorsContentProps>(
  ({ theme, columns = 2, rows, isOpenPopUp }) => ({
    display: "grid",
    gridTemplateColumns: `repeat(${columns}, 1fr)`,
    gridAutoRows: "minmax(66px, auto)",
    ...(isOpenPopUp && { gap: "1.5rem" }),
    ...(rows === 2 && {
      flex: 1,
      alignItems: "center",
    }),
  })
);

export const GeneralIndicatorsItem = styled("div")({
  display: "flex",
});

export const GeneralIndicatorsItemIcon = styled("div")(({ theme }) => ({
  flex: "0 0 auto",
  marginRight: theme.spacing(1.5),
}));

export const GeneralIndicatorsItemContent = styled("div")(() => ({
  display: "flex",
  gap: 4,
  flexDirection: "column",
  justifyContent: "flex-start",
  position: "relative",
}));

export const SubjectPlace = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  fontSize: theme.spacing(1.5),
  color: theme.palette.text.secondary,
}));

export const ValueDateWrapper = styled("div")(({ theme }) => ({
  marginRight: theme.spacing(1.5),
}));

export const ValueText = styled("div")<IValueTextProps>(
  ({ theme, fontStyle = "normal", valueColor }) => ({
    margin: theme.spacing(-0.3, 0),
    fontStyle: fontStyle,
    color: changeColor(valueColor, theme),
  })
);

export const ValueItem = styled("span", {
  shouldForwardProp: (prop) =>
    !["isOpenPopUp", "valueColor"].includes(prop.toString()),
})<IValueItemProps>(({ theme, valueColor, isOpenPopUp }) => ({
  color: changeColor(valueColor, theme),
  fontSize: isOpenPopUp ? 28 : 18,
}));

export const GeneralIndicatorsItemTitle = styled("span", {
  shouldForwardProp: (prop) =>
    !["isOpenPopUp", "underline"].includes(prop.toString()),
})<IGeneralsIndicatorsTypography>(
  ({ theme, typography, underline, isOpenPopUp }) => ({
    flex: 1,
    color: theme.palette.text.tertiary,
    fontSize: isOpenPopUp ? 24 : 16,
    fontWeight: theme.typography.fontWeightMedium,
    ...(typography === "light"
      ? {
          minHeight: theme.spacing(2.5),
          display: "block",
        }
      : {}),
    "& > .isNotFoundName": {
      display: "grid",
      gap: "0.25rem",
    },
    "&:hover": {
      textDecoration: underline ? "underline" : "none",
      cursor: underline ? "pointer" : "auto",
    },
  })
);

export const GeneralIndicatorsItemTitleWide = styled(
  GeneralIndicatorsItemTitle
)(({ theme, isOpenPopUp }) => ({
  position: "absolute",
  width: `calc(200% + ${theme.spacing(2)})`,
  fontSize: isOpenPopUp ? 16 : 12,
}));
