import { useState } from "react";

export const useContentExpand = () => {
  const [expanded, setExpanded] = useState<string[]>([]);

  const handleExpanded = (panel: string) => {
    setExpanded((prev) => {
      const isCheckPanel = prev.includes(panel);
      return isCheckPanel
        ? prev.filter((index) => index !== panel)
        : [...prev, panel];
    });
  };

  return {
    expanded,
    handleExpanded,
  };
};
