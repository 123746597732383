export const DATA_PERIOD_VALUE = "120210000";
export const DATA_ACTUAL_VALUE = "2"; // Признак актуальности

export enum MODEL_IDS {
  KPI_SOC_ECONOMY = "1836",
  VDL = "1838",
  NATIONAL_PROJECTS_INDICATORS = "1839",
  KKT = "1840",
  BUDGET = "1841",
  PROJECT_PASSPORT = "1842",
  PROJECT_RESULTS = "1843",
  PROJECT_FINANCES = "1846",
  INCIDENT = "1854",
  RISKS = "1858",
}

export interface IModel {
  modelId: MODEL_IDS;
  uuid: string;
  dimId: string;
  indicatorId: string;
  indexes: Record<string, string>;
  dataPeriodId: string;
  dataActualId: string;
  dataRegionId: string;
}

export interface IModelKpiSocEconomy extends IModel {
  modelId: MODEL_IDS.KPI_SOC_ECONOMY;
  indexes: {
    fact: string;
    plan: string;
    factChangeAppg: string;
    factChangeYearStart: string;
    factDiffRF: string;
    factDiffFO: string;
    factHighLowRF: string;
    factHighLowFO: string;
    placeRF: string;
    placeFO: string;
    color: string;
    colorMap: string;
    pointsRF: string;
    pointsFO: string;
    placeChangeRF: string;
    colorChangeRF: string;
    colorChangeFO: string;
    placeChangeFO: string;
    factChangeAptg: string;
    colorChangeAptg: string;
    colorGraph: string;
  };
  dataSummaryId: string;
}

export interface IModelVDL extends IModel {
  modelId: MODEL_IDS.VDL;
  indexes: {
    fact: string;
    color: string;
  };
  dataRegionLeader: string;
}

export interface IModelNationalProjectsIndicators extends IModel {
  modelId: MODEL_IDS.NATIONAL_PROJECTS_INDICATORS;
  indexes: {
    fact: string;
    plan: string;
    color: string;
  };
  dataProjectId: string;
}

export interface IModelKKT extends IModel {
  modelId: MODEL_IDS.KKT;
  indexes: {
    fact: string;
    increase: string;
    factDiffRF: string;
    factDiffFO: string;
    factHighLowRF: string;
    factHighLowFO: string;
    increaseColor: string;
  };
  dataSummaryId: string;
}

export interface IModelBudget extends IModel {
  modelId: MODEL_IDS.BUDGET;
  indexes: {
    fact: string;
    dynamicsAppg: string;
    factHighLowRFAppg: string;
    factHighLowFOAppg: string;
    color: string;
  };
  dataSummaryId: string;
}

export interface IModelProjectPassport extends IModel {
  modelId: MODEL_IDS.PROJECT_PASSPORT;
  indexes: {
    fact: string;
    plan: string;
    percent: string;
    status: string;
    color: string;
    achievement: string;
    achievementProjectColor: string;
  };
  dataProjectId: string;
  dataIndicatorsId: string;
  dataSummaryId: string;
}

export interface IModelProjectResults extends IModel {
  modelId: MODEL_IDS.PROJECT_RESULTS;
  indexes: {
    status: string;
    executionStatus: string;
    // TODO: включить, когда добавят в таблицу фактов на бэке
    // planKT: string;
    // factKT: string;
    // achievementKT: string;
    fact: string;
    plan: string;
    percent: string;
    color: string;
  };
  dataResultId: string;
  dataProjectId: string;
  dataCheckpointId: string;
}

export interface IModelProjectFinances extends IModel {
  modelId: MODEL_IDS.PROJECT_FINANCES;
  indexes: {
    fact: string;
    plan: string;
    percent: string;
    color: string;
    sumOfThreeYears: string;
  };
  dataProjectId: string;
  dataResultId: string;
}

export interface IModelIncident extends IModel {
  modelId: MODEL_IDS.INCIDENT;
  indexes: {
    fact: string;
    plan: string;
    dmIncidentLevel: string;
    dmTypeOfIncident: string;
  };
}

export interface IModelRisks extends IModel {
  modelId: MODEL_IDS.RISKS;
  indexes: {
    fact: string;
    plan: string;
    color: string;
    status: string;
  };
  dataProjectId: string;
  dataResultId: string;
  dataRiskId: string;
  dataSummaryId: string;
}

export interface IModels {
  [MODEL_IDS.KPI_SOC_ECONOMY]: IModelKpiSocEconomy;
  [MODEL_IDS.VDL]: IModelVDL;
  [MODEL_IDS.NATIONAL_PROJECTS_INDICATORS]: IModelNationalProjectsIndicators;
  [MODEL_IDS.KKT]: IModelKKT;
  [MODEL_IDS.BUDGET]: IModelBudget;
  [MODEL_IDS.PROJECT_PASSPORT]: IModelProjectPassport;
  [MODEL_IDS.PROJECT_RESULTS]: IModelProjectResults;
  [MODEL_IDS.PROJECT_FINANCES]: IModelProjectFinances;
  [MODEL_IDS.INCIDENT]: IModelIncident;
  [MODEL_IDS.RISKS]: IModelRisks;
}

export const isModel = <M extends IModel>(
  model: IModel,
  modelId: MODEL_IDS
): model is M => model.modelId === modelId;

export const MODELS: IModels = {
  [MODEL_IDS.KPI_SOC_ECONOMY]: {
    modelId: MODEL_IDS.KPI_SOC_ECONOMY,
    uuid: "dca40329-f41e-4a60-a6d0-a8e577b07f64",
    dimId: "484",
    indicatorId: "493",
    indexes: {
      fact: "494",
      plan: "495",
      factChangeAppg: "500",
      factChangeYearStart: "501",
      factDiffRF: "502",
      factDiffFO: "503",
      factHighLowRF: "504",
      factHighLowFO: "505",
      placeRF: "506",
      placeFO: "507",
      color: "510",
      colorMap: "511",
      pointsRF: "508",
      pointsFO: "509",
      placeChangeRF: "496",
      colorChangeRF: "498",
      colorChangeFO: "584",
      placeChangeFO: "497",
      factChangeAptg: "910",
      colorChangeAptg: "499",
      colorGraph: "880",
    },
    dataPeriodId: "468",
    dataActualId: "490",
    dataRegionId: "479",
    dataSummaryId: "835",
  },
  [MODEL_IDS.VDL]: {
    modelId: MODEL_IDS.VDL,
    uuid: "b951570e-331a-46d2-87d4-c0ef1ce181d9",
    dimId: "610",
    indicatorId: "620",
    indexes: {
      fact: "621",
      color: "622",
    },
    dataPeriodId: "585",
    dataActualId: "617",
    dataRegionId: "596",
    dataRegionLeader: "603",
  },
  [MODEL_IDS.NATIONAL_PROJECTS_INDICATORS]: {
    modelId: MODEL_IDS.NATIONAL_PROJECTS_INDICATORS,
    uuid: "3519e6cb-36ca-455a-93d7-068284b6749d",
    dimId: "647",
    indicatorId: "657",
    indexes: {
      fact: "658",
      plan: "659",
      color: "660",
    },
    dataPeriodId: "623",
    dataActualId: "654",
    dataRegionId: "634",
    dataProjectId: "641",
  },
  [MODEL_IDS.KKT]: {
    modelId: MODEL_IDS.KKT,
    uuid: "6c082eee-56e0-4273-8f6e-d5ccf0d63cc2",
    dimId: "679",
    indicatorId: "689",
    indexes: {
      fact: "690",
      increase: "691",
      factDiffRF: "692",
      factDiffFO: "693",
      factHighLowRF: "694",
      factHighLowFO: "695",
      increaseColor: "696",
    },
    dataPeriodId: "661",
    dataActualId: "686",
    dataRegionId: "672",
    dataSummaryId: "837",
  },
  [MODEL_IDS.BUDGET]: {
    modelId: MODEL_IDS.BUDGET,
    uuid: "cc6ffc93-79ab-428d-a889-93411eeae9fe",
    dimId: "715",
    indicatorId: "725",
    indexes: {
      fact: "726",
      dynamicsAppg: "727",
      factHighLowRFAppg: "730",
      factHighLowFOAppg: "731",
      color: "742",
    },
    dataPeriodId: "697",
    dataActualId: "722",
    dataRegionId: "708",
    dataSummaryId: "839",
  },
  [MODEL_IDS.PROJECT_PASSPORT]: {
    modelId: MODEL_IDS.PROJECT_PASSPORT,
    uuid: "90b71ce0-aa10-4b64-b70a-22ac67c3f1ef",
    dimId: "1129",
    indicatorId: "799",
    indexes: {
      fact: "800",
      plan: "801",
      percent: "802",
      status: "803",
      color: "804",
      achievement: "1141",
      achievementProjectColor: "881",
    },
    dataPeriodId: "748",
    dataActualId: "796",
    dataRegionId: "760",
    dataProjectId: "767",
    dataIndicatorsId: "784",
    dataSummaryId: "841",
  },
  [MODEL_IDS.PROJECT_RESULTS]: {
    modelId: MODEL_IDS.PROJECT_RESULTS,
    uuid: "69e757d3-06cf-4dd9-8935-511c1d140123",
    dimId: "855",
    indicatorId: "858",
    indexes: {
      status: "859",
      executionStatus: "860",
      fact: "1134",
      plan: "1135",
      percent: "1136",
      color: "1137",
    },
    dataPeriodId: "805",
    dataActualId: "855",
    dataRegionId: "817",
    dataProjectId: "824",
    dataResultId: "841",
    dataCheckpointId: "847",
  },
  [MODEL_IDS.PROJECT_FINANCES]: {
    modelId: MODEL_IDS.PROJECT_FINANCES,
    uuid: "aa353298-ac3e-42bd-a4a0-052f18c00008",
    dimId: "1066",
    indicatorId: "1079",
    indexes: {
      fact: "1080",
      plan: "1081",
      percent: "1082",
      color: "1083",
      sumOfThreeYears: "1121",
    },
    dataPeriodId: "1024",
    dataActualId: "1076",
    dataRegionId: "1036",
    dataProjectId: "1043",
    dataResultId: "1060",
  },
  [MODEL_IDS.INCIDENT]: {
    modelId: MODEL_IDS.INCIDENT,
    uuid: "da6ff291-620b-44ff-b9e0-affbd01bf620",
    dimId: "899",
    indicatorId: "915",
    indexes: {
      fact: "916",
      plan: "",
      dmIncidentLevel: "909",
      dmTypeOfIncident: "912",
    },
    dataPeriodId: "888",
    dataActualId: "",
    dataRegionId: "905",
  },
  [MODEL_IDS.RISKS]: {
    modelId: MODEL_IDS.RISKS,
    uuid: "c56fe3f9-2ef1-43f8-a27b-cae81eb574c8",
    dimId: "976",
    indicatorId: "995",
    indexes: {
      fact: "996",
      plan: "997",
      color: "998",
      status: "999",
    },
    dataPeriodId: "917",
    dataSummaryId: "932",
    dataRegionId: "946",
    dataProjectId: "954",
    dataResultId: "985",
    dataRiskId: "990",
    dataActualId: "",
  },
};

export const getModel = (modelId: MODEL_IDS) => MODELS[modelId];

export enum REQUEST_TYPE {
  DIMENSION_DATA = "dimension_data",
  DATA2 = "data2",
}

export enum SORT {
  ASC = "asc",
  DESC = "desc",
}

export enum FILTER_TYPE {
  AND = "AND",
  OR = "OR",
  EQ = "EQ",
  STARTER = "STARTER",
}

export enum FILTER_OPERAND_TYPE {
  LEVEL = "LEVEL",
  PARENT = "PARENT",
  EQ = "EQ",
  GE = "GE",
  LE = "LE",
  DIM = "DIM",
  CONST = "CONST",
  TEXT = "TEXT",
  AND = "AND",
}

export interface IBaseAttributeVals {
  NAME: string;
  SHORT_NAME: string;
  short_name: string;
  UNIT: string;
  unit_measure: string;
}

export interface IIndicatorAttributeVals extends IBaseAttributeVals {
  name: string;
  code: string;
  SORT_ORDER: string;
}

export interface IPreparedIndicatorAttributeVals
  extends Omit<IIndicatorAttributeVals, "SORT_ORDER"> {
  SORT_ORDER: number;
}

export interface IPeriodAttributeVals extends IPreparedIndicatorAttributeVals {
  DateEnd: string;
  DatePart: DATE_PART;
  DateStart: string;
  FULLNAME: string;
  REPORT_DATE: string;
  PREVIOUS_DATE: string;
  YEAR: string;
}

export interface IRegionAttributeVals {
  name: string;
  code_fk: string;
  code_fns: string;
  code_olap: string;
  adm_center: string;
  SHORT_NAME: string;
}

export interface IRegionLeaderAttributeVals {
  FIO: string;
  age: string;
  birthday: string;
  id_subject: string;
  photo: string;
}

export interface IProjectAttributeVals {
  NAME: string;
  end_action: string;
  completion_status_attr?: string;
  id_subject: string;
  initiator_address: string;
  initiator_inn: string;
  initiator_name: string;
  initiator_phone: string;
  initiator_email: string;
  project_target: string;
  name: string;
  sort_order: string;
  implementation_period: string;
  SHORT_NAME: string;
  start_action: string;
}

export interface IProjectIndicatorAttributeVals {
  NAME: string;
  UNIT: string;
  id_project: string;
  id_result: string;
  name: string;
  short_name: string;
  target_value: string;
  type_ind_id: string;
  type_ind_unit_measure: string;
  unit_measure: string;
  status: string;
  achievement_date_plan: string;
  achievement_date_fact: string;
  sort_order: string;
  achievement_date: string;
}

export interface IProjectResultsAttributeVals {
  NAME: string;
  name: string;
  short_name: string;
  sort_order: string;
  achievement_date: string;
  id_project: string;
}

export interface IProjectFinancesAttributeVals {
  DateEnd: string;
  NAME: string;
  name: string;
  short_name: string;
  sort_order: string;
  id_project: string;
  unit_measure: string;
  sum_ind: string;
  achievement_date: string;
  type_ind_id: string;
  type_ind_unit_measure: string;
}

export interface IProjectCheckpointAttributeVals {
  NAME: string;
  name: string;
  short_name: string;
  sort_order: string;
  status: string;
  achievement_date_fact: string;
  achievement_date_plan: string;
  id_result: string;
  DateEnd?: string;
}

export interface INationalProjectAttributeVals {
  NAME: string;
  logo: string;
  name: string;
  short_name: string;
  sort_order: string;
}

export interface INationalProjectIndicatorAttributeVals
  extends IBaseAttributeVals {
  foiv: string;
  name: string;
  short_name: string;
}

export type IAttributeVals =
  | IIndicatorAttributeVals
  | IPeriodAttributeVals
  | IRegionAttributeVals
  | IRegionLeaderAttributeVals
  | IProjectAttributeVals
  | IProjectIndicatorAttributeVals
  | IProjectResultsAttributeVals
  | IProjectCheckpointAttributeVals
  | IProjectFinancesAttributeVals
  | INationalProjectAttributeVals
  | INationalProjectIndicatorAttributeVals;

export type IPreparedAttributeVals =
  | IPreparedIndicatorAttributeVals
  | IPeriodAttributeVals
  | IRegionAttributeVals
  | IRegionLeaderAttributeVals
  | IProjectAttributeVals
  | IProjectIndicatorAttributeVals
  | IProjectResultsAttributeVals
  | IProjectCheckpointAttributeVals
  | IProjectFinancesAttributeVals
  | INationalProjectAttributeVals
  | INationalProjectIndicatorAttributeVals;

export interface IDataFilterOperand {
  type: FILTER_OPERAND_TYPE;
  dim_id?: string;
  version?: number;
}

export interface IDataFilterOperandLevel extends IDataFilterOperand {
  type: FILTER_OPERAND_TYPE.LEVEL;
  levels: number[];
  rootIds?: string[];
  showAllLevelElements?: boolean;
  showChildren?: boolean;
  showLeafsOnly?: boolean;
}

export interface IDataFilterOperandParent extends IDataFilterOperand {
  type: FILTER_OPERAND_TYPE.PARENT;
  values: string[];
}

export interface IDataFilterOperandDim extends IDataFilterOperand {
  type: FILTER_OPERAND_TYPE.DIM;
  id: string;
  attributeCode?: string;
}

export interface IDataFilterOperandConst extends IDataFilterOperand {
  type: FILTER_OPERAND_TYPE.CONST;
  values: string[];
}

export interface IDataFilterOperandText extends IDataFilterOperand {
  type: FILTER_OPERAND_TYPE.TEXT;
  values: string[];
}

export interface IDataFilterOperandCompare extends IDataFilterOperand {
  type:
    | FILTER_OPERAND_TYPE.EQ
    | FILTER_OPERAND_TYPE.GE
    | FILTER_OPERAND_TYPE.LE
    | FILTER_OPERAND_TYPE.AND;
  operands: IDataFilterOperandAny[];
}

export type IDataFilterOperandAny =
  | IDataFilterOperandLevel
  | IDataFilterOperandParent
  | IDataFilterOperandDim
  | IDataFilterOperandConst
  | IDataFilterOperandText
  | IDataFilterOperandCompare;

export interface IDataFilterStarter {
  type: FILTER_TYPE.STARTER;
  dim_id: string;
}

export interface IDataFilterWithOperands {
  type: FILTER_TYPE.AND | FILTER_TYPE.OR | FILTER_TYPE.EQ;
  operands: (IDataFilterOperandAny | IDataFilter)[];
}

export type IDataFilter = (IDataFilterStarter | IDataFilterWithOperands) & {
  version?: number;
};

export const isDataFilterWithOperands = (
  filter: IDataFilter | IDataFilterOperand
): filter is IDataFilterWithOperands => {
  return (filter as IDataFilterWithOperands).operands !== undefined;
};

export interface IDataSort {
  dim_id: string;
  sortField: string;
  sortOrder: SORT;
  version?: number;
}

export interface IOlapRequestBaseParams {
  modelUuid: string;
  dataSort?: IDataSort[];
  dataFilter?: IDataFilter | IDataFilterOperand;
  includeAttributesByCodes?: string[];
  includeGaps?: boolean;
  dataRequestCaching?: boolean;
}

export interface IOlapParamsDimensionData extends IOlapRequestBaseParams {
  dimensionId: string;
}

export interface IOlapParamsData2 extends IOlapRequestBaseParams {
  indicatorId?: string;
  dimensions?: any; //TODO
  indicators?: any; //TODO
}

export interface IOlapRequestDimensionData {
  type: REQUEST_TYPE.DIMENSION_DATA;
  params: IOlapParamsDimensionData;
}

export interface IOlapRequestData2 {
  type: REQUEST_TYPE.DATA2;
  params: IOlapParamsData2;
}

export type IOlapRequest = (IOlapRequestDimensionData | IOlapRequestData2) & {
  requestId?: string;
};

export interface IOlapResponseDataItem {
  dims: { [dimId: string]: string };
  indicatorVals: { [key: string]: { sum: number } };
}

export interface IOlapResponseDimensionItemDim<
  A extends IAttributeVals = IIndicatorAttributeVals
> {
  dims?: any;
  id: string;
  code: string;
  attributeVals: A;
  childrenCnt: number;
  level: number;
  parentId: string[];
  queryParentId: string[];
  type?: string;
  childrenList?: any[];
  indicatorVals?: any;
}

export interface IPreparedOlapResponseDimensionItemDim<
  A extends IPreparedAttributeVals = IPreparedAttributeVals
> extends Omit<IOlapResponseDimensionItemDim, "attributeVals"> {
  attributeVals: A;
}

export interface IOlapResponseDimensionItems<
  A extends IAttributeVals = IIndicatorAttributeVals
> {
  [dimId: string]: {
    [id: string]: IOlapResponseDimensionItemDim<A>;
  };
}

export interface IOlapResponseDimensionItemDimWithVals<
  A extends IAttributeVals = IIndicatorAttributeVals
> extends IOlapResponseDimensionItemDim<A> {
  indicatorVals: IOlapResponseDataItem["indicatorVals"];
  dims: { [dimId: string]: IOlapResponseDimensionItemDimWithVals<A> };
}

export interface IOlapResponseDataDimensionData<
  A extends IAttributeVals = IIndicatorAttributeVals
> {
  items: IOlapResponseDimensionItemDim<A>[];
}

export interface IOlapResponseDataData2<
  A extends IAttributeVals = IIndicatorAttributeVals
> {
  data: IOlapResponseDataItem[];
  dimensionItems: IOlapResponseDimensionItems<A>;
}

export interface IOlapResponseDimensionsData<
  A extends IAttributeVals = IIndicatorAttributeVals
> {
  responses: {
    [id: string]: {
      requestId: string;
      type: REQUEST_TYPE.DIMENSION_DATA;
      data: IOlapResponseDataDimensionData<A>;
    };
  };
}

export interface IOlapResponseData2<
  A extends IAttributeVals = IIndicatorAttributeVals
> {
  responses: {
    [id: string]: {
      requestId: string;
      type: REQUEST_TYPE.DATA2;
      data: IOlapResponseDataData2<A>;
    };
  };
}

export type IOlapResponse = IOlapResponseDimensionsData | IOlapResponseData2;

export enum RATE {
  HIGH = "high",
  LOW = "low",
}

export enum DATE_PART {
  YEAR = "YEAR",
  QUARTER = "QUARTER",
  MONTH = "MONTH",
  WEEK = "WEEK",
  DAY = "DAY",
}

export interface IIndicator {
  id: string;
  code: string;
  date?: string;
  prevDate?: string;
  year?: string;
  rate?: RATE;
  district?: string;
  level?: number;
  value?: number;
  dateCode?: string;
  values?: { [sumIndicatorId: string]: number };
  attributeVals: IIndicatorAttributeVals;
}

export interface IPreparedIndicator<
  A extends IPreparedAttributeVals = IPreparedAttributeVals
> extends Omit<IIndicator, "attributeVals"> {
  attributeVals: A;
}

export interface IRow<
  A extends IPreparedAttributeVals = IPreparedAttributeVals
> {
  [indicatorId: string]: IPreparedIndicator<A>;
}

export interface IRowDatePart<
  A extends IPreparedAttributeVals = IPreparedAttributeVals
> {
  [indicatorId: string]: Partial<
    Record<DATE_PART, Record<string, IPreparedIndicator<A>>>
  >;
}

export interface IRowDateCode<
  A extends IPreparedAttributeVals = IPreparedAttributeVals
> {
  [indicatorId: string]: Record<string, IPreparedIndicator<A>>;
}
