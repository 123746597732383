import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { prepareProjects, prepareResponseIndicators } from "../../lib/helpers";
import { AllProjects, IIndicatorsProjectsState } from "../../lib/types";
import { getProjects } from "../api/api";

export const initialState: IIndicatorsProjectsState = {
  allProjects: undefined,
  fetching: false,
  error: null,
};

export const extraActionsIndicators = {
  getAll: createAsyncThunk(
    "indicators/projects/getAll",
    async ({ regionIds }: { regionIds: string[] }) =>
      await getProjects({ regionIds: regionIds })
  ),
};

const indicatorsSlice = createSlice({
  name: "indicators/projects",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActionsIndicators.getAll.pending, (state) => {
        state.fetching = true;
      })
      .addCase(extraActionsIndicators.getAll.rejected, (state, action) => {
        state.fetching = false;
        state.error = action.error;
      })
      .addCase(extraActionsIndicators.getAll.fulfilled, (state, action) => {
        const { data: response, projects } = action.payload;
        const preparedProjects = prepareProjects(projects);
        const preparedResponse = prepareResponseIndicators(
          response,
          preparedProjects
        );

        state.allProjects = Object.values(preparedResponse).reduce(
          (prev, project) => {
            const { id_subject } = project.attributeVals;
            !prev[id_subject]
              ? (prev[id_subject] = [project])
              : prev[id_subject].push(project);
            return prev;
          },
          {} as AllProjects
        );

        state.fetching = false;
        state.error = null;
      });
  },
});

export const reducer = indicatorsSlice.reducer;

export default indicatorsSlice;
