import React, { useEffect, useRef } from "react";
import { useLocation, useParams } from "react-router";

import { useRegion } from "@/pages/RegionPage/hooks/useRegion";
import { RegionMainInfo } from "@/pages/RegionPage/ui/RegionMainInfo/ui/RegionMainInfo";
import { RegionMutableInfo } from "@/pages/RegionPage/ui/RegionMutableInfo/ui/RegionMutableInfo";
import { TopBar } from "@/shared/TopBar/ui/TopBar";

import { useRegionPage } from "../hooks/useRegionPage";
import { RegionFooter } from "./RegionFooter/RegionFooter";
import { BasicPage, BasicSection } from "./RegionPage.styled";
import { RegionTabs } from "./RegionTabs/ui/RegionTabs";

export const RegionPage = () => {
  const { id } = useParams<"id">();
  const region = useRegion(id!);
  const location = useLocation();
  const locationState = useRef<any>();
  const { setRegionId } = useRegionPage();

  useEffect(() => {
    if (location?.state) {
      locationState.current = location.state;
    }
  }, [location.state]);

  useEffect(() => {
    if (region?.id) {
      setRegionId(region.id);
    }
  }, [id, region?.id]);

  return (
    <BasicPage>
      <BasicSection>
        <TopBar
          back={-1}
          timeline
          period
          download
          title={region?.attributeVals.name ?? ""}
          navigateOptions={{
            state: {
              ...locationState.current,
            },
          }}
        />

        <RegionTabs />
        <RegionMainInfo />
        <RegionMutableInfo />
      </BasicSection>
      <RegionFooter />
    </BasicPage>
  );
};
