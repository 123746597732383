import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IRegionsControlState } from "../../lib/types";

export const initialState: IRegionsControlState = {
  id: undefined,
};

const regionControlSlice = createSlice({
  name: "controls/regions",
  initialState,
  reducers: {
    setControlRegion: (state, action: PayloadAction<string | undefined>) => {
      state.id = action.payload;
    },
  },
});

export const { setControlRegion } = regionControlSlice.actions;

export const reducer = regionControlSlice.reducer;

export default regionControlSlice;
