import { useCallback, useEffect, useRef } from "react";
import {
  NavigateFunction,
  NavigateOptions,
  To,
  useLocation,
  useNavigate,
} from "react-router";
import { createSearchParams, useSearchParams } from "react-router-dom";

export interface ILocationState {
  from?: string;
  tabId?: string;
  payload?: any;
}

export const useRouter = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const rrNavigate = useNavigate();
  const { state: localState } = useLocation();
  const locationState = useRef();

  useEffect(() => {
    if (localState) locationState.current = localState;
  }, [localState]);

  const getPath = useCallback(
    (to: To) => {
      const toPath =
        typeof to === "string"
          ? { pathname: to.split("?")[0], search: to.split("?")[1] }
          : to;

      const params = createSearchParams(searchParams);
      createSearchParams(toPath.search).forEach((value, key) => {
        params.set(key, value);
      });

      return { pathname: toPath.pathname ?? "", search: params.toString() };
    },
    [searchParams]
  );

  const navigate = useCallback<NavigateFunction>(
    (to, options?) => {
      if (typeof to === "number") {
        return rrNavigate(to); // (delta: number): void
      } else {
        return rrNavigate(getPath(to), options as NavigateOptions);
      }
    },
    [getPath, rrNavigate]
  );

  const createLinkState = useCallback<
    (props: { payload?: any; from?: string }) => ILocationState
  >(({ payload, from }) => {
    return {
      from: from,
      payload,
    };
  }, []);

  return {
    searchParams,
    setSearchParams,
    navigate,
    getPath,
    locationState: locationState.current as ILocationState | undefined,
    createLinkState,
  };
};
