import {
  getOperands,
  olapRequestData2,
  olapRequestDimensionData,
} from "@/store/api/core";
import {
  DATA_ACTUAL_VALUE,
  FILTER_OPERAND_TYPE,
  FILTER_TYPE,
  IProjectCheckpointAttributeVals,
  IProjectFinancesAttributeVals,
  IProjectIndicatorAttributeVals,
  MODEL_IDS,
  MODELS,
} from "@/store/scheme/olap";

export const getProjectInfo = ({
  projectId,
  dataSummaryId = "0",
}: {
  projectId: string;
  dataSummaryId?: string;
}) => {
  const model = MODELS[MODEL_IDS.PROJECT_PASSPORT];

  const dimensions = [
    {
      id: model.dataPeriodId,
      includeItems: true,
      includeAttributesByCodes: ["YEAR", "FULLNAME"],
    },
    { id: model.dataRegionId },
    {
      id: model.dataProjectId,
      includeItems: true,
      includeAttributesByCodes: [
        "name",
        "implementation_period",
        "initiator_inn",
        "initiator_name",
        "project_target",
        "initiator_email",
        "initiator_phone",
        "initiator_address",
      ],
    },
    { id: model.dimId },
    { id: model.dataActualId },
    { id: model.dataSummaryId },
  ];

  const indicators = {
    id: model.indicatorId,
    items: [
      { id: model.indexes.fact },
      { id: model.indexes.plan },
      { id: model.indexes.percent },
      { id: model.indexes.status },
      { id: model.indexes.achievement },
      { id: model.indexes.color },
      { id: model.indexes.achievementProjectColor },
    ],
  };

  const operands = getOperands([
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataProjectId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: [projectId],
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      dim_id: model.dataActualId,
      showLeafsOnly: false,
      showChildren: false,
      levels: [1],
      showAllLevelElements: false,
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataSummaryId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: [dataSummaryId],
        },
      ],
    },
  ]);

  return olapRequestData2<IProjectIndicatorAttributeVals>(
    dimensions.map(({ id }) => id),
    model.indicatorId,
    {
      includeGaps: false,
      dataRequestCaching: true,
      modelUuid: model.uuid,
      dimensions,
      indicators,
      dataFilter: {
        type: FILTER_TYPE.AND,
        version: 1,
        operands,
      },
    },
    "abeedec7-19aa-4bc9-a939-9146c4f87b86"
  );
};

export const getProjectFinances = ({ projectId }: { projectId: string }) => {
  const model = MODELS[MODEL_IDS.PROJECT_FINANCES];

  const dimensions = [
    {
      id: model.dataPeriodId,
      includeItems: true,
      includeAttributesByCodes: ["YEAR"],
    },
    { id: model.dataRegionId },
    { id: model.dataProjectId },
    { id: model.dataResultId },
    { id: model.dimId },
    { id: model.dataActualId },
  ];

  const indicators = {
    id: model.indicatorId,
    items: [
      { id: model.indexes.fact },
      { id: model.indexes.plan },
      { id: model.indexes.percent },
      { id: model.indexes.sumOfThreeYears },
    ],
  };

  const operands = getOperands([
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataProjectId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: [projectId],
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      dim_id: model.dimId,
      showLeafsOnly: false,
      showChildren: false,
      levels: [1],
      showAllLevelElements: false,
    },
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      dim_id: model.dataActualId,
      showLeafsOnly: false,
      showChildren: false,
      levels: [1],
      showAllLevelElements: false,
    },
  ]);

  return olapRequestData2<IProjectFinancesAttributeVals>(
    dimensions.map(({ id }) => id),
    model.indicatorId,
    {
      modelUuid: model.uuid,
      indicatorId: model.indicatorId,
      includeGaps: false,
      dimensions,
      indicators,
      dataFilter: {
        type: FILTER_TYPE.AND,
        version: 1,
        operands,
      },
    },
    "ae3f565e-93d7-4c2d-8fe8-c09c8ad51f86"
  );
};

export const getProjectCheckpointsAndResults = (projectId: string) => {
  const model = MODELS[MODEL_IDS.PROJECT_RESULTS];

  const dimensions = [
    { id: model.dataPeriodId },
    { id: model.dataRegionId },
    { id: model.dataProjectId },
    { id: model.dataActualId },
    {
      id: model.dataResultId,
      includeItems: true,
      includeAttributesByCodes: ["name", "achievement_date"],
    },
    {
      id: model.dataCheckpointId,
      includeItems: true,
      includeAttributesByCodes: [
        "name",
        "achievement_date_plan",
        "achievement_date_fact",
      ],
    },
  ];

  const indicators = {
    id: model.indicatorId,
    items: [{ id: model.indexes.status }, { id: model.indexes.percent }],
  };

  const operands = getOperands([
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataProjectId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: [projectId],
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataActualId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: [DATA_ACTUAL_VALUE],
        },
      ],
    },
  ]);

  return olapRequestData2<IProjectIndicatorAttributeVals>(
    dimensions.map(({ id }) => id),
    model.dataCheckpointId,
    {
      includeGaps: false,
      dataRequestCaching: true,
      modelUuid: model.uuid,
      dimensions,
      indicators,
      dataFilter: {
        type: FILTER_TYPE.AND,
        version: 1,
        operands,
      },
    },
    "a4116d05-ad33-4bbb-9817-af57d3114cf2"
  );
};

export const getCheckpoints = (projectId: string) => {
  const model = MODELS[MODEL_IDS.PROJECT_RESULTS];

  const operands = getOperands([
    {
      type: FILTER_OPERAND_TYPE.DIM,
      id: model.dataCheckpointId,
      attributeCode: "id_project",
    },
    {
      type: FILTER_OPERAND_TYPE.CONST,
      values: [projectId],
    },
  ]);

  return olapRequestDimensionData<IProjectCheckpointAttributeVals>(
    model.dataRegionId,
    {
      modelUuid: model.uuid,
      dimensionId: model.dataCheckpointId,
      dataFilter: {
        type: FILTER_OPERAND_TYPE.EQ as any,
        version: 1,
        operands,
      },
      includeAttributesByCodes: [
        "name",
        "achievement_date_fact",
        "achievement_date_plan",
      ],
    },
    "1da355a2-3053-40bc-b501-e87a3b6e6d7c"
  );
};

export const getResults = (projectId: string) => {
  const model = MODELS[MODEL_IDS.PROJECT_RESULTS];

  const operands = getOperands([
    {
      type: FILTER_OPERAND_TYPE.DIM,
      id: model.dataResultId,
      attributeCode: "id_project",
    },
    {
      type: FILTER_OPERAND_TYPE.CONST,
      values: [projectId],
    },
  ]);

  return olapRequestDimensionData<IProjectCheckpointAttributeVals>(
    model.dataRegionId,
    {
      modelUuid: model.uuid,
      dimensionId: model.dataResultId,
      dataFilter: {
        type: FILTER_OPERAND_TYPE.EQ as any,
        version: 1,
        operands,
      },
      includeAttributesByCodes: ["name", "achievement_date", "id_project"],
    },
    "db853908-b200-4ca4-a363-4288c1eaaf04"
  );
};
