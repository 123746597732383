import { useTheme } from "@mui/material";

import { colorBasedOnValue } from "@/pages/MainPage/lib/helpers";
import { LineChart } from "@/shared/LineChart/ui/LineChart";
import { ValueDate } from "@/shared/ValueDate/ValueDate";

import { getConvertDote } from "../lib/helpers";
import { GetRowChartProps } from "../lib/types";
import { LineValue, LineWrapper, TextBlock, Unit } from "./Achievements.styled";

export const GetRowChart = ({
  title,
  date,
  value,
  isOpenPopUp,
  color = null,
}: GetRowChartProps) => {
  const theme = useTheme();
  const conversionValue = getConvertDote(value);
  const conversionColor = colorBasedOnValue(theme, color);
  return (
    <div>
      <TextBlock isOpenPopUp={isOpenPopUp}>
        <div>{title}</div>
        <ValueDate isOpenPopUp={isOpenPopUp}>{date}</ValueDate>
      </TextBlock>

      <LineWrapper>
        <LineValue isOpenPopUp={isOpenPopUp} color={conversionColor}>
          {value}
          <Unit isOpenPopUp={isOpenPopUp}>%</Unit>
        </LineValue>

        <LineChart
          total={100}
          type="primary"
          size={isOpenPopUp ? "middle" : undefined}
          value={conversionValue}
          color={conversionColor}
        />
      </LineWrapper>
    </div>
  );
};
