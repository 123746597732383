import { olapRequestDimensionData } from "@/store/api/core";
import { FILTER_TYPE, MODEL_IDS, MODELS } from "@/store/scheme/olap";

export const getLeaders = () => {
  const model = MODELS[MODEL_IDS.VDL];

  return olapRequestDimensionData(model.dataRegionLeader, {
    modelUuid: model.uuid,
    dimensionId: model.dataRegionLeader,
    dataFilter: {
      type: FILTER_TYPE.STARTER,
      dim_id: model.dataRegionLeader,
    },
    includeAttributesByCodes: [
      "NAME",
      "SHORT_NAME",
      "SORT_ORDER",
      "FIO",
      "birthday",
      "age",
      "photo",
      "id_subject",
    ],
  });
};
