import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { REGION_COUNTRY_CODE } from "@/shared/lib/constants";
import { useAppSelector } from "@/store/redux/hooks";
import { useExportControl } from "@/widgets/ExportControl/hooks/useExportControl";
import { lastAvailableRfPeriod } from "@/widgets/ExportControl/lib/constants";
import {
  formationPath,
  formationRfPath,
} from "@/widgets/ExportControl/lib/helpers";

export const useDownload = () => {
  const { user } = useAppSelector((state) => state.auth.user);
  const { districts } = useRegions();
  const { years, maxYear } = useExportControl();

  const getFilePathAndName = (id: string) => {
    let fileName = "РФ",
      filePath = "";
    if (![REGION_COUNTRY_CODE, "19"].includes(id)) {
      const quarter = +years[maxYear]?.["quarter"];
      fileName =
        districts?.find((district) => district?.id === id)?.attributeVals
          .SHORT_NAME || "";

      filePath = formationPath({
        fileType: "pdf",
        fileName,
        quarter,
        year: String(maxYear),
      });
    } else {
      fileName = id === "19" ? "Московская область" : "РФ";
      filePath = formationRfPath({
        fileName,
        year: String(lastAvailableRfPeriod),
      });
    }
    return { fileName, filePath };
  };

  const isCheckShowDownload = user?.isVIP && !user?.regions?.length;

  return { isCheckShowDownload, getFilePathAndName };
};
