import { styled, ToggleButton, ToggleButtonGroup } from "@mui/material";

export const ToggleButtonWrapper = styled(ToggleButtonGroup, {
  shouldForwardProp: (prop) =>
    !["changePadding", "noBorder"].includes(prop.toString()),
})<{
  changePadding?: boolean;
  noBorder?: boolean;
}>(({ theme, disabled, noBorder = false, changePadding = false }) => ({
  height: "2.5rem",
  boxSizing: "border-box",
  backgroundColor: disabled
    ? theme.palette.button.dividerBackground
    : theme.palette.button.background,
  padding: changePadding ? theme.spacing(0.25) : theme.spacing(0.5),
  border: `1px solid ${
    !noBorder
      ? theme.palette.button.border
      : disabled
      ? theme.palette.button.dividerBackground
      : theme.palette.button.background
  }`,
}));

export const ToggleButtonItem = styled(ToggleButton)(
  ({ theme, selected, disabled }) => ({
    minWidth: theme.spacing(13),
    padding: "0.25rem 1rem",
    fontSize: theme.spacing(2),
    textTransform: "none",
    lineHeight: theme.spacing(3),
    borderRadius: `${theme.spacing(1)} !important`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: theme.typography.fontWeightMedium,
    border: "none !important",
    backgroundColor: selected
      ? disabled
        ? `${theme.palette.button.dividerDisable} !important`
        : `${theme.palette.button.primary} !important`
      : "none",

    "& p": {
      color: selected
        ? disabled
          ? theme.palette.text.disabledSelected
          : theme.palette.primary.contrastText
        : disabled
        ? theme.palette.text.disabledNotSelected
        : theme.palette.button.notSelected,
    },
  })
);
