import { useCallback, useEffect, useState } from "react";

import { IColumn } from "../lib/types";

export const useStickyTableCells = (
  columns: IColumn[],
  stickyIndex = 0,
  offset = 0
) => {
  const [tableCellSizes, setTableCellSizes] = useState<number[]>([]);

  const getSticky = useCallback(
    (index: number) => {
      if (index >= stickyIndex) {
        return {};
      }

      return {
        width: columns[index]?.width,
        stickyPosition: tableCellSizes[index - 1] ?? offset,
        stickyWidth: tableCellSizes[index],
        stickyLast: index === stickyIndex,
      };
    },
    [columns, offset, stickyIndex, tableCellSizes]
  );

  useEffect(() => {
    const thSizes: number[] = columns.reduce((prev, column, index) => {
      const width = Number(column.width) ?? 0;

      prev.push(prev[index - 1] ? prev[index - 1] + width : offset + width);
      return prev;
    }, [] as number[]);

    setTableCellSizes(thSizes);
  }, [columns, offset, setTableCellSizes]);

  return {
    tableCellSizes,
    getSticky,
  };
};
