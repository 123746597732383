import BudgetIcon from "@assets/Budget.svg";
import { FC } from "react";

import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { CardListInner } from "@/shared/CardList/CardList.styled";
import { CompareIcons } from "@/shared/CompareIcons/ui/CompareIcons";
import { getValueItem } from "@/shared/GeneralIndicators/lib/helpers";
import { useRegionPopUp } from "@/shared/RegionPopUp/hooks/useRegionPopUp";
import { IPopUpProps, REGION_TABLES } from "@/shared/RegionPopUp/lib/types";
import { Sceleton } from "@/shared/Sceleton/ui/Sceleton";
import { VALUE_COLOR } from "@/shared/Table/model/api/scheme";
import { ExpandButton } from "@/shared/Table/ui/TableEntities/ExpandButton/ExpandButton";
import { WidgetScrollbar } from "@/shared/WidgetScrollbar/ui/WidgetScrollbar";

import { useBudget } from "../hooks/useBudget";
import { useBudgetRows } from "../hooks/useBudgetRows";
import { notifyDynamicsCalc } from "../lib/constants";
import { getUnitMeasure } from "../lib/helpers";
import {
  BudgetPopUpButton,
  BudgetRow,
  BudgetRowDynamics,
  BudgetRowEmptyIcon,
  BudgetRowTitle,
  BudgetRowValue,
  BudgetUnit,
  DynamicColumn,
  ToolTipStyled,
} from "./Budget.styled";
import { BudgetDynamics } from "./BudgetDynamics";
import { BudgetHeader } from "./BudgetHeader";

export const Budget: FC<IPopUpProps> = ({ isOpenPopUp }) => {
  const { isCheckRfFoPage } = useRegions();
  const { data, fetching } = useBudget();
  const {
    rows,
    isCheckOpenIndicator,
    handleExpand,
    expandedRow,
    expanded,
    handleOpenPassport,
  } = useBudgetRows();
  const { PopUpButton, PopUp, popupHeightMax }: any = useRegionPopUp(
    REGION_TABLES.BUDGET,
    isOpenPopUp,
    <>
      <img src={BudgetIcon} alt="Бюджет" />
      <BudgetHeader openWidget={true} />
    </>,
    undefined,
    undefined,
    1008
  );

  if (fetching) {
    return <Sceleton type="line" />;
  }

  return (
    <WidgetScrollbar
      isOpenPopUp={isOpenPopUp}
      titleIncident="Бюджет"
      dateIncident={data?.["95"]?.attributeVals?.REPORT_DATE}
      icon={<img src={BudgetIcon} alt="Бюджет" />}
      heightMax={popupHeightMax}
      autoHeight={isOpenPopUp}
      header={
        !isOpenPopUp && (
          <>
            <BudgetHeader />
            <BudgetPopUpButton>
              {!isOpenPopUp && <PopUpButton />}
            </BudgetPopUpButton>
          </>
        )
      }
    >
      {rows.map((row) => {
        return (
          <BudgetRow
            isOpenPopUp={!!isOpenPopUp}
            key={row.key}
            sx={expandedRow(row.key, expanded)}
          >
            <CardListInner padding>
              <BudgetRowTitle
                level={row.level}
                onClick={(event) =>
                  isCheckOpenIndicator(event) && handleOpenPassport(row.key)
                }
              >
                <span className="budget">{row?.title},</span>
                <BudgetUnit>{row?.titleUnit}</BudgetUnit>
                {row.key === "94" && (
                  <ExpandButton
                    onClick={handleExpand}
                    isExpanded={!expanded}
                    size="small"
                    darkTheme
                  />
                )}
              </BudgetRowTitle>

              <BudgetRowValue isCheckRf={isCheckRfFoPage.isRF}>
                {row?.value}
              </BudgetRowValue>

              <BudgetRowDynamics
                isCenter={
                  !row?.valueHL &&
                  !isCheckRfFoPage.isRF &&
                  !(row?.dynamics === "-" && row.key === "99")
                }
              >
                {!isCheckRfFoPage.isRF && (
                  <CompareIcons value={row?.valueHL!!} />
                )}
                {row?.dynamics === "-" && row.key === "99" ? (
                  <>
                    <BudgetRowEmptyIcon />
                    <ToolTipStyled
                      title={notifyDynamicsCalc}
                      placement="bottom"
                      arrow
                    >
                      <span>x</span>
                    </ToolTipStyled>
                  </>
                ) : (
                  <DynamicColumn
                    valueColor={+row?.colorDynamics as unknown as VALUE_COLOR}
                  >
                    {row?.dynamics !== "-"
                      ? `${getValueItem({
                          key: row.key,
                          value: row?.dynamics,
                          valueColor:
                            row?.colorDynamics as unknown as VALUE_COLOR,
                        })} ${getUnitMeasure(row.key)}`
                      : "-"}
                  </DynamicColumn>
                )}
              </BudgetRowDynamics>
            </CardListInner>
            {!isCheckRfFoPage.isRF && <BudgetDynamics row={row} />}
          </BudgetRow>
        );
      })}
      <PopUp />
    </WidgetScrollbar>
  );
};
