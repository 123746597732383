import { ACHIEVE_RISKS_VALUE } from "./types";

export const switcherRisksData = [
  { value: ACHIEVE_RISKS_VALUE.ACHIEVE, title: "Достижение" },
  { value: ACHIEVE_RISKS_VALUE.RISKS, title: "Риски", disabled: true },
];

export const achieveTitle = "Достижение по проектам";
export const risksTitle = "Риски реализации проектов";
export const textUnderTitle = "по данным АЦ";
