export const decimalToString = (x: number | undefined): string => {
  const numberToFixed = Number(String(x).match(/\d+$/gm)?.[0] ?? 0);
  return x ? x.toFixed(numberToFixed) : "0";
};

export const getTitleCell = (indicator: string) => {
  switch (indicator) {
    case "106":
      return "Средняя заработная плата";
    case "107":
      return "Выпуск товаров и услуг";
    case "108":
      return "Частные инвестиции";
    case "109":
      return "Новые рабочие места";

    default:
      return "-";
  }
};
