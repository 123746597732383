export enum ACHIEVE_RISKS_VALUE {
  ACHIEVE = "achieve",
  RISKS = "risks",
}

export interface Iswitcher {
  mainSwitcher: ACHIEVE_RISKS_VALUE;
  risksSwitcher: string;
}
export interface IAchieveRisksWrapperProps {
  isOpenPopUp?: boolean;
  switcher?: Iswitcher;
  callbackChange?: () => void;
}
