import { useMemo } from "react";

import { useTabs } from "@/app/hooks/useTabs";
import {
  REGION_COUNTRY_CODE,
  REGION_DISTRICT_CODES,
} from "@/shared/lib/constants";
import { formatNumber } from "@/shared/lib/heplers";
import { useData } from "@/shared/Table/hooks/useTotal";
import { useAppSelector } from "@/store/redux/hooks";
import { MODEL_IDS } from "@/store/scheme/olap";
import { TAB_ID } from "@/store/scheme/region";

import { getTargetId, getVdlId } from "../lib/helpers";
import {
  TypeVdlValue,
  useGetVldDataReturnT,
  useGetVldDataT,
} from "../lib/types";

export const useGetVldData = ({
  districtId,
  selectedSubjectId,
  regionId,
  regions,
}: useGetVldDataT): useGetVldDataReturnT => {
  const { getIndicator } = useData();
  const { activeTab } = useTabs();
  const { enabled: achEnabled } = useAppSelector(
    (state) => state.controls.achievement
  );
  const { kpiData, projectPassport } = useAppSelector((state) => state.map.geo);
  const targetIdForMap = getTargetId(
    true,
    selectedSubjectId,
    regionId,
    districtId
  );

  const [vdlValues, vdlLabels] = useMemo(() => {
    const isRiskTab = activeTab === TAB_ID.RISKS;

    const vdlId = getVdlId(activeTab, achEnabled);
    const isZero = vdlId === "0";

    const vdlUnit =
      getIndicator(
        isZero ? projectPassport : kpiData,
        targetIdForMap,
        vdlId,
        undefined,
        isZero ? MODEL_IDS.PROJECT_PASSPORT : undefined
      )?.attributeVals?.UNIT || isZero
        ? "%"
        : "";

    const vdlValues: { [key: string]: TypeVdlValue } = {};
    const vdlLabels: { [key: string]: string | undefined } = {};
    const setValueAndLable = (id: string) => {
      switch (true) {
        case isZero &&
          ![TAB_ID.RESULT_CHECKPOINTS, TAB_ID.RESULT_PROJECTS].includes(
            activeTab
          ):
          vdlValues[id] = {
            value: getIndicator(
              projectPassport,
              id,
              vdlId,
              undefined,
              MODEL_IDS.PROJECT_PASSPORT
            )?.values?.["1141"],
            unit: vdlUnit,
          };
          break;

        case isZero && activeTab === TAB_ID.RESULT_PROJECTS:
          vdlValues[id] = {
            value: getIndicator(
              projectPassport,
              id,
              vdlId,
              undefined,
              MODEL_IDS.PROJECT_PASSPORT
            )?.values?.["802"],
            unit: vdlUnit,
          };
          break;

        case !isZero && activeTab === TAB_ID.RESULT_CHECKPOINTS:
          vdlValues[id] = {
            value: getIndicator(
              projectPassport,
              id,
              vdlId,
              undefined,
              MODEL_IDS.PROJECT_PASSPORT
            )?.values?.["802"],
            unit: "%",
          };
          break;

        case vdlId === "10":
          vdlValues[id] = {
            value: getIndicator(kpiData, id, vdlId)?.values?.["500"],
            unit: vdlUnit,
          };
          break;

        default:
          vdlValues[id] = {
            sum: getIndicator(kpiData, id, "104")?.values?.["494"],
            value: getIndicator(kpiData, id, vdlId)?.values?.[
              achEnabled ? "500" : "494"
            ],
            unit: vdlUnit,
          };
          break;
      }

      switch (true) {
        case isRiskTab:
          vdlLabels[id] = `${
            getIndicator(kpiData, id, "104")?.values?.["494"] ?? "-"
          } / ${getIndicator(kpiData, id, vdlId)?.values?.["494"] ?? "-"}`;
          break;

        case isZero:
          vdlLabels[id] = formatNumber(
            getIndicator(
              projectPassport,
              id,
              vdlId,
              undefined,
              MODEL_IDS.PROJECT_PASSPORT
            )?.values?.["1141"],
            vdlUnit,
            0
          );
          break;

        case vdlId === "10":
          vdlLabels[id] = formatNumber(
            getIndicator(kpiData, id, vdlId)?.values?.["500"],
            vdlUnit,
            0
          );
          break;

        default:
          vdlLabels[id] = formatNumber(
            getIndicator(kpiData, id, vdlId)?.values?.[
              achEnabled ? "500" : "494"
            ],
            "%",
            0
          );
          break;
      }
    };

    setValueAndLable(targetIdForMap);

    switch (true) {
      case Boolean(
        targetIdForMap !== REGION_COUNTRY_CODE && districtId && regions?.length
      ):
        regions?.map(({ id }) => id).forEach(setValueAndLable);
        break;

      case targetIdForMap === REGION_COUNTRY_CODE:
        REGION_DISTRICT_CODES.forEach(setValueAndLable);
        break;

      default:
        break;
    }

    return [vdlValues, vdlLabels];
  }, [
    activeTab,
    getIndicator,
    projectPassport,
    kpiData,
    targetIdForMap,
    achEnabled,
    districtId,
    regions,
  ]);

  return { vdlValues, vdlLabels };
};
