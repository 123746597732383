import { Grid } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import { FC, useEffect } from "react";
import { useParams } from "react-router";

import { useRegionGraphs } from "@/pages/RegionGraphsPage/hooks/useRegionGraphs";
import { GraphCard } from "@/shared/GraphCard/ui/GraphCard";
import { GridContainer } from "@/shared/GraphCard/ui/GraphCard.styled";
import { UseGraphOptions } from "@/shared/GraphContainer/lib/helpers";
import { getPrevPer } from "@/shared/lib/heplers";
import { Loader } from "@/shared/Loader/Loader";

import { IGraphContainer, IGraphData } from "../lib/types";

export const RegionGraphContainer: FC<IGraphContainer> = ({ dataName }) => {
  const { id: regionId = "8" } = useParams<"id">();

  const {
    periodCodes,
    currentData,
    currentFetching,
    currentAction,
    additionalOpt: { modelId, indicators, graphMap },
  } = UseGraphOptions(dataName, regionId);

  const { requestGraphsData } = useRegionGraphs();

  const regionGData = currentData?.[regionId]?.[modelId] as any;

  useEffect(() => {
    if (regionId && isEmpty(regionGData) && !currentFetching) {
      requestGraphsData(currentAction);
    }
  }, [regionId, requestGraphsData, currentAction]);

  if (!regionGData) return <Loader />;

  const preparedData = graphMap.reduce((prev, { iId }) => {
    const vallByDateCode = periodCodes?.[iId]?.["dateCodes"]?.map(
      (dateCode: string) => {
        const indicatorById = regionGData?.[iId];
        const valByCode = indicatorById?.[dateCode]?.values;
        const curValByCode =
          indicatorById?.[getPrevPer()]?.values ??
          indicatorById?.[periodCodes?.[iId]["dateCodes"][0]]?.values;

        let plan =
          valByCode?.[indicators.plan] ?? curValByCode?.[indicators.plan];
        let fact = valByCode?.[indicators.fact];

        if (["106", "109"].includes(iId)) {
          plan = plan ? Math.round(plan) : plan;
          fact = fact ? Math.round(fact) : fact;
        }

        return {
          fact,
          plan,
          percent: valByCode?.[indicators.percent],
          color: valByCode?.[indicators.color],
          unit: indicatorById?.[dateCode]?.attributeVals.UNIT,
        };
      }
    );

    prev[iId] = vallByDateCode;

    return prev;
  }, {} as { [key: string]: IGraphData[] });

  const isOnlyOne = graphMap.length === 1;

  return (
    <GridContainer container spacing={isOnlyOne ? undefined : 2}>
      {graphMap.map(({ title, iId }) => (
        <Grid key={iId} item xs={isOnlyOne ? 12 : 6}>
          <GraphCard
            isOnlyOne={isOnlyOne}
            title={title}
            graphData={preparedData[iId]}
            periods={periodCodes?.[iId]?.["periods"]}
          />
        </Grid>
      ))}
    </GridContainer>
  );
};
