import { getOperands, olapRequestData2 } from "@/store/api/core";
import {
  FILTER_OPERAND_TYPE,
  FILTER_TYPE,
  MODEL_IDS,
  MODELS,
} from "@/store/scheme/olap";

export const getFOSumProjects = ({
  dataSummaryId,
  dataRegionId,
}: Record<string, string>) => {
  const model = MODELS[MODEL_IDS.PROJECT_PASSPORT];

  const dimensions = [
    {
      id: model.dataPeriodId,
      includeItems: true,
      includeAttributesByCodes: ["REPORT_DATE"],
    },
    {
      id: model.dataRegionId,
    },
    {
      id: model.dataProjectId,
      includeItems: true,
      includeAttributesByCodes: ["name", "implementation_period"],
    },
    {
      id: model.dimId,
    },
    {
      id: model.dataActualId,
    },
    {
      id: model.dataSummaryId,
    },
  ];

  const indicators = {
    id: model.indicatorId,
    items: [
      { id: model.indexes.plan },
      { id: model.indexes.fact },
      { id: model.indexes.status },
    ],
  };

  const operands = getOperands([
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      version: 1,
      dim_id: model.dataPeriodId,
      showLeafsOnly: false,
      showChildren: false,
      levels: [1, 2],
      showAllLevelElements: false,
    },
    {
      type: FILTER_OPERAND_TYPE.PARENT,
      version: 1,
      dim_id: model.dataRegionId,
      values: [dataRegionId],
    },
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      version: 1,
      dim_id: model.dataProjectId,
      showLeafsOnly: false,
      showChildren: false,
      levels: [0, 1],
      showAllLevelElements: false,
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      version: 1,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          version: 1,
          id: model.dimId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          version: 1,
          values: ["0", "106", "107", "108", "109"],
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      version: 1,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          version: 1,
          id: model.dataActualId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          version: 1,
          values: ["2"],
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      version: 1,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          version: 1,
          id: model.dataSummaryId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          version: 1,
          values: [dataSummaryId],
        },
      ],
    },
  ]);

  return olapRequestData2(
    dimensions.map(({ id }) => id),
    model.indicatorId,
    {
      modelUuid: model.uuid,
      includeGaps: false,
      indicatorId: model.indicatorId,
      dimensions,
      dataRequestCaching: true,
      indicators,
      dataFilter: {
        type: FILTER_TYPE.AND,
        version: 1,
        operands,
      },
    },
    "169b20c0-e410-4e84-9ca0-51a39fbbc7d7"
  );
};
