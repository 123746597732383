import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { Stack } from "@mui/material";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { AchievementControl } from "@/shared/AchievementControl/ui/AchievementControl";
import { COMPARE_TYPE } from "@/shared/CompareControl/lib/types";
import { compareSetValue } from "@/shared/CompareControl/model/compareSlice";
import { CompareControl } from "@/shared/CompareControl/ui/CompareControl";
import { useFuturePlan } from "@/shared/Table/hooks/useFuturePlan";
import { useAppDispatch, useAppSelector } from "@/store/redux/hooks";
import { infoField } from "@/store/scheme/common";
import { TAB_ID } from "@/store/scheme/region";
import { menuSetOpen } from "@/widgets/MainMenu/model/menuSlice";
import { MenuButton } from "@/widgets/MainMenu/ui/MainMenu.styled";

import { useAppBar } from "../hooks/useAppBar";
import { renderFilterForAppBar } from "../lib/helpers";
import { AppBarProps } from "../lib/types";
import {
  AppBarStyled,
  Controls,
  InfoText,
  ToolbarStyled,
  WrapperStack,
} from "./AppBar.styled";
import { AppBarLogotype } from "./AppBarLogotype/AppBarLogotype";
import { AppBarPeriodFilter } from "./AppBarPeriodFilter/AppBarPeriodFilter";
import { AppBarUserMenu } from "./AppBarUserMenu/AppBarUserMenu";

export const AppBar: FC<AppBarProps> = ({ withMenu }) => {
  const { menu } = useAppSelector((state) => state.ui);
  const { compare } = useAppSelector((state) => state.controls);
  const { isCheckRfFoPage } = useRegions();
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isCheckFuturePlan } = useFuturePlan();
  const backLinkRef = useRef("/");
  const [localStateTabId, setlocalStateTabId] = useState<string>();
  const getTabIdByLocation = useMemo(() => {
    const result = pathname.replace(/\/regions\/\d*\/*/gm, "");

    if (pathname.startsWith("/regions")) {
      return result || localStateTabId;
    }
    if (pathname.startsWith("/achievementLevel")) {
      switch (true) {
        case pathname.startsWith(`/achievementLevel/${TAB_ID.VDL_PROJECTS}`):
          return TAB_ID.VDL_PROJECTS;
        case pathname.startsWith(`/achievementLevel/${TAB_ID.PROJECTS}`):
          return TAB_ID.PROJECTS;

        default:
          return TAB_ID.VDL;
      }
    }
  }, [localStateTabId, pathname]);

  const { resetTabsByCkickOnHome, isShowCompareControl, isControls } =
    useAppBar();

  const handleClickLogo = () => {
    resetTabsByCkickOnHome();
    navigate("/", {
      state: {
        ...location?.state,
        initialValue: "",
        tabId: getTabIdByLocation,
      },
    });
  };

  const toggleOpen = () => {
    dispatch(menuSetOpen(!menu.open));
  };

  useEffect(() => {
    if (location?.state?.backLink) {
      backLinkRef.current = location?.state?.backLink;
    }
  }, [location?.state?.backLink]);

  useEffect(() => {
    if (location?.state?.tabId) {
      setlocalStateTabId(location?.state?.tabId);
    }
  }, [location?.state?.tabId]);

  useEffect(() => {
    if (isCheckRfFoPage.isAll && compare.value === COMPARE_TYPE.FO) {
      dispatch(compareSetValue(COMPARE_TYPE.RF));
    }
  }, [isCheckRfFoPage.isAll]);

  return (
    <AppBarStyled>
      <ToolbarStyled>
        <MenuButton onClick={toggleOpen} edge="start">
          {menu.open ? <CloseIcon /> : <MenuIcon />}
        </MenuButton>

        <Stack direction="row" alignItems="center" onClick={handleClickLogo}>
          <AppBarLogotype />
        </Stack>

        <InfoText>
          <p> Последнее обновление: </p>
          {infoField}
        </InfoText>

        <Controls>
          {withMenu && isControls && (
            <>
              <AchievementControl />

              {isShowCompareControl && <CompareControl />}
            </>
          )}
          {withMenu &&
            !["faq", "acts", "reports"].find((path) =>
              pathname.includes(path)
            ) && (
              <WrapperStack alignItems="center" direction="row">
                {!(
                  pathname.startsWith("/projects") ||
                  ["/graphs"].find((path) => pathname.includes(path))
                ) && <AppBarPeriodFilter />}

                {renderFilterForAppBar(pathname, isCheckFuturePlan)}
              </WrapperStack>
            )}

          {withMenu && <AppBarUserMenu />}
        </Controls>
      </ToolbarStyled>
    </AppBarStyled>
  );
};
