import { useMemo } from "react";
import { useParams } from "react-router";

import { useAppSelector } from "@/store/redux/hooks";

import { defaultRisksColumns } from "../lib/constants";
import { IRisksRow } from "../lib/types";

export const useProjectRisks = () => {
  const { id } = useParams<"id">();
  const { riskProjectReference, riskProjectFetching, riskByProject } =
    useAppSelector((state) => state.regions.riskProject);

  const columns = [...defaultRisksColumns];

  const rows = useMemo(() => {
    const data = riskProjectReference?.[id!!];
    return Object.values(data || {}) as IRisksRow[];
  }, [riskProjectReference, id]);

  return { columns, rows, riskProjectFetching, riskByProject };
};
