import React, { FC } from "react";

import { useTable } from "../hooks/useTable";
import { IRegionPopUpProps, REGION_TABLES } from "../lib/types";
import {
  RegionPopUpBody,
  RegionPopUpHeader,
  RegionsPopUp,
} from "./RegionPopUp.styled";
import { mustBeLineArray } from "./RegionPopUpButton/lib/constants";
import { RegionPopUpButton } from "./RegionPopUpButton/ui/RegionPopUpButton";

const colorDivider = "#E1E6ED20";

export const RegionPopUp: FC<IRegionPopUpProps> = ({
  open,
  handleOpen,
  table,
  title,
  selectedProject,
  periodId,
  minWidth,
  switcher,
}) => {
  const { TABLES } = useTable(selectedProject, periodId, switcher);
  const { component, width } = TABLES[table ?? REGION_TABLES.DEFAULT];

  return (
    <RegionsPopUp
      open={open}
      onClose={handleOpen}
      fullWidth
      maxWidth="lg"
      width={width}
    >
      <RegionPopUpHeader
        minWidth={minWidth}
        isChangeHeight={[
          REGION_TABLES.PROJECTS_TABLE,
          REGION_TABLES.ACHIEVEMENTS,
        ].includes(table!!)}
      >
        {title && <div className="title">{title}</div>}

        <RegionPopUpButton
          open={open}
          handleOpen={handleOpen}
          isChangePosition={[
            REGION_TABLES.PROJECTS_TABLE,
            REGION_TABLES.ACHIEVEMENTS,
          ].includes(table!!)}
        />
      </RegionPopUpHeader>

      {mustBeLineArray.includes(table!!) && (
        <div style={{ height: "0.375rem", background: colorDivider }} />
      )}

      <RegionPopUpBody colorBody={table === REGION_TABLES.PROJECTS_TABLE}>
        {component}
      </RegionPopUpBody>
    </RegionsPopUp>
  );
};
