import { TableBody, TableHead, TableRow } from "@mui/material";
import React, { FC, Fragment, useEffect, useRef, useState } from "react";

import { ProjectResultsGraph } from "@/features/ProjectResultsGraph/ui/ProjectResultsGraph";
import { ProjectResultsSelect } from "@/features/ProjectResultsGraph/ui/ProjectResultsSelect";
import { useProjectPage } from "@/pages/ProjectsPage/hooks/useProjectPage";
import { useProjectResults } from "@/pages/ProjectsPage/hooks/useProjectResults";
import { IProjectResultsTableProps } from "@/pages/ProjectsPage/lib/types";
import { CardList } from "@/shared/CardList/CardList.styled";
import { DotMarkFail, DotMarkSuccess } from "@/shared/Dots/Dots.styled";
import { useOpenIncident } from "@/shared/Incident/hooks/useOpenIncident";
import { IncidentCreateButton } from "@/shared/Incident/ui/IncidentCreateButton/IncidentCreateButton";
import { useCheckpointsFilter } from "@/shared/InputSelect/hooks/useCheckpointsFilter";
import { formatNumber } from "@/shared/lib/heplers";
import { stickyTop } from "@/shared/ProjectResultsGroup/lib/helpers";
import { ProjectResultsGroup } from "@/shared/ProjectResultsGroup/ProjectResultsGroup";
import { Sceleton } from "@/shared/Sceleton/ui/Sceleton";
import { StickyTableCell } from "@/shared/TableBase/BasicTable.styled";

import {
  ProjectStatus,
  ProjectTable,
  ProjectTableCell,
  ProjectTitle,
  ProjectTitleText,
} from "../common/ProjectTable.styled";
import { generalInfo } from "./lib/constants";
import {
  GeneralInfoResult,
  GeneralInfoResultItem,
} from "./ProjectResultsTable.styled";

export const ProjectResultsTable: FC<IProjectResultsTableProps> = ({
  handleLegendCallback,
}) => {
  const { allCheckpoints, actualPeriodData } = useCheckpointsFilter();
  const reportDate = actualPeriodData?.attributeVals.REPORT_DATE || "";
  const { projectResultsFetching } = useProjectPage();

  const [activeResult, setActiveResult] = useState("");
  const [widthWrapper, setWidthWrapper] = useState("");
  const { columns, rows, activeIcon, changeContent, Select }: any =
    useProjectResults({
      handleLegendCallback,
    });
  const widthRef = useRef(null);
  const { move, handleMove, handleChange } = useOpenIncident();
  const headerValues = actualPeriodData?.indicators["129"].indicatorVals;

  const getResultId = (id: string) => {
    setActiveResult(id);
  };

  const getStyleAfterResize = () => {
    if (widthRef?.current) {
      setWidthWrapper(getComputedStyle(widthRef.current).width as string);
    }
  };

  useEffect(() => {
    const timeId = setTimeout(() => {
      getStyleAfterResize();
    }, 0);

    window.addEventListener("resize", getStyleAfterResize);

    return () => {
      clearTimeout(timeId);
      window.removeEventListener("resize", getStyleAfterResize);
    };
  }, [window, getStyleAfterResize]);

  return (
    <CardList
      ref={widthRef}
      onMouseEnter={() => handleMove(true)}
      onMouseLeave={() => handleMove(false)}
      sx={{ position: "relative" }}
    >
      <ProjectTitle>
        <ProjectTitleText isFlex={false}>Контрольные точки</ProjectTitleText>

        <GeneralInfoResult>
          {generalInfo.map((item) => (
            <GeneralInfoResultItem
              key={`${item.indicator}_${item.name}`}
              isBig={item.name === "Достижение"}
              colorValue={item?.color ? headerValues?.[item?.color]?.sum : null}
            >
              <span>{item.name}</span>

              {actualPeriodData && (
                <p>
                  {formatNumber(
                    headerValues?.[item.indicator]?.sum,
                    item.unit,
                    ![0, 100].includes(
                      headerValues?.[item.indicator]?.sum || 0
                    ) && item.name === "Достижение"
                      ? 1
                      : 0
                  )}
                </p>
              )}

              {!actualPeriodData && <p style={{ color: "#fff" }}>-</p>}
            </GeneralInfoResultItem>
          ))}
        </GeneralInfoResult>
        {move && (
          <IncidentCreateButton
            bigSize
            posRight={520}
            onClick={(event) => {
              handleChange(
                event,
                widthRef?.current,
                "Контрольные точки",
                reportDate
              );
            }}
          />
        )}
        {activeIcon === "1" && <Select />}
        {activeIcon === "2" && (
          <ProjectResultsSelect handleChangeCallback={getResultId} />
        )}
        <ProjectResultsGroup
          disabled={!Object.keys(allCheckpoints || {})?.length}
          handleChangeCallback={changeContent}
        />
      </ProjectTitle>

      {activeIcon === "1" ? (
        projectResultsFetching ? (
          <Sceleton type="line" heightLine={84} />
        ) : (
          <ProjectTable fullHeight horizontalBorders>
            <TableHead>
              <TableRow>
                {columns.map(({ id, label, ...column }: any) => (
                  <ProjectTableCell key={id} {...column} {...stickyTop(0)}>
                    {label}
                  </ProjectTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(
                (
                  row: {
                    label: any;
                    id: any;
                  }[],
                  rowIndex: React.Key | null | undefined
                ) => (
                  <TableRow key={rowIndex}>
                    {row.map(({ id }: any, colIndex: any) => (
                      <Fragment key={id}>
                        {id === "checkpoint-status" ? (
                          <StickyTableCell
                            key={id}
                            {...row[colIndex]}
                            style={{
                              verticalAlign: "top",
                              padding: "1.5rem 0",
                              ...(typeof row[colIndex]?.label !== "number" && {
                                padding: "1.37rem 0.75rem",
                              }),
                            }}
                          >
                            {typeof row[colIndex]?.label === "number" ? (
                              <ProjectStatus>
                                {row[colIndex]?.label ? (
                                  <DotMarkSuccess />
                                ) : (
                                  <DotMarkFail />
                                )}
                              </ProjectStatus>
                            ) : (
                              row[colIndex]?.label
                            )}
                          </StickyTableCell>
                        ) : (
                          <StickyTableCell
                            key={id}
                            {...row[colIndex]}
                            width={id === "checkpoint-name" ? 660 : undefined}
                            style={{
                              verticalAlign: "top",
                              padding: "1.37rem 0.75rem",
                            }}
                          >
                            {row[colIndex]?.label ?? "-"}
                          </StickyTableCell>
                        )}
                      </Fragment>
                    ))}
                  </TableRow>
                )
              )}
            </TableBody>
          </ProjectTable>
        )
      ) : (
        <ProjectResultsGraph
          resultId={activeResult}
          widthWrapper={widthWrapper}
        />
      )}
    </CardList>
  );
};
