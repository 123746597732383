export const gasuOrigin = "https://gasu-office.roskazna.ru";
export const uatOrigin = "https://gasu-office-test.roskazna.ru";
export const gasuPanelLink = `${gasuOrigin}/pages/armregions`;
export const uatPanelLink = `${uatOrigin}/pages/armregions`;
export const gasuCabinet = `${gasuOrigin}/pages/cabinet`;
export const nationId = "1015";
export const moduleName = "АРМ Регионы";
export const createdIncidentText = "Обращение создано";

export const createdGasuText = "Перейти в личный кабинет";
export const screenshotTitle =
  "Скриншот виджета, из которого создается обращение, делается автоматически и будет прикреплен в виде файла";

// Данные ниже константы необходимы для отправки обращения (обязательные)
export const serviceDev = "service$202459863";
export const serviceProd = "service$1545364214";
