export interface ICompareControl {
  isDark?: boolean;
  disabled?: boolean;
}

export enum COMPARE_TYPE {
  RF = "RF",
  FO = "FO",
}

export interface ICompareState {
  value: COMPARE_TYPE;
  disabled: boolean;
}
