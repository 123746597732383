import { styled } from "@mui/material";

import { colorBasedOnValue } from "@/pages/MainPage/lib/helpers";
import { ToolTipStyled } from "@/shared/Budget/ui/Budget.styled";

export const RiskWrapper = styled("div")(({ theme }) => ({
  height: "100%",
  width: "100%",
  padding: 0,
  margin: 0,
  background: theme.palette.background.paper,
}));

export const RiskLevelTitle = styled("div")(({ theme }) => ({
  color: theme.palette.text.unitTextSelected,
  fontSize: theme.spacing(2),
  fontWeight: 500,
  lineHeight: theme.spacing(2.5),
}));

export const RiskLevel = styled("span", {
  shouldForwardProp: (prop) => !["colorValue"].includes(prop.toString()),
})<{ colorValue?: number | null }>(({ theme, colorValue = null }) => ({
  color: colorBasedOnValue(theme, colorValue, false),
  fontSize: theme.spacing(2.75),
  lineHeight: theme.spacing(3.25),
  fontWeight: 700,
}));

export const RiskTitleWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(0.5),
}));

export const RiskLevelWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(0.5),
  "& > svg": {
    cursor: "pointer",
    fontSize: theme.spacing(2),
    color: theme.palette.text.unitTextSelected,
  },
}));

export const MethodRiskWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  fontSize: theme.spacing(2),
  color: theme.palette.text.unitTextSelected,
  "& > p": {
    fontSize: theme.spacing(2),
    lineHeight: theme.spacing(2.5),
  },
  "& > ul": {
    margin: 0,
    padding: 0,
    "& > li": {
      listStyle: "none",
      fontSize: theme.spacing(2),
      lineHeight: theme.spacing(2.5),
    },
  },
}));

export const RiskToolTipStyled = styled(ToolTipStyled)(({ theme }) => ({
  "& .MuiTooltip-tooltip": {
    maxWidth: theme.spacing(75),
    boxSizing: "border-box",
    fontSize: theme.spacing(2),
    padding: theme.spacing(1.5, 2),
    color: theme.palette.text.unitTextSelected,
    backgroundColor: theme.palette.toolTip.background,
    border: `1px solid ${theme.palette.toolTip.background}`,
    boxShadow: "0px 4px 8px -4px rgba(1, 59, 121, 0.08)",
    margin: "-18px !important",
    "&:first-of-type": {
      textAlign: "start",
    },
  },
  "& .MuiTooltip-arrow": {
    color: theme.palette.toolTip.background,
  },
}));

export const styleRiskCell = {
  fontSize: "1rem",
  lineHeight: "1.25rem",
  fontWeight: 500,
};
