import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTheme } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { SelectChangeEvent } from "@mui/material/Select";
import { useEffect, useMemo, useState } from "react";

import { useProjectPage } from "@/pages/ProjectsPage/hooks/useProjectPage";
import {
  MenuItemStyled,
  SelectStyled,
} from "@/shared/InputSelect/InputSelect.styled";

import { IProjectResultsSelectProps } from "../lib/types";
import { menuStyle } from "./ProjectsResultsGraph.styled";

export const ProjectResultsSelect = ({
  handleChangeCallback,
}: IProjectResultsSelectProps) => {
  const theme = useTheme();
  const { allResults, allCheckpoints } = useProjectPage();
  const [saveResultId, setSaveResultId] = useState<string>("");

  const actualCheckpointsResults = useMemo(() => {
    return Object.keys(allResults ?? {})?.filter((item) =>
      Object.keys(allCheckpoints ?? {})?.includes(item)
    );
  }, [allResults, allCheckpoints]);

  useEffect(() => {
    if (allResults && actualCheckpointsResults) {
      const firstKeyResults = actualCheckpointsResults?.[0];
      setSaveResultId(allResults?.[firstKeyResults]?.id);
      handleChangeCallback(allResults?.[firstKeyResults]?.id);
    }
  }, [allResults, setSaveResultId, actualCheckpointsResults]);

  const handleChange = (event: SelectChangeEvent<any>) => {
    setSaveResultId(event.target.value as string);
    handleChangeCallback(event.target.value);
  };

  return (
    <FormControl>
      <SelectStyled
        value={saveResultId}
        IconComponent={KeyboardArrowDownIcon}
        onChange={handleChange}
        MenuProps={{ sx: menuStyle(theme) }}
      >
        {actualCheckpointsResults.map((result) => (
          <MenuItemStyled
            key={(allResults?.[result] as any)?.id}
            value={(allResults?.[result] as any)?.id}
          >
            {(allResults?.[result] as any)?.attributeVals?.name}
          </MenuItemStyled>
        ))}
      </SelectStyled>
    </FormControl>
  );
};
