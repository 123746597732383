import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { IRegionsState } from "@/shared/lib/types";
import {
  IAttributeVals,
  IOlapResponseDimensionItemDimWithVals,
  MODEL_IDS,
  MODELS,
} from "@/store/scheme/olap";

import { convertFio } from "../lib/helpers";
import { getAllVDL } from "./api";

export const initialState: IRegionsState = {
  data: undefined,
  fetching: false,
  error: null,
};

export const extraActionsAllVdl = {
  get: createAsyncThunk("regions/AllVDL/get", async () => await getAllVDL()),
};

const allVdlSlice = createSlice({
  name: "allVdl",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActionsAllVdl.get.pending, (state) => {
        state.data = undefined;
        state.fetching = true;
      })
      .addCase(extraActionsAllVdl.get.fulfilled, (state, action) => {
        const model = MODELS[MODEL_IDS.VDL];

        state.data = (
          action.payload as unknown as IOlapResponseDimensionItemDimWithVals<IAttributeVals>[]
        ).reduce((prev: Record<string, Record<string, string>>, cur: any) => {
          const dimIdCode = cur?.dims?.[model.dimId];
          const dataActualIdCode = cur?.dims?.[model.dataActualId]?.code;

          if (dataActualIdCode === "2") {
            const dataRegionId = cur?.dims?.[model.dataRegionId];

            if (!prev[dataRegionId.code]) {
              prev[dataRegionId.code] = {};
            }
            const leader = cur?.dims?.[model.dataRegionLeader]?.attributeVals;

            prev[dataRegionId.code] = {
              regionId: dataRegionId.code,
              leaderFio: convertFio(leader?.FIO || ""),
              leaderPhoto: leader?.photo,
              parentRegionId: dataRegionId?.parentId[0],
              reportDate: cur?.attributeVals["REPORT_DATE"],
              regionName: dataRegionId?.attributeVals["SHORT_NAME"],
              indicatorName: dimIdCode?.attributeVals["short_name"],
              indicatorUnit: dimIdCode?.attributeVals["unit_measure"],
              factValue: (cur?.indicatorVals || {})?.[model.indexes.fact]?.sum,
            };
          }
          return { ...prev };
        }, {});

        state.fetching = false;
        state.error = null;
      })
      .addCase(extraActionsAllVdl.get.rejected, (state, action) => {
        state.fetching = false;
        state.error = action.error;
      });
  },
});

export const reducer = allVdlSlice.reducer;

export default allVdlSlice;
