import { TAB_ID } from "@store/scheme/region";
import { useRef, useState } from "react";
import { useLocation, useParams } from "react-router";

import { useRouter } from "@/app/hooks/useRouter";
import { defineTabs } from "@/pages/InsideMainPage/lib/constants";
import { IAchievementTabsProps } from "@/pages/InsideMainPage/lib/types";
import { AverageAchievementTable } from "@/pages/MainPage/ui/Tables/AverageAchievementTable";
import { AverageKeysIndicatorsAndProjectsTable } from "@/pages/MainPage/ui/Tables/AverageKeysIndicatorsAndProjectsTable";
import { AverageKeysIndicatorsTable } from "@/pages/MainPage/ui/Tables/AverageKeysIndicatorsTable";
import { AverageRiskAndProjectsTable } from "@/pages/MainPage/ui/Tables/AverageRiskAndProjectsTable";
import { useOpenIncident } from "@/shared/Incident/hooks/useOpenIncident";
import { IncidentCreateButton } from "@/shared/Incident/ui/IncidentCreateButton/IncidentCreateButton";
import { isDevBranch } from "@/shared/lib/constants";
import { useFuturePlan } from "@/shared/Table/hooks/useFuturePlan";

import { AchievementNavigateWrapper } from "../InsideMainPageButtons/AchievementNavigateWrapper";
import {
  TabPanelTable,
  TabPanelWrapper,
  TabsListUnstyled,
  TabsWrapper,
  TabUnstyled,
} from "../InsideMainPageTabs.styled";

export const AchievementEnableTabs = ({
  isShowExport,
}: IAchievementTabsProps) => {
  const [openPopUp, setOpenPopUp] = useState(false);
  const { tabName = TAB_ID.VDL_PROJECTS } = useParams<"tabName">();
  const [tabValue, setTabValue] = useState(tabName);
  const location = useLocation();
  const { navigate } = useRouter();
  const showRef = useRef<HTMLDivElement | null>(null);
  const { isCheckRiskFuturePlan } = useFuturePlan();
  const { handleChange } = useOpenIncident();
  const postionIncident = () => {
    return tabValue === TAB_ID.RISKS ? 138 : !isShowExport ? 294 : 356;
  };

  const handleClosePopUp = () => {
    setOpenPopUp(!openPopUp);
  };

  const handleChangeNavigate = (
    event: React.SyntheticEvent<Element, Event> | null,
    value: string | number | boolean | null
  ) => {
    setTabValue(value as TAB_ID);
    navigate(`/achievementLevel/${value}`, {
      state: {
        ...location?.state,
      },
    });
    setOpenPopUp(false);
  };

  return (
    <TabsWrapper
      ref={showRef}
      value={tabValue}
      onChange={handleChangeNavigate}
      isRisks={tabValue === TAB_ID.RISKS}
    >
      <AchievementNavigateWrapper
        handleClosePopUp={handleClosePopUp}
        isNotTableRisks={tabValue !== TAB_ID.RISKS}
        isShowExport={isShowExport}
      />
      <TabsListUnstyled>
        <TabUnstyled value={TAB_ID.VDL_PROJECTS} afterElement>
          Достижение по целям развития экономики и проектам
        </TabUnstyled>

        <TabUnstyled value={TAB_ID.VDL}>
          Достижение по целям развития экономики
        </TabUnstyled>

        <TabUnstyled value={TAB_ID.PROJECTS}>
          Достижение по проектам
        </TabUnstyled>
        {isCheckRiskFuturePlan && isDevBranch && (
          <TabUnstyled value={TAB_ID.RISKS} afterElement isRisks>
            Риски реализации проектов
          </TabUnstyled>
        )}
      </TabsListUnstyled>

      <IncidentCreateButton
        bigSize
        className="hoverIncident"
        posRight={postionIncident()}
        onClick={(event) => {
          handleChange(event, showRef?.current, defineTabs[tabValue]);
        }}
      />

      <TabPanelTable value={TAB_ID.VDL_PROJECTS}>
        <TabPanelWrapper>
          <AverageKeysIndicatorsAndProjectsTable
            openColumnsModal={openPopUp}
            closeColumnsModal={handleClosePopUp}
          />
        </TabPanelWrapper>
      </TabPanelTable>

      <TabPanelTable value={TAB_ID.VDL}>
        <TabPanelWrapper>
          <AverageKeysIndicatorsTable
            openColumnsModal={openPopUp}
            closeColumnsModal={handleClosePopUp}
          />
        </TabPanelWrapper>
      </TabPanelTable>

      <TabPanelTable value={TAB_ID.PROJECTS}>
        <TabPanelWrapper>
          <AverageAchievementTable
            openColumnsModal={openPopUp}
            closeColumnsModal={handleClosePopUp}
          />
        </TabPanelWrapper>
      </TabPanelTable>
      {isCheckRiskFuturePlan && isDevBranch && (
        <TabPanelTable value={TAB_ID.RISKS}>
          <TabPanelWrapper>
            <AverageRiskAndProjectsTable
              openColumnsModal={openPopUp}
              closeColumnsModal={handleClosePopUp}
            />
          </TabPanelWrapper>
        </TabPanelTable>
      )}
    </TabsWrapper>
  );
};
