import { Theme } from "@mui/material";
import { graphic } from "echarts";

import { formatNumber } from "@/shared/lib/heplers";

export const getTextStyle = {
  fontSize: 14,
  lineHeight: 16,
};

export const getLabelStyle = (
  theme: Theme,
  isIndicatorPeople?: boolean,
  fractionDigits?: number,
  typeName?: string
) => {
  return {
    show: true,
    fontSize: 16,
    fontWeight: theme.typography.fontWeightRegular,
    formatter: ({ value }: any) =>
      formatNumber(value, undefined, fractionDigits),
    position: isIndicatorPeople ? "bottom" : "top",
    offset: [10, 6],
    color:
      theme.palette.graphs[
        typeName === "childFact" ? "factLabel" : "planLabel"
      ],
  };
};

export const getXaxisLabelStyle = (theme: Theme, isVertical?: boolean) => ({
  fontSize: isVertical ? 12 : 14,
  lineHeight: isVertical ? 16 : 18,
  fontWeight: 500,
  color:
    theme.palette.mode === "dark" && !isVertical
      ? theme.palette.text.lightGrey
      : theme.palette.button.achievementText,
  verticalAlign: isVertical ? "top" : "center",
});

export const xAxisOptions = () => ({
  type: "category",
  boundaryGap: false,
  axisLine: { show: false },
  axisTick: { show: false },
  alignTicks: true,
  axisLabel: {
    show: true,
    align: "center",
    interval: 0,
    hideOverlap: false,
  },
});

export const getFormatterByTop = (value: string) => {
  const [factRegion, changeFact, fractionDigits] = value?.split(",");
  return [
    `{titleFactRegion|${formatNumber(
      factRegion,
      undefined,
      +fractionDigits
    )}}{titleBg|}`,
    `{hr|}`,
    `{titleFactDistrict|${formatNumber(
      changeFact,
      undefined,
      +fractionDigits
    )}}{titleBg|}`,
  ].join("\n");
};

export const richAxisLabel = (theme: Theme) => {
  const rich = {
    titleBg: {
      padding: [10, 0],
    },
    hr: {
      borderColor: theme.palette.graphs.factLabel,
      borderWidth: 0.5,
      width: "100%",
      height: 1,
      align: "start",
    },
    titleFactRegion: {
      color: theme.palette.graphs.factLabel,
      fontSize: 16,
    },
    titleFactDistrict: {
      color: theme.palette.graphs.planLabel,
      fontSize: 16,
    },
  };
  return rich;
};

export const prepareGraphOptions = (
  theme: Theme,
  isYaxis: boolean,
  type: string = "line",
  typeChild: string | undefined = undefined,
  legendChildName: string = "",
  legendParentdName: string = "",
  isRF: boolean = false
) => {
  const options = {
    tooltip: {
      showContent: false,
      trigger: "none",
    },
    grid: {
      top: "8%",
      bottom: "23%",
      ...(isYaxis && { right: "4%", left: "4%" }),
      containLabel: true,
    },
    // Периоды
    xAxis: {
      type: "category",
      boundaryGap: type === "bar",
      splitLine: {
        show: true,
        lineStyle: { color: theme.palette.graphs.graphLine },
      },
      alignTicks: true,
      axisLine: { show: typeChild },
      axisTick: { show: false },
      offset: typeChild ? 0 : 25,
    },
    // Легенда берется из пропсов
    legend: {
      icon: "circle",
      bottom: "4%",
      data: [legendChildName, legendParentdName],
      textStyle: {
        color: theme.palette.text.primary,
        ...getTextStyle,
      },
      selectedMode: false,
    },
    // Боковые панели для индикаторов из isYAxisIndicator (для субъектов и ФО) и defaultIrregularIndicators (для РФ)
    ...(isYaxis
      ? {
          yAxis: [
            ...(!!legendChildName
              ? [
                  {
                    type: "value",
                    ...(!(typeChild || isRF) && {
                      scale: true,
                    }),
                    position: "left",
                    offset: typeChild ? 0 : 35,
                    axisLabel: {
                      fontSize: "14px",
                      color: theme.palette.graphs.graphHoverLine,
                      formatter: (value: string) =>
                        formatNumber(value, undefined, 0),
                    },
                    axisLine: {
                      onZero: true,
                      show: true,
                      lineStyle: {
                        ...getTextStyle,
                        fontWeight: 500,
                        color:
                          theme.palette.graphs[
                            isRF ? "graphsStart" : "graphHoverLine"
                          ],
                      },
                    },
                    alignTicks: true,
                    splitLine: { show: false },
                    axisTick: {
                      show: true,
                    },
                  },
                ]
              : [{}]),
            ...(!!legendParentdName && type !== "bar"
              ? [
                  {
                    type: "value",
                    scale: true,
                    position: "right",
                    offset: 35,
                    axisLabel: {
                      fontSize: "14px",
                      color: theme.palette.graphs.graphHoverLine,
                      formatter: (value: string) =>
                        formatNumber(value, undefined, 0),
                    },
                    axisLine: {
                      show: true,
                      lineStyle: {
                        ...getTextStyle,
                        fontWeight: 500,
                        color: theme.palette.graphs.graphsStart,
                      },
                    },
                    alignTicks: true,
                    splitLine: { show: false },
                    axisTick: {
                      show: true,
                    },
                  },
                ]
              : [{}]),
          ],
        }
      : {
          yAxis: [
            {
              type: "value",
              axisLabel: { show: false },
              axisLine: { show: false },
              splitLine: { show: false },
            },
          ],
        }),
    series: [
      ...(!!legendChildName
        ? [
            {
              name: legendChildName,
              typeName: "childFact",
              type: typeChild || type,
              silent: true,
              symbolSize: 8,
              symbol: "circle",
              itemStyle: { color: theme.palette.text.primary },
              lineStyle: { width: 3 },
              ...(isYaxis && { yAxisIndex: 0 }),
              ...(type === "bar" &&
                !typeChild && {
                  barWidth: "49%",
                  itemStyle: {
                    color: new graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0.3,
                        color:
                          theme.palette.graphs[
                            isRF
                              ? "graphsBarDistrictStart"
                              : "graphsBarRegionStart"
                          ],
                      },
                      {
                        offset: 1,
                        color:
                          theme.palette.graphs[
                            isRF ? "graphsBarDistrictEnd" : "graphsBarRegionEnd"
                          ],
                      },
                    ]),
                    borderRadius: [2, 2, 0, 0],
                  },
                }),
              ...(typeChild && {
                symbol: "rect",
                symbolSize: [56, 24],
                label: {
                  align: "center",
                  verticalAlign: "center",
                  fontWeight: 500,
                },
              }),
            },
          ]
        : []),
      ...(!!legendParentdName
        ? [
            {
              name: legendParentdName,
              typeName: "parentFact",
              type,
              silent: true,
              ...(isYaxis && type !== "bar" && { yAxisIndex: 1 }),
              ...(type === "bar" && {
                barWidth: typeChild ? "30%" : "50%",
                barGap: "1%",
                itemStyle: {
                  color: new graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0.3,
                      color: theme.palette.graphs["graphsBarDistrictStart"],
                    },
                    {
                      offset: 1,
                      color: theme.palette.graphs["graphsBarDistrictEnd"],
                    },
                  ]),
                  borderRadius: [2, 2, 0, 0],
                },
              }),
              ...(type !== "bar" && {
                symbol: "pin",
                symbolSize: 0,
                lineStyle: { width: 0 },
                areaStyle: {
                  color: new graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0.3,
                      color: theme.palette.graphs["graphsStart"],
                    },
                    {
                      offset: 1,
                      color: theme.palette.graphs["graphsEnd"],
                    },
                  ]),
                },
              }),
            },
          ]
        : []),
    ],
  };
  return options;
};
