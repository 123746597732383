import TableChartIcon from "@mui/icons-material/TableChart";
import { TAB_ID } from "@store/scheme/region";
import { FC, useEffect } from "react";
import { useLocation, useParams } from "react-router";

import { useRouter } from "@/app/hooks/useRouter";
import { GRAPH_LEVEL } from "@/pages/RegionGraphsPage/lib/constants";
import { useTransitionPage } from "@/shared/Table/hooks/useTransitionPage";

import { MapButtonStyled } from "../InsideMainPageTabs.styled";

export const ButtonTableBack: FC = () => {
  const { id } = useParams<"id">();
  const { navigate } = useRouter();
  const location = useLocation();
  const { resetSubjectId, navigateParams, getTabName } = useTransitionPage();

  useEffect(() => {
    if (id) {
      resetSubjectId();
    }
  }, [id]);

  const checkLocationPath = () => {
    if (location.pathname?.match(/graphs/gim)) {
      const tabId = getTabName(location.pathname);
      return tabId === GRAPH_LEVEL.RESULT_PROJECTS ? TAB_ID.PROJECTS : null;
    }
    return;
  };

  const handleNavigate = () => {
    const checkCurrentLocation =
      location?.pathname?.match(/^\/achievementLevel/gi);
    if (!checkCurrentLocation) {
      const tabName = checkLocationPath();
      const path = `/achievementLevel/${tabName || TAB_ID.VDL_PROJECTS}`;
      navigate(path, {
        state: {
          ...location?.state,
          ...navigateParams(id),
        },
      });
    }
  };

  return (
    <MapButtonStyled onClick={handleNavigate}>
      <TableChartIcon />
    </MapButtonStyled>
  );
};
