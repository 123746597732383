import { Fragment } from "react";

import { Loader } from "../Loader/Loader";
import { Wrapper } from "./Block.styled";
import { BlockWpapperProps } from "./lib/types";

export const BlockWpapper = ({
  sx,
  children,
  isFetching = false,
  hasData,
  noDataText = "При загрузке данных произошла ошибка. Пожалуйста, обновите страницу.",
}: BlockWpapperProps) => {
  if (isFetching) {
    return (
      <Wrapper>
        <Loader />
      </Wrapper>
    );
  }

  if (!hasData) {
    return <Wrapper sx={sx}>{noDataText}</Wrapper>;
  }

  return <Fragment>{children}</Fragment>;
};
