import { Modal } from "@mui/material";
import React from "react";

import { ModalWrapperProps } from "./lib/types";
import { ModalWrapperStyled } from "./Modal.styled";

export const ModalWrapper = ({
  open,
  onClose,
  children,
  width,
  height,
}: ModalWrapperProps) => {
  return (
    <Modal open={open} onClose={onClose}>
      <ModalWrapperStyled widthsize={width} heightsize={height}>
        {children}
      </ModalWrapperStyled>
    </Modal>
  );
};
