import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";

import { useRegion } from "@/pages/RegionPage/hooks/useRegion";
import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { REGION_COUNTRY_CODE } from "@/shared/lib/constants";
import { MODEL_IDS, MODELS } from "@/store/scheme/olap";
import { IModels } from "@/store/scheme/olap";
import { indicatorsEnterprises } from "@/widgets/IndicatorPassport/lib/constants";

import {
  absoluteUnits,
  budgetGraphIndicators,
  kktGraphIndicators,
  kpiGraphIndicators,
  PERIOD_GRAPH,
  PERIOD_INDICATOR_END,
  PERIOD_INDICATOR_START,
} from "../lib/constants";
import { comparePeriods, convertByUnit, defaultPeriods } from "../lib/helpers";
import { IIndicatorGraph } from "../lib/types";
import { useBudgetGraph } from "./useBudgetGraph";
import { useKktGraph } from "./useKktGraph";
import { useKpiGraph } from "./useKpiGraph";

export const useTotalGraph = (indicatorId: string | null) => {
  const { pathname } = useLocation();
  const pathId = pathname?.match(/\d+/gim)?.join();
  const { getDistrictByRegion, isCheckRfFoPage } = useRegions();
  const region = useRegion(pathId!);
  const district = getDistrictByRegion(region?.id!);
  const { requestKpiGraph, kpiGraphData, kpiGraphFetching } = useKpiGraph();
  const { requestBudgetGraph, budgetGraphData, budgetGraphFetching } =
    useBudgetGraph();
  const { requestKktGraph, kktGraphData, kktGraphFetching } = useKktGraph();
  const [model, setModel] = useState<IModels[MODEL_IDS]>(
    {} as IModels[MODEL_IDS]
  );
  const [fetching, setFetching] = useState<boolean>(false);

  const requestByIndicator = useMemo(() => {
    let defaultRequest: ((args: IIndicatorGraph) => void) | null = null;
    if (indicatorId) {
      switch (true) {
        case kpiGraphIndicators.includes(indicatorId):
          return requestKpiGraph;
        case budgetGraphIndicators.includes(indicatorId):
          return requestBudgetGraph;
        case kktGraphIndicators.includes(indicatorId):
          return requestKktGraph;
        default:
          return defaultRequest;
      }
    }
    return defaultRequest;
  }, [indicatorId]);

  useEffect(() => {
    if (pathId && indicatorId && requestByIndicator) {
      requestByIndicator(pathId, district, indicatorId);
    }
  }, [indicatorId, requestByIndicator, pathId, district?.id]);

  const graphData = useMemo(() => {
    if (indicatorId) {
      switch (true) {
        case kpiGraphIndicators.includes(indicatorId):
          setModel(MODELS[MODEL_IDS.KPI_SOC_ECONOMY]);
          setFetching(kpiGraphFetching);
          return kpiGraphData;
        case budgetGraphIndicators.includes(indicatorId):
          setModel(MODELS[MODEL_IDS.BUDGET]);
          setFetching(budgetGraphFetching);
          return budgetGraphData;
        case kktGraphIndicators.includes(indicatorId):
          setModel(MODELS[MODEL_IDS.KKT]);
          setFetching(kktGraphFetching);
          return kktGraphData;
        default:
          return {};
      }
    }
    return {};
  }, [
    indicatorId,
    kpiGraphData,
    budgetGraphData,
    kktGraphData,
    kktGraphFetching,
    kpiGraphFetching,
    budgetGraphFetching,
  ]);

  const [findMaxPeriodByCompare, compareStart, compareEnd] = useMemo(() => {
    const defaultComparePeriod = comparePeriods(indicatorId);
    // По индикатору находим начало строки периода
    const compareStart = defaultComparePeriod
      ? PERIOD_INDICATOR_START[defaultComparePeriod as unknown as PERIOD_GRAPH]
      : "";
    // По индикатору находим конец строки периода
    const compareEnd = defaultComparePeriod
      ? PERIOD_INDICATOR_END[defaultComparePeriod as unknown as PERIOD_GRAPH]
      : "";

    if (compareStart && compareEnd && graphData?.[indicatorId!!]) {
      const currentData = Object.keys(
        graphData?.[indicatorId!!]?.[pathId!!] || {}
      );
      const districtData = Object.keys(
        graphData?.[indicatorId!!]?.[district?.id!!] || {}
      );
      const rfData = Object.keys(
        graphData?.[indicatorId!!]?.[REGION_COUNTRY_CODE] || {}
      );
      // Нахождение нужных периодов по началу и концу строки
      const reg = new RegExp("^" + compareStart + "\\w+" + compareEnd + "$");
      // Формирование периодов из всех поступивших данных
      const periods = Array.from(
        new Set([...currentData, ...districtData, ...rfData])
      );
      // Фильтрация периодов
      const filterPeriods = periods
        ?.filter((period) => {
          return period.match(reg);
        })
        ?.map((period) => +period);
      // Нахождение актуального периода
      const maxPeriod = Math.max(...filterPeriods);
      return [maxPeriod, compareStart, compareEnd];
    }
    return [0, compareStart, compareEnd];
  }, [graphData?.[indicatorId!!], pathId, district?.id]);

  const divideValues = indicatorsEnterprises.includes(indicatorId!!)
    ? 1000
    : ["31", "54"].includes(indicatorId!!) && isCheckRfFoPage.isAll
    ? 1000000
    : 0;

  const [prepareData, xAxisPeriods] = useMemo(() => {
    const [periods, xAxisPeriods] = defaultPeriods(
      findMaxPeriodByCompare,
      compareStart,
      compareEnd
    );
    const graphDataVyIndicator = graphData?.[indicatorId!!];

    const prepareData = periods?.map((period) => {
      const region = graphDataVyIndicator?.[pathId!!]?.[period];
      const FO = graphDataVyIndicator?.[district?.id!!]?.[period];
      const RF = graphDataVyIndicator?.[REGION_COUNTRY_CODE]?.[period];
      const unit = region?.["UNIT"] || FO?.["UNIT"] || RF?.["UNIT"];

      let indicatorRating: null | string = null;
      let factRF = null;
      let factDistrict = null;

      if (model.modelId === MODEL_IDS.KPI_SOC_ECONOMY && indicatorId === "7") {
        indicatorRating =
          region?.indicatorVals?.[model?.indexes?.factChangeYearStart];
      } else {
        factRF = convertByUnit(
          RF?.indicatorVals?.[model?.indexes?.fact],
          divideValues
        );
        factDistrict = convertByUnit(
          FO?.indicatorVals?.[model?.indexes?.fact],
          divideValues
        );
      }

      const fractionDigits =
        indicatorsEnterprises.includes(indicatorId!!) ||
        absoluteUnits.includes(unit)
          ? 0
          : 1;
      const factRegion = convertByUnit(
        region?.indicatorVals?.[model?.indexes?.fact],
        divideValues
      );

      return {
        period,
        indicatorRating,
        factRegion,
        factRF,
        factDistrict,
        fractionDigits,
      };
    });
    return [prepareData, xAxisPeriods];
  }, [
    findMaxPeriodByCompare,
    compareStart,
    compareEnd,
    graphData?.[indicatorId!!],
    divideValues,
    indicatorId,
    pathId,
    district?.id,
    model,
  ]);

  return { graphData, prepareData, xAxisPeriods, fetching, pathId };
};
