import { styled } from "@mui/material";

export interface IPropmpTitleStyledProps {
  achEnabled?: boolean;
}

export const MainGrid = styled("div")(({ theme }) => ({
  boxSizing: "border-box",
  maxHeight: "100%",
  display: "grid",
  gridTemplateRows: "12.5rem auto",
  gap: theme.spacing(2, 0),
  padding: theme.spacing(3, 5, 2),
}));

export const MapGrid = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "32.25rem 1fr",
  gap: theme.spacing(3),
  padding: theme.spacing(4, 3, 3),
  background: theme.palette.tabs.backgroundContent,
  borderRadius: theme.spacing(2),
}));

export const VdlWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexFlow: "column",
  justifyContent: "space-between",
}));

export const MapWrapper = styled("div")(({ theme }) => ({
  overflow: "hidden",
  position: "relative",
}));

export const BackLink = styled("span")(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(2),
  left: 0,
  fontSize: 14,
  textDecorationLine: "underline",
  color: theme.palette.appBar.logoColor,
  cursor: "pointer",
}));

export const MiniMap = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 0,
  right: 0,
  padding: theme.spacing(1, 4),
  background: "rgba(7, 146, 190, 0.1)",
  border: `1px solid ${theme.palette.table.rowBorder}`,
  borderRadius: theme.spacing(),
  cursor: "pointer",
}));
