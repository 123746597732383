import { styled } from "@mui/material";

export const WrapperScrollbar = styled("div")<{ darkTheme?: boolean }>(
  ({ theme, darkTheme }) => ({
    height: "100%",
    paddingRight: darkTheme ? theme.spacing(3) : 0,
    ".track": {
      borderRadius: theme.spacing(0.375),
      background: theme.palette.scrollbar.track,
      "&.vertical": {
        right: darkTheme ? `-${theme.spacing(3)}` : `-${theme.spacing(2)}`,
        bottom: theme.spacing(0.25),
        top: theme.spacing(0.25),
        zIndex: 100,
      },
      "&.horizontal": {
        right: theme.spacing(0.25),
        bottom: theme.spacing(0.25),
        left: theme.spacing(0.25),
        zIndex: 100,
      },
    },
    ".thumb": {
      cursor: "pointer",
      borderRadius: "inherit",
      background: theme.palette.scrollbar.thumb,
    },
    ".scroll-view": {
      scrollbarWidth: "none",
      "&::-webkit-scrollbar": {
        width: 0,
      },
    },
  })
);

export const CustomScrollbarWrapper = styled("div")<{ isOpenPopUp?: boolean }>(
  ({ theme, isOpenPopUp }) => ({
    width: "100%",
    height: "100%",
    ".track": {
      borderRadius: theme.spacing(0.375),
      background: theme.palette.scrollbar.track,
      "&.vertical": {
        right: theme.spacing(0.625),
        bottom: theme.spacing(0.5),
        top: theme.spacing(0.5),
        zIndex: 100,
      },
      "&.horizontal": {
        right: theme.spacing(0.25),
        bottom: theme.spacing(0.25),
        left: theme.spacing(0.25),
        zIndex: 100,
      },
    },
    ".thumb": {
      cursor: "pointer",
      borderRadius: "inherit",
      background: theme.palette.scrollbar.thumb,
    },
    ".scroll-view": {
      ...(!isOpenPopUp && { marginRight: "16px !important" }),
      ...(!isOpenPopUp && { marginBottom: "-7px !important" }),
      scrollbarWidth: "none",
      "&::-webkit-scrollbar": {
        width: 0,
      },
    },
  })
);
