import { FC } from "react";

import { IControl } from "../lib/types";
import { ToggleButtonGroupStyled } from "./Control.styled";

export const Control: FC<IControl> = ({ children, ...props }) => (
  <ToggleButtonGroupStyled color="primary" {...props}>
    {children}
  </ToggleButtonGroupStyled>
);
