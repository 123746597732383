import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { IProjectAttributeVals, MODEL_IDS, MODELS } from "@/store/scheme/olap";

import { ISumRisksRfFoState, ISumRisksTotal } from "../../lib/types";
import { getSumRisksRFFo } from "../api/sum_risks_rf_fo";

export const initialState: ISumRisksRfFoState = {
  sumRfFoRisks: undefined,
  fetching: true,
  error: null,
};

export const extraActionsSumRfFoRisks = {
  get: createAsyncThunk(
    "sum_risks/getRfFo",
    async ({ dataSummaryId, dataRegionId }: Record<string, string>) =>
      await getSumRisksRFFo({ dataSummaryId, dataRegionId })
  ),
};

const sumRfFoRisksSlice = createSlice({
  name: "sum_risks",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActionsSumRfFoRisks.get.pending, (state) => {
        state.sumRfFoRisks = undefined;
        state.fetching = true;
      })
      .addCase(extraActionsSumRfFoRisks.get.fulfilled, (state, action) => {
        const model = MODELS[MODEL_IDS.RISKS];
        state.sumRfFoRisks = action.payload.reduce((prev, cur) => {
          if (!prev) prev = {};
          const regionId = cur?.dims[model.dataRegionId]?.code;
          if (!prev[regionId]) prev[regionId] = {};
          const projectId = cur?.dims[model.dataProjectId]?.code;
          if (!prev[regionId][projectId]) prev[regionId][projectId] = {};
          const indicatorId = cur?.dims[model.dataResultId]?.code;
          if (!prev[regionId][projectId][indicatorId])
            prev[regionId][projectId][indicatorId] = {};
          prev[regionId][projectId][indicatorId] = {
            regionName: (
              cur?.dims[model.dataRegionId]
                ?.attributeVals as IProjectAttributeVals
            )?.name,
            regionId: cur?.dims[model.dataRegionId]?.code,
            attributeVals: cur?.attributeVals,
            indicatorVals: Object.fromEntries(
              Object.entries(
                cur.dims[model.dataResultId]?.indicatorVals || {}
              ).map(([key, value]) => [key, value?.sum])
            ) as any,
          };
          return prev;
        }, {} as ISumRisksTotal);
        state.fetching = false;
        state.error = null;
      })
      .addCase(extraActionsSumRfFoRisks.get.rejected, (state, action) => {
        state.fetching = false;
        state.error = action.error;
      });
  },
});

export const reducer = sumRfFoRisksSlice.reducer;

export default sumRfFoRisksSlice;
