import {
  SceletonCircleStyled,
  SceletonLine1Styled,
} from "../../Sceleton.styled";
import {
  SceletonCircleContent,
  SceletonCircleWrapper,
} from "./SceletonCirle.styled";

export const SceletonCircle = () => {
  return (
    <SceletonCircleWrapper>
      <SceletonCircleStyled />

      <SceletonCircleContent>
        <SceletonLine1Styled widthLine={"5.8rem"} />
        <SceletonLine1Styled widthLine={"3.8rem"} />
      </SceletonCircleContent>
    </SceletonCircleWrapper>
  );
};
