import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Divider, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router";

import { useRisksTransition } from "@/pages/MainPage/hooks/useRisksTransition";
import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { useRegionPageTabs } from "@/pages/RegionPage/ui/RegionTabs/hooks/useRegionPageTabs";
import { usePeriod } from "@/shared/PeriodControl/hooks/usePeriod";
import { periodIsUpdate } from "@/shared/PeriodControl/model/periodSlice";
import { Popover } from "@/shared/Popover/ui/Popover";
import { useAppDispatch, useAppSelector } from "@/store/redux/hooks";
import { TAB_ID } from "@/store/scheme/region";
import { MIN_YEAR } from "@/widgets/ExportControl/lib/constants";

import {
  minPeriod,
  minPeriodWithRfFo,
  minPeriodWithRisks,
} from "../../lib/constants";
import {
  MenuItemStyled,
  PopoverStyled,
  PopoverStyledHeader,
  TextStyled,
  WrapperStack,
  YearChange,
} from "./AppBarPeriodFilter.styled";

export const AppBarPeriodFilter = () => {
  const { pathname } = useLocation();
  const { user } = useAppSelector((state) => state.auth.user);
  const { minYear, checkRisksTable } = useRisksTransition();
  const { updateTabAndSetUrl } = useRegionPageTabs();
  const theme = useTheme();
  const today = moment().format("DD.MM.YYYY");
  const todayYear = moment().year();
  const {
    period: { currentPeriod },
    achievement,
  } = useAppSelector((state) => state.controls);
  const [year, setYear] = useState(() => {
    return currentPeriod !== "0" ? +currentPeriod?.slice(1, 5) : todayYear;
  });
  const [maxYear, setMaxYear] = useState(MIN_YEAR);
  const { arrPeriodData, handleClick } = usePeriod();
  const { isCheckRfFoPage } = useRegions();
  const periodFilterRef = useRef(null);

  const initialValue =
    currentPeriod === "0"
      ? today
      : (arrPeriodData?.[year] ?? []).find((x) => x?.id === currentPeriod)
          ?.name || today;

  const [selectValue, setSelectValue] = useState<string>(initialValue);
  const [isOpen, setOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const periods = Object.keys(arrPeriodData || {});
    if (periods?.length) {
      setMaxYear(+periods[periods.length - 1]);
    }
  }, [arrPeriodData]);

  const handleChandePeriod = (type: "next" | "prev") => {
    const sign = type === "prev" ? year - 1 : year + 1;
    setYear(sign);
  };
  // Принудительный переход для роли аналитика из Частных инвестиций если период > 320220003 или 0
  const forcedUrl = (periodId: string) => {
    if (
      pathname.startsWith("/region") &&
      pathname.endsWith("/investment") &&
      user?.isAnalyticFK &&
      (+periodId > 320220003 || +periodId === 0)
    ) {
      return updateTabAndSetUrl({
        tabByUrl: TAB_ID.VDL_PROJECTS,
        tabByUpdate: TAB_ID.VDL_PROJECTS,
      });
    }
    return;
  };

  const handleChange = (event: React.MouseEvent<HTMLElement>) => {
    const value =
      arrPeriodData?.[year]?.find(
        (period) => period?.id === event.currentTarget.id
      ) || event.currentTarget.id;

    if (value) {
      const isValueObject = value instanceof Object;
      const name = isValueObject ? value?.name : today;
      const id = isValueObject ? value?.id : "0";

      setSelectValue(name);
      handleClick(id);
      dispatch(periodIsUpdate(false));
      setOpen(false);
      forcedUrl(id);
    }
  };

  const availablePeriods = useMemo(() => {
    const availableMinPeriod = isCheckRfFoPage.isAll
      ? minPeriodWithRfFo
      : checkRisksTable && achievement.enabled
      ? minPeriodWithRisks
      : minPeriod;
    return (arrPeriodData?.[year] || [])?.filter(
      (period) => +period?.id >= availableMinPeriod
    );
  }, [
    arrPeriodData,
    year,
    isCheckRfFoPage.isAll,
    pathname,
    achievement.enabled,
    checkRisksTable,
  ]);

  useEffect(() => {
    if (checkRisksTable && year < minYear) {
      setYear(minYear);
    }
  }, [checkRisksTable, year, minYear]);

  return (
    <WrapperStack direction="row">
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        flex={1}
        sx={{ overflow: "hidden" }}
      >
        <TextStyled ref={periodFilterRef} onClick={() => setOpen(!isOpen)}>
          {selectValue}

          {!isOpen && <KeyboardArrowDownIcon />}
          {isOpen && <KeyboardArrowUpIcon />}
        </TextStyled>

        <Popover
          open={isOpen}
          anchorEl={periodFilterRef.current}
          onClose={() => setOpen(false)}
        >
          <PopoverStyled>
            <PopoverStyledHeader>
              <span>{year}</span>

              <div style={{ display: "flex", gap: "0.5rem" }}>
                <YearChange
                  aria-label="prev year"
                  disabled={year === minYear || year < minYear}
                  onClick={() => handleChandePeriod("prev")}
                >
                  <KeyboardArrowLeftIcon fontSize="small" />
                </YearChange>
                <YearChange
                  aria-label="next year"
                  disabled={year === maxYear}
                  onClick={() => handleChandePeriod("next")}
                >
                  <KeyboardArrowRightIcon fontSize="small" />
                </YearChange>
              </div>
            </PopoverStyledHeader>

            {availablePeriods?.map((item) => (
              <MenuItemStyled
                value={item?.name}
                key={item?.id}
                id={item?.id}
                onClick={handleChange}
                selected={item?.id === currentPeriod}
              >
                <Typography noWrap>{item?.name}</Typography>
              </MenuItemStyled>
            ))}

            <Divider />

            <MenuItemStyled
              id="0"
              sx={{ color: theme.palette.chart.fill.primary }}
              onClick={handleChange}
              value={today}
              selected={"0" === currentPeriod}
            >
              <Typography noWrap>{today}</Typography>
            </MenuItemStyled>
          </PopoverStyled>
        </Popover>
      </Stack>
    </WrapperStack>
  );
};
