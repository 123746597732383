import { TAB_ID } from "@store/scheme/region";
import { FC } from "react";

import { GridCard } from "@/shared/GridCard/ui/GridCard";
import { formatNumber } from "@/shared/lib/heplers";

import { IMaincardProps } from "../../../lib/types";
import { ValueColor } from "./MainCard.styled";

export const MainCard: FC<IMaincardProps> = ({
  data: mainData,
  closeIcon,
  selected,
  fontSize,
  noCloseButton,
  arrow,
  visible,
  onClick,
  onComponentClick,
  setSelectedTabId,
  sx,
}) => {
  if (visible) {
    return null;
  }
  return (
    <GridCard
      subTitle="Достижение"
      setSelectedTabId={setSelectedTabId}
      onClick={onClick}
      closeIcon={closeIcon}
      selected={selected}
      data={mainData}
      gridArea={mainData.gridArea}
      onComponentClick={onComponentClick}
      noCloseButton={noCloseButton}
      arrow={arrow}
      sx={sx}
      compactTitle={[TAB_ID.VDL, TAB_ID.PROJECTS].includes(mainData.id)}
    >
      <ValueColor
        sx={{ fontSize: fontSize }}
        valueColor={mainData?.color}
        selected={selected}
      >
        {formatNumber(mainData?.fact, mainData?.unit, 0) ?? "-"}
      </ValueColor>
    </GridCard>
  );
};
