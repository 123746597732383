import { MouseEvent, useState } from "react";

import { screenshotWidget } from "../lib/helpers";
import { useIncident } from "./useIncident";

export const useOpenIncident = () => {
  const [move, setMove] = useState(false);
  const { openIncident } = useIncident();

  const handleMove = (isMove: boolean) => setMove(isMove);

  const handleChange = async (
    event: MouseEvent<HTMLButtonElement>,
    screenshotCurrent: HTMLDivElement | null,
    titleWidget: string,
    reportDate?: string
  ) => {
    event.stopPropagation();
    const screenshot = await screenshotWidget(screenshotCurrent);
    const reportPeriod = reportDate?.replace(/^на/gim, "");
    openIncident({ screenshot, titleWidget, reportPeriod });
  };

  return { move, handleMove, handleChange };
};
