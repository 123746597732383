import { Divider } from "@mui/material";
import { ReactNode, useCallback } from "react";

import { RegionPlace } from "@/features/RegionPlace/RegionPlace";
import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import {
  FooterDotMarkFail,
  FooterDotMarkSuccess,
  Item,
} from "@/shared/Footer/ui/Footer.styled";
import { FooterHighLow } from "@/shared/Footer/ui/subComponents/FooterHighLow";
import { TAB_ID } from "@/store/scheme/region";

export const useRegionFooter = () => {
  const { isCheckRfFoPage } = useRegions();

  const getFooterComponent = (legends: Record<string, ReactNode>[]) => {
    return [...legends].map(({ name, icon }, index) => {
      return (
        <Item key={index}>
          {icon}
          {name}
        </Item>
      );
    });
  };

  const changePlacePlusLag = (isNotLag?: boolean) => {
    const lag = !isNotLag
      ? getFooterComponent([
          { icon: <FooterDotMarkFail />, name: "Отставание от плана" },
          { icon: <FooterDotMarkSuccess />, name: "По плану" },
        ])
      : null;
    return (
      <>
        <RegionPlace /> <Divider orientation="vertical" flexItem /> {lag}
      </>
    );
  };

  const collectLegendsTab = useCallback(
    (tabId: TAB_ID) => {
      let children: ReactNode;
      switch (tabId) {
        case TAB_ID.VDL_PROJECTS:
          if (isCheckRfFoPage.isRF) {
            children = null;
          } else {
            children = changePlacePlusLag();
          }
          break;
        case TAB_ID.MANUFACTURING:
        case TAB_ID.PROFIT:
          if (isCheckRfFoPage.isRF) {
            children = <FooterHighLow />;
          } else {
            children = (
              <>
                {changePlacePlusLag(true)}
                <FooterHighLow />
              </>
            );
          }
          break;
        case TAB_ID.INVESTMENT:
        case TAB_ID.EMPLOYED:
          if (isCheckRfFoPage.isRF) {
            children = <FooterHighLow />;
          } else {
            children = (
              <>
                {changePlacePlusLag(isCheckRfFoPage.isFO)}
                {!isCheckRfFoPage.isFO && (
                  <Divider orientation="vertical" flexItem />
                )}
                <FooterHighLow />
              </>
            );
          }
          break;
        case TAB_ID.RISKS:
          if (isCheckRfFoPage.isRF) {
            children = null;
          } else {
            children = (
              <>
                {changePlacePlusLag()}
                {!isCheckRfFoPage.isFO && (
                  <>
                    <Divider orientation="vertical" flexItem />
                    <FooterHighLow />
                  </>
                )}
              </>
            );
          }
          break;
      }
      return children;
    },
    [isCheckRfFoPage.isFO, isCheckRfFoPage.isRF]
  );

  return {
    collectLegendsTab,
  };
};
