import { styled } from "@mui/material";

import { ICoatStyled } from "../lib/types";

export const CoatStyled = styled("div")<ICoatStyled>(
  ({ theme, parent, isOpenPopUp }) => ({
    display: "flex",
    flex: "0 0 auto",
    alignItems: "center",
    justifyContent: "center",
    width: theme.spacing(isOpenPopUp ? 7.5 : 5),
    height: theme.spacing(isOpenPopUp ? 7.5 : 5),

    ...(parent
      ? {
          borderRadius: "50%",
          backgroundColor: theme.palette.background.light,
        }
      : {}),

    "& img": {
      width: theme.spacing(isOpenPopUp ? 4.25 : 2.5),
      height: theme.spacing(isOpenPopUp ? 4.25 : 2.5),
    },
  })
);
