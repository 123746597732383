import { styled } from "@mui/material";

import { colorBasedOnValue } from "@/pages/MainPage/lib/helpers";

export const GeneralInfoResult = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1",
  gap: "24px",
}));

export const GeneralInfoResultItem = styled("div")<{
  isBig: boolean;
  colorValue?: number | null;
}>(({ theme, isBig, colorValue = null }) => ({
  display: "flex",
  gap: "0.5rem",
  alignItems: "end",
  "& > span": {
    color: theme.palette.text.tertiary,
  },
  "& > p": {
    fontSize: isBig ? "1.5rem" : "1.25rem",
    fontWeight: isBig ? 600 : 500,
    color: isBig
      ? colorBasedOnValue(theme, colorValue)
      : theme.palette.text.primary,
    lineHeight: "1.5rem",
  },
}));
