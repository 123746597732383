import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { prepareAttributes } from "@/shared/lib/heplers";
import { ArgumentsWithDistrictIdType } from "@/shared/lib/types";
import {
  IPeriodAttributeVals,
  IPreparedIndicatorAttributeVals,
  MODEL_IDS,
  MODELS,
} from "@/store/scheme/olap";
import { IIndicatorPassport } from "@/widgets/IndicatorPassport/lib/types";

import { IKktSlice } from "../lib/types";
import { getKkt } from "./api";

export const initialState: IKktSlice = {
  data: undefined,
  kktPasport: {} as IIndicatorPassport,
  fetching: false,
  error: null,
};

export const extraActionsKkt = {
  getKkt: createAsyncThunk(
    "regions/kkt/get",
    async (args: ArgumentsWithDistrictIdType) => await getKkt(args)
  ),
};

const kktSlice = createSlice({
  name: "kkt",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActionsKkt.getKkt.pending, (state) => {
        state.data = undefined;
        state.fetching = true;
      })
      .addCase(extraActionsKkt.getKkt.fulfilled, (state, action) => {
        let temporaryKkt = {};
        const model = MODELS[MODEL_IDS.KKT];

        state.data = action.payload.reduce((prev, cur) => {
          const subjectId = cur?.dims?.[model.dataRegionId]?.code;
          temporaryKkt = cur.dims[model.dimId].attributeVals || {};

          prev[subjectId] = {
            ...prepareAttributes<IPreparedIndicatorAttributeVals>({
              ...cur.attributeVals,
              UNIT: (
                cur?.dims?.[model.dimId]?.attributeVals as IPeriodAttributeVals
              )?.unit_measure,
            }),
            attributeVals: cur.dims[model.dataRegionId].attributeVals || {},
            indicatorVals: Object.fromEntries(
              Object.entries(cur?.indicatorVals || {}).map(([key, value]) => [
                key,
                value?.sum,
              ])
            ) as any,
          };

          return prev;
        }, {} as any);
        state.kktPasport = { ...(temporaryKkt as IIndicatorPassport) };
        state.fetching = false;
        state.error = null;
      })
      .addCase(extraActionsKkt.getKkt.rejected, (state, action) => {
        state.fetching = false;
        state.error = action.error;
      });
  },
});

export const reducer = kktSlice.reducer;

export default kktSlice;
