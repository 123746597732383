import { styled } from "@mui/material";

import { VALUE_COLOR } from "@/shared/Table/model/api/scheme";

import { IValueChangeSup, IValueProps, IValueUnit } from "./lib/types";

export const Value = styled("div")<IValueProps>(({ theme, rateColor }) => ({
  display: "flex",
  alignItems: "baseline",
  margin: "0.125rem 0",
  fontWeight: theme.typography.fontWeightBold,
  color: theme.mixins.rateColor(rateColor, theme.palette.text.primary),
  fontSize: theme.spacing(2),
}));

export const ValuePlace = styled("span")<{ isOpenPopUp?: boolean }>(
  ({ theme, isOpenPopUp }) => ({
    color: theme.palette.text.tertiary,
    whiteSpace: "nowrap",
    marginLeft: theme.spacing(3),
    fontSize: isOpenPopUp ? 16 : 12,
    lineHeight: isOpenPopUp ? "2.1rem" : "0.75rem",
  })
);

export const ValueNumber = styled("div")({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-end",
});

export const ValueHLIcon = styled("div", {
  shouldForwardProp: (prop) =>
    !["isBig", "isOpenPopUp"].includes(prop.toString()),
})(({ theme }) => ({
  display: "flex",
  flex: "0 0",
  flexBasis: theme.spacing(2),
  marginLeft: theme.spacing(),

  "& svg": {
    width: "100%",
    height: "auto",
  },
}));

export const ValueIcon = styled(ValueHLIcon)<{ isOpenPopUp?: boolean }>(
  ({ theme, isOpenPopUp }) => ({
    flexBasis: isOpenPopUp ? 32 : "1.1vw",
    marginRight: theme.spacing(),
    marginLeft: 0,
  })
);

export const ValueUnit = styled("span")<IValueUnit>(
  ({ theme, noMargin, isOpenPopUp }) => ({
    marginLeft: noMargin ? 0 : theme.spacing(),
    fontSize: isOpenPopUp ? 20 : 16,
  })
);

export const ValueChange = styled(ValueNumber)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));

export const ValueChangeSup = styled("sup")<IValueChangeSup>(
  ({ theme, difference = VALUE_COLOR.BLUE, isOpenPopUp }) => ({
    color: theme.palette.indicatorCard.tooltip,
    fontSize: isOpenPopUp ? 12 : 10,
  })
);
