import client from "@store/api/client";
import { AxiosBasicCredentials } from "axios";

import {
  User,
  UserGroup,
  UserInfo,
  UserOrganization,
  UserRole,
} from "@/store/scheme/user";

export const AUTH_API_BASEPATH = "/api/auth_api/v1.0";

export function getToken(
  type: "password" | "oam_remote_user",
  credentials?: AxiosBasicCredentials
) {
  return client.post<{
    access_token: string;
    refresh_token: string;
    token_type: string;
  }>(
    `${AUTH_API_BASEPATH}/oauth/token?grant_type=${type}&client_id=planet-analitics&client_secret=planet-analitics-password${
      credentials
        ? `&username=${credentials.username}&password=${credentials.password}`
        : ""
    }`
  );
}

export interface IPlanetaUser {
  changePassRequired: boolean;
  email: string | null;
  expireDate: string | null;
  firstName: string | null;
  id: number;
  lastName: string | null;
  login: string;
  middleName: string | null;
  name: string;
}

export const getAuthorized = () =>
  client.get<IPlanetaUser>(`${AUTH_API_BASEPATH}/user/authorized`);

export const getOAMUser = async (): Promise<User> => {
  const { data: userInfo } = await client.get<UserInfo>(
    "/api-gasu/planeta/userInfo"
  );
  const { data: userOrganization } = await client.get<UserOrganization>(
    `/api-gasu/public/users/${userInfo.userId}/`
  );
  const { data: userRoles } = await client.get<UserRole[]>(
    `/api-gasu/public/users/${userInfo.userId}/roles/`
  );
  const { data: userGroups } = await client.get<UserGroup[]>(
    `/api-gasu/public/users/${userInfo.userId}/groups`
  );

  const isVIP = userRoles.some((role) => role.actualCode === "arm_region_vip");
  const isAnalyticFK = userRoles.some(
    (role) => role.actualCode === "arm_region_analytic_fk"
  );
  // отфильтруем роли доступа к субъектам
  const regions = userRoles
    .filter((role) => role.description && /^region_\d+$/.test(role.description))
    .map((role) => role.description.replace("region_", ""));

  return {
    ...userInfo,
    ...userOrganization,
    isVIP,
    isAnalyticFK,
    regions,
    name: `${userInfo.lastName} ${userInfo.firstName} ${userInfo.middleName}`,
    roles: [...userRoles],
    roleCodes: new Set(userRoles.map(({ actualCode }) => actualCode)),
    groups: [...userGroups],
    groupCodes: new Set(userGroups.map(({ actualCode }) => actualCode)),
  };
};
