// новые территории, обьединеные в один geoJson
export const newTerritories = [
  {
    type: "Feature",
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-3646886.557354152202606, 7554688.966249546967447],
            [-3637632.788712194655091, 7533867.986805142834783],
            [-3535841.333650661632419, 7514203.728440983220935],
            [-3535841.333650661632419, 7451740.790107768960297],
            [-3512706.912045767530799, 7441330.300385566428304],
            [-3505766.585564299486578, 7400845.062577002681792],
            [-3516177.07528650155291, 7371927.035570885054767],
            [-3488415.769360628910363, 7347635.892885746434331],
            [-3513863.633126012049615, 7346479.171805501915514],
            [-3521960.680687725078315, 7316404.423719139769673],
            [-3574013.129298736341298, 7336068.682083299383521],
            [-3572856.408218491822481, 7296740.165354980155826],
            [-3594834.108743140939623, 7310620.818317916244268],
            [-3629535.741150481626391, 7300210.328595713712275],
            [-3641102.951952928677201, 7249314.60106494743377],
            [-3694312.121644184924662, 7231963.784861276857555],
            [-3740580.964853972196579, 7296740.165354980155826],
            [-3771812.434020579326898, 7289799.838873512111604],
            [-3803043.903187185991555, 7309464.097237671725452],
            [-3893268.147446272429079, 7355732.940447458997369],
            [-3901365.195007984992117, 7391591.293935044668615],
            [-3921029.453372145071626, 7400845.062577002681792],
            [-3946477.317137528210878, 7395061.457175779156387],
            [-4013567.139791720546782, 7428606.36850287579],
            [-4033231.39815588016063, 7465621.443070705980062],
            [-4072559.914884200319648, 7467934.885231195017695],
            [-4074873.357044689357281, 7494539.470076822675765],
            [-4119985.479174232576042, 7511890.286280493251979],
            [-4141963.179698881693184, 7538494.871126120910048],
            [-4196329.070470382459462, 7557002.408410036936402],
            [-4217150.049914786592126, 7554688.966249546967447],
            [-4218306.770995032042265, 7574353.224613706581295],
            [-4233344.145038212649524, 7574353.224613706581295],
            [-4236814.308278947137296, 7620622.067823494784534],
            [-4273829.382846777327359, 7657637.142391324974597],
            [-4306217.573093628510833, 7731667.291526985354722],
            [-4342075.926581214182079, 7768682.366094815544784],
            [-4349016.2530626822263, 7798757.114181177690625],
            [-4316628.062815831042826, 7804540.71958240121603],
            [-4266889.056365309283137, 7740921.060168943367898],
            [-4262262.172044330276549, 7749018.107730655930936],
            [-4277299.546087510883808, 7790660.066619465127587],
            [-4235657.58719870261848, 7783719.740137997083366],
            [-4218306.770995032042265, 7743234.502329432405531],
            [-4165097.601303775794804, 7760585.318533102981746],
            [-4153530.390501328743994, 7782563.019057752564549],
            [-4109574.98945203050971, 7745547.944489921443164],
            [-4101477.941890317481011, 7706219.427761602215469],
            [-4072559.914884200319648, 7676144.679675240069628],
            [-4032074.677075635641813, 7595174.204058111645281],
            [-3989275.997106581926346, 7614838.462422271259129],
            [-3983492.391705358400941, 7642599.768348144367337],
            [-4014723.860871965065598, 7639129.605107409879565],
            [-4039015.003557103686035, 7664577.468872793018818],
            [-3992746.160347315948457, 7679614.8429159745574],
            [-3959201.249020219780505, 7657637.142391324974597],
            [-3932596.664174592122436, 7639129.605107409879565],
            [-3926813.058773368597031, 7577823.387854441069067],
            [-3934910.106335081160069, 7529241.102484163828194],
            [-3902521.916088229976594, 7519987.33384220674634],
            [-3895581.589606761932373, 7543121.755447099916637],
            [-3872447.168001867830753, 7541965.03436685539782],
            [-3866663.562600644305348, 7506106.680879269726574],
            [-3840058.977755016181618, 7516517.170601472258568],
            [-3836588.814514282159507, 7555845.687329791486263],
            [-3813454.392909388057888, 7561629.292731015011668],
            [-3828491.766952569596469, 7598644.367298845201731],
            [-3789163.250224249437451, 7592860.761897621676326],
            [-3766028.828619355801493, 7546591.918687834404409],
            [-3720916.706489812582731, 7554688.966249546967447],
            [-3709349.495687365531921, 7568569.61921248305589],
            [-3696625.563804673962295, 7553532.24516930244863],
            [-3701252.448125652968884, 7494539.470076822675765],
            [-3667707.536798556800932, 7553532.24516930244863],
            [-3646886.557354152202606, 7554688.966249546967447],
          ],
        ],
      ],
    },
    properties: {
      name: "",
    },
  },
];
