import { useCallback } from "react";
import { useParams } from "react-router";

import { useTabs } from "@/app/hooks/useTabs";
import { defaultTabs } from "@/shared/GridCard/lib/constants";
import { GOALS_TABS, IDynamicTab, STEPS } from "@/shared/GridCard/lib/types";
import { REGION_COUNTRY_CODE } from "@/shared/lib/constants";
import { convertPreviosDate, formatNumber } from "@/shared/lib/heplers";
import { useData } from "@/shared/Table/hooks/useTotal";
import { useAppSelector } from "@/store/redux/hooks";
import { textForTabsAndTable } from "@/store/scheme/common";
import { MODEL_IDS, MODELS } from "@/store/scheme/olap";
import { TAB_ID } from "@/store/scheme/region";

export const useMapTabs = () => {
  const { id } = useParams<"id" | "tabId">();
  const { districtId } = useParams<"districtId">();
  const { saveStep } = useAppSelector((state) => state.ui.tabs);
  const { enabled } = useAppSelector((state) => state.controls.achievement);
  const { kpiData, projectPassport } = useAppSelector((state) => state.map.geo);
  const { getIndicator } = useData();
  const model = MODELS[MODEL_IDS.KPI_SOC_ECONOMY];
  const { checkPeriodByHideDate, receiptGoal, isCheckPeriod } = useTabs();

  const getMapTabsData = useCallback(
    (targetID: string): IDynamicTab[] => {
      const tabs = GOALS_TABS.map((tab) => {
        const [indicator, altIndicator] = receiptGoal(tab);

        const mainModel = getIndicator(kpiData, targetID, indicator);
        const altModel = getIndicator(kpiData, targetID, altIndicator);

        const disId = id || districtId;

        return {
          id: tab,
          text: mainModel?.attributeVals?.short_name,
          date: mainModel?.date,
          ...(isCheckPeriod(indicator) && {
            prevPeriod: ["1", "2", "3", "4", "132", "133"].includes(indicator)
              ? enabled
                ? convertPreviosDate(mainModel?.prevDate)
                : mainModel?.prevDate
              : "",
          }),
          value: mainModel?.values?.[model.indexes.factChangeAppg],
          valueUnit: altModel?.attributeVals?.UNIT ?? "%",
          plan: mainModel?.values?.[model.indexes.plan],
          fact: mainModel?.values?.[model.indexes.fact],
          unit: mainModel?.attributeVals?.UNIT,
          rf: getIndicator(kpiData, REGION_COUNTRY_CODE, indicator)?.values?.[
            model.indexes.fact
          ],
          fo: disId
            ? getIndicator(kpiData, disId, indicator)?.values?.[
                model.indexes.fact
              ]
            : undefined,
          hlRF: mainModel?.values?.[model.indexes.factHighLowRF] ?? 0,
          hlFO: mainModel?.values?.[model.indexes.factHighLowFO] ?? 0,
          color: mainModel?.values?.[model.indexes.colorGraph] ?? null,
        };
      }) as IDynamicTab[];

      if (enabled) {
        const vdlPModel = getIndicator(kpiData, targetID, "123");
        const vdlModel = getIndicator(kpiData, targetID, "10");
        const projectsModel = getIndicator(
          projectPassport,
          targetID,
          "0",
          undefined,
          MODEL_IDS.PROJECT_PASSPORT
        );

        const checkpointsModel = getIndicator(
          projectPassport,
          targetID,
          "129",
          undefined,
          MODEL_IDS.PROJECT_PASSPORT
        );

        tabs.unshift(
          {
            ...defaultTabs[TAB_ID.VDL_PROJECTS],
            date: vdlPModel?.date,
            fact: vdlPModel?.values?.[model.indexes.factChangeAppg],
            unit: vdlPModel?.attributeVals?.UNIT,
            color: vdlPModel?.values?.[model.indexes.colorGraph],
          },
          {
            ...defaultTabs[TAB_ID.VDL],
            date: vdlModel?.date,
            ...(!checkPeriodByHideDate && {
              prevPeriod: convertPreviosDate(vdlModel?.prevDate),
            }),
            fact: vdlModel?.values?.[model.indexes.factChangeAppg],
            unit: vdlModel?.attributeVals?.UNIT,
            color: vdlModel?.values?.[model.indexes.colorGraph],
          },
          {
            ...defaultTabs[TAB_ID.PROJECTS],
            date: projectsModel?.date,
            prevPeriod:
              saveStep !== STEPS.MAIN_FULL
                ? "в соотв. с графиком проекта"
                : textForTabsAndTable,
            fact: projectsModel?.values?.["1141"],
            unit: projectsModel?.attributeVals?.UNIT || "%",
            color: projectsModel?.values?.["881"],
          },
          {
            ...defaultTabs[TAB_ID.RESULT_PROJECTS],
            date: projectsModel?.date,
            prevPeriod: textForTabsAndTable,
            value: projectsModel?.values?.["802"],
            unit: projectsModel?.attributeVals?.UNIT || "%",
            color: projectsModel?.values?.["804"],
          },
          {
            ...defaultTabs[TAB_ID.RESULT_CHECKPOINTS],
            date: checkpointsModel?.date,
            prevPeriod: textForTabsAndTable,
            value: checkpointsModel?.values?.["802"],
            unit: "%",
            color: checkpointsModel?.values?.["804"],
          }
        );
      } else {
        const projectsModel = getIndicator(kpiData, targetID, "104");
        const problemModel = getIndicator(kpiData, targetID, "121");

        tabs.unshift({
          ...defaultTabs[TAB_ID.RISKS],
          projects: formatNumber(projectsModel?.values?.["494"], undefined, 0),
          value: problemModel?.values?.[model.indexes.fact],
          unit: projectsModel?.attributeVals?.UNIT || "%",
          color: problemModel?.values?.[model.indexes.color],
        });
      }

      return tabs.map((tab) => ({ ...tab, gridArea: tab.id }));
    },
    [
      kpiData,
      projectPassport,
      enabled,
      getIndicator,
      id,
      districtId,
      model.indexes.fact,
      model.indexes.factHighLowRF,
      model.indexes.factHighLowFO,
      model.indexes.color,
      model.indexes.colorGraph,
      saveStep,
      isCheckPeriod,
    ]
  );

  return {
    getMapTabsData,
  };
};
