import { TAB_ID } from "@store/scheme/region";
import { FC, useCallback, useEffect } from "react";

import { useFilteredTabs } from "@/shared/AchievementTabCard/hooks/useFilteredTabs";
import {
  GoalsCard,
  MainCard,
  ProjectsCard,
} from "@/shared/AchievementTabCard/ui/components";
import { STEPS } from "@/shared/GridCard/lib/types";

import { useAchievementTabs } from "../hooks/useAchievementTabs";
import { setFontSize } from "../lib/helpers";
import { IMapTabCardProps } from "../lib/types";
import { GridMapContainer } from "./AchievementTabCard.styled";

export const MapTabCard: FC<IMapTabCardProps> = ({
  selectedTabId,
  setSelectedTabId,
  tabs,
}) => {
  const { mainTabs, goalsTabs, projectsTabs } = useFilteredTabs(tabs);
  const {
    saveStep,
    setSaveStep,
    setInitialStep,
    selectedTab,
    isCheckSaveStep,
  } = useAchievementTabs();

  const onTabClick = useCallback(
    (tabId: TAB_ID) => {
      const selectTab = selectedTab(tabId);
      setSaveStep(selectTab);
    },
    [selectedTab, setSaveStep]
  );

  useEffect(() => {
    const defaultStep = setInitialStep(selectedTabId, saveStep);
    setSaveStep(defaultStep);
  }, [saveStep, setSaveStep, setInitialStep]);

  return (
    <GridMapContainer
      typeArea={saveStep}
      columns={[STEPS.GOALS, STEPS.PROJECTS].includes(saveStep) ? 6 : 3}
    >
      {mainTabs.map((tab) => (
        <MainCard
          key={tab.id}
          data={tab}
          setSelectedTabId={setSelectedTabId}
          onClick={() => onTabClick(tab.id)}
          selected={selectedTabId === tab.id}
          arrow={isCheckSaveStep(tab.id)}
          fontSize={setFontSize(tab.id, saveStep)}
          noCloseButton={isCheckSaveStep(tab.id)}
          onComponentClick={isCheckSaveStep(tab.id)}
          closeIcon={saveStep !== STEPS.MAIN && tab.id === TAB_ID.VDL_PROJECTS}
          visible={saveStep === STEPS.MAIN && tab.id !== TAB_ID.VDL_PROJECTS}
        />
      ))}
      {saveStep === STEPS.GOALS && (
        <>
          {goalsTabs.map((tab) => (
            <GoalsCard
              key={tab.id}
              data={tab}
              main
              selected={selectedTabId === tab.id}
              setSelectedTabId={setSelectedTabId}
            />
          ))}
        </>
      )}
      {saveStep === STEPS.PROJECTS && (
        <>
          {projectsTabs.map((tab) => (
            <ProjectsCard
              key={tab.id}
              data={tab}
              selected={selectedTabId === tab.id}
              setSelectedTabId={setSelectedTabId}
            />
          ))}
        </>
      )}
    </GridMapContainer>
  );
};
