import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  IIncidentProps,
  IIncidentState,
  IIncidentSueAllRequest,
} from "../../lib/types";
import { postIncident } from "../api/api";

export const initialState: IIncidentState = {
  incident: {
    data: {
      screenshot: "",
      titleWidget: "",
    },
    open: false,
  },
  success: false,
  fetching: false,
  error: null,
};

export const extraActionsIncident = {
  post: createAsyncThunk(
    "incident/post",
    async (allData: IIncidentSueAllRequest) => await postIncident(allData)
  ),
};

const incidentSlice = createSlice({
  name: "incident",
  initialState,
  reducers: {
    setIncident: (state, action: PayloadAction<IIncidentProps>) => {
      state.incident = { ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(extraActionsIncident.post.pending, (state) => {
        state.success = false;
        state.fetching = true;
      })
      .addCase(extraActionsIncident.post.fulfilled, (state, action) => {
        state.success = true;
        state.fetching = false;
        state.error = null;
      })
      .addCase(extraActionsIncident.post.rejected, (state, action) => {
        state.fetching = false;
        state.success = false;
        state.error = action.error;
      });
  },
});

export const { setIncident } = incidentSlice.actions;

export const reducer = incidentSlice.reducer;

export default incidentSlice;
