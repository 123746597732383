import { styled } from "@mui/material";

export const HeaderBase = styled("div", {
  shouldForwardProp: (prop) =>
    !["direction", "isCenter", "sx"].includes(prop.toString()),
})<{ direction: "row" | "column"; isCenter?: boolean }>(
  ({ theme, isCenter = false, direction = "row" }) => ({
    display: "flex",
    gap: theme.spacing(2),
    flexDirection: direction,
    ...(isCenter && {
      alignItems: "center",
    }),
  })
);
