export const DATE_DEFAULT_FORMAT = "YYYY-MM-DD";
export const DATE_DISPLAY_FORMAT = "DD MMMM YYYY";

export enum REGION_GENERAL_INDICATORS {
  MANUFACTURING = "1",
  PROFIT = "2",
  INVESTMENTS = "3",
  WORKERS = "4",
  EXPORT = "5",
  IMPORT = "6",
}

export enum VALUE_COLOR {
  GREEN = 1,
  BLACK = 2,
  RED = 3,
  BLUE = 4,
  GREY = 5,
  LIGHT_GREEN = 6,
  BORDO = 7,
  ORANGE = 8,
}

export enum PROJECT_INDICATORS {
  SALARY = "106",
  PROFIT = "107",
  INVESTMENTS = "108",
  PLACES = "109",
}

export enum PROJECT_FINANCES {
  FEDERAL_BUDGET = "111",
  REGIONAL_BUDGET = "112",
  NON_BUDGET = "113",
  TOTAL = "120",
}

export enum PROJECT_COLOR {
  RED = 1,
  GREEN = 2,
}

export enum PROJECT_STATUS {
  RED = 0,
  GREEN = 1,
}
