import { styled } from "@mui/material";

export const IconWrapper = styled("div")<{
  achEnabled?: boolean;
  isVDL?: boolean;
  openWidget?: boolean;
}>(({ theme, achEnabled = true, isVDL = false, openWidget }) => ({
  flexShrink: 0,
  height: isVDL ? (achEnabled ? 48 : 104) : openWidget ? 60 : 40,
  width: isVDL ? (achEnabled ? 48 : 104) : openWidget ? 60 : 40,
  background: theme.palette.table.backgroundImg,
  borderRadius: "50%",
  "& > svg": {
    height: isVDL ? (achEnabled ? 30 : 78) : openWidget ? 36 : 24,
    width: isVDL ? (achEnabled ? 30 : 78) : openWidget ? 36 : 24,
    margin: "50% 50%",
    transform: "translate(-50%, -50%)",
    "& > path": {
      fill:
        theme.palette.mode === "light"
          ? theme.palette.graphs.graphHoverLine
          : isVDL
          ? theme.palette.icon.iconFoMap
          : theme.palette.dateBadge.color,
      fillOpacity: 1,
    },
  },
}));
