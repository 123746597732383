import { useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";

import { colorBasedOnValue } from "@/pages/MainPage/lib/helpers";
import { REGION_COUNTRY_CODE } from "@/shared/lib/constants";
import { formatNumber } from "@/shared/lib/heplers";
import { useAppSelector } from "@/store/redux/hooks";

import { compareRisks, riskIndicators, riskValues } from "../lib/constants";
import { useRisks } from "./useRisks";

export const useRisksGraph = () => {
  const theme = useTheme();
  const { id } = useParams();
  const { achievements } = useAppSelector((state) => state.ui.tabs);
  const { sumRisksTotal } = useRisks();
  const [risksProjectTotal, setRisksProjectTotal] = useState<
    Record<string, string | undefined>
  >({
    value: undefined,
    color: undefined,
  });

  const riskReportDate = useMemo(() => {
    return sumRisksTotal?.[REGION_COUNTRY_CODE]?.[0]?.["152"]?.attributeVals?.[
      "REPORT_DATE"
    ];
  }, [sumRisksTotal]);

  const commonProjects = useMemo(() => {
    const indicatorValues = achievements?.["104"]?.[id!!]?.indicatorVals;
    return {
      value: indicatorValues?.["494"],
      color: colorBasedOnValue(theme, indicatorValues?.["510"]),
    };
  }, [achievements, id]);

  const [commonRiskById, commonRiskIndicators] = useMemo(() => {
    const indicators = sumRisksTotal?.[id!!]?.[0];
    return [indicators?.["152"]?.indicatorVals, indicators];
  }, [sumRisksTotal, id]);

  const risksProjectItem = useMemo(() => {
    return formatNumber(commonRiskById?.[riskValues.percent], undefined, 0);
  }, [sumRisksTotal, id, commonRiskById]);

  useEffect(() => {
    setRisksProjectTotal({
      value: formatNumber(commonRiskById?.[riskValues.fact], undefined, 0),
      color: colorBasedOnValue(theme, commonRiskById?.[riskValues.color]),
    });
  }, [sumRisksTotal, id, commonRiskById]);

  const prepareRisksData = useMemo(() => {
    let prepareTotalRisks = 0;
    const prepareRisks = riskIndicators.reduce((prev, cur) => {
      if (!prev) prev = [];
      const data = commonRiskIndicators?.[cur];
      if (data) {
        const risk = {
          level: compareRisks[cur],
          value: formatNumber(
            data?.indicatorVals?.[riskValues.fact] || 0,
            undefined,
            0
          ),
          color: colorBasedOnValue(
            theme,
            data?.indicatorVals?.[riskValues.color]
          ),
        };
        prepareTotalRisks += +(risk.value || 0);
        prev.push(risk);
      }
      return prev;
    }, [] as any);
    if (prepareTotalRisks !== commonProjects?.value) {
      prepareRisks.push({
        level: "total",
        value: commonProjects.value - prepareTotalRisks,
        color: theme.palette.diagram.baseDiagram,
      });
    }
    return prepareRisks;
  }, [sumRisksTotal, id, commonRiskIndicators]);

  const prepareData = useMemo(
    () =>
      [...prepareRisksData].map((item: any) => ({
        ...item,
        fill: formatNumber(
          (item.value / commonProjects?.value) * 100,
          undefined,
          0
        ),
      })),
    [prepareRisksData]
  );

  return {
    prepareData,
    risksProjectItem,
    commonProjects,
    risksProjectTotal,
    riskReportDate,
  };
};
