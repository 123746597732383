import { useCallback } from "react";

import { extraActionsFeedback } from "@/shared/Feedback/model/feedbackSlice";
import { useAppDispatch, useAppSelector } from "@/store/redux/hooks";

import {
  gasuOrigin,
  moduleName,
  nationId,
  serviceDev,
  serviceProd,
  uatOrigin,
} from "../lib/constants";
import { descriptionFormation } from "../lib/helpers";
import {
  IIncidentData,
  IIncidentProps,
  IIncidentSueRequest,
} from "../lib/types";
import {
  extraActionsIncident,
  setIncident,
} from "../model/slices/incidentSlice";
import { extraActionsLevelIncident } from "../model/slices/levelIncidentsSlice";
import { extraActionsTypeIncident } from "../model/slices/typeIncidentsSlice";

export const useIncident = () => {
  const {
    incident: { incident, success, error, fetching },
    levelIncident: { levelIncident },
    typeIncident: { typeIncident },
  } = useAppSelector((state) => state.feedback);
  const dispatch = useAppDispatch();

  const setMoveIncident = useCallback(
    ({ data, open }: IIncidentProps) => {
      dispatch(setIncident({ data, open }));
    },
    [dispatch]
  );

  const openIncident = useCallback(
    (data: IIncidentData) => {
      setMoveIncident({ data, open: true });
    },
    [setMoveIncident]
  );

  const requestIncident = (
    file: File,
    incidentData: Record<string, string>
  ) => {
    const {
      titleWidget,
      incidentLevel,
      incidentType,
      panelLink,
      description,
      authorIncident,
      dateCreateIncident,
      reportPeriod,
      infoPanel,
      territory,
    }: Record<string, string> = incidentData;

    const sueFields: IIncidentSueRequest = {
      incidentLevel,
      incidentType,
      panelLink,
      service: null,
      nationId: nationId,
      nationName: moduleName,
      user: authorIncident,
      incidentName: moduleName,
      description: descriptionFormation({
        titleWidget,
        infoPanel,
        territory,
        reportPeriod,
        description,
        authorIncident,
        dateCreateIncident,
      }),
    };
    // Значение поля service зависит от стенда
    sueFields.service = [uatOrigin, gasuOrigin].includes(
      window?.location.origin
    )
      ? serviceProd
      : serviceDev;

    dispatch(extraActionsIncident.post({ file, data: sueFields }));
  };

  const requestLevelIncident = () => {
    dispatch(extraActionsLevelIncident.post());
  };

  const requesTypeIncident = () => {
    dispatch(extraActionsTypeIncident.post());
  };

  const requestAnalyticsFeedback = () => {
    dispatch(extraActionsFeedback.postAnalytics());
  };

  const requestFieldsIncidents = () => {
    requestLevelIncident();
    requesTypeIncident();
    requestAnalyticsFeedback();
  };

  return {
    success,
    error,
    fetching,
    incident,
    openIncident,
    levelIncident,
    typeIncident,
    setMoveIncident,
    requestIncident,
    requestFieldsIncidents,
  };
};
