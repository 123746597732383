import { useAppSelector } from "@store/redux/hooks";
import { MODEL_IDS, MODELS } from "@store/scheme/olap";
import { FC, useMemo } from "react";

import { useKkt } from "@/features/KktWidget/hooks/useKkt";
import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { COMPARE_TYPE } from "@/shared/CompareControl/lib/types";
import { IValue } from "@/shared/GeneralIndicators/lib/types";
import { GeneralIndicators } from "@/shared/GeneralIndicators/ui/GeneralIndicators";
import { REGION_COUNTRY_CODE } from "@/shared/lib/constants";
import { formatNumber } from "@/shared/lib/heplers";
import { IPopUpProps, REGION_TABLES } from "@/shared/RegionPopUp/lib/types";

import { kktTitle } from "../lib/constants";

export const KktWidget: FC<IPopUpProps> = ({ isOpenPopUp }) => {
  const { data, fetching } = useKkt();
  const regionId = useAppSelector((state) => state.controls.regions.id);
  const {
    getDistrictByRegion,
    indicatorHighLow,
    compareLabel,
    isCheckRfFoPage,
  } = useRegions();
  const district = getDistrictByRegion(regionId!);
  const model = MODELS[MODEL_IDS.KKT];
  const { compare } = useAppSelector((state) => state.controls);

  const values: IValue[] = useMemo(() => {
    if (regionId) {
      return [
        {
          ...(!isCheckRfFoPage.isRF && {
            title:
              data?.[regionId]?.attributeVals?.[
                isCheckRfFoPage.isFO ? "SHORT_NAME" : "name"
              ],
          }),
          date: data?.[regionId]?.REPORT_DATE,
          key: "110",
          value: formatNumber(
            data?.[regionId]?.indicatorVals?.[model.indexes.fact]
          ),
          unit: data?.[regionId]?.UNIT,
        },
        ...(!isCheckRfFoPage.isRF
          ? [
              {
                title: compareLabel,
                date: data?.[REGION_COUNTRY_CODE]?.REPORT_DATE,
                key: "parent-110",
                value: formatNumber(
                  data?.[
                    compare.value !== COMPARE_TYPE.FO
                      ? REGION_COUNTRY_CODE
                      : district?.id ?? regionId
                  ]?.indicatorVals?.[model.indexes.fact]
                ),
                unit: data?.[REGION_COUNTRY_CODE]?.UNIT,
              },
            ]
          : []),
        {
          key: "increase-110",
          date: data?.[regionId]?.PREVIOUS_DATE,
          value: formatNumber(
            data?.[regionId]?.indicatorVals?.[model.indexes.increase],
            undefined,
            undefined,
            true
          ),
          unit: "%",
          valueColor:
            data?.[regionId]?.indicatorVals?.[model.indexes.increaseColor],
          valueHL:
            data?.[regionId]?.indicatorVals?.[model.indexes[indicatorHighLow]],
        },
        ...(!isCheckRfFoPage.isRF
          ? [
              {
                key: "parent-increase-110",
                date: data?.[REGION_COUNTRY_CODE]?.PREVIOUS_DATE,
                value: formatNumber(
                  data?.[
                    compare.value !== COMPARE_TYPE.FO
                      ? REGION_COUNTRY_CODE
                      : district?.id ?? regionId
                  ]?.indicatorVals?.[model.indexes.increase],
                  undefined,
                  undefined,
                  true
                ),
                unit: "%",
                valueColor:
                  data?.[
                    compare.value !== COMPARE_TYPE.FO
                      ? REGION_COUNTRY_CODE
                      : district?.id ?? regionId
                  ]?.indicatorVals?.[model.indexes.increaseColor],
              },
            ]
          : []),
      ];
    }

    return [];
  }, [
    compare.value,
    data,
    district,
    indicatorHighLow,
    compareLabel,
    regionId,
    isCheckRfFoPage.isFO,
    isCheckRfFoPage.isRF,
  ]);

  return (
    <GeneralIndicators
      title={kktTitle}
      generalFetching={fetching}
      indicatorsFontStyle="italic"
      values={values}
      isOpenPopUp={isOpenPopUp}
      popUpId={REGION_TABLES.KKT}
    />
  );
};
