import { FC, SVGProps } from "react";

const icon: FC<SVGProps<SVGSVGElement>> = ({ children, ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 2C7.89543 2 7 2.89543 7 4V10H6.20711C4.87076 10 4.2015 11.6157 5.14645 12.5607L10.5858 18C11.3668 18.781 12.6332 18.781 13.4142 18L18.8536 12.5607C19.7985 11.6157 19.1293 10 17.7929 10H17V4C17 2.89543 16.1046 2 15 2H9ZM9 4H15V12H16.5858L12 16.5858L7.41422 12H9V4ZM5 20C4.44772 20 4 20.4477 4 21C4 21.5523 4.44772 22 5 22H19C19.5523 22 20 21.5523 20 21C20 20.4477 19.5523 20 19 20H5Z"
      fill="currentColor"
    />
  </svg>
);

export default icon;
