import React, { useEffect, useState } from "react";

import {
  fileName,
  lastAvailableRfPeriod,
  periodsUpLoadingRf,
} from "../lib/constants";
import { formationRfPath } from "../lib/helpers";
import { IExportControlProps } from "../lib/types";
import { RfPeriodButton, RfPeriodsWrapper } from "./ExportControl.styled";

export const RfExportControl = ({ handleChangeData }: IExportControlProps) => {
  const [periodId, setPeriodId] = useState(0);
  const periods = periodsUpLoadingRf();
  const handleClick = (id: number) => setPeriodId(id);

  useEffect(() => {
    setPeriodId(lastAvailableRfPeriod || 0);
  }, []);

  const formationCallbackData = () => {
    const isDisabled = !periodId;
    let filePath = "";
    if (periodId) {
      filePath = formationRfPath({
        fileName,
        year: String(periodId),
      });
    }
    handleChangeData({ isDisabled, filePath, fileName });
  };

  useEffect(() => {
    formationCallbackData();
  }, [periodId]);

  return (
    <RfPeriodsWrapper>
      <span>Выберите период выгрузки</span>
      <div>
        {periods?.map(({ id, isDisabled }) => (
          <RfPeriodButton
            key={id}
            isSelected={id === periodId}
            disabled={isDisabled}
            onClick={() => handleClick(id)}
          >
            {id}
          </RfPeriodButton>
        ))}
      </div>
    </RfPeriodsWrapper>
  );
};
