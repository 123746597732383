import { useMemo } from "react";

import { useAppSelector } from "@/store/redux/hooks";

import { labelFuturePlan } from "../lib/constants";
import { IHeaderColumns } from "../lib/types";

export const useFuturePlan = () => {
  const { currentPeriod } = useAppSelector((state) => state.controls.period);
  const isCheckFuturePlan = useMemo(() => {
    return currentPeriod === "0" || currentPeriod >= "320220004";
  }, [currentPeriod]);

  const isCheckRiskFuturePlan = useMemo(() => {
    return currentPeriod === "0" || currentPeriod >= "320230003";
  }, [currentPeriod]);

  const isFuturePlanLabel = (initialHeaderCols: IHeaderColumns[]) => {
    return initialHeaderCols.some(({ label }) => {
      const clearingSigns = label?.split(",")?.[0] || "";
      return labelFuturePlan.includes(clearingSigns);
    });
  };

  return { isCheckFuturePlan, isFuturePlanLabel, isCheckRiskFuturePlan };
};
