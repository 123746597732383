import { getOperands, olapRequestData2 } from "@/store/api/core";
import {
  FILTER_OPERAND_TYPE,
  FILTER_TYPE,
  MODEL_IDS,
  MODELS,
} from "@/store/scheme/olap";

// Запрос для получения на странице РФ - ФО и на странице ФО - субъектов
export const getSumRisksRFFo = ({
  dataSummaryId,
  dataRegionId,
}: Record<string, string>) => {
  const model = MODELS[MODEL_IDS.RISKS];

  const dimensions = [
    {
      id: model.dataPeriodId,
      includeItems: true,
      includeAttributesByCodes: ["REPORT_DATE"],
    },
    {
      id: model.dataRegionId,
    },
    {
      id: model.dataProjectId,
    },
    {
      id: model.dimId,
    },
    {
      id: model.dataResultId,
    },
    {
      id: model.dataRiskId,
    },
    {
      id: model.dataSummaryId,
    },
  ];

  const indicators = {
    id: model.indicatorId,
    items: [
      { id: model.indexes.fact },
      { id: model.indexes.plan },
      { id: model.indexes.color },
    ],
  };

  const operands = getOperands([
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      dim_id: model.dataPeriodId,
      levels: [2],
      showChildren: false,
      showLeafsOnly: false,
      showAllLevelElements: false,
    },
    {
      type: FILTER_OPERAND_TYPE.PARENT,
      version: 1,
      dim_id: model.dataRegionId,
      values: [dataRegionId],
    },
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      dim_id: model.dataProjectId,
      levels: [0],
      showChildren: false,
      showLeafsOnly: false,
      showAllLevelElements: false,
    },
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      dim_id: model.dimId,
      levels: [0],
      showChildren: false,
      showLeafsOnly: false,
      showAllLevelElements: false,
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataResultId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: ["152", "153", "154", "155"],
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataRiskId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: ["2"],
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataSummaryId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: [dataSummaryId],
        },
      ],
    },
  ]);

  return olapRequestData2(
    dimensions.map(({ id }) => id),
    model.indicatorId,
    {
      modelUuid: model.uuid,
      includeGaps: false,
      indicatorId: model.indicatorId,
      dimensions,
      dataRequestCaching: true,
      indicators,
      dataFilter: {
        type: FILTER_TYPE.AND,
        version: 1,
        operands,
      },
    },
    "1aa3ff5d-2020-489d-80bf-60c3fb19ea54"
  );
};
