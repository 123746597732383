import { combineReducers } from "@reduxjs/toolkit";

import {
  IPagesProjectCheckpointsAndResultsState,
  IPagesProjectFinancesState,
  IPagesProjectInfoState,
} from "@/pages/ProjectsPage/lib/types";
import checkpointsAndResultsSlice from "@/pages/ProjectsPage/model/slice/checkpointsAndResultsSlice";
import financesSlice from "@/pages/ProjectsPage/model/slice/financesSlice";
import infoSlice from "@/pages/ProjectsPage/model/slice/infoSlice";

export interface IPagesProjectReducer {
  checkpointsAndResults: IPagesProjectCheckpointsAndResultsState;
  finances: IPagesProjectFinancesState;
  info: IPagesProjectInfoState;
}

const reducer = combineReducers<IPagesProjectReducer>({
  checkpointsAndResults: checkpointsAndResultsSlice.reducer,
  finances: financesSlice.reducer,
  info: infoSlice.reducer,
});

export default reducer;
