import ExportIcon from "@assets/svg_components/Export";
import React, { FC } from "react";

import { ToggleButtonWrapper } from "@/pages/NormativeLegalActs/ui/ActsContent/ui/ActsContent.styled";
import { ToggleButtonStyled } from "@/shared/DownloadControl/DownloadControl.styled";

import { LinkUnstyled } from "./DownloadLink.styled";

export const DownloadLink: FC<{ href?: string }> = ({ href }) => {
  return (
    <LinkUnstyled href={href} target="_blank">
      <ToggleButtonWrapper background>
        <ToggleButtonStyled value={{}}>
          <ExportIcon />
        </ToggleButtonStyled>
      </ToggleButtonWrapper>
    </LinkUnstyled>
  );
};
