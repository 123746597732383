import { IconButton, styled } from "@mui/material";

export const Main = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "110%",
  zIndex: 1,
  width: 306,
  backgroundColor:
    theme.palette.mode === "dark"
      ? theme.palette.input.background
      : theme.palette.background.darkTheme,
  border: `1px solid ${
    theme.palette.mode === "dark"
      ? theme.palette.input.border
      : theme.palette.button.border
  }`,
  borderRadius: theme.shape.borderRadius,
}));

export const YearSelect = styled("div")(({ theme }) => ({
  boxSizing: "border-box",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(2),
  borderBottom: `1px solid ${
    theme.palette.mode === "dark"
      ? theme.palette.input.border
      : theme.palette.button.border
  }`,
}));

export const Year = styled("span")(({ theme }) => ({
  fontSize: 16,
  fontWeight: theme.typography.fontWeightBold,
}));

export const YearChange = styled(IconButton)(({ theme }) => ({
  width: 24,
  height: 24,
  marginLeft: theme.spacing(0.5),
  color: theme.palette.text.tertiary,

  "&.Mui-disabled": {
    color: theme.palette.text.primary,
    opacity: 0.2,
  },
}));

export const QuartersSelect = styled("div")(({ theme }) => ({
  boxSizing: "border-box",
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(1.5, 2),
}));

export const Quarter = styled("div")<{
  selected?: boolean;
  disabled?: boolean;
}>(({ theme, selected, disabled }) => ({
  width: "min-content",
  padding: theme.spacing(0.5),
  fontSize: 14,
  lineHeight: theme.spacing(2.5),
  fontWeight: selected ? theme.typography.fontWeightSemiBold : "initial",
  color: theme.palette.text[selected ? "primary" : "tertiary"],
  opacity: disabled ? 0.2 : 1,
  backgroundColor: selected ? theme.palette.background.rowHover : "initial",
  border: `${selected ? 1 : 0}px solid ${theme.palette.table.rowBorder}`,
  borderRadius: theme.shape.borderRadius,
  cursor: "pointer",
  pointerEvents: disabled ? "none" : "auto",
}));
