import { FC } from "react";

import SortArrowDown from "../SortArrowDown";
import SortArrowUp from "../SortArrowUp";
import { IconWrapper } from "./SortArrows.styled";

export interface ISortArrowsProps {
  sortType?: "asc" | "desc";
  isLight?: boolean;
}

export const SortArrows: FC<ISortArrowsProps> = ({ sortType, isLight }) => {
  return (
    <IconWrapper>
      <SortArrowUp sortType={sortType} isLight={isLight} />
      <SortArrowDown sortType={sortType} isLight={isLight} />
    </IconWrapper>
  );
};
