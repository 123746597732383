import { useMemo } from "react";

import { useFuturePlan } from "@/shared/Table/hooks/useFuturePlan";
import { useAppSelector } from "@/store/redux/hooks";
import { MODEL_IDS, MODELS } from "@/store/scheme/olap";

import { defaultRows, getIndicatorsByPeriod } from "../lib/helpers";
import { Rows } from "../lib/types";

export const useSumFoProjects = (periodId: string) => {
  const model = MODELS[MODEL_IDS.PROJECT_PASSPORT];
  const { isCheckFuturePlan } = useFuturePlan();
  const { sumProjectsByFo } = useAppSelector(
    (state) => state.regions.sumFoProjects
  );

  const currentDataState = useMemo(() => {
    const periodByFuturePlan = isCheckFuturePlan ? periodId : "120300000";
    return sumProjectsByFo?.[periodByFuturePlan];
  }, [periodId, sumProjectsByFo, isCheckFuturePlan]);

  const changeModelValue = useMemo(() => {
    return isCheckFuturePlan
      ? getIndicatorsByPeriod(model, periodId)
      : model.indexes.plan;
  }, [periodId, isCheckFuturePlan]);

  const rows: Rows[] = useMemo(() => {
    return Object.keys(currentDataState ?? {})?.map((regionId) => {
      return {
        id: regionId,
        ...{
          ...defaultRows(
            model.dataRegionId,
            changeModelValue,
            "0",
            currentDataState?.[regionId]
          ),
        },
        projectList: Object.keys(currentDataState?.[regionId] || {})
          ?.filter((item) => item !== "0")
          ?.map((projectId) => {
            return {
              id: projectId,
              ...{
                ...defaultRows(
                  model.dataProjectId,
                  changeModelValue,
                  projectId,
                  currentDataState?.[regionId]
                ),
              },
              status:
                currentDataState?.[regionId]?.[projectId]?.[model.dataProjectId]
                  ?.indicatorVals?.[model.indexes.status],
              isNotJumpOnPage:
                !!currentDataState?.[regionId]?.[projectId]?.[
                  model.dataProjectId
                ]?.["end_action"],
            };
          }),
      };
    });
  }, [currentDataState, changeModelValue]);

  return { rows };
};
