import { useCallback } from "react";

import { useAppDispatch, useAppSelector } from "@/store/redux/hooks";

import { extraActionsKktGraph } from "../model/slices/kktGraphSlice";

export const useKktGraph = () => {
  const dispatch = useAppDispatch();
  const { data: kktGraphData, fetching: kktGraphFetching } = useAppSelector(
    (state) => state.regions.kktGraph
  );

  const requestKktGraph = useCallback(
    (regionId: string, district: any, indicatorId: string) => {
      dispatch(
        extraActionsKktGraph.get({
          regionId,
          districtId: district?.id,
          indicatorId,
        })
      );
    },
    []
  );
  return { requestKktGraph, kktGraphData, kktGraphFetching };
};
