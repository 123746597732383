import React from "react";

import { useTabs } from "@/app/hooks/useTabs";
import { Footer } from "@/shared/Footer/ui/Footer";

import { useRegionFooter } from "./hooks/useRegionFooter";

export const RegionFooter = () => {
  const { activeTab } = useTabs();
  const { collectLegendsTab } = useRegionFooter();

  return <Footer>{collectLegendsTab(activeTab)}</Footer>;
};
