import { useTheme } from "@mui/material";
import * as echarts from "echarts";
import ReactECharts from "echarts-for-react";
import { Feature } from "geojson";
import { FC, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";

import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { BlockWpapper } from "@/shared/BlockWrapper/BlockWrapper";
import { COMPARE_TYPE } from "@/shared/CompareControl/lib/types";
import {
  compareSetDisabled,
  compareSetValue,
} from "@/shared/CompareControl/model/compareSlice";
import { formatNumber } from "@/shared/lib/heplers";
import { useAppDispatch, useAppSelector } from "@/store/redux/hooks";
import { TAB_ID } from "@/store/scheme/region";

import { useMapData } from "../hooks/useMapData";
import { useMapOptions } from "../hooks/useMapOptions";
import { currentDataT, MapProps } from "../lib/types";
import {
  COUNTRY_MAP_BY_DISTRICTS_ID,
  COUNTRY_MAP_BY_REGIONS_ID,
} from "../model/api/scheme";
import { geoExtraActions } from "../model/geoSlice";

export const Map: FC<MapProps> = ({
  vdlValues,
  selectedSubjectId,
  setSelectedSubjectId,
  selectedTabId,
}) => {
  // для ререндера карты
  // const [render, setRender] = useState(true);

  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { allDistrictIds, allRegions, allRegionIds, districtIds, regionIds } =
    useRegions();
  const { regionId } = useParams<"regionId">();
  const { districtId } = useParams<"districtId">();
  const [activeMapId, setActiveMap] = useState<string>();
  const {
    foData,
    foFetching,
    regionsData,
    regionsFetching,
    foError,
    regionsError,
  } = useAppSelector((state) => state.map.geo);

  const { enabled: achEnabled } = useAppSelector(
    (state) => state.controls.achievement
  );

  const { filteredFoData, filteredRegionsData } = useMapData({
    foData,
    allDistrictIds,
    regionsData,
    allRegionIds,
  });

  const currentData: currentDataT = districtId
    ? filteredRegionsData
    : filteredFoData;

  const getRegionCenterById = (id: string) =>
    allRegions.find((region) => String(id) === region.id)?.attributeVals
      .adm_center ?? "";

  const setMapValues = (id: string) => {
    switch (true) {
      case !achEnabled && selectedTabId === TAB_ID.RISKS:
        return `${formatNumber(
          Math.round(vdlValues[id]?.sum ?? NaN),
          undefined,
          0
        )}/${formatNumber(
          Math.round(vdlValues[id]?.value ?? NaN),
          undefined,
          0
        )}`;

      default:
        return formatNumber(
          Math.round(vdlValues[id]?.value ?? NaN),
          vdlValues[id]?.unit === "%" || vdlValues[id]?.unit === "тыс шт"
            ? "%"
            : undefined,
          0
        );
    }
  };

  const { option, onEvents } = useMapOptions({
    activeMapId,
    theme,
    selectedTabId,
    achEnabled,
    currentData,
    vdlValues,
    districtIds,
    regionIds,
    districtId,
    getRegionCenterById,
    selectedSubjectId,
    setMapValues,
    setSelectedSubjectId,
    navigate,
    location,
  });

  // initial getGeoJsons
  useEffect(() => {
    if (!foData && !foFetching && !foError) {
      dispatch(geoExtraActions.getGeoJson(COUNTRY_MAP_BY_DISTRICTS_ID));
    }
    if (!regionsData && !regionsFetching && !regionsError) {
      dispatch(geoExtraActions.getRegionsGeoJson(COUNTRY_MAP_BY_REGIONS_ID));
    }
  }, [
    dispatch,
    foData,
    regionsData,
    foFetching,
    regionsFetching,
    foError,
    regionsError,
  ]);

  // нужно для ререндера карты
  // const rernder = () => {
  //   setRender(false);
  //   setTimeout(() => {
  //     setRender(true);
  //   }, 100);
  // };

  // foData?.jsonData?.features - добавить в зависимости, когда включаете render
  // registerMap foData
  useEffect(() => {
    if (filteredFoData?.jsonData && !foFetching) {
      echarts.registerMap(COUNTRY_MAP_BY_DISTRICTS_ID, filteredFoData.jsonData);
      // rernder();
    }
  }, [filteredFoData, foFetching]);

  // registerMap regionsData
  useEffect(() => {
    if (districtId && filteredRegionsData?.jsonData && !regionsFetching) {
      echarts.registerMap(COUNTRY_MAP_BY_REGIONS_ID, {
        ...filteredRegionsData.jsonData,
        features: filteredRegionsData.jsonData.features
          .filter(
            ({ properties }: Feature) => properties?.district_id === districtId
          )
          .map((region: any) => ({
            ...region,
            properties: {
              ...region.properties,
              name: getRegionCenterById(region.properties.id),
            },
          })),
      });
    }
  }, [filteredRegionsData, regionsFetching, districtId, getRegionCenterById]);

  // setActiveMap
  useEffect(() => {
    if (
      regionsData?.jsonData &&
      foData?.jsonData &&
      !regionsFetching &&
      !foFetching
    ) {
      setActiveMap(
        districtId ? COUNTRY_MAP_BY_REGIONS_ID : COUNTRY_MAP_BY_DISTRICTS_ID
      );
    }
  }, [
    districtId,
    foFetching,
    regionsFetching,
    regionsData?.jsonData,
    foData?.jsonData,
  ]);

  useEffect(() => {
    dispatch(compareSetDisabled(!regionId));

    if (!regionId) dispatch(compareSetValue(COMPARE_TYPE.RF));
  }, [dispatch, regionId]);

  const currentFetching = useMemo(() => {
    return districtId ? regionsFetching : foFetching;
  }, [districtId, regionsFetching, foFetching]);

  return (
    <BlockWpapper
      hasData={!!Object.keys(option || {})?.length}
      isFetching={currentFetching}
    >
      {/* {render && ( */}
      <ReactECharts
        option={option}
        onEvents={onEvents}
        style={{ height: "33.37rem" }}
      />
      {/* )} */}
    </BlockWpapper>
  );
};
