import theme from "@app/theme/dark";
import { REGION_TABS } from "@store/scheme/region";
import { useMemo } from "react";
import { useParams } from "react-router";

import { AchievementVdl } from "@/features/AchievementVdl/ui/AchievementVdl";
import { EnterpriseWidget } from "@/features/EnterpriseWidget/ui/EnterpriseWidget";
import { GeneralIndicatorsEmployed } from "@/features/GeneralIndicatorsWidget/components/GeneralIndicatorsEmployed";
import { GeneralIndicatorsInvestment } from "@/features/GeneralIndicatorsWidget/components/GeneralIndicatorsInvestment/GeneralIndicatorsInvestment";
import { GeneralIndicatorsManufacturing } from "@/features/GeneralIndicatorsWidget/components/GeneralIndicatorsManufacturing";
import { GeneralIndicatorsProfit } from "@/features/GeneralIndicatorsWidget/components/GeneralIndicatorsProfit";
import { GeneralIndicatorsRate } from "@/features/GeneralIndicatorsWidget/components/GeneralIndicatorsRate";
import { KktWidget } from "@/features/KktWidget/ui/KktWidget";
import { NationalProjectsIndicators } from "@/features/NationalProjectsIndicators/ui/NationalProjectsIndicators";
import { BirthRate } from "@/features/RegionMutableEmployed/components/BirthRate";
import { DeathRate } from "@/features/RegionMutableEmployed/components/DeathRate";
import { DistrictValuesEmployed } from "@/features/RegionMutableEmployed/components/DistrictValuesEmployed";
import { Migration } from "@/features/RegionMutableEmployed/components/Migration";
import { Natality } from "@/features/RegionMutableEmployed/components/Natality";
import { SelfEmployed } from "@/features/RegionMutableEmployed/components/SelfEmployed";
import { UnemploymentRate } from "@/features/RegionMutableEmployed/components/UnemploymentRate";
import { DistrictValuesInvestment } from "@/features/RegionMutableInvestment/components/DistrictValuesInvestment";
import { VEDInvestment } from "@/features/RegionMutableInvestment/components/VEDInvestment/ui/VEDInvestment";
import { DistrictValuesManufacturing } from "@/features/RegionMutableManufacturing/components/DistrictValuesManufacturing";
import { VEDManufacturing } from "@/features/RegionMutableManufacturing/components/VEDManufacturing/ui/VEDManufacturing";
import { AverageMonthNominalSalary } from "@/features/RegionMutableProfit/components/AverageMonthNominalSalary";
import { AverageSalary } from "@/features/RegionMutableProfit/components/AverageSalary";
import { DistrictValuesProfit } from "@/features/RegionMutableProfit/components/DistrictValuesProfit";
import { Inflation } from "@/features/RegionMutableProfit/components/Inflation";
import { NominalIncome } from "@/features/RegionMutableProfit/components/NominalIncome";
import { AchieveRisksWrapper } from "@/features/RegionMutableRate/components/AchieveRisksWrapper";
import { Iswitcher } from "@/features/RegionMutableRate/lib/types";
import { RegionProjectsWidget } from "@/features/RegionProjectsWidget/ui/RegionProjectsWidget";
import { VdlLeader } from "@/features/VdlLeader/ui/VdlLeader";
import { Budget } from "@/shared/Budget/ui/Budget";

import { PopupItem, REGION_TABLES } from "../lib/types";

export const useTable = (
  selectedProject?: string,
  periodId?: string,
  switcher?: Iswitcher
) => {
  const { tabId } = useParams();

  const districtValue = useMemo(() => {
    if (tabId) {
      switch (tabId) {
        case REGION_TABS.EMPLOYED:
          return <DistrictValuesEmployed isOpenPopUp />;
        case REGION_TABS.INVESTMENT:
          return <DistrictValuesInvestment isOpenPopUp />;
        case REGION_TABS.PROFIT:
          return <DistrictValuesProfit isOpenPopUp />;
        case REGION_TABS.MANUFACTURING:
          return <DistrictValuesManufacturing isOpenPopUp />;
      }
    }
    return <DistrictValuesEmployed isOpenPopUp />;
  }, [tabId]);

  const TABLES: Record<REGION_TABLES, PopupItem> = {
    [REGION_TABLES.DEFAULT]: { component: <div /> },
    [REGION_TABLES.BUDGET]: {
      component: <Budget isOpenPopUp />,
      width: theme.spacing(126),
      height: "auto",
    },
    [REGION_TABLES.VED_MANUFACTURING]: {
      component: <VEDManufacturing isOpenPopUp />,
      width: theme.spacing(126),
      height: "auto",
    },
    [REGION_TABLES.VED_INVESTMENT]: {
      component: <VEDInvestment isOpenPopUp />,
      width: theme.spacing(126),
      height: "auto",
    },
    [REGION_TABLES.PROJECTS_TABLE]: {
      component: <RegionProjectsWidget isOpenPopUp selectedPeriod={periodId} />,
      width: theme.spacing(225),
      height: "auto",
    },
    [REGION_TABLES.DISTRICT_VALUES]: {
      component: districtValue,
      width: theme.spacing(68),
      height: "auto",
    },
    [REGION_TABLES.INFLATION]: {
      component: <Inflation isOpenPopUp />,
      width: theme.spacing(90),
    },
    [REGION_TABLES.AVERAGE_SALARY]: {
      component: <AverageSalary isOpenPopUp />,
      width: theme.spacing(90),
    },
    [REGION_TABLES.KKT]: {
      component: <KktWidget isOpenPopUp />,
      width: theme.spacing(90),
    },
    [REGION_TABLES.NATALITY]: {
      component: <Natality isOpenPopUp />,
      width: theme.spacing(90),
    },
    [REGION_TABLES.BIRTH_RATE]: {
      component: <BirthRate isOpenPopUp />,
      width: theme.spacing(90),
    },
    [REGION_TABLES.DEATH_RATE]: {
      component: <DeathRate isOpenPopUp />,
      width: theme.spacing(90),
    },
    [REGION_TABLES.UNEMPLOYMENT_RATE]: {
      component: <UnemploymentRate isOpenPopUp />,
      width: theme.spacing(90),
    },
    [REGION_TABLES.MIGRATION]: {
      component: <Migration isOpenPopUp />,
      width: theme.spacing(90),
    },
    [REGION_TABLES.SELF_EMPLOYED]: {
      component: <SelfEmployed isOpenPopUp />,
      width: theme.spacing(90),
    },
    [REGION_TABLES.VDL]: {
      component: <VdlLeader isOpenPopUp />,
    },
    [REGION_TABLES.ACHIEVEMENT_VDL]: {
      component: <AchievementVdl isOpenPopUp />,
      height: "auto",
    },
    [REGION_TABLES.ACHIEVEMENTS]: {
      component: <AchieveRisksWrapper isOpenPopUp switcher={switcher} />,
      height: "auto",
    },
    [REGION_TABLES.NOMINAL_INCOME]: {
      component: <NominalIncome isOpenPopUp />,
      width: theme.spacing(90),
    },
    [REGION_TABLES.NATIONAL_PROJECTS_INDICATORS]: {
      component: (
        <NationalProjectsIndicators
          isOpenPopUp
          selectedProject={selectedProject}
        />
      ),
      width: theme.spacing(126),
    },
    [REGION_TABLES.GENERAL_INDICATORS_RATE]: {
      component: <GeneralIndicatorsRate isOpenPopUp />,
      width: theme.spacing(126),
    },
    [REGION_TABLES.GENERAL_INDICATORS_INVESTMENT]: {
      component: <GeneralIndicatorsInvestment isOpenPopUp />,
      width: theme.spacing(126),
    },
    [REGION_TABLES.GENERAL_INDICATORS_MANUFACTURING]: {
      component: <GeneralIndicatorsManufacturing isOpenPopUp />,
      width: theme.spacing(126),
    },
    [REGION_TABLES.GENERAL_INDICATORS_PROFIT]: {
      component: <GeneralIndicatorsProfit isOpenPopUp />,
      width: theme.spacing(126),
    },
    [REGION_TABLES.GENERAL_INDICATORS_EMPLOYED]: {
      component: <GeneralIndicatorsEmployed isOpenPopUp />,
      width: theme.spacing(126),
    },
    [REGION_TABLES.AVERAGE_MONTH_NOMINAL_SALARY]: {
      component: <AverageMonthNominalSalary isOpenPopUp />,
      width: theme.spacing(90),
    },
    [REGION_TABLES.ENTERPRISE]: {
      component: <EnterpriseWidget isOpenPopUp />,
      width: theme.spacing(126),
      height: "auto",
    },
  };

  return { TABLES };
};
