import AccessTime from "@mui/icons-material/AccessTime";
import { useAppDispatch, useAppSelector } from "@store/redux/hooks";
import { FC } from "react";

import { Control } from "@/shared/Control/ui/Control";

import { periodToggle } from "../model/periodSlice";
import { ToggleButtonStyled } from "./PeriodControl.styled";

export const PeriodControl: FC = () => {
  const dispatch = useAppDispatch();
  const { enabled } = useAppSelector((state) => state.controls.period);

  const handleChange = () => {
    dispatch(periodToggle(!enabled));
  };

  return (
    <Control>
      <ToggleButtonStyled
        selected={enabled}
        value="period"
        onClick={handleChange}
      >
        <AccessTime />
      </ToggleButtonStyled>
    </Control>
  );
};
