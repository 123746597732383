import { MODEL_IDS, MODELS } from "@/store/scheme/olap";

export const riskIndicators = ["153", "154", "155"];
export const compareRisks: Record<string, string> = {
  "153": "high",
  "154": "middle",
  "155": "low",
};

export const riskValues = {
  status: MODELS[MODEL_IDS.RISKS].indexes.status,
  color: MODELS[MODEL_IDS.RISKS].indexes.color,
  fact: MODELS[MODEL_IDS.RISKS].indexes.fact,
  percent: MODELS[MODEL_IDS.RISKS].indexes.plan,
};
