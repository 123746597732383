import { combineReducers } from "@reduxjs/toolkit";

import { IMapReducer } from "@/features/Map/lib/types";
import map from "@/store/redux/slices/mapReducer";

import { IIndicatorsReducer } from "../../shared/Table/lib/types";
import auth, { IAuthReducer } from "./slices/auth/reducer";
import controls, { IControlsReducer } from "./slices/controlReducer";
import feedback, { IFeedbackReducer } from "./slices/feedbackReducer";
import projectsReducer, {
  IPagesProjectReducer,
} from "./slices/projectsReducer";
import regions, { IRegionsReducer } from "./slices/regionsReducer";
import tableIndicators from "./slices/tableReducer";
import ui, { IUIReducer } from "./slices/uiReducer";

export interface IState {
  auth: IAuthReducer;
  controls: IControlsReducer;
  indicators: IIndicatorsReducer;
  map: IMapReducer;
  pages: IPagesProjectReducer;
  regions: IRegionsReducer;
  ui: IUIReducer;
  feedback: IFeedbackReducer;
}

const reducer = combineReducers({
  auth,
  controls,
  indicators: tableIndicators,
  map,
  pages: projectsReducer,
  regions,
  ui,
  feedback,
});

export default reducer;
