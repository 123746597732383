import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Divider, FormControl, Stack } from "@mui/material";
import { FC, Fragment, useEffect, useMemo, useState } from "react";

import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { REGION_COUNTRY_CODE } from "@/shared/lib/constants";
import { useAppSelector } from "@/store/redux/hooks";

import { IAppBarFilter, Item, targetType } from "../../lib/types";
import {
  BootstrapInput as mainBI,
  ListTextStyled as mainLTS,
  MenuItemStyled,
  SelectStyled,
  WrapperStack as mainWS,
} from "./AppBarFilter.styled";
import { AppBarFilterItem } from "./AppBarFilterItem";
import {
  BootstrapInput as vdlBI,
  ListTextStyled as vdlLTS,
  WrapperStack as vdlWS,
} from "./VDLFilter.styled";

export const AppBarFilter: FC<IAppBarFilter> = ({
  title = "",
  arrayData,
  subjectId,
  indexCurrentValue,
  defaultValue,
  useRF,
  useFO,
  isVDL,
  onChange,
}) => {
  const { user } = useAppSelector((state) => state.auth.user);
  const { districts, regionIds, getRegionsByParent } = useRegions();
  const [selectValue, setSelectValue] = useState<string>("");
  const [isOpen, setOpen] = useState<boolean>(false);

  const BootstrapInput = isVDL ? vdlBI : mainBI;
  const ListTextStyled = isVDL ? vdlLTS : mainLTS;
  const WrapperStack = isVDL ? vdlWS : mainWS;
  const curValue = selectValue || defaultValue;

  const handleChangeRegion = (id: string) => {
    const value = arrayData.find((item) => item?.id === id);
    if (value) {
      setSelectValue(value?.name);
      setOpen(false);
      if (onChange) onChange({ id: value.id, type: targetType.REGION });
    }
  };

  const handleChangeFO = (item: Item) => {
    setSelectValue(item?.name);
    setOpen(false);
    if (onChange) onChange({ id: item.id, type: targetType.FO });
  };

  const handleToggleSelect = () => {
    setOpen((status) => !status);
  };

  const regionsDropdown = useMemo(() => {
    const getDisctrictChildren = (
      districtId: string
    ): { id: string; name: string }[] => {
      const regions = getRegionsByParent(districtId)
        ?.filter((region) =>
          regionIds.length ? regionIds.includes(region.id) : true
        )
        ?.sort(
          (sortA, sortB) =>
            +sortA.attributeVals.code_fns - +sortB.attributeVals.code_fns
        )
        ?.map((region) => ({
          id: region?.id,
          name: region?.attributeVals?.name,
        }));
      return regions || [];
    };

    const result = districts.map((district) => ({
      id: district?.id,
      name: district?.attributeVals?.name,
      children: getDisctrictChildren(district?.id),
    }));

    if (useRF) {
      result.unshift({
        id: REGION_COUNTRY_CODE,
        name: "Российская Федерация",
        children: [],
      });
    }

    return result;
  }, [districts, getRegionsByParent, useRF]);

  useEffect(() => {
    if (~(indexCurrentValue ?? 0) && arrayData.length > 0) {
      setSelectValue(arrayData[indexCurrentValue || 0]?.name);
    }
  }, [arrayData, indexCurrentValue]);

  useEffect(() => {
    if (subjectId && arrayData.length > 0) {
      const currentValue =
        arrayData.find(({ id }) => id === subjectId) ||
        regionsDropdown.find(({ id }) => id === subjectId);

      setSelectValue((currentValue?.name || defaultValue) ?? "");
    }
  }, [arrayData, regionsDropdown, subjectId, defaultValue]);

  return (
    <WrapperStack direction="row">
      <Stack
        direction="row"
        alignItems="center"
        flex={1}
        spacing={2}
        sx={{ overflow: "hidden" }}
      >
        <FormControl fullWidth>
          <SelectStyled
            variant={isVDL ? "standard" : undefined}
            MenuProps={{ disablePortal: true }}
            open={isOpen}
            IconComponent={KeyboardArrowDownIcon}
            onOpen={handleToggleSelect}
            onClose={handleToggleSelect}
            value={curValue}
            input={<BootstrapInput title={title} />}
            renderValue={() => (
              <ListTextStyled primary={title} secondary={curValue} />
            )}
          >
            {regionsDropdown?.map((item) => [
              item?.id === REGION_COUNTRY_CODE ? (
                <Fragment key={item?.id}>
                  <MenuItemStyled
                    key={item?.id}
                    value={item?.id}
                    selected={curValue === item?.name}
                    onClick={() => !user?.isAnalyticFK && handleChangeFO(item)}
                  >
                    {item?.name}
                  </MenuItemStyled>
                  <Divider variant="middle" />
                </Fragment>
              ) : (
                <AppBarFilterItem
                  key={item.id}
                  curValue={curValue}
                  item={item}
                  handleChangeRegion={handleChangeRegion}
                  handleChangeFO={useFO ? handleChangeFO : undefined}
                />
              ),
            ])}
          </SelectStyled>
        </FormControl>
      </Stack>
    </WrapperStack>
  );
};
