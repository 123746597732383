import { ElementType, ReactNode } from "react";

import { Iswitcher } from "@/features/RegionMutableRate/lib/types";

export enum REGION_TABLES {
  DEFAULT = "DEFAULT",
  BUDGET = "BUDGET",
  VED_MANUFACTURING = "VED_MANUFACTURING",
  VED_INVESTMENT = "VED_INVESTMENT",
  PROJECTS_TABLE = "PROJECTS_TABLE",
  DISTRICT_VALUES = "DISTRICT_VALUES",
  GENERAL_INDICATORS_RATE = "GENERAL_INDICATORS_RATE",
  GENERAL_INDICATORS_INVESTMENT = "GENERAL_INDICATORS_INVESTMENT",
  GENERAL_INDICATORS_MANUFACTURING = "GENERAL_INDICATORS_MANUFACTURING",
  GENERAL_INDICATORS_PROFIT = "GENERAL_INDICATORS_PROFIT",
  GENERAL_INDICATORS_EMPLOYED = "GENERAL_INDICATORS_EMPLOYED",
  NOMINAL_INCOME = "NOMINAL_INCOME",
  AVERAGE_MONTH_NOMINAL_SALARY = "AVERAGE_MONTH_NOMINAL_SALARY",
  INFLATION = "INFLATION",
  AVERAGE_SALARY = "AVERAGE_SALARY",
  KKT = "KKT",
  NATALITY = "NATALITY",
  BIRTH_RATE = "BIRTH_RATE",
  DEATH_RATE = "DEATH_RATE",
  UNEMPLOYMENT_RATE = "UNEMPLOYMENT_RATE",
  MIGRATION = "MIGRATION",
  SELF_EMPLOYED = "SELF_EMPLOYED",
  NATIONAL_PROJECTS_INDICATORS = "NATIONAL_PROJECTS_INDICATORS",
  VDL = "VDL",
  ACHIEVEMENTS = "ACHIEVEMENTS",
  ACHIEVEMENT_VDL = "ACHIEVEMENT_VDL",
  ENTERPRISE = "ENTERPRISE",
}

export interface IPopUpProps {
  popUpId?: REGION_TABLES;
  isOpenPopUp?: boolean;
}

export interface IRestoreRegionPopUp {
  PopUpButton: ElementType;
  PopUp: ElementType;
  popupHeightMax?: string;
}

export interface IRegionPopUpProps {
  open: boolean;
  table?: REGION_TABLES;
  handleOpen?: () => void;
  title?: string | ReactNode;
  selectedProject?: string;
  periodId?: string;
  minWidth?: number;
  switcher?: Iswitcher;
}

export interface PopupItem {
  component: ReactNode;
  width?: number | string;
  height?: number | string;
  zoom?: number;
}

export interface WrapperProps {
  width?: number | string;
  height?: number | string;
  scale?: number;
}
