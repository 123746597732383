import { Button, styled } from "@mui/material";

export const IncidentHeaderTitle = styled("h4")(({ theme }) => ({
  color: theme.palette.text.primary,
}));

export const IncidentHeaderGroup = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  "& > button > svg > path": {
    fill: theme.palette.graphs.graphCloseButton,
  },
}));

export const IncidentScreenButton = styled(Button)(({ theme }) => ({
  minWidth: theme.spacing(4),
}));

export const IncidentMain = styled("main")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: "100%",
  color: theme.palette.text.primary,
  fontSize: theme.spacing(2),
  lineHeight: theme.spacing(2.5),
  padding: theme.spacing(0, 4),
  "& > p > .loader-wrapper": {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-0, -50%)",
  },
}));

export const IncidentFooter = styled("footer")<{ isCreated?: boolean }>(
  ({ theme, isCreated }) => ({
    display: "flex",
    justifyContent: isCreated ? "space-between" : "flex-end",
    alignItems: "center",
    minHeight: theme.spacing(11),
    padding: theme.spacing(0, 4),
    background: theme.palette.dropdownWidget.baseHeader,
    color: theme.palette.text.primary,
    "& > a": {
      textDecoration: "underline",
    },
  })
);

export const GroupButton = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
}));
