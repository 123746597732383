import { useCallback, useMemo } from "react";

import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { COMPARE_TYPE } from "@/shared/CompareControl/lib/types";
import { useAppDispatch, useAppSelector } from "@/store/redux/hooks";
import { MODEL_IDS, MODELS } from "@/store/scheme/olap";

import { indicatorsTitle } from "../lib/constants";
import { extraActionsGeneralIndicators } from "../model/slices/generalIndicatorsSlice";

// Используется в компонентах, которые содержат в себе GeneralIndicators
export const useGeneralIndicators = () => {
  const model = MODELS[MODEL_IDS.KPI_SOC_ECONOMY];
  const {
    data,
    genIndicatorPassport,
    investmentData,
    investmentRating,
    fetching,
  } = useAppSelector((state) => state.regions.generalIndicators);
  const { compare } = useAppSelector((state) => state.controls);
  const dispatch = useAppDispatch();
  const { isCheckRfFoPage } = useRegions();

  const indicators = useMemo(() => {
    return {
      place:
        compare.value === COMPARE_TYPE.FO
          ? model.indexes.placeFO
          : model.indexes.placeRF,
      placeChange:
        compare.value === COMPARE_TYPE.FO
          ? model.indexes.placeChangeFO
          : model.indexes.placeChangeRF,
      colorChange:
        compare.value === COMPARE_TYPE.FO
          ? model.indexes.colorChangeFO
          : model.indexes.colorChangeRF,
      highLow:
        compare.value === COMPARE_TYPE.FO
          ? model.indexes.factHighLowFO
          : model.indexes.factHighLowRF,
    };
  }, [compare.value]);

  const requestGeneralIndicators = useCallback(
    (regionId: string, dataSummaryId: string, district: any) => {
      dispatch(
        extraActionsGeneralIndicators.get({
          regionId,
          districtId: district?.id,
          dataSummaryId,
        })
      );
      if (isCheckRfFoPage.isAll) {
        dispatch(
          extraActionsGeneralIndicators.investment({
            regionId,
            districtId: district?.id,
            dataSummaryId,
          })
        );
      }
    },
    [isCheckRfFoPage.isAll]
  );

  const generalIndicatorsTitle = useMemo(() => {
    const { title, subTitle } = indicatorsTitle;
    return `${title} ${!isCheckRfFoPage.isAll ? subTitle : ""}`;
  }, [isCheckRfFoPage.isAll]);

  return {
    data,
    investmentData,
    genIndicatorPassport,
    generalIndicatorsTitle,
    requestGeneralIndicators,
    indicators,
    investmentRating,
    fetching,
  };
};
