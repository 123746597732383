import { REGION_COUNTRY_CODE } from "@/shared/lib/constants";
import { ArgumentsWithDistrictIdType } from "@/shared/lib/types";
import { getOperands, olapRequestData2 } from "@/store/api/core";
import {
  DATA_ACTUAL_VALUE,
  FILTER_OPERAND_TYPE,
  FILTER_TYPE,
  MODEL_IDS,
  MODELS,
} from "@/store/scheme/olap";

export const getTabsAchievements = ({
  regionId,
  districtId,
  dataSummaryId,
}: ArgumentsWithDistrictIdType) => {
  const model = MODELS[MODEL_IDS.KPI_SOC_ECONOMY];

  const dimensions = [
    {
      id: model.dataPeriodId,
      includeItems: true,
      includeAttributesByCodes: ["REPORT_DATE"],
    },
    { id: model.dataRegionId },
    { id: model.dimId },
    { id: model.dataActualId },
    { id: model.dataSummaryId },
  ];

  const indicators = {
    id: model.indicatorId,
    items: [
      { id: model.indexes.fact },
      { id: model.indexes.plan },
      { id: model.indexes.factChangeAppg },
      { id: model.indexes.factHighLowRF },
      { id: model.indexes.factHighLowFO },
      { id: model.indexes.color },
      { id: model.indexes.colorGraph },
    ],
  };

  const operands = getOperands([
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      dim_id: model.dataPeriodId,
      showLeafsOnly: false,
      showChildren: false,
      levels: [2, 3],
      showAllLevelElements: false,
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataRegionId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: [regionId, districtId, REGION_COUNTRY_CODE],
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dimId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: [
            "1",
            "2",
            "3",
            "4",
            "10",
            "104",
            "121",
            "123",
            "127",
            "132",
            "133",
          ],
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataActualId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: [DATA_ACTUAL_VALUE],
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataSummaryId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: [dataSummaryId],
        },
      ],
    },
  ]);

  return olapRequestData2(
    dimensions.map(({ id }) => id),
    model.indicatorId,
    {
      modelUuid: model.uuid,
      includeGaps: false,
      indicatorId: model.indicatorId,
      dimensions,
      dataRequestCaching: true,
      indicators,
      dataFilter: {
        type: FILTER_TYPE.AND,
        version: 1,
        operands,
      },
    },
    "2aed3aaa-3f1d-480b-a4e2-2d088d4373c0"
  );
};

export const getTabsDynamics = (regionId: string, districtId: string) => {
  const model = MODELS[MODEL_IDS.KPI_SOC_ECONOMY];

  const dimensions = [
    {
      id: model.dataPeriodId,
      includeItems: true,
      includeAttributesByCodes: ["REPORT_DATE"],
    },
    {
      id: model.dataRegionId,
    },
    {
      id: model.dimId,
    },
    {
      id: model.dataActualId,
    },
  ];

  const indicators = {
    id: model.indicatorId,
    items: [
      { id: model.indexes.fact },
      { id: model.indexes.plan },
      { id: model.indexes.factChangeAppg },
      { id: model.indexes.color },
      { id: model.indexes.factHighLowRF },
      { id: model.indexes.factHighLowFO },
    ],
  };

  const operands = getOperands([
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      version: 1,
      dim_id: model.dataPeriodId,
      showLeafsOnly: false,
      showChildren: false,
      levels: [2, 3],
      showAllLevelElements: false,
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      version: 1,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          version: 1,
          id: model.dataRegionId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          version: 1,
          values: [regionId, districtId, REGION_COUNTRY_CODE],
        },
      ],
    },

    {
      type: FILTER_OPERAND_TYPE.EQ,
      version: 1,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          version: 1,
          id: model.dimId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          version: 1,
          values: ["1", "2", "3", "4", "121"],
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      version: 1,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          version: 1,
          id: model.dataActualId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          version: 1,
          values: ["2"],
        },
      ],
    },
  ]);

  return olapRequestData2(
    dimensions.map(({ id }) => id),
    model.indicatorId,
    {
      modelUuid: model.uuid,
      includeGaps: false,
      indicatorId: model.indicatorId,
      dimensions,
      dataRequestCaching: true,
      indicators,
      dataFilter: {
        type: FILTER_TYPE.AND,
        version: 1,
        operands,
      },
    }
  );
};

export const getTabsMainAchievements = ({
  regionId,
  districtId,
  dataSummaryId,
}: ArgumentsWithDistrictIdType) => {
  const model = MODELS[MODEL_IDS.PROJECT_PASSPORT];

  const dimensions = [
    {
      id: model.dataPeriodId,
      includeItems: true,
      includeAttributesByCodes: ["REPORT_DATE"],
    },
    {
      id: model.dataRegionId,
    },
    {
      id: model.dataProjectId,
      includeItems: true,
      includeAttributesByCodes: ["name", "implementation_period"],
    },
    {
      id: model.dimId,
    },
    {
      id: model.dataActualId,
    },
    {
      id: model.dataSummaryId,
    },
  ];

  const indicators = {
    id: model.indicatorId,
    items: [
      { id: model.indexes.plan },
      { id: model.indexes.percent },
      { id: model.indexes.status },
      { id: model.indexes.achievement },
      { id: model.indexes.fact },
      { id: model.indexes.achievementProjectColor },
      { id: model.indexes.color },
    ],
  };

  const operands = getOperands([
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      version: 1,
      dim_id: model.dataPeriodId,
      showLeafsOnly: false,
      showChildren: false,
      levels: [1, 2],
      showAllLevelElements: false,
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      version: 1,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          version: 1,
          id: model.dataRegionId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          version: 1,
          values: [regionId],
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      version: 1,
      dim_id: model.dataProjectId,
      showLeafsOnly: false,
      showChildren: false,
      levels: [0, 1],
      showAllLevelElements: false,
    },
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      version: 1,
      dim_id: model.dimId,
      showLeafsOnly: false,
      showChildren: false,
      levels: [0, 1],
      showAllLevelElements: false,
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      version: 1,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          version: 1,
          id: model.dataActualId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          version: 1,
          values: ["2"],
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      version: 1,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          version: 1,
          id: model.dataSummaryId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          version: 1,
          values: [dataSummaryId],
        },
      ],
    },
  ]);

  return olapRequestData2(
    dimensions.map(({ id }) => id),
    model.indicatorId,
    {
      modelUuid: model.uuid,
      includeGaps: false,
      indicatorId: model.indicatorId,
      dimensions,
      dataRequestCaching: true,
      indicators,
      dataFilter: {
        type: FILTER_TYPE.AND,
        version: 1,
        operands,
      },
    },
    "f26d61a7-ded9-4418-9b5a-bc51cd5119b7"
  );
};

export const getTabsCheckpointsAchievements = (
  regionId: string,
  districtId: string
) => {
  const model = MODELS[MODEL_IDS.PROJECT_RESULTS];

  const dimensions = [
    {
      id: model.dataPeriodId,
      includeItems: true,
      includeAttributesByCodes: ["REPORT_DATE"],
    },
    {
      id: model.dataRegionId,
    },
    {
      id: model.dataProjectId,
    },
    {
      id: model.dataResultId,
    },
    {
      id: model.dataCheckpointId,
    },
    {
      id: model.dimId,
    },
  ];

  const indicators = {
    id: model.indicatorId,
    items: [{ id: model.indexes.percent }],
  };

  const operands = getOperands([
    {
      type: FILTER_OPERAND_TYPE.EQ,
      version: 1,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          version: 1,
          id: model.dataRegionId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          version: 1,
          values: [regionId],
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      version: 1,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          version: 1,
          id: model.dataProjectId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          version: 1,
          values: ["0"],
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      version: 1,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          version: 1,
          id: model.dataResultId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          version: 1,
          values: ["0"],
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      version: 1,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          version: 1,
          id: model.dataCheckpointId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          version: 1,
          values: ["0"],
        },
      ],
    },

    {
      type: FILTER_OPERAND_TYPE.EQ,
      version: 1,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          version: 1,
          id: model.dataActualId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          version: 1,
          values: ["2"],
        },
      ],
    },
  ]);

  return olapRequestData2(
    dimensions.map(({ id }) => id),
    model.indicatorId,
    {
      modelUuid: model.uuid,
      includeGaps: false,
      indicatorId: model.indicatorId,
      dimensions,
      dataRequestCaching: true,
      indicators,
      dataFilter: {
        type: FILTER_TYPE.AND,
        version: 1,
        operands,
      },
    },
    "3a96082d-b622-409d-80cc-53c7c7d9c76c"
  );
};
