import { useTheme } from "@mui/material";
import React, { FC } from "react";

import { useVDL } from "@/features/VdlLeader/hooks/useVDL";

import {
  AgeWrapper,
  AvatarStyled,
  AvatarWrapper,
  LeaderInfo,
  NameWrapper,
} from "./Leader.styled";

export const Leader: FC<{ isOpenPopUp?: boolean }> = ({ isOpenPopUp }) => {
  const theme = useTheme();
  const { leaderData } = useVDL();
  const { FIO, photo, age } = leaderData || {};

  const getFullName = () => {
    return !isOpenPopUp ? (
      FIO
    ) : (
      <>
        {FIO?.split(" ")?.[0]} <br /> {FIO?.split(" ")?.slice(1)?.join(" ")}
      </>
    );
  };

  return (
    <AvatarWrapper>
      {leaderData && (
        <>
          <AvatarStyled
            isOpenPopUp={isOpenPopUp}
            alt={FIO}
            src={photo}
            sx={{ background: theme.palette.text.primary }}
          />
          <LeaderInfo>
            <NameWrapper isOpenPopUp={isOpenPopUp}>{getFullName()}</NameWrapper>
            {age && <AgeWrapper>{age + " лет"}</AgeWrapper>}
          </LeaderInfo>
        </>
      )}
    </AvatarWrapper>
  );
};
