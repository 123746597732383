import { MODEL_IDS, MODELS } from "@store/scheme/olap";
import React, { FC, useMemo } from "react";

import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { useGeneralIndicators } from "@/shared/GeneralIndicators/hooks/useGeneralIndicators";
import { IValue } from "@/shared/GeneralIndicators/lib/types";
import { GeneralIndicators } from "@/shared/GeneralIndicators/ui/GeneralIndicators";
import { formatNumber } from "@/shared/lib/heplers";
import { IPopUpProps, REGION_TABLES } from "@/shared/RegionPopUp/lib/types";

import { grossDomesticProduct, grossRegionalProduct } from "../lib/constants";
import { getCorrectUnitIntoTrillion } from "../lib/helpers";

export const GeneralIndicatorsManufacturing: FC<IPopUpProps> = ({
  isOpenPopUp,
}) => {
  const { data, indicators, generalIndicatorsTitle, fetching } =
    useGeneralIndicators();
  const model = MODELS[MODEL_IDS.KPI_SOC_ECONOMY];
  const { isCheckRfFoPage } = useRegions();

  const getIndicatorByPage = isCheckRfFoPage.isRF
    ? [...grossDomesticProduct]
    : [...grossRegionalProduct];

  const values: IValue[] = useMemo(() => {
    return getIndicatorByPage.map((item) => {
      const subjectData = data?.[item];

      return {
        key: item,
        title:
          item === "13"
            ? "ВВП на душу населения"
            : item === "11"
            ? "ВВП"
            : subjectData?.short_name,
        // Для ВРП в карточке ФО значение в трилл руб
        value:
          isCheckRfFoPage.isFO && item === "31"
            ? getCorrectUnitIntoTrillion(
                subjectData?.indicatorVals?.[model.indexes.fact]
              )
            : formatNumber(
                subjectData?.indicatorVals?.[model.indexes.fact],
                undefined,
                1
              ),
        date: subjectData?.attributeVals?.REPORT_DATE,
        // Для ВРП в карточке ФО значение в трилл руб
        unit:
          isCheckRfFoPage.isFO && item === "31"
            ? "трилл руб"
            : subjectData?.UNIT,
        ...(!isCheckRfFoPage.isRF && {
          place: subjectData?.indicatorVals?.[indicators.place],
          placeChange: formatNumber(
            subjectData?.indicatorVals?.[indicators.placeChange],
            undefined,
            0,
            true
          ),
          placeChangeRate: subjectData?.indicatorVals?.[indicators.colorChange],
        }),
      };
    });
  }, [
    data,
    indicators.colorChange,
    indicators.place,
    indicators.placeChange,
    getIndicatorByPage,
    isCheckRfFoPage.isRF,
    isCheckRfFoPage.isFO,
  ]);

  return (
    <GeneralIndicators
      isNotGetDate={true}
      generalFetching={fetching}
      title={generalIndicatorsTitle}
      typography="light"
      values={values}
      isOpenPopUp={isOpenPopUp}
      popUpId={REGION_TABLES.GENERAL_INDICATORS_MANUFACTURING}
    />
  );
};
