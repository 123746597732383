import { Tab, TabPanel, Tabs, TabsList } from "@mui/base";
import { Button, styled, ToggleButtonGroup } from "@mui/material";

import { ITabsListProps } from "../../lib/types";

export const TabsWrapper = styled(Tabs)<{
  isRisks?: boolean;
  isDinamic?: boolean;
}>(({ theme, isRisks = false, isDinamic = false }) => {
  return {
    margin: theme.spacing(0, 3),
    position: "relative",
    "& > .hoverIncident": {
      top: 0,
      display: "none",
    },
    "&:hover > .hoverIncident": {
      display: "block",
    },
    ...(!isDinamic && {
      ...(isRisks && {
        "@media (max-width: 133rem)": {
          padding: "3rem 0",
        },
      }),
      ...(!isRisks && {
        "@media (max-width: 141rem)": {
          padding: "3rem 0",
        },
      }),
    }),
    ...(isDinamic && {
      "@media (max-width: 120rem)": {
        padding: "3rem 0",
      },
    }),
  };
});

export const TabsListUnstyled = styled(TabsList)(({ theme }) => ({
  display: "flex",
  "&:focus": {
    outline: "none",
  },
}));

export const TabUnstyled = styled(Tab, {
  shouldForwardProp: (prop) => prop !== "afterElement",
})<ITabsListProps>(({ theme, disabled, afterElement, isRisks }) => ({
  outline: "none",
  fontSize: theme.spacing(2.5),
  padding: theme.spacing(2, 4.5),
  borderRadius: theme.spacing(2, 2, 0, 0),
  border: 0,
  background: "none",
  color: theme.palette.tabs.textColor,
  fontWeight: theme.typography.fontWeightBold,
  ...(disabled ? { cursor: "not-allowed" } : { cursor: "pointer" }),
  "&.Mui-selected": {
    color: theme.palette.tabs.textColorSelect,
    backgroundColor: theme.palette.tabs.selectedDark,
    position: "relative",
    ...(afterElement
      ? {
          "&:after": {
            display: "block",
            content: '""',
            position: "absolute",
            top: "100%",
            ...(!isRisks ? { left: 0 } : { right: 0 }),
            width: theme.spacing(2),
            height: theme.spacing(2),
            backgroundColor: theme.palette.background.paper,
          },
        }
      : {}),
  },
  "&:focus": {
    outline: "none",
  },
}));

export const TabPlug = styled(Tab)(({ theme }) => ({
  color: "transparent",
  cursor: "default",
  background: "transparent",
  outline: "none",
  border: "none",
}));

export const TabPanelUnstyled = styled(TabPanel)(({ theme }) => ({
  background: theme.palette.tabs.background,
  borderRadius: theme.spacing(2),
  height: `calc(100vh - 190px)`,
  "&.TabPanel-root:first-of-type": {
    borderRadius: theme.spacing(0, 2, 2, 2),
  },
}));

export const TabPanelTable = styled(TabPanel, {
  shouldForwardProp: (prop) => !["withFooter"].includes(prop.toString()),
})<{ withFooter?: boolean }>(({ theme, withFooter }) => ({
  background: theme.palette.tabs.background,
  borderRadius: theme.spacing(2),
  height: `calc(100vh - ${withFooter ? 300 : 255}px)`,
  "&.TabPanel-root:first-of-type": {
    borderRadius: theme.spacing(0, 2, 2, 2),
  },
  padding: withFooter ? theme.spacing(4, 2, 4, 4) : theme.spacing(4, 2, 0, 4),
  backgroundColor: theme.palette.background.paper,
  "@media (min-width: 1920px)": {
    ...(withFooter && {
      marginTop: "3rem",
    }),
  },
}));

export const Panel = styled(TabPanelTable)(({ theme }) => ({
  "&.TabPanel-root:first-of-type": {
    borderRadius: theme.spacing(2),
  },
}));

export const TabPanelWrapper = styled("div", {
  shouldForwardProp: (prop) => !["withFooter"].includes(prop.toString()),
})<{ withFooter?: boolean }>(({ theme, withFooter }) => ({
  width: "100%",
  height: `calc(100vh - ${withFooter ? 360 : 255}px)`,
}));

export const ButtonsWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  right: 0,
  top: 0,
  display: "flex",
  alignItems: "center",
  padding: "0 0 0 1rem",
  gap: 20,
}));

export const SwitcherButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: theme.spacing(5),
  maxWidth: theme.spacing(17),
  padding: theme.spacing(0, 0.5),
  background:
    theme.palette.mode === "dark"
      ? theme.palette.appBar.achievementBackground
      : theme.palette.text.onAccent,
  border: `1px solid ${theme.palette.button.border}`,
  boxSizing: "border-box",
}));

export const MapButtonStyled = styled(Button)(({ theme }) => ({
  color: theme.palette.button.notSelected,
  "&.MuiButton-root": {
    minWidth: theme.spacing(5),
    padding: 0,
    margin: "auto",
    height: "100%",
    "&:hover": {
      background: "none",
    },
  },
}));

export const ToggleButtonStyled = styled("div")<{ isSelected: boolean }>(
  ({ theme, isSelected }) => ({
    boxSizing: "border-box",
    fontSize: 16,
    height: theme.spacing(4),
    maxHeight: "100%",
    maxWidth: theme.spacing(5),
    padding: theme.spacing(0, 0),
    margin: theme.spacing(0.25, 0),
    textTransform: "none",
    border: "none",
    borderRadius: `${theme.spacing()} !important`,
    color: theme.palette.button.notSelected,
    "&:hover": {
      background: theme.palette.tabs.content,
    },
    ...(isSelected && {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.button.primary,
      "& > button > svg, & > .MuiToggleButtonGroup-root > button > svg": {
        fill: theme.palette.primary.contrastText,
      },
      "&:hover": {
        background: theme.palette.button.primary,
      },
    }),
    "& > .MuiToggleButtonGroup-root": {
      height: "100%",
      "& > .MuiButtonBase-root": {
        minWidth: theme.spacing(5),
        padding: 0,
        margin: "auto",
        height: "100%",
      },
    },
    "&:first-of-type": {
      width: theme.spacing(6),
      maxWidth: theme.spacing(6),
    },
    "&.MuiToggleButtonGroup-grouped:not(:first-of-type)": {
      marginLeft: 0,
      borderLeft: 0,
    },
  })
);
