import { useTheme } from "@mui/material";
import { useCallback } from "react";

import { ACHIEVEMENT_COLOR, AchievementsProps } from "../lib/types";

export const useColorAchievements = ({
  selected = false,
}: AchievementsProps) => {
  const theme = useTheme();

  const achievementColorTheme = {
    [ACHIEVEMENT_COLOR.GREY]: theme.palette.chart.grey,
    [ACHIEVEMENT_COLOR.GREEN]: selected
      ? theme.palette.tabs.selectedGreenValue
      : theme.palette.success.main,
    [ACHIEVEMENT_COLOR.RED]: selected
      ? theme.palette.tabs.selectedRedValue
      : theme.palette.error.main,
  };

  const achievementColor = useCallback(
    (value: any) => {
      if ([undefined, null, ""].includes(value))
        return {
          color: achievementColorTheme[ACHIEVEMENT_COLOR.GREY],
          blur: theme.palette.diagram.blurGrey,
        };

      switch (true) {
        case Math.round(value) < 90:
          return {
            color: achievementColorTheme[ACHIEVEMENT_COLOR.RED],
            blur: theme.palette.diagram.blurRed,
          };
        case Math.round(value) >= 90:
          return {
            color: achievementColorTheme[ACHIEVEMENT_COLOR.GREEN],
            blur: theme.palette.diagram.blurGreen,
          };
      }
    },
    [theme.palette, selected]
  );

  return {
    achievementColor,
    achievementColorTheme,
  };
};
