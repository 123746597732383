import { useCallback, useMemo } from "react";

import { useAppDispatch, useAppSelector } from "@/store/redux/hooks";

import { extraActionsVdl } from "../model/vdlSlice";

export const useVDL = () => {
  const regionId = useAppSelector((state) => state.controls.regions.id);
  const { items: leaders } = useAppSelector((state) => state.regions.leaders);
  const dispatch = useAppDispatch();
  const { data } = useAppSelector((state) => state.regions.vdl);

  const requestVDL = useCallback(
    (regionId: string) => {
      if (regionId) {
        dispatch(extraActionsVdl.get(regionId));
      }
    },
    [dispatch]
  );

  const leaderData = useMemo(() => {
    return (leaders ?? []).find(
      (leader) => leader.attributeVals.id_subject === regionId
    )?.attributeVals;
  }, [leaders, regionId]);

  return {
    data,
    leaderData,
    requestVDL,
  };
};
