import { MenuItem, Select, styled } from "@mui/material";

export const SelectStyled = styled(Select, {
  shouldForwardProp: (prop) => !["isLineEntire"].includes(prop.toString()),
})<{ isLineEntire?: boolean }>(({ theme, isLineEntire = false }) => ({
  height: theme.spacing(5),
  width: theme.spacing(40),
  fontSize: theme.spacing(1.75),
  background:
    theme.palette.mode === "dark"
      ? theme.palette.input.background
      : theme.palette.background.darkTheme,
  transition: theme.transitions.create("all"),
  ...(isLineEntire && {
    "& > .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.palette.button.dark}`,
    },
  }),
  "&:hover": {
    borderColor: theme.palette.primary.contrastText,
  },
  "& > .MuiSelect-icon": {
    color: "inherit",
    fontSize: theme.spacing(4),
  },
}));

export const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  fontSize: theme.spacing(2),
  borderRadius: theme.spacing(0.5),
  transition: theme.transitions.create("background-color"),
  marginTop: theme.spacing(),
  "&:first-of-type": {
    marginTop: 0,
  },
  "&:hover": {
    backgroundColor: theme.palette.input.hover,
  },
  "&.Mui-selected": {
    backgroundColor: theme.palette.input.selected,
    color: theme.palette.text.onAccent,
  },
}));
