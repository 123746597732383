import { Button, styled } from "@mui/material";

import { BasicSection } from "@/pages/RegionPage/ui/RegionPage.styled";

export const ProjectsSection = styled(BasicSection)(({ theme }) => ({
  //display: "flex",
  //flexDirection: "column",
  paddingBottom: 0,
  display: "grid",
  gridTemplateRows: "0.1fr 0.5fr 1.4fr",
}));

export const ButtonStyled = styled(Button)(({ theme }) => ({
  minWidth: 40,
  width: 40,
  height: 40,
  backgroundColor: theme.palette.input.background,
  border: `1px solid ${theme.palette.button.border}`,
  color: theme.palette.button.icon,
}));
