import { useCallback } from "react";

import { useTabs } from "@/app/hooks/useTabs";
import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { defaultTabs } from "@/shared/GridCard/lib/constants";
import { GOALS_TABS, IDynamicTab } from "@/shared/GridCard/lib/types";
import { REGION_COUNTRY_CODE } from "@/shared/lib/constants";
import { convertPreviosDate, formatNumber } from "@/shared/lib/heplers";
import { useAppSelector } from "@/store/redux/hooks";
import { MODEL_IDS, MODELS } from "@/store/scheme/olap";
import { TAB_ID } from "@/store/scheme/region";

export const useCountryTabs = () => {
  const { getDistrictByRegion } = useRegions();
  const {
    achievements,
    mainAchievements,
    achievementsFiltered,
    achievementsFetching,
    mainAchievementsFetching,
  } = useAppSelector((state) => state.ui.tabs);
  const regionId = useAppSelector((state) => state.controls.regions.id);
  const { enabled } = useAppSelector((state) => state.controls.achievement);
  const model = MODELS[MODEL_IDS.KPI_SOC_ECONOMY];
  const modelP = MODELS[MODEL_IDS.PROJECT_PASSPORT];
  const { receiptGoal, isCheckPeriod } = useTabs();

  const district = getDistrictByRegion(regionId!);

  const receiptDataForTab = (
    tab: TAB_ID,
    indicator: string | number,
    regionId: string | number,
    district?: { id: string | number }
  ) => {
    const isCheckTab = tab === TAB_ID.MANUFACTURING && !enabled;
    const achievementsFilterInd = achievementsFiltered?.[indicator];
    const achievementsInd = achievements?.[indicator];
    const data = isCheckTab
      ? achievementsFilterInd?.[regionId]
      : achievementsInd?.[regionId];

    const factData = isCheckTab
      ? achievementsFilterInd?.[regionId]
      : achievementsInd?.[regionId];

    const rf = isCheckTab
      ? achievementsFilterInd?.[REGION_COUNTRY_CODE]
      : achievementsInd?.[REGION_COUNTRY_CODE];

    const fo = isCheckTab
      ? achievementsFilterInd?.[district?.id!!]
      : achievementsInd?.[district?.id!!];
    return [data, factData, rf, fo];
  };

  const getRegionTabsData = useCallback(
    (targetID: string): IDynamicTab[] => {
      if (regionId) {
        const tabs: IDynamicTab[] = GOALS_TABS.map((tab) => {
          const [indicator] = receiptGoal(tab);
          const [data, factData, rf, fo = {}] = receiptDataForTab(
            tab,
            indicator,
            regionId,
            district
          );

          return {
            id: tab,
            gridArea: tab,
            text: data?.attributeVals?.short_name,
            date: data?.REPORT_DATE,
            ...(isCheckPeriod(indicator) && {
              prevPeriod: ["1", "2", "3", "4", "132", "133"].includes(indicator)
                ? enabled
                  ? convertPreviosDate(data?.PREVIOUS_DATE)
                  : data?.PREVIOUS_DATE
                : "",
            }),
            value: data?.indicatorVals?.[model.indexes.factChangeAppg],
            valueUnit: data?.attributeVals?.unit_measure ?? "%",
            plan: data?.indicatorVals?.[model.indexes.plan],
            fact: factData?.indicatorVals?.[model.indexes.fact],
            unit: data?.attributeVals?.unit_measure ?? "%",
            rf: rf?.indicatorVals?.[model.indexes.fact],
            fo: fo?.indicatorVals?.[model.indexes.fact],
            hlRF: data?.indicatorVals?.[model.indexes.factHighLowRF] ?? 0,
            hlFO: data?.indicatorVals?.[model.indexes.factHighLowFO] ?? 0,
            color: data?.indicatorVals?.[model.indexes.colorGraph] ?? null,
          };
        });

        if (enabled) {
          const vdlPData = achievements?.["123"]?.[regionId];
          const vdlData = achievements?.["10"]?.[regionId];
          const projectsData = mainAchievements?.["0"]?.["0"];

          tabs.unshift(
            {
              ...defaultTabs[TAB_ID.VDL_PROJECTS],
              date: vdlPData?.REPORT_DATE,
              fact: vdlPData?.indicatorVals?.[model.indexes.factChangeAppg],
              unit: vdlPData?.attributeVals?.unit_measure,
              color: vdlPData?.indicatorVals?.[model.indexes.colorGraph],
            },
            {
              ...defaultTabs[TAB_ID.VDL],
              date: vdlPData?.REPORT_DATE,
              fact: vdlData?.indicatorVals?.[model.indexes.factChangeAppg],
              unit: vdlData?.attributeVals?.unit_measure,
              color: vdlData?.indicatorVals?.[model.indexes.colorGraph],
            },
            {
              ...defaultTabs[TAB_ID.PROJECTS],
              date: projectsData?.REPORT_DATE,
              fact: projectsData?.indicatorVals?.[modelP.indexes.achievement],
              unit: projectsData?.attributeVals?.unit_measure || "%",
              color:
                projectsData?.indicatorVals?.[
                  modelP.indexes.achievementProjectColor
                ],
            }
          );
        } else {
          const dataRisks = achievements?.["121"]?.[regionId];
          const dataProjects = achievements?.["104"]?.[regionId];

          tabs.unshift({
            ...defaultTabs[TAB_ID.RISKS],
            projects: formatNumber(
              dataProjects?.indicatorVals?.[model.indexes.fact],
              undefined,
              0
            ),
            value: dataRisks?.indicatorVals?.[model.indexes.fact],
            unit: dataRisks?.attributeVals?.unit_measure || "%",
            color: dataRisks?.indicatorVals?.[model.indexes.color],
          });
        }

        return tabs;
      }

      return [];
    },
    [achievements, district, enabled, mainAchievements, regionId, isCheckPeriod]
  );

  return {
    getRegionTabsData,
    tabFetching: achievementsFetching,
    tabEconomicsFetching: achievementsFetching && mainAchievementsFetching,
  };
};
