import { useTheme } from "@mui/material";

export const GeneralInfoIcon = () => {
  const theme = useTheme();
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.68848 4.10263C2.68848 3.74486 2.9785 3.45483 3.33627 3.45483H7.77849V4.75042H3.98406V22.7043H17.9894V4.75042H8.27199V3.45483H18.6372C18.9949 3.45483 19.285 3.74486 19.285 4.10263V23.3521C19.285 23.7099 18.9949 23.9999 18.6372 23.9999H3.33627C2.9785 23.9999 2.68848 23.7099 2.68848 23.3521V4.10263Z"
        fill={theme.palette.icon.iconProject}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1574 2.7763H9.75366V1.48071H17.1574V2.7763Z"
        fill={theme.palette.icon.iconProject}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.714844 2.12851C0.714844 1.77074 1.00487 1.48071 1.36264 1.48071H4.81773V2.7763H2.01043V21.3779H0.714844V2.12851Z"
        fill={theme.palette.icon.iconProject}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6765 13.1415H6.29858V11.8459H15.6765V13.1415Z"
        fill={theme.palette.icon.iconProject}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6765 11.1672H6.29858V9.87158H15.6765V11.1672Z"
        fill={theme.palette.icon.iconProject}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7407 15.1157H6.29858V13.8201H10.7407V15.1157Z"
        fill={theme.palette.icon.iconProject}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.1582 1.63505C5.1582 0.732247 5.88989 0 6.79325 0H7.78038C8.68365 0 9.41526 0.732321 9.41526 1.63505V7.06423C9.41526 7.96714 8.68359 8.69928 7.78038 8.69928H7.28675C6.38333 8.69928 5.65183 7.96711 5.65183 7.06423V5.58365H6.94742V7.06423C6.94742 7.25195 7.09923 7.4037 7.28675 7.4037H7.78038C7.96776 7.4037 8.11967 7.25191 8.11967 7.06423V1.63505C8.11967 1.44745 7.96771 1.29558 7.78038 1.29558H6.79325C6.60568 1.29558 6.45379 1.44752 6.45379 1.63505V4.10289H5.1582V1.63505Z"
        fill={theme.palette.icon.iconProject}
      />
    </svg>
  );
};
