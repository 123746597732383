import AddIcon from "@mui/icons-material/Add";
import { ButtonProps } from "@mui/material";
import React, { FC } from "react";

import {
  IncidentButtonStyled,
  IncidentTooltipStyled,
} from "./IncidentCreateButton.styled";

export const IncidentCreateButton: FC<
  ButtonProps & { posRight: number; bigSize?: boolean }
> = ({ ...props }) => {
  const { posRight, bigSize = false } = props;
  return (
    <IncidentTooltipStyled title="Создать обращение" placement="top" arrow>
      <IncidentButtonStyled {...props} posRight={posRight} bigSize={bigSize}>
        <AddIcon />
      </IncidentButtonStyled>
    </IncidentTooltipStyled>
  );
};
