import { styled } from "@mui/material";

export const SceletonCardStyled = styled("div")({
  display: "flex",
  gap: "1.5rem",
  flexDirection: "column",
  margin: "0.75rem 0",
});

export const SceletonPartStyled = styled("div")<{
  direction?: "column" | "row";
}>(({ direction }) => ({
  display: "flex",
  flexDirection: direction || "column",
  gap: "0.5rem",
  alignItems: "center",
}));
