import { useCallback } from "react";

import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { COMPARE_TYPE } from "@/shared/CompareControl/lib/types";
import { REGION_COUNTRY_CODE } from "@/shared/lib/constants";
import {
  formatNumber,
  formatNumberPeople,
  replaceSignature,
} from "@/shared/lib/heplers";
import { useAppDispatch, useAppSelector } from "@/store/redux/hooks";
import { MODEL_IDS, MODELS } from "@/store/scheme/olap";

import { ValueBasicType } from "../lib/types";
import { extraActionsKpi } from "../model/slices/kpiSlice";

// Используется в компонентах, которые содержат в себе GeneralIndicators
export const useKpi = () => {
  const model = MODELS[MODEL_IDS.KPI_SOC_ECONOMY];
  const { data, fetching } = useAppSelector((state) => state.regions.kpi);
  const regionId = useAppSelector((state) => state.controls.regions.id);
  const { compare } = useAppSelector((state) => state.controls);
  const dispatch = useAppDispatch();
  const { getDistrictByRegion, indicatorHighLow, isCheckRfFoPage } =
    useRegions();
  const district = getDistrictByRegion(regionId!);

  const requestKpi = useCallback(
    (regionId: string, dataSummaryId: string, district: any) => {
      dispatch(
        extraActionsKpi.get({
          regionId,
          districtId: district?.id,
          dataSummaryId,
        })
      );
    },
    [dispatch]
  );

  const getValuesById = useCallback(
    (id: string) => {
      let regionData = [];
      let districtData = [];

      const countryData = Object.keys(data?.[id]?.[REGION_COUNTRY_CODE] || {})
        .sort((sortA, sortB) => (+sortA < +sortB ? 1 : -1))
        .map((item, idx) => {
          return data?.[id]?.[REGION_COUNTRY_CODE]?.[item];
        });

      if (district?.id) {
        districtData = Object.keys(data?.[id]?.[district?.id] || {})
          .sort((sortA, sortB) => (+sortA < +sortB ? 1 : -1))
          .map((item, idx) => {
            return data?.[id]?.[district?.id]?.[item];
          });
      }

      if (regionId) {
        regionData = Object.keys(data?.[id]?.[regionId] || {})
          .sort((sortA, sortB) => (+sortA < +sortB ? 1 : -1))
          .map((item, idx) => {
            return data?.[id]?.[regionId]?.[item];
          });
      }

      return { countryData, districtData, regionData };
    },
    [data, district, regionId]
  );

  const setBaseValues = useCallback(
    (indicators: string[]): ValueBasicType[] => {
      const parentTitle = compare.value === COMPARE_TYPE.FO ? "ФО" : "РФ";

      return indicators.flatMap((item, idx) => {
        const { countryData, districtData, regionData } = getValuesById(item);

        const currentData: any =
          compare.value === COMPARE_TYPE.FO ? districtData : countryData;

        return [
          {
            key: item,
            ...(idx === 0 &&
              !isCheckRfFoPage.isRF && {
                title:
                  regionData?.[0]?.[
                    isCheckRfFoPage.isFO ? "SHORT_NAME" : "name"
                  ],
              }),
            date:
              idx === 0
                ? regionData?.[0]?.REPORT_DATE
                : regionData?.[0]?.PREVIOUS_DATE,
            unit: regionData?.[0]?.attributeVals?.unit_measure,
            value:
              regionData?.[0]?.attributeVals?.unit_measure === "чел"
                ? formatNumberPeople(
                    regionData?.[0]?.indicatorVals?.[model.indexes.fact],
                    undefined,
                    undefined,
                    !!idx
                  )
                : formatNumber(
                    regionData?.[0]?.indicatorVals?.[model.indexes.fact],
                    undefined,
                    undefined,
                    !!idx
                  ),
            ...(idx !== 0 && {
              valueHL:
                regionData?.[0]?.indicatorVals?.[
                  model.indexes[indicatorHighLow]
                ],
              valueColor: regionData?.[0]?.indicatorVals?.[model.indexes.color],
            }),
          },
          ...(!isCheckRfFoPage.isRF
            ? [
                {
                  key: `parent-${item}`,
                  ...(idx === 0 && { title: parentTitle }),
                  date:
                    idx === 0
                      ? currentData?.[0]?.REPORT_DATE
                      : currentData?.[0]?.PREVIOUS_DATE,
                  unit: currentData?.[0]?.attributeVals?.unit_measure,
                  value:
                    currentData?.[0]?.attributeVals?.unit_measure === "чел"
                      ? formatNumberPeople(
                          currentData?.[0]?.indicatorVals?.[model.indexes.fact],
                          undefined,
                          undefined,
                          !!idx
                        )
                      : formatNumber(
                          currentData?.[0]?.indicatorVals?.[model.indexes.fact],
                          undefined,
                          undefined,
                          !!idx
                        ),
                  ...(idx !== 0 && {
                    valueColor:
                      currentData?.[0]?.indicatorVals?.[model.indexes.color],
                  }),
                },
              ]
            : []),
        ];
      });
    },
    [
      compare.value,
      getValuesById,
      indicatorHighLow,
      isCheckRfFoPage.isRF,
      isCheckRfFoPage.isFO,
    ]
  );

  const setDynamicValues = useCallback(
    (indicator: string): ValueBasicType[] => {
      const parentTitle = compare.value === COMPARE_TYPE.FO ? "ФО" : "РФ";
      const { countryData, districtData, regionData } =
        getValuesById(indicator);

      const currentData: any =
        compare.value === COMPARE_TYPE.FO ? districtData : countryData;

      return [
        {
          key: indicator,
          ...(!isCheckRfFoPage.isRF && {
            title:
              regionData?.[0]?.[isCheckRfFoPage.isFO ? "SHORT_NAME" : "name"],
          }),
          date: regionData?.[0]?.REPORT_DATE,
          unit: regionData?.[0]?.attributeVals?.unit_measure,
          value: formatNumberPeople(
            regionData?.[0]?.indicatorVals?.[model.indexes.fact],
            undefined,
            undefined,
            true
          ),
        },
        ...(!isCheckRfFoPage.isRF
          ? [
              {
                key: `parent-${indicator}`,
                title: parentTitle,
                date: currentData?.[0]?.REPORT_DATE,
                unit: currentData?.[0]?.attributeVals?.unit_measure,
                value: formatNumberPeople(
                  currentData?.[0]?.indicatorVals?.[model.indexes.fact],
                  undefined,
                  undefined,
                  true
                ),
              },
            ]
          : []),
        {
          key: `${indicator}-prev`,
          date: replaceSignature(regionData?.[0]?.PREVIOUS_DATE),
          unit: regionData?.[regionData.length - 1]?.attributeVals
            ?.unit_measure,
          value: formatNumberPeople(
            regionData?.[regionData.length - 1]?.indicatorVals?.[
              model.indexes.fact
            ],
            undefined,
            undefined,
            true
          ),
          valueColor:
            regionData?.[regionData.length - 1]?.indicatorVals?.[
              model.indexes.color
            ],
        },
        ...(!isCheckRfFoPage.isRF
          ? [
              {
                key: `parent-${indicator}-prev`,
                date: replaceSignature(currentData?.[0]?.PREVIOUS_DATE),
                unit: currentData?.[regionData.length - 1]?.attributeVals
                  ?.unit_measure,
                value: formatNumberPeople(
                  currentData?.[countryData.length - 1]?.indicatorVals?.[
                    model.indexes.fact
                  ],
                  undefined,
                  undefined,
                  true
                ),
                valueColor:
                  currentData?.[countryData.length - 1]?.indicatorVals?.[
                    model.indexes.color
                  ],
              },
            ]
          : []),
      ];
    },
    [compare.value, getValuesById, isCheckRfFoPage.isFO, isCheckRfFoPage.isRF]
  );

  return {
    data,
    fetching,
    requestKpi,
    getValuesById,
    model,
    setBaseValues,
    setDynamicValues,
  };
};
