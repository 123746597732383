import { useMemo } from "react";
import { useNavigate } from "react-router";

import { useProjectPage } from "@/pages/ProjectsPage/hooks/useProjectPage";
import { useProjects } from "@/pages/ProjectsPage/hooks/useProjects";
import { getUrlByTheme } from "@/shared/lib/heplers";
import { useAppSelector } from "@/store/redux/hooks";

import { IAppBarProjectsFilterProps, ProjectsArray } from "../../lib/types";
import { AppBarSimpleFilter } from "../AppBarSimpleFilter/AppBarSimpleFilter";

export const AppBarProjectsFilter = ({ path }: IAppBarProjectsFilterProps) => {
  const { projectsDirectory } = useProjects();
  const { info } = useProjectPage();
  const navigate = useNavigate();
  const regionId = useAppSelector((state) => state.controls.regions.id);

  const convertPath = useMemo(() => {
    const formatingPath = path?.split("/");
    const lastElement = formatingPath?.[formatingPath.length - 1];
    return isNaN(Number(lastElement)) ? lastElement : "";
  }, [path]);

  const handleClick = (item: { id: string }) => {
    const path = convertPath;
    if (item?.id)
      navigate(getUrlByTheme(`/projects/${item.id}${path ? `/${path}` : ""}`));
  };

  const projectsArray: ProjectsArray[] = projectsDirectory
    .filter(
      (item) =>
        item?.attributeVals?.id_subject === regionId &&
        item?.attributeVals?.end_action === ""
    )
    ?.map((item) => ({
      id: item?.id,
      name: item?.attributeVals?.name,
    }));

  const projectsIndex = useMemo(() => {
    if (projectsArray.length > 0) {
      return projectsArray.findIndex((item) => item.id === info?.general?.id);
    }
    return 0;
  }, [info?.general?.id, projectsArray]);

  return (
    <AppBarSimpleFilter
      arrayData={projectsArray}
      indexCurrentValue={projectsIndex}
      onChange={handleClick}
    />
  );
};
