import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ArgumentsType } from "@/shared/lib/types";
import { IRegionAttributeVals, MODEL_IDS, MODELS } from "@/store/scheme/olap";

import { IRiskKpi, ISumRisksKpiState } from "../../lib/types";
import { getSumRisksKpiRfFo } from "../api/sum_risks_kpi_rf_fo";

export const initialState: ISumRisksKpiState = {
  risksKpi: undefined,
  fetching: true,
  error: null,
};

export const extraActionsSumRiskKpi = {
  get: createAsyncThunk(
    "sum_risks_kpi/get",
    async ({ dataSummaryId, regionId }: ArgumentsType) =>
      await getSumRisksKpiRfFo({ dataSummaryId, regionId })
  ),
};

const sumRisksKpiSlice = createSlice({
  name: "sum_risks_kpi",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActionsSumRiskKpi.get.pending, (state) => {
        state.risksKpi = undefined;
        state.fetching = true;
      })
      .addCase(extraActionsSumRiskKpi.get.fulfilled, (state, action) => {
        const model = MODELS[MODEL_IDS.KPI_SOC_ECONOMY];
        state.risksKpi = action.payload.reduce((prev, cur) => {
          if (!prev) prev = {};
          const region = cur?.dims?.[model.dataRegionId];
          const id = region?.code;
          const indicator = cur?.dims?.[model.dimId];
          if (!prev?.[id]) prev[id] = {};
          prev[id] = {
            id,
            name: (region?.attributeVals as IRegionAttributeVals)?.name,
            parentId: region?.parentId?.[0],
            total: indicator?.indicatorVals?.[model.indexes.fact]?.sum,
          };
          return prev;
        }, {} as IRiskKpi);
        state.fetching = false;
        state.error = null;
      })
      .addCase(extraActionsSumRiskKpi.get.rejected, (state, action) => {
        state.fetching = false;
        state.error = action.error;
      });
  },
});

export const reducer = sumRisksKpiSlice.reducer;

export default sumRisksKpiSlice;
