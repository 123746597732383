import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useTheme } from "@mui/material";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";

import { useProjectPage } from "@/pages/ProjectsPage/hooks/useProjectPage";
import { useCheckpointsFilter } from "@/shared/InputSelect/hooks/useCheckpointsFilter";
import { IProjectResultsGraphProps } from "@/shared/ProjectResultsGroup/lib/types";

import { getColorPoint } from "../lib/helpers";
import {
  Checkpoint,
  CheckpointDate,
  CheckpointDote,
  CheckpointName,
  CheckpointsContainer,
  CheckpointsInner,
  CheckpointsInnerLine,
  CheckpointsInnerLineToday,
  CheckpointsWrapper,
  StyledButton,
} from "./ProjectsResultsGraph.styled";

export const ProjectResultsGraph = ({
  resultId,
  widthWrapper,
}: IProjectResultsGraphProps) => {
  const theme = useTheme();
  const { allCheckpoints } = useProjectPage();
  const { actualPeriod } = useCheckpointsFilter();
  const [shift, setShift] = useState<number>(0);
  const [disabled, setDisabled] = useState({
    left: true,
    right: false,
  });
  const [countDataIncludesPeriod, setCurrentDataIncludesPeriod] =
    useState<number>(0);
  let averageLeft = 0;

  useEffect(() => {
    setShift(0);
    setDisabled({
      left: true,
      right: true,
    });
  }, [resultId, widthWrapper]);

  const actualPeriodDate = useMemo(() => {
    const period = actualPeriod?.replace(/-/gi, ".");
    return moment(period);
  }, [actualPeriod]);

  const sortCheckPoints = useMemo(() => {
    return [...(allCheckpoints?.[resultId] || [])].sort((a, b) => {
      const dateA = a?.dims?.["847"].attributeVals.achievement_date_plan;
      const dateB = b?.dims?.["847"].attributeVals.achievement_date_plan;

      if (!dateA || !dateB || dateA === dateB) return 0;

      return moment(dateA, "DD-MM-YYYY").isBefore(moment(dateB, "DD-MM-YYYY"))
        ? -1
        : 1;
    });
  }, [allCheckpoints, resultId]);

  const currentCheckPoints = useMemo(() => {
    let countIncludesPeriod = 0;
    return sortCheckPoints?.reduce((prev = [], current: any) => {
      const result = {
        ...current.dims?.["847"]?.attributeVals,
        colorPoint: getColorPoint(
          current.dims?.["847"]?.attributeVals?.status,
          theme
        ),
      };

      if (
        moment(result?.["achievement_date_plan"], "DD-MM-YYYY").isSameOrBefore(
          moment(actualPeriodDate, "DD-MM-YYYY")
        )
      ) {
        ++countIncludesPeriod;
      }
      prev.push(result);

      setCurrentDataIncludesPeriod(countIncludesPeriod);
      return prev;
    }, [] as Record<string, any>[]);
  }, [sortCheckPoints, actualPeriodDate, theme.palette]);

  const [totalWidth, middleAverageLeft, currentWidth] = useMemo(() => {
    let totalWidth = (currentCheckPoints.length - 1) * 336 + 430;
    let middleAverageLeft = 336;
    let currentWidth = middleAverageLeft * (countDataIncludesPeriod - 1) + 130;

    if (currentWidth < 0) {
      currentWidth = 0;
    }
    if (totalWidth < parseInt(widthWrapper)) {
      totalWidth = parseInt(widthWrapper);
      middleAverageLeft = totalWidth / currentCheckPoints.length;
      currentWidth = middleAverageLeft * (countDataIncludesPeriod - 1) + 130;
    }

    if (countDataIncludesPeriod === currentCheckPoints?.length) {
      currentWidth = totalWidth;
    }

    return [totalWidth, middleAverageLeft, currentWidth];
  }, [sortCheckPoints, widthWrapper, countDataIncludesPeriod]);

  const middleAverageShift = useMemo(
    () => parseInt(widthWrapper) / 4,
    [widthWrapper]
  );

  const handleShift = useCallback(
    (side: string) => {
      switch (side) {
        case "left":
          setShift((prev) => {
            if (prev + middleAverageShift > 0) {
              return 0;
            } else {
              return (prev += middleAverageShift);
            }
          });
          break;
        case "right":
          setShift((prev) => {
            if (
              -(prev - middleAverageShift) >
              totalWidth - parseInt(widthWrapper)
            ) {
              return (prev -= totalWidth - parseInt(widthWrapper) + prev);
            } else {
              return (prev -= middleAverageShift);
            }
          });
          break;
        default:
          setShift(0);
          break;
      }
    },
    [totalWidth, currentWidth, widthWrapper, middleAverageLeft]
  );

  const checkDisabledShift = useMemo(() => {
    return totalWidth - parseInt(widthWrapper) === 0
      ? 0
      : -(totalWidth - parseInt(widthWrapper));
  }, [totalWidth, widthWrapper]);

  useEffect(() => {
    setDisabled(() => {
      return {
        left: shift === 0,
        right: shift === checkDisabledShift,
      };
    });
  }, [setDisabled, shift, checkDisabledShift]);

  return (
    <CheckpointsWrapper width={widthWrapper}>
      <CheckpointsContainer>
        <CheckpointsInner width={totalWidth} carusel={shift}>
          <CheckpointsInnerLine />
          <CheckpointsInnerLineToday width={currentWidth} />
          {currentCheckPoints.map(
            (
              {
                achievement_date_plan,
                achievement_date_fact,
                name,
                colorPoint,
              },
              index
            ) => {
              return (
                <Checkpoint
                  width={middleAverageLeft}
                  left={
                    index === 0
                      ? (averageLeft += 130)
                      : (averageLeft += middleAverageLeft)
                  }
                  key={name + index}
                >
                  <CheckpointDate color={colorPoint}>
                    <p className="date_plan">{achievement_date_plan}</p>
                    <span className="date_fact">
                      {achievement_date_fact || "-"}
                    </span>
                  </CheckpointDate>
                  <CheckpointDote color={colorPoint} />
                  <CheckpointName>{name}</CheckpointName>
                </Checkpoint>
              );
            }
          )}
        </CheckpointsInner>
      </CheckpointsContainer>
      <StyledButton
        disabled={disabled.left}
        style={{ left: "2rem" }}
        onClick={() => handleShift("left")}
      >
        <ArrowBackIosNewIcon />
      </StyledButton>
      <StyledButton
        disabled={disabled.right}
        style={{ right: "2rem" }}
        onClick={() => handleShift("right")}
      >
        <ArrowForwardIosIcon />
      </StyledButton>
    </CheckpointsWrapper>
  );
};
