import { InputBase, ListItemText, Stack, styled } from "@mui/material";

export const BootstrapInput = styled(InputBase)(({ theme }) => {
  return {
    "label + &": {
      marginTop: theme.spacing(3),
    },

    "& > .MuiSvgIcon-root": {
      color: theme.palette.icon.color,
    },

    "& .MuiInputBase-input": {
      position: "relative",
      height: theme.spacing(6),
      boxSizing: "border-box",
      fontSize: 14,
      padding: theme.spacing(),
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      backgroundColor: theme.palette.button.background,
    },
  };
});

export const ListTextStyled = styled(ListItemText)(({ theme }) => ({
  margin: theme.spacing(0),
  color: theme.palette.text.secondary,
  display: "flex",
  alignItems: "center",
  "& .MuiListItemText-primary": {
    fontWeight: theme.typography.fontWeightExtraBold,
    fontSize: theme.spacing(2.75),
  },
  "& .MuiListItemText-secondary": {
    color: theme.palette.modal.text,
    fontWeight:
      theme.palette.mode === "dark"
        ? theme.typography.fontWeightBold
        : theme.typography.fontWeightSemiBold,
    fontSize: theme.spacing(2.75),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

export const WrapperStack = styled(Stack)(({ theme }) => ({
  width: "100%",
  overflow: "hidden",
}));
