import { FC, useState } from "react";

import { useFilteredTabs } from "@/shared/AchievementTabCard/hooks/useFilteredTabs";
import { EconomicsProjectCard } from "@/shared/AchievementTabCard/ui/components";
import { GoalsCard } from "@/shared/AchievementTabCard/ui/components/GoalsCard/GoalsCard";

import { IRegionTabCardProps } from "../lib/types";
import { GridContainer } from "./components/EconomicsProjectCard/EconomicsProjectCard.styled";

export const RegionTabCard: FC<IRegionTabCardProps> = ({
  tabs,
  setSelectedTabId,
  selectedTabId,
  tabFetching = false,
}) => {
  const [isFullShow, setIsFullShow] = useState(false);
  const { goalsTabs } = useFilteredTabs(tabs);

  return (
    <GridContainer active={isFullShow}>
      <>
        <EconomicsProjectCard
          tabs={tabs}
          isFullShow={isFullShow}
          selectedTabId={selectedTabId}
          setIsFullShow={setIsFullShow}
          setSelectedTabId={setSelectedTabId}
        />

        {goalsTabs.map((t) => (
          <GoalsCard
            setSelectedTabId={setSelectedTabId}
            key={t.id}
            data={t}
            selected={selectedTabId === t.id}
            tabFetching={tabFetching}
          />
        ))}
      </>
    </GridContainer>
  );
};
