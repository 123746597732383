import { useAppSelector } from "@store/redux/hooks";
import React, { useState } from "react";

import { RegionProjectsWidget } from "@/features/RegionProjectsWidget/ui/RegionProjectsWidget";
import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import {
  BoxStyled,
  RegionGridCell,
  RegionGridRate,
} from "@/pages/RegionPage/ui/RegionMutableInfo/ui/RegionMutableInfo.styled";
import { Sceleton } from "@/shared/Sceleton/ui/Sceleton";

import { AchieveRisksWrapper } from "./components/AchieveRisksWrapper";

export const RegionMutableRate = () => {
  const [isAchieveArea, setIsAchieveArea] = useState<boolean>(true);
  const { highlightWidget, mainAchievementsFetching } = useAppSelector(
    (state) => state.ui.tabs
  );
  const { isCheckRfFoPage } = useRegions();
  const { fetching: fetchingFO } = useAppSelector(
    (state) => state.regions.sumFoProjects
  );
  const { fetching: fetchingRF } = useAppSelector(
    (state) => state.regions.sumRfProjects
  );
  const fetchingRegionProjects = !isCheckRfFoPage.isAll
    ? mainAchievementsFetching
    : isCheckRfFoPage.isRF
    ? fetchingRF
    : fetchingFO;
  // Колбэк для проверки отображать ли при наведении на таб Достижение подсветку виджета Достижения
  const handleChangeAchieveArea = () => setIsAchieveArea(!isAchieveArea);

  return (
    <BoxStyled>
      <RegionGridRate>
        <RegionGridCell
          sx={{ gridArea: "Achievements" }}
          isHighlight={highlightWidget === "Achievements" && isAchieveArea}
        >
          {/* TODO: Добавить fetching для Рисков */}
          {mainAchievementsFetching && <Sceleton type="achieve" size={200} />}
          {!mainAchievementsFetching && (
            <AchieveRisksWrapper callbackChange={handleChangeAchieveArea} />
          )}
        </RegionGridCell>

        <RegionGridCell sx={{ gridArea: "ProjectsTable" }}>
          {fetchingRegionProjects ? (
            <Sceleton type="line" />
          ) : (
            <RegionProjectsWidget container="rate" />
          )}
        </RegionGridCell>
      </RegionGridRate>
    </BoxStyled>
  );
};
