import { useMemo } from "react";

import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { REGION_COUNTRY_CODE } from "@/shared/lib/constants";
import { formatNumber } from "@/shared/lib/heplers";
import { SelectValues } from "@/shared/ProjectsFilter/lib/types";
import { useFuturePlan } from "@/shared/Table/hooks/useFuturePlan";
import { PROJECT_INDICATORS } from "@/shared/Table/model/api/scheme";
import { useAppSelector } from "@/store/redux/hooks";
import { MODEL_IDS, MODELS } from "@/store/scheme/olap";

import {
  checkArraySomeIndicators,
  getIndicatorsByPeriod,
} from "../lib/helpers";
import { Columns, Rows } from "../lib/types";

export const useSumProjects = (periodId: string) => {
  const { isCheckRfFoPage } = useRegions();
  const { isCheckFuturePlan } = useFuturePlan();
  const { extensionProjectsList } = useAppSelector((state) => state.ui.tabs);
  const { sumProjectsByRf } = useAppSelector(
    (state) => state.regions.sumRfProjects
  );
  const model = MODELS[MODEL_IDS.PROJECT_PASSPORT];
  let numberInOrder = 0;

  const [totalCurrentState, currentDataState, currentCode] = useMemo(() => {
    const periodByFuturePlan = isCheckFuturePlan ? periodId : "120300000";
    return isCheckRfFoPage.isRF
      ? [
          sumProjectsByRf,
          sumProjectsByRf?.[periodByFuturePlan],
          REGION_COUNTRY_CODE,
        ]
      : [
          extensionProjectsList,
          extensionProjectsList?.[periodByFuturePlan],
          "0",
        ];
  }, [
    isCheckRfFoPage.isRF,
    isCheckFuturePlan,
    periodId,
    extensionProjectsList,
    sumProjectsByRf,
  ]);

  const changeModelValue = useMemo(() => {
    return isCheckFuturePlan
      ? getIndicatorsByPeriod(model, periodId)
      : model.indexes.plan;
  }, [periodId, isCheckFuturePlan]);

  const columns = useMemo(() => {
    const prepareColumns: Columns[] = [
      {
        id: PROJECT_INDICATORS.INVESTMENTS,
        title: `Частные инвестиции, ${
          currentDataState?.[currentCode]?.[PROJECT_INDICATORS.INVESTMENTS]
            ?.UNIT || ""
        }`,
      },
      {
        id: PROJECT_INDICATORS.PLACES,
        title: `Новые рабочие места, ${
          currentDataState?.[currentCode]?.[PROJECT_INDICATORS.PLACES]?.UNIT ||
          ""
        }`,
      },
      {
        id: PROJECT_INDICATORS.PROFIT,
        title: `Выпуск товаров и услуг, ${
          currentDataState?.[currentCode]?.[PROJECT_INDICATORS.PROFIT]?.UNIT ||
          ""
        }`,
      },
      {
        id: PROJECT_INDICATORS.SALARY,
        title: `Средняя з/п, ${
          currentDataState?.[currentCode]?.[PROJECT_INDICATORS.SALARY]?.UNIT ||
          ""
        }`,
      },
    ];
    if (!isCheckRfFoPage.isAll) {
      prepareColumns.push({
        id: "date",
        title: "Срок",
      });
    }
    return prepareColumns;
  }, [currentDataState, isCheckRfFoPage.isAll, currentCode]);

  const prepareFilterCurrentData = useMemo(() => {
    const keysCurrentData = Object.keys(currentDataState ?? {});
    if (currentCode === "0") {
      return keysCurrentData?.filter((data) => {
        const projectId = currentDataState?.[data];
        return (
          projectId?.[model.dataProjectId] &&
          checkArraySomeIndicators(Object.keys(projectId))
        );
      });
    }
    return keysCurrentData?.sort((a, b) => +a - +b);
  }, [currentCode, currentDataState]);

  const getRowName = (currentId: string) => {
    if (isCheckRfFoPage.isRF) {
      return currentId === REGION_COUNTRY_CODE
        ? "Итого по РФ"
        : currentDataState?.[currentId]?.[model.dataRegionId]?.name;
    }
    return currentId === "0"
      ? `Итого ${
          isCheckFuturePlan
            ? isCheckRfFoPage.isFO
              ? `по ${
                  currentDataState?.[currentId]?.[model.dataProjectId]
                    ?.foAttributeVals?.["SHORT_NAME"]
                }`
              : "по субъекту"
            : ""
        }`
      : currentDataState?.[currentId]?.[model.dataProjectId]?.name;
  };

  const rows: Rows[] = useMemo(() => {
    return prepareFilterCurrentData?.map((currentId) => {
      return {
        id:
          currentCode === REGION_COUNTRY_CODE &&
          currentId === REGION_COUNTRY_CODE
            ? "0"
            : currentId,
        name: getRowName(currentId),
        [PROJECT_INDICATORS.SALARY]: formatNumber(
          currentDataState?.[currentId]?.[PROJECT_INDICATORS.SALARY]
            ?.indicatorVals?.[changeModelValue],
          undefined,
          0
        ),
        [PROJECT_INDICATORS.PROFIT]: formatNumber(
          currentDataState?.[currentId]?.[PROJECT_INDICATORS.PROFIT]
            ?.indicatorVals?.[changeModelValue],
          undefined,
          2
        ),
        [PROJECT_INDICATORS.INVESTMENTS]: formatNumber(
          currentDataState?.[currentId]?.[PROJECT_INDICATORS.INVESTMENTS]
            ?.indicatorVals?.[changeModelValue],
          undefined,
          2
        ),
        [PROJECT_INDICATORS.PLACES]: formatNumber(
          currentDataState?.[currentId]?.[PROJECT_INDICATORS.PLACES]
            ?.indicatorVals?.[changeModelValue],
          undefined,
          0
        ),
        ...(currentCode === "0" && {
          date:
            currentDataState?.[currentId]?.[model.dataProjectId]
              ?.implementation_period || "-",
          color:
            currentDataState?.[currentId]?.[PROJECT_INDICATORS.SALARY]
              ?.indicatorVals?.[model.indexes.color],
          status:
            currentDataState?.[currentId]?.[model.dataProjectId]
              ?.indicatorVals?.[model.indexes.status],
          isNotJumpOnPage:
            !!currentDataState?.[currentId]?.[model.dataProjectId]?.[
              "end_action"
            ],
          ...(currentId !== "0" && {
            number: ++numberInOrder,
          }),
        }),
      };
    });
  }, [prepareFilterCurrentData, numberInOrder, changeModelValue, currentCode]);

  const selectData = useMemo(() => {
    if (isCheckFuturePlan) {
      return Object.keys(totalCurrentState || {})
        ?.map((period) => {
          const attributeValues =
            totalCurrentState?.[period]?.[currentCode]?.[
              isCheckRfFoPage.isRF ? model.dataRegionId : model.dataProjectId
            ]?.attributeVals;
          const select = {
            id: period,
            sortId: attributeValues?.["YEAR"],
          } as SelectValues;

          if (period?.match(/^3/gim)) {
            select.name = `${attributeValues?.["REPORT_DATE"]?.replace(
              /^([a-zа-яё\s])+/gim,
              ""
            )} (факт)`;
            select.sortId = "1";
          } else {
            select.name = `${attributeValues?.["name"]} (план)`;
          }
          return select;
        })
        ?.sort((a, b) => (+a.sortId > +b.sortId ? 1 : -1));
    }
    return [] as SelectValues[];
  }, [totalCurrentState, currentCode, isCheckFuturePlan, isCheckRfFoPage.isRF]);

  return { columns, rows, isCheckFuturePlan, selectData, currentCode };
};
