import modeSlice from "@app/theme/model/modeSlice";
import { combineReducers } from "@reduxjs/toolkit";
import menuSlice from "@widgets/MainMenu/model/menuSlice";

import { IModeState } from "@/app/theme/lib/types";
import { ITabsState } from "@/pages/MainPage/lib/types";
import { IMenuState } from "@/widgets/AppBar/lib/types";

import tabsSlice from "../../../pages/MainPage/model/tabsSlice";

export interface IUIReducer {
  menu: IMenuState;
  mode: IModeState;
  tabs: ITabsState;
}

const uiReducer = combineReducers<IUIReducer>({
  menu: menuSlice.reducer,
  mode: modeSlice.reducer,
  tabs: tabsSlice.reducer,
});

export default uiReducer;
