import { styled, Typography } from "@mui/material";

import { GeneralIndicatorsHeader } from "@/shared/GeneralIndicators/ui/GeneralIndicators.styled";

import {
  INationalProjectsIndicatorsContentProps,
  INationalProjectsIndicatorsPage,
} from "../lib/types";

export const NationalProjectsIndicatorsContent = styled(
  "div"
)<INationalProjectsIndicatorsContentProps>(({ theme, page, carusel }) => ({
  flex: "1 1 100%",
  overflow: "hidden",

  "& > div": {
    width: "100%",
    /*...(carusel && {
      width: "150%",
      transform: `translateX(${-(100 / 3) * ((page ?? 1) - 1)}%)`,
    }),
    */
    display: "flex",
    height: "100%",
    gap: theme.spacing(8),
    /*
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    */
  },
}));

export const NationalProjectsIndicatorsItem = styled("div")(({ theme }) => ({
  flex: "1 1 1px",
  minHeight: theme.spacing(20),
}));

export const NationalProjectsIndicatorsPages = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  flex: "0 0 auto",
  gap: theme.spacing(),
  alignItems: "center",
  justifyContent: "center",
}));

export const NationalProjectsIndicatorsPage = styled(
  "div"
)<INationalProjectsIndicatorsPage>(({ theme, active }) => ({
  display: "flex",
  alignItems: "center",
  width: theme.spacing(5),
  height: "100%",
  cursor: "pointer",

  "&:after": {
    display: "block",
    width: "100%",
    height: theme.spacing(0.5),
    backgroundColor: active
      ? theme.palette.dropdownWidget.baseDropdown
      : "rgba(64, 136, 230, 0.2)",
    borderRadius: 1,
    content: "''",

    transition: theme.transitions.create(["background-color"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  "&:hover:after": {
    backgroundColor: theme.palette.primary.light,
  },
}));

export const LineIndicator = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

export const ItemTitle = styled(Typography, {
  shouldForwardProp: (prop) => !["isOpenPopUp"].includes(prop.toString()),
})<{ isOpenPopUp?: boolean }>(({ theme, isOpenPopUp }) => ({
  flex: "0 0 auto",
  color: theme.palette.text.tertiary,
  fontSize: theme.spacing(isOpenPopUp ? 3 : 2),
  lineHeight: theme.spacing(isOpenPopUp ? 4 : 2),
  whiteSpace: "nowrap",
}));

export const ItemLabel = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(0.5),
  marginBottom: theme.spacing(),
}));

export const LineIndicatorValue = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: theme.spacing(),
  width: "100%",
}));

export const LineIndicatorValueStyled = styled(LineIndicatorValue)(
  ({ theme }) => ({
    paddingTop: theme.spacing(2.5),
  })
);

export const CircleIndicator = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(3),
}));

export const CircleValue = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "baseline",
  gap: theme.spacing(),
  marginBottom: theme.spacing(1.5),

  "&:last-of-type": {
    marginBottom: 0,
  },
}));

export const LegendIndicator = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(),
}));

export const LegendIndicatorItem = styled("div")(({ theme }) => ({
  flex: "1",
  display: "flex",
  alignItems: "baseline",
  gap: theme.spacing(),
}));

export const NPLinkStyled = styled("a")(({ theme }) => ({
  display: "flex",
  position: "absolute",
  left: theme.spacing(3),
  bottom: theme.spacing(2),
}));

export const HeaderButtons = styled("div", {
  shouldForwardProp: (prop) => !["isOpenPopUp"].includes(prop.toString()),
})<{ isOpenPopUp: boolean }>(({ theme, isOpenPopUp }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: theme.spacing(isOpenPopUp ? 2 : 5),
  width: theme.spacing(isOpenPopUp ? 32 : 35),
  whiteSpace: "nowrap",
  transition: theme.transitions.create(["gap"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

export const DropdownWrapper = styled("div")(({ theme }) => ({}));

export const NationalIndicatorsHeader = styled(GeneralIndicatorsHeader, {
  shouldForwardProp: (prop) => !["isOpenPopUp"].includes(prop.toString()),
})<{
  isOpenPopUp?: boolean;
}>(({ theme, isOpenPopUp }) => ({
  ...(isOpenPopUp && {
    position: "absolute",
    top: theme.spacing(-6.7),
    right: theme.spacing(8),
  }),
}));
