import { CSSProperties } from "react";

export const stickyTop = (
  top: number,
  zIndex?: number
): { style: CSSProperties } => {
  return {
    style: {
      position: "sticky",
      top: top,
      zIndex: zIndex,
    } as CSSProperties,
  };
};
