import React, { useCallback, useEffect, useMemo, useState } from "react";

import { IAchievementIndicatorHeaderProps } from "@/pages/ProjectsPage/lib/types";
import { Unit } from "@/shared/Achievements/ui/Achievements.styled";
import { useCheckpointsFilter } from "@/shared/InputSelect/hooks/useCheckpointsFilter";
import { InputSelect } from "@/shared/InputSelect/InputSelect";
import { SELECT_VALUE } from "@/shared/InputSelect/lib/types";
import { formatNumber } from "@/shared/lib/heplers";

import {
  AchievementHeader,
  AchievementTitle,
  AchievementValue,
} from "./ProjectIndicatorsTable.styled";

export const AchievementIndicatorHeader = ({
  changePeriod,
}: IAchievementIndicatorHeaderProps) => {
  const { actualPeriodData } = useCheckpointsFilter();
  const values = actualPeriodData?.indicators?.["0"]?.values?.["802"];
  const valueColor = actualPeriodData?.indicators?.["0"]?.values?.["804"];
  const [period, setPeriod] = useState(SELECT_VALUE.CURRENT_PERIOD);

  const Select = useCallback(
    () => (
      <InputSelect
        isLineEntire
        value={period}
        handleChange={(event) => setPeriod(event.target.value)}
      />
    ),
    [period]
  );

  useEffect(() => {
    if (period) {
      changePeriod(period);
    }
  }, [period]);

  const convertValues = useMemo(() => {
    if (values !== undefined) {
      const fractionDigits = [100, 0].includes(values) ? 0 : 1;
      return formatNumber(values, undefined, fractionDigits);
    }
    return values;
  }, [values]);

  return (
    <AchievementHeader>
      <AchievementTitle>
        Достижение
        {convertValues !== undefined ? (
          <AchievementValue valueColor={valueColor}>
            <span>{convertValues}</span>
            <Unit>%</Unit>
          </AchievementValue>
        ) : (
          " -"
        )}
      </AchievementTitle>
      <Select />
    </AchievementHeader>
  );
};
