import { TAB_ID } from "@store/scheme/region";

export const getActualIndicator = (tabId: string) => {
  switch (tabId) {
    case TAB_ID.PROFIT:
      return "114";
    case TAB_ID.INVESTMENT:
      return "115";
    case TAB_ID.EMPLOYED:
      return "116";
    case TAB_ID.RISKS:
    case TAB_ID.VDL_PROJECTS:
    case TAB_ID.MANUFACTURING:
    default:
      return "105";
  }
};
