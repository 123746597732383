import { Tab, TabPanel, Tabs, TabsList } from "@mui/base";
import { styled } from "@mui/material";

export const TabsUnstyled = styled(Tabs)({
  "@media (max-width: 118.75rem)": {
    padding: "3rem 0",
  },
});

export const TabsListUnstyled = styled(TabsList)({
  "&:focus": {
    outline: "none",
  },
});

export const TabUnstyled = styled(Tab, {
  shouldForwardProp: (prop) =>
    !["afterElement", "isInWork"].includes(prop.toString()),
})<{ afterElement?: boolean; isInWork?: boolean }>(
  ({ theme, afterElement, isInWork }) => ({
    width: 277,
    height: 52,
    marginLeft: theme.spacing(2.5),
    background: "transparent",
    border: `none`,
    borderBottom: "none",
    borderTopLeftRadius: theme.spacing(2),
    borderTopRightRadius: theme.spacing(2),
    color:
      theme.palette.mode === "dark"
        ? theme.palette.text.secondary
        : theme.palette.text.unitText,
    fontSize: 20,
    fontWeight: theme.typography.fontWeightBold,
    cursor: "pointer",
    transition: theme.transitions.create(["color", "background-color"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(isInWork && {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(0.5),
      whiteSpace: "nowrap",
    }),
    "&:first-of-type": {
      marginLeft: 0,
    },

    "&.Mui-selected": {
      backgroundColor:
        theme.palette.mode === "dark"
          ? theme.palette.tabs.selected
          : theme.palette.text.onAccent,
      color: theme.palette.text.primary,
      position: "relative",
      ...(afterElement
        ? {
            "&:after": {
              display: "block",
              content: '""',
              position: "absolute",
              top: "100%",
              left: 0,
              width: theme.spacing(2),
              height: theme.spacing(2),
              backgroundColor: theme.palette.tabs.selected,
            },
          }
        : {}),
    },
    "&:focus": {
      outline: "none",
    },
  })
);

export const TabPanelUnstyled = styled(TabPanel)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
}));
