import { Box, TextField } from "@mui/material";
import { actions } from "@store/redux/slices/auth/tokenSlice";
import { useState } from "react";

import { useAppDispatch } from "@/store/redux/hooks";

import { Control, SubmitButton } from "./Login.styled";

export const Login = () => {
  const dispatch = useAppDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleSubmit = () => {
    dispatch(
      actions.getToken({
        type: "password",
        credentials: { username, password },
      })
    );
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Control>
        <TextField
          label="Логин"
          name="username"
          onChange={handleUsernameChange}
          required
        />
      </Control>
      <Control>
        <TextField
          label="Пароль"
          name="password"
          type="password"
          onChange={handlePasswordChange}
          required
        />
      </Control>
      <SubmitButton variant="contained" type="submit">
        Авторизоваться
      </SubmitButton>
    </Box>
  );
};
