import { SceletonLine2Styled } from "../../Sceleton.styled";
import { SceletonLineWrapper } from "./SceletonLine.styled";

export const SceletonLine = () => {
  return (
    <SceletonLineWrapper>
      {[...Array(3)].map((_, index) => (
        <SceletonLine2Styled key={index} />
      ))}
    </SceletonLineWrapper>
  );
};
