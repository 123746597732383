import { useNationalProjects } from "@/features/NationalProjectsIndicators/hooks/useNationalProjects";
import {
  DotMarkChartBackground,
  DotMarkChartPrimary,
} from "@/shared/Dots/Dots.styled";
import {
  GeneralIndicatorsItemTitle,
  ValueDateWrapper,
  ValueItem,
  ValueText,
} from "@/shared/GeneralIndicators/ui/GeneralIndicators.styled";
import { formatNumber } from "@/shared/lib/heplers";
import { LineChart } from "@/shared/LineChart/ui/LineChart";
import { Value, ValueNumber, ValueUnit } from "@/shared/Value/Value.styled";
import { ValueDate } from "@/shared/ValueDate/ValueDate";
import { MODEL_IDS, MODELS } from "@/store/scheme/olap";

import {
  ItemLabel,
  ItemTitle,
  LegendIndicator,
  LegendIndicatorItem,
  LineIndicator,
  LineIndicatorValue,
  NationalProjectsIndicatorsItem,
} from "../../NationalProjectsIndicators.styled";
import { INationalProjectsItemProps } from "../lib/types";

export const NationalProjectsItem = ({
  title,
  selected,
  indicator,
  isOpenPopUp,
}: INationalProjectsItemProps) => {
  const { data } = useNationalProjects();
  const projectsIndicator = data?.[selected]?.[indicator];

  const model = MODELS[MODEL_IDS.NATIONAL_PROJECTS_INDICATORS];

  const isCheckIndicatorWithOutLegend = indicator === "101";
  const getFormatNumber = (fact: any, indicator: string) => {
    const options: any[] = indicator === "103" ? [undefined, 0] : [];
    return formatNumber(fact, ...options);
  };

  return (
    <NationalProjectsIndicatorsItem>
      <LineIndicator>
        <ItemLabel>
          <ItemTitle isOpenPopUp={isOpenPopUp}>{title}</ItemTitle>

          <ValueDateWrapper>
            <ValueDate isOpenPopUp={isOpenPopUp}>
              {projectsIndicator?.REPORT_DATE}
            </ValueDate>
          </ValueDateWrapper>
        </ItemLabel>

        <LineIndicatorValue
          sx={
            isCheckIndicatorWithOutLegend && isOpenPopUp
              ? { paddingTop: "1.85rem" }
              : isCheckIndicatorWithOutLegend
              ? { paddingTop: "1.25rem" }
              : undefined
          }
        >
          {indicator !== "101" ? (
            <LegendIndicator>
              <LegendIndicatorItem>
                <DotMarkChartPrimary isSmall />

                <div>
                  <GeneralIndicatorsItemTitle isOpenPopUp={isOpenPopUp}>
                    Факт
                  </GeneralIndicatorsItemTitle>

                  <Value>
                    <ValueNumber>
                      <ValueText>
                        <ValueItem
                          isOpenPopUp={isOpenPopUp}
                          sx={{ whiteSpace: "nowrap" }}
                        >
                          {getFormatNumber(
                            projectsIndicator?.indicatorVals?.[
                              model.indexes.fact
                            ],
                            indicator
                          )}
                          {projectsIndicator?.UNIT && indicator !== "103" && (
                            <ValueUnit isOpenPopUp={isOpenPopUp}>
                              {projectsIndicator?.UNIT}
                            </ValueUnit>
                          )}
                        </ValueItem>
                      </ValueText>
                    </ValueNumber>
                  </Value>
                </div>
              </LegendIndicatorItem>

              <LegendIndicatorItem>
                <DotMarkChartBackground isSmall />
                <div>
                  <GeneralIndicatorsItemTitle isOpenPopUp={isOpenPopUp}>
                    План
                  </GeneralIndicatorsItemTitle>

                  <Value>
                    <ValueNumber>
                      <ValueText sx={{ whiteSpace: "nowrap" }}>
                        <ValueItem isOpenPopUp={isOpenPopUp}>
                          {getFormatNumber(
                            projectsIndicator?.indicatorVals?.[
                              model.indexes.plan
                            ],
                            indicator
                          )}
                        </ValueItem>

                        {projectsIndicator?.UNIT && indicator !== "103" && (
                          <ValueUnit isOpenPopUp={isOpenPopUp}>
                            {projectsIndicator?.UNIT}
                          </ValueUnit>
                        )}
                      </ValueText>
                    </ValueNumber>
                  </Value>
                </div>
              </LegendIndicatorItem>
            </LegendIndicator>
          ) : (
            <Value>
              <ValueNumber>
                <ValueText sx={{ whiteSpace: "nowrap" }}>
                  <ValueItem isOpenPopUp={isOpenPopUp}>
                    {getFormatNumber(
                      projectsIndicator?.indicatorVals?.[model.indexes.fact],
                      indicator
                    )}
                  </ValueItem>

                  {projectsIndicator?.UNIT && (
                    <ValueUnit isOpenPopUp={isOpenPopUp}>
                      {projectsIndicator?.UNIT}
                    </ValueUnit>
                  )}
                </ValueText>
              </ValueNumber>
            </Value>
          )}
          <LineChart
            total={
              (isCheckIndicatorWithOutLegend
                ? 100
                : projectsIndicator?.indicatorVals?.[model.indexes.plan]) ?? "-"
            }
            value={
              projectsIndicator?.indicatorVals?.[model.indexes.fact] ?? "-"
            }
          />
        </LineIndicatorValue>
      </LineIndicator>
    </NationalProjectsIndicatorsItem>
  );
};
