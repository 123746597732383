import { styled, Typography } from "@mui/material";
import { TypographyProps } from "@mui/material/Typography/Typography";
import { FC } from "react";

import { ValueHLIcon } from "@/shared/Value/Value.styled";

import { IVEDRowTitleProps } from "../lib/types";

const VEDTitleTextTypography = styled(Typography)(({ theme }) => ({
  fontSize: 15,
  width: "100%",
}));

export const VEDTitleText: FC<TypographyProps> = (props) => (
  <VEDTitleTextTypography variant="subtitle1" {...props} />
);

export const VEDRowTitle = styled("div")<IVEDRowTitleProps>(
  ({ theme, first, isOpenPopUp }) => ({
    fontSize: isOpenPopUp ? "1.25rem" : "1rem",
    flex: "1 0 43%",
    paddingLeft: first ? undefined : theme.spacing(3),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(0.5),
    boxSizing: "border-box",
    "& > div": {
      "&:hover": {
        textDecoration: "underline",
        cursor: "pointer",
      },
    },
  })
);

export const VEDHeader = styled("div")<{ openWidget?: boolean }>(
  ({ theme, openWidget }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flex: "1 1 100%",
    ...(openWidget && {
      maxWidth: theme.spacing(85),
    }),
  })
);

export const VEDRowProgress = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 100%",
  flexDirection: "column",
  gap: theme.spacing(),
}));

export const VEDRowProgressLine = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
}));

export interface IVEDRowProgressValueProps {
  small?: boolean;
  isOpenPopUp?: boolean;
}

export const VEDRowProgressValue = styled("div")<IVEDRowProgressValueProps>(
  ({ theme, small, isOpenPopUp }) => ({
    minWidth: theme.spacing(12.5),
    whiteSpace: "nowrap",
    flex: "0 0 80px",
    fontSize: isOpenPopUp ? (small ? 20 : 24) : small ? 18 : 22,
    lineHeight: 1,
    fontWeight: small
      ? theme.typography.fontWeightRegular
      : theme.typography.fontWeightBold,
  })
);

export const VEDRowHL = styled(ValueHLIcon)<{ isBig?: boolean }>(
  ({ theme, isBig }) => ({
    flexBasis: theme.spacing(isBig ? 6 : 5),
    marginLeft: theme.spacing(2),
  })
);

export const VedWrapperScrollbar = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  ".track": {
    borderRadius: theme.spacing(0.375),
    background: theme.palette.scrollbar.track,
    "&.vertical": {
      right: theme.spacing(0.625),
      bottom: theme.spacing(0.5),
      top: theme.spacing(0.5),
      zIndex: 100,
    },
  },
  ".thumb": {
    cursor: "pointer",
    borderRadius: "inherit",
    background: theme.palette.scrollbar.thumb,
  },
  ".scroll-view": {
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      width: 0,
    },
  },
}));

export const VEDRfFo = styled("span")(({ theme }) => ({
  color: theme.palette.text.tertiary,
  paddingLeft: theme.spacing(),
}));
