import { TableBody, TableHead, TableRow } from "@mui/material";
import React, { useMemo, useRef } from "react";

import { ReactComponent as InfoIcon } from "@/assets/Info.svg";
import { useProjectRisks } from "@/pages/ProjectsPage/hooks/useProjectRisks";
import { RiskRowField } from "@/pages/ProjectsPage/lib/types";
import { CardList } from "@/shared/CardList/CardList.styled";
import { useOpenIncident } from "@/shared/Incident/hooks/useOpenIncident";
import { IncidentCreateButton } from "@/shared/Incident/ui/IncidentCreateButton/IncidentCreateButton";
import { compareRisks, failRiskText } from "@/shared/lib/constants";
import { Sceleton } from "@/shared/Sceleton/ui/Sceleton";
import { StickyTableCell } from "@/shared/TableBase/BasicTable.styled";

import {
  ProjectInfoText,
  ProjectTable,
  ProjectTableCell,
  ProjectTitle,
} from "../common/ProjectTable.styled";
import { MethodCalcRisk } from "./MethodCalcRisk";
import {
  RiskLevel,
  RiskLevelTitle,
  RiskLevelWrapper,
  RiskTitleWrapper,
  RiskToolTipStyled,
  RiskWrapper,
  styleRiskCell,
} from "./ProjectRisksTable.styled";

export const ProjectRisksTable = () => {
  const showRef = useRef<HTMLDivElement | null>(null);
  const { move, handleMove, handleChange } = useOpenIncident();
  const { columns, rows, riskProjectFetching, riskByProject } =
    useProjectRisks();

  const levelRisk = riskByProject?.["levelRisk"];

  const isShowRiskValues = useMemo(() => {
    return ["0", "1", "2"]?.includes(String(levelRisk?.level)) && rows?.length;
  }, [levelRisk?.level, rows?.length]);

  return (
    <CardList
      ref={showRef}
      onMouseEnter={() => handleMove(true)}
      onMouseLeave={() => handleMove(false)}
      sx={{ position: "relative" }}
    >
      <ProjectTitle>
        {isShowRiskValues ? (
          <RiskTitleWrapper>
            <RiskLevelTitle>Уровень риска</RiskLevelTitle>
            <RiskLevelWrapper>
              <RiskLevel colorValue={levelRisk?.color}>
                {compareRisks?.[String(levelRisk?.level)]}
              </RiskLevel>
              <RiskToolTipStyled
                title={<MethodCalcRisk />}
                placement="right-start"
                arrow
              >
                <InfoIcon />
              </RiskToolTipStyled>
            </RiskLevelWrapper>
          </RiskTitleWrapper>
        ) : null}
        {move && (
          <IncidentCreateButton
            bigSize
            posRight={24}
            onClick={(event) => {
              handleChange(
                event,
                showRef?.current,
                "Риски реализации",
                levelRisk?.date
              );
            }}
          />
        )}
      </ProjectTitle>
      {riskProjectFetching ? (
        <Sceleton type="line" heightLine={84} />
      ) : (
        <RiskWrapper>
          {isShowRiskValues ? (
            <ProjectTable fullHeight horizontalBorders>
              <TableHead>
                <TableRow>
                  {columns.map(({ id, label, ...column }: any) => (
                    <ProjectTableCell key={id} {...column}>
                      {label}
                    </ProjectTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.map((row) => (
                  <TableRow key={row?.id}>
                    {columns.map((column) => (
                      <StickyTableCell
                        key={column?.id}
                        style={{ ...styleRiskCell }}
                      >
                        {row?.[column?.id as RiskRowField] ?? "-"}
                      </StickyTableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </ProjectTable>
          ) : (
            <ProjectInfoText>{failRiskText}</ProjectInfoText>
          )}
        </RiskWrapper>
      )}
    </CardList>
  );
};
