export enum REGION_TABS {
  RATE = "rate",
  MANUFACTURING = "manufacturing",
  PROFIT = "profit",
  INVESTMENT = "investment",
  EMPLOYED = "employed",
}

export enum TAB_ID {
  VDL_PROJECTS = "vdlProjects",
  VDL = "vdl",
  PROJECTS = "projects",
  RATE = "rate",
  RISKS = "risks",
  MANUFACTURING = "manufacturing",
  PROFIT = "profit",
  INVESTMENT = "investment",
  EMPLOYED = "employed",
  RESULT_PROJECTS = "resultProjects",
  RESULT_CHECKPOINTS = "resultCheckpoints",
}

export enum TAB_LEVEL {
  ACHIEVE = "achievement",
  INDICATOR = "indicators",
  CHECKPOINT = "checkpoints",
  FINANCE = "financing",
  RISKS = "risks",
}
