import { SerializedError } from "@reduxjs/toolkit";

import { COMPARE_TYPE } from "../CompareControl/lib/types";
import { REGION_COATS, REGION_REPORTS } from "./constants";

export type ArgumentsType = {
  regionId: string;
  dataSummaryId: string;
};
export type ArgumentsWithDistrictIdType = ArgumentsType & {
  districtId: string;
};

export enum REGION_LEVEL {
  COUNTRY = 0,
  DISTRICT = 1,
  REGION = 2,
  PROJECT = 3,
}

export type IRegionCoatKey = keyof typeof REGION_COATS;
export type IRegionReportsKey = keyof typeof REGION_REPORTS;

export interface IRegionsState {
  data?: {
    [indicatorId: string]: any;
  };
  investmentData?: any;
  fetching: boolean;
  investmentFetching?: boolean;
  error: SerializedError | null;
}
export type FactHighLow = Record<
  keyof typeof COMPARE_TYPE,
  "factHighLowFO" | "factHighLowRF"
>;

export type CompareTitle = Record<keyof typeof COMPARE_TYPE, "ФО" | "РФ">;
