import { ChangeEvent, ReactElement, useCallback } from "react";

import { HeaderColumns, ICheckedScheme, IHeaderColumns } from "../lib/types";
import {
  CheckBoxStyled,
  FormControlLabelStyled,
} from "../ui/PopUp/PopUp.styled";

export const useColumns = () => {
  const labelStorage = [] as string[];
  const copyObject = useCallback((object: any) => {
    return JSON.parse(JSON.stringify(object));
  }, []);

  const getParentLabel = (popUpLabel: string) => {
    let label = popUpLabel?.split(" ") || "";
    label.splice(label.indexOf("План"));
    return label.join(" ");
  };

  const getInitialHeaderColsScheme = (initialHeaderCols: IHeaderColumns[]) => {
    return initialHeaderCols.reduce(
      (
        prev,
        { label, popUpLabel, parentHeaderLabel, defaultColumn, dateCode = "" }
      ) => {
        prev[popUpLabel || label] = {
          checked: false,
          popUpLabel: popUpLabel,
          isDateCode: !!dateCode,
        };
        if (parentHeaderLabel) {
          prev[parentHeaderLabel].quantitySubColumns =
            (prev[parentHeaderLabel].quantitySubColumns || 0) + 1;
          if (defaultColumn) {
            prev[parentHeaderLabel].defaultQuantity =
              (prev[parentHeaderLabel].defaultQuantity || 0) + 1;
          }
        }
        return prev;
      },
      {} as ICheckedScheme
    );
  };

  const sortedColumns = (columns: HeaderColumns[]) => {
    return columns
      ?.filter((column) => !!column?.popUpLabel)
      ?.sort((sortA, sortB) =>
        (sortA?.sortId || 0) > (sortB?.sortId || 0) ? 1 : -1
      );
  };

  const collectionCheckBoxes = useCallback(
    (
      columns: IHeaderColumns[],
      checkedScheme: ICheckedScheme,
      handleChangeCheckBox: (event: ChangeEvent<HTMLInputElement>) => void
    ) => {
      return [...sortedColumns(columns)]?.reduce(
        (prev, { popUpLabel, parentHeaderLabel, defaultColumn, label }) => {
          if (popUpLabel) {
            if (!labelStorage.includes(popUpLabel)) {
              labelStorage.push(popUpLabel);
              prev.push(
                <FormControlLabelStyled
                  key={popUpLabel}
                  control={
                    <CheckBoxStyled
                      checked={checkedScheme?.[popUpLabel]?.checked}
                      name={parentHeaderLabel}
                      onChange={handleChangeCheckBox}
                      id={popUpLabel}
                      key={`${popUpLabel}_${label}`}
                      disabled={defaultColumn}
                    />
                  }
                  label={popUpLabel}
                />
              );
            }
          }
          return prev;
        },
        [] as ReactElement[]
      );
    },
    [labelStorage]
  );

  return {
    copyObject,
    collectionCheckBoxes,
    getParentLabel,
    getInitialHeaderColsScheme,
  };
};
