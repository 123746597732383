import PhotoIcon from "@mui/icons-material/Photo";
import { useTheme } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { ContentScrollbar } from "@/app/AppComponent/ui/App.styled";
import { CustomScrollbar } from "@/shared/CustomScrollbar/ui/CustomScrollbar";
import { DefaultButton } from "@/shared/DefaultButton/DefaultButton";
import { Loader } from "@/shared/Loader/Loader";
import { ModalWrapper } from "@/shared/Modal/Modal";
import { CloseButton, FormHeader } from "@/shared/Table/ui/PopUp/PopUp.styled";

import { useIncident } from "../../hooks/useIncident";
import {
  createdGasuText,
  createdIncidentText,
  gasuCabinet,
} from "../../lib/constants";
import { base64ToFile, getLinkFile } from "../../lib/helpers";
import { IIncidentDefaultFormData, IIncidentEditFields } from "../../lib/types";
import { initialState } from "../../model/slices/incidentSlice";
import { IncidentTooltipStyled } from "../IncidentCreateButton/IncidentCreateButton.styled";
import { IncidentForm } from "../IncidentForm/IncidentForm";
import {
  GroupButton,
  IncidentFooter,
  IncidentHeaderGroup,
  IncidentHeaderTitle,
  IncidentMain,
  IncidentScreenButton,
} from "./IncidentWrapper.styled";

export const IncidentWrapper = () => {
  const theme = useTheme();
  const {
    incident: {
      open,
      data: { screenshot, titleWidget },
    },
    fetching,
    setMoveIncident,
    requestIncident,
  } = useIncident();
  const [isCreatedIncident, setIsCreatedIncident] = useState<boolean>(false);
  const [incidentEditFields, setIncidentEditFields] =
    useState<IIncidentEditFields>({
      incidentLevel: "",
      incidentType: "",
      description: "",
    });
  const [incidentFormData, setIncidentFormdata] = useState<
    IIncidentDefaultFormData | {}
  >({});

  useEffect(() => {
    setIsCreatedIncident(false);
    setIncidentFormdata({});
    setIncidentEditFields({
      incidentLevel: "",
      incidentType: "",
      description: "",
    });
  }, [open]);

  const handleCloseIncident = useCallback(() => {
    setMoveIncident({ ...initialState.incident });
  }, []);

  const screenshotFormat = async () =>
    await base64ToFile(screenshot, titleWidget);

  const handleScreenshot = async () => {
    getLinkFile(screenshot, titleWidget);
  };

  const handleFillForm = (data: IIncidentDefaultFormData) => {
    setIncidentFormdata(data);
  };
  const handleSendForm = async () => {
    // Создание File из скриншота для отправки в api
    const fileScreenshot = await screenshotFormat();

    requestIncident(fileScreenshot, {
      titleWidget,
      ...incidentEditFields,
      ...incidentFormData,
    });
    setIsCreatedIncident(true);
  };

  const getMessageAfterSendForm = useMemo(() => {
    return fetching ? (
      <div className="loader-wrapper">
        <Loader />
      </div>
    ) : (
      <>{createdIncidentText}</>
    );
  }, [fetching]);

  return (
    <ModalWrapper
      open={open}
      onClose={handleCloseIncident}
      width={isCreatedIncident ? 83 : 133}
      height={isCreatedIncident ? 30.5 : 100}
      children={
        <>
          <FormHeader
            sx={{
              background: theme.palette.dropdownWidget.baseHeader,
              borderBottom: "transparent",
              gap: "1rem",
            }}
          >
            <IncidentHeaderTitle>Создать обращение</IncidentHeaderTitle>

            <IncidentHeaderGroup>
              {!isCreatedIncident && (
                <>
                  <IncidentTooltipStyled
                    title="Скриншот элемента"
                    placement="top"
                    arrow
                  >
                    <IncidentScreenButton onClick={handleScreenshot}>
                      <PhotoIcon />
                    </IncidentScreenButton>
                  </IncidentTooltipStyled>
                </>
              )}
              <CloseButton onClick={handleCloseIncident} />
            </IncidentHeaderGroup>
          </FormHeader>
          <ContentScrollbar>
            <CustomScrollbar>
              <IncidentMain>
                {isCreatedIncident ? (
                  <p>{getMessageAfterSendForm}</p>
                ) : (
                  <IncidentForm
                    incidentEditFields={incidentEditFields}
                    callbackForm={handleFillForm}
                    callbackEditFields={setIncidentEditFields}
                  />
                )}
              </IncidentMain>
            </CustomScrollbar>
          </ContentScrollbar>
          <IncidentFooter isCreated={isCreatedIncident}>
            {isCreatedIncident ? (
              <>
                <Link to={gasuCabinet} target="_blank">
                  {createdGasuText}
                </Link>
                <DefaultButton
                  titleText="Закрыть"
                  onClick={handleCloseIncident}
                />
              </>
            ) : (
              <GroupButton>
                <DefaultButton
                  key="Отменить"
                  titleText="Отменить"
                  onClick={handleCloseIncident}
                />
                <DefaultButton
                  selected
                  disabled={
                    !(
                      incidentEditFields.incidentLevel &&
                      incidentEditFields.incidentType &&
                      incidentEditFields.description
                    )
                  }
                  key="Направить"
                  titleText="Направить"
                  onClick={handleSendForm}
                />
              </GroupButton>
            )}
          </IncidentFooter>
        </>
      }
    />
  );
};
