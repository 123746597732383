import { useMemo } from "react";

import { useFeedback } from "@/shared/Feedback/hooks/useFeedback";
import { useIncident } from "@/shared/Incident/hooks/useIncident";
import { IncidentWrapper } from "@/shared/Incident/ui/IncidentWrapper/IncidentWrapper";
import { useIndicatorPassport } from "@/widgets/IndicatorPassport/hooks/useIndicatorPassport";
import { IndicatorPassport } from "@/widgets/IndicatorPassport/ui/IndicatorPassport";

import { FeedbackWrapper } from "../../Feedback/ui/FeedbackWrapper";

export const useModal = () => {
  // Хук для компонентов, которые вызываются глобально, через dispatch (Обратная связь, Паспорт, Создание обращения)
  const { isOpenFeedback } = useFeedback();
  const {
    incident: { open: isOpenIncident },
  } = useIncident();
  const {
    indicatorPassport: { open: isOpenPassport },
  } = useIndicatorPassport();

  const componentModal = useMemo(() => {
    switch (true) {
      case isOpenFeedback:
        return <FeedbackWrapper />;
      case isOpenIncident:
        return <IncidentWrapper />;
      case isOpenPassport:
        return <IndicatorPassport />;
      default:
        return null;
    }
  }, [isOpenFeedback, isOpenIncident, isOpenPassport]);

  return { componentModal };
};
