import client from "@/store/api/client";

import { IIncidentSueAllRequest } from "../../lib/types";

export const postIncident = async ({ file, data }: IIncidentSueAllRequest) => {
  const response = await postFileIncident(file);
  if (response?.data && ["string", "number"].includes(typeof response?.data)) {
    data.files = [+response.data];
  }
  return await client.post("/api-sue/api/create-arm-incident", data);
};

// Отправка File
export const postFileIncident = async (file: File) => {
  const formData = new FormData();
  formData.append("file", file);
  try {
    const response = await client.post("/api-sue/api/file", formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    return response;
  } catch (err) {
    return null;
  }
};
