export type AchievementLevelValues = {
  value?: string;
  color?: string;
  fill?: string;
};

export type AchievementLevelProps = {
  size?: number;
  unit?: string;
  value?: number;
  color?: string;
  radius?: string;
  text?: string;
  blur?: string;
  blurValue?: number;
  isHideText?: boolean;
  isHighLight?: boolean;
  isOpenPopUp?: boolean;
  bunchValues?: AchievementLevelValues[];
};

export enum ACHIEVEMENT_COLOR {
  GREY = "GREY",
  RED = "RED",
  GREEN = "GREEN",
}

export interface AchievementsProps {
  selected?: boolean;
}
