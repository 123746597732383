import { useTheme } from "@mui/material";
import { FC } from "react";

import { colorBasedOnValue } from "@/pages/MainPage/lib/helpers";
import { AchievementLevel } from "@/shared/AchievementLevel/ui/AchievementLevel";
import { IAchievementCard } from "@/shared/AchievementTabCard/lib/types";
import { GridCard } from "@/shared/GridCard/ui/GridCard";

export const ProjectsCard: FC<IAchievementCard> = ({
  data: t,
  selected,
  setSelectedTabId,
}) => {
  const theme = useTheme();

  return (
    <GridCard
      subTitle="Достижение"
      setSelectedTabId={setSelectedTabId}
      key={t.id}
      gridArea={t.gridArea}
      noCloseButton
      data={t}
      selected={selected}
      direction="row"
      dateLeft
    >
      <AchievementLevel
        size={180}
        unit={t.unit}
        value={t.value}
        color={colorBasedOnValue(theme, t?.color || null, selected)}
      />
    </GridCard>
  );
};
