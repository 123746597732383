import { useAppDispatch, useAppSelector } from "@/store/redux/hooks";

import { extraActionsRisksTable } from "../model/riskSubjectsTableSlice";

export const useRisksSubjectsProjects = () => {
  const dispatch = useAppDispatch();
  const { riskData, riskProjectData } = useAppSelector(
    (state) => state.map.riskTable
  );

  const requestRiskTable = (currentPeriod: string) => {
    dispatch(extraActionsRisksTable.post(currentPeriod));
    dispatch(extraActionsRisksTable.postRiskProjects(currentPeriod));
  };

  return { riskData, riskProjectData, requestRiskTable };
};
