import {
  IOlapResponseDimensionItemDim,
  IProjectCheckpointAttributeVals,
} from "@/store/scheme/olap";

export enum SELECT_VALUE {
  CURRENT_PERIOD = "CURRENT_PERIOD",
  ENTIRE_PERIOD = "ENTIRE_PERIOD",
}

export interface ICheckpoints {
  [
    key: string
  ]: IOlapResponseDimensionItemDim<IProjectCheckpointAttributeVals>[];
}
