import React, { FC, useCallback } from "react";

import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { CardListRow } from "@/shared/CardList/CardList.styled";
import { CompareIcons } from "@/shared/CompareIcons/ui/CompareIcons";
import { formatNumber } from "@/shared/lib/heplers";
import { LineChart } from "@/shared/LineChart/ui/LineChart";
import { useRegionPopUp } from "@/shared/RegionPopUp/hooks/useRegionPopUp";
import { ValueDate } from "@/shared/ValueDate/ValueDate";
import { WidgetScrollbar } from "@/shared/WidgetScrollbar/ui/WidgetScrollbar";
import { useIndicatorPassport } from "@/widgets/IndicatorPassport/hooks/useIndicatorPassport";

import { IVEDProps } from "../lib/types";
import {
  VEDHeader,
  VEDRfFo,
  VEDRowHL,
  VEDRowProgress,
  VEDRowProgressLine,
  VEDRowProgressValue,
  VEDRowTitle,
  VEDTitleText,
} from "./VED.styled";

export const VED: FC<IVEDProps> = ({
  icon,
  title,
  rows = [],
  popUpId,
  isOpenPopUp,
  maxRowValue,
  titleIncident,
}) => {
  const { compareLabel } = useRegions();
  const { openIndicatorPassport } = useIndicatorPassport();
  const { PopUp, PopUpButton, popupHeightMax }: any = useRegionPopUp(
    popUpId,
    isOpenPopUp,
    <>
      {icon}
      <VEDHeader openWidget={true}>{title}</VEDHeader>
    </>,
    undefined,
    undefined,
    920
  );

  const handleOpenPassport = useCallback(
    (indicatorId: string) => {
      openIndicatorPassport(indicatorId);
    },
    [openIndicatorPassport]
  );

  return (
    <WidgetScrollbar
      isOpenPopUp={isOpenPopUp}
      titleIncident={typeof title === "string" ? title : titleIncident}
      icon={icon}
      header={
        !isOpenPopUp && (
          <VEDTitleText>
            <VEDHeader>
              {title}
              <PopUpButton />
            </VEDHeader>
          </VEDTitleText>
        )
      }
      heightMax={popupHeightMax}
      autoHeight={isOpenPopUp}
    >
      {rows
        .filter((row) => row.value > 0)
        .map((row) => {
          const max = Math.max(
            row.value,
            row?.valueRegion ?? maxRowValue ?? 100
          );
          return (
            <CardListRow isOpenPopUp={!!isOpenPopUp} key={row.key}>
              <VEDRowTitle
                first={["1", "56"].includes(row.key)}
                isOpenPopUp={isOpenPopUp}
              >
                <div
                  onClick={() => {
                    row.key && handleOpenPassport(row?.key);
                  }}
                >
                  {row.title}
                </div>
                <ValueDate isOpenPopUp={isOpenPopUp}>
                  {row?.reportDate}
                </ValueDate>
              </VEDRowTitle>
              <VEDRowProgress>
                <VEDRowProgressLine>
                  <LineChart
                    total={max}
                    value={row.value}
                    type={row?.type || "secondary"}
                  />
                  <VEDRowProgressValue isOpenPopUp={isOpenPopUp}>
                    {formatNumber(row.value, "%")}
                  </VEDRowProgressValue>
                </VEDRowProgressLine>

                {!!row?.valueRegion && (
                  <VEDRowProgressLine>
                    <LineChart
                      total={max}
                      value={row.valueRegion}
                      type="primary"
                    />
                    <VEDRowProgressValue small isOpenPopUp={isOpenPopUp}>
                      {formatNumber(row.valueRegion, "%")}

                      <VEDRfFo>{compareLabel}</VEDRfFo>
                    </VEDRowProgressValue>
                  </VEDRowProgressLine>
                )}
              </VEDRowProgress>
              {row?.valueHL !== undefined && (
                <VEDRowHL isBig={isOpenPopUp}>
                  {<CompareIcons isBig={isOpenPopUp} value={row.valueHL!!} />}
                </VEDRowHL>
              )}
            </CardListRow>
          );
        })}
      <PopUp />
    </WidgetScrollbar>
  );
};
