import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { prepareAttributes } from "@/shared/lib/heplers";
import {
  IPreparedIndicatorAttributeVals,
  MODEL_IDS,
  MODELS,
} from "@/store/scheme/olap";

import { ISumRfProjectsState } from "../../lib/types";
import { getRFSumProjects } from "../api/sum_rf_projects_api";

export const initialState: ISumRfProjectsState = {
  sumProjectsByRf: undefined,
  fetching: true,
  error: null,
};

export const extraActionsSumRfProjects = {
  get: createAsyncThunk(
    "sum_rf_projects/get",
    async (dataSummaryId: string) => await getRFSumProjects(dataSummaryId)
  ),
};

const sumRfProjectsSlice = createSlice({
  name: "sum_rf_projects",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActionsSumRfProjects.get.pending, (state) => {
        state.sumProjectsByRf = undefined;
        state.fetching = true;
      })
      .addCase(extraActionsSumRfProjects.get.fulfilled, (state, action) => {
        const model = MODELS[MODEL_IDS.PROJECT_PASSPORT];
        state.sumProjectsByRf = action.payload.reduce((prev, cur) => {
          // Периоды с 3 - квартальные для фактов, 120240000, 120300000
          const period = cur?.code;
          if (!prev[period]) {
            prev[period] = {};
          }
          const { code: districtId } = cur.dims[model.dataRegionId];
          // Проверка на 760 и из него берем districtId
          if (districtId) {
            if (!prev[period][districtId]) {
              prev[period][districtId] = {};
            }
            const indicatorId = cur.dims[model.dimId].code;
            const ditrictIdDimId = (cur?.dims[model.dataRegionId] as any)
              ?.dimId as string;
            // Проверка на 1129 и из него берем 4 индикатора 106, 107, 108, 109
            if (indicatorId) {
              // Структура {320220004 => id district => 106 => данные}
              prev[period][districtId][indicatorId] = {
                ...prepareAttributes<IPreparedIndicatorAttributeVals>(
                  cur.dims[model.dimId].attributeVals
                ),
                attributeVals: cur?.attributeVals || {},
                indicatorVals: Object.fromEntries(
                  Object.entries(cur?.indicatorVals || {}).map(
                    ([key, value]) => [key, value?.sum]
                  )
                ) as any,
              };

              // Для {пример => 320220004 => id district => 760 берем наименование, дату
              prev[period][districtId][ditrictIdDimId] = {
                ...prepareAttributes<IPreparedIndicatorAttributeVals>(
                  cur.dims[model.dataRegionId].attributeVals
                ),
                attributeVals: cur?.attributeVals || {},
              };
            }
          }
          return prev;
        }, {} as any);
        state.fetching = false;
        state.error = null;
      })
      .addCase(extraActionsSumRfProjects.get.rejected, (state, action) => {
        state.fetching = false;
        state.error = action.error;
      });
  },
});

export const reducer = sumRfProjectsSlice.reducer;

export default sumRfProjectsSlice;
