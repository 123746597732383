import client from "@/store/api/client";
import { getOperands, olapRequestData2 } from "@/store/api/core";
import {
  FILTER_OPERAND_TYPE,
  FILTER_TYPE,
  MODEL_IDS,
  MODELS,
} from "@/store/scheme/olap";

import { IFeedbackRequest } from "../lib/types";

export const postFeedback = async (feedbackData: IFeedbackRequest) => {
  return await client.post("/api-ind/api/create-arm-feedback", feedbackData);
};

export const getAnalyticsFeedback = () => {
  const model = MODELS[MODEL_IDS.INCIDENT];

  const dimensions = [
    {
      id: model.dataPeriodId,
      includeItems: true,
      includeAttributesByCodes: ["REPORT_DATE"],
    },
    { id: model.dataRegionId },
    { id: model.dimId },
  ];

  const indicators = {
    id: model.indicatorId,
    items: [{ id: model.indexes.fact }],
  };

  const operands = getOperands([
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      dim_id: model.dataPeriodId,
      showLeafsOnly: false,
      showChildren: false,
      levels: [2],
      showAllLevelElements: false,
    },
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      dim_id: model.dataRegionId,
      showLeafsOnly: false,
      showChildren: false,
      levels: [0, 1],
      showAllLevelElements: false,
    },
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      dim_id: model.dimId,
      showLeafsOnly: false,
      showChildren: false,
      levels: [1],
      showAllLevelElements: false,
    },
  ]);

  return olapRequestData2(
    dimensions.map(({ id }) => id),
    model.indicatorId,
    {
      includeGaps: false,
      dataRequestCaching: false,
      modelUuid: model.uuid,
      dimensions,
      indicators,
      dataFilter: {
        type: FILTER_TYPE.AND,
        version: 1,
        operands,
      },
    },
    "9ee7f02b-1e09-4836-beb5-90d62d9715b9"
  );
};
