import { useAppSelector } from "@store/redux/hooks";
import { MODEL_IDS, MODELS } from "@store/scheme/olap";
import { FC } from "react";

import { useTabs } from "@/app/hooks/useTabs";
import { Table } from "@/shared/Table/ui/Table";

import { useKeysIndicatorsColumns } from "../../hooks/useKeysIndicatorsColumns";
import { AverageKeysIndicatorsTableProps } from "../../lib/types";

export const AverageKeysIndicatorsTable: FC<
  AverageKeysIndicatorsTableProps
> = ({ openColumnsModal, closeColumnsModal }) => {
  const model = MODELS[MODEL_IDS.KPI_SOC_ECONOMY];
  const { kpiData } = useAppSelector((state) => state.map.geo);
  const { checkPeriodByHideDate } = useTabs();

  const { keysIndicatorsColumns } = useKeysIndicatorsColumns({
    model,
    kpiData,
    checkPeriodByHideDate,
  });

  return (
    <Table
      helperHeder="в том числе"
      headerColumns={keysIndicatorsColumns}
      openColumnsModal={openColumnsModal}
      closeColumnsModal={closeColumnsModal}
    />
  );
};
