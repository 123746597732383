import { olapRequestDimensionData } from "@/store/api/core";
import { MODEL_IDS, MODELS } from "@/store/scheme/olap";

export const getTypeIncidents = () => {
  const model = MODELS[MODEL_IDS.INCIDENT];

  return olapRequestDimensionData(
    model.dimId,
    {
      modelUuid: model.uuid,
      dimensionId: model.indexes.dmTypeOfIncident,
      includeAttributesByCodes: ["name"],
      dataRequestCaching: true,
    },
    "0384c64c-a39a-42dd-be0c-8c5658141bad"
  );
};
