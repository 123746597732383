import { useAppSelector } from "@store/redux/hooks";
import { FC } from "react";

import { Footer } from "@/shared/Footer/ui/Footer";
import { FooterHighLow } from "@/shared/Footer/ui/subComponents/FooterHighLow";
import { REGION_COUNTRY_CODE } from "@/shared/lib/constants";
import { useTransitionPage } from "@/shared/Table/hooks/useTransitionPage";

import { DarkHomeWrapper } from "./InsideMainPage.styled";
import { AchievementDisableTabs } from "./InsideMainPageTabs/AchievementDisableTabs/AchievementDisableTabs";
import { AchievementEnableTabs } from "./InsideMainPageTabs/AchievementEnableTabs/AchievementEnableTabs";

export const InsideMainPage: FC = () => {
  const { achievement } = useAppSelector((state) => state.controls);
  const { subjectId } = useTransitionPage();
  const isShowExport = subjectId?.districtId === REGION_COUNTRY_CODE;

  return (
    <DarkHomeWrapper>
      {achievement.enabled && (
        <AchievementEnableTabs isShowExport={isShowExport} />
      )}
      {!achievement.enabled && (
        <AchievementDisableTabs isShowExport={isShowExport} />
      )}

      {achievement.enabled ? null : (
        <Footer>
          <FooterHighLow />
        </Footer>
      )}
    </DarkHomeWrapper>
  );
};
