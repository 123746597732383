import { styled } from "@mui/material";

import { CardListRow } from "@/shared/CardList/CardList.styled";

export const EnterpriseTitle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
}));

export const EnterpriseHeader = styled("div")<{ openWidget?: boolean }>(
  ({ theme, openWidget }) => ({
    flex: "1 0 35%",
    alignSelf: "center",
    fontWeight: 500,
    fontSize: theme.spacing(openWidget ? 2.5 : 2),
    lineHeight: theme.spacing(openWidget ? 3 : 2.5),
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  })
);

export const EnterpriseAbsolut = styled("div")<{ openWidget?: boolean }>(
  ({ theme, openWidget }) => ({
    display: "flex",
    flexDirection: "column",
    flex: "1 0 15%",
    fontWeight: 700,
    fontSize: theme.spacing(openWidget ? 2.5 : 2.25),
    lineHeight: theme.spacing(2.5),
  })
);

export const EnterpriseListRow = styled(CardListRow)<{ isOpenPopUp: boolean }>(
  ({ theme, isOpenPopUp }) => ({
    ...(isOpenPopUp && {
      background: theme.palette.popup.popupBackground,
      padding: theme.spacing(3, 5),
    }),
    flex: "1 1 33%",
    gap: theme.spacing(),
    boxSizing: "border-box",
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.tabs.backgroundContent}`,
    },
  })
);

export const EnterpriseValue = styled("div")<{
  isGray?: boolean;
  isLine?: boolean;
  openWidget?: boolean;
}>(({ theme, isGray = false, isLine = false, openWidget }) => ({
  ...(isGray && {
    fontWeight: 400,
    fontSize: theme.spacing(openWidget ? 2.5 : 2.25),
    paddingBottom: theme.spacing(),
  }),
  ...(isLine && {
    minWidth: theme.spacing(12.5),
    whiteSpace: "nowrap",
    flex: "0 0 80px",
    fontSize: isGray ? (openWidget ? 20 : 18) : openWidget ? 24 : 22,
    lineHeight: 1,
    fontWeight: isGray
      ? theme.typography.fontWeightRegular
      : theme.typography.fontWeightBold,
  }),
}));

export const EnterpriseWrapper = styled("div")(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
}));
