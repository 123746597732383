import { FileType, Periods } from "./types";

export const MIN_YEAR = 2022;
export const QUARTERS = ["I", "II", "III", "IV"];
export const fileName = "РФ";

// Ручной ввод данных для отображения доступных периодов.
// Добавлять данные при загрузке в management_peropt и в Reports.tsx
export const periods: Record<string, Periods[]> = {
  2022: [
    { quarter: "1", formatFile: ["ods", "pdf", "xlsx"] },
    { quarter: "2", formatFile: ["ods", "pdf", "xlsx"] },
    { quarter: "3", formatFile: ["ods", "pdf", "xlsx"] },
    { quarter: "4", formatFile: ["ods", "pdf", "xlsx"] },
  ],
  2023: [
    { quarter: "1", formatFile: ["ods", "pdf", "xlsx"] },
    { quarter: "2", formatFile: ["ods", "pdf", "xlsx"] },
  ],
};

export const fileTypeSolid: FileType[] = ["xlsx", "pdf", "ods"];
// Ручной ввод данных для отображения доступных периодов для РФ.
export const allowedRfPeriods = [2023];
export const lastAvailableRfPeriod =
  allowedRfPeriods[allowedRfPeriods.length - 1];

export const periodsUpLoadingRf = () => {
  const emptyArray = [];
  for (let i = 2023; i < 2031; i++) {
    emptyArray.push({ id: i, isDisabled: !allowedRfPeriods.includes(i) });
  }
  return emptyArray;
};
