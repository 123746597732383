import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { VALUE_COLOR } from "@/shared/Table/model/api/scheme";
import { ValueChangeSup } from "@/shared/Value/Value.styled";

import { RegionPlaceColor, RegionPlaceWrapper } from "./RegionPlace.styled";

export const RegionPlace = () => {
  const { compareLabel } = useRegions();

  return (
    <RegionPlaceWrapper>
      <RegionPlaceColor>
        {`место в ${compareLabel}`}
        <ValueChangeSup difference={VALUE_COLOR.BLUE}>x</ValueChangeSup>
      </RegionPlaceColor>
      <div>Изменение места</div>
    </RegionPlaceWrapper>
  );
};
