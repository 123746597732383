import { DataName, IPanelGraphs } from "./types";

export const goalsTitle = "Достижение по целям развития экономики",
  indicatorsTitle = "Достижение по показателям проектов",
  checkpointsTitle = "Достижение по контрольным точкам проектов";

export const goalsDescription = (
  <>
    Прогноз МЭР на 30.09.2022 <br /> Нарастающий итог к соответствующему периоду
    2020 года
  </>
);

export const indicatorsDescription =
  "Рассчитывается на основе среднего достижения по всем реализуемым проектам на территории РФ, ФО, субъекта РФ";

export enum GRAPH_LEVEL {
  VDL = "vdl",
  RESULT_PROJECTS = "resultProjects",
  RESULT_CHECKPOINTS = "resultCheckpoints",
}

export const tabsGraphs: Record<string, number | string | GRAPH_LEVEL>[] = [
  { id: 1, value: GRAPH_LEVEL.VDL, title: goalsTitle },
  { id: 2, value: GRAPH_LEVEL.RESULT_PROJECTS, title: indicatorsTitle },
  { id: 3, value: GRAPH_LEVEL.RESULT_CHECKPOINTS, title: checkpointsTitle },
];

export const panelGraphs: IPanelGraphs[] = [
  { id: 1, value: GRAPH_LEVEL.VDL, description: goalsDescription },
  {
    id: 2,
    value: GRAPH_LEVEL.RESULT_PROJECTS,
    description: indicatorsDescription,
  },
  { id: 3, value: GRAPH_LEVEL.RESULT_CHECKPOINTS },
];

export const defineTabs: Record<string, string> = {
  [GRAPH_LEVEL.VDL]: goalsTitle,
  [GRAPH_LEVEL.RESULT_CHECKPOINTS]: checkpointsTitle,
  [GRAPH_LEVEL.RESULT_PROJECTS]: indicatorsTitle,
};

export const defineDataName: Record<string, DataName> = {
  [GRAPH_LEVEL.VDL]: "Goals",
  [GRAPH_LEVEL.RESULT_CHECKPOINTS]: "Chekpoints",
  [GRAPH_LEVEL.RESULT_PROJECTS]: "Indicators",
};
