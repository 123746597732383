import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosBasicCredentials } from "axios";

import { getToken } from "./api/client";

export interface ITokenState {
  type: "password" | "oam_remote_user";
  access_token?: string;
  refresh_token?: string;
  token_type?: string;
  fetching: boolean;
}

const tokenInfoJSON = localStorage.getItem("tokenInfo");
const tokenInfo = tokenInfoJSON ? JSON.parse(tokenInfoJSON) : {};

export const initialState: ITokenState = {
  type: "password",
  access_token: undefined,
  refresh_token: undefined,
  token_type: undefined,
  fetching: false,
};

export const extraActions = {
  getToken: createAsyncThunk(
    "auth/token/get",
    async ({
      type,
      credentials,
    }: {
      type: ITokenState["type"];
      credentials?: AxiosBasicCredentials;
    }) => await getToken(type, credentials)
  ),
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.access_token = undefined;
      state.refresh_token = undefined;
      state.token_type = undefined;

      localStorage.removeItem("tokenInfo");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.getToken.pending, (state, action) => {
        state.type = action.meta.arg.type;
        state.access_token = undefined;
        state.refresh_token = undefined;
        state.token_type = undefined;
        state.fetching = true;
      })
      .addCase(extraActions.getToken.fulfilled, (state, action) => {
        const { access_token, refresh_token, token_type } = action.payload.data;

        state.access_token = access_token;
        state.refresh_token = refresh_token;
        state.token_type = token_type;

        localStorage.setItem(
          "tokenInfo",
          JSON.stringify({
            access_token,
            refresh_token,
            token_type,
          })
        );

        state.fetching = false;
      })
      .addCase(extraActions.getToken.rejected, (state, action) => {
        if (action.meta.arg.type === "password") {
          state.access_token = tokenInfo.access_token;
          state.refresh_token = tokenInfo.refresh_token;
          state.token_type = tokenInfo.token_type;
        }

        state.fetching = false;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};
export const reducer = slice.reducer;

export default slice;
