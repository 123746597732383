import React from "react";

import {
  BasicPage,
  BasicSection,
} from "@/pages/RegionPage/ui/RegionPage.styled";
import { TopBar } from "@/shared/TopBar/ui/TopBar";

import { ActsContent } from "./ActsContent/ui/ActsContent";

export const NormativeLegalActs = () => {
  return (
    <BasicPage>
      <BasicSection stretch>
        <TopBar title="Нормативно-правовые акты" back={-1} />
        <ActsContent />
      </BasicSection>
    </BasicPage>
  );
};
