import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Grid, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";

import { useIncident } from "../../hooks/useIncident";
import { useIncidentForm } from "../../hooks/useIncidentForm";
import { screenshotTitle } from "../../lib/constants";
import { IIncidentFormProps } from "../../lib/types";
import {
  FormLabelStyled,
  InputStyled,
  PlaceholderStyled,
  ScreenshotStyled,
  SelectStyled,
  TextFieldStyled,
} from "./IncidentForm.styled";

export const IncidentForm = ({
  incidentEditFields,
  callbackForm,
  callbackEditFields,
}: IIncidentFormProps) => {
  const {
    incident: {
      data: { titleWidget },
    },
    levelIncident,
    typeIncident,
  } = useIncident();
  const [isLimitSymbol, setIsLimitSymbol] = useState(false);
  const {
    territory,
    reportPeriod,
    infoPanel,
    authorIncident,
    dateCreateIncident,
    panelLink,
    menuStyle,
  } = useIncidentForm();

  useEffect(() => {
    setIsLimitSymbol(false);
    callbackForm({
      reportPeriod,
      panelLink,
      authorIncident,
      dateCreateIncident,
      infoPanel,
      territory,
    });
  }, []);

  const handleEditFields = (type: string, data: string) => {
    if (type === "description") {
      // Проверка на количество символов в инпуте Описание
      if (data?.length >= 5000) {
        setIsLimitSymbol(true);
        return;
      } else {
        if (isLimitSymbol) setIsLimitSymbol(false);
      }
    }
    callbackEditFields((state) => ({ ...state, [type]: data }));
  };

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormLabelStyled>Уровень обращения</FormLabelStyled>
          <SelectStyled
            displayEmpty
            fullWidth
            renderValue={(selected: any) => {
              if (selected.length === 0) {
                return <PlaceholderStyled>Не выбрано</PlaceholderStyled>;
              }

              return selected;
            }}
            name="incidentLevel"
            value={incidentEditFields.incidentLevel}
            IconComponent={KeyboardArrowDownIcon}
            MenuProps={{ sx: menuStyle }}
            onChange={(event) =>
              handleEditFields("incidentLevel", event.target.value as string)
            }
          >
            {levelIncident?.map(({ id, name }) => (
              <MenuItem key={id} value={name}>
                {name}
              </MenuItem>
            ))}
          </SelectStyled>
        </Grid>
        <Grid item xs={6}>
          <FormLabelStyled>Тип обращения</FormLabelStyled>
          <SelectStyled
            fullWidth
            displayEmpty
            renderValue={(selected: any) => {
              if (selected.length === 0) {
                return <PlaceholderStyled>Не выбрано</PlaceholderStyled>;
              }

              return selected;
            }}
            name="incidentType"
            value={incidentEditFields.incidentType}
            IconComponent={KeyboardArrowDownIcon}
            MenuProps={{ sx: menuStyle }}
            onChange={(event) =>
              handleEditFields("incidentType", event.target.value as string)
            }
          >
            {typeIncident?.map(({ id, name }) => (
              <MenuItem key={id} value={name}>
                {name}
              </MenuItem>
            ))}
          </SelectStyled>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <FormLabelStyled>Описание</FormLabelStyled>
          <TextFieldStyled
            error={isLimitSymbol}
            helperText={isLimitSymbol ? "Превышено количество символов" : ""}
            fullWidth
            multiline
            maxRows={6}
            placeholder="Введите текст"
            name="description"
            value={incidentEditFields.description}
            onChange={(event) => {
              handleEditFields("description", event.target.value);
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormLabelStyled>Наименование виджета</FormLabelStyled>
          <InputStyled value={titleWidget} disabled />
        </Grid>
        <Grid item xs={6}>
          <FormLabelStyled>Наименование информационной панели</FormLabelStyled>
          <InputStyled value={infoPanel} disabled />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormLabelStyled>Наименование территории</FormLabelStyled>
          <InputStyled value={territory} disabled />
        </Grid>
        <Grid item xs={6}>
          <FormLabelStyled>Отчётный период</FormLabelStyled>
          <InputStyled value={reportPeriod} disabled />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormLabelStyled>Ссылка на панель</FormLabelStyled>
          <InputStyled value={panelLink} disabled />
        </Grid>
        <Grid item xs={6}>
          <FormLabelStyled>Автор и время создания черновика</FormLabelStyled>
          <InputStyled
            value={`${authorIncident}, ${dateCreateIncident}`}
            disabled
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <ScreenshotStyled>{screenshotTitle}</ScreenshotStyled>
        </Grid>
      </Grid>
    </Box>
  );
};
