import React, { FC } from "react";

import { Table } from "@/shared/Table/ui/Table";
import { useAppSelector } from "@/store/redux/hooks";
import { MODEL_IDS, MODELS } from "@/store/scheme/olap";

import { useAverageRiskAndProjectsColumns } from "../../hooks/useAverageRiskAndProjectsColumns";
import { useRisksSubjectsProjects } from "../../hooks/useRisksSubjectsProjects";
import { AverageAchievementTableProps } from "../../lib/types";

export const AverageRiskAndProjectsTable: FC<AverageAchievementTableProps> = ({
  openColumnsModal,
  closeColumnsModal,
}) => {
  const modelPassport = MODELS[MODEL_IDS.PROJECT_PASSPORT];
  const modelRisk = MODELS[MODEL_IDS.RISKS];
  const modelKpiData = MODELS[MODEL_IDS.KPI_SOC_ECONOMY];
  const { projectPassport, kpiData } = useAppSelector((state) => state.map.geo);
  const { riskData } = useRisksSubjectsProjects();
  const { averageRiskAndProjectsColumns } = useAverageRiskAndProjectsColumns({
    modelPassport,
    projectPassport,
    modelKpiData,
    kpiData,
    modelRisk,
    riskData,
  });

  return (
    <Table
      helperHeder="в том числе количество проектов"
      headerColumns={averageRiskAndProjectsColumns}
      openColumnsModal={openColumnsModal}
      closeColumnsModal={closeColumnsModal}
      withProjects
    />
  );
};
