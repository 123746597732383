import { styled } from "@mui/material";

export const Root = styled("span")<{ show?: boolean; isOpenPopUp?: boolean }>(
  ({ theme, show, isOpenPopUp = false }) => ({
    display: "inline-block",
    width: "fit-content",
    whiteSpace: "nowrap",
    padding: theme.spacing(0, 1),
    height: theme.spacing(2.5),
    fontSize: isOpenPopUp ? "0.875rem" : "0.75rem",
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: theme.spacing(2.5),
    borderRadius: 2,
    backgroundColor: theme.palette.dateBadge.background,
    color: theme.palette.dateBadge.color,
    opacity: show ? 1 : 0,
  })
);
