import React from "react";
import { useParams } from "react-router";

import { useRegion } from "@/pages/RegionPage/hooks/useRegion";
import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import {
  CardListHeaderText,
  CardListInner,
} from "@/shared/CardList/CardList.styled";
import { useFuturePlan } from "@/shared/Table/hooks/useFuturePlan";
import { ValueDate } from "@/shared/ValueDate/ValueDate";

import { useBudget } from "../hooks/useBudget";
import { convertShortSubject } from "../lib/helpers";
import { BudgetHeaderProps } from "../lib/types";
import { BudgetDate, BudgetRegionText } from "./Budget.styled";

export const BudgetHeader = ({ openWidget }: BudgetHeaderProps) => {
  const { data } = useBudget();
  const { compareLabel, isCheckRfFoPage } = useRegions();
  const { isCheckFuturePlan } = useFuturePlan();
  const { id } = useParams<"id">();
  const region = useRegion(id!);

  return (
    <CardListInner
      sx={{
        display: "grid",
        gridTemplateColumns: `${
          isCheckFuturePlan ? (openWidget ? 2.5 : 2) : 2.5
        }fr 1.5fr 1fr`,
      }}
    >
      <CardListHeaderText
        sx={openWidget ? { fontSize: "1.62rem", lineHeight: "1.12rem" } : {}}
      >
        Бюджет
        <BudgetDate>
          <ValueDate isOpenPopUp={!openWidget}>
            {data?.["95"]?.attributeVals?.REPORT_DATE}
          </ValueDate>
        </BudgetDate>
      </CardListHeaderText>

      {!isCheckRfFoPage.isRF && region?.attributeVals?.name && (
        <BudgetRegionText openWidget={openWidget}>
          {isCheckRfFoPage.isFO
            ? convertShortSubject(region.attributeVals.name)
            : region.attributeVals.name}
        </BudgetRegionText>
      )}

      {!isCheckRfFoPage.isRF && (
        <BudgetRegionText openWidget={openWidget}>
          {compareLabel}
        </BudgetRegionText>
      )}
    </CardListInner>
  );
};
