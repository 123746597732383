import { styled } from "@mui/material";

import { ProjectTableCellProps } from "@/pages/ProjectsPage/lib/types";
import {
  CardListTitle,
  CardListTitleText,
} from "@/shared/CardList/CardList.styled";
import { BasicTable } from "@/shared/TableBase/BasicTable";
import { StickyTableCell } from "@/shared/TableBase/BasicTable.styled";

export const ProjectTable = styled(BasicTable)(({ theme }) => ({
  "& .MuiTableCell-root": {
    padding: theme.spacing(1.5),
  },

  "& .MuiTableCell-head": {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    fontSize: 16,
    color: theme.palette.text.tertiary,
  },
}));

export const ProjectTableCell = styled(StickyTableCell, {
  shouldForwardProp: (prop) => prop !== "width",
})<ProjectTableCellProps>(({ width, align }) => ({
  minWidth: width,
  boxSizing: "border-box",
  wordBreak: "break-word",
  hyphens: "auto",
  textAlign: align,
}));

export const ProjectTableDropdown = styled("div")(({ theme }) => ({
  textAlign: "center",
  "& > span": {
    width: "100%",
    justifyContent: "center",
  },
  "& > div": {
    color: theme.palette.text.tertiary,
  },
}));

export const ProjectTitleText = styled(CardListTitleText, {
  shouldForwardProp: (prop) => !["isFlex"].includes(prop.toString()),
})<{ isFlex?: boolean }>(({ theme, isFlex = true }) => ({
  fontSize: theme.spacing(4.25),
  flex: !isFlex ? "none" : 1,
}));

export const ProjectTitle = styled(CardListTitle)(({ theme }) => ({
  height: theme.spacing(10),
  paddingTop: theme.spacing(),
  background:
    theme.palette.mode === "dark"
      ? theme.palette.table.backgroundLight
      : theme.palette.text.onAccent,
}));

export const ProjectStatus = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100$",
  alignItems: "center",
  justifyContent: "center",
}));

export const ProjectInfoText = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  height: "100%",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.text.primary,
  fontSize: theme.spacing(4.25),
  fontWeight: 600,
  lineHeight: theme.spacing(5),
}));
