import React from "react";

import {
  ToggleButtonGroupStyled,
  ToggleButtonStyled,
} from "@/pages/ProjectsPage/ui/tables/ProjectFinancesTable/ProjectFinancesTable.styled";
import { Content } from "@/shared/Achievements/ui/Achievements.styled";

import { RisksGraph } from "./components/RisksGraph";
import { RisksTable } from "./components/RisksTable";
import { IRisksWidgetProps } from "./lib/types";
import { RisksSwitcherWrapper } from "./RisksWidget.styled";

export const RisksWidget = ({
  isOpenPopUp,
  activeSwitcher,
  allSwitcherData,
  callbackActiveSwicher,
}: IRisksWidgetProps) => {
  return (
    <div>
      <RisksSwitcherWrapper>
        <ToggleButtonGroupStyled
          size="small"
          exclusive
          value={activeSwitcher}
          onChange={callbackActiveSwicher}
        >
          {Object.keys(allSwitcherData).map((index: number | string) => (
            <ToggleButtonStyled
              value={allSwitcherData[index as number].id}
              key={allSwitcherData[index as number].id}
            >
              {allSwitcherData[index as number].icon}
            </ToggleButtonStyled>
          ))}
        </ToggleButtonGroupStyled>
      </RisksSwitcherWrapper>
      <Content isOpenPopUp={!!isOpenPopUp} sx={{ padding: 0 }}>
        {activeSwitcher === "2" ? (
          <RisksGraph isOpenPopUp={isOpenPopUp} />
        ) : (
          <RisksTable isOpenPopUp={isOpenPopUp} />
        )}
      </Content>
    </div>
  );
};
