import { useTheme } from "@mui/material";
import React, { FC } from "react";

export const ProjectTableIcon: FC = () => {
  const theme = useTheme();
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2030_45635)">
        <path
          d="M31.5303 3.50729H22.1388L16.2366 0.0644727C16.0903 -0.0208398 15.9096 -0.0208398 15.7633 0.0644727L9.86125 3.50729H0.469687C0.210312 3.50729 0 3.7176 0 3.97697V5.98091C0 6.24029 0.210312 6.4506 0.469687 6.4506H1.50294V26.0201C1.50294 26.2795 1.71325 26.4898 1.97263 26.4898H15.5304V31.0612H13.9961C13.7368 31.0612 13.5264 31.2715 13.5264 31.5309C13.5264 31.7903 13.7368 32.0006 13.9961 32.0006H18.0039C18.2634 32.0006 18.4736 31.7903 18.4736 31.5309C18.4736 31.2715 18.2634 31.0612 18.0039 31.0612H16.4697V26.4898H30.0274C30.2869 26.4898 30.4971 26.2795 30.4971 26.0201V6.45054H31.5304C31.7898 6.45054 32.0001 6.24022 32.0001 5.98085V3.97697C32 3.7176 31.7897 3.50729 31.5303 3.50729ZM16 1.01385L20.2745 3.50729H11.7255L16 1.01385ZM31.0607 5.51122H26.2701C26.0106 5.51122 25.8004 5.72154 25.8004 5.98091C25.8004 6.24029 26.0106 6.4506 26.2701 6.4506H29.5578V25.5504H2.44225V6.45054H24.0991C24.3586 6.45054 24.5688 6.24022 24.5688 5.98085C24.5688 5.72147 24.3586 5.51116 24.0991 5.51116H0.939313V4.44666H31.0606V5.51122H31.0607Z"
          fill={theme.palette.icon.iconProject}
        />
        <path
          d="M31.5001 5.95054H31.0607V5.51122H31.5001V5.95054Z"
          stroke={theme.palette.icon.iconProject}
          strokeOpacity="0.7"
        />
        <path
          d="M9.81084 7.3335C7.34196 7.3335 5.33334 9.34212 5.33334 11.811C5.33334 14.2799 7.34196 16.2885 9.81084 16.2885C12.2797 16.2885 14.2883 14.2799 14.2883 11.811C14.2883 9.34212 12.2797 7.3335 9.81084 7.3335ZM9.81084 15.3491C7.8599 15.3491 6.27265 13.7619 6.27265 11.8109C6.27265 9.85993 7.8599 8.27275 9.81084 8.27275C11.7618 8.27275 13.349 9.86 13.349 11.8109C13.349 13.7619 11.7618 15.3491 9.81084 15.3491Z"
          fill="#92B7C7"
        />
        <path
          d="M24.7407 20.759L26.3821 19.1176C26.8582 18.6415 26.8582 17.8668 26.3821 17.3907C25.9114 16.9199 25.1258 16.9199 24.6551 17.3907L23.0137 19.0321L21.3723 17.3907C20.9017 16.9199 20.116 16.9199 19.6453 17.3907C19.1692 17.8668 19.1692 18.6415 19.6453 19.1176L21.2868 20.759L19.6453 22.4004C19.1692 22.8765 19.1692 23.6512 19.6453 24.1273C20.1159 24.598 20.9017 24.5981 21.3723 24.1273L23.0137 22.4859L24.655 24.1273C25.1257 24.598 25.9115 24.5981 26.3821 24.1273C26.8582 23.6512 26.8582 22.8765 26.3821 22.4004L24.7407 20.759ZM25.73 23.4503C25.624 23.5706 25.4328 23.5767 25.3194 23.4632L23.3458 21.4896C23.1648 21.3087 22.8626 21.3087 22.6816 21.4896L20.708 23.4633C20.5946 23.5767 20.4033 23.5704 20.2973 23.4503C20.2004 23.3403 20.2058 23.1683 20.3095 23.0647L22.2831 21.0911C22.4665 20.9077 22.4665 20.6103 22.2831 20.427L20.3095 18.4534C20.2059 18.3498 20.2004 18.1778 20.2973 18.0678C20.4033 17.9475 20.5946 17.9414 20.708 18.0549L22.6816 20.0285C22.8651 20.2119 23.1624 20.2119 23.3458 20.0285L25.3194 18.0549C25.4329 17.9415 25.6241 17.9476 25.7301 18.0679C25.827 18.1778 25.8215 18.3498 25.7179 18.4534L23.7444 20.427C23.5609 20.6104 23.5609 20.9078 23.7444 21.0911L25.7179 23.0647C25.8216 23.1684 25.827 23.3403 25.73 23.4503Z"
          fill="#92B7C7"
        />
        <path
          d="M26.8139 11.6254L24.3091 9.6215C24.1066 9.45944 23.811 9.49231 23.6489 9.69481C23.4869 9.89731 23.5197 10.1929 23.7222 10.355L25.1816 11.5224H19.0059C17.6419 11.5224 16.5323 12.6321 16.5323 13.996V19.0058C16.5323 19.8517 15.844 20.5401 14.9981 20.5401H5.98044C5.72106 20.5401 5.51075 20.7504 5.51075 21.0097C5.51075 21.2691 5.72106 21.4794 5.98044 21.4794H14.9981C16.362 21.4794 17.4716 20.3697 17.4716 19.0059V13.9961C17.4716 13.1501 18.1599 12.4618 19.0059 12.4618H25.1816L23.7222 13.6293C23.5383 13.7764 23.4923 14.0463 23.6178 14.2457C23.7651 14.4797 24.0928 14.5358 24.3091 14.3628L26.8139 12.3589C27.0452 12.1738 27.0452 11.8106 26.8139 11.6254Z"
          fill={theme.palette.icon.iconProject}
        />
      </g>
      <defs>
        <clipPath id="clip0_2030_45635">
          <rect width="32" height="32" fill={theme.palette.icon.iconProject} />
        </clipPath>
      </defs>
    </svg>
  );
};
