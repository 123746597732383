import { useAppSelector } from "@store/redux/hooks";
import {
  IPreparedIndicator,
  IPreparedIndicatorAttributeVals,
  IRowDatePart,
} from "@store/scheme/olap";
import { useCallback } from "react";

import {
  CHEKPOINTS_GRAPHS,
  DataName,
  GOAL_GRAPHS,
  INDICATORS_GRAPHS,
  TOptions,
  TPeriods,
} from "./types";

export function UseGraphOptions(
  dataName: DataName,
  regionId: string
): TOptions {
  const {
    graphData,
    graphFetching,
    indicatorsGraphData,
    indicatorsGraphFetching,
  } = useAppSelector((state) => state.indicators.total);

  const checkPeriodsByActual = useCallback((periods: string[]) => {
    let quarterPeriods: string[] = [];
    const yearPeriods = periods
      ?.reduce((prev, cur) => {
        const year = +cur?.slice(1, 5);
        if (year >= 2022) {
          if (cur?.match(/^3/gi)?.length) {
            quarterPeriods.push(cur);
          } else {
            prev.push(cur);
          }
        }
        return prev;
      }, [] as string[])
      ?.filter((period) => {
        const year = period?.slice(1, 5) || "";
        return !quarterPeriods?.filter((quarterPeriod) => {
          return quarterPeriod?.match(year)?.join("");
        })?.length;
      });
    return [...quarterPeriods, ...yearPeriods];
  }, []);

  const periodsForDate = useCallback(
    (
      periodsObject: Record<string, IPreparedIndicator>,
      dateCodes: string[]
    ) => {
      return [...dateCodes]?.reduce((prev, cur) => {
        let year = periodsObject?.[cur]?.year?.match(/[0-9]/gi)?.join("") || "";
        const date = periodsObject?.[cur]?.date?.slice(3, 8) || "";

        if (cur?.match(/^3/gi)?.length) {
          year = periodsObject?.[cur]?.date?.slice(9) || "";
          prev.push(`${year}\n${date}`);
        } else {
          prev.push(year);
        }
        return prev;
      }, [] as string[]);
    },
    []
  );

  const periodsByGraphMapId = useCallback(
    (
      regionGData: Record<string, IRowDatePart<IPreparedIndicatorAttributeVals>>
    ) => {
      return Object.keys(regionGData || {})?.reduce((prev, cur: string) => {
        const checkPeriodsActual = checkPeriodsByActual(
          Object.keys(regionGData?.[cur])?.sort()
        );
        prev[cur] = {
          dateCodes: [...checkPeriodsActual],
          periods: [
            ...periodsForDate(
              { ...regionGData?.[cur] } as Record<string, IPreparedIndicator>,
              checkPeriodsActual
            ),
          ],
        };
        return prev;
      }, {} as Record<string, TPeriods>);
    },
    []
  );

  switch (dataName) {
    case "Goals":
      return {
        periodCodes: periodsByGraphMapId(
          graphData?.[regionId]?.[GOAL_GRAPHS.modelId]
        ),
        currentData: graphData,
        currentFetching: graphFetching,
        currentAction: "getGraphData",
        additionalOpt: GOAL_GRAPHS,
      };

    case "Indicators":
      return {
        periodCodes: periodsByGraphMapId(
          indicatorsGraphData?.[regionId]?.[INDICATORS_GRAPHS.modelId]
        ),
        currentData: indicatorsGraphData,
        currentFetching: indicatorsGraphFetching,
        currentAction: "getIndicatorsGraphData",
        additionalOpt: INDICATORS_GRAPHS,
      };

    case "Chekpoints":
      return {
        periodCodes: periodsByGraphMapId(
          indicatorsGraphData?.[regionId]?.[CHEKPOINTS_GRAPHS.modelId]
        ),
        currentData: indicatorsGraphData,
        currentFetching: indicatorsGraphFetching,
        currentAction: "getIndicatorsGraphData",
        additionalOpt: CHEKPOINTS_GRAPHS,
      };
  }
}
