import { styled } from "@mui/material";

import {
  BootstrapInput,
  MenuItemStyled,
} from "@/widgets/AppBar/ui/AppBarPeriodFilter/AppBarPeriodFilter.styled";

export const BootstrapCloneInput = styled(BootstrapInput)(({ theme }) => {
  return {
    "& > .MuiSvgIcon-root": {
      color: theme.palette.text.primary,
    },
    "& .MuiInputBase-input": {
      borderRadius: theme.spacing(),
      position: "relative",
      backgroundColor: theme.palette.modal.background,
      border: `1px solid ${theme.palette.table.divider}`,
      fontSize: 14,
      padding: theme.spacing(),
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      color: theme.palette.text.tertiary,
      fontWeight: theme.typography.fontWeightSemiBold,
    },
  };
});

export const MenuItemCloneStyled = styled(MenuItemStyled)(({ theme }) => ({
  justifyContent: "flex-start",
  border: "none",
  "&.MuiButtonBase-root.MuiMenuItem-root.Mui-selected": {
    background: theme.palette.button.selected,
    borderRadius: theme.spacing(),
    color: theme.palette.text.primary,
    border: "none",
  },
  "&:hover, &.MuiButtonBase-root.MuiMenuItem-root.Mui-selected:hover": {
    background: theme.palette.primary.dark,
    borderRadius: theme.spacing(),
    border: "none",
  },
}));
