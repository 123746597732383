import { useParams } from "react-router";

import { useRegion } from "@/pages/RegionPage/hooks/useRegion";
import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { useAppSelector } from "@/store/redux/hooks";

export const useRisks = () => {
  const { id } = useParams<"id">();
  const region = useRegion(id!);
  const { isCheckRfFoPage } = useRegions();

  const {
    sumTotalRisk: { sumRisksTotal },
    sumRfFoRisk: { sumRfFoRisks },
    risksKpi: { risksKpi },
  } = useAppSelector((state) => state.regions);

  const getRiskTitle = () => {
    const title = isCheckRfFoPage.isRF
      ? "РФ"
      : isCheckRfFoPage.isFO
      ? region?.attributeVals?.["SHORT_NAME"]
      : "субъекту";
    return title;
  };

  return { sumRfFoRisks, sumRisksTotal, risksKpi, getRiskTitle };
};
