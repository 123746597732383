import { Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import { FC, useRef } from "react";

import { useProjectPage } from "@/pages/ProjectsPage/hooks/useProjectPage";
import { Icon } from "@/shared/CustomIcon/Icon.styled";
import { CustomScrollbar } from "@/shared/CustomScrollbar/ui/CustomScrollbar";
import { useOpenIncident } from "@/shared/Incident/hooks/useOpenIncident";
import { IncidentCreateButton } from "@/shared/Incident/ui/IncidentCreateButton/IncidentCreateButton";
import { useCheckpointsFilter } from "@/shared/InputSelect/hooks/useCheckpointsFilter";

import {
  ProjectCard,
  ProjectCardContent,
  ProjectCardTitle,
} from "../../ProjectCards.styled";
import { ProjectGoalsText } from "./ProjectGoals.styled";
import { GoalsIcon } from "./ProjectGoalsIcon";

export const ProjectGoals: FC = () => {
  const theme = useTheme();
  const { info } = useProjectPage();
  const { actualPeriodData } = useCheckpointsFilter();
  const reportDate = actualPeriodData?.attributeVals.REPORT_DATE || "";
  const showRef = useRef<HTMLDivElement | null>(null);
  const { move, handleMove, handleChange } = useOpenIncident();

  return (
    <ProjectCard
      variant="outlined"
      ref={showRef}
      onMouseEnter={() => handleMove(true)}
      onMouseLeave={() => handleMove(false)}
    >
      <ProjectCardContent>
        <ProjectCardTitle>
          <Icon
            size="big"
            sx={{ background: theme.palette.table.backgroundImg }}
          >
            <GoalsIcon />
          </Icon>

          <Typography variant="subtitle1">Цели проекта</Typography>
          {move && (
            <IncidentCreateButton
              bigSize
              posRight={24}
              onClick={(event) => {
                handleChange(
                  event,
                  showRef?.current,
                  "Цели проекта",
                  reportDate
                );
              }}
            />
          )}
        </ProjectCardTitle>
        <CustomScrollbar>
          <ProjectGoalsText>
            {info?.general?.attributeVals?.project_target || "-"}
          </ProjectGoalsText>
        </CustomScrollbar>
      </ProjectCardContent>
    </ProjectCard>
  );
};
