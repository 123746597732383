import { useAppSelector } from "@store/redux/hooks";
import { FC, useMemo } from "react";

import { dynamicTableTitle } from "@/pages/InsideMainPage/lib/constants";
import { IndicatorsDynamicTableProps } from "@/pages/MainPage/lib/types";
import { ACHIEVEMENT_STATUS } from "@/shared/Table/lib/types";
import { HeaderColumns } from "@/shared/Table/lib/types";
import { Table } from "@/shared/Table/ui/Table";
import { MODEL_IDS, MODELS } from "@/store/scheme/olap";

export const IndicatorsDynamicTable: FC<IndicatorsDynamicTableProps> = ({
  openColumnsModal,
  closeColumnsModal,
}) => {
  const model = MODELS[MODEL_IDS.KPI_SOC_ECONOMY];
  const { kpiData } = useAppSelector((state) => state.map.geo);

  const headerColumns = useMemo(
    () => [
      {
        id: "1",
        sortId: 1,
        indicator: model.indexes.fact,
        popUpLabel: "Выпуск товаров и услуг,%",
        label: "Выпуск товаров и услуг,%",
        sortable: true,
        isComparable: true,
        model: model.modelId,
        data: kpiData,
        sortBy: "ManufacturingDimamics",
        indicatorColor: "",
      },
      {
        id: "132",
        sortId: 2,
        indicator: model.indexes.fact,
        popUpLabel: "Доходы населения,%",
        label: "Доходы населения,%",
        sortable: true,
        isComparable: true,
        model: model.modelId,
        achievementStatus: ACHIEVEMENT_STATUS.ENABLED,
        data: kpiData,
        sortBy: "ProfitDimamics",
        indicatorColor: "",
      },
      {
        id: "133",
        sortId: 3,
        indicator: model.indexes.fact,
        popUpLabel: "Частные инвестиции,%",
        label: "Частные инвестиции,%",
        sortable: true,
        isComparable: true,
        model: model.modelId,
        achievementStatus: ACHIEVEMENT_STATUS.ENABLED,
        data: kpiData,
        sortBy: "InvestmentDimamics",
        indicatorColor: "",
      },
      {
        id: "4",
        sortId: 4,
        indicator: model.indexes.fact,
        popUpLabel: "Число рабочих мест,%",
        label: "Число рабочих мест,%",
        sortable: true,
        isComparable: true,
        model: model.modelId,
        achievementStatus: ACHIEVEMENT_STATUS.ENABLED,
        data: kpiData,
        sortBy: "EmployedDimamics",
        indicatorColor: "",
      },
      {
        id: "104",
        sortId: 5,
        subId: "121",
        indicator: model.indexes.fact,
        subIndicator: model.indexes.fact,
        label: "Проекты / с отставанием от плана, шт",
        popUpLabel: "Проекты / с отставанием от плана, шт",
        sortable: true,
        model: model.modelId,
        data: kpiData,
        sortBy: "ProjectsDimamics",
        indicatorColor: model.indexes.color,
      },
    ],
    [kpiData, model, model.indexes.fact, model.modelId]
  ) as HeaderColumns[];

  return (
    <Table
      title={dynamicTableTitle}
      headerColumns={headerColumns}
      borderForDynamics={true}
      openColumnsModal={openColumnsModal}
      closeColumnsModal={closeColumnsModal}
    />
  );
};
