import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Typography } from "@mui/material";
import { FC, useCallback, useRef, useState } from "react";

import { useAppSelector } from "@/store/redux/hooks";

import { IAppBarFilterItem } from "../../lib/types";
import {
  MenuItemPopper,
  MenuItemStyled,
  MenuItemWrapper,
} from "./AppBarFilter.styled";

export const AppBarFilterItem: FC<IAppBarFilterItem> = ({
  item,
  handleChangeRegion,
  handleChangeFO,
  curValue,
}) => {
  const { user } = useAppSelector((state) => state.auth.user);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const wrapperRef = useRef(null);

  const handlePopoverOpen = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const isSelectedMenuItemWrapper = Boolean(
    curValue === item?.name ||
      item?.children.find(({ name }) => curValue === name)
  );

  return (
    <div onMouseOver={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
      <MenuItemWrapper
        selected={isSelectedMenuItemWrapper}
        ref={wrapperRef}
        onClick={() => !user?.isAnalyticFK && handleChangeFO?.(item)}
      >
        <Typography>{item?.name}</Typography>
        {item?.children?.length > 0 && <NavigateNextIcon />}

        {item?.children?.length > 0 && (
          <MenuItemPopper
            onMouseLeave={handlePopoverClose}
            onClick={handlePopoverClose}
            placement="left-end"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            modifiers={[
              {
                name: "preventOverflow",
                enabled: true,
                options: {
                  altAxis: false,
                  altBoundary: false,
                  tether: false,
                  rootBoundary: "document",
                  padding: 8,
                },
              },
            ]}
          >
            {item.children.map((child) => (
              <MenuItemStyled
                onClick={(event) => {
                  handleChangeRegion(child.id);
                  event.stopPropagation();
                }}
                key={child.id}
                value={child.name}
                selected={curValue === child.name}
              >
                {child.name}
              </MenuItemStyled>
            ))}
          </MenuItemPopper>
        )}
      </MenuItemWrapper>
    </div>
  );
};
