import { useAppSelector } from "@store/redux/hooks";
import { MODEL_IDS, MODELS } from "@store/scheme/olap";
import { FC } from "react";

import { useTabs } from "@/app/hooks/useTabs";
import { Table } from "@/shared/Table/ui/Table";

import { useKeysIndicatorsAndProjectsColumns } from "../../hooks/useKeysIndicatorsAndProjectsColumns";
import { AverageKeysIndicatorsAndProjectsTableProps } from "../../lib/types";

export const AverageKeysIndicatorsAndProjectsTable: FC<
  AverageKeysIndicatorsAndProjectsTableProps
> = ({ openColumnsModal, closeColumnsModal }) => {
  const modelKPI = MODELS[MODEL_IDS.KPI_SOC_ECONOMY];
  const modelPassport = MODELS[MODEL_IDS.PROJECT_PASSPORT];
  const { factChangeAppg, factChangeYearStart } = modelKPI.indexes;
  const { kpiData, projectPassport } = useAppSelector((state) => state.map.geo);
  const { checkPeriodByHideDate } = useTabs();
  const { keysIndicatorsAndProjectsColumns } =
    useKeysIndicatorsAndProjectsColumns({
      modelPassport,
      projectPassport,
      factChangeAppg,
      factChangeYearStart,
      checkPeriodByHideDate,
      kpiData,
      modelKPI,
    });

  return (
    <Table
      isSort
      helperHeder="в том числе"
      headerColumns={keysIndicatorsAndProjectsColumns}
      openColumnsModal={openColumnsModal}
      closeColumnsModal={closeColumnsModal}
    />
  );
};
