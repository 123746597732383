import { useTheme } from "@mui/material";

export const InitiatorIcon = () => {
  const theme = useTheme();
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.2956 14.6365C20.4453 14.6365 20.5884 14.698 20.6914 14.8067L22.1231 16.3173C22.3225 16.5278 22.3225 16.8574 22.1231 17.0678L17.3504 22.1026C17.2474 22.2113 17.1042 22.2728 16.9545 22.2728C16.8048 22.2728 16.6616 22.2113 16.5586 22.1026L13.695 19.0817C13.4955 18.8713 13.4955 18.5416 13.695 18.3312L15.1268 16.8207C15.2298 16.712 15.3729 16.6505 15.5227 16.6505C15.6724 16.6505 15.8155 16.712 15.9185 16.8207L16.9545 17.9136L19.8997 14.8067C20.0027 14.698 20.1458 14.6365 20.2956 14.6365ZM20.2955 15.9748L17.3504 19.0817C17.2474 19.1903 17.1042 19.2519 16.9545 19.2519C16.8048 19.2519 16.6616 19.1903 16.5586 19.0817L15.5227 17.9888L14.8424 18.7064L16.9545 20.9345L20.9757 16.6925L20.2955 15.9748Z"
        fill={theme.palette.icon.iconProject}
        stroke={theme.palette.icon.iconProject}
        strokeOpacity="0.5"
        strokeWidth="0.3"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9092 11.909C14.5954 11.909 11.9093 14.5952 11.9093 17.909C11.9093 21.2228 14.5955 23.909 17.9092 23.909C21.2231 23.909 23.9093 21.2229 23.9093 17.909C23.9093 14.5952 21.2231 11.909 17.9092 11.909ZM10.8184 17.909C10.8184 13.9927 13.9929 10.8181 17.9092 10.8181C21.8256 10.8181 25.0002 13.9927 25.0002 17.909C25.0002 21.8254 21.8256 24.9999 17.9092 24.9999C13.9929 24.9999 10.8184 21.8253 10.8184 17.909Z"
        fill={theme.palette.icon.iconProject}
        stroke={theme.palette.icon.iconProject}
        strokeOpacity="0.5"
        strokeWidth="0.3"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.42399 2.19472C6.16195 1.43022 7.19336 1 8.3638 1C9.53429 1 10.5657 1.4302 11.3037 2.1947C12.0406 2.95811 12.4548 4.02307 12.4548 5.24173V6.57645C12.4548 7.79517 12.0407 8.86013 11.3037 9.62354C10.5657 10.388 9.5343 10.8182 8.3638 10.8182C7.19336 10.8182 6.16194 10.388 5.42398 9.62355C4.68705 8.86015 4.27295 7.79519 4.27295 6.57645V5.24173C4.27295 4.02309 4.68708 2.95814 5.42399 2.19472ZM6.20888 2.95236C5.68954 3.49038 5.36386 4.27357 5.36386 5.24173V6.57645C5.36386 7.54474 5.68954 8.32792 6.20886 8.8659C6.72714 9.4028 7.46842 9.72727 8.3638 9.72727C9.25925 9.72727 10.0006 9.40278 10.5188 8.86588C11.0382 8.32789 11.3639 7.54472 11.3639 6.57645V5.24173C11.3639 4.27354 11.0382 3.49036 10.5188 2.95235C10.0005 2.41542 9.25921 2.09091 8.3638 2.09091C7.46847 2.09091 6.72718 2.41542 6.20888 2.95236Z"
        fill={theme.palette.icon.iconProject}
        stroke={theme.palette.icon.iconProject}
        strokeOpacity="0.5"
        strokeWidth="0.3"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.87589 10.823C5.39308 10.0759 7.2151 9.72729 8.50036 9.72729C9.81915 9.72729 11.7066 10.0944 13.2489 10.8854C13.517 11.0229 13.6228 11.3516 13.4853 11.6197C13.3479 11.8877 13.0191 11.9936 12.7511 11.8561C11.3772 11.1514 9.6629 10.8182 8.50036 10.8182C7.36948 10.8182 5.71405 11.1338 4.35782 11.8017C2.9824 12.479 2.09091 13.4206 2.09091 14.6043V18.4546C2.09091 18.7558 1.8467 19 1.54545 19C1.24421 19 1 18.7558 1 18.4546V14.6043C1 12.7848 2.37789 11.5606 3.87589 10.823Z"
        fill={theme.palette.icon.iconProject}
        stroke={theme.palette.icon.iconProject}
        strokeOpacity="0.5"
        strokeWidth="0.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
