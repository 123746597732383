import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  FormControl,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material";
import React, { ReactElement, ReactNode, useEffect, useState } from "react";

import {
  ListTextStyled,
  WrapperStack,
} from "@/widgets/AppBar/ui/AppBarPeriodFilter/AppBarPeriodFilter.styled";

import { ProjectsFilterProps, SelectValues } from "./lib/types";
import {
  BootstrapCloneInput,
  MenuItemCloneStyled,
} from "./ProjectsFilter.styled";

export const ProjectsFilter = ({
  selectValues,
  selectedPeriod,
  handleClick,
}: ProjectsFilterProps) => {
  const theme = useTheme();
  const [selectValue, setSelectValue] = useState<string>("");
  const [isOpen, setOpen] = useState<boolean>(false);

  const changePutSelectedValue = (lotOfValues?: SelectValues) => {
    setSelectValue(lotOfValues?.name || "");
    handleClick(lotOfValues?.id || "");
  };

  useEffect(() => {
    if (selectValues?.length && !selectedPeriod) {
      changePutSelectedValue(selectValues?.[0]);
    }

    return () => changePutSelectedValue(undefined);
  }, [selectValues, selectedPeriod]);

  useEffect(() => {
    if (selectedPeriod) {
      const value = selectValues?.find((item) => item?.id === selectedPeriod);
      changePutSelectedValue(value);
    }
  }, [selectedPeriod]);

  const handleChange = (event: SelectChangeEvent, child: ReactNode) => {
    const value = selectValues.find(
      (item) => item?.id === (child as ReactElement)?.props?.id
    );

    if (value) {
      changePutSelectedValue(value);
    }
  };

  return (
    <WrapperStack direction="row">
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        flex={1}
        sx={{ overflow: "hidden" }}
      >
        <FormControl fullWidth>
          <Select
            open={isOpen}
            IconComponent={KeyboardArrowDownIcon}
            onOpen={() => setOpen(!isOpen)}
            onClose={() => setOpen(!isOpen)}
            value={selectValue}
            onChange={handleChange}
            input={<BootstrapCloneInput />}
            renderValue={() => (
              <ListTextStyled secondary={`на ${selectValue}`} />
            )}
            MenuProps={{
              style: {
                maxWidth: 224,
              },
              PaperProps: {
                style: {
                  background: theme.palette.input.background,
                  minWidth: 224,
                },
              },
            }}
          >
            {selectValues?.map((item) => (
              <MenuItemCloneStyled
                key={item?.id}
                id={item?.id}
                value={item?.name}
              >
                <Typography noWrap>{item?.name}</Typography>
              </MenuItemCloneStyled>
            ))}
          </Select>
        </FormControl>
      </Stack>
    </WrapperStack>
  );
};
