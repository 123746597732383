import { FC } from "react";

import { IPopoverProps } from "../lib/types";
import { PopoverStyled } from "./Popover.styled";

export const Popover: FC<IPopoverProps> = ({
  children,
  open,
  anchorEl,
  onClose,
}) => {
  return (
    <PopoverStyled
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={open}
    >
      {children}
    </PopoverStyled>
  );
};
