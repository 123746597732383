import { RegionMutableEmployed } from "@/features/RegionMutableEmployed/RegionMutableEmployed";
import { RegionMutableInvestment } from "@/features/RegionMutableInvestment/RegionMutableInvestment";
import { RegionMutableManufacturing } from "@/features/RegionMutableManufacturing/RegionMutableManufacturing";
import { RegionMutableProfit } from "@/features/RegionMutableProfit/RegionMutableProfit";
import { RegionMutableRate } from "@/features/RegionMutableRate/RegionMutableRate";
import { TAB_ID } from "@/store/scheme/region";

export const REGION_CONTAINERS = {
  [TAB_ID.VDL_PROJECTS]: RegionMutableRate,
  [TAB_ID.VDL]: RegionMutableRate,
  [TAB_ID.PROJECTS]: RegionMutableRate,
  [TAB_ID.RISKS]: RegionMutableRate,
  [TAB_ID.RATE]: RegionMutableRate,
  [TAB_ID.MANUFACTURING]: RegionMutableManufacturing,
  [TAB_ID.PROFIT]: RegionMutableProfit,
  [TAB_ID.INVESTMENT]: RegionMutableInvestment,
  [TAB_ID.EMPLOYED]: RegionMutableEmployed,
  [TAB_ID.RESULT_PROJECTS]: RegionMutableRate,
  [TAB_ID.RESULT_CHECKPOINTS]: RegionMutableRate,
};
