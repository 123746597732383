import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { prepareAttributes } from "@/shared/lib/heplers";
import { ArgumentsWithDistrictIdType } from "@/shared/lib/types";
import {
  IPreparedIndicatorAttributeVals,
  MODEL_IDS,
  MODELS,
} from "@/store/scheme/olap";

import { IGeneralIndicatorsSlice } from "../../lib/types";
import {
  getGeneralIndicators,
  getGeneralIndicatorsInvestment,
} from "../api/general_indicators_api";

export const initialState: IGeneralIndicatorsSlice = {
  data: undefined,
  investmentData: { data: undefined, arr: [] },
  genIndicatorPassport: {},
  investmentRating: {},
  fetching: false,
  investmentFetching: false,
  error: null,
};

export const extraActionsGeneralIndicators = {
  investment: createAsyncThunk(
    "regions/generalIndicators/investment",
    async (args: ArgumentsWithDistrictIdType) =>
      await getGeneralIndicatorsInvestment(args)
  ),
  get: createAsyncThunk(
    "regions/generalIndicators/get",
    async (args: ArgumentsWithDistrictIdType) =>
      await getGeneralIndicators(args)
  ),
};

const generalIndicatorsSlice = createSlice({
  name: "generalIndicators",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActionsGeneralIndicators.get.pending, (state) => {
        state.data = undefined;
        state.fetching = true;
      })
      .addCase(extraActionsGeneralIndicators.get.fulfilled, (state, action) => {
        const model = MODELS[MODEL_IDS.KPI_SOC_ECONOMY];

        state.data = action.payload.reduce((prev, cur) => {
          const indicatorId = cur?.dims?.[model.dimId]?.code;

          if (indicatorId) {
            prev[indicatorId] = {
              ...prepareAttributes<IPreparedIndicatorAttributeVals>(
                cur.dims[model.dimId].attributeVals
              ),
              attributeVals: cur?.attributeVals || {},
              indicatorVals: Object.fromEntries(
                Object.entries(cur?.indicatorVals || {}).map(([key, value]) => [
                  key,
                  value?.sum,
                ])
              ) as any,
            };
          }

          return prev;
        }, {} as any);

        state.genIndicatorPassport = action.payload.reduce((prev, cur) => {
          const indicatorId = cur?.dims?.[model.dimId]?.code;

          if (indicatorId) {
            prev[indicatorId] = {
              attributeVals: cur.dims[model.dimId].attributeVals || {},
            };
          }

          return prev;
        }, {} as any);
        state.fetching = false;
        state.error = null;
      })
      .addCase(extraActionsGeneralIndicators.get.rejected, (state, action) => {
        state.fetching = false;
        state.error = action.error;
      })
      .addCase(extraActionsGeneralIndicators.investment.pending, (state) => {
        state.investmentData.data = undefined;
        state.investmentData.arr = [];
        state.investmentFetching = true;
      })
      .addCase(
        extraActionsGeneralIndicators.investment.fulfilled,
        (state, action) => {
          const model = MODELS[MODEL_IDS.KPI_SOC_ECONOMY];

          state.investmentData.data = action.payload.reduce((prev, cur) => {
            const indicatorId = cur?.dims?.[model.dimId]?.code;

            if (indicatorId) {
              prev[indicatorId] = {
                ...prepareAttributes<IPreparedIndicatorAttributeVals>(
                  cur.dims[model.dimId].attributeVals
                ),
                attributeVals: cur?.attributeVals || {},
                indicatorVals: Object.fromEntries(
                  Object.entries(cur?.indicatorVals || {}).map(
                    ([key, value]) => [key, value?.sum]
                  )
                ) as any,
              };
            }

            return prev;
          }, {} as any);

          const arr = action.payload.map((item) => {
            return {
              name: item.dims[479].attributeVals,
              place: item.indicatorVals[494].sum,
              parent: item.dims[479].parentId[0],
            };
          });

          state.investmentData.arr = arr.sort((a, b) => a.place - b.place);

          state.investmentRating = action.payload.reduce((prev, cur) => {
            const indicatorId = cur?.dims?.[model.dimId]?.code;

            if (indicatorId) {
              prev[indicatorId] = {
                attributeVals: cur.dims[model.dimId].attributeVals || {},
              };
            }

            return prev;
          }, {} as any);

          state.fetching = false;
          state.error = null;
        }
      )
      .addCase(
        extraActionsGeneralIndicators.investment.rejected,
        (state, action) => {
          state.investmentFetching = false;
          state.error = action.error;
        }
      );
  },
});

export const reducer = generalIndicatorsSlice.reducer;

export default generalIndicatorsSlice;
