import { Card, styled, Theme, Typography } from "@mui/material";
import { TypographyProps } from "@mui/material/Typography/Typography";
import { FC } from "react";

export const CardList = styled(Card)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
  minHeight: "100%",
  boxSizing: "border-box",
  boxShadow: "none",
});

export const CardListHeader = styled("div")(({ theme }) => ({
  boxSizing: "border-box",
  flex: `0 0 ${theme.spacing(8)}`,
  maxHeight: theme.spacing(8),
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  borderBottom: `3px solid ${theme.palette.table.divider}`,
  top: 0,
  background: theme.palette.appBar.backgroundColor,
  zIndex: 1,
}));

export const CardListInner = styled("div")<{ padding?: boolean }>(
  ({ theme, padding }) => ({
    flex: "1",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
    padding: padding ? theme.spacing(0, 2, 0, 3) : 0,
  })
);

export const CardListIcon = styled("div")(({ theme }) => ({
  marginRight: theme.spacing(4),
}));

export const CardListHeaderTextTypography: FC<TypographyProps> = (props) => (
  <Typography variant="subtitle1" {...props} />
);

export const CardListHeaderText = styled(CardListHeaderTextTypography)(
  ({ theme }) => ({
    fontSize: theme.spacing(2),
    flex: "1 1 auto",
  })
);

const getCardListResultCellColor = (color: string, theme: Theme) => {
  switch (color) {
    case "secondary":
      return theme.palette.text.tertiary;
    default:
      return theme.palette.text.primary;
  }
};

export const CardListResultCell = styled("div")<{
  color?: "primary" | "secondary";
  justifyContentEnd?: boolean;
}>(({ theme, color, justifyContentEnd }) => ({
  boxSizing: "border-box",
  flex: `0 0 ${100 + parseInt(theme.spacing(3))}px`,
  maxWidth: 100 + parseInt(theme.spacing(3)),
  alignSelf: "stretch",
  display: "flex",
  alignItems: "center",
  paddingLeft: theme.spacing(2),
  ...(color && { color: getCardListResultCellColor(color, theme) }),
  ...(justifyContentEnd && { justifyContent: "flex-end" }),
}));

export const CardListContainer = styled("div")(({ theme }) => ({
  overflowY: "auto",
  flex: "1",
}));

export const CardListTitle = styled("div", {
  shouldForwardProp: (prop) => !["openWidget"].includes(prop.toString()),
})<{ openWidget?: boolean }>(({ theme, openWidget }) => ({
  display: "flex",
  flex: "0 0 auto",
  alignItems: "center",
  height: theme.spacing(openWidget ? 10.75 : 8),
  padding: theme.spacing(0, 3, 0, 3),
  borderBottom: openWidget
    ? `6px solid #E1E6ED20`
    : `3px solid ${
        theme.palette.mode === "dark"
          ? theme.palette.table.divider
          : theme.palette.diagram.baseDiagram
      }`,
  boxSizing: "border-box",
  gap: theme.spacing(3),
  backgroundColor:
    theme.palette.mode === "dark"
      ? openWidget
        ? theme.palette.popup.header
        : theme.palette.table.backgroundLight
      : theme.palette.button.dividerBackground,
  maxWidth: "100%",
}));

export const CardListTitleIcon = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "0 0",
  flexBasis: theme.spacing(4),
  alignItems: "center",
  justifyContent: "center",
  marginRight: theme.spacing(2.5),
}));

const CardListTitleTextTypography = styled(Typography)({
  flex: "1 1 auto",
});

export const CardListTitleText: FC<TypographyProps> = (props) => (
  <CardListTitleTextTypography variant="subtitle1" {...props} />
);

export const CardListTitleDate = styled("div")(({ theme }) => ({
  flex: "0 0 auto",
  alignSelf: "flex-end",
  marginLeft: theme.spacing(2.5),
  paddingBottom: theme.spacing(),
}));

export const CardListContent = styled("div")<{ withCustomScrollbar?: boolean }>(
  ({ theme, withCustomScrollbar }) => ({
    height: "100%",
    flex: "1 1 100%",
    paddingRight: withCustomScrollbar ? theme.spacing(4) : 0,
  })
);

export const CardListRow = styled("div", {
  shouldForwardProp: (prop) => !["isOpenPopUp"].includes(prop.toString()),
})<{ isOpenPopUp?: boolean }>(({ theme, isOpenPopUp }) => ({
  ...(isOpenPopUp && { background: "#1E2731" }),
  display: "flex",
  alignItems: "center",
  padding: isOpenPopUp ? theme.spacing(1.25, 5) : theme.spacing(2, 3),
}));
