import HomeIcon from "@mui/icons-material/Home";
import { List, ListItemText } from "@mui/material";
import { useTheme } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@store/redux/hooks";
import { FC, useCallback } from "react";
import { useLocation, useNavigate } from "react-router";

import { useRouter } from "@/app/hooks/useRouter";
import { FeedbackCommonInfo } from "@/shared/Feedback/ui/FeedbackCommonInfo/FeedbackCommonInfo";
import { getUrlByTheme } from "@/shared/lib/heplers";

import { useMainMenu } from "../hooks/useMainMenu";
import { IMainMenu, IMenuItem } from "../lib/types";
import { menuSetOpen } from "../model/menuSlice";
import {
  Drawer,
  IconContainer,
  ListItemButtonStyled,
  ListItemIconStyled,
  ListItemStyled,
  MenuBottom,
  Version,
} from "./MainMenu.styled";

export const MainMenu: FC<IMainMenu> = ({ isLightTheme, setIsLightTheme }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { getPath } = useRouter();
  const {
    menu,
    mode: { isDark },
  } = useAppSelector((state) => state.ui);
  const { MENU } = useMainMenu();
  const theme = useTheme();

  const toggleOpen = useCallback(() => {
    dispatch(menuSetOpen(!menu.open));
  }, [dispatch, menu.open]);

  const handleClick = useCallback(
    (item: IMenuItem) => () => {
      if (!item.disabled) {
        item.path.includes("https://")
          ? window.open(item.path, "_blank")
          : navigate(getPath(item.path));
        dispatch(menuSetOpen(false));
      }
    },
    [getPath, navigate]
  );

  const goToMainPage = useCallback(() => {
    navigate(getPath(getUrlByTheme(`${isDark ? "" : "/achievementLevel"}`)), {
      state: { initialValue: "" },
    });
    dispatch(menuSetOpen(false));
  }, [getPath, isDark, navigate]);

  return (
    <Drawer
      open={menu.open}
      onClose={toggleOpen}
      anchor="left"
      variant="temporary"
    >
      <List style={{ padding: "2rem 0", flex: "0 0 78%" }}>
        <ListItemStyled selected={location.pathname === "/"}>
          <ListItemButtonStyled onClick={goToMainPage}>
            <IconContainer>
              <ListItemIconStyled>
                <HomeIcon
                  sx={{
                    color: menu.open
                      ? theme.palette.text.primary
                      : theme.palette.text.grey,
                  }}
                />
              </ListItemIconStyled>
            </IconContainer>
            {menu.open && (
              <ListItemText
                sx={{ paddingLeft: "1rem" }}
                primary="Главная"
                disableTypography
              />
            )}
          </ListItemButtonStyled>
        </ListItemStyled>

        {MENU.map((item) => (
          <ListItemStyled
            key={item.path}
            selected={location.pathname === item.path}
            disabled={item.disabled}
          >
            <ListItemButtonStyled onClick={handleClick(item)}>
              <IconContainer>
                <ListItemIconStyled>
                  <item.icon
                    sx={{
                      color: theme.palette.text.primary,
                    }}
                  />
                </ListItemIconStyled>
              </IconContainer>
              <ListItemText
                primary={item.text}
                sx={{ paddingLeft: "1rem" }}
                disableTypography
              />
            </ListItemButtonStyled>
          </ListItemStyled>
        ))}
      </List>
      <MenuBottom
        direction="column"
        alignItems="flex-end"
        justifyContent="flex-end"
        gap="1.5rem"
      >
        {/* <ThemeSwitch handleTheme={() => setIsLightTheme(!isLightTheme)} /> */}
        <FeedbackCommonInfo />
        <Version>v. {process.env.REACT_APP_VERSION}</Version>
      </MenuBottom>
    </Drawer>
  );
};
