import {
  Drawer as MuiDrawer,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Stack,
  styled,
  Switch,
  Theme,
} from "@mui/material";

export const drawerWidth = 377;
export const drawerThinWidth = 64;

const openedMixin = (theme: Theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  background: theme.palette.mainMenu.backgroundColor,
  borderRight: "none",
  color: theme.palette.text.primary,
});

const closedMixin = (theme: Theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: drawerThinWidth,
  background: theme.palette.mainMenu.backgroundColor,
  borderRight: "none",
  color: theme.palette.text.primary,
});

export const RootContainer = styled("div")({
  flex: "0 0 auto",
});

export const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
  display: "flex",
  position: "relative",
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  "& > .MuiPaper-root": {
    marginTop: "5.5rem",
  },
  "& > .MuiBackdrop-root": {
    opacity: "0 !important",
  },
  ...((open
    ? {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
      }
    : {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
      }) as {}),
}));

export interface IDrawerHeaderProps {
  isInner?: boolean;
}

export const DrawerHeader = styled("div")<IDrawerHeaderProps>(
  ({ theme, isInner }) => ({
    width: "100%",
    height: theme.spacing(9),
    borderBottom:
      theme.palette.mode === "dark" && isInner
        ? undefined
        : `2px solid ${theme.palette.appBar.borderColor}`,
    boxSizing: "border-box",
  })
);

export const DrawerToggleButton = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  width: drawerThinWidth,
  height: "100%",
  marginLeft: theme.spacing(2),
}));

export const ToggleButtonContainer = styled("div")({
  position: "fixed",
});

export const MenuButton = styled(IconButton)(({ theme }) => ({
  position: "relative",
  zIndex: 1500,
  color: theme.palette.text.tertiary,
}));

export interface IListItemStyledProps {
  disabled?: boolean;
}

export const ListItemStyled = styled(ListItem)<IListItemStyledProps>(
  ({ theme, disabled }) => ({
    width: "auto",
    height: theme.spacing(8),
    padding: 0,
    margin: theme.spacing(0, 5),
    border: "1px solid transparent",
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.primary,
    transition: theme.transitions.create("background-color", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    "&.Mui-selected": {
      backgroundColor: theme.palette.mainMenu.selectedItem,
      borderColor: theme.palette.border.selected,
      borderRadius: theme.shape.borderRadius,
      color: theme.palette.geo.select?.border,
      "& > div > div > .MuiListItemIcon-root > svg": {
        color: theme.palette.geo.select?.border,
      },
    },
    "&:hover": {
      ...(!disabled && {
        backgroundColor: theme.palette.mainMenu.hoverItem,
        borderColor: theme.palette.mainMenu.hoverItem,
        borderRadius: theme.shape.borderRadius,
        color: theme.palette.text.primary,
        "& > div > div > .MuiListItemIcon-root > svg": {
          color: theme.palette.text.primary,
        },
      }),
    },
    "& > .MuiListItemButton-root:hover": {
      backgroundColor: "transparent",
    },
    "@media (max-height: 38.75rem)": {
      height: 48,
    },
  })
);

export const ListItemButtonStyled = styled(ListItemButton)({
  padding: 20,
  height: "100%",
  "@media (max-height: 38.75rem)": {
    padding: 0,
  },
});

export const IconContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
}));

export const ListItemIconStyled = styled(ListItemIcon)(({ theme }) => ({
  minWidth: "auto",
  color: theme.palette.primary.contrastText,
}));

export const Version = styled("div")(({ theme }) => ({
  fontWeight: 600,
  fontSize: "1.25rem",
  lineHeight: "1.5rem",
  color:
    theme.palette.mode === "dark"
      ? theme.palette.chart.background
      : theme.palette.text.grey,
}));

export const MenuBottom = styled(Stack)(({ theme }) => ({
  position: "absolute",
  bottom: 108,
  width: "100%",
  padding: theme.spacing(0, 4),
  boxSizing: "border-box",
  "& > .feedbackWrapper": {
    width: "100%",
  },
}));

export const SwitchThemeButton = styled(Switch)(({ theme }) => ({
  borderRadius: theme.spacing(2.5),
  padding: 0,
  alignItems: "center",
  border: `1px solid ${theme.palette.table.borderButton}`,
  boxSizing: "border-box",
  ".MuiSwitch-switchBase": {
    position: "relative",
    padding: theme.spacing(0.3),
  },
  ".MuiSwitch-track": {
    background: "none !important",
  },
  ".MuiSvgIcon-root": {
    background: "rgba(73, 180, 239, 0.3)",
    border: `1px solid ${theme.palette.chart.fill.primary}`,
    borderRadius: "50%",
    padding: theme.spacing(0.5),
    boxSizing: "border-box",
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: theme.palette.text.primary,
  },
}));
