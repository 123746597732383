import React, { useState } from "react";

import { ReactComponent as StarColor } from "@/assets/StarColor.svg";
import { ReactComponent as StarEmpty } from "@/assets/StarEmpty.svg";

import { IFeedbackContentProps } from "../lib/types";
import {
  FeedbackContentHeader,
  FeedbackContentRating,
  FeedbackContentStyled,
  FeedbackRatingText,
  RatingStyled,
} from "./Feedback.styled";

export const FeedbackContent = ({ handleRating }: IFeedbackContentProps) => {
  const [rating, setRating] = useState<number | null>(null);

  return (
    <FeedbackContentStyled>
      <FeedbackContentHeader>
        Оцените ваше взаимодействие с модулем
      </FeedbackContentHeader>
      <FeedbackContentRating>
        <FeedbackRatingText>Совсем не доволен</FeedbackRatingText>
        <RatingStyled
          value={rating}
          onChange={(event, newValue) => {
            setRating(newValue);
            handleRating(newValue);
          }}
          size="large"
          max={5}
          icon={<StarColor fontSize="inherit" />}
          emptyIcon={<StarEmpty fontSize="inherit" />}
        />
        <FeedbackRatingText>Очень доволен</FeedbackRatingText>
      </FeedbackContentRating>
    </FeedbackContentStyled>
  );
};
