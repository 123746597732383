import { PUBLIC_URL, REGION_REPORTS } from "@/shared/lib/constants";
import { IRegionReportsKey } from "@/shared/lib/types";

import { FilesResultType, RegionsType } from "./types";

export const getDateByNameReport = (period: string) => {
  return `${period.slice(0, 2)}.${period.slice(2, 4)}.${period.slice(4)}`;
};

export const getFilesByReportDate = (
  id: IRegionReportsKey,
  arrPeriod?: string[]
): any => {
  return (
    arrPeriod || [
      "01042022",
      "01072022",
      "01102022",
      "01012023",
      "01042023",
      "01072023",
    ]
  ).map((reportDate) => ({
    url: `${PUBLIC_URL}/files/${reportDate}/${REGION_REPORTS[id]}_${reportDate}.pdf`,
    date: getDateByNameReport(reportDate),
    id: reportDate,
  }));
};

export const getFilesSizes = async (regions: RegionsType[]) => {
  const result: FilesResultType = {};

  const promisses = regions.flatMap(({ files, id }) => {
    return files.map(async (file) => {
      const response = await fetch(file.url);
      const data = await response.blob();
      if (!result[id]) {
        result[id] = {};
      }
      result[id][file.id] = (data.size * 0.000001).toFixed(2);
    });
  });

  await Promise.all(promisses);
  return result;
};
