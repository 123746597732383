import { FC, useCallback } from "react";

import {
  ToggleButtonItem,
  ToggleButtonWrapper,
} from "@/shared/ToggleButton/ToggleButton.styled";
import { useAppDispatch, useAppSelector } from "@/store/redux/hooks";

import { COMPARE_TYPE, ICompareControl } from "../lib/types";
import { compareSetValue } from "../model/compareSlice";

export const CompareControl: FC<ICompareControl> = (props) => {
  const dispatch = useAppDispatch();
  const { value } = useAppSelector((state) => state.controls.compare);

  const handleChange = useCallback(
    (event: any, val: COMPARE_TYPE) => {
      dispatch(compareSetValue(val));
    },
    [dispatch]
  );

  return (
    <ToggleButtonWrapper
      {...props}
      onChange={handleChange}
      exclusive
      value={value}
    >
      <ToggleButtonItem value="RF">
        <p>РФ</p>
      </ToggleButtonItem>
      <ToggleButtonItem value="FO">
        <p>ФО</p>
      </ToggleButtonItem>
    </ToggleButtonWrapper>
  );
};
