export const getOffsetLabel = (name?: string) => {
  switch (name) {
    case "г. Красногорск":
      return [0, 40];
    case "г. Гатчина":
      return [40, 20];
    case "г. Великий Новгород":
      return [0, 10];
    case "г. Петропавловск-Камчатский":
      return [20, 50];
    case "г. Хабаровск":
      return [-10, -20];
    case "г. Владивосток":
      return [0, 20];
    case "г. Абакан":
      return [35, 0];
    case "г. Кызыл":
      return [0, 10];
    case "г. Йошкар-Ола":
      return [20, 0];
    case "г. Пенза":
      return [0, 10];
    case "г. Симферополь":
      return [20, 15];
    case "г. Краснодар":
      return [10, -30];
    case "г. Магас":
      return [25, -10];
    case "г. Грозный":
      return [0, 15];
  }
};
