import { Footer } from "../Footer/ui/Footer";
import {
  FooterDotMarkFact,
  FooterDotMarkPlan,
  Item,
} from "../Footer/ui/Footer.styled";

export const FooterForecastFact = () => {
  return (
    <Footer>
      <Item>
        <FooterDotMarkPlan />
        Прогноз
      </Item>
      <Item>
        <FooterDotMarkFact colorDote />
        Факт
      </Item>
    </Footer>
  );
};
