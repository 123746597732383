import { useTheme } from "@mui/material";
import React, { useCallback, useMemo } from "react";

import { ContentScrollbar } from "@/app/AppComponent/ui/App.styled";
import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { CustomScrollbar } from "@/shared/CustomScrollbar/ui/CustomScrollbar";
import { isTestBranch } from "@/shared/lib/constants";
import { ModalWrapper } from "@/shared/Modal/Modal";
import { CloseButton, FormHeader } from "@/shared/Table/ui/PopUp/PopUp.styled";

import { useIndicatorPassport } from "../hooks/useIndicatorPassport";
import { usePassportHeader } from "../hooks/usePassportHeader";
import {
  indicatorsEnterprises,
  PASSPORT_BUTTONS,
  PASSPORT_COMPARE,
  PASSPORT_TABS,
} from "../lib/constants";
import { initialState } from "../model/passportSlice";
import {
  PassportHeaderGroup,
  PassportHeaderTitle,
} from "./IndicatopPassport.styled";
import { IndicatorPassportContent } from "./IndicatorPassportContent/IndicatorPassportContent";
import { IndicatorPassportGraph } from "./IndicatorPassportGraph/IndicatorPassportGraph";

export const IndicatorPassport = () => {
  const theme = useTheme();
  const {
    indicatorPassport: { indicatorId, data, open },
    setIndicatorPassport,
  } = useIndicatorPassport();
  const { isCheckRfFoPage } = useRegions();
  const { activeIcon, compare, resetState, renderButtonGroup } =
    usePassportHeader();

  const handleClosePassport = useCallback(() => {
    setIndicatorPassport({ ...initialState.indicatorPassport });
    resetState();
  }, []);

  const [dataIndicators, title, unit] = useMemo(() => {
    let unit: string;
    let title = data?.attributeVals?.short_name || "-";
    if (["31", "54"].includes(indicatorId!!) && isCheckRfFoPage.isAll) {
      unit = "трилл руб";
    } else if (indicatorsEnterprises.includes(indicatorId!!)) {
      unit = "тыс ед";
    } else {
      unit = data?.attributeVals?.unit_measure || "-";
    }
    return [data, title, unit];
  }, [data, indicatorId, isCheckRfFoPage.isAll]);

  const renderContent = useCallback(() => {
    switch (activeIcon) {
      case PASSPORT_TABS.GRAPH:
        return (
          <IndicatorPassportGraph
            indicatorId={indicatorId}
            activeCompare={compare}
          />
        );
      case PASSPORT_TABS.TABLE:
      default:
        return <IndicatorPassportContent indicatorResult={dataIndicators} />;
    }
  }, [dataIndicators, activeIcon, compare, indicatorId]);

  return (
    <ModalWrapper
      open={!!indicatorId && open}
      onClose={handleClosePassport}
      width={141}
      height={93}
      children={
        <>
          <FormHeader
            sx={{
              background: theme.palette.dropdownWidget.baseHeader,
              gap: "1rem",
            }}
          >
            <PassportHeaderTitle>
              {`${title}, `}
              <span>{unit}</span>
            </PassportHeaderTitle>

            <PassportHeaderGroup>
              {isTestBranch &&
                !isCheckRfFoPage.isAll &&
                !["7", "27"].includes(indicatorId!!) &&
                activeIcon === PASSPORT_TABS.GRAPH &&
                renderButtonGroup(PASSPORT_COMPARE, compare)}
              {isTestBranch &&
                !(isCheckRfFoPage.isAll && ["7"].includes(indicatorId!!)) &&
                renderButtonGroup(PASSPORT_BUTTONS, activeIcon)}
              <CloseButton onClick={handleClosePassport} />
            </PassportHeaderGroup>
          </FormHeader>

          <ContentScrollbar>
            <CustomScrollbar>{renderContent()}</CustomScrollbar>
          </ContentScrollbar>
        </>
      }
    />
  );
};
