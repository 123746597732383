import React, { useEffect, useMemo, useState } from "react";

import { ICONS_INSIDE_PAGE } from "@/pages/InsideMainPage/lib/constants";
import { AchievementNavigateGroupProps } from "@/pages/InsideMainPage/lib/types";

import {
  SwitcherButtonGroup,
  ToggleButtonStyled,
} from "../InsideMainPageTabs.styled";

export const AchievementNavigateGroup = ({
  iconId,
  isNoGraphs = false,
}: AchievementNavigateGroupProps) => {
  const [changeIcon, setChangeIcon] = useState(ICONS_INSIDE_PAGE[1].id);

  useEffect(() => {
    if (iconId) {
      setChangeIcon(iconId);
    }
  }, [iconId]);

  const getActualIconsButton = useMemo(() => {
    return isNoGraphs ? [...ICONS_INSIDE_PAGE]?.slice(0, 2) : ICONS_INSIDE_PAGE;
  }, [isNoGraphs, ICONS_INSIDE_PAGE]);

  const handleIcon = (
    event: React.MouseEvent<HTMLElement>,
    newIcon: string
  ) => {
    if (newIcon && newIcon !== changeIcon) {
      setChangeIcon(newIcon);
    }
  };

  return (
    <SwitcherButtonGroup
      size="small"
      exclusive
      value={changeIcon}
      onChange={handleIcon}
    >
      {Object.keys(getActualIconsButton).map((index: any) => (
        <ToggleButtonStyled
          isSelected={ICONS_INSIDE_PAGE[index].id === changeIcon}
          key={ICONS_INSIDE_PAGE[index].id as string}
        >
          {ICONS_INSIDE_PAGE[index].icon}
        </ToggleButtonStyled>
      ))}
    </SwitcherButtonGroup>
  );
};
