import { Timeline } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import { TableBody, TableHead, TableRow, Tooltip } from "@mui/material";
import { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";

import { colorBasedOnValue } from "@/pages/MainPage/lib/helpers";
import { useProjectIndicators } from "@/pages/ProjectsPage/hooks/useProjectIndicators";
import { STICKY_INDEX_INDICATOR } from "@/pages/ProjectsPage/lib/constants";
import { Unit } from "@/shared/Achievements/ui/Achievements.styled";
import { CardList } from "@/shared/CardList/CardList.styled";
import { DotMarkFail, DotMarkSuccess } from "@/shared/Dots/Dots.styled";
import { Dropdown } from "@/shared/Dropdown/ui/Dropdown";
import { useOpenIncident } from "@/shared/Incident/hooks/useOpenIncident";
import { IncidentCreateButton } from "@/shared/Incident/ui/IncidentCreateButton/IncidentCreateButton";
import { useCheckpointsFilter } from "@/shared/InputSelect/hooks/useCheckpointsFilter";
import { SELECT_VALUE } from "@/shared/InputSelect/lib/types";
import { formatNumber } from "@/shared/lib/heplers";
import { Sceleton } from "@/shared/Sceleton/ui/Sceleton";
import { StickyTableCell } from "@/shared/TableBase/BasicTable.styled";
import { useStickyTableCells } from "@/shared/TableBase/hooks/useStickyTableCells";
import { ToggleButtonStyled } from "@/shared/TimeLineControl/TimeLineControl.styled";

import {
  ProjectTable,
  ProjectTableCell,
  ProjectTableDropdown,
  ProjectTitle,
  ProjectTitleText,
} from "../common/ProjectTable.styled";
import { AchievementIndicatorHeader } from "./AchievementIndicatorHeader";

export const ProjectIndicatorsTable = () => {
  const theme = useTheme();
  const showRef = useRef<HTMLDivElement | null>(null);
  const { move, handleMove, handleChange } = useOpenIncident();
  const navigate = useNavigate();
  const { id } = useParams<"id">();
  const { actualPeriodData } = useCheckpointsFilter();
  const reportDate = actualPeriodData?.attributeVals.REPORT_DATE || "";
  const [changePeriod, setChangePeriod] = useState<SELECT_VALUE | null>(
    SELECT_VALUE.CURRENT_PERIOD
  );
  const {
    QUAR_OPTIONS,
    columnRows,
    rows,
    quarter,
    handleQuarter,
    infoFetching,
  } = useProjectIndicators(changePeriod);

  const handleChangePeriod = (period: SELECT_VALUE) => {
    setChangePeriod(period);
  };

  const { getSticky: getStickyFirstRow } = useStickyTableCells(
    columnRows[0],
    STICKY_INDEX_INDICATOR
  );
  const { getSticky: getStickySecondRow } = useStickyTableCells(
    columnRows[1],
    0,
    getStickyFirstRow(STICKY_INDEX_INDICATOR).stickyPosition
  );
  const { getSticky: getStickyThirdRow } = useStickyTableCells(
    columnRows[2],
    -1,
    getStickyFirstRow(STICKY_INDEX_INDICATOR).stickyPosition
  );

  return (
    <CardList
      ref={showRef}
      onMouseEnter={() => handleMove(true)}
      onMouseLeave={() => handleMove(false)}
      sx={{ position: "relative" }}
    >
      <ProjectTitle>
        <ProjectTitleText sx={{ flex: "none" }}>
          Показатели проекта
        </ProjectTitleText>
        <AchievementIndicatorHeader changePeriod={handleChangePeriod} />
        {move && (
          <IncidentCreateButton
            bigSize
            posRight={440}
            onClick={(event) => {
              handleChange(
                event,
                showRef?.current,
                "Показатели проекта",
                reportDate
              );
            }}
          />
        )}
        <ToggleButtonStyled
          onClick={() => navigate(`/projects/${id}/graphs`)}
          value={{}}
        >
          <Timeline />
        </ToggleButtonStyled>
      </ProjectTitle>
      {infoFetching ? (
        <Sceleton type="line" heightLine={84} />
      ) : (
        <ProjectTable fullHeight horizontalBorders>
          <TableHead>
            {columnRows.map((columns, index) => {
              let getSticky = getStickyFirstRow;

              switch (index) {
                case 1:
                  getSticky = getStickySecondRow;
                  break;
                case 2:
                  getSticky = getStickyThirdRow;
                  break;
              }

              return (
                <TableRow key={index}>
                  {columns.map(({ id, label, oldid, ...column }, index) => (
                    <ProjectTableCell
                      key={id}
                      align={id === "number" ? "center" : undefined}
                      sx={
                        oldid === "current-year-percent"
                          ? { whiteSpace: "nowrap" }
                          : null
                      }
                      {...Object.fromEntries(
                        Object.entries({ ...column })?.filter(
                          ([item, _]) => item !== "isFilter"
                        )
                      )}
                      {...getSticky(index)}
                    >
                      {column?.isFilter ? (
                        <ProjectTableDropdown>
                          <Dropdown
                            key={id}
                            value={quarter[id]}
                            options={QUAR_OPTIONS[id]}
                            onChange={(event) => handleQuarter(id, event)}
                          />
                        </ProjectTableDropdown>
                      ) : (
                        label
                      )}
                    </ProjectTableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableHead>

          <TableBody>
            {rows.map((cells, rowIndex) => {
              return (
                <TableRow key={rowIndex}>
                  {cells.map(
                    (
                      { id, value, oldid, colorPercent = null, ...cell },
                      index
                    ) => {
                      const sticky =
                        index < STICKY_INDEX_INDICATOR
                          ? getStickyFirstRow(index)
                          : getStickySecondRow(index - STICKY_INDEX_INDICATOR);

                      const returnValue = value ?? "-";
                      const isParsingNumber =
                        !["achievement_date", "number"].includes(id) &&
                        !["current-year-percent"].includes(oldid) &&
                        !isNaN(parseInt(value as string));

                      if (oldid === "completion_status_attr") {
                        return (
                          <StickyTableCell
                            align="center"
                            key={id}
                            {...cell}
                            {...sticky}
                          >
                            {typeof value !== "undefined" ? (
                              value ? (
                                <DotMarkSuccess />
                              ) : (
                                <DotMarkFail />
                              )
                            ) : (
                              "-"
                            )}
                          </StickyTableCell>
                        );
                      }

                      if (
                        [
                          "current-year-fact",
                          "current-year-plan",
                          "current-year-percent",
                        ].includes(oldid)
                      ) {
                        const fractionDigits =
                          oldid === "current-year-percent" &&
                          (value === "100" || value === "0")
                            ? 0
                            : [1, 3].includes(rowIndex) &&
                              oldid !== "current-year-percent"
                            ? 0
                            : oldid === "current-year-percent"
                            ? 1
                            : 2;

                        return (
                          <Tooltip
                            key={id}
                            title={value as number | string}
                            placement="top"
                            arrow
                            disableHoverListener={value === "-"}
                          >
                            <StickyTableCell
                              key={id}
                              {...cell}
                              {...sticky}
                              align={id === "number" ? "center" : undefined}
                              style={
                                oldid === "current-year-percent" &&
                                value !== "-"
                                  ? {
                                      color: colorBasedOnValue(
                                        theme,
                                        colorPercent
                                      ),
                                    }
                                  : null
                              }
                            >
                              {formatNumber(
                                value as number | string,
                                undefined,
                                fractionDigits
                              )}
                              {oldid === "current-year-percent" &&
                                value !== "-" && <Unit>%</Unit>}
                            </StickyTableCell>
                          </Tooltip>
                        );
                      }
                      return (
                        <StickyTableCell
                          key={id}
                          {...cell}
                          {...sticky}
                          align={id === "number" ? "center" : undefined}
                          style={id === "short_name" ? { zIndex: 2 } : null}
                        >
                          {[1].includes(rowIndex) && isParsingNumber
                            ? formatNumber(value as string, undefined, 0)
                            : returnValue}
                        </StickyTableCell>
                      );
                    }
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </ProjectTable>
      )}
    </CardList>
  );
};
