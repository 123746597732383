import { useTheme } from "@mui/material";
import React from "react";

import { useRegions } from "@/pages/RegionPage/hooks/useRegions";
import { BlockWpapper } from "@/shared/BlockWrapper/BlockWrapper";
import { defaultNoDataText } from "@/shared/lib/constants";

import { useAllVDL } from "../hooks/useAllVdl";
import { AllVdlLeaderProps } from "../lib/types";
import {
  AllVdlUnit,
  AllVdlWrapper,
  AvatarStyled,
  LeaderInfo,
  NameWrapper,
  RegionWrapper,
} from "./AchievementVdl.styled";

export const AllVdlLeader = ({
  allVdl,
  isOpenPopUp = false,
}: AllVdlLeaderProps) => {
  const theme = useTheme();
  const { isCheckRfFoPage } = useRegions();
  const { allVdlFetching } = useAllVDL();
  return (
    <BlockWpapper
      hasData={!!(allVdl || [])?.length}
      isFetching={allVdlFetching}
      noDataText={defaultNoDataText}
    >
      {allVdl?.map((vdl) => (
        <AllVdlWrapper
          isOpenPopUp={isOpenPopUp && !isCheckRfFoPage.isRF}
          openWidgetByRf={isOpenPopUp && isCheckRfFoPage.isRF}
          key={vdl.leaderFio}
        >
          <AvatarStyled
            isOpenPopUp={isOpenPopUp}
            alt={vdl.leaderFio}
            src={vdl.leaderPhoto}
            sx={{ background: theme.palette.text.primary }}
          />

          <LeaderInfo isOpenPopUp={isOpenPopUp}>
            <NameWrapper isOpenPopUp={isOpenPopUp}>{vdl.leaderFio}</NameWrapper>

            <RegionWrapper isOpenPopUp={isOpenPopUp}>
              {vdl.regionName}
            </RegionWrapper>
          </LeaderInfo>
          <AllVdlUnit
            openWidget={!!isOpenPopUp}
          >{`${vdl.factValue} ${vdl.indicatorUnit}`}</AllVdlUnit>
        </AllVdlWrapper>
      ))}
    </BlockWpapper>
  );
};
