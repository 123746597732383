import { combineReducers } from "@reduxjs/toolkit";

import riskTableSlice from "@/pages/MainPage/model/riskSubjectsTableSlice";

import { IMapReducer } from "../../../features/Map/lib/types";
import geoSlice from "../../../features/Map/model/geoSlice";

const mapReducer = combineReducers<IMapReducer>({
  geo: geoSlice.reducer,
  riskTable: riskTableSlice.reducer,
});

export default mapReducer;
