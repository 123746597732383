import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import { PropsWithChildren, useCallback, useState } from "react";

import { IDropdownProps, IDropdownValue } from "../lib/types";
import {
  DropdownOption,
  DropdownSelect,
  DropdownStyled,
} from "./Dropdown.styled";

export const Dropdown = <I extends IDropdownValue>({
  value,
  options,
  isBigSize = false,
  onChange,
}: PropsWithChildren<IDropdownProps<I>>) => {
  const [open, setOpen] = useState(false);

  const toggle = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleChange = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      if (onChange) {
        onChange(event.target.value as I);
      }
    },
    [onChange]
  );

  const selectedLabel =
    options?.find((opt) => opt.value === value)?.label ?? "";

  return (
    <DropdownStyled onClick={toggle} isBigSize={isBigSize}>
      {selectedLabel}
      {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}

      <DropdownSelect
        open={open}
        value={value}
        onChange={handleChange}
        onClose={handleClose}
      >
        {options?.map((opt) => (
          <DropdownOption
            key={opt?.value}
            value={opt?.value}
            isBigSize={isBigSize}
            selected={opt?.value === value}
          >
            <div>{opt?.label}</div>
          </DropdownOption>
        ))}
      </DropdownSelect>
    </DropdownStyled>
  );
};
