import { PERIOD_GRAPH, PERIOD_INDICATOR } from "./constants";

// Функция возвращает массив периодов от актуального (maxPeriods) и меньше и массив дат
export const defaultPeriods = (
  maxPeriod: number,
  compareStart: string,
  compareEnd: string
) => {
  const periods = [];
  const xAxisPeriods = [];

  if (maxPeriod && compareStart && compareEnd) {
    const defaultPeriodArray = String(maxPeriod);
    const year = +[...defaultPeriodArray?.split("")].splice(1, 4).join("");

    let count = 4;

    while (count > 0) {
      const yearInsert = year - count;
      const insert = `${compareStart}${yearInsert}${compareEnd}`;
      periods.push(insert);
      xAxisPeriods.push(`01.01.${yearInsert + 1}`);
      count--;
    }
    xAxisPeriods.push(`01.01.${year + 1}`);
    periods.push(String(maxPeriod));
  }
  return [periods, xAxisPeriods];
};

// Функция возвращает периодичность
export const comparePeriods = (indicatorId: string | null) => {
  switch (true) {
    case PERIOD_INDICATOR[PERIOD_GRAPH.DAY].includes(indicatorId!!):
      return [PERIOD_GRAPH.DAY];
    case PERIOD_INDICATOR[PERIOD_GRAPH.MONTH].includes(indicatorId!!):
      return [PERIOD_GRAPH.MONTH];
    case PERIOD_INDICATOR[PERIOD_GRAPH.QUARTER].includes(indicatorId!!):
      return [PERIOD_GRAPH.QUARTER];
    case PERIOD_INDICATOR[PERIOD_GRAPH.YEAR].includes(indicatorId!!):
      return [PERIOD_GRAPH.YEAR];
    default:
      return;
  }
};

// Функция возвращает значение, которое нужно конвертировать по unit (для 31, 54, 14, 16, 18 индикаторов)
export const convertByUnit = (
  value?: string | number,
  divideValue?: number
) => {
  let convertValue = value;

  if (divideValue && convertValue) {
    convertValue = +convertValue / +divideValue;
  }
  return convertValue;
};
