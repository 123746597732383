import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { prepareAttributes } from "@/shared/lib/heplers";
import { ArgumentsWithDistrictIdType } from "@/shared/lib/types";
import {
  IPreparedIndicatorAttributeVals,
  MODEL_IDS,
  MODELS,
} from "@/store/scheme/olap";

import { IBudgetSlice } from "../lib/types";
import { getBudget } from "./api";

export const initialState: IBudgetSlice = {
  data: undefined,
  budgetPassport: {},
  fetching: false,
  error: null,
};

export const extraActionsBudget = {
  get: createAsyncThunk(
    "regions/budget/get",
    async (args: ArgumentsWithDistrictIdType) => await getBudget(args)
  ),
};

const budgetSlice = createSlice({
  name: "budget",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActionsBudget.get.pending, (state) => {
        state.data = undefined;
        state.fetching = true;
      })
      .addCase(extraActionsBudget.get.fulfilled, (state, action) => {
        const model = MODELS[MODEL_IDS.BUDGET];
        const temporaryPassport = {} as any;

        state.data = action.payload.reduce((prev, cur) => {
          const regionId = cur?.dims?.[model.dataRegionId]?.code;

          if (regionId) {
            if (!prev[regionId]) {
              prev[regionId] = {};
              temporaryPassport[regionId] = {};
            }

            if (cur?.dims?.[model.dimId]?.code) {
              prev[regionId][cur.dims[model.dimId].code] = {
                ...prepareAttributes<IPreparedIndicatorAttributeVals>(
                  cur.dims[model.dimId].attributeVals
                ),
                attributeVals: cur?.attributeVals || {},
                indicatorVals: Object.fromEntries(
                  Object.entries(cur?.indicatorVals || {}).map(
                    ([key, value]) => [key, value?.sum]
                  )
                ) as any,
              };
              temporaryPassport[regionId][cur.dims[model.dimId].code] = {
                attributeVals: cur.dims[model.dimId].attributeVals || {},
              };
            }
          }

          return prev;
        }, {} as any);
        state.fetching = false;
        state.error = null;
        state.budgetPassport = { ...temporaryPassport };
      })
      .addCase(extraActionsBudget.get.rejected, (state, action) => {
        state.fetching = false;
        state.error = action.error;
      });
  },
});

export const reducer = budgetSlice.reducer;

export default budgetSlice;
