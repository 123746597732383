import { toPng } from "html-to-image";

import { IIsCheckRfFoPage } from "@/pages/RegionPage/lib/types";

// Функция получает ссылку на элемент и формирует изображение
export const screenshotWidget = async (linkElement: HTMLElement | null) => {
  if (!linkElement) {
    return "";
  }
  // Фильтруем элемент для виджета с изображением глав
  const png = await toPng(linkElement, {
    filter: (node) => {
      return !node.classList?.contains("MuiAvatar-img");
    },
  });
  return png;
};

// Функция по сохранению изображения на ПК
export const screenshotDownload = (title: string, screenshot: string) => {
  const link = document.createElement("a");
  link.download = `${title}.png`;
  link.href = screenshot;
  link.click();
};

const defineTabPanel = (pathName: string, isCheckRFFo: IIsCheckRfFoPage) => {
  switch (true) {
    case pathName.endsWith("/manufacturing"):
      return "Выпуск товаров и услуг";
    case pathName.endsWith("/profit"):
      return "Доходы населения";
    case pathName.endsWith("/investment"):
      return "Частные инвестиции";
    case pathName.endsWith("/employed"):
      return "Число рабочих мест";
    default:
      if (isCheckRFFo.isRF) {
        return "Страница Российская Федерация";
      } else if (isCheckRFFo.isFO) {
        return "Страница федерального округа";
      } else {
        return "Страница субъекта РФ";
      }
  }
};

// Определение инфо панели
export const defineInfoPanel = (
  pathName: string,
  isCheckRFFo: IIsCheckRfFoPage
) => {
  switch (true) {
    case pathName === "/":
    case pathName.startsWith("/district"):
    case pathName.startsWith("/selectedDistrict"):
      return "Главная";
    case pathName.startsWith("/achievementLevel"):
      return "Таблицы 'Подробнее'";
    case pathName.startsWith("/projects") && !pathName.endsWith("/graphs"):
      return "Проекты";
    case pathName.startsWith("/region") && !pathName.endsWith("/graphs"):
      return defineTabPanel(pathName, isCheckRFFo);
    case pathName.endsWith("/graphs"):
      return "Графический отчет";
  }
};

// Функция по созданию объекта File из base64
export const base64ToFile = async (screenshot: string, title: string) => {
  const response = await fetch(screenshot);
  const data = await response.blob();
  const file = new File([data], `${title}.png`, { type: "image/png" });
  return file;
};

// Функция по созданию ссылки
export const getLinkFile = (screenshot: string, titleWidget: string) => {
  var myWindow = window.open("", "") as Window;
  myWindow.document.write(
    `<html><head><title>${titleWidget}</title></head><body>`
  );
  myWindow.document.write(
    `<img src=${screenshot} style=background:#202b37;max-width:100%;height:auto>`
  );
  myWindow.document.write("</body></html>");
};

export const descriptionFormation = ({
  titleWidget,
  infoPanel,
  territory,
  reportPeriod,
  description,
  authorIncident,
  dateCreateIncident,
}: Record<string, string>) => {
  return ` Наименование виджета: ${titleWidget} 
  \n Наименование информационной панели: ${infoPanel} 
  \n Наименование территории: ${territory} 
  \n Отчётный период: ${reportPeriod} 
  \n Описание: ${description} 
  \n Автор и время создания черновика: ${authorIncident}, ${dateCreateIncident}`;
};
