import { ArgumentsType } from "@/shared/lib/types";
import { getOperands, olapRequestData2 } from "@/store/api/core";
import {
  DATA_ACTUAL_VALUE,
  FILTER_OPERAND_TYPE,
  FILTER_TYPE,
  MODEL_IDS,
  MODELS,
} from "@/store/scheme/olap";

// Запрос на получение 104 индикатора КПИ для рисков на страницах РФ/ФО
export const getSumRisksKpiRfFo = ({
  dataSummaryId,
  regionId,
}: ArgumentsType) => {
  const model = MODELS[MODEL_IDS.KPI_SOC_ECONOMY];
  const dimensions = [
    {
      id: model.dataPeriodId,
      includeItems: true,
      includeAttributesByCodes: ["REPORT_DATE"],
    },
    {
      id: model.dataRegionId,
    },
    {
      id: model.dimId,
    },
    {
      id: model.dataActualId,
    },
    {
      id: model.dataSummaryId,
    },
  ];

  const indicators = {
    id: model.indicatorId,
    items: [{ id: model.indexes.fact }],
  };

  const operands = getOperands([
    {
      type: FILTER_OPERAND_TYPE.LEVEL,
      dim_id: model.dataPeriodId,
      showLeafsOnly: false,
      showChildren: false,
      levels: [2],
      showAllLevelElements: false,
    },
    {
      type: FILTER_OPERAND_TYPE.PARENT,
      dim_id: model.dataRegionId,
      values: [regionId],
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dimId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: ["104"],
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataActualId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: [DATA_ACTUAL_VALUE],
        },
      ],
    },
    {
      type: FILTER_OPERAND_TYPE.EQ,
      operands: [
        {
          type: FILTER_OPERAND_TYPE.DIM,
          id: model.dataSummaryId,
        },
        {
          type: FILTER_OPERAND_TYPE.CONST,
          values: [dataSummaryId],
        },
      ],
    },
  ]);

  return olapRequestData2(
    dimensions.map(({ id }) => id),
    model.indicatorId,
    {
      modelUuid: model.uuid,
      includeGaps: false,
      indicatorId: model.indicatorId,
      dimensions,
      indicators,
      dataFilter: {
        type: FILTER_TYPE.AND,
        version: 1,
        operands,
      },
    },
    "9cf2424f-e39c-4c21-baea-529dee686140"
  );
};
