import { Stack } from "@mui/material";
import { FC, ReactNode, useContext } from "react";
import { createPortal } from "react-dom";

import { RootContainerContext } from "@/app/AppComponent/ui/App";

import { DividerFooter, FooterStyled } from "./Footer.styled";

export const Footer: FC<{ children: ReactNode }> = ({ children }) => {
  const rootContainerRef = useContext(RootContainerContext);

  return (
    rootContainerRef &&
    createPortal(
      <FooterStyled>
        <Stack
          direction="row"
          divider={<DividerFooter orientation="vertical" flexItem />}
          gap={2}
        >
          {children}
        </Stack>
      </FooterStyled>,
      rootContainerRef
    )
  );
};
