import React, { FC, useMemo } from "react";

import { useKpi } from "@/shared/GeneralIndicators/hooks/useKpi";
import { IValue } from "@/shared/GeneralIndicators/lib/types";
import { GeneralIndicators } from "@/shared/GeneralIndicators/ui/GeneralIndicators";
import { IPopUpProps, REGION_TABLES } from "@/shared/RegionPopUp/lib/types";

export const AverageMonthNominalSalary: FC<IPopUpProps> = ({ isOpenPopUp }) => {
  const { setBaseValues, fetching } = useKpi();

  const values: IValue[] = useMemo(() => {
    return setBaseValues(["52", "53"]);
  }, [setBaseValues]);

  return (
    <GeneralIndicators
      title="Среднемесячная начисленная номинальная заработная плата"
      generalFetching={fetching}
      indicatorsFontStyle="italic"
      values={values}
      isOpenPopUp={isOpenPopUp}
      popUpId={REGION_TABLES.AVERAGE_MONTH_NOMINAL_SALARY}
    />
  );
};
