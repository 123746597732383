import React from "react";

import { MethodRiskWrapper } from "./ProjectRisksTable.styled";

export const MethodCalcRisk = () => {
  return (
    <>
      <MethodRiskWrapper>
        <p>
          Риск реализации проекта рассчитывается по 10-ти балльной системе, в
          рамках которой оценивается перенос плановой даты исполнения
          контрольных точек и степень снижения плановых значений показателей
          проектов относительно изначально утвержденных.
        </p>
        <br />
        <p>Шкала баллов:</p>
        <ul>
          <li>0-2 баллов – без риска;</li>
          <li>2-6 баллов – средний риск;</li>
          <li>6-10 баллов– высокий риск</li>
        </ul>
      </MethodRiskWrapper>
    </>
  );
};
