import { useAppSelector } from "@store/redux/hooks";
import { MODEL_IDS, MODELS } from "@store/scheme/olap";
import { FC } from "react";

import { Table } from "@/shared/Table/ui/Table";

import { useAverageAchievementColumns } from "../../hooks/useAverageAchievementColumns";
import { AverageAchievementTableProps } from "../../lib/types";

export const AverageAchievementTable: FC<AverageAchievementTableProps> = ({
  openColumnsModal,
  closeColumnsModal,
}) => {
  const modelPassport = MODELS[MODEL_IDS.PROJECT_PASSPORT];

  const { projectPassport } = useAppSelector((state) => state.map.geo);

  const { averageAchievementColumns } = useAverageAchievementColumns({
    modelPassport,
    projectPassport,
  });

  return (
    <Table
      helperHeder="в том числе"
      headerColumns={averageAchievementColumns}
      openColumnsModal={openColumnsModal}
      closeColumnsModal={closeColumnsModal}
      withProjects
    />
  );
};
