import moment from "moment";

import {
  DATE_PART,
  IAttributeVals,
  IPreparedAttributeVals,
  IPreparedIndicator,
  IPreparedIndicatorAttributeVals,
  IPreparedOlapResponseDimensionItemDim,
} from "../../store/scheme/olap";

export const formatNumber = (
  value?: number | string,
  unit?: string,
  fractionDigits = 1,
  keepSign = false,
  isDash?: boolean
) => {
  let curValue: number | string | undefined = "";
  let textValue = "";

  if (isDash && value === 0) return "-";

  if (
    typeof value === "string" &&
    value.includes(",") &&
    !isNaN(Number(value.replace(/\s/g, "").replace(/,/g, ".")))
  ) {
    curValue = Number(value.replace(/\s/g, "").replace(/,/g, "."));
  } else {
    curValue =
      typeof value === "number" || value === "" || isNaN(Number(value))
        ? value
        : Number(value);
  }

  if (typeof curValue === "number" && !Number.isNaN(curValue)) {
    textValue = new Intl.NumberFormat(navigator.language, {
      maximumFractionDigits: fractionDigits,
      minimumFractionDigits: fractionDigits,
    }).format(curValue);

    if (keepSign && curValue > 0) {
      textValue = `+${textValue}`;
    }
    if (Number(textValue.replace(/,/gm, ".")) === 0) {
      textValue = `${textValue.replace(/-/gm, "")}`;
    }
  } else {
    textValue = "-";
  }

  return unit
    ? `${textValue}${textValue !== "-" ? ` ${unit}` : ""}`
    : textValue;
};

export const formatNumberPeople = (
  value?: number | string,
  unit?: string,
  fractionDigits?: number,
  keepSign = false
) => {
  const curValue =
    value == null || typeof value === "number" ? value : Number(value);
  const fd =
    curValue != null && Math.floor(curValue) === value ? 0 : fractionDigits;

  return formatNumber(curValue, unit, fd, keepSign);
};

export const prepareUnit = (val: string) =>
  val?.replace(/(кв\.\s*м\.|м2)+/, "м²");

export const prepareAttributes = <
  A extends IPreparedAttributeVals = IPreparedAttributeVals
>(
  attributes: IAttributeVals
) =>
  (Object.keys(attributes) as (keyof IAttributeVals)[]).reduce((prev, attr) => {
    const value = attributes[attr];

    switch (attr) {
      case "UNIT":
      case "unit_measure":
        prev.UNIT = prepareUnit(value);
        break;
      case "type_ind_unit_measure":
        prev.type_ind_unit_measure = prepareUnit(value);
        break;
      case "SORT_ORDER":
        prev.SORT_ORDER = parseInt(value) || 0;
        break;
      default:
        prev[attr] = value;
        break;
    }

    return prev;
  }, {} as { [key: string]: any }) as A;

export const getRegion = (
  regionId: string,
  regions: IPreparedOlapResponseDimensionItemDim[]
) => {
  const region: IPreparedIndicator = {
    id: regionId,
    code: regionId,
    value: parseInt(regionId),
    attributeVals:
      regions.find((r) => r.id === regionId)?.attributeVals ??
      ({} as IPreparedIndicatorAttributeVals),
  };

  return region;
};

export const getDatePartIndicator = <
  A extends IPreparedAttributeVals = IPreparedAttributeVals
>(
  indicator?: Partial<Record<DATE_PART, Record<string, IPreparedIndicator<A>>>>,
  periodStart = DATE_PART.YEAR,
  dateCode?: string,
  getPrevPeriod?: boolean
) => {
  const periods = [
    DATE_PART.YEAR,
    DATE_PART.QUARTER,
    DATE_PART.MONTH,
    DATE_PART.WEEK,
    DATE_PART.DAY,
  ];
  periods.splice(0, periods.indexOf(periodStart));
  for (let period of periods) {
    if (indicator?.[period]) {
      const datePart = indicator?.[period];

      if (!datePart) continue;

      const keys = Object.keys(datePart).sort((a, b) => (a < b ? 1 : -1));
      const key = dateCode ?? keys[0];

      let targetKey = key;

      if (getPrevPeriod) {
        let prevPeriod = key;
        const curYear = key.slice(1, 5);
        const prevYear = String(Number(curYear) - 1);
        let prevQuarter = String(Number(key.slice(-1)) - 1);
        const isLastQ = prevQuarter === "0";
        if (isLastQ) prevQuarter = "4";

        switch (periodStart) {
          case DATE_PART.YEAR:
            prevPeriod = key[0] + prevYear + key.slice(5, key.length);
            break;

          case DATE_PART.QUARTER:
            prevPeriod =
              key[0] +
              (isLastQ ? prevYear : curYear) +
              key.slice(5, 8) +
              prevQuarter;
            break;
        }

        targetKey = keys.find((key) => key === prevPeriod) ?? key;
      }

      return targetKey ? datePart[targetKey] : undefined;
    }
  }
};

export const getUrlByTheme = (pathname: string): string => {
  const isLightMode = window.location.pathname.includes("/light");
  return `${isLightMode ? "/light" : ""}${pathname}`;
};

export const getPrevPer = (type = "3") => {
  switch (type) {
    case "1":
      return `${type}${moment().year() - 1}0000`;

    case "3":
      return `${type}${moment().year()}000${moment().quarter() - 1}`;

    case "4":
    default:
      return `${type}${moment().year()}000${moment().month() - 1}`;
  }
};

export const getParams = (param: string) => {
  const { pathname } = document.location;
  if (!pathname.includes(param)) return "";

  const arr = pathname.split("/");
  const index = arr.findIndex((el) => el === param);
  const id = arr[index + 1];

  return id;
};

export const convertPreviosDate = (date?: string) => {
  const isFirstDateOfYear = /^к 01.01./.test(date ?? "");
  return date?.replace(/\d$/gi, isFirstDateOfYear ? "1" : "0");
};

export const replaceSignature = (date: string) => {
  return date?.replace(/^к/gim, "на");
};
