import { SxProps } from "@mui/material/styles";
import React from "react";

import { TAB_ID } from "@/store/scheme/region";

export enum STEPS {
  MAIN = "main",
  MAIN_FULL = "mainFull",
  GOALS = "goals",
  PROJECTS = "projects",
}

export const MAIN_TABS = [TAB_ID.VDL_PROJECTS, TAB_ID.VDL, TAB_ID.PROJECTS];
export const PROJECTS_TABS = [
  TAB_ID.RESULT_PROJECTS,
  TAB_ID.RESULT_CHECKPOINTS,
];
export const GOALS_TABS = [
  TAB_ID.MANUFACTURING,
  TAB_ID.PROFIT,
  TAB_ID.INVESTMENT,
  TAB_ID.EMPLOYED,
];

export interface GridCardITab {
  id: TAB_ID;
  text?: string;
  date?: string;
  prevPeriod?: string;
  value?: number;
  valueUnit?: string;
  plan?: number;
  fact?: number;
  unit?: string;
  projects?: string;
  rf?: number;
  fo?: number;
  hlRF?: number;
  hlFO?: number;
  color?: number;
}

export interface IDynamicTab extends GridCardITab {
  gridArea: string;
}
export interface IGridCard {
  children: React.ReactNode;
  data: GridCardITab;
  subTitle?: string;
  gridArea?: string;
  selected?: boolean;
  onComponentClick?: boolean;
  closeIcon?: boolean;
  noCloseButton?: boolean;
  arrow?: boolean;
  dateLeft?: boolean;
  compactTitle?: boolean;
  isHighlight?: boolean;
  noBorder?: boolean;
  direction?: "column" | "row";
  className?: string;
  classNameContent?: string;
  sx?: SxProps;
  setSelectedTabId: (tabId: TAB_ID) => void;
  onClick?: (tabId: TAB_ID) => void;
}

export type TypeGridCardStyled = {
  selected?: boolean;
  arrow?: boolean;
  gridArea?: string;
  needStep?: boolean;
  isHighlight?: boolean;
  noBorder?: boolean;
};

export type TypeCardTitle = {
  isAchievement: boolean;
  selected?: boolean;
  arrow?: boolean;
};

export type TypeCardSubTitle = {
  selected?: boolean;
  compactTitle?: boolean;
  splitLines?: boolean;
  isHighLight?: boolean;
  arrow?: boolean;
};

export type TypeCardDate = {
  selected?: boolean;
  dateLeft?: boolean;
  prevPeriod?: boolean;
};

export type TypeUnit = {
  selected?: boolean;
};
