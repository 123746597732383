import { ButtonProps } from "@mui/material";
import { SerializedError } from "@reduxjs/toolkit";
import {
  IPreparedIndicatorAttributeVals,
  IPreparedOlapResponseDimensionItemDim,
  IProjectAttributeVals,
  IProjectIndicatorAttributeVals,
  IRegionAttributeVals,
  IRow,
  IRowDatePart,
  MODEL_IDS,
} from "@store/scheme/olap";
import { Dispatch, SetStateAction } from "react";

import { TAB_ID } from "@/store/scheme/region";

export enum ACHIEVEMENT_STATUS {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
}

export interface IIndicatorsTotalState {
  graphData: {
    [regionId: string]: {
      [modelId: string]: IRowDatePart<IPreparedIndicatorAttributeVals>;
    };
  };
  indicatorsGraphData: {
    [regionId: string]: {
      [modelId: string]: IRowDatePart<IPreparedIndicatorAttributeVals>;
    };
  };
  graphFetching: boolean;
  indicatorsGraphFetching: boolean;
  graphError: SerializedError | null;
  indicatorGraphError: SerializedError | null;
}

export interface IProject {
  attributeVals: IProjectAttributeVals;
  indicators: IRow<IProjectIndicatorAttributeVals>;
}

export interface AllProjects {
  [regionId: string]: IProject[];
}

export interface IIndicatorsProjectsState {
  allProjects?: AllProjects;
  fetching: boolean;
  error: SerializedError | null;
}

export interface ISubjectId {
  districtId?: string | null;
  regionId?: string | null;
}
export interface ISubjectIdState {
  subjectId?: ISubjectId;
}

export interface IIndicatorsReducer {
  total: IIndicatorsTotalState;
  indicators: IIndicatorsProjectsState;
  subjectId: ISubjectIdState;
}

export type Order = "asc" | "desc";
export type GetComparatorCallback = <T>(
  order: Order,
  orderBy: keyof T
) => (a: T, b: T) => number;
export type DescendingComparatorCallback = <T>(
  a: T,
  b: T,
  orderBy: keyof T
) => number;
export type SortByCallback = <T>(
  arr: T[],
  comparator: (a: T, b: T) => number
) => T[];

export interface HeaderColumns {
  id: string | "header";
  sortId?: number;
  subId?: string;
  label: string;
  model: MODEL_IDS;
  labelId?: string;
  unitId?: string;
  isComparable?: boolean;
  indicator: string;
  subIndicator?: string;
  achievementStatus?: ACHIEVEMENT_STATUS;
  parentHeaderLabel?: string;
  popUpLabel?: string;
  sortable?: true;
  updateDateId?: string;
  notVisible?: boolean;
  colorValues?: boolean;
  valuesUnit?: string;
  focused?: boolean;
  mainColumn?: number;
  fractionDigits?: number;
  defaultColumn?: boolean;
  data?: any;
  rowSpan?: number;
  colSpan?: number;
  helperId?: string;
  sortBy?: string;
  projectsData?: any;
  riskData?: any;
  needPrevPeriod?: boolean;
  prevPeriod?: boolean;
  dateCode?: string;
  indicatorColor?: string;
  isLastColumn?: boolean;
  subLabel?: string;
  textPeriod?: string;
  isNotNeedPeriod?: boolean;
  isRiskColumn?: boolean;
  subData?: any;
  subModel?: MODEL_IDS;
  projectId?: string;
  subShareId?: string;
  subProjectIndicatorId?: string;
}

export interface IHomeTableColumn extends Partial<HeaderColumns> {
  level?: number;
  lastSubColumn?: boolean;
}

export interface ITableHeader {
  data: HeaderColumns[];
  helperHeder?: string;
  setOrderBy: Dispatch<SetStateAction<any>>;
  setOrder: Dispatch<SetStateAction<any>>;
  orderBy: any;
  order: any;
  borderForDynamics?: boolean;
}

export interface ITableBody {
  columns: HeaderColumns[];
  withProjects?: boolean;
  order: any;
  orderBy: any;
  isSort?: boolean;
}

export interface ITable {
  headerColumns: HeaderColumns[];
  openColumnsModal: boolean;
  title?: string;
  helperHeder?: string;
  withProjects?: boolean;
  isSort?: boolean;
  borderForDynamics?: boolean;
  closeColumnsModal: () => void;
}

export interface IExpandButtonStyledProps {
  size?: "small";
  darkTheme?: boolean;
}

export interface PropsWithLevel {
  level?: number;
}
export interface IMainTableValueProps extends PropsWithLevel {
  valueColor?: VALUE_COLOR;
  focused?: boolean;
}

export interface IRegionRow {
  region: any;
  columns: HeaderColumns[];
  withProjects?: boolean;
  order: any;
  orderBy: any;
}

export interface IProjectRow {
  region: any;
  columns: HeaderColumns[];
  order: any;
  orderBy: any;
  currentTabName: TAB_ID;
}

export interface IExpandButton extends ButtonProps {
  isExpanded?: boolean;
  size?: "small";
  darkTheme?: boolean;
  closeIcon?: boolean;
}

export interface ITableCellExpandableProps {
  whiteSpace?: "nowrap";
  isBorderRight?: boolean;
}

export interface IExpandButtonStyledProps {
  size?: "small";
  darkTheme?: boolean;
}

export interface IDistrictRow {
  district: any;
  columns: HeaderColumns[];
  withProjects?: boolean;
  order: any;
  orderBy: any;
}

export enum VALUE_COLOR {
  GREEN = "1",
  DEFAULT = "2",
  RED = "3",
  LIGHT_GREEN = "6",
  BORDO = "7",
  ORANGE = "8",
}

export interface IPopUpProps {
  currentHeaderCols: IHeaderColumns[];
  initialHeaderCols: IHeaderColumns[];
  onSubmit: (prop: IHeaderColumns[]) => void;
  openPopUp: boolean;
  onClosePopUp: (prop: boolean) => void;
}

export interface ICheckedScheme {
  [key: string]: {
    checked: boolean;
    quantitySubColumns?: number;
    defaultQuantity?: number;
    popUpLabel?: string;
    isDateCode?: boolean;
  };
}

export interface IHeaderColumns {
  id: string | "header";
  subId?: string;
  label: string;
  model: MODEL_IDS;
  labelId?: string;
  unitId?: string;
  isComparable?: boolean;
  indicator: string;
  subIndicator?: string;
  achievementStatus?: ACHIEVEMENT_STATUS;
  parentHeaderLabel?: string;
  popUpLabel?: string;
  sortable?: true;
  updateDateId?: string;
  notVisible?: boolean;
  colorValues?: boolean;
  valuesUnit?: string;
  focused?: boolean;
  mainColumn?: number;
  fractionDigits?: number;
  defaultColumn?: boolean;
  helperId?: string;
  dateCode?: string;
}
export interface IIndicatorsProjectsState {
  allProjects?: AllProjects;
  fetching: boolean;
  error: SerializedError | null;
}

export interface useDistrictRowTableT {
  columns: HeaderColumns[];
  allDistricts: IPreparedOlapResponseDimensionItemDim<IRegionAttributeVals>[];
  getIndicator: any;
  isSort?: boolean;
}

export interface IMainDataProject {
  isShow: boolean;
  attributeVals: IProjectAttributeVals;
}

export interface IFuturePlanCheckBoxProps {
  activeColumns?: ICheckedScheme;
  handlePlanCheckBox: (scheme: ICheckedScheme) => void;
}
