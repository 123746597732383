import { IconButton, styled } from "@mui/material";

export const IconButtonStyled = styled(IconButton)<{
  selected: boolean | undefined;
}>(({ theme, selected }) => ({
  padding: 0,
  width: theme.spacing(4),
  height: theme.spacing(4),
  background: selected
    ? theme.palette.button.popup
    : theme.palette.button.iconBackground,
  border: `1px solid ${
    selected
      ? theme.palette.button.downloadBorder
      : theme.palette.text.disabledNotSelected
  }`,
  color: theme.palette.text.onAccent,
  borderRadius: theme.spacing(0.5),
  "& svg": {
    fill: selected ? "currentColor" : theme.palette.text.light,
  },
}));
