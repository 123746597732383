import { styled } from "@mui/material";

export const ProjectGeneralInfoValues = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2.5),
  marginTop: theme.spacing(2),

  "& > div": {
    flex: 1,
  },
}));

export const ProjectGeneralInfoValue = styled("div")(({ theme }) => ({
  fontSize: theme.spacing(3.5),
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: theme.spacing(3),
}));
