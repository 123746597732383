import React, { ReactNode, useCallback, useMemo, useState } from "react";

import { Iswitcher } from "@/features/RegionMutableRate/lib/types";

import { IRestoreRegionPopUp, REGION_TABLES } from "../lib/types";
import { RegionPopUp } from "../ui/RegionPopUp";
import { ButtonPlug } from "../ui/RegionPopUp.styled";
import { RegionPopUpButton } from "../ui/RegionPopUpButton/ui/RegionPopUpButton";

export const useRegionPopUp = (
  table?: REGION_TABLES,
  isOpenPopUp?: boolean,
  title?: string | ReactNode,
  selected?: string,
  periodId?: string,
  minWidth?: number,
  switcher?: Iswitcher
): IRestoreRegionPopUp => {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback((isOpen: boolean) => {
    return setOpen(isOpen);
  }, []);

  const PopUpButton = useCallback(() => {
    return isOpenPopUp ? (
      <ButtonPlug />
    ) : (
      <RegionPopUpButton open={open} handleOpen={() => handleOpen(!open)} />
    );
  }, [handleOpen, isOpenPopUp, open]);

  const PopUp = useCallback(() => {
    return (
      <RegionPopUp
        open={open}
        table={table}
        title={title}
        periodId={periodId}
        minWidth={minWidth}
        selectedProject={selected}
        switcher={switcher}
        handleOpen={() => handleOpen(!open)}
      />
    );
  }, [handleOpen, open, table, title]);

  const popupHeightMax = useMemo(
    () => (isOpenPopUp ? `70vh` : undefined),
    [isOpenPopUp]
  );

  return {
    PopUp,
    PopUpButton,
    popupHeightMax,
  };
};
